import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';


export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: 'transparent',
      height: '151px',
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        flexGrow: 0,
      },
      [theme.breakpoints.down(500)]: {
        height: '91px',

      }
    },
    gridContainer: {
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    mainNavigatorContainer: {
      [theme.breakpoints.down(1000)]: {
        display: 'none !important'
      }
    },
    logoWrapper: {
      position: 'relative',
      width: '102px',
      height: '28px'
    },
    logoAbsolute: {
      position: 'absolute',
      top: '-4px',
      width: '102px',
      height: '28px',
      [theme.breakpoints.down(1050)]: {
        display: 'none'
      },
      [theme.breakpoints.down(1000)]: {
        display: 'block'
      }
    },
    oldLogo: {
      display: 'none',
      [theme.breakpoints.down(1050)]: {
        display: 'block'
      },
      [theme.breakpoints.down(1000)]: {
        display: 'none',
      }
    },
    logo: {
      display: 'none',
      [theme.breakpoints.down(1780)]: {
        display: 'block',
      }
    },
    bigLogo: {
      display: 'block',
      [theme.breakpoints.down(1780)]: {
        display: 'none',
      }
    },
    button: {
      boxSizing: 'border-box',
      borderRadius: '4px',
      padding: '0 36px',
      display: 'block',
      [theme.breakpoints.down(1000)]: {
        margin: '15px 0 0',
      },
      "&:hover": {
        '& span': {
          fontWeight: '600 !important'
        }
      }
    },
    btnMenu: {
      margin: '0 0 0 auto',
      display: 'none',
      [theme.breakpoints.down(1000)]: {
        display: 'block',
      },
      '& svg': {
        fontSize: '42px',
        color: '#ffffff',
      },
    },
    icons: {
      margin: '0 0 0 auto'
    },
    text: {
      padding: '0 0 50px 0',
      margin: '0',
      boxSizing: 'border-box',
    },
    paper: {
      position: 'absolute',
      width: '30%',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: theme.palette.background.paper,
      border: 'none',
      padding: theme.spacing(2, 4, 3),
      borderRadius: '10px',
    },
    box: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'baseline',
      width: '100%'
    },
    personalAccoutLink: {
      color: '#306F9A'
    },
    profileBox: {
      display: 'flex',
      flexDirection: 'column',
      gap: '6px',
      marginBottom: '20px'
    },
    messages: {
      position: 'absolute',
      width: '29px',
      height: '19px',
      backgroundColor: '#309A90',
      borderRadius: '10px',
      color: '#ffffff',
      bottom: '70%',
      left: '35%',
      textAlign: 'center',
    },
    title: {
      padding: '38px 30px ',
    },
    mobMenu: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100vw',
      height: '100vh',
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'flex-end',
      zIndex: 5,
    },
    mobMenuWrap: {
      minWidth: '270px',
      padding: '25px',
      height: '100vh',
      backgroundColor: "#ffffff",
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      '&>div': {
        '&>div': {
          '&>div': {
            padding: '0 0 20px 0',
            display: 'block',
          }
        }
      },
    },
    avatarWrap: {
      position: 'relative',
      zIndex: 5,
      margin: '0 0 0 auto',
      width: '60px',
      height: '60px',
      '&>div': {
        '&:first-child:hover': {
          cursor: 'pointer',
          '&+div': {
            display: 'block',
            '&:hover': {
              display: 'block',
            }
          }
        },
        '&:last-child': {
          position: 'absolute',
          width: '300px',
          backgroundColor: '#fff',
          display: 'none',
          right: '0',
          top: '100%',
          '&:hover': {
            display: 'block',
          }
        }
      },
      [theme.breakpoints.down('sm')]: {
        margin: '0 0 25px',
        '&>div:last-child': {
          position: 'static!important',
        }
      }
    },
    enter: {},
    userInfo: {
      textAlign: 'start',
      padding: '15px',
      position: 'absolute',
      zIndex: 5,
      width: '300px',
      right: '50px',
      marginTop: '10px',
      '& a': {
        display: 'block',
        padding: '8px 0',
        '&:hover': {
          cursor: 'pointer',
        }
      },
      [theme.breakpoints.down(1280)]: {
        right: '0px',
      }
    },
    phone: {
      textDecoration: 'none',
      textAlign: 'left',
      display: 'block',
      [theme.breakpoints.down(1000)]: {
        position: 'sticky',
        bottom: '70px',
        paddingTop: '10px',
      }
    },
    productsContainer: {
      marginTop: '30px'
    },
    product: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      textDecoration: 'none',
      fontSize: '16px',
      fontFamily: "Gilroy",
      fontWeight: 500,
      marginTop: '20px',
      color: '#131313',
      position: 'relative',
    }
  })
);
