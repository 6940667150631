import { memo, useState } from "react";
import NumberFormat from "react-number-format";
import authApi from "../../../core/api/authApi";
import { actions } from "../../../core/store";
import { FormType } from "../../../app/enum";
import classes from "../LoginNew.module.scss";
import { useHistory } from "react-router-dom";
import { TextFieldMain } from "../../../core/consts/redesign/UIcomponents/TextFieldMain";
import { COLORS } from "../../../core/consts/redesign/commonRedesign";
import CircularProgress, { circularProgressClasses } from "@mui/material/CircularProgress";

type Props = {
  setPhone: (phone: string) => void;
};

const PhoneForm: React.FC<Props> = (props) => {
  const [errMessage, setErrMessage] = useState<string | null>(null);
  const [phone, setPhone] = useState({ number: "" });

  const [isLoader, setIsLoader] = useState(false);

  const history = useHistory();
  const handlePressKey = (e: any) => {
    if (e.key === "Enter" && !isLoader) {
      handleClickSendPhone();
    }
  };
  const handleClickSendPhone = () => {

    const phoneNumberLength = phone.number.replaceAll(" ", "").length;
    if (phone.number.length === 0) {
      setErrMessage("Заполните номер телефона");
      return;
    } else if (phoneNumberLength < 16) {
      setErrMessage("Некорректный номер телефона");
      return;
      // } else if (!/\+7 \(\d\d\d\) \d\d\d-\d\d-\d\d/g.test(phone.number)) {
      //   setErrMessage("Неверный формат номера телефона");
      //   return;
    }
    props.setPhone(phone.number);
    setIsLoader(true);
    authApi
      .checkPhoneCode(true, phone.number)
      .then((res) => {
        setErrMessage(null);
        actions.setCurrentUser({
          isAuth: false,
          inn: "",
          userId: res.id,
          phone: "",
          role: "",
          manager: null,
          nameFull: "",
          email: "",
          is_manager_approval: undefined,
          status: undefined,
        });
        history.push({
          pathname: history.location.pathname,
          search: `?formType=${FormType.LoginSecondPhone}`,
        });
        setIsLoader(false);
      })
      .catch((err) => {
        err && setErrMessage("Произошла ошибка, попробуйте позже.");
        err.response.status === 400 &&
          setErrMessage("Некорректный номер телефона");
        err.response.status === 404 &&
          setErrMessage("Пользователь с таким номером не зарегистрирован");
        err.response.status === (502 || 500) &&
          setErrMessage("Ошибка на сервере");
      })
      .finally(() => setIsLoader(false))
  };

  const handleChangePhone = (event: React.ChangeEvent<HTMLInputElement>) => {
    // console.log("event.target.value", event.target.value);
    let localPhoneNumber = event.target.value;
    const phoneNumberLength = localPhoneNumber.replaceAll(" ", "").length;
    // console.log("phoneNumberLength", phoneNumberLength);
    // if (localPhoneNumber[0] !== "7" && phoneNumberLength === 15) {
    //   localPhoneNumber = `+7 (${localPhoneNumber[1]}${localPhoneNumber[4]}${localPhoneNumber[5]}) ${localPhoneNumber[6]}${localPhoneNumber[9]}${localPhoneNumber[10]}-${localPhoneNumber[11]}${localPhoneNumber[13]}-${localPhoneNumber[14]}${localPhoneNumber[16]}`;
    // } else
    if (phoneNumberLength === 16 && localPhoneNumber[1] !== "7") {
      localPhoneNumber =
        "+7" + localPhoneNumber.substring(2, localPhoneNumber.length);
    } else if (localPhoneNumber[1] !== "7" && localPhoneNumber !== "") {
      if (localPhoneNumber[1] === "8") {
        localPhoneNumber = "+7";
      } else {
        localPhoneNumber =
          "+7" + localPhoneNumber.substring(1, localPhoneNumber.length);
      }
    } else if (localPhoneNumber === "") {
      localPhoneNumber = "";
    }
    // console.log("localPhoneNumber", localPhoneNumber);
    setPhone({ number: localPhoneNumber });
  };

  return (
    <div className={classes.main_column}>
      <div className={classes.input_box}>
        <NumberFormat
          id="phone"
          label={<div style={{ color: COLORS.gray }}>Ваш номер</div>}
          customInput={TextFieldMain}
          // variant="filled"
          type="tel"
          format="+# (###) ###-##-##"
          placeholder="+7 (800) 000-00-00"
          // className={classes.textField}
          onKeyPress={handlePressKey}
          value={phone.number}
          error={Boolean(errMessage)}
          onChange={handleChangePhone}
          autoFocus={true}
          inputMode="numeric"
          InputLabelProps={{
            shrink: true,
            style: { fontSize: 20 },
          }}
          autoComplete="tel"
        />
        {errMessage && <div className="error">{errMessage}</div>}
      </div>
      <button className="button_primary_w100" onClick={handleClickSendPhone}
        disabled={isLoader}
      >
        {isLoader ? <CircularProgress
          variant="indeterminate"
          disableShrink
          sx={{
            color: (theme) =>
              theme.palette.mode === "light"
                ? COLORS.brand_blue
                : COLORS.brand_blue,
            animationDuration: "550ms",
            left: 0,
            [`& .${circularProgressClasses.circle}`]: {
              strokeLinecap: "round",
            },
          }}
          size={21}
          thickness={4}
          {...props}
        /> : "Продолжить"}
      </button>
    </div>
  );
};

export default memo(PhoneForm);
