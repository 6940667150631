import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styles from "../LoginNew.module.scss";
import { FormType } from "../../../app/enum";

const detectDevice = (): string => {
  const userAgentRes = navigator.userAgent;
  let result = "desk";

  // if (/IEMobile|Windows Phone|Lumia/i.test(userAgentRes)) {
  //   result = "Windows Phone";
  // } else if (/iPhone|iP[oa]d/.test(userAgentRes)) {
  //   result = "iOS";
  // } else if (/Android/.test(userAgentRes)) {
  //   result = "Android";
  // } else if (/BlackBerry|PlayBook|BB10/.test(userAgentRes)) {
  //   result = "BlackBerry";
  // } else if (/Mobile Safari/.test(userAgentRes)) {
  //   result = "Mobile Safari";
  // } else if (
  //   /webOS|Mobile|Tablet|Opera Mini|\bCrMo\/|Opera Mobi/i.test(userAgentRes)
  // ) {
  //   result = "mob";
  // }
  return result;
};
const authTypes = [
  {
    id: 1,
    title: "Номер телефона",
    desc: "Вход с использованием номера сотового телефона РФ",
    value: FormType.LoginFirstPhone,
  },
  {
    id: 2,
    title: "E-mail",
    desc: "Вход с использованием любого адреса электронной почты",
    value: FormType.LoginFirstEmail,
  },
  {
    id: 3,
    title: "Электронная цифровая подпись",
    desc: (
      <>
        Вход с ЭЦП. Сертификат электронной подписи можно получить в одном из
        аккредитованных Минкомсвязью России{" "}
        <a
          href="https://digital.gov.ru/ru/activity/govservices/2/#section-list-of-accredited-centers"
          target="_blank"
          className={styles.blue_text}
        >
          удостоверяющих центров
        </a>
        .
      </>
    ),
    value: FormType.LoginCertificate,
  },
];

const LoginTypesForm = () => {
  const [isNotMob, setIsNotMob] = useState<boolean>();

  useEffect(() => {
    setIsNotMob(detectDevice() === "desk");
  }, []);

  const history = useHistory();

  return (
    <div className={styles.typeItem_box}>
      {(isNotMob ? authTypes : [authTypes[0], authTypes[1]]).map((elem) => (
        <div
          className={elem.id === 3 ? styles.typeItem_cert : styles.typeItem}
          key={elem.id}
          onClick={() => {
            history.push({
              pathname: history.location.pathname,
              search: `?formType=${elem.value}`,
            });
          }}
        >
          <div className={styles.typeItem_title}>{elem.title}</div>
          <div>{elem.desc}</div>
        </div>
      ))}
    </div>
  );
};

export default LoginTypesForm;
