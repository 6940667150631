export type CountsState = dataForCounts[];
export type dataForCounts = {
  leadId: number,
  countNeededDocs: number;
  countUnreadMessage: number,
  countUnreadNotifications: number,
}

export enum countsActionTypes {
  EDIT_DOCS_COUNT = 'EDIT_DOCS_COUNT',
  EDIT_CHATS_COUNT = 'EDIT_CHATS_COUNT',
  EDIT_NOTIFICATIONS_COUNT = 'EDIT_NOTIFICATIONS_COUNT',
  CLEAN_USER_COUNTS = 'CLEAN_USER_COUNTS',
}

interface EditDocsAction {
  type: countsActionTypes.EDIT_DOCS_COUNT;
  payload: dataForCounts
}

interface EditChatsAction {
  type: countsActionTypes.EDIT_CHATS_COUNT;
  payload: dataForCounts
}

interface EditNotificationsAction {
  type: countsActionTypes.EDIT_NOTIFICATIONS_COUNT;
  payload: dataForCounts
}

interface CleanCountsAction {
  type: countsActionTypes.CLEAN_USER_COUNTS;
}

export type CountsActions = EditDocsAction | EditChatsAction | EditNotificationsAction | CleanCountsAction;

