import React, { memo, useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  makeStyles,
  createStyles,
  Typography,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";

const useStyles = makeStyles(() =>
  createStyles({
    ol: {
      counterReset: "section",
      listStyleType: "none",
      fontFamily: "Gilroy",
    },
    li: {
      fontFamily: "Gilroy",
      "&::before": {
        counterIncrement: "section",
        content: 'counters(section, ".") ". "',
      },
    },
  })
);

type propsType = {
  isOpen: boolean;
  clientType: string;
  handleClose: any;
};
const AgreementModal: React.FC<propsType> = (props) => {
  const [open, setOpen] = useState(props.isOpen);
  const classes = useStyles();
  useEffect(() => {
    setOpen(props.isOpen);
  }, [props.isOpen]);

  const handleClose = () => {
    props.handleClose();
    // setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      style={{ fontFamily: "Gilroy" }}
    >
      <DialogTitle id="scroll-dialog-title">Согласие</DialogTitle>
      <DialogContent dividers={true}>
        <DialogContentText
          id="scroll-dialog-description"
          tabIndex={-1}
          style={{ fontFamily: "Gilroy" }}
        >
          <p>
            <h4>
              Согласие на обработку персональных данных, на получение из бюро
              кредитных историй информации/кредитных отчетов
            </h4>
            <ol className={classes.ol}>
              <li className={classes.li}>
                Настоящим я сознательно, свободно, своей волей и в своем
                интересе даю свое согласие (далее – «Согласие»){" "}
                <strong>ООО «ФИНЛАБ»</strong> (ОГРН 1217700262592, адрес места
                нахождения: 127018, г. Москва, пр-д Марьиной рощи 3-й, д. 40,
                стр. 1, этаж/помещ./ком. 15/I/6, на обработку, а также
                совершение любых действий с использованием средств автоматизации
                и/или без средств автоматизации, в том числе сбор, запись,
                систематизацию, накопление, хранение, уточнение (обновление,
                изменение) извлечение, использование, передачу (распространение,
                предоставление, доступ) <strong> Третьим лицам</strong>,
                обезличивание, блокирование, удаление, уничтожение в
                соответствии с Федеральным законом от 27.07.2006 г. No 152-ФЗ «О
                персональных данных» всех моих персональных данных,
                предоставленных мною самостоятельно при заполнении, в том числе
                регистрации и заполнении Анкеты на официальном сайте ООО
                «ФИНЛАБ» в информационно- коммуникационной сети «Интернет»
                www.finlab.ru/ , по почте, электронной почте или иным способом,
                а также полученных ООО «ФИНЛАБ» и любыми бюро кредитных историй
                от любых третьих лиц в соответствии с настоящим Согласием, в том
                числе: фамилия, имя, отчество; пол; дата и место рождения;
                паспортные данные; идентификационный номер налогоплательщика;
                страховой номер индивидуального лицевого счета; фотографии;
                место проживания (официальной регистрации), включая регион
                проживания, основание проживания (наём или нахождение жилого
                помещения в собственности), адрес фактического проживания, время
                проживания по текущему адресу; номер мобильного телефона; номер
                домашнего телефона (в случае представления); номер рабочего
                телефона (при наличии); электронная почта; семейное положение,
                количество детей и иждивенцев; образование, профессия, сведения
                о месте работы, должность, фирменное наименование и
                организационно-правовая форма юридического лица, доходы, даты
                зарплаты; сведения о наличии у меня задолженности, в т.ч. по
                займам, по оплате коммунальных платежей, за аренду и пр.;
                информацию, содержащуюся в кредитной истории.
              </li>
              <li className={classes.li}>
                Настоящее Согласие на обработку моих персональных данных дается
                в целях рассмотрения партнерами ООО «ФИНЛАБ» возможности
                заключения со мной соответствующих договоров по приобретению
                мною продуктов/услуг партнеров ООО «ФИНЛАБ» или исполнения
                соответствующих договоров (в том числе для обработки
                персональных данных с использованием оценочных (скоринговых)
                методик, предоставления персональных данных в обезличенном виде
                третьим лицам для их обработки с применением оценочных
                (скоринговых) методик).
              </li>
              <li className={classes.li}>
                Настоящее Согласие дается в целях:
                <ol className={classes.ol}>
                  <li className={classes.li}>
                    подбора и предоставления мне ООО «ФИНЛАБ» информации о
                    запрошенной мной финансовой услуге (в том числе банковской
                    услуге, страховой услуг, и услуге, оказываемой финансовыми
                    организациями);
                  </li>
                  <li className={classes.li}>
                    рассмотрения Третьими лицами возможности
                    заключения/исполнения со мной соответствующих договоров по
                    приобретению мною продуктов/услуг Третьих лиц или исполнения
                    соответствующих договоров (в том числе для обработки
                    персональных данных с использованием оценочных (скоринговых)
                    методик), а также проверки моей благонадежности, путем
                    получения ООО «ФИНЛАБ» и/или Третьими лицами из любого бюро
                    кредитных историй информации/кредитных отчетов обо мне в
                    соответствии с Федеральным законом от 30.12.2004 г. No
                    218-ФЗ «О кредитных историях».
                  </li>
                  <li className={classes.li}>
                    осуществления Третьими лицами направленного на возврат
                    просроченной задолженности взаимодействия с соответствующими
                    органами и передачи им сведений о моих персональных данных,
                    в т. ч. о просроченной задолженности и ее взыскании.
                  </li>
                  <li className={classes.li}>
                    информирования меня ООО «Финлаб» и/или Третьими лицами о
                    новых акциях, продуктах и услугах, предоставляемых Третьими
                    лицами, в целях осуществления контроля проведения и
                    подведения итогов маркетинговых акций, а также в целях
                    проведения электронных и смс-опросов.
                  </li>
                  <li className={classes.li}>
                    получение мной рекламных предложений и информации о
                    страховых, финансовых и банковских продуктах Третьих лиц
                    через мобильный или стационарный телефон посредством
                    голосовых вызовов или коротких текстовых сообщений (SMS), а
                    также на электронную почту.
                  </li>
                </ol>
              </li>
              <li className={classes.li}>
                Настоящее согласие действует в течение пяти лет со дня его
                предоставления, за исключением согласия на получение информации
                о моей кредитной истории (п.8), которое действует в течение
                шести месяцев со дня оформления.
              </li>
              <li className={classes.li}>
                Согласие на обработку и использование моих персональных данных,
                за исключением данного в целях, указанных в п 2.3, может быть
                досрочно отозвано путем направления заявления в ООО «ФИНЛАБ» в
                простой письменной форме. Отказаться от рассылок рекламного
                характера, а также отозвать согласие на обработку моих данных в
                целях, указанных в п. 2.5 настоящего Согласия, я вправе, путем
                направления сообщения по электронной почте info@finlab.ru.
                Согласие на обработку и использование моих персональных данных,
                данное в целях, указанных в п. 2.3, может быть отозвано в любое
                время путем направления ООО «ФИНЛАБ» уведомления через нотариуса
                или по почте заказным письмом с уведомлением о вручении либо
                путем вручения заявления под расписку уполномоченному лицу ООО
                «ФИНЛАБ».
              </li>
              <li className={classes.li}>
                Я поручаю ООО «ФИНЛАБ» обработку персональных данных
                предоставляемых мной лиц. Настоящим я подтверждаю, что
                персональные данные лиц предоставляются мной с согласия таких
                лиц с учетом цели, способов и сроков обработки персональных
                данных, определенных настоящим Согласием.
              </li>
              <li className={classes.li}>
                {" "}
                Я даю свое согласие на получение от ООО «ФИНЛАБ» и/или Третьих
                лиц информационных материалов о наступлении сроков исполнения
                обязательств по договорам, возникновении или наличии
                просроченной задолженности с указанием суммы, иной информации,
                связанной с исполнением договоров, а также информации,
                направляемой в целях, указанных в п. 2.3 настоящего Согласия, по
                любым каналам связи, включая: смс-оповещение, почтовое письмо,
                телеграмму, голосовое сообщение, сообщение по электронной почте,
                личное сообщение в мессенджере или социальной сети,
                push-уведомления через мобильное приложение или браузер. При
                этом для данных информационных рассылок я разрешаю ООО «ФИНЛАБ»
                и Третьим лицам использовать любую контактную информацию,
                переданную мной ООО «ФИНЛАБ»
              </li>
            </ol>
          </p>
          <p>
            <h4>
              СОГЛАСИЕ
              <br />
              юридического лица на получение информации в Бюро кредитных историй
            </h4>
            <ol className={classes.ol}>
              <li className={classes.li}>
                Настоящим Клиент, выражает свое согласие обществу с ограниченной
                ответственностью «ФИНЛАБ» (далее - ООО «ФИНЛАБ»), ОГРН
                1217700262592, расположенному по адресу:127018, г. Москва, пр-д
                Марьиной рощи 3-й, д. 40, стр. 1, этаж/помещ./ком. 15/I/6, а
                также Третьим лицам1, как пользователям кредитной истории, на
                получение из любого бюро кредитныхисторий, кредитного отчета,
                содержащего, в том числе, основную часть кредитной
                истории,определенную в ст. 4 Федерального закона от 30.12.2004
                г. No218-ФЗ «О кредитных историях».
              </li>
              <li className={classes.li}>
                Указанное Согласие действительно с момента его подписания и
                выдано в целях:
                <ol className={classes.ol}>
                  <li className={classes.li}>
                    рассмотрения ООО «ФИНЛАБ» и/или Третьими лицами возможности
                    заключения с Агентом соответствующих договоров по
                    приобретению Агентом продуктов/услуг Третьих лиц (в том
                    числе банковских услуг, страховых услуг, и услуг,
                    оказываемых финансовыми организациями) илиисполнения
                    соответствующих договоров.
                  </li>
                  <li className={classes.li}>
                    осуществления ООО «ФИНЛАБ» и/или Третьими лицами
                    направленного на возвратпросроченной задолженности
                    взаимодействия с соответствующими органами и передачи им
                    сведений об Агенте, в т. ч. о просроченной задолженности и
                    ее взыскании.
                  </li>
                </ol>
              </li>
              <li className={classes.li}>
                Настоящее Согласие считается действительным в течение двух
                месяцев со дня его оформления. В случае, если в течение
                указанного срока соответствующий договор с Третьим лицом был
                заключен, согласие сохраняет силу в течение всего срока действия
                договора.
              </li>
            </ol>
          </p>
          <hr />
          <p style={{ fontSize: "10px" }}>
            <sup>1</sup>Перечень третьих лиц, с которыми у ООО «ФИНЛАБ»
            заключены агентские и/или иные договорные отношения,
            предусматривающие обработку данных клиентов: ПАО «Сбербанк» -
            Россия, Москва, 117312, ул. Вавилова, д.19, ОГРН: 1027700132195,
            ИНН/КПП: 7707083893/ 773601001; ПАО АКБ «Металлинвестбанк» - 119180,
            г. Москва, ул. Большая Полянка, д. 47, стр. 2, ОГРН: 1027700218666,
            ИНН/КПП 7709138570/770601001; АО КБ «Модульбанк» - 127015, г.
            Москва, ул.Новодмитровская, д. 2, корп. 1, ОГРН: 1022200525841,
            ИНН/КПП 2204000595/771543001; АО «АБ «РОССИЯ» - 191124,
            Санкт-Петербург, пл. Растрелли, д.2, лит. А., ОГРН:1027800000084,
            ИНН/КПП: 7831000122/783501001; АО «АЛЬФА-БАНК» - 107078, Москва, ул.
            Каланчевская, 27, ОГРН: 1027700067328, ИНН/КПП: 7728168971/
            770801001; Банк ВТБ (ПАО) - 191144, город Санкт-Петербург, Дегтярный
            пер., д. 11 литер а, ОГРН: 1027739609391, ИНН/КПП
            7702070139/783501001; АО «Тинькофф Банк» - Россия, 127287, г.
            Москва, ул. Хуторская 2-я, д. 38А, стр. 26, ОГРН: 1027739642281,
            ИНН/КПП: 7710140679/771301001; АКБ «Абсолют Банк» (ПАО) - 127051, г.
            Москва, Цветной бульвар, д. 18, ОГРН: 1027700024560, ИНН/КПП:
            7736046991/770201001; Эс-Би-Ай Банк ООО - 125315, город Москва,
            Ленинградский пр-кт, д. 72 к. 2, корпус 4, ОГРН: 1037739028678,
            ИНН/КПП: 7708013592/774301001; ПАО РОСБАНК - 107078, г.Москва,
            ул.Маши Порываевой, д. 34, ОГРН: 1027739460737, ИНН/КПП: 7730060164/
            770801001; АО «Райффайзенбанк» - 129090, Москва, ул.Троицкая, д.17,
            стр. 1, ОГРН: 1027739326449, ИНН/КПП: 7744000302/ 770201001; АО МФК
            «МИКРО КАПИТАЛ» - 443013, Самарская область, г. Самара, пр. Карла
            Маркса, д. 32, эт.1, офис 13-14, ОГРН 1056316050790, ИНН/КПП
            6316103050/ 631501001; АО «БАНК СГБ» -160001, г. Вологда, ул.
            Благовещенская, д.3, ОГРН: 1023500000160 ИНН/КПП:
            3525023780/997950001; ПАО«МТС-Банк» - Россия, 115432, г. Москва,
            Андропова пр-т, д. 18, корп. 1, ОГРН: 1027739053704, ИНН/КПП:
            7702045051/772501001; АО "МИнБанк" - Россия, 115419, г. Москва, ул.
            Орджоникидзе, д. 5., ОГРН: 1027739179160, ИНН/КПП:
            7725039953/997950001; ПАО Банк «ФК Открытие» - 115114, г. Москва,
            ул. Летниковская, д. 2, стр. 4, ОГРН: 1027739019208 ИНН/КПП:
            7706092528/770501001; АО КБ «РУСНАРБАНК» - Российская Федерация,
            115184, город Москва, Озерковский переулок, дом 3, ОГРН:
            1027739028855, ИНН/КПП: 7744002211/ 770501001; «Кредит Урал Банк»
            (Акционерное общество) - Россия, 455044 г. Магнитогорск, ул.
            Гагарина, 17, ОГРН: 1027400000638 ИНН/КПП: 7414006722/9979500; КБ
            «ЛОКО-Банк» (АО) - 125167, г. Москва, Ленинградский просп., д. 39,
            стр. 80, ОГРН: 1057711014195, ИНН/КПП: 7750003943/771401001; АО
            «РЕАЛИСТ БАНК» - 109004, город Москва, ул. Станиславского, д. 4 стр.
            1, ОГРН: 1023800000124, ИНН/КПП: 3801002781/770901001 ; ООО «Мэйджор
            Лизинг» - РФ, 143420, Московская обл., Красногорский р-н, п/о
            Архангельское, 25 км а/м «Балтия», складской комплекс «Кулон Балтия»
            инв. № 30-7153/1, кабинет 119, ОГРН: 1085024000181, ИНН/КПП:
            5024093363/502401001; ООО "СпецАвтоТехЛизинг" - 125284, г. Москва,
            Хорошевское шоссе, д. 32А, пом. XXVI, ком. 30, ОГРН: 1027700497351,
            ИНН/КПП: 7726290542/771401001; ООО «Солид-Лизинг» - 123007, г.
            Москва, Хорошевское ш., д. 32А, пом. XXVIII, ОГРН: 1047796977392,
            ИНН/КПП: 7714582540/771401001 ; ООО «"РОВИ ФАКТОРИНГ ПЛЮС"» -
            109028, город Москва, Серебряническая наб, д. 29, этаж/помещ./ком.
            4/III/51, ОГРН: 1175007004150, ИНН/КПП: 5010053018/770901001; АО
            «МОСКОМБАНК» - 119146, город Москва, 1-Я Фрунзенская ул., д.5, ОГРН:
            1027739223687, ИНН/КПП: 7727065444/770401001; ПАО «Промсвязьбанк» -
            109052, город Москва, Смирновская ул., д. 10 стр. 22, ОГРН:
            1027739019142, ИНН/КПП: 7744000912/772201001; ООО «КОНТРОЛ лизинг» -
            196210, г. Санкт-Петербург, ул. Стартовая, дом 8, литер А, ОГРН:
            1097847051620, ИНН/КПП: 7805485840/770801001 ; ПАО «Совкомбанк» -
            156000, Костромская область, город Кострома, пр-кт Текстильщиков, д.
            46, ОГРН: 1144400000425, ИНН/КПП: 4401116480/770343003; ООО
            "РЕСО-Лизинг" - 117105, город Москва, Нагорный пр-д, д. 6 стр 8,
            ОГРН: 1037709061015, ИНН/КПП: 7709431786/772601001; АО
            «ПЕРВОУРАЛЬСКБАНК» - 623101, Свердловская область, г. Первоуральск,
            пр. Ильича, 9 «Б», ОГРН: 1026600001823, ИНН/КПП:
            6625000100/668401001; ООО «Прайм Факторинг» - 127015, г. Москва, ул.
            Новодмитровская, д. 2 корп. 1 этаж 9 Ч.Н.П. LVI ком. 1., ОГРН:
            1107746554321, ИНН/КПП: 7705922670/770501001; ПАО СКБ Приморья
            «Примсоцбанк» - 690106, Приморский край, город Владивосток,
            Партизанский пр-кт, д.44, ОГРН: 1022500001061, ИНН/КПП:
            2539013067/667145001; ООО «Транспортная лизинговая компания» -
            150521, Ярославская область, Ярославский район, поселок Щедрино,
            Московская ул., стр. 6а, офис 307, ОГРН: 1027600838913, ИНН/КПП:
            7606041801/762701001; ООО «АТБ ЛК» - 109004, г. Москва, ул.
            Николоямская, д. 38, стр. 2, этаж 4, помещ. 41, 43-46, ОГРН:
            1029703024733, ИНН/КПП: 7703363177/770901001; ООО «ЗЕНИТ ФИНАНС» -
            117638, город Москва, Одесская ул, д. 2, этаж 18 помещ. II, ОГРН:
            1187746040910, ИНН/КПП: 7702428209/772701001; ООО МКК «Папа Финанс»
            - 105082, г. Москва, пер. Спартаковский, д. 2, стр. 1, пом. Н.П.14,
            ОГРН: 770101001, ИНН/КПП: 9701125685/770101001; ООО «Прайм-инвест» -
            123290, город Москва, Шмитовский пр-д, д. 39 к. 2, эт -1 пом XVIII
            каб 2-2, ОГРН: 1157746872843, ИНН/КПП: 7704330079/770401001; АКБ
            «Держава» ПАО - 119435, город Москва, Большой Саввинский пер., д. 2
            стр.9, ОГРН: 1027739120199, ИНН/КПП: 7729003482/770401001 ; ООО
            «Райффайзен-Лизинг» - 119002, РФ, г. Москва, пл. Смоленская-Сенная,
            д.28, ОГРН: 1027739597819, ИНН/КПП: 7702278747/774850001, АО
            «Газпромбанк» - 117420, город Москва, ул. Намёткина, д.16 к.1, ОГРН
            1027700167110, ИНН/КПП: 7744001497/772801001, ПАО «АК БАРС» -
            420066, Республика Татарстан, город Казань, ул. Декабристов, д.1,
            ОГРН: 1021600000124, ИНН/КПП: 1653001805/16560100;, ТКБ БАНК ПАО -
            109147, город Москва, Воронцовская ул., д.27/35, ОГРН:
            1027739186970, ИНН/КПП: 7709129705/770901001; АО «ЮниКредит Банк» -
            119034, Россия, Москва, Пречистенская наб., д. 9, ОГРН:
            1027739082106, ИНН/КПП: 7710030411/770401001; ПАО Банк ЗЕНИТ, ОГРН:
            1027739056927, ИНН/КПП: 7729405872/772701001.
          </p>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          <Typography
            component="span"
            variant="body1"
            style={{ color: "inherit", textTransform: "none", lineHeight: "3", fontFamily: "Gilroy" }}
          >
            Ознакомлен(-а)
          </Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default memo(AgreementModal);
