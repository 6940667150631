import { CookiesEnum } from "../../consts/redesign/commonRedesign";
import proposalService from "../../services/ProposalService";
import tokenService from "../../services/TokenService";
import { CartActionTypes, CartActions, CartItemForCookieType, CartState } from "../../types/cart";
import { GetPropsUniversalQueryType, ResponseItemDataType } from "../../types/proposalTypes";

const getProposalData = async (realId: number) => {
  try {
    const Q: GetPropsUniversalQueryType = {
      id: realId,
    };
    const res = await proposalService.getPropsServiceUniversal(Q);
    if (res) {
      return (res.result?.[0])
    }
  } catch (e) {
    console.log(e)
  }
}

let tempCart: ResponseItemDataType[] = [];

if (typeof window !== "undefined") {
  try {
    const cookieCart = tokenService._getCookie(CookiesEnum.cart);
    if (cookieCart) {
      const tempCartFromCookieString = decodeURIComponent(cookieCart);
      const tempCartFromCookie: CartItemForCookieType[] = JSON.parse(tempCartFromCookieString);
      // console.log("Cart loaded from cookie:", tempCartFromCookie);

      if (tempCartFromCookie && Array.isArray(tempCartFromCookie)) {
        const fetchPromises = tempCartFromCookie.map(elem => {
          return getProposalData(+elem.idOpen)
            .then(res => {
              if (res) {
                tempCart.push(res);
                // console.log("Added to tempCart:", res);
              }
              return res;
            })
            .catch(error => {
              console.error('Error fetching data for ID:', elem.id, error);
              return null;
            });
        });

        Promise.all(fetchPromises).then(() => {
          // console.log("Final tempCart after all fetches:", tempCart);
          window.localStorage.setItem(CookiesEnum.cart, JSON.stringify(tempCart));
        });
      }
    }
  } catch (e) {
    console.error('Error processing cookie cart data:', e);
  }
}

const initialState: CartState = {
  applications: tempCart,
  isOpenCart: false,
};

export const CartReducer = (state = initialState, action: CartActions) => {
  switch (action.type) {
    case CartActionTypes.ADD_TO_CART: {
      const temp = state.applications.find(
        (elem) => elem?.id === action.payload?.id
      );
      if (temp) {
        return { ...state, isOpenCart: true };
      } else {
        typeof window !== "undefined" &&
          window.localStorage.setItem(
            CookiesEnum.cart,
            JSON.stringify([...state.applications, action.payload])
          );

        // for cookie
        const tempCart = [...state.applications, action.payload];
        const tempCartForCookie: CartItemForCookieType[] = [];
        tempCart.forEach(elem => {
          const temp: CartItemForCookieType = {
            idOpen: elem.id,
            id: elem.realProposalId,
            name: elem.name,
            bankName: elem.bankName
          }
          tempCartForCookie.push(temp);
        })
        tokenService._setCookie(CookiesEnum.cart, JSON.stringify(tempCartForCookie), 1, "/")

        return {
          ...state,
          applications: [...state.applications, action.payload],
        };
      }
    }
    case CartActionTypes.ADD_TO_CART_ARRAY: {
      const newForCart: ResponseItemDataType[] = [];
      action.payload.forEach(elem => {
        const temp = state.applications.find(
          (el) => el?.id === elem.id
        );
        if (!temp) {
          newForCart.push(elem);
        }
      })
      if (newForCart.length > 0 && typeof window !== "undefined") {
        window.localStorage.setItem(
          CookiesEnum.cart,
          JSON.stringify([...state.applications, ...newForCart])
        );
        // for cookie
        const tempCart = [...state.applications, ...newForCart];
        const tempCartForCookie: CartItemForCookieType[] = [];
        tempCart.forEach(elem => {
          const temp: CartItemForCookieType = {
            idOpen: elem.id,
            id: elem.realProposalId,
            name: elem.name,
            bankName: elem.bankName
          }
          tempCartForCookie.push(temp);
        })
        tokenService._setCookie(CookiesEnum.cart, JSON.stringify(tempCartForCookie), 1, "/")
      }
      return {
        ...state,
        applications: [...state.applications, ...newForCart],
      };

    }
    // case CartActionTypes.DELETE_FROM_CART: {
    //   const temp = state.applications.filter(
    //     (elem) => elem?.id !== action.payload?.id
    //   );
    //   if (typeof window !== "undefined") {
    //     window.localStorage.setItem(CookiesEnum.cart, JSON.stringify(temp));
    //     // for cookie
    //     const tempCartForCookie: CartItemForCookieType[] = [];
    //     temp.forEach(elem => {
    //       const tempItem: CartItemForCookieType = {
    //         idOpen: elem.id,
    //         id: elem?.realProposalId,
    //         name: elem.name,
    //         bankName: elem.bankName
    //       }
    //       tempCartForCookie.push(tempItem);
    //       console.log('from cart', tempItem, temp)
    //     })

    //     tokenService._setCookie(CookiesEnum.cart, JSON.stringify(tempCartForCookie), 1)
    //     console.log('from cart', tempCartForCookie)
    //   }
    //   return { ...state, applications: temp ?? [] };
    // }

    case CartActionTypes.DELETE_FROM_CART: {
      const temp = state.applications.filter(
        (elem) => elem?.id !== action.payload?.id
      );
      if (typeof window !== "undefined") {
        if (temp.length === 0) {
          window.localStorage.removeItem(CookiesEnum.cart);
          tokenService._removeCookie(CookiesEnum.cart);
          // console.log('Cart is now empty, cart removed from storage and cookies.');
        } else {
          window.localStorage.setItem(CookiesEnum.cart, JSON.stringify(temp));
          // console.log('Cart is NOT empty, LS updated.', temp);

          const cookieCart = tokenService._getCookie(CookiesEnum.cart)
          if (cookieCart) {
            // const tempCartFromCookieString = decodeURIComponent(cookieCart);
            // const tempCartFromCookie: CartItemForCookieType[] = JSON.parse(tempCartFromCookieString);
            // console.log("cart cokkie", tempCartFromCookie);
          }
          const tempCartForCookie = temp.map(elem => ({
            idOpen: elem.id,
            id: elem?.realProposalId,
            name: elem.name,
            bankName: elem.bankName
          }));
          tokenService._setCookie(CookiesEnum.cart, JSON.stringify(tempCartForCookie), 1, "/");
          // console.log('Cart is NOT empty,Updated cart in cookies', tempCartForCookie);
        }
      }
      return { ...state, applications: temp ?? [] };
    }

    case CartActionTypes.CLEAR_CART: {
      if (typeof window !== "undefined") {
        window.localStorage.removeItem(CookiesEnum.cart);
        tokenService._setCookie(CookiesEnum.cart, JSON.stringify([]), 1, "/")
        // tokenService._removeCookie(CookiesEnum.cart)
      }
      return { ...state, applications: [] };
    }

    case CartActionTypes.CHANGE_IS_OPEN_CART: {
      return { ...state, isOpenCart: action.payload };
    }

    default: {
      return state;
    }
  }
};
