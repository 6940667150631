import { COLORS } from "../../../core/consts/redesign/commonRedesign";
import { BasketIcon } from "../../../core/consts/redesign/icons/Basket";
import { PauseIcon } from "../../../core/consts/redesign/icons/PauseIcon";
import { TickIcon } from "../../../core/consts/redesign/icons/Tick";
import { useAppSelector } from "../../../core/store";
import {
  BankType,
  FieldType,
  PropsalData,
} from "../../new-order/types/bigForm";
import classes from "../NewOrderRedesign.module.scss";

export type StepType = {
  //PropsalData
  id: number;
  stepId: number;
  name: string;
  isFull: boolean;
  isCommon: boolean;
  banks: BankType[];
  fields: FieldType[];
  innerSteps: StepType[];
  proposals: { id: number[] };
};
type PropsType = {
  step: PropsalData | null;
  index: number;
  isCurrent: boolean;
  isPrevFull: boolean;
  // setCurrentStep: React.Dispatch<React.SetStateAction<PropsalData | null>>;
  setCurrentStep: (step: PropsalData) => void;
  deleteOrder: (id: number, bank: string, name: string) => void;
  isInner?: boolean; // подшаг лиА
  isFinish?: boolean;
  isFinishStep?: boolean;
  isHeaderForStep?: boolean; // заголовок для шага с подшагами
  isFooterForStep?: boolean; // для псевдо внутреннего последнего шага
};

const StepLine = ({
  step,
  index,
  isCurrent,
  isPrevFull,
  setCurrentStep,
  deleteOrder,
  isInner,
  isFinish,
  isFinishStep,
  isHeaderForStep,
  isFooterForStep,
}: PropsType) => {
  const { realProposalIds } = useAppSelector((state) => state.dataForOrder);
  const handleChangeCurrentStep = () => {
    // console.log("handleChangeCurrentStep step", step);
    step && setCurrentStep(step);
  };
  if (isFinish) {
    return (
      <div className={classes.step_line}>
        <div className={classes.step_line_in1}>
          <div className={classes.relative}>
            <div className={classes.full_round}>
              <TickIcon />
            </div>
          </div>
          <div style={{ color: COLORS.success }}> Заявка отправлена </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className={classes.step_line}
      onClick={() => {
        if (!isFinishStep) {
          if (!(isHeaderForStep === true)) {
            handleChangeCurrentStep();
          }
        }
      }}
      style={{
        paddingLeft: isFooterForStep ? "30px" : "",
        cursor: isFinishStep || isHeaderForStep ? "default" : "pointer"
      }}
    >
      <div className={classes.step_line_in1}
        style={{
          pointerEvents: isFinishStep || isHeaderForStep ? "none" : "auto",
          cursor: isFinishStep || isHeaderForStep ? "default" : "pointer"
        }}>
        {isHeaderForStep ? null : step?.isFill ? (
          <div className={classes.relative}>
            <div className={classes.full_round}>
              <TickIcon />
              {/* <div className={classes.stick} /> */}
              {/* {isPrevFull && <div className={classes.stick_up} />} */}
            </div>
          </div>
        ) : step?.isMissed ? (
          <div className={classes.relative}>
            <div className={classes.pause_round}>
              <PauseIcon />
            </div>
          </div>
        ) : isCurrent ? (
          <div className={classes.relative}>
            <div className={classes.current_round} />
            {/* {isPrevFull && <div className={classes.stick_up} />} */}
          </div>
        ) : (
          <div className={classes.empty_round} />
        )}

        <div className={classes.step_title} style={{ color: isCurrent ? COLORS.black : "" }}>
          {isFooterForStep ? "Информация для заявки" : `${step?.name}`}
        </div>
      </div>

      {!step?.isCommon && !isInner && !isFooterForStep && (
        <div className={classes.step_line_in1}>
          <div className={classes.logo_box}>
            <img src={step?.banks?.[0]?.icon} alt="bank icon" />
          </div>
          {/* <div className="spacer" /> */}
          {realProposalIds?.length > 1 && !isFinish && (
            <div
              className={classes.basket_box}
              style={{
                cursor: "pointer"
              }}
              onClick={(event) => {
                event.stopPropagation();
                if (step) {
                  deleteOrder(step.id, step.banks[0].name, step.name);
                }
              }}
            >
              <BasketIcon />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default StepLine;
