import { Location } from "history";
import { useEffect, useState } from "react";
import { Beforeunload } from "react-beforeunload";
import { Prompt, useHistory, useLocation } from "react-router-dom";
import ConfirmModalRedesign from "../../../../app/components/confirm-modal-redesign/ConfirmModalRedesign";
import { actions } from "../../../../core/store";

interface Props {
  when?: boolean | undefined;
  handleMoveToDraft?: (
    setModalVisible: (isModalVisible: boolean) => void,
    setConfirmedNavigation: (confirmedNavigation: boolean) => void
  ) => void;
}

const PromptModal = ({ when, handleMoveToDraft }: Props) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState<Location | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const history = useHistory();
  const location = useLocation();

  const closeModal = () => {
    setModalVisible(false);
    // setConfirmedNavigation(true);
    actions.setAlertModal({
      tittle: undefined,
      text: undefined,
      isError: false,
      confModal: false,
      draftModal: false,
      isRemain: false,
      isPromptShow: true
    });
  };

  const confirmModal = () => {
    setModalVisible(false);
    setConfirmedNavigation(true);
    actions.setAlertModal({
      tittle: undefined,
      text: undefined,
      isError: false,
      confModal: false,
      draftModal: false,
      isRemain: false,
      isPromptShow: true
    });
  };

  const shouldBlockNavigation = (nextLocation: Location) => {
    if (location.pathname !== nextLocation.pathname) {
      return true;
    } else {
      return false;
    }
  };

  const handleBlockedNavigation = (nextLocation: Location): boolean => {
    if (!confirmedNavigation && shouldBlockNavigation!(nextLocation)) {
      setModalVisible(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };

  const handleConfirmNavigationClick = () => {
    // handleMoveToDraft(setModalVisible, setConfirmedNavigation);
    confirmModal();
  };

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      history.push(lastLocation.pathname);
    }
  }, [confirmedNavigation, history, lastLocation]);

  return (
    <Beforeunload onBeforeunload={(event) => event.preventDefault()}>
      <Prompt when={when} message={handleBlockedNavigation} />
      <ConfirmModalRedesign
        title={"Покинуть заявку"}
        text="Вы действительно хотите закончить заполнение заявки? Мы можем сохранить заявку как черновик, чтобы в дальнейшем вы могли продолжить заполнение заявки"
        handleCancel={closeModal}
        handleConfirm={confirmModal}
        textButtonHandle={handleConfirmNavigationClick}
        cancelBtnText={"Вернуться к заполнению"}
        confBtnText={"Покинуть завку"}
        open={modalVisible}
        textTextBtn={"Сохранить как черновик"}
        setModalProps={setModalVisible}
      />
    </Beforeunload>
  );
};
export default PromptModal;
