import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import { Modal } from '@mui/material';
import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import IconButtonMain from "../../../UIcomponentsRedesign/IconButtonMain/IconButtonMain";
import SpinnerMain from "../../../UIcomponentsRedesign/SpinnerMain";
import { ChatByType, MessageStatusEnum, TypeChat, messageUploadingType } from "../../../core/api/chatsApi";
import { COLORS, ROLES } from "../../../core/consts/redesign/commonRedesign";
import { ArrowLeft, Close } from "../../../core/consts/redesign/icons";
import useIsMobile from "../../../core/hooks/useIsMobile";
import chatService from "../../../core/services/ChatsService";
import { actions } from "../../../core/store";
import { addMessage, removeMessage } from "../../../core/store/action-creators/currentChatsData";
import { rootState } from "../../../core/types/rootState";
import { downloadBlobFile } from "../../../core/utils/downloadBlobFile";
import AvatarNew from "../avatar-new/AvatarNew";
import ErrorModal from "../error-modal/ErrorModal";
import ChatInput from "./ChatInput";
import ChatMessages from "./ChatMessages";

/* chat modal */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      outline: 'none',
      position: "absolute",
      width: "600px",
      height: "calc(100% - 48px)",
      top: "47%",
      left: "calc(50% + 60px)",
      margin: "24px auto",
      transform: "translate(-50%, -50%)",
      backgroundColor: COLORS.white,
      border: "none",
      borderRadius: "8px",
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      [theme.breakpoints.down(1024)]: {
        left: "50%",
      },
      [theme.breakpoints.down(760)]: {
        width: "100%",
        height: "100%",
        left: 0,
        top: 0,
        transform: "translateX(0%)",
        margin: 0,
        borderRadius: 0
      }
    },
    titleBox: {
      position: 'relative',
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      padding: "12px 16px"
    },
    box: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: 'center',
      width: "calc(100% - 48px)",
      gap: "8px",
      marginRight: "24px",
      [theme.breakpoints.down(1024)]: {
        justifyContent: 'center',
        marginRight: "8px",
      },
    },
    closeButton: {
      width: "24px",
      height: "24px",
      padding: "3px",
      [theme.breakpoints.down(1024)]: {
        padding: "0px",
        position: 'absolute',
        left: "16px",
        top: "50%",
        transform: 'translateY(-50%)',
      },
    },
    chatTitle: {
      fontFamily: 'Gilroy',
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: '21px',
      color: COLORS.black,
      [theme.breakpoints.down(1024)]: {
        flex: 1,
        textAlign: 'center',
        marginLeft: '32px',
        whiteSpace: 'pre-wrap',
      },
    }
  })
);

type PropsType = {
  chatId?: number;
  open: boolean;
  chatWithManager?: boolean;
  archiveLead?: boolean;
  handleClose?: () => void;
  handleCancel?: () => void;
  handleConfirm?: () => void;
};

const ChatModalNew: React.FC<PropsType> = ({
  chatId,
  open,
  chatWithManager = false,
  handleClose,
  archiveLead
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const chat = useSelector((state: rootState) =>
    state.chats.find(chat => chat.chatId === chatId)
  );
  const chatData = useSelector((state: rootState) => state.currentChat);
  const currentChatId = chatData?.chatId;
  const initialUser = useSelector((state: rootState) => state.currentUser);
  const isManager = initialUser.role === ROLES.MANAGER;
  const [renew, setRenew] = useState<boolean>(true);
  const [uploadError, setUploadError] = useState<boolean>(false);
  const [unreadMessages, setUnreadMessages] = useState(0);
  const [errorModal, setErrorModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [abortController, setAbortController] = useState<AbortController | null>(null);

  const avatar = chat?.avatar_url ?? '';

  const chatTitle = chat?.typeChat === TypeChat.ManagerLead
    ? (isManager ? `Чат по заявке ${chat.leadId}` : `Личный менеджер Финлаб по заявке ${chat.leadId}`)
    : (chat?.typeChat === TypeChat.Bank ? `Менеджер ${chat.nameChat}`
      : chatWithManager
        ? `Чат с менеджером` : "");

  const isMobile = useIsMobile();
  useEffect(() => {
    const timer = setInterval(() => {
      setRenew(!renew);
    }, 2000);
    return () => clearInterval(timer);
  });

  useEffect(() => {
    const fetchChatData = async () => {
      try {
        let res: ChatByType | undefined = undefined;

        if (chatWithManager) { //for specific chat , different call
          res = await chatService.getMessagesListManagerChatByLeadIdService();
        } else if (chatId) { // for all simple chats call by id
          res = await chatService.getMessagesListByIdService(chatId.toString());
        }

        if (res) {
          const { nameChat, chatId, avatarUrl, leadId, typeChat, chatMessages } = res;
          dispatch(
            actions.setCurrentChatData({
              nameChat: nameChat,
              chatId: chatId,
              avatar_url: avatarUrl,
              leadId: leadId,
              chatType: typeChat,
              chatMessages: chatMessages ? chatMessages : []
            })
          );
          setUnreadMessages(
            chatMessages.filter((message) => {
              return !message.isMyMessage && !message.isRead;
            }).length
          );
        }
      } catch (error) {
        setErrorModal(true);
        // console.error(error);
      }
    };

    fetchChatData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [renew]);

  //Через 2 секунды после открытия устанавливает что сообщения прочитаны.
  useEffect(() => {
    setTimeout(() => {
      if (currentChatId && unreadMessages > 0) {
        readMessages(currentChatId);
        setUnreadMessages(0);
      }
    }, 2000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentChatId, unreadMessages]);

  const readMessages = (id: number) => {
    chatService
      .readIncomeMessagesByChatIdService({ chatId: id })
      .then((res) => {
        setRenew(!renew);
      })
      .catch((error) => {
        setErrorModal(true)
        console.error(error);
      });
  };

  const handleMessageSend = (message: string) => {
    const id = currentChatId || chatId
    if ((id && id > 0)) {
      const B = {
        chatId: id,
        text: message,
      };
      chatService
        .addMessageToChatService(B)
        .then((res: any) => {
          setRenew(!renew);
        })
        .catch((error) => {
          setErrorModal(true)
          console.error(error);
        });
    }
  };

  const downloadDoc = (id: string, name: string): void => {
    setIsLoading(true)
    chatService
      .getDocFromChatService(id)
      .then((res: any) => {
        downloadBlobFile(res, name);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false)
      });
  };

  const handleSelectFile = (event: any) => {
    if (currentChatId) {
      const stringChatID = currentChatId.toString();
      const abortController = new AbortController();
      setAbortController(abortController);
      const file = event.target.files[0];

      if (!file) {
        console.error('No file selected');
        setErrorModal(true)
        return;
      }

      const newMessage: messageUploadingType = {
        id: Date.now(),
        message: file.name,
        isMyMessage: true,
        dateMessage: new Date().toISOString(),
        documentId: 'uploading',
        status: MessageStatusEnum.SendUnRead,
        progress: 0,
        loadedMB: '0',
        totalMB: (file.size / 1024 / 1024).toFixed(2) + ' мб',
      };
      dispatch(addMessage(newMessage));
      chatService.uploadDocToChatService(stringChatID, file, (percentage, loadedMB, totalMB) => {
        const updatedMessage: messageUploadingType = {
          ...newMessage,
          progress: percentage,
          loadedMB,
          totalMB,
        };
        dispatch(actions.updateMessage(updatedMessage));
      }, abortController.signal)
        .then((res) => {
          setRenew(!renew);
          dispatch(removeMessage(newMessage.id));
        })
        .catch((error) => {
          if (error.message !== 'прервано') {
            setUploadError(true);
          } else {
            console.log('Request was aborted, no further action needed.');
          }
        })
    }
  };

  const handleCancelDocUpload = (messageId: number) => {
    if (abortController) {
      abortController.abort();
      dispatch(removeMessage(messageId));
    }
  };

  const handleCloseChat = () => {
    if (abortController) {
      abortController.abort();
    }
    handleClose && handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleCloseChat}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description">
      <div className={classes.paper}>
        <div className={classes.titleBox}>
          <div className={classes.box}>
            <IconButtonMain
              className={classes.closeButton}
              color='noBgBlack'
              aria-label="close"
              style={{ width: '24px' }}
              icon={isMobile ? <ArrowLeft color={COLORS.black} /> : <Close color={COLORS.black} />}
              handleClick={handleClose} />
            <div className={classes.chatTitle}>{chatTitle}</div>
          </div>
          <AvatarNew
            url={avatar}
            info={chatTitle}
          />
        </div>
        <div className="line_x" />
        <ChatMessages
          // key={v4()}
          downloadDoc={downloadDoc}
          uploadError={uploadError}
          handleCancel={handleCancelDocUpload}
        />
        {!archiveLead &&
          <ChatInput
            handleSelectFile={handleSelectFile}
            handleMessageSend={handleMessageSend}
          />
        }
        {isLoading &&
          <SpinnerMain fixedPosition />
        }
        {errorModal && <ErrorModal isOpen={errorModal} />}
      </div>
    </Modal>
  );
};

export default memo(ChatModalNew);
