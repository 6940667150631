import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import ArchiveFilteredProductsPage from "../../pages/archive-filtered-product/routing";
import ClientMessagesPage from "../../pages/client-messages/routing";
import ClientProductDetails from "../../pages/client-product-details/routing";
import ClientSupportChat from "../../pages/client-support-chat/routing";
import ManagerActsPage from "../../pages/manager-acts/routing";
import ManagerClientPage from "../../pages/manager-clients/routing";
import ManagerProfilePage from "../../pages/manager-profile/routing";
import NotFoundPage from "../../pages/not-found/routing";
import FilteredProductsPageNew from "../../pages/products-filtered-new/routing";
import { ROUTES } from "../consts/common";
import { rootState } from "../types/rootState";



const ManagerRoutes = () => {
  const match = useRouteMatch();
  const isAuth = useSelector((s: rootState) => s.currentUser.isAuth);

  return (
    <>
      <Helmet>
        <title>Finlab - кабинет менеджера</title>
        <meta
          name="description"
          content="Finlab.ru — финансовые услуги для бизнеса. Бесплатный подбор кредитов и 
                    лизинга для бизнеса, факторинг, банковские гарантии, РКО."
        />
      </Helmet>
      {isAuth ? (
        <Switch>
          <Route path={`${match.path}/profile`} exact>
            {ManagerProfilePage}
          </Route>
          <Route path={`${match.path}/products`} exact>
            {FilteredProductsPageNew}
          </Route>
          <Route path={`${match.path}/archive`} exact>
            {ArchiveFilteredProductsPage}
          </Route>
          <Route path={`${match.path}/product/:tab/:productId/:leadId`} exact>
            {ClientProductDetails}
          </Route>
          <Route path={`${match.path}/clients`} exact>
            {ManagerClientPage}
          </Route>
          <Route path={`${match.path}/acts`} exact>
            {ManagerActsPage}
          </Route>
          <Route path={`${match.path}/support`} exact>
            {ClientSupportChat}
          </Route>
          <Route path={`${match.path}/messages`} exact>
            {ClientMessagesPage}
          </Route>
          {NotFoundPage}
        </Switch>
      ) : (
        <Redirect to={ROUTES.MAIN} />
      )}
    </>
  );
};

export default <Route component={ManagerRoutes} path={ROUTES.MANAGER} />;
