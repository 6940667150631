import {
  Box,
  IconButton,
  Link,
  Theme,
  Tooltip,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import DoneOutlineOutlinedIcon from "@material-ui/icons/DoneOutlineOutlined";
import React, { memo, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { ROLES, ROUTES } from "../../../../core/consts/common";
import notificationsService from "../../../../core/services/NotificationService";
import { rootState } from "../../../../core/types/rootState";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    notificationWrap: {
      padding: theme.spacing(3),
      "&:not(:last-child)": {
        borderBottom: "solid 1px #D0D9DE",
      },
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "#F2F2F2",
      },
    },
    bankName: {
      padding: "5px 10px",
      borderRadius: "4px",
      backgroundColor: "#F2F2F2",
      maxWidth: "fit-content",
    },
    readMark: {
      position: "absolute",
      bottom: "0",
      right: "20px",
      zIndex: 25,
    },
    notificationText: {
      paddingTop: "15px",
      maxWidth: '90%',
      maxHeight: '80px',
      overflowY: 'auto',
    },
  })
);

type propsType = {
  propsalName: string;
  date: string;
  bankName: string;
  id: number;
  text: string;
  isRead: boolean;
  leadId: number;
  productId: number;
  actionId: string;
};

const Notification: React.FC<propsType> = (props) => {
  const classes = useStyles();
  const [isRead, setIsRead] = useState(false);
  const initialUser = useSelector((state: rootState) => state.currentUser);
  const history = useHistory();

  const handleRead = (id: number, route: boolean) => {
    setIsRead(true);
    notificationsService
      .readNotification(id.toString())
      .then((res) => {
        if (route && props.actionId !== "1001") {
          if (initialUser.role === ROLES.CLIENT) {
            history.push(
              `${ROUTES.CLIENT}/product/0/${props.productId}/${props.leadId}`
            );
          } else if (initialUser.role === ROLES.AGENT) {
            history.push(
              `${ROUTES.AGENT}/product/0/${props.productId}/${props.leadId}`
            );
          } else if (initialUser.role === ROLES.PARTNER) {
            history.push(
              `${ROUTES.PARTNER}/application/0/${props.leadId}`
            );
          } else if (initialUser.role === ROLES.MANAGER) {
            history.push(
              `${ROUTES.MANAGER}/product/0/${props.productId}/${props.leadId}`
            );
          } else if (initialUser.role === ROLES.ADMIN) {
            history.push(
              `${ROUTES.ADMIN}/product/0/${props.productId}/${props.leadId}`
            );
          }

        }

      })
      .catch((error) => {
        console.log(error);
      });
  };

  function linkText(text: string): string[] {
    return text.split(": ");
  }

  return (
    <Box style={{ position: "relative" }}>
      <Box
        className={classes.notificationWrap}
        onClick={() => props.actionId !== "1001" && handleRead(props.id, true)}
      >
        <Typography
          variant="h4"
          component="p"
          style={{
            fontSize: "16px",
            paddingBottom: "10px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {props.propsalName}
          <Typography
            variant="body2"
            component="span"
            style={{ color: "#B0BFC6" }}
          >
            {props.date}
          </Typography>
        </Typography>
        <Typography variant="body2" component="p" className={classes.bankName}>
          {props.bankName}
        </Typography>
        {props.actionId !== "1001" ? (
          <Typography
            variant="body1"
            component="p"
            className={classes.notificationText}
          >
            {props.text}
          </Typography>
        ) : (
          <Typography
            variant="body1"
            component="p"
            className={classes.notificationText}
          >
            {linkText(props.text)[0]}:{" "}
            <Link
              target="_blank"
              rel="noopener"
              variant="body1"
              href={`${linkText(props.text)[1]}`}
            >
              Перейти по ссылке
            </Link>
          </Typography>
        )}
      </Box>
      <IconButton
        className={classes.readMark}
        disabled={isRead || props.isRead}
        onClick={() => handleRead(props.id, false)}
      >
        <Tooltip
          title={`${isRead || props.isRead ? "Прочитано" : "Прочитать"}`}
          placement="top"
        >
          {/* {(props.isRead || isRead )? <DoubleApproveGreen/> : <DoubleApproveGrey/>} */}
          <DoneOutlineOutlinedIcon
            style={{
              color: `${isRead || props.isRead ? "#8AC44B" : "#B0BFC6"}`,
            }}
          />
        </Tooltip>
      </IconButton>
    </Box>
  );
};

export default memo(Notification);
