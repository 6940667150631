import { dateConverter } from "../helpers/dateConverter";
import { http } from "../infrastructure/Https";
import tokenService from "../services/TokenService";
import { article } from "../types/articlesTypes";

const ROOT_URL = '/api/v1/articles'

class ArticlesApi {

    getArticlesList(Q?: {
        category?: number,
        count?: number,
        offset?: number,
        Sort?: string,
        Statuses?: string,
        ImportantId?: number,
        IsActive?: boolean,
        IsImportant?: boolean,
        Tag?: number,
        Rubric?: number,
        Author?: number,
    }) {
        return http.get(`${ROOT_URL}/list`, Q)
            .then((res: any) => {
                return res;
            }).catch((err) => {
                console.log(err);
            })
    }
    getArticleById(
        id?: string,
        alias?: string,
    ): Promise<article> {
        return id ?
            http.get(`${ROOT_URL}/?id=${id}`) :
            http.get(`${ROOT_URL}/?alias=${alias}`)
    }
    getArticlesRubrics() {
        return http.get(`${ROOT_URL}/rubric`)
            .then((res) => { return res })
            .catch((err) => console.log(err))
    }
    getArticlesTags() {
        return http.get(`${ROOT_URL}/tag`)
            .then((res) => { return res })
            .catch((err) => console.log(err))
    }
    getArticlesAuthors() {
        return http.get(`${ROOT_URL}/authors`)
            .then((res) => { return res })
            .catch((err) => console.log(err))
    }
    putArticleView(id: string) {
        return http.put(`${ROOT_URL}/view`, null, { 'id': id })
            .then((res) => { return res })
            .catch((err) => console.log(err))
    }
    putArticleRate(rate: { id: string, rate: string }) {
        return http.put(`${ROOT_URL}/rate`, null, rate)
            .then((res) => { return res })
            .catch((err) => console.log(err))
    }
    deleteArticleById(id: string) {
        const accessToken = tokenService.getAccessToken();
        return http.delete(`${ROOT_URL}/${id}`, null, null, accessToken)
            .then((res) => { return res })
            .catch((err) => console.log(err))
    }
    putPartData(id: number, isActive?: boolean, isImportant?: boolean) {
        const accessToken = tokenService.getAccessToken();
        return http.put(`${ROOT_URL}/partput`, null, { id, isActive, isImportant }, accessToken)
    }
    newArticle(data: any) {
        const accessToken = tokenService.getAccessToken();
        const formData = new FormData();
        formData.append('Title', data.title)
        formData.append('ShortText', data.shortText)
        formData.append('Text', data.text)
        if (data.thumbnail) formData.append('Thumbnail', data.thumbnail)
        // if (data.thumbnailText) formData.append('ThumbnailText', data.thumbnailText)
        formData.append('ShortText', data.shortText)
        formData.append('IsActive', data.isActive)
        // formData.append('SourceUrl', data.sourceUrl)
        // formData.append('SourceText', data.sourceText)
        formData.append('CreateDate', dateConverter(data.createDate).split('.').reverse().join('-'))
        if (data.editDate) formData.append('EditDate', dateConverter(data.editDate).split('.').reverse().join('-'))
        if (data.imageFile) formData.append('Image', data.imageFile)
        if (data.imageId) formData.append('imageId', data.imageId)
        formData.append('PostTelegram', data.postTelegram)
        // formData.append('SourceUrl', data.sourceUrl)
        formData.append('isImportant', data.isImportant)
        formData.append('seoTitle', data.seoTitle)
        formData.append('SeoDescription', data.seoDescription)
        formData.append('AuthorId', data.authorId)
        if (data.articlesTagsId.length !== 0) data.articlesTagsId.forEach((item: any) => formData.append("ArticlesTagsId", item))
        if (data.articlesRubricId.length !== 0) data.articlesRubricId.forEach((item: any) => formData.append("ArticlesRubricId", item))
        return http.post(`${ROOT_URL}`, null, formData, accessToken)
    };

    editArticle(data: any, id: string) {
        const accessToken = tokenService.getAccessToken();
        const formData = new FormData();
        formData.append('id', id)
        formData.append('Title', data.title)
        formData.append('ShortText', data.shortText)
        formData.append('Text', data.text)
        if (data.thumbnail) formData.append('Thumbnail', data.thumbnail)
        // if (data.thumbnailText) formData.append('ThumbnailText', data.thumbnailText)
        formData.append('ShortText', data.shortText)
        formData.append('IsActive', data.isActive)
        // formData.append('SourceUrl', data.sourceUrl)
        // formData.append('SourceText', data.sourceText)
        formData.append('CreateDate', dateConverter(data.createDate).split('.').reverse().join('-'))
        if (data.editDate) formData.append('EditDate', dateConverter(data.editDate).split('.').reverse().join('-'))
        if (data.imageFile) formData.append('Image', data.imageFile)
        if (data.imageId) formData.append('imageId', data.imageId)
        formData.append('PostTelegram', data.postTelegram)
        // formData.append('SourceUrl', data.sourceUrl)
        formData.append('isImportant', data.isImportant)
        formData.append('seoTitle', data.seoTitle)
        formData.append('SeoDescription', data.seoDescription)
        formData.append('AuthorId', data.authorId)
        if (data.articlesTagsId.length !== 0) data.articlesTagsId.forEach((item: any) => formData.append("ArticlesTagsId", item))
        if (data.articlesRubricId.length !== 0) data.articlesRubricId.forEach((item: any) => formData.append("ArticlesRubricId", item))
        return http.put(`${ROOT_URL}`, null, formData, accessToken)
    };
}

const articlesApi = new ArticlesApi();
export default articlesApi;