import * as React from "react";
import { checkIsToday, dateConverter, timeConverter } from "../../../core/helpers/dateConverter";
import AvatarNew from "../avatar-new/AvatarNew";
import classes from "./ChatPreview.module.scss";

type IProps = {
  avatarUrl?: string;
  title: string;
  lastMessageText?: string;
  lastMessageTime?: string;
  unreadCount?: number;
  handleChatClick?: () => void;
};

const ChatPreview: React.FC<IProps> = ({
  avatarUrl,
  title,
  lastMessageText,
  lastMessageTime,
  unreadCount,
  handleChatClick
}) => {

  return (
    <div className={classes.container} onClick={handleChatClick}>
      <AvatarNew
        info={title}
        url={avatarUrl}
      />
      <div className={classes.columnBox}>
        <div className={classes.rowBox}>
          <h4 className={classes.title}>
            {title}
          </h4>
          <span className={classes.grayText}>
            {lastMessageTime &&
              (checkIsToday(lastMessageTime)
                ? timeConverter(lastMessageTime)
                : dateConverter(lastMessageTime))}
          </span>
        </div>
        <div className={classes.rowBox}>
          <div className={classes.grayText}>
            {lastMessageText}
          </div>
          {unreadCount && unreadCount > 0 ?
            <div className="round_blue_small">
              {unreadCount}
            </div> : ''
          }
        </div>
      </div>
    </div>
  );
};

export default ChatPreview;
