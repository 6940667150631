import { Typography, makeStyles } from '@material-ui/core';
import { useEffect, useRef, useState } from 'react';
import IconButtonMain from '../../../UIcomponentsRedesign/IconButtonMain/IconButtonMain';
import { COLORS } from '../../../core/consts/redesign/commonRedesign';
import { Info } from '../../../core/consts/redesign/icons';
import useIsMobile from '../../../core/hooks/useIsMobile';

const useStyles = makeStyles((theme) => ({
  infoContainer: {
    position: 'relative',
  },
  iconButton: {
    width: "40px",
    height: "40px",
    padding: "0px",
    margin: 0,
    '& svg path': {
      fill: COLORS.gray,
    },
  },
  iconButtonActive: {
    '& svg path': {
      fill: COLORS.brand_blue,
    },
  },
  customPopover: {
    boxSizing: 'border-box',
    position: 'absolute',
    width: '400px',
    maxHeight: '440px',
    borderRadius: '8px 0px 8px 8px',
    border: `1px solid ${COLORS.light_gray_stroke}`,
    overflow: 'auto',

    background: COLORS.white,
    top: '35px',
    left: '-180px',
    transform: 'translateX(-50%)',
    zIndex: 300,
    display: 'none',
    transition: 'max-height 0.3s ease',
  },
  customPopoverMob: {
    boxSizing: 'border-box',
    background: COLORS.white,
    zIndex: 300,
    padding: '16px 8px',
    borderRadius: '20px 20px 0 0',
    display: 'block',
    transition: 'all 0.3s ease',
    position: 'fixed',
    margin: 0,
    bottom: 0,
    left: 0,
    transform: 'none',
    width: '100%',
    height: 'auto',
    maxHeight: 'calc(100% - 72px)'
  },
  popoverContent: {
    maxWidth: "100%",
    background: COLORS.white,
    color: COLORS.black,
    [theme.breakpoints.down(1024)]: {
      // maxHeight: 'none',
    },
  },

  titleContainer: {
    padding: '16px 8px 8px',
    margin: 0,
    top: 0,
    zIndex: 2,
    paddingBottom: '8px',
    position: 'sticky',
    backgroundColor: COLORS.white,
    [theme.breakpoints.down(1024)]: {
      padding: '0',
    },
  },
  stickyTitle: {
    fontSize: '18px',
    lineHeight: '21px',
    textTransform: "none",
    fontWeight: 600,
  },
  scrollableContent: {
    padding: '0 8px 16px',
    [theme.breakpoints.down(1024)]: {
      padding: '0',
    },
  },
  content: {
    fontSize: '16px',
    lineHeight: '21px',
    fontWeight: 500,
    textTransform: "none",
    [theme.breakpoints.down(1024)]: {
      padding: '0',
    },
  },
  showMore: {
    color: COLORS.brand_blue,
    cursor: 'pointer',
  },
  showPopover: {
    display: 'block',
  },
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'transparent',
    zIndex: 200,
  },
  [theme.breakpoints.down(1024)]: {
    overlay: {
      backgroundColor: COLORS.black,
      opacity: 0.6,
    },
  },
}));

interface InfoPopoverProps {
  text: string;
  id: string;
  info: string;
}

const InfoPopover: React.FC<InfoPopoverProps> = ({ id, text, info }) => {
  const classes = useStyles();
  const [showPopover, setShowPopover] = useState(false);
  const popoverRef = useRef<HTMLDivElement | null>(null);
  const [isTruncated, setIsTruncated] = useState(false);
  const [displayText, setDisplayText] = useState(info);
  const [showMore, setShowMore] = useState(false);
  const textRef = useRef<HTMLDivElement | null>(null);
  const isMobile = useIsMobile();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (popoverRef.current && !popoverRef.current.contains(event.target as Node)) {
        setShowPopover(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    if (textRef.current) {
      let lineHeight = parseInt(window.getComputedStyle(textRef.current).lineHeight, 10);
      if (isNaN(lineHeight)) {
        const fontSize = parseInt(window.getComputedStyle(textRef.current).fontSize, 10);
        lineHeight = fontSize * 1.2;
      }
      const maxHeight = 8 * lineHeight;
      const isTextOverflowing = textRef.current.scrollHeight > maxHeight;
      setIsTruncated(isTextOverflowing);
      if (!showMore && isTextOverflowing) {
        const approxChars = maxHeight / lineHeight * (textRef.current.clientWidth / (lineHeight * 0.6));
        setDisplayText(`${info.substring(0, approxChars - 3)}...`);
      } else {
        setDisplayText(info);
      }
    }
  }, [showPopover, showMore, text, info]);

  const togglePopover = () => {
    setShowPopover(!showPopover);
    if (showMore) {
      setShowMore(false);
    }
  };

  const toggleTextDisplay = () => {
    setShowMore(!showMore);
  };

  return (
    <div
      className={classes.infoContainer}
      id={id}>
      <IconButtonMain
        color="noBgGray"
        className={`${classes.iconButton} ${showPopover ? classes.iconButtonActive : ''}`}
        handleClick={togglePopover}
        icon={<Info />}
      />
      {showPopover && (
        (isMobile ?
          (
            <>
              <div className={`${classes.customPopoverMob} ${showPopover ? classes.showPopover : ''}`}
                ref={popoverRef}>
                <div className={classes.popoverContent}>
                  <div className={classes.titleContainer}>
                    <h3 className={classes.stickyTitle}>{text}</h3>
                  </div>
                  <div className={classes.content} ref={textRef}>
                    {displayText}
                    {isTruncated && (
                      <Typography
                        className={classes.showMore}
                        onClick={toggleTextDisplay}
                      >
                        {showMore ? '' : 'подробнее'}
                      </Typography>
                    )}
                  </div>
                </div>
              </div>
              <div className={classes.overlay} onClick={togglePopover}></div>
            </>
          ) : (
            <>
              <div className={`${classes.customPopover} ${showPopover ? classes.showPopover : ''}`}
                ref={popoverRef}
              >
                <div className={classes.popoverContent}>
                  <div className={classes.titleContainer}>
                    <h3 className={classes.stickyTitle}>{text}</h3>
                  </div>
                  <div className={classes.scrollableContent} ref={textRef}>
                    <span
                      className={classes.content}>
                      {info}
                    </span>

                  </div>
                </div>
              </div>
              <div className={classes.overlay} onClick={togglePopover}></div>
            </>
          ))
      )}
    </div>
  );
};

export default InfoPopover;
