import { Theme, createStyles, makeStyles } from "@material-ui/core"

export const useStyles = makeStyles((theme: Theme) => createStyles({
    container: {
        padding: '40px 80px',
        margin: '0 auto',
        boxSizing: 'border-box',
        maxWidth: '100%',
    },
    pageWrap: {
        width: '100%',
        display: 'flex',
        padding: theme.spacing(3),
    },
    paper: {
        borderRadius: '10px',
        flex: 'auto',
        overflowY: 'auto',
        position: 'relative',
        scrollbarWidth: 'thin',
        '&::-webkit-scrollbar': {
            width: '0.5em',
            height: '0.2em',
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(208 217 222 / 88%)',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#D0D9DE',
            outline: '0.2px solid #D0D9DE',
        },
    },
    searchContainer: {
        position: 'sticky',
        top: 0,
        zIndex: 5,
        height: '85px'
    },
    search: {
        backgroundColor: '#ffffff',
        width: '100%!important',
        margin: '0!important',
        '& div::before': {
            display: 'none',
        },
        '& div::after': {
            display: 'none',
        },
        '& input': {
            padding: '25px 25px 15px 15px',
        },
        '& label': {
            padding: '25px 15px',
        },
        boxSizing: 'border-box'
    },
    notificationsBox: {
        height: '80%',
        borderRadius: '10px',
    },
}))