import { AgentActsData, AgentLeadsData } from './../types/agentTypes';
import { QueryParamsGetLeads } from '../types/leadsApiTypes'
import { http } from "../infrastructure/Https";
import tokenService from "../services/TokenService";

const ROOT_URL = '/api/v1/admin'


class AdminApi {
    getAdminApplicationList(bank:string, product:string, status: any, sortByBank:string) {
        const accessToken = tokenService.getAccessToken();
        const params = new URLSearchParams();
        if (bank !== 'all' && bank){
            params.append("BankId", bank);
        }
            status.forEach((element: any) => {
                params.append("Statuses", element.key);
            });
        if (product !== 'all'){
            params.append("ProductId", product);
        }
        if (sortByBank){
            params.append("Sort", sortByBank);
        }

        return http.get(`${ROOT_URL}/proposals/getProposals`, params, accessToken)
    }
    getAdminFiltersList() {
        const accessToken = tokenService.getAccessToken()
        return http.get(`${ROOT_URL}/proposals/getFilters`, undefined, accessToken)
    }
    putAdminUpdateProposal(id:number, isActive?:boolean, showOnMainPage?:boolean ) {
        const data = {
            proposalId: id,
            isActive,
            showOnMainPage
        }
        const accessToken = tokenService.getAccessToken()
        return http.put(`${ROOT_URL}/proposals/fastUpdateProposal`, null, data, accessToken)
    }
}

const adminApi = new AdminApi()
export default adminApi
