import React from "react";
import { useSelector } from "react-redux";
import { actions } from "../../../core/store";
import * as selectors from "../../../core/store/selectors";
import { rootState } from "../../../core/types/rootState";
import { Field, FieldType } from "../types/bigForm";

type RequiredProps = {
  field: FieldType;
  propsalId: number;
  parentName: string | undefined;
  indexGroupField?: number;
};

type Props<T> = T & RequiredProps;

const withFormDataModal = <T extends Object>(Component: React.FC<T>) => {
  return (props: Props<T>) => {
    const value = useSelector((state: rootState) =>
      // selectors.currentModalFieldValue(state, { fieldName: props.field.name, modalIndex: 0 })
      selectors.currentModalFieldValue(state, {
        fieldName: props.field.name,
        modalIndex: props.indexGroupField,
      })
    );
    const error = useSelector((state: rootState) =>
      // selectors.currentModalFieldError(state, { fieldName: props.field.name })
      selectors.currentModalFieldError(state, {
        fieldName: props.field.name,
        modalIndex: props.indexGroupField,
      })
    );

    // if (
    //   props.parentName === "GeneralDirector" &&
    //   props.field.name === "GDFullName"
    // ) {
    //   console.log("withFormDataModal props.field.name", props.field.name);
    //   console.log("withFormDataModal value", value);
    // }

    const onChange = (
      fieldName: string,
      value: Field,
      propsalId: number,
      parentName?: string,
      indexGroupField?: number
    ) => {
      actions.setValueFieldCurrentModal({
        fieldName,
        value,
        modalIndex: indexGroupField,
      });
    };
    if (!props.parentName) {
      return <Component {...props} />;
    }
    return (
      <Component {...props} value={value} error={error} onChange={onChange} />
    );
  };
};

export default withFormDataModal;
