import {
  Button,
  Checkbox,
  Dialog,
  FormControlLabel,
  IconButton,
  TextField,
  Theme,
  Typography,
  createStyles,
  makeStyles
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import React, { ChangeEvent, memo, useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { useSelector } from "react-redux";
import { ROLES } from "../../../core/consts/common";
import { DocIcon } from "../../../core/consts/images/DocIcon";
import feedbakcService from "../../../core/services/FeedbackService";
import { rootState } from "../../../core/types/rootState";
import Preloader from "../preloader/Preloader";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      maxWidth: "100%",
      backgroundColor: "transparent",
    },
    wrapper: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
    },
    container: {
      position: "relative",
      display: "block",
      backgroundColor: theme.palette.background.paper,
      borderRadius: "10px",
      width: "780px",
      border: "none",
      padding: "50px 80px 50px 75px",
    },
    containerModal: {
      position: "relative",
      display: "block",
      background: "linear-gradient(114.44deg, #942BD5 0%, #B239CC 100%)",
      width: "780px",
      border: "none",
      padding: "50px 80px 50px 75px",
    },
    choose: {
      textDecoration: "none",
      padding: "25px 0",
      color: "#306F9A",
      "&:hover": {
        cursor: "pointer",
      },
    },
    upload: {
      position: "absolute",
      width: "100%",
      height: "100%",
      opacity: 0,
      left: 0,
      "&::-webkit-file-upload-button": { cursor: "pointer" },
      "&:hover": {
        cursor: "pointer",
      },
    },
    box: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "baseline",
      width: "100%",
    },
    text: {
      padding: "0 0 50px 0",
      margin: "0",
      boxSizing: "border-box",
    },
    document: {
      color: "#306F9A",
      display: "flex",
      alignItems: "center",
      "&:hover": {
        color: "#204A66",
      },
    },
    loadBtn: {
      position: "relative",
      color: "#306F9A",
      fontFamily: "Gilroy",
      fontWeight: 500,
      fontSize: "16px",
      cursor: "pointer",
    },
    documentEllipsis: {
      overflow: "hidden",
      maxWidth: "230px",
      textOverflow: "ellipsis ellipsis",
      display: "inline-block",
      whiteSpace: "nowrap",
    },
    input: {
      width: "300px",
      marginTop: "25px",
      "& label": {
        color: "#828282",
      },
    },
    button: {
      marginTop: "25px",
      width: "300px",
    },
  })
);

type propsType = {
  isOpen: boolean;
  setOpenModal: any;
  text: string;
  handleCloseChatWindowModal?: () => void;
};

const SupportModal: React.FC<propsType> = (props) => {
  const classes = useStyles();
  const [isSent, setIsSent] = useState(false);
  const [modalData, setModalData] = useState<null | any>(null);
  const [loadedFiles, setFiles] = useState<any[]>([]);
  const [isFileNeeded, setFilesNeeded] = useState(false);
  const [allFilesSize, setFilesSize] = useState(0);
  const [modalError, setModalError] = useState<null | any>({
    phone: undefined,
    title: undefined,
    text: undefined,
    email: undefined,
    inn: undefined,
  });
  const { inn, phone, role, email } = useSelector(
    (state: rootState) => state.currentUser
  );

  useEffect(() => {
    setModalError({
      phone: undefined,
      title: undefined,
      text: undefined,
      email: undefined,
      inn: undefined,
    });
    setFilesNeeded(false);
    setFiles([]);
  }, [props.isOpen]);

  useEffect(() => {
    if (role !== ROLES.MANAGER) {
      setModalData((state: any) => ({
        ...state,
        inn: inn,
        phone: phone,
        email: email,
        leadId: "",
        title: "",
        text: "",
      }));
    } else {
      setModalData((state: any) => ({
        ...state,
        inn: "",
        phone: "",
        email: "",
        leadId: "",
        title: "",
        text: "",
      }));
    }
  }, [role, inn, phone, props.isOpen]);

  const handleClose = () => {
    props.setOpenModal(false);
    if (props.handleCloseChatWindowModal) props.handleCloseChatWindowModal();
  };

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    setModalData((state: any) => ({ ...state, [name]: value }));
    setModalError((state: any) => ({ ...state, [name]: undefined }));
  };

  const validation = () => {
    if (
      !modalData.phone.match(
        /(\+7|8)[\s(]*\d{3}[)\s]*\d{3}[\s-]?\d{2}[\s-]?\d{2}/
      )
    ) {
      setModalError((state: any) => ({
        ...state,
        phone: "Неверный формат телефона",
      }));
    }

    if (modalData.phone === "") {
      setModalError((state: any) => ({ ...state, phone: "Обязательное поле" }));
    }
    if (modalData.text === "") {
      setModalError((state: any) => ({
        ...state,
        text: "Обязательное поле",
      }));
    }

    if (
      modalData.inn.length !== 0 &&
      (modalData.inn.length > 12 || modalData.inn.length < 10)
    ) {
      setModalError((state: any) => ({
        ...state,
        inn: "ИНН состоит из 10-12 цифр",
      }));
    }
    if (modalData.title === "") {
      setModalError((state: any) => ({
        ...state,
        title: "Обязательное поле",
      }));
    }

    if (
      modalData.email.length !== 0 &&
      !modalData.email.match(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/)
    ) {
      setModalError((state: any) => ({
        ...state,
        email: "Неверный формат почты",
      }));
    }
  };

  const [isFetching, setFetching] = useState(false);

  const handleModalSubmit = (event: any) => {
    validation();
    event.preventDefault();
    if (
      allFilesSize < 5242880 * 5 &&
      modalData.phone &&
      modalData.title &&
      modalData.text &&
      (modalData.email.length === 0 ||
        modalData.email.match(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/)) &&
      (modalData.inn.length === 0 ||
        (modalData.inn.length <= 12 && modalData.inn.length >= 10)) &&
      modalData.phone.match(
        /(\+7|8)[\s(]*\d{3}[)\s]*\d{3}[\s-]?\d{2}[\s-]?\d{2}/
      )
    ) {
      setFetching(true)
      feedbakcService
        .postFeedbackService(modalData, isFileNeeded, loadedFiles)
        .then((res: any) => {
          setFetching(false)
          setIsSent(true);
          setTimeout(() => {
            props.setOpenModal(false);
            if (props.handleCloseChatWindowModal) props.handleCloseChatWindowModal();
            setIsSent(false);
          }, 2000);
        })
        .catch((err: any) => {
          setFetching(false)
          console.log(err);
        });
    }
  };

  const handleDeleteFile = (keyDelete: number) => {
    const currentFile = loadedFiles.find((el, key) => key === keyDelete)
    setFilesSize(state => state - currentFile.size)
    setFiles((state: any) => [
      ...state.filter((el: any, key: number) => key !== keyDelete),
    ]);
  };

  const handleChangeFile = (e: any) => {
    const files = e.currentTarget.files;
    for (let i = 0; i < files.length; i++) {
      setFilesSize((state) => state + files[i].size)
      setFiles((state: any) => [...state, files[i]]);
    }
  };

  const handleLongName = (docName: string): string[] => {
    let tmpArr = docName.split(".");
    let first = tmpArr.slice(0, tmpArr.length - 1).join(".");
    let second = tmpArr.slice(tmpArr.length - 1).join("");
    return [first, second];
  };
  return (
    <Dialog
      open={props.isOpen}
      scroll="body"
      onClose={handleClose}
      maxWidth={false}
      className={classes.dialog}
    >
      <div className={classes.wrapper}>
        {isFetching ? <div className={classes.container}> <Preloader preloaderType="block" /> </div> : isSent ? (
          <div
            className={classes.containerModal}
            style={{
              background:
                "linear-gradient(114.44deg, #942BD5 0%, #B239CC 100%)",
            }}
          >
            <div style={{ width: "80%" }}>
              <div className={classes.box}>
                <Typography
                  variant="body1"
                  component="h3"
                  style={{ color: "#fff", padding: "0 0 25px 0" }}
                >
                  Заявка отправлена
                </Typography>
                <IconButton
                  style={{ position: "absolute", top: "33px", right: "50px" }}
                  aria-label="close"
                  onClick={handleClose}
                >
                  <CloseIcon style={{ color: "#fff" }} />
                </IconButton>
              </div>
              <Typography
                variant="body2"
                component="p"
                style={{ color: "#fff" }}
              >
                Ваша заявка успешно отправлена, мы свяжемся с вами в ближайшее
                время!
              </Typography>
            </div>
          </div>
        ) : (
          <div className={classes.container}>
            <div style={{ width: "90%" }}>
              <div className={classes.box}>
                <Typography
                  variant="h3"
                  component="h3"
                  style={{ padding: "0 0 25px 0", fontSize: "32px" }}
                >
                  Обратная связь
                </Typography>
                <IconButton
                  style={{ position: "absolute", top: "33px", right: "50px" }}
                  aria-label="close"
                  onClick={handleClose}
                >
                  <CloseIcon />
                </IconButton>
              </div>
              <Typography
                variant="body1"
                component="p"
                className={classes.text}
              >
                {props.text}
              </Typography>
              {modalData && (
                <form onSubmit={handleModalSubmit} noValidate>
                  <NumberFormat
                    className={classes.input}
                    format="+7 (###) ###-##-##"
                    customInput={TextField}
                    label={"Ваш номер телефона"}
                    disabled={role !== ROLES.MANAGER ? true : false}
                    onChange={handleChangeInput}
                    required
                    value={modalData.phone}
                    error={!!modalError.phone}
                    inputMode="tel"
                    color="secondary"
                    name="phone"
                    type={"tel"}
                    helperText={modalError.phone}
                  />
                  <TextField
                    className={classes.input}
                    onChange={handleChangeInput}
                    disabled={role !== ROLES.MANAGER ? true : false}
                    label={"Электронная почта"}
                    value={modalData.email}
                    error={!!modalError.email}
                    color="secondary"
                    name="email"
                    type={"email"}
                    helperText={modalError.email}
                  />
                  <NumberFormat
                    className={classes.input}
                    customInput={TextField}
                    disabled={role !== ROLES.MANAGER ? true : false}
                    label={"ИНН"}
                    onChange={handleChangeInput}
                    value={modalData.inn}
                    error={!!modalError.inn}
                    helperText={modalError.inn}
                    color="secondary"
                    name="inn"
                  />
                  <NumberFormat
                    className={classes.input}
                    label={"Номер заявки"}
                    value={modalData.leadId}
                    onChange={handleChangeInput}
                    customInput={TextField}
                    color="secondary"
                    name="leadId"
                  />
                  <TextField
                    className={classes.input}
                    label={"Краткое описание"}
                    onChange={handleChangeInput}
                    value={modalData.title}
                    required
                    error={!!modalError.title}
                    color="secondary"
                    name="title"
                    helperText={modalError.title}
                  />
                  <TextField
                    name="text"
                    className={classes.input}
                    style={{ width: "100%" }}
                    label={"Полное описание"}
                    color="secondary"
                    type={"textarea"}
                    value={modalData.text}
                    onChange={handleChangeInput}
                    required
                    error={!!modalError.text}
                    multiline={true}
                    maxRows={10}
                    minRows={3}
                    helperText={modalError.text}
                  />
                  <div style={{ marginTop: "27px" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isFileNeeded}
                          onChange={(e) =>
                            setFilesNeeded(e.currentTarget.checked)
                          }
                          color="primary"
                        />
                      }
                      label={
                        <Typography style={{ fontSize: "12px" }}>
                          Прикрепить документы
                        </Typography>
                      }
                    />
                  </div>
                  {isFileNeeded && (
                    <div
                      style={{
                        marginTop: "30px",
                        borderTop: "1px solid #D0D9DE",
                        borderBottom: "1px solid #D0D9DE",
                        padding: "7px 0 30px 0",
                      }}
                    >
                      <div>
                        {loadedFiles.map((el: any, key: number) => {
                          return (
                            <div
                              style={{
                                display: "flex",
                                marginTop: "23px",
                                justifyContent: "space-between",
                              }}
                              key={el.key}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {" "}
                                <DocIcon />{" "}
                                <div
                                  style={{
                                    marginLeft: "14px",
                                    color: "#306F9A",
                                    fontFamily: "Gilroy",
                                    fontWeight: 500,
                                    fontSize: "16px",
                                  }}
                                >
                                  <span className={classes.document}>
                                    <span className={classes.documentEllipsis}>
                                      {handleLongName(el.name)[0]}
                                    </span>
                                    .{handleLongName(el.name)[1]}
                                  </span>
                                </div>
                              </div>
                              <div
                                style={{
                                  color: "#D62D30",
                                  fontFamily: "Gilroy",
                                  fontWeight: 500,
                                  fontSize: "16px",
                                  cursor: "pointer",
                                }}
                                onClick={() => handleDeleteFile(key)}
                              >
                                Удалить
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          marginTop: "23px",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <DocIcon />
                          <div
                            style={{
                              marginLeft: "14px",
                              color: "#B0BFC6",
                              fontFamily: "Gilroy",
                              fontWeight: 500,
                              fontSize: "16px",
                            }}
                          >
                            Документ не выбран
                          </div>
                        </div>
                        <div className={classes.loadBtn}>
                          Загрузить
                          <input
                            type="file"
                            className={classes.upload}
                            multiple
                            onChange={handleChangeFile}
                            id="upload"
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          marginTop: "17px",
                          color: "#B0BFC6",
                          fontFamily: "Gilroy",
                          fontWeight: 500,
                          fontSize: "16px",
                        }}
                      >
                        Размер загружаемых документов не должен привышать 20 мб.
                      </div>
                    </div>
                  )}
                  {allFilesSize > 5242880 * 5 && <div style={{ color: '#D62D30', fontSize: '16px', marginTop: '8px', fontFamily: 'Gotham Pro' }}>Превышен размер вложения</div>}
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    className={classes.button}
                    disabled={
                      !modalData.phone || !modalData.title || !modalData.text
                        ? true
                        : false
                    }
                  >
                    <Typography
                      variant="body1"
                      component="span"
                      style={{
                        color: "#ffffff",
                        textTransform: "none",
                        lineHeight: "2",
                      }}
                    >
                      Отправить
                    </Typography>
                  </Button>
                </form>
              )}
            </div>
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default memo(SupportModal);
