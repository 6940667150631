import { Theme, createStyles, makeStyles } from "@material-ui/core";

/* Status cloud */
const useStyles = makeStyles((theme: Theme) => createStyles({
  statusCloud: {
    borderRadius: "2px",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "21px",
    width: "fit-content",
    padding: "0 8px 2px",
    textAlign: "center",
  }
}));

type StatusCloudProps = {
  id?: string;
  statusBg?: string | null | undefined;
  statusTextColor?: string | null | undefined;
  status: string | null | undefined;
  statusBgRgba?: string | null;
  statusTextColorRgba?: string | null;
  lowercase?: boolean;
}
const StatusCloud: React.FC<StatusCloudProps> = ({
  id,
  statusBg,
  statusTextColor,
  statusBgRgba,
  statusTextColorRgba,
  status,
  lowercase

}) => {
  const classes = useStyles();

  return (
    <div
      id={id}
      className={classes.statusCloud}
      style={{
        textTransform: lowercase ? "lowercase" : "none",
        background: statusBgRgba
          ? statusBgRgba
          : (statusBg
            ? `#${statusBg}`
            : "#70B2E2"),
        color: statusTextColorRgba
          ? statusTextColorRgba
          : (statusTextColor
            ? `#${statusTextColor}`
            : "white"),
      }}>
      {status}
    </div>
  );
};
export default (StatusCloud)