export const ArrowUpIcon = ({ color }: { color?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.19953 13.3308C6.45829 13.6542 6.93026 13.7067 7.25371 13.4479L11.9976 9.6528L16.7415 13.4479C17.065 13.7067 17.5369 13.6542 17.7957 13.3308C18.0545 13.0074 18.002 12.5354 17.6786 12.2766L12.4661 8.10668C12.1922 7.88755 11.803 7.88755 11.5291 8.10668L6.31666 12.2766C5.99322 12.5354 5.94078 13.0074 6.19953 13.3308Z"
      fill={color ? color : "gray2"}
    />
  </svg>
);
