import {ClientType} from '../../app/components/registration/RegistrationDetails';
import {http} from '../infrastructure/Https';

const ROOT_URL = '/api/v1';
export type RegistrationResponseType = {
    id: string
}

export type RegistrationFirstType = {
    isPhoneCheck:boolean,
    phone: string,
    role: ClientType | undefined,
}

export type RegistrationAgentType = {
    phone: string,
    nalogs: string,
    FactAddress: string,
    PostAddress: string,
    account: string,
    isUI: boolean,
    TaxType: string,
    Passport: any,
    Protocol: any,
    isPhoneCheck: boolean,
    role: ClientType | undefined,
}

export type RegistrationSecondType = {
    userId: string,
    code: string
}

class RegistrationApi {
    registrarion({...props}: RegistrationFirstType) {
        const body = {...props};
        return http.post<undefined, RegistrationFirstType, RegistrationResponseType>(`${ROOT_URL}/register`, undefined, body);
    }

    registrationAgent(props: any) {
        const formData = new FormData();
        for (const key in props) {
            formData.append(key, props[key]);
        }
        return http.postMultiple<undefined, any, any>(`${ROOT_URL}/registeragent`, undefined, formData);
    }

    confirmRegistration({...props}: RegistrationSecondType) {
        const body = {...props};
        return http.post(`${ROOT_URL}/register/phone`, undefined, body);
    }
};


const registrationApi = new RegistrationApi();
export default registrationApi;