import React, {
  memo,
  useEffect,
  useMemo,
  useState,
} from 'react';

import clsx from 'clsx';
import { useSelector } from 'react-redux';

import {
  Box,
  Divider,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core';

import {
  CHAT_ROLES,
  ROLES,
} from '../../../../core/consts/common';
import { SearchIcon } from '../../../../core/consts/images/SearchIcon';
import { dateWithTimeConverter } from '../../../../core/helpers/dateConverter';
import { actions } from '../../../../core/store';
import { rootState } from '../../../../core/types/rootState';
import Avatar from '../../avatar';
import ChatWindow from '../../chat-window';
import { useStyles } from './DetailsChatStyles';

type propsType = {
  id: number;
  isInputNeeded?: boolean;
  choiceChat?: string;
  isArchive?: boolean;
};

const DetailsChat: React.FC<propsType> = ({ id, isInputNeeded = true, choiceChat, isArchive }) => {
  const classes = useStyles();
  const [isManagerChat, setIsManagerChat] = useState<number | boolean>(0);
  const initialUser = useSelector((state: rootState) => state.currentUser);

  let chatData = useSelector((state: rootState) => state.currentChat);

  const handleManagerChatClick = () => {
    setIsManagerChat(true);
  };

  const handleBankChatClick = () => {
    initialUser.role !== ROLES.PARTNER && setIsManagerChat(false);
  };

  const cleanBank = () => {
    actions.setCurrentChatData({
      bankName: "",
    });
  };

  useEffect(() => {
    initialUser.role === ROLES.MANAGER && setIsManagerChat(true);
    choiceChat === 'bank' && setIsManagerChat(false);
    return () => cleanBank();
  }, []);

  return (
    <Grid
      alignContent="space-between"
      container
      spacing={3}
      className={classes.grid}
    >
      <Grid item className={classes.gridItem} style={{ padding: "0" }}>
        <TextField
          label="Поиск"
          color="primary"
          type="search"
          variant="standard"
          style={{ width: "100%" }}
          // onChange={handleSearchChange}
          className={classes.search}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <Divider />
        <Box className={classes.chatsBox}>
          {useMemo(
            () =>
              initialUser.role !== ROLES.PARTNER && (
                <Box
                  className={clsx(classes.boxSender, {
                    [classes.boxSenderGrey]:
                      typeof isManagerChat === "number" ||
                      (typeof isManagerChat === "boolean" && isManagerChat),
                  })}
                  onClick={handleManagerChatClick}
                >
                  <Avatar
                    size="medium"
                    currentChatName={
                      chatData.bankName
                        ? chatData?.bankName.replaceAll('"', "")
                        : "Имя Банк"
                    }
                    info={
                      initialUser.role !== ROLES.MANAGER
                        ? (initialUser.manager && initialUser.manager.avatar_url) || "Менеджер"
                        : chatData.roleChat === "agent"
                          ? CHAT_ROLES.agent
                          : CHAT_ROLES.client
                    }
                  />
                  &nbsp;&nbsp;&nbsp;
                  <Box className={classes.messageInfoWrap}>
                    <Typography variant="h4" component="p">
                      Чат с
                      {`${initialUser.role === ROLES.MANAGER
                        ? chatData.roleChat === "agent"
                          ? " агентом "
                          : " клиентом "
                        : " менеджером "
                        }`}
                      {initialUser.role !== ROLES.MANAGER &&
                        (initialUser.manager && initialUser.manager.fio)
                        ? initialUser.manager.fio
                        : chatData.nameChat
                          ? chatData.nameChat.split("%")[1]
                          : "No Name"}
                      &nbsp;по заявке {id}
                    </Typography>
                    <Box className={classes.messageInfoSubWrap}>
                      <Typography
                        variant="body2"
                        component="span"
                        className={classes.messageSender}
                        style={{
                          maxWidth: `${chatData.isMyMessage ? "fit-content" : "50%"
                            }`,
                        }}
                      >
                        {chatData?.isMyMessage
                          ? "Вы"
                          : chatData?.lastMessage && chatData?.nameChat}
                        &nbsp;
                      </Typography>
                      <Typography
                        variant="body2"
                        component="p"
                        className={classes.message}
                        style={{
                          maxWidth: `${chatData.isMyMessage ? "fit-content" : "340px"}`,
                        }}
                      >
                        {chatData?.lastMessage}
                      </Typography>
                    </Box>
                    <Typography
                      variant="body2"
                      component="span"
                      className={classes.messageInfo}
                    >
                      {chatData.countUnreadMessage &&
                        chatData?.countUnreadMessage > 0 ? (
                        <Typography
                          variant="body2"
                          component="span"
                          className={classes.messagesCount}
                        >
                          непрочитанных: {chatData.countUnreadMessage}
                        </Typography>
                      ) : null}
                      {chatData!.lastTimeMessage !== "" &&
                        chatData!.lastTimeMessage ? (
                        <Typography
                          variant="body2"
                          component="span"
                          style={{ color: "#B0BFC6" }}
                        >
                          {dateWithTimeConverter(chatData!.lastTimeMessage)}
                        </Typography>
                      ) : null}
                    </Typography>
                  </Box>
                </Box>
              ),
            [
              chatData,
              initialUser.manager && initialUser.manager.avatar_url,
              initialUser.manager && initialUser.manager.fio,
              initialUser.role,
              isManagerChat,
              id,
            ]
          )}
          <Divider />
          {useMemo(
            () =>
              initialUser.role !== ROLES.MANAGER && (
                <Box
                  className={clsx(classes.boxSender, {
                    [classes.boxSenderGrey]:
                      initialUser.role === ROLES.PARTNER ||
                      (typeof isManagerChat === "boolean" &&
                        isManagerChat === false),
                  })}
                  onClick={handleBankChatClick}
                >
                  <Avatar
                    size="medium"
                    info={
                      chatData.bankName
                        ? chatData?.bankName.replaceAll(/["|%]/g, "")
                        : "Имя Банк"
                    }
                    currentChatName={
                      chatData.bankName
                        ? chatData?.bankName.replaceAll(/["|%]/g, "")
                        : "Имя Банк"
                    }
                  />
                  &nbsp;&nbsp;&nbsp;
                  <Box className={classes.messageInfoWrap}>
                    {initialUser.role !== ROLES.PARTNER ? (
                      <Typography variant="h4" component="p">
                        Чат с банком&nbsp;
                        {chatData.bankName &&
                          chatData?.bankName.replaceAll(/["|'|%]/g, "")}
                        &nbsp;по заявке {id}
                      </Typography>
                    ) : (
                      <Typography variant="h4" component="p">
                        {chatData.bankName}
                      </Typography>
                    )}
                    <Box className={classes.messageInfoSubWrap}>
                      <Typography
                        variant="body2"
                        component="span"
                        className={classes.messageSender}
                        style={{
                          maxWidth: `${chatData.isMyMessage ? "fit-content" : "50%"}`,
                        }}
                      >
                        {chatData?.isMyMessageBank
                          ? "Вы"
                          : chatData?.lastMessageBank && chatData?.bankName}
                        &nbsp;
                      </Typography>
                      <Typography
                        variant="body2"
                        component="p"
                        className={classes.message}
                        style={{
                          maxWidth: `${chatData.isMyMessage ? "fit-content" : "340px"}`,
                        }}
                      >
                        {chatData?.lastMessageBank}
                      </Typography>
                    </Box>
                    <Typography
                      variant="body2"
                      component="p"
                      className={classes.messageInfo}
                    >
                      {chatData.countUnreadMessageBank &&
                        chatData?.countUnreadMessageBank > 0 ? (
                        <Typography
                          variant="body2"
                          component="span"
                          className={classes.messagesCount}
                        >
                          непрочитанных: {chatData?.countUnreadMessageBank}
                        </Typography>
                      ) : null}
                      {chatData!.lastTimeMessageBank &&
                        chatData!.lastTimeMessageBank !== "" ? (
                        <Typography
                          variant="body2"
                          component="span"
                          style={{ color: "#B0BFC6" }}
                        >
                          {dateWithTimeConverter(chatData!.lastTimeMessageBank)}
                        </Typography>
                      ) : null}
                    </Typography>
                  </Box>
                </Box>
              ),
            [chatData, isManagerChat, id, initialUser.role]
          )}
        </Box>
      </Grid>
      <Divider orientation="vertical" flexItem />
      <Grid item className={clsx(classes.gridItem, classes.gridItemChat)}>
        {initialUser.role !== ROLES.PARTNER &&
          (typeof isManagerChat === "number" ||
            (typeof isManagerChat === "boolean" && isManagerChat)) && (
            <ChatWindow
              isInputNeeded={!isInputNeeded}
              leadId={id.toString()}
              chatType={"manager"}
            />
          )}
        {(initialUser.role === ROLES.PARTNER ||
          (initialUser.role !== ROLES.MANAGER &&
            typeof isManagerChat === "boolean" &&
            isManagerChat === false)) && (
            <ChatWindow
              isInputNeeded={!isInputNeeded}
              leadId={id.toString()}
              chatType={""}
              isArchive={isArchive}
            />
          )}
      </Grid>
    </Grid>
  );
};

export default memo(DetailsChat);
