import { Box, Button, Container, Divider, Grid, MenuItem, Paper, Select, TextField, Typography } from "@material-ui/core";
import clsx from "clsx";
import React, { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { v4 } from "uuid";
import HeaderNew from "../../app/components/redesign/Header/HeaderNew";
import agentService from "../../core/services/AgentService";
import { useAppThunkDispatch } from "../../core/store";
import { setIsOpenInnModal } from "../../core/store/action-creators";
import { AgentClientsListType } from "../../core/types/agentTypes";
import { clientStatuses } from "../../core/types/managerApiTypes";
import { rootState } from "../../core/types/rootState";
import AddClientModal from "./components/add-client-modal";
import { useStylesAgentClients } from "./useStylesAgentClients";

/* Redesign . All agent's clients page. */

const clientTypes = {
  ul: "Юридические лица",
  ip: "Индивидульные предприниматели"
} as Record<string, string>

const AgentClientsPage: React.FC = () => {
  const classes = useStylesAgentClients();
  const initialUser = useSelector((state: rootState) => state.currentUser);
  const dispatch = useAppThunkDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [searchInn, setSearchInn] = useState('');
  const [update, setUpdate] = useState<boolean>(false);
  const [searchCompanyName, setSearchCompanyName] = useState('');
  const [text, setText] = useState('');
  const [searchRegion, setSearchRegion] = useState('all');
  const [searchClientTypes, setSearchClientTypes] = useState('all');
  const [clientsData, setCleintsData] = useState<AgentClientsListType>()
  const [requestTimer, setRequestTimer] = useState(0)
  const [textTimer, setTextTimer] = useState(0)

  const description = `Всего клиентов ${clientsData ? clientsData.clientCount : 0}`

  const handleOpen = () => {
    setOpenModal(true)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
    setUpdate((prev) => !prev)
  }

  useEffect(() => {
    if (initialUser.status === clientStatuses.NotCompleteRegistration) {
      dispatch(setIsOpenInnModal(true));
      return;
    }
    clearTimeout(requestTimer)
    const localTimer = window.setTimeout(
      () => {
        const requestParams = {
          Inn: searchInn,
          Region: searchRegion === 'all' ? '' : searchRegion,
          CompanyName: searchCompanyName,
          clientType: searchClientTypes === 'all' ? '' : searchClientTypes
        }
        agentService.getClientsListService(requestParams)
          .then((res: AgentClientsListType | 'Ошибка') => {
            if (res === 'Ошибка') {
              setCleintsData(undefined)
              setText('Произошла ошибка, попробуйте перезагрузить страницу.')
            } else if (res && res.clientCount === 0) {
              setCleintsData(undefined)
              const textCurrentTimer = window.setTimeout(() => setText('У вас пока нет клиентов.'), 1000)
              setTextTimer(textCurrentTimer)
            } else {
              setCleintsData(res)
            }
          })
          .catch((error) => {
            console.log(error)
          })
      },
      500,
    )
    setRequestTimer(localTimer)

    return () => {
      requestTimer && clearTimeout(requestTimer)
      textTimer && clearTimeout(textTimer)
    }
  }, [searchInn, searchCompanyName, searchRegion, searchClientTypes, update, initialUser.status])

  const handleRemoveFilters = () => {
    setSearchInn('')
    setSearchCompanyName('')
    setSearchRegion('all')
    setSearchClientTypes('all')
  }

  return (
    <div>
      <HeaderNew title={"Клиенты"} description={description} />
      <Container className={classes.container}>
        <Box className={clsx(classes.searchWrap, classes.box)}>
          <TextField
            label="ИНН клиента"
            color='primary'
            type="search"
            variant="standard"
            onChange={(e) => setSearchInn(e.currentTarget.value)}
            className={classes.search}
            value={searchInn}
          />
          <TextField
            label="Наименование клиента"
            color='primary'
            type="search"
            variant="standard"
            onChange={(e) => setSearchCompanyName(e.currentTarget.value)}
            value={searchCompanyName}
            className={classes.search}
          />
          <Select
            color='secondary'
            value={searchRegion}
            className={classes.select}
            onChange={(e: any) => setSearchRegion(e.target.value)}
          >
            <MenuItem value={'all'}>Все регионы</MenuItem>
            {clientsData && clientsData.regions && clientsData.regions.map((region) => {
              return <MenuItem key={v4()} value={region}>{region}</MenuItem>
            })}
          </Select>
          <Select
            color='secondary'
            value={searchClientTypes}
            className={classes.select}
            onChange={(e: any) => setSearchClientTypes(e.target.value)}
          >
            <MenuItem key={v4()} value={'all'}>Все типы клиентов</MenuItem>
            {clientsData && clientsData.clientTypes && clientsData.clientTypes.map((type) => {
              return <MenuItem key={v4()} value={type}>{clientTypes[type as string]}</MenuItem>
            })}
          </Select>
          <Button
            variant='text'
            onClick={handleRemoveFilters}
          ><Typography variant="body1" component='span' style={{ color: '#306F9A', textTransform: 'none', lineHeight: '2' }}>
              Сбросить фильтры
            </Typography>
          </Button>
        </Box>
        <Box className={clsx(classes.addClientWrap, classes.box)}>
          <Typography variant="body1" component='span' style={{ textTransform: 'none', lineHeight: '2' }}>
            {`Найдено клиентов: ${clientsData ? clientsData.clients.length : 0}`}
          </Typography>
          <Button
            style={{ boxShadow: 'none', borderRadius: '4px', outline: 'none', border: 'none' }}
            variant='contained'
            color='primary'
            onClick={handleOpen}
          ><Typography variant="body1" component='span' style={{ color: '#ffffff', textTransform: 'none', lineHeight: '2' }}>
              Добавить клиента
            </Typography>
          </Button>
        </Box>
        {
          clientsData && <div className={classes.bodyContainer}>
            <Paper className={clsx(classes.clientsWrap, classes.box)}>
              <Grid className={classes.gridTitles}>
                <Grid item xl={3} lg={3} md={3}>
                  <Typography className={classes.gridTitle} variant="body2" component='span'>
                    Наименование/ИНН
                  </Typography>
                </Grid>
                <Grid item xl={3} lg={3} md={3}>
                  <Typography className={classes.gridTitle} variant="body2" component='span'>
                    Регион и время
                  </Typography>
                </Grid>
              </Grid>
              {clientsData && clientsData.clients.map((item, index) => {
                return <div key={v4()}>
                  <Grid className={classes.gridItems}>
                    <Grid item className={classes.gridItem} xl={3} lg={3} md={3}>
                      <Typography className={classes.gridItemFirst} variant="body1" component='span'>
                        {item.companyName}
                      </Typography>
                      <Typography variant="body2" component='span'>
                        {`ИНН: ${item.inn}`}
                      </Typography>
                    </Grid>
                    <Grid item className={classes.gridItem} xl={3} lg={3} md={3}>
                      <Typography className={classes.gridItemFirst} variant="body1" component='span'>
                        {item.region}
                      </Typography>
                      <Typography variant="body2" component='span'>
                        {item.localTime}
                      </Typography>
                    </Grid>
                  </Grid>
                  {index < clientsData.clients.length - 1 && <Divider className={classes.divider} />}
                </div>
              })}
            </Paper>
          </div>}
        {!clientsData && (text !== "") && <div className={classes.bodyContainer}>
          < Paper className={clsx(classes.clientsWrap, classes.box)}>
            <Box className={classes.noLeadsWrap}>
              <Typography style={{ margin: 'auto' }} component='h4' variant='h4' >{text}</Typography>
            </Box>
          </Paper>
        </div>}
      </Container>
      {
        openModal && <AddClientModal
          isOpen={openModal}
          setCloseModal={handleCloseModal}
        />
      }
    </div >
  )
}

export default memo(AgentClientsPage)