import { Theme, createStyles, makeStyles } from "@material-ui/core";
import Popover from '@material-ui/core/Popover';
import { useEffect, useRef, useState } from "react";
import { COLORS } from "../../core/consts/redesign/commonRedesign";

/* Redesign. custom black popover with delay for form fields */

const useStyles = makeStyles((theme: Theme) => createStyles({
  popover: {
    backgroundColor: COLORS.black,
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxWidth: '350px',
    padding: '10px 16px',
    alignItems: 'flex-start',
    justifyContent: 'center',
    borderRadius: '4px 4px 4px 0px',
  },

  captionLabel: {
    fontSize: '14px',
    lineHeight: '19px',
    fontWeight: 600,
    color: COLORS.light_gray_stroke
  },

  captionValue: {
    fontSize: '16px',
    lineHeight: '21px',
    fontWeight: 600,
    color: COLORS.white
  }
}));

type CustomPopoverProps = {
  id: string;
  label: string;
  value?: string;
  margin?: string;
}
const CustomPopover: React.FC<CustomPopoverProps> = ({
  id,
  label,
  value,
  margin = '0px'
}) => {
  const classes = useStyles();
  // const labelRef = useRef<HTMLDivElement>(null);
  // const inputRef = useRef<HTMLInputElement>(null)
  // const [isOverflowed, setIsOverflowed] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [isMouseOver, setIsMouseOver] = useState(false);
  const popoverTriggerRef = useRef<HTMLDivElement>(null);

  const closeTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  // useEffect(() => {
  //   const checkOverflow = () => {
  //     const label = labelRef.current;
  //     const input = inputRef.current;
  //     if (input) {
  //       const isOverflowing = input.offsetWidth < input.scrollWidth;
  //       setIsOverflowed(isOverflowing);
  //     }
  //         if (label) {
  //       const isOverflowing = label.offsetWidth < label.scrollWidth;
  //       setIsOverflowed(isOverflowing);
  //     }
  //   };
  //   checkOverflow();
  //   window.addEventListener('resize', checkOverflow);
  //   return () => window.removeEventListener('resize', checkOverflow);
  // }, [label, value]);


  const handlePopoverOpen = () => {
    setIsMouseOver(true);
    setTimeout(() => {
      if (isMouseOver) {
        setPopoverOpen(true);
      }
    }, 2000);
  };

  const handlePopoverClose = () => {
    closeTimeoutRef.current = setTimeout(() => {
      setPopoverOpen(false);
      closeTimeoutRef.current = null;
    }, 2000);
  };

  useEffect(() => {
    return () => {
      if (closeTimeoutRef.current) {
        clearTimeout(closeTimeoutRef.current);
      }
    };
  }, []);


  return (
    <>
      <div
        id={id}
        ref={popoverTriggerRef}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        style={{
          width: '50px',
          height: '100%',
          position: 'absolute',
          right: 0,
          top: 0,
          cursor: 'pointer',
          zIndex: 100,
          marginRight: margin
        }} />
      <Popover
        id="mouse-over-popover"
        open={popoverOpen}
        onClose={handlePopoverClose}
        anchorEl={popoverTriggerRef.current}
        disableRestoreFocus
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
      >
        <div className={classes.popover}>
          <span className={classes.captionLabel}>{label}</span>
          <span className={classes.captionValue}>{value}</span>
        </div>
      </Popover>
    </>
  );
};
export default (CustomPopover)