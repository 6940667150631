import {
  DataForBigForm,
  ErrorsFields,
  ErrorsFiledsModal,
  FieldModal,
  FieldType,
  FormField,
  PropsalData,
} from "../types/bigForm";

export const checkIncludesKeyInObject = <T extends {}>(
  obj: T,
  key: string
): boolean => {
  if (Object.keys(obj).includes(key)) {
    return true;
  }
  return false;
};

export const filterObject = <T extends Record<number, any>>(
  obj: T,
  keys: number[]
) => {
  return keys.reduce<Record<number, any>>(
    (newObj, key) => {
      newObj[key] = obj[key]
      return newObj
    },
    {}
  );
};

//Проверка на JSON
export const safeJsonParse = <T extends unknown = unknown>(
  str: string | null | undefined
): T | undefined => {
  if (!str) {
    return undefined;
  }
  try {
    return JSON.parse(str);
  } catch (error) {
    return undefined;
  }
};

//Для модалок
//Проверяет есть ли филд в предзаполненных данных и если есть подставляет предзаполненное значение,
//а если нет, то прешедшее начальное значение.
const defineValueModalField = <T>(
  valueFields: FieldModal,
  fieldName: string,
  initialValue: T
) => {
  if (checkIncludesKeyInObject(valueFields, fieldName)) {
    return valueFields[fieldName];
  }
  return initialValue;
};

//Для обычных филдов не модалок
//Если есть предзаполненное значение возвращает его, если нет то ставит initialValue
const defineValueField = <T, U>(valueField: string, initialValue: U): T | U => {
  if (safeJsonParse<string>(valueField) && valueField.length > 0) {
    const parsValueField: T = JSON.parse(valueField);
    return parsValueField;
  }
  return initialValue;
};

//Обработка поля модалки, возвращает значение поля модалки
const handleFieldModal = (field: FieldType, prefilledValues: FieldModal) => {
  if (field.dataType === "string" && field.isMultiple) {
    return defineValueModalField(prefilledValues, field.name, []);
  } else if (field.dataType === "string") {
    return defineValueModalField(prefilledValues, field.name, "");
  } else if (field.dataType === "combobox" && field.isMultiple) {
    return defineValueModalField(prefilledValues, field.name, []);
  } else if (field.dataType === "combobox") {
    return defineValueModalField(prefilledValues, field.name, "");
  } else if (field.dataType === "bool") {
    return defineValueModalField(prefilledValues, field.name, false);
  } else {
    return defineValueModalField(prefilledValues, field.name, "");
  }
};

export type FieldsModalArrayData = {
  fieldsWithValues: FieldModal[];
  errorsFields: ErrorsFiledsModal[];
};

export type FieldsModalData = {
  fieldsWithValues: FieldModal;
  errorsFields: ErrorsFiledsModal;
};

//Подставляет значения в поля для модалки с множественным выбором
export const assemblingPrefilledValueFields = (
  prefilledValues: FieldModal,
  modalData: FieldType
) => {
  return modalData.childs.reduce<FieldsModalData>(
    (fieldWithValue, field) => {
      fieldWithValue.fieldsWithValues[field.name] = handleFieldModal(
        field,
        prefilledValues
      );
      fieldWithValue.errorsFields[field.name] = "";
      return fieldWithValue;
    },
    {
      fieldsWithValues: {},
      errorsFields: {},
    }
  );
};
//Собираются филды для модалки с множественным выбором
//Если есть предзаполненные значения они подставляются
export const handleFieldsModalArray = (
  modalData: FieldType
): FieldsModalArrayData | undefined => {
  try {
    const prefilledValues = safeJsonParse<FieldModal[]>(modalData.values);
    if (prefilledValues && prefilledValues.length > 0) {
      const res = prefilledValues.map((valueFields) => {
        return assemblingPrefilledValueFields(valueFields, modalData);
      });
      return {
        fieldsWithValues: res.map((modalData) => modalData.fieldsWithValues),
        errorsFields: res.map((modalData) => modalData.errorsFields),
      };
    }
    return {
      fieldsWithValues: [],
      errorsFields: [
        modalData.childs.reduce<ErrorsFiledsModal>(
          (errorsFieldsModal, field) => {
            errorsFieldsModal[field.name] = "";
            return errorsFieldsModal;
          },
          {}
        ),
      ],
    };
  } catch (e) {
    console.log('handleFieldsModalArray -->', e);
  }
};

//Собираются филды для модалки с единственным выбором
//Если есть предзаполненные значения они подставляются
export const handleFieldsModalObject = (
  modalData: FieldType
): FieldsModalData | undefined => {
  try {
    const prefilledValues = safeJsonParse<FieldModal>(modalData.values);
    if (prefilledValues && Object.keys(prefilledValues).length > 0) {
      return assemblingPrefilledValueFields(prefilledValues, modalData);
    }
    return {
      fieldsWithValues: {},
      errorsFields: modalData.childs.reduce<ErrorsFiledsModal>(
        (errorsFieldsModal, field) => {
          errorsFieldsModal[field.name] = "";
          return errorsFieldsModal;
        },
        {}
      ),
    };
  } catch (e) {
    console.log('handleFieldsModalObject -->', e);
  }
};

type FieldsDataForBigForm = {
  fieldsWithValues: FormField;
  errorsFields: ErrorsFields;
};

export const handlePropsalFields = (fields: FieldType[], propsalId: string) => {
  let fieldsDataForBigForm: FieldsDataForBigForm = {
    fieldsWithValues: {},
    errorsFields: {},
  };
  try {
    //Создание данных для формы.
    fieldsDataForBigForm = fields.reduce<FieldsDataForBigForm>(
      (resultFields, field) => {
        //создается поле для текста ошибки
        resultFields.errorsFields[field.name] = "";
        //Если филд имеет подфилды, т.е. это модалка
        if (field.childs.length > 0) {
          //если групповой филд (модалка) с множественным выбором
          if (field.isMultiple) {
            const modalField = handleFieldsModalArray(field);
            if (modalField) {
              resultFields.fieldsWithValues[field.name] =
                modalField.fieldsWithValues;
              resultFields.errorsFields[field.name] = modalField.errorsFields;
            } else {
              resultFields.fieldsWithValues[field.name] = [];
            }
          } else {
            //если групповой филд (модалка) с единственным выбором
            const modalField = handleFieldsModalObject(field);
            if (modalField) {
              resultFields.fieldsWithValues[field.name] =
                modalField.fieldsWithValues;
              resultFields.errorsFields[field.name] = modalField.errorsFields;
            } else {
              resultFields.fieldsWithValues[field.name] = {};
            }
          }
        } else if (field.dataType === "string" && field.isMultiple) {
          resultFields.fieldsWithValues[field.name] = defineValueField<
            string[],
            []
          >(field.values, []);
        } else if (field.dataType === "string") {
          if (field.values && field.values.length > 0) {
            resultFields.fieldsWithValues[field.name] = field.values;
          } else {
            resultFields.fieldsWithValues[field.name] = "";
          }
        } else if (field.dataType === "combobox" && field.isMultiple) {
          resultFields.fieldsWithValues[field.name] = defineValueField<
            any[],
            []
          >(field.values, []);
        } else if (field.dataType === "combobox") {
          resultFields.fieldsWithValues[field.name] = defineValueField<
            string,
            string
          >(field.values, "");
        } else if (field.dataType === "bool") {
          resultFields.fieldsWithValues[field.name] = field.values;
        } else {
          if (field.values && field.values.length > 0) {
            resultFields.fieldsWithValues[field.name] = field.values;
          } else {
            resultFields.fieldsWithValues[field.name] = "";
          }
        }
        return resultFields;
      },
      {
        fieldsWithValues: {},
        errorsFields: {},
      }
    );
  } catch (e) {
    console.log('handlePropsalFields -->', e);
  }
  return {
    errorsFields: fieldsDataForBigForm.errorsFields,
    fields: fieldsDataForBigForm.fieldsWithValues,
  };
};

export const handleResponseForBigForm = (response: PropsalData[]) => {
  // console.log("handleResponseForBigForm response", response)
  let addedProposalData: PropsalData[] = [...response];
  response.forEach(elem => {
    if (elem?.innerSteps) {
      addedProposalData = addedProposalData.concat(elem.innerSteps)
    }
  })
  // console.log("handleResponseForBigForm addedProposalData", addedProposalData)

  return addedProposalData.reduce(
    (dataForBigForm, propsal, index) => {
      const { errorsFields, fields } = handlePropsalFields(
        propsal.fields,
        propsal.id.toString()
      );
      dataForBigForm.formValues[propsal.id] = fields;
      dataForBigForm.errorsData[propsal.id] = {
        id: propsal.id,
        fields: errorsFields,
        isErrors: undefined,
      };
      return dataForBigForm;
    },
    {
      errorsData: {},
      formValues: {},
    } as DataForBigForm
  );
};
