import {
  Button,
  Checkbox,
  Container,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Theme,
  Tooltip,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { ChangeEvent, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import HeaderNew from "../../app/components/redesign/Header/HeaderNew";
import { ROUTES } from "../../core/consts/common";
import adminService from "../../core/services/AdminService";
import Eye from "./images/Eye";
import Folder from "./images/Folder";
import Phone from "./images/Phone";

/* Redesign . Admin all products list for editing */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      maxWidth: "100%",
      padding: '40px 80px',
      margin: "0 auto",
      boxSizing: "border-box",
    },
    filters: {
      display: "flex",
      gap: "15px",
      flexWrap: "wrap",
      alignItems: "center",
    },
    filter: {
      width: "300px",
      minWidth: "300px",
    },
    table: {
      padding: "30px 0",
      background: "white",
      borderRadius: "10px",
      marginTop: "30px",
    },
    tableHead: {
      display: "grid",
      gridTemplateColumns: "3fr 2fr 3fr 2fr 1fr",
      gridTemplateAreas: "'bank product name date options'",
      padding: "0 30px",
      "& div": {
        color: "#617E8C",
        fontSize: "14px",
        fontFamily: "Gilroy",
        fontWeight: 600,
      },
    },
    bank: {
      gridArea: "bank",
      display: "flex",
      alignItems: "center",
    },
    product: {
      gridArea: "product",
    },
    name: {
      gridArea: "name",
    },
    date: {
      gridArea: "date",
    },
    bankBody: {
      marginTop: "10px",
    },
    tableElem: {
      display: "grid",
      gridTemplateColumns: "3fr 2fr 3fr 1fr 2fr",
      gridTemplateAreas: "'bank product name date options'",
      height: "104px",
      borderBottom: "1px solid #D0D9DE",
      textDecoration: "none",
      alignItems: "center",
      cursor: "pointer",
      padding: "0 30px",
      "&:hover": {
        background: "#F2F2F2",
      },
      "& div": {
        color: "#131313",
      },
    },
    options: {
      gridArea: "options",
    },
    btnText: {
      color: "#306F9A",
      fontFamily: "Gilroy",
      fontWeight: 500,
      fontSize: "14px",
      cursor: "pointer",
      marginTop: "10px",
    },
    btnMenu: {
      whiteSpace: 'nowrap',
      padding: "18px 36px",
      display: "block",
    },
    modal: {
      width: "343px",
      height: "130px",
      background: "white",
      position: "absolute",
      top: "70px",
      boxShadow: "0px 15px 20px rgba(0, 0, 0, 0.05)",
      borderRadius: "10px",
      padding: "20px 20px 25px 20px",
    },
    button: {
      height: "51px",
      width: "100%",
    },
    text: {
      color: "inherit",
      textTransform: "none",
    },
    btnDrop: {
      width: "100%",
      textTransform: "none",
      marginTop: "5px",
    },
    btnIcon: {
      fontSize: 18,
      color: "#D0D9DE",
      marginLeft: "7px",
    },
    control: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "30px",
      alignItems: "start",
    }
  })
);

const MainAdminPage = () => {
  const classes = useStyles();
  const [bank, setBank] = useState<any>({ key: "all", value: "Все продукты" });
  const [product, setProduct] = useState<any>("all");
  const [status, setStatus] = useState<any[]>([]);
  const [sortByBank, setSortByBank] = useState<any[] | null | string>(
    "NewDate"
  );
  const [isClickBtn, setClickBtn] = useState(true);
  const handleChange = (event: React.ChangeEvent<any>) => {
    const { value, name } = event.target;
    setClickBtn(false);
    if (name === "product") {
      setProduct(value as any[]);
    }
    if (name === "status") {
      setStatus(value as any[]);
    }
    if (name === "sortByBank") {
      setSortByBank(value as any[]);
    }
  };
  const isShowBtn = () => {
    if (
      bank.key !== "all" ||
      product !== "all" ||
      status.length !== 0 ||
      sortByBank !== ""
    ) {
      if (isClickBtn) {
        return false;
      } else {
        return true;
      }
    } else return false;
  };
  const deleteFilters = () => {
    setBank({ key: "all", value: "Все продукты" });
    setSortByBank("NewDate");
    setStatus([]);
    setProduct("all");
    adminService
      .getAdminApplicationService("all", "all", [], "NewDate")
      .then((res: any) => {
        try {
          setApplication(res);
        } catch (e) {
          console.log(e);
        }
      });
  };
  const [application, setApplication] = useState<null | any>(null);
  const [filters, setFilters] = useState<null | any>(null);
  const [isClickIcons, setClickIcons] = useState(false);
  const handleClickShow = () => {
    adminService
      .getAdminApplicationService(
        bank.key,
        product,
        status,
        sortByBank || "Bank"
      )
      .then((res: any) => {
        try {
          setApplication(res);
        } catch (e) {
          console.log(e);
        }
      });
  };
  const handleClickBtn = () => {
    handleClickShow();
    setClickBtn(true);
  };
  useEffect(() => {
    handleClickShow();
  }, [isClickIcons]);
  useEffect(() => {
    adminService.getAdminFiltersService().then((res: any) => {
      try {
        setFilters(res);
      } catch (e) {
        console.log(e);
      }
    });
  }, []);

  const handleClickIcon = (
    id: number,
    isActive?: boolean,
    showOnMainPage?: boolean
  ) => {
    adminService
      .putAdminUpdateProposalService(id, isActive, showOnMainPage)
      .then(() => {
        setClickIcons(!isClickIcons);
      });
  };

  const handleChoiceBank = (_: ChangeEvent<{}>, value: any) => {
    setClickBtn(false);
    if (value) {
      setBank(value as any[]);
    }
  };

  if (!filters) return null;
  return (
    <div>
      <HeaderNew title={"Редактирование предложений"} />
      <Container className={classes.container}>
        <Grid>
          <div className={classes.control}>
            <div className={classes.filters}>
              <FormControl className={classes.filter}>
                <InputLabel></InputLabel>
                <Autocomplete
                  value={bank}
                  onChange={handleChoiceBank}
                  freeSolo
                  options={filters.banks}
                  getOptionLabel={(option) => (option ? option.text || '' : '')} //fix for mui warning
                  disableClearable
                  renderInput={(params) => (
                    <TextField {...params} label="Банк" />
                  )}
                />
              </FormControl>
              <FormControl className={classes.filter}>
                <InputLabel></InputLabel>
                <Select
                  value={product}
                  onChange={handleChange}
                  name={"product"}
                >
                  <MenuItem value={"all"}>Все продукты</MenuItem>
                  {filters.products.map((prodElem: any) => (
                    <MenuItem key={prodElem.key} value={prodElem.key}>
                      {prodElem.text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl className={classes.filter}>
                <InputLabel>Выбор статуса</InputLabel>
                <Select
                  multiple
                  value={status}
                  onChange={handleChange}
                  name={"status"}
                  input={<Input />}
                  renderValue={(selected) =>
                    (selected as any[]).map((el) => el.text).join(", ")
                  }
                >
                  {filters.statuses.map((statusElem: any) => (
                    <MenuItem key={statusElem.key} value={statusElem}>
                      <Checkbox
                        color="primary"
                        checked={
                          !!status.find((el) => el.key === statusElem.key)
                        }
                      />
                      <ListItemText primary={statusElem.text} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <div style={{ position: "relative" }}>
                <FormControl className={classes.filter}>
                  <InputLabel></InputLabel>
                  <Select
                    value={sortByBank}
                    onChange={handleChange}
                    name={"sortByBank"}
                  >
                    {filters.sort.map((sortElem: any) => (
                      <MenuItem key={sortElem.key} value={sortElem.key}>
                        {sortElem.text}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {isShowBtn() && (
                  <div className={classes.modal}>
                    <Button
                      color={"primary"}
                      variant="contained"
                      className={classes.button}
                      onClick={handleClickBtn}
                    >
                      <Typography
                        variant={"subtitle2"}
                        className={classes.text}
                        component={"span"}
                      >
                        Показать предложения
                      </Typography>
                    </Button>
                    <Button
                      color={"primary"}
                      className={classes.btnDrop}
                      variant="text"
                      onClick={deleteFilters}
                    >
                      <Typography variant={"subtitle2"}>
                        Сбросить фильтры
                      </Typography>
                      <CloseIcon className={classes.btnIcon} />
                    </Button>
                  </div>
                )}
              </div>
              <div onClick={deleteFilters} className={classes.btnText}>
                Сбросить фильтры
              </div>
            </div>
            <div>
              <Link
                to={`${ROUTES.ADMIN}/edit-products/new-product`}
              >
                <Button
                  className={classes.btnMenu}
                  color="primary"
                  variant="outlined"
                  style={{ display: "inline-block" }}
                >
                  <Typography
                    variant="body1"
                    component="span"
                    style={{ color: "inherit", textTransform: "none" }}
                  >
                    Новое предложение
                  </Typography>
                </Button>
              </Link>
            </div>
          </div>
          <div
            style={{
              color: "#131313",
              fontFamily: "Gilroy",
              fontWeight: 500,
              fontSize: "14px",
            }}
          >
            Найдено предложений: {application ? application.length : 0}{" "}
          </div>
          <div className={classes.table}>
            <div className={classes.tableHead}>
              <div className={classes.bank}>Наименование банка</div>
              <div className={classes.product}>Продукт</div>
              <div className={classes.name}>Наименование предложения</div>
              <div className={classes.date}>Дата изменения</div>
              <div className={classes.options}></div>
            </div>
            <div className={classes.bankBody}>
              {application &&
                application.map((el: any, key: number) => {
                  return (
                    <Link
                      key={key}
                      to={`${ROUTES.ADMIN}/edit-products/new-product/${el.id}`}
                      className={classes.tableElem}
                    >
                      <div
                        className={classes.bank}
                        style={{ fontWeight: "600" }}
                      >
                        <img
                          style={{ height: "54px", objectFit: "contain" }}
                          src={el.imageUrl}
                        />
                        <div style={{ marginLeft: "30px" }}> {el.bank}</div>
                      </div>
                      <div
                        className={classes.product}
                        style={{ fontWeight: "600" }}
                      >
                        {el.product}
                      </div>
                      <div className={classes.name}>{el.proposalName}</div>
                      <div className={classes.date}>
                        {el.updateDate.split(" ")[0]}
                      </div>
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                        className={classes.options}
                      >
                        {el.isRealCard && (
                          <Tooltip title={"ЛК"} placement="top">
                            <IconButton>
                              <Folder />
                            </IconButton>
                          </Tooltip>
                        )}
                        <IconButton
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleClickIcon(el.id, !el.isActive);
                          }}
                        >
                          <Eye isActive={el.isActive} />
                        </IconButton>
                        <Tooltip
                          title={"Отображать на главной"}
                          placement="top"
                        >
                          <IconButton
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              handleClickIcon(
                                el.id,
                                undefined,
                                !el.showOnMainPage
                              );
                            }}
                          >
                            <Phone isActive={el.showOnMainPage} />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </Link>
                  );
                })}
            </div>
          </div>
        </Grid>
      </Container>
    </div>
  );
};

export default MainAdminPage;
