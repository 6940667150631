import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
import { FormType } from "../../app/enum";
import AgentActs from "../../pages/agent-acts/routing";
import AgentClients from "../../pages/agent-clients/routing";
import AgentProfilePage from "../../pages/agent-profile/routing";
import ArchiveFilteredProductsPage from "../../pages/archive-filtered-product/routing";
import ClientMessagesPage from "../../pages/client-messages/routing";
import ClientProductDetails from "../../pages/client-product-details/routing";
import ClientSupportChat from "../../pages/client-support-chat/routing";
import KnowledgeBasePage from "../../pages/knowledge-base/routing";
import KnowledgePage from "../../pages/knowledge-page/routing";
import NewOrder from "../../pages/new-order/routing";
import NotFoundPage from "../../pages/not-found/routing";
import FilteredProductsPageNew from "../../pages/products-filtered-new/routing";
import ProposalListPage from "../../pages/proposalList/routing";
import { ROUTES } from "../consts/common";
import { detectDevice } from "../helpers/detectDevice";
import { rootState } from "../types/rootState";

const AgentRoutes = () => {
  const match = useRouteMatch();
  const isAuth = useSelector((s: rootState) => s.currentUser.isAuth);
  const history = useHistory();

  const block = () => {
    history.push({
      pathname: ROUTES.MAIN,
      search: `?formType=${FormType.LoginBlockMob}`,
    });
  };

  return (
    <>
      <Helmet>
        <title>Finlab - кабинет агента</title>
        <meta
          name="description"
          content="Finlab.ru — финансовые услуги для бизнеса. Бесплатный подбор кредитов и 
                    лизинга для бизнеса, факторинг, банковские гарантии, РКО."
        />
      </Helmet>
      {/* <LoginModal /> */}
      {detectDevice() !== "desk" ? (
        block()
      ) : isAuth ? (
        <Switch>
          <Route path={`${match.path}/new-order`} exact>
            {NewOrder}
          </Route>
          <Route path={`${match.path}/profile`} exact>
            {AgentProfilePage}
          </Route>
          <Route path={`${match.path}/form-new-order`} exact>
            {/* {ClientNewOrderPage} */}
            {ProposalListPage}
          </Route>
          <Route path={`${match.path}/products`} exact>
            {FilteredProductsPageNew}
          </Route>
          <Route path={`${match.path}/archive`} exact>
            {ArchiveFilteredProductsPage}
          </Route>
          <Route path={`${match.path}/product/:tab/:productId/:leadId`} exact>
            {ClientProductDetails}
          </Route>
          <Route path={`${match.path}/clients`} exact>
            {AgentClients}
          </Route>
          <Route path={`${match.path}/acts`} exact>
            {AgentActs}
          </Route>
          <Route path={`${match.path}/support`} exact>
            {ClientSupportChat}
          </Route>
          <Route path={`${match.path}/messages`} exact>
            {ClientMessagesPage}
          </Route>
          <Route path={`/agent/knowledge-base`} exact>
            {KnowledgeBasePage}
          </Route>
          <Route path={`${match.path}/knowledge-base/:cardId?`} exact>
            {KnowledgePage}
          </Route>
          {NotFoundPage}
        </Switch>
      ) : (
        <Redirect to={ROUTES.MAIN} />
      )}
    </>
  );
};

export default <Route component={AgentRoutes} path={ROUTES.AGENT} />;
