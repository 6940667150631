import React, { memo, useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { Link, NavLink, useHistory, useLocation } from "react-router-dom";

import {
  Box,
  Button,
  Grid,
  Hidden,
  IconButton,
  Paper,
  Typography,
} from "@material-ui/core";
import { ArrowBack, Close } from "@material-ui/icons";

import { MessageNotification } from "../../../core/api/chatsApi";
import { ROLES, ROUTES } from "../../../core/consts/common";
import ArrowRight from "../../../core/consts/images/ArrowRight";
import logo from "../../../core/consts/images/logo.svg";
import authService from "../../../core/services/AuthService";
import chatService from "../../../core/services/ChatsService";
import notificationsService from "../../../core/services/NotificationService";
import { clientStatuses } from "../../../core/types/managerApiTypes";
import { rootState } from "../../../core/types/rootState";
import { PhoneNumber, PhoneNumberHref } from "../../../pages/contacts/Contacts";
import { FormType } from "../../enum";
import Avatar from "../avatar/Avatar";
import ChatModal from "../chat-modal";
import ChatModalManager from "../chat-modal-manager";
import MainNavigation from "../main-navigation/MainNavigation";
import NotificationsContainer from "../notifications-container";
import RegistrationModal from "../registration";
import RegistrationModalDetail from "../registration/RegistrationDetails";
import { Logo, MobMenu } from "./icons";
import { useStyles } from "./styles";

/** 
 * Header component. Used only for Not Found Page.
 */

const productUrls = [
  {
    name: "Все продукты",
    url: "/products/",
  },
  {
    name: "Кредитование",
    url: "/credits/",
  },
  {
    name: "Банковские гарантии",
    url: "/bankovskiye-garantii/",
  },
  {
    name: "Факторинг",
    url: "/factoring/",
  },
  {
    name: "Лизинг",
    url: "/lizing/",
  },
  {
    name: "Открытие р/с",
    url: "/otkritie-raschetnogo-scheta/",
  },
];
const privateUrls = [
  {
    name: "Потребительский кредит",
    url: "/potrebcredits/",
  },
  {
    name: "Кредитная карта",
    url: "/creditcard/",
  },
  {
    name: "Ипотека",
    url: "/ipoteka/",
  },
];

type ManagerChatData = {
  clientName?: string;
  chatId?: number;
};

const Header: React.FC = () => {
  const classes = useStyles();
  const [mobMenuShown, setMobMenuShown] = useState(false);
  const initialUser = useSelector((state: rootState) => state.currentUser);
  const [isAuthState, setIsAuthState] = useState(initialUser.isAuth);
  const [messagesCount, setMessagesCount] = useState(0);
  const [notificationsCount, setNotificationsCount] = useState(0);
  const history = useHistory();
  const [openChatModal, setOpenChatModal] = React.useState<boolean>(false);
  const [managerChatData, setManagerChatData] =
    React.useState<ManagerChatData>();
  const [isShowMenu, setShowMenu] = useState(false);
  const [isProductsShow, setProductsShow] = useState<string>("");
  const [checked, setChecked] = useState(false);
  const [messages, setMessages] = useState<MessageNotification[]>([]);
  useEffect(() => {
    setIsAuthState(initialUser.isAuth);
  }, [initialUser]);

  const handleLogin = () => {
    const isRestrationed = window.localStorage.getItem("isRegistration");
    setMobMenuShown(false);
    if (isRestrationed) {
      history.push({
        pathname: history.location.pathname,
        search: `?formType=${FormType.LoginFirstEmail}`,
      });
      return;
    }
    // history.push({
    //   pathname: history.location.pathname,
    //   search: `?formType=${FormType.Registration}`,
    // });
    history.push({
      pathname: history.location.pathname,
      search: `?formType=${FormType.LoginFirstPhone}`,
    });
  };

  const toggleMenu = () => {
    setMobMenuShown(!mobMenuShown);
  };

  const closeMobMenu = (e: any) => {
    if (e.target === e.currentTarget) {
      toggleMenu();
    }
  };

  useEffect(() => {
    isAuthState &&
      chatService
        .getChatsCountService()
        .then((res) => {
          setMessagesCount(res.count);
          if (res.newMessages) {
            setMessages((state) => [...res.newMessages]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
  }, [isAuthState, checked]);

  useEffect(() => {
    document.body.style.overflow = mobMenuShown ? "hidden" : "auto";
  }, [mobMenuShown]);

  useEffect(() => {

    isAuthState && initialUser.status !== clientStatuses.NotCompleteRegistration &&
      //initialUser.role !== ROLES.MANAGER &&
      notificationsService
        .getNotificationsCount()
        .then((res: { count: number }) => {
          setNotificationsCount(res.count);
        })
        .catch((error) => {
          console.log(error);
        });
  }, [isAuthState, checked]);

  // checked меняется для проверки количества уведомлений и сообщений. Запрос осуществляется в useEffect выше.
  useEffect(() => {
    let timer: number | undefined;
    if (isAuthState) {
      timer = window.setInterval(() => {
        setChecked((state) => !state);
      }, 30000);
      return () => {
        window.clearInterval(timer);
      };
    } else {
      window.clearInterval(timer);
    }
  }, [isAuthState]);

  const handleLogout = () => {
    authService
      .logout()
      .then((res: any) => {
        history.push(ROUTES.MAIN);
      })
      .catch((error: any) => {
        console.log(error);
      });
  };

  const handleLinkClickScrollUp = () => {
    console.log("@@@@@@@@@@@@@ Header scroll")
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  const onMouseLeaveMenu = () => {
    setTimeout(() => {
      setShowMenu(false);
    }, 500);
  };

  const handleCloseChat = () => {
    setOpenChatModal(false);
  };

  const handleOpenChat = (
    isOpen: boolean,
    clientName: string,
    chatId: number
  ) => {
    setOpenChatModal(isOpen);
    setManagerChatData({
      chatId,
      clientName,
    });
  };

  const location = useLocation();
  return (
    <div className={classes.root}>
      {/* <LoginModal /> */}
      <RegistrationModal />
      <RegistrationModalDetail />
      <Grid container className={classes.gridContainer}>
        <Grid
          onClick={handleLinkClickScrollUp}
          className={classes.logoWrapper}
          item
          sm={1}
          md={1}
          lg={1}
        >
          <Link to={ROUTES.MAIN}>
            <Logo className={classes.oldLogo} />
            <img
              className={classes.logoAbsolute}
              src={logo}
              alt="logo"
              style={{ cursor: "pointer" }}
            />
          </Link>
        </Grid>
        <Hidden only={["sm", "xs"]}>
          <Grid className={classes.mainNavigatorContainer} item md={9} lg={9}>
            <MainNavigation position={true} />
          </Grid>
        </Hidden>
        {mobMenuShown && (
          <Box className={classes.mobMenu} onClick={closeMobMenu}>
            <Button className={classes.btnMenu} onClick={toggleMenu}>
              <Close />
            </Button>
            {!isProductsShow ? (
              <Box className={classes.mobMenuWrap}>
                <Box>
                  {isAuthState && (
                    <Box className={classes.profileBox}>
                      <Typography component="span" variant="body1">
                        {initialUser.nameFull !== "null null" &&
                          initialUser.nameFull}
                      </Typography>
                      <Link
                        to={
                          initialUser.role === ROLES.CLIENT
                            ? `${ROUTES.CLIENT}/products`
                            : initialUser.role === ROLES.AGENT
                              ? `${ROUTES.AGENT}/products`
                              : initialUser.role === ROLES.PARTNER
                                ? `${ROUTES.PARTNER}/profile`
                                : initialUser.role === ROLES.MANAGER
                                  ? `${ROUTES.MANAGER}/profile`
                                  : `${ROUTES.ADMIN}/profile`
                        }
                        style={{ textDecoration: "none" }}
                      >
                        <Typography
                          component="span"
                          variant="body1"
                          className={classes.personalAccoutLink}
                        >
                          В личный кабинет
                        </Typography>
                      </Link>
                    </Box>
                  )}
                  <MainNavigation
                    mobileProductsClick={setProductsShow}
                    position={true}
                  />
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={isAuthState ? handleLogout : handleLogin}
                    type="submit"
                    className={classes.button}
                  >
                    <Typography
                      component="span"
                      variant="body1"
                      style={{
                        color: "#306F9A",
                        textTransform: "none",
                        lineHeight: "3",
                      }}
                    >
                      {isAuthState ? "Выйти" : "В кабинет"}
                    </Typography>
                  </Button>
                </Box>
                <Typography
                  color="primary"
                  variant="h3"
                  component="a"
                  href="tel:88005003593"
                  className={classes.phone}
                >
                  {PhoneNumber}
                </Typography>
              </Box>
            ) : (
              <Box className={classes.mobMenuWrap}>
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <IconButton
                      style={{ paddingLeft: 0 }}
                      onClick={() => setProductsShow("")}
                    >
                      <ArrowBack style={{ fontSize: "24px", color: "black" }} />
                    </IconButton>
                    <div
                      style={{
                        color: "#B0BFC6",
                        fontSize: "16px",
                        fontFamily: "Gilroy",
                        fontWeight: 500,
                        padding: 0,
                      }}
                    >
                      Продукты
                    </div>
                  </div>
                  <div className={classes.productsContainer}>
                    {(isProductsShow === "products"
                      ? productUrls
                      : privateUrls
                    ).map((el, key) => {
                      return (
                        <NavLink
                          onClick={() => {
                            setMobMenuShown(false);
                            setProductsShow("");
                          }}
                          className={classes.product}
                          to={el.url}
                          key={key}
                        >
                          {location.pathname.includes(el.url) && (
                            <div
                              style={{
                                position: "absolute",
                                left: "-25px",
                                width: "3px",
                                height: "35px",
                                background: "#309A90",
                              }}
                            />
                          )}
                          <div
                            style={
                              location.pathname.includes(el.url)
                                ? { color: "#B0BFC6" }
                                : {}
                            }
                          >
                            {el.name}
                          </div>
                          <ArrowRight />
                        </NavLink>
                      );
                    })}
                  </div>
                </div>
                <Typography
                  color="primary"
                  variant="h3"
                  component="a"
                  href={PhoneNumberHref}
                  className={classes.phone}
                >
                  {PhoneNumber}
                </Typography>
              </Box>
            )}
          </Box>
        )}
        <Hidden only={["sm", "xs"]}>
          <Grid
            className={classes.mainNavigatorContainer}
            item
            lg={2}
            style={{ justifyContent: "flex-end", display: "flex" }}
          >
            {isAuthState ? (
              <Box
                // onMouseLeave={onMouseLeaveMenu}
                style={{
                  position: "relative",
                  marginTop: "10px",
                  paddingBottom: "10px",
                  right: "100px",
                  paddingLeft: "100px",
                }}
              >
                <span
                  style={{ cursor: "pointer" }}
                  onMouseEnter={() => setShowMenu(true)}
                >
                  <Avatar
                    currentChatName={initialUser.nameFull}
                    url={
                      initialUser.role === ROLES.CLIENT
                        ? `${ROUTES.CLIENT}/profile`
                        : initialUser.role === ROLES.AGENT
                          ? `${ROUTES.AGENT}/profile`
                          : initialUser.role === ROLES.PARTNER
                            ? `${ROUTES.PARTNER}/profile`
                            : initialUser.role === ROLES.MANAGER
                              ? `${ROUTES.MANAGER}/profile`
                              : `${ROUTES.ADMIN}/profile`
                    }
                    info={initialUser.nameFull}
                  />
                </span>
                {isShowMenu && (
                  <Paper
                    className={classes.userInfo}
                    onMouseLeave={onMouseLeaveMenu}
                  >
                    <Typography component="h4" variant="h4">
                      {initialUser.nameFull === "null null"
                        ? ""
                        : initialUser.nameFull}
                    </Typography>
                    <Typography
                      component="span"
                      variant="body2"
                      style={{ color: "#B0BFC6" }}
                    >
                      {initialUser.role}
                    </Typography>
                    <Link
                      color="primary"
                      to={
                        initialUser.role === ROLES.CLIENT
                          ? `${ROUTES.CLIENT}/products`
                          : initialUser.role === ROLES.AGENT
                            ? `${ROUTES.AGENT}/products`
                            : initialUser.role === ROLES.PARTNER
                              ? `${ROUTES.PARTNER}/applications`
                              : initialUser.role === ROLES.MANAGER
                                ? `${ROUTES.MANAGER}/clients`
                                : `${ROUTES.ADMIN}/clients`
                      }
                      style={{ textDecoration: "none" }}
                    >
                      <Typography component="p" variant="body1">
                        В личный кабинет
                      </Typography>
                    </Link>
                    {initialUser.status !==
                      clientStatuses.NotCompleteRegistration && (
                        <Link
                          color="primary"
                          to={
                            initialUser.role === ROLES.CLIENT
                              ? `${ROUTES.CLIENT}/messages`
                              : initialUser.role === ROLES.AGENT
                                ? `${ROUTES.AGENT}/messages`
                                : initialUser.role === ROLES.PARTNER
                                  ? `${ROUTES.PARTNER}/messages`
                                  : initialUser.role === ROLES.MANAGER
                                    ? `${ROUTES.MANAGER}/messages`
                                    : `${ROUTES.ADMIN}/messages`
                          }
                          style={{ textDecoration: "none" }}
                        >
                          <Typography
                            component="p"
                            variant="body1"
                            style={{ position: "relative" }}
                          >
                            Сообщения
                            {messagesCount + notificationsCount > 0 && (
                              <Typography
                                variant="body2"
                                component="span"
                                className={classes.messages}
                              >
                                {messagesCount + notificationsCount}
                              </Typography>
                            )}{" "}
                          </Typography>
                        </Link>
                      )}
                    <Typography
                      onClick={handleLogout}
                      component="a"
                      variant="body2"
                      style={{
                        color: "#617E8C",
                        borderTop: "1px solid #F2F2F2",
                        padding: "15px 0 0",
                      }}
                    >
                      Выйти
                    </Typography>
                  </Paper>
                )}
              </Box>
            ) : (
              <Button
                color="primary"
                variant="outlined"
                onClick={handleLogin}
                type="submit"
                className={classes.button}
              >
                <Typography
                  component="span"
                  variant="body1"
                  className={classes.enter}
                  style={{
                    color: "inherit",
                    textTransform: "none",
                    lineHeight: "3",
                  }}
                >
                  В кабинет
                </Typography>
              </Button>
            )}
          </Grid>
        </Hidden>
        <Hidden only={["xl", "lg"]}>
          <Button className={classes.btnMenu} onClick={toggleMenu}>
            <MobMenu />
          </Button>
        </Hidden>
        <NotificationsContainer
          notifications={messages}
          position="rightTop"
          isOpenChat={openChatModal}
          openChatModal={handleOpenChat}
        />
        {(initialUser.role === ROLES.MANAGER ||
          initialUser.role === ROLES.ADMIN) &&
          managerChatData?.chatId &&
          managerChatData?.clientName && (
            <ChatModalManager
              open={openChatModal}
              close={handleCloseChat}
              currentChatId={String(managerChatData.chatId)}
              clientName={managerChatData.clientName}
            />
          )}
        {initialUser.role !== ROLES.MANAGER &&
          initialUser.role !== ROLES.ADMIN && (
            <ChatModal open={openChatModal} close={handleCloseChat} />
          )}
      </Grid>
    </div>
  );
};

export default memo(Header);
