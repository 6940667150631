import {
  Theme,
  createStyles,
  makeStyles
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SpinnerMain from "../../../../UIcomponentsRedesign/SpinnerMain";
import EmptyPage from "../../../../app/components/empty-page/EmptyPage";
import SortingLine from "../../../../app/components/sorting-line/SortingLine";
import {
  notificationType
} from "../../../../core/api/notificationsApi";
import { COLORS, DateSortType, ROLES } from "../../../../core/consts/redesign/commonRedesign";
import { formatDateTime } from "../../../../core/helpers/dateConverter";
import notificationsService from "../../../../core/services/NotificationService";
import { rootState } from "../../../../core/types/rootState";
type Props = {
  leadId: number;
  setResetUnread: React.Dispatch<React.SetStateAction<boolean>>;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
      display: 'flex',
      flexDirection: "column",
      alignItems: "center",
      gap: '4px',
    },
    notificationWrapper: {
      width: '100%',
      display: 'flex',
      flexDirection: "row",
      alignItems: "flex-start",
      justifyContent: "space-between",
      padding: "16px",
      background: COLORS.white,
      borderRadius: "8px",
      [theme.breakpoints.down(1024)]: {
        flexDirection: "column",
        gap: "24px"
      },
    },
    descBox: {
      width: '70%',
      display: 'flex',
      flexDirection: "column",
      alignItems: "flex-start",
      gap: '24px',
      [theme.breakpoints.down(1024)]: {
        width: '100%',
      },
    },
    titleBox: {
      display: 'flex',
      flexDirection: "column",
      alignItems: "flex-start",
      gap: '4px',
    },

    title: {
      color: COLORS.black,
      fontSize: "16px",
      lineHeight: "21px",
      fontFamily: "Gilroy",
      fontWeight: 600,
    },
    text: {
      color: COLORS.gray2,
      fontSize: "16px",
      lineHeight: "21px",
      fontFamily: "Gilroy",
      fontWeight: 500,
    },
    boxWithRound: {
      display: 'flex',
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      gap: '4px',
    }
  })
);

const Notifications: React.FC<Props> = ({ leadId, setResetUnread }) => {
  const classes = useStyles();
  const initialUser = useSelector((state: rootState) => state.currentUser);
  const isManager = initialUser.role === ROLES.MANAGER;
  const [isLoading, setIsLoading] = useState(true);
  const [notifications, setNotifications] = useState<notificationType[]>([]);
  const [totalItems, setTotalItems] = useState<number>(0)
  const [searchPeriod, setSearchPeriod] = useState<DateSortType>(DateSortType.sort_desc);
  const [readNotificationIds, setReadNotificationIds] = useState<number[]>([]);
  const [unreadNotificationIds, setUnreadNotificationIds] = useState<number[]>([]);

  //sort notifications on frontend
  useEffect(() => {
    if (searchPeriod === DateSortType.sort_desc) {
      setNotifications((currentNotifications) => [...currentNotifications].reverse());
    }
  }, [searchPeriod]);

  useEffect(() => {
    let queryParams = { onlyUnread: false, leadId };
    notificationsService.getNotificationsListService(queryParams)
      .then(res => {
        setNotifications(res.result);
        setTotalItems(res.pagination.total);
        const unreadIds = res.result
          .filter(notification => !notification.isRead)
          .map(notification => notification.id);
        setUnreadNotificationIds(unreadIds);
      })
      .catch(console.error)
      .finally(() => setIsLoading(false));
  }, [leadId]);

  // useEffect(() => {
  //   if (unreadNotificationIds.length === 0) {
  //     setTimeout(() => {
  //       setResetUnread(true)
  //       console.log("trigger reset", unreadNotificationIds);
  //     }, 3000)
  //   }
  // }, [unreadNotificationIds])

  useEffect(() => {
    const unreadToProcess = unreadNotificationIds.filter(id => {
      const notification = notifications.find(n => n.id === id);
      if (isManager) {
        return !readNotificationIds.includes(id) && notification?.forManager === true;
      } else {
        return !readNotificationIds.includes(id);
      }
    });

    if (unreadToProcess.length > 0) {
      const markReadPromises = unreadToProcess.map(id =>
        new Promise<number>((resolve, reject) => {
          setTimeout(() => {
            notificationsService.readNotification(id.toString())
              .then(() => {
                resolve(id);
              })
              .catch(error => {
                console.error(`Failed to mark notification ${id} as read:`, error);
                reject(error);
              });
          }, 1000);
        })
      );

      Promise.all(markReadPromises)
        .then(completedIds => {
          setReadNotificationIds(prevIds => {
            const allIds = [...prevIds, ...completedIds];
            const uniqueIds = new Set<number>(allIds);
            return Array.from(uniqueIds);
          });
          // setResetUnread(true)
        })
        .catch(error => {
          console.log(`Some notifications failed to be marked as read:`, error);
        })
        .finally(() => {
          setResetUnread(true)
        });
    }
  }, [unreadNotificationIds, readNotificationIds]);


  return (
    <>
      {isLoading ? (
        <SpinnerMain />
      ) : (
        <>
          {totalItems &&
            <SortingLine
              extraMargin
              isForNotifications
              totalItems={totalItems}
              searchPeriod={searchPeriod}
              setSearchPeriod={setSearchPeriod}
            />}
          <div className={classes.container}>
            {notifications.length > 0 ?
              notifications.map((el, key) => {
                const isRead = readNotificationIds.includes(el.id) || el.isRead;
                return (
                  <div key={el.id} className={classes.notificationWrapper} data-id={el.id} data-read={el.isRead ? 'true' : 'false'}>
                    <div className={classes.descBox}>
                      <div className={classes.titleBox}>
                        {!isRead ?
                          (
                            <div className={classes.boxWithRound}>
                              <div className="round_blue_small" />
                              <h4 className={classes.title}>{el.proposal.name}</h4>
                            </div>
                          ) : (
                            <h4 style={{ color: COLORS.gray2 }} className={classes.title}>{el.proposal.name}</h4>
                          )}
                        <span className={classes.text}>
                          {formatDateTime(el.dateAdd)}
                        </span>
                      </div>
                      <span className={classes.text}>
                        {el.text}
                      </span>
                    </div>
                    {/* <ButtonCustom style={{ width: isMobile ? '100%' : "210px" }} color="primary">Загрузить документы</ButtonCustom> */}
                  </div>
                );
              }) :
              <EmptyPage
                margin="80px"
                maxWidth="600px"
                gap="16px"
                titleColor={COLORS.gray2}
                title='Здесь будут находиться уведомления'
                content="Все события по вашей заявке будут храниться на этом экране"
              />}
          </div>
        </>)}
    </>
  );
};

export default Notifications;
