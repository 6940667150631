import {
  Button,
  Container,
  Tab,
  Tabs,
  Theme,
  Tooltip,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import React, { memo, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { v4 } from "uuid";
import ClientDetail from "../../app/components/components-product-page/client-detail/ClientDetail";
import DetailsChat from "../../app/components/components-product-page/details-chat";
import Status from "../../app/components/components-product-page/status";
import ConfirmModal from "../../app/components/confirm-modal";
import HeaderNew from "../../app/components/redesign/Header/HeaderNew";
import { ROUTES } from "../../core/consts/common";
import chatService from "../../core/services/ChatsService";
import introspectService from "../../core/services/IntropsectService";
import leadsService from "../../core/services/LeadsService";
import partnerService from "../../core/services/PartnerService";
import { LeadDocType } from "../../core/types/leadsApiTypes";
import ArchiveStatusModal from "./components/ArchiveStatusModal";
import ChangeStatusModal from "./components/ChangeStatusModal";
import CustomSnackBar from "./components/CustomSnackBar";
import DocumentModal from "./components/DocumentModal";
import DocumentCard from "./components/document-card";

/* Redesign . Client's product page for partner */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      maxWidth: "100%",
      padding: '40px 80px',
      margin: "0 auto",
      boxSizing: "border-box",
    },
    grid: {
      gridTemplateColumns: "1fr 1fr",
      gridTemplateAreas: "'l r'",
      gridGap: "30px",
      paddingTop: "30px",
      display: "grid",
      width: "100%",
    },
    leftInfo: {
      gridArea: "l",
      height: "fit-content",
    },
    rightInfo: {
      gridArea: "r",
    },
    title: {
      color: "#131313",
      fontFamily: "Gilroy",
      fontWeight: 600,
      fontSize: "18px",
    },
    showMore: {
      color: "#306F9A",
      "&:hover": {
        cursor: "pointer",
        color: "#204A66",
      },
    },
    status: {
      background: "white",
      padding: "30px",
      borderRadius: "10px",
    },
    documents: {
      background: "white",
      padding: "30px",
      borderRadius: "10px",
      marginTop: "30px",
    },
    statusTitle: {
      color: "#131313",
      fontFamily: "Gilroy",
      fontWeight: 500,
      fontSize: "18px",
    },
    btn: {
      borderRadius: "4px",
    },
    btnText: {
      border: "1px solid #306F9A",
      padding: "18px 36px",
    },
    textDoc: {
      fontSize: "16px",
      color: "#306F9A",
      fontFamily: "Gilroy",
      fontWeight: 500,
      textTransform: "none",
    },
    textDocDisabled: {
      fontSize: "16px",
      color: "white",
      fontFamily: "Gilroy",
      fontWeight: 500,
      textTransform: "none",
    },
    text: {
      textTransform: "none",
      color: "white",
      fontSize: "14px",
      fontFamily: "Gilroy",
      fontWeight: 500,
    },
    btnsContainer: {
      display: "flex",
      justifyContent: "space-between",
      // padding: "30px 30px 0",
    },
    changeStatusBtn: {
      background: "#306F9A",
      borderRadius: "4px",
      padding: "18px 36px",
      marginLeft: "15px",
    },
    tabs: {
      position: "relative",
      borderBottom: "1px solid #D0D9DE",
      display: "flex",
      [theme.breakpoints.down("md")]: {
        "&>div": {
          overflowX: "scroll!important",
          scrollbarWidth: "none",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        },
      },
    },
    tab: {
      fontFamily: "Gilroy",
      fontWeight: 500,
      fontStyle: "normal",
      fontSize: "16px",
      lineHeight: "1.3",
      color: "#131313",
      textTransform: "none",
    },
    doc: {
      height: "100%",
      width: "100%",
      display: "flex",
      alignItems: "center",
      marginLeft: "15px",
      color: "#306F9A",
      fontFamily: "Gilroy",
      fontWeight: 500,
      borderBottom: "1px solid #D0D9DE",
      cursor: "pointer",
      "&:hover": {
        color: "#204A66",
      },
    },
    disabledBtn: {
      background: "#E0E0E0",
      padding: "18px 36px",
      marginTop: "30px",
    },
    messagesCount: {
      position: "absolute",
      width: "fit-content",
      padding: "2px 8px",
      backgroundColor: "#309A90",
      borderRadius: "10px",
      color: "#ffffff",
      justifyContent: "center",
      top: "5%",
      right: "8%",
    },
  })
);

type PropsType = {
  match: {
    params: {
      tab: string;
      leadId: string;
    };
  };
};

const PartnerCurrentApplicationPage: React.FC<PropsType> = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [currentApplication, setCurrentApplication] = useState<null | any>(
    null
  );
  const tab = props.match.params.tab;
  const [currentDocs, setCurrentDocs] = useState<null | any>(null);
  const applicationId = props.match.params.leadId;
  const [requstStatus, setRequstStatus] = useState<boolean>(false);
  const [documentRequestStatus, setDocumentRequestStatus] = useState(false);
  const [documentShownAll, setDocumentShownAll] = useState(false);
  const [archiveRequestStatus, setArchiveRequestStatus] = useState(false);
  const [currentTab, setTab] = useState(parseInt(tab));
  const [messagesCount, setMessagesCount] = useState("0");
  const [isChangeStatusModal, setChangeStatusModal] = useState(false);
  const [isDocumentModal, setDocumentModal] = useState(false);
  const [isArchiveModal, setArchiveModal] = useState(false);
  const [isBtnMoreNeed, setBtnMoreNeed] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [docsSentStatus, setDocsSentStatus] = useState<string>("");
  const [showButton, setShowButton] = useState(false);
  const [isErrorModal, setErrorModal] = useState(false)
  const fullTitle = `${"Заявка"} ${currentApplication?.id || applicationId}`;


  useEffect(() => {
    leadsService
      .getLeadByIdService(applicationId!)
      .then((res: any) => {
        setCurrentApplication(res);
      })
      .catch((error: any) => {
        introspectService.checkToken(error.response.status.toString());
        if (error.response.status === 403) {
          setErrorModal(true)
        }
        console.log(error);
      });
  }, [
    requstStatus,
    documentRequestStatus,
    archiveRequestStatus,
    applicationId,
  ]);

  useEffect(() => {
    leadsService
      .getLeadDocsService(parseInt(applicationId))
      .then((res: LeadDocType[]) => {
        setCurrentDocs(res.reverse());
      })
      .catch((error: any) => {
        console.log(error);
      });
  }, [applicationId, documentRequestStatus]);

  useEffect(() => {
    leadsService
      .getLeadDocsSendStatusService(parseInt(applicationId))
      .then((res) => {
        setDocsSentStatus(res.sendDocsState);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [applicationId]);

  useEffect(() => {
    chatService
      .getLeadMessagesCountService(applicationId!)
      .then((res) => {
        setMessagesCount(res.count.toString());
        tab === "1"
          ? setMessagesCount("0")
          : setMessagesCount(res.count.toString());
      })
      .catch((error) => {
        console.log(error);
      });
  }, [applicationId]);

  const handleClickArchive = () => {
    partnerService.putLeadArchive(+applicationId!);
  };

  const a11yProps = (index: number) => {
    return {
      id: `tab-${index}`,
      "aria-controls": `tabpanel-${index}`,
    };
  };

  useEffect(() => {
    if (currentApplication?.leadStatusHistory.length > 5) {
      setBtnMoreNeed(true);
    }
  }, [currentApplication?.leadStatusHistory.length]);

  const onChangeTab = (e: any, newValue: string) => {
    if (newValue === "1") {
      currentApplication ? setTab(parseInt(newValue)) : setDocumentModal(true);
    } else setTab(parseInt(newValue));
  };

  const handleClickTab = (value: number) => {
    value === 1 && setMessagesCount("0");
    setTab(value);
  };

  useEffect(() => {
    if (currentDocs) {
      const divShowMore = window.document.getElementById("showMore");
      if (divShowMore) {
        if (divShowMore.scrollHeight > divShowMore.clientHeight + 1) {
          setShowButton(true);
        }
      }
    }
  }, [currentDocs, currentTab]);

  const routeToSupport = () => {
    history.push(`${ROUTES.PARTNER}/support`)
  }

  const routeToApplications = () => {
    history.push(`${ROUTES.PARTNER}/applications`)

  }

  return (
    <div>
      <HeaderNew title={fullTitle} showGoBack />
      <Container className={classes.container}>
        <div className={classes.btnsContainer}>
          <div>
            <Tabs
              TabIndicatorProps={{ style: { background: "#309A90" } }}
              value={currentTab}
              aria-label="tabs"
              onChange={onChangeTab}
              className={classes.tabs}
            >
              {["Общая информация", "Чат"].map((item, index) => (
                <Tab
                  className={classes.tab}
                  label={item}
                  onClick={() => handleClickTab(index)}
                  key={v4()}
                  {...a11yProps(index)}
                />
              ))}
              {messagesCount && messagesCount !== "0" && (
                <Typography
                  variant="body2"
                  component="span"
                  className={classes.messagesCount}
                >
                  {messagesCount}
                </Typography>
              )}
            </Tabs>
          </div>
          <div>
            {!currentApplication?.isArchive ? (
              <>
                <Button
                  color={"primary"}
                  className={classes.btnText}
                  variant="text"
                  onClick={() => setArchiveModal(true)}
                >
                  <Typography
                    variant={"subtitle2"}
                    className={classes.textDoc}
                    component={"span"}
                  >
                    Перенести в архив
                  </Typography>
                </Button>
                <Button
                  color={"primary"}
                  className={classes.changeStatusBtn}
                  variant="contained"
                  onClick={() => setChangeStatusModal(true)}
                >
                  <Typography
                    variant={"body1"}
                    className={classes.text}
                    component={"span"}
                    style={{ fontSize: "16px" }}
                  >
                    Изменить статус
                  </Typography>
                </Button>
              </>
            ) : (
              <Button
                color={"primary"}
                className={classes.disabledBtn}
                variant="text"
                style={{ marginTop: "0px" }}
                disabled={true}
              >
                <Typography
                  variant={"subtitle2"}
                  className={classes.textDocDisabled}
                  component={"span"}
                >
                  Заявка в архиве
                </Typography>
              </Button>
            )}
          </div>
        </div>
        {currentTab === 0 ? (
          <div className={classes.grid}>
            <div className={classes.leftInfo}>
              {currentApplication && (
                <ClientDetail
                  title="Клиент"
                  proposalsItems={currentApplication.groupFieldsProposalItems}
                  filedsArr={currentApplication.fieldsProposalItems}
                  errorMessage={"Ошибка"}
                />
              )}
            </div>
            <div className={classes.rightInfo}>
              <div className={classes.status}>
                <div className={classes.statusTitle}>Статус заявки</div>
                <div className={classes.btn}>
                  {currentApplication &&
                    (currentApplication?.leadStatusHistory.length > 5 &&
                      !showMore ? (
                      <Status
                        stages={currentApplication?.leadStatusHistory.slice(
                          0,
                          5
                        )}
                      />
                    ) : (
                      <Status stages={currentApplication?.leadStatusHistory} />
                    ))}
                </div>
                <div
                  style={{
                    color: "#306F9A",
                    fontFamily: "Gilroy",
                    fontWeight: 500,
                    fontSize: "16px",
                    cursor: "pointer",
                  }}
                  onClick={() => setShowMore((state) => !state)}
                >
                  {isBtnMoreNeed &&
                    (showMore ? "Свернуть" : "Смотреть полностью")}
                </div>
              </div>
              <div className={classes.documents}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div className={classes.statusTitle}>
                    Прикрепленные документы
                  </div>
                  {currentApplication?.isArchive === false && (
                    <Tooltip
                      title={
                        docsSentStatus === "DocsUploading"
                          ? "В данный момент запрос невозможен - ожидается загрузка документов клиентом"
                          : ""
                      }
                      placement="top"
                    >
                      <Button
                        color={"primary"}
                        variant="text"
                        className={classes.btnText}
                        onClick={() =>
                          docsSentStatus !== "DocsUploading" &&
                          setDocumentModal(true)
                        }
                      >
                        <Typography
                          variant="body1"
                          component="span"
                          className={classes.textDoc}
                        >
                          Запросить документ
                        </Typography>
                      </Button>
                    </Tooltip>
                  )}
                </div>
                <div
                  id={"showMore"}
                  style={{
                    maxHeight: `${documentShownAll ? "fit-content" : "calc(100vh - 655px)"
                      }`,
                    overflow: "hidden",
                    marginBottom: "50px",
                  }}
                >
                  {currentDocs?.map((el: LeadDocType) => {
                    return <DocumentCard doc={el} />;
                  })}
                </div>
                {showButton && (
                  <Typography
                    onClick={() => setDocumentShownAll(!documentShownAll)}
                    variant="body1"
                    component="span"
                    className={classes.showMore}
                  >
                    {documentShownAll ? "Свернуть" : "Смотреть полностью"}
                  </Typography>
                )}
              </div>
            </div>
          </div>
        ) : (
          applicationId && (
            <div style={{ margin: "30px" }}>
              <DetailsChat
                id={+applicationId}
                isInputNeeded={!currentApplication?.isArchive}
              />
            </div>
          )
        )}
      </Container>
      {isChangeStatusModal && (
        <ChangeStatusModal
          requstStatus={requstStatus}
          setRequstStatus={setRequstStatus}
          setChangeStatusModal={setChangeStatusModal}
          isOpen={isChangeStatusModal}
        />
      )}
      {isDocumentModal && currentApplication ? (
        <DocumentModal
          setDocumentModal={setDocumentModal}
          isOpen={isDocumentModal}
          leadId={currentApplication.id}
          setDocumentRequestStatus={setDocumentRequestStatus}
        />
      ) : (
        <ConfirmModal
          handleCancel={() => setDocumentModal(false)}
          setModalProps={setDocumentModal}
          title="Упс, что-то пошло не так"
          cancelBtnText="Закрыть"
          open={isDocumentModal}
        />
      )}
      {isArchiveModal && (
        <ArchiveStatusModal
          isOpen={isArchiveModal}
          setCloseModal={setArchiveModal}
          handleArchive={handleClickArchive}
          archiveRequestStatus={archiveRequestStatus}
          setArchiveRequestStatus={setArchiveRequestStatus}
        />
      )}
      {requstStatus && (
        <CustomSnackBar
          isOpen={requstStatus}
          handleClose={() => setRequstStatus(false)}
          title={"Статус успешно изменен"}
          subTitle={"Клиент увидит измененный статус в своем личном кабинете"}
        />
      )}
      {documentRequestStatus && (
        <CustomSnackBar
          isOpen={documentRequestStatus}
          handleClose={() => setDocumentRequestStatus(false)}
          title={"Запрос успешно отправлен"}
          subTitle={"Клиент получит уведомление в своем личном кабинете"}
        />
      )}
      {archiveRequestStatus && (
        <CustomSnackBar
          isOpen={archiveRequestStatus}
          handleClose={() => setArchiveRequestStatus(false)}
          title={"Заявка архивирована"}
          subTitle={"Теперь данная заявка находится в разделе «Архив заявок»"}
        />
      )}
      {isErrorModal && (
        <ConfirmModal
          handleConfirm={routeToApplications}
          title={"Недостаточно прав для просмотра этой страницы"}
          handleCancel={routeToSupport}
          confBtnText="К заявкам"
          cancelBtnText="В техподдержку"
          isExtraCloseModal={true}
          open={isErrorModal}
        />
      )}
    </div>
  );
};

export default memo(PartnerCurrentApplicationPage);
