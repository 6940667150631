import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import AdminCreateArticle from "../../pages/admin-create-article/routing";
import AdminCreateNews from "../../pages/admin-create-news/routing";
import AdminCreateProduct from "../../pages/admin-create-product/routing";
import AdminEditArticle from "../../pages/admin-edit-article/routing";
import AdminEditNews from "../../pages/admin-edit-news/routing";
import AdminEditProduct from "../../pages/admin-edit-product/routing";
import ArchiveFilteredProductsPage from "../../pages/archive-filtered-product/routing";
import ClientMessagesPage from "../../pages/client-messages/routing";
import ClientProductDetails from "../../pages/client-product-details/routing";
import MainAdminPage from "../../pages/main-admin-page/route";
import MainArticlesPage from "../../pages/main-articles-page/route";
import MainNewsPage from "../../pages/main-news-page/route";
import ManagerClientPage from "../../pages/manager-clients/routing";
import ManagerProfilePage from "../../pages/manager-profile/routing";
import NotFoundPage from "../../pages/not-found/routing";
import ProductsFilteredPageNew from "../../pages/products-filtered-new/routing";
import StatisticPage from "../../pages/statistic/routing";
import { ROUTES } from "../consts/common";
import { rootState } from "../types/rootState";



const AdminRoutes = () => {
  const match = useRouteMatch();
  const isAuth = useSelector((s: rootState) => s.currentUser.isAuth);

  return (
    <>
      <Helmet>
        <title>Finlab - кабинет администратора</title>
        <meta
          name="description"
          content="Finlab.ru — финансовые услуги для бизнеса. Бесплатный подбор кредитов и 
                    лизинга для бизнеса, факторинг, банковские гарантии, РКО."
        />
      </Helmet>
      {isAuth ? (
        <Switch>
          <Route path={`${match.path}/profile`} exact>
            {ManagerProfilePage}
          </Route>
          <Route path={`${match.path}/products`} exact>
            {ProductsFilteredPageNew}
          </Route>
          <Route path={`${match.path}/archive`} exact>
            {ArchiveFilteredProductsPage}
          </Route>
          <Route path={`${match.path}/product/:tab/:productId/:leadId`} exact>
            {ClientProductDetails}
          </Route>
          <Route path={`${match.path}/clients`} exact>
            {ManagerClientPage}
          </Route>
          <Route path={`${match.path}/messages`} exact>
            {ClientMessagesPage}
          </Route>
          <Route path={`${match.path}/edit-products`} exact >
            {MainAdminPage}
          </Route>
          <Route path={`${match.path}/edit-news`} exact>
            {MainNewsPage}
          </Route>
          <Route path={`${match.path}/edit-news/new-article/`} exact>
            {AdminCreateNews}
          </Route>
          <Route path={`${match.path}/edit-news/new-article/:id`} exact >
            {AdminEditNews}
          </Route>
          <Route path={`${match.path}/edit-products/new-product`} exact >
            {AdminCreateProduct}
          </Route>
          <Route path={`${match.path}/edit-products/new-product/:id`} exact >
            {AdminEditProduct}
          </Route>
          <Route path={`${match.path}/edit-articles`} exact>
            {MainArticlesPage}
          </Route>
          <Route path={`${match.path}/edit-articles/new-article/`} exact>
            {AdminCreateArticle}
          </Route>
          <Route path={`${match.path}/edit-articles/new-article/:id`} exact >
            {AdminEditArticle}
          </Route>
          <Route path={`${match.path}/statistic`} exact >
            {StatisticPage}
          </Route>
          {NotFoundPage}
        </Switch>
      ) : (
        <Redirect to={ROUTES.MAIN} />
      )}
    </>
  );
};

export default <Route component={AdminRoutes} path={ROUTES.ADMIN} />;
