import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import React, { memo, useLayoutEffect, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { COLORS } from "../../../core/consts/redesign/commonRedesign";
import { checkIsToday, dateConverterLongNoTime } from "../../../core/helpers/dateConverter";
import { rootState } from "../../../core/types/rootState";
import ChatErrorMessage from "./ChatErrorMessage";
import ChatMessage from "./ChatMessage";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: 'center',
      justifyContent: 'flex-start',
      width: "100%",
      height: "100vh",
      overflowY: "auto",
      overflowX: "hidden",
      gap: "8px",
      padding: "0 8px",
    },
    spacer: {
      flex: "1 1 auto",
    },
    dateSeparator: {
      textAlign: "center",
      fontSize: '14px',
      lineHeight: '19px',
      fontWeight: 500,
      color: COLORS.light_gray_stroke,
      margin: "8px",
      padding: 0
    },
  })
);

const ChatMessages = ({ downloadDoc, uploadError, handleCancel }: {
  downloadDoc: (id: string, name: string) => void,
  uploadError: boolean,
  handleCancel: (id: number) => void
}) => {
  const chatData = useSelector((state: rootState) => state.currentChat);
  const classes = useStyles();
  const messages = chatData.chatMessages;
  let lastDate = '';
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  useLayoutEffect(scrollToBottom, [messages?.length]);

  const reversedMessages = useMemo(() => [...(messages ?? [])].reverse(), [messages]);

  return (
    <div className={classes.container}>
      <div className={classes.spacer} />
      {reversedMessages && reversedMessages.map((msg) => {
        const messageDate = msg.dateMessage && dateConverterLongNoTime(msg.dateMessage);
        const isNewDate = lastDate !== messageDate;
        let dateSeparator = null;
        if (isNewDate) {
          dateSeparator = msg.dateMessage && checkIsToday(msg.dateMessage)
            ? <div key={`${msg.id}-separator-today`} className={classes.dateSeparator}>Сегодня, {messageDate}</div>
            : <div key={`${msg.id}-separator`} className={classes.dateSeparator}>{messageDate}</div>;
          lastDate = messageDate!;
        }
        return (
          <>
            <React.Fragment key={`${msg.id}-fragment`}>
              {dateSeparator && <div key={`${msg.id}-dateSeparator`}>{dateSeparator}</div>}
              <ChatMessage
                key={msg.id}
                message={msg}
                downloadDoc={downloadDoc}
                handleCancel={() => handleCancel(msg.id)}
              />
            </React.Fragment>
          </>
        );
      })}
      {uploadError && <ChatErrorMessage />}
      <div ref={messagesEndRef} />
    </div>
  );
};

export default memo(ChatMessages);