import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { COLORS } from '../../../core/consts/redesign/commonRedesign';
import { AlertTriangle } from '../../../core/consts/redesign/icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    message: {
      backgroundColor: COLORS.alert,
      color: COLORS.white,
      alignSelf: 'flex-end',
      borderRadius: '8px 8px 0px 8px',
      padding: '12px 12px 16px',
      minWidth: "120px",
      maxWidth: '80%',
      height: 'fit-content',
      position: "relative",
      overflowWrap: 'break-word',
      wordBreak: 'break-word',
      whiteSpace: 'pre-wrap',
      // hyphens: 'auto'
    },
    messageFontDoc: {
      fontSize: '16px',
      lineHeight: '21px',
      fontWeight: 500,
      textAlign: 'left'
    },
    docBox: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: 'center',
      width: "100%",
      gap: "16px",
    },
  })
);

const ChatErrorMessage = () => {
  const classes = useStyles();
  return (
    <div className={classes.message}>
      <div className={classes.docBox}>
        <AlertTriangle color={COLORS.white} />
        <span className={classes.messageFontDoc}>
          {"Не удалось загрузить файл\nФормат: pdf, doc, jpg, png, heic\nМаксимальный размер: 40 мб"}
        </span>
      </div>
    </div>
  );
};

export default ChatErrorMessage;
