import { dateConverter } from "../helpers/dateConverter";
import { http } from "../infrastructure/Https";
import tokenService from "../services/TokenService";
import { newsType } from "../types/newsTypes";

const ROOT_URL = '/api/v1/news'

class NewsApi {

    getCategoryList() {
        return http.get(`${ROOT_URL}/category`)
    }

    addCategory(B: { name: string, parent: number }) {
        const accessToken = tokenService.getAccessToken()
        return http.post(`${ROOT_URL}/category`, null, B, accessToken)
    }

    getNewsList(Q: { category?: number, count?: number, offset?: number, Statuses?: string, Sort?: string }) {
        return http.get(`${ROOT_URL}`, Q)
    }
    postNews(data: any) {
        const accessToken = tokenService.getAccessToken()
        const formData = new FormData();
        formData.append('Title', data.title)
        formData.append('ShortText', data.shortText)
        formData.append('Text', data.text)
        if (data.thumbnail) formData.append('Thumbnail', data.thumbnail)
        if (data.thumbnailText) formData.append('ThumbnailText', data.thumbnailText)
        formData.append('ShortText', data.shortText)
        formData.append('CategoryId', data.categoryId)
        formData.append('IsActive', data.isActive)
        formData.append('SourceUrl', data.sourceUrl)
        formData.append('SourceText', data.sourceText)
        formData.append('CreateDate', dateConverter(data.createDate).split('.').reverse().join('-'))
        if (data.editDate) formData.append('EditDate', dateConverter(data.editDate).split('.').reverse().join('-'))
        if (data.imageFile) formData.append('Image', data.imageFile)
        formData.append('PostTelegram', data.postTelegram)
        formData.append('SourceUrl', data.sourceUrl)
        formData.append('isImportant', data.isImportant)
        return http.post(`${ROOT_URL}V2`, null, formData, accessToken)
    }
    putNews(data: any, id: string) {
        const accessToken = tokenService.getAccessToken()
        const formData = new FormData();
        formData.append('Id', id)
        formData.append('Title', data.title)
        formData.append('ShortText', data.shortText)
        formData.append('Text', data.text)
        if (data.imageId) formData.append('ImageId', data.imageId)
        if (data.thumbnail) formData.append('Thumbnail', data.thumbnail)
        if (data.thumbnailText) formData.append('ThumbnailText', data.thumbnailText)
        formData.append('ShortText', data.shortText)
        formData.append('CategoryId', data.categoryId)
        formData.append('IsActive', data.isActive)
        formData.append('SourceUrl', data.sourceUrl)
        formData.append('SourceText', data.sourceText)
        formData.append('CreateDate', dateConverter(data.createDate).split('.').reverse().join('-'))
        if (data.editDate) formData.append('EditDate', dateConverter(data.editDate).split('.').reverse().join('-'))
        if (data.imageFile) formData.append('Image', data.imageFile)
        formData.append('PostTelegram', data.postTelegram)
        formData.append('SourceUrl', data.sourceUrl)
        formData.append('isImportant', data.isImportant)
        return http.put(`${ROOT_URL}V2`, null, formData, accessToken)
    }

    addNews(B: newsType) {
        const accessToken = tokenService.getAccessToken()
        return http.post(`${ROOT_URL}/category`, null, B, accessToken)
    }
    getNewsImage(id: string) {
        const accessToken = tokenService.getAccessToken()
        return http.getWithBlob(`${ROOT_URL}/image/${id}`, null, accessToken)
    }
    getNewsById(id: number) {
        return http.get(`${ROOT_URL}/${id.toString()}`)
    }

    getNewsForAdminById(id: number) {
        return http.get(`${ROOT_URL}Manager/${id.toString()}`)
    }

    getNewsOther(Q: { NewsId?: number, category?: number, count?: number, offset?: number }) {
        return http.get(`${ROOT_URL}/other`, Q)
    }

    getTagsList() {
        return http.get(`${ROOT_URL}/tags`)
    }

    addTag(B: { name: string }) {
        const accessToken = tokenService.getAccessToken()
        return http.post(`${ROOT_URL}/tag`, null, B, accessToken)
    }
    putPartData(id: number, isActive: boolean) {
        const accessToken = tokenService.getAccessToken()
        return http.put(`${ROOT_URL}/partput`, null, { id, isActive }, accessToken)
    }
}

const newsApi = new NewsApi()
export default newsApi