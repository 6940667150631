import { BigFormAction, bigFormActionTypes, BigFormState } from "../../types/bigForm"

const initialState:BigFormState = {
    errorsFieldsArray: [],
    isDisplay: false,
    propsalsId: [],
}

export const bigFormReducer = (state = initialState, action: BigFormAction) => {
    switch (action.type) {
        case bigFormActionTypes.UPDATE_ERRORS_ARRAY:
            return {
                ...state,
                ...action.payload,
            }
        case bigFormActionTypes.DISPLAY_SIGNALS_IN_ACCORDION:
            return {
                ...state,
                ...action.payload,
            }
        case bigFormActionTypes.SET_PROPSALS_ID:
            return {
                ...state,
                ...action.payload,
            }
        default:
            return state
    }
}