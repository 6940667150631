import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import { useFormik } from "formik";
import React, { memo, useState } from "react";
import NumberFormat from "react-number-format";
import * as Yup from "yup";
import { CookiesEnum } from "../../../../../core/consts/common";
import tokenService from "../../../../../core/services/TokenService";
import AgreementModal from "../../../agreement-modal";
import { FormTypeAgent } from "../../RegistrationDetails";
import { NalogsSelections } from "./const";

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      inputBox: {
        display: "flex",
        flexDirection: "column",
        marginTop: "25px",
        maxWidth: "100%",
      },
      iconCheckRadio: {
        "& svg": {
          fontSize: "24px",
        },
      },
      submit: {
        marginTop: "35px",
        width: "300px",
        [theme.breakpoints.down(400)]: {
          maxWidth: "100%",
        },
      },
      textField: {
        width: "300px",
        height: "51px",
        fontFamily: "Gilroy",
        fontWeight: 500,
        marginBottom: "25px",
        [theme.breakpoints.down(400)]: {
          maxWidth: "100%",
        },
      },
      checkBoxField: {
        width: "300px",
        [theme.breakpoints.down(400)]: {
          maxWidth: "100%",
        },
      },
      offerta: {
        color: "#306F9A",
        "&:hover": {
          cursor: "pointer",
          textDecoration: "underline",
        },
      },
      offertaWrap: {
        width: "250px",
        display: "block",
        color: "#131313",
        fontSize: "12px",
        fontFamily: "Gilroy",
        fontWeight: 500,
        [theme.breakpoints.down(400)]: {
          maxWidth: "calc(100% - 23px)",
        },
      },
    }),
  { index: 1 }
);

export enum ClientType {
  Client = "client",
  Agent = "agent",
}

type Props = {
  handleSubmit: (
    data: FormTypeAgent,
    setBadRequestFields: (data: any) => void
  ) => void;
  isFetching: boolean;
};
type BadRequestType = {
  lastName: string;
  firstName: string;
  middleName: string;
  phone: string;
  email: string;
};
const RegistrationAgent: React.FC<Props> = ({ handleSubmit, isFetching }) => {
  const classes = useStyles();
  const [checkedSubmit, setCheckedSubmit] = useState<boolean>(false);
  const [isUl, setIsUl] = useState<string>("физ");
  const [isUlString, setIsUlString] = useState("физ");
  const [phone, setPhone] = useState({ number: "" });
  const [agreementOpen, setAgreementOpen] = useState(false);
  const [message, setMessage] = useState<string>("");
  const [badRequestFields, setBadRequestFields] = useState<BadRequestType>({
    lastName: "",
    firstName: "",
    middleName: "",
    phone: "",
    email: "",
  });

  // const clickId = localStorage.getItem("cid");
  const clickId = tokenService._getCookie(CookiesEnum.cid);
  const cid = clickId ? { cid: clickId } : {};

  // const userId = localStorage.getItem("uid");
  const userId = tokenService._getCookie(CookiesEnum.uid);
  const uid = userId ? { c2MUserId: userId } : {};

  const formik = useFormik({
    initialValues: {
      phone: "",
      TaxType: NalogsSelections[0],
    },
    validationSchema: Yup.object({}),
    onSubmit: (values) => {
      const requestData = {
        agentType:
          isUlString === "физ"
            ? "fl"
            : isUlString === "ип"
              ? "ip"
              : isUlString === "юр"
                ? "ul"
                : "",
        phone: phone.number,
        ...cid,
        ...uid,
      };
      setMessage(
        checkedSubmit
          ? ""
          : "Подтвердите согласие на обработку персональных данных"
      );
      handleSubmit(requestData, setBadRequestFields);
    },
  });

  const isDisableBtn = () => {
    if (phone.number && checkedSubmit) {
      return false;
    } else return true;
  };

  const handleCheckedSubmit = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedSubmit(event.target.checked);
  };

  const handleIsUl = (event: any) => {
    setIsUlString(event.target.value);
    setIsUl(event.target.value);
  };

  const handleAgreementModal = () => {
    setAgreementOpen(!agreementOpen);
  };

  const handleChangePhone = (event: React.ChangeEvent<HTMLInputElement>) => {
    let localPhoneNumber = event.target.value;
    const phoneNumberLength = localPhoneNumber.replaceAll(" ", "").length;
    if (phoneNumberLength === 16 && localPhoneNumber[1] !== "7") {
      localPhoneNumber =
        "+7" + localPhoneNumber.substring(2, localPhoneNumber.length);
    } else if (localPhoneNumber[1] !== "7" && localPhoneNumber !== "") {
      if (localPhoneNumber[1] === "8") {
        localPhoneNumber = "+7";
      } else {
        localPhoneNumber =
          "+7" + localPhoneNumber.substring(1, localPhoneNumber.length);
      }
    } else if (localPhoneNumber === "") {
      localPhoneNumber = "";
    }
    setPhone({ number: localPhoneNumber });
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className={classes.inputBox}>
        <Typography variant="body2" component="p" style={{ fontSize: "12px" }}>
          Вы являетесь...
        </Typography>
        <FormControl component="fieldset">
          <RadioGroup
            onChange={handleIsUl}
            style={{ marginBottom: "15px" }}
            value={isUlString}
            name="isUl"
          >
            <FormControlLabel
              value="физ"
              control={
                <Radio color="primary" className={classes.iconCheckRadio} />
              }
              style={{ marginRight: "0" }}
              label={<Typography variant="body2">Физ. лицом</Typography>}
            />
            <FormControlLabel
              value="ип"
              control={
                <Radio color="primary" className={classes.iconCheckRadio} />
              }
              style={{ marginRight: "0" }}
              label={
                <Typography variant="body2">
                  Индивидуальным предпринимателем
                </Typography>
              }
            />
            <FormControlLabel
              value="юр"
              control={
                <Radio color="primary" className={classes.iconCheckRadio} />
              }
              style={{ marginRight: "0" }}
              label={<Typography variant="body2">Юр.лицом</Typography>}
            />
          </RadioGroup>
        </FormControl>
        <NumberFormat
          id="phone"
          label="Ваш номер"
          customInput={TextField}
          type="tel"
          format="+# (###) ###-##-##"
          placeholder="+7 (800) 000-00-00"
          className={classes.textField}
          value={phone.number}
          error={Boolean(badRequestFields.phone)}
          helperText={badRequestFields.phone}
          onChange={handleChangePhone}
          inputMode="numeric"
          InputLabelProps={{
            shrink: true,
          }}
        />

        <Box>
          <FormControlLabel
            style={{
              boxSizing: "border-box",
              maxWidth: "100%",
              padding: 0,
              alignItems: "flex-start",
            }}
            control={
              <Checkbox
                id="offerta"
                checked={checkedSubmit}
                onChange={handleCheckedSubmit}
                color="primary"
                className={classes.iconCheckRadio}
              />
            }
            label={
              <Typography
                variant="body2"
                component="span"
                style={{ fontSize: "12px", marginTop: "5px" }}
                className={classes.offertaWrap}
              >
                Я даю своё{" "}
                <span
                  className={classes.offerta}
                  onClick={handleAgreementModal}
                >
                  согласие на обработку персональных данных
                </span>
              </Typography>
            }
          />
        </Box>
        <Typography variant="body1" component="p" style={{ color: "red" }}>
          {message}
        </Typography>
        {isFetching ? (
          <div
            style={{
              marginTop: "35px",
              display: "flex",
              justifyContent: "center",
              width: "300px",
            }}
          >
            {" "}
            <CircularProgress />{" "}
          </div>
        ) : (
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={isDisableBtn()}
            className={classes.submit}
            style={isDisableBtn() ? { backgroundColor: "#E0E0E0" } : {}}
          >
            <Typography
              component="span"
              variant="body1"
              style={{
                color: "#ffffff",
                textTransform: "none",
                lineHeight: "3",
              }}
            >
              Получить код
            </Typography>
          </Button>
        )}
      </div>
      <AgreementModal
        isOpen={agreementOpen}
        clientType="agent"
        handleClose={handleAgreementModal}
      />
    </form>
  );
};

export default memo(RegistrationAgent);
