import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import IconButtonMain from '../../../UIcomponentsRedesign/IconButtonMain/IconButtonMain';
import { MessageStatusEnum, messageType, messageUploadingType } from "../../../core/api/chatsApi";
import { COLORS } from '../../../core/consts/redesign/commonRedesign';
import { Close, Docs, ErrorSign, Img, TwoTicks } from '../../../core/consts/redesign/icons';
import { timeConverter } from '../../../core/helpers/dateConverter';
import { isImageFile } from '../../../core/helpers/isImageFile';
const isMessageUploadingType = (message: messageType | messageUploadingType): message is messageUploadingType => {
  return (message as messageUploadingType).progress !== undefined;
};
const ChatMessage = ({ message, downloadDoc, handleCancel }: {
  message: messageType | messageUploadingType,
  downloadDoc: (id: string, name: string) => void,
  handleCancel: () => void
}) => {
  const isMyMessage = message.isMyMessage;
  const isDoc = message.documentId && message.documentId !== "0";
  const documentId = message.documentId || '';
  const messageText = message.message || ''
  const messageStatus = message.status || MessageStatusEnum.SendUnRead;
  const messageDate = message.dateMessage || Date.now().toString()

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      message: {
        padding: '12px 12px 16px',
        minWidth: "120px",
        maxWidth: '80%',
        height: 'fit-content',
        position: "relative",
        overflowWrap: 'break-word',
        wordBreak: 'break-word',
        whiteSpace: 'pre-wrap',
        cursor: isDoc ? "pointer" : "default"
      },
      myMessage: {
        backgroundColor: COLORS.brand_blue,
        color: COLORS.white,
        alignSelf: 'flex-end',
        borderRadius: '8px 8px 0px 8px',

      },
      theirMessage: {
        backgroundColor: COLORS.light_gray_bg,
        color: COLORS.black,
        alignSelf: 'flex-start',
        borderRadius: '8px 8px 8px 0px',
      },
      messageFont: {
        fontSize: '14px',
        lineHeight: '19px',
        fontWeight: 500,
        textAlign: 'left'
      },
      messageFontDoc: {
        fontSize: '16px',
        lineHeight: '21px',
        fontWeight: 500,
        textAlign: 'left'
      },
      docBox: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: 'center',
        width: "100%",
        gap: "16px",
      },
      timeBox: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: 'center',
        width: "100%",
        gap: "4px",
        position: "absolute",
        padding: '1px',
        bottom: 0,
        right: "4px"
      },
      messageTime: {
        fontSize: '12px',
        lineHeight: '16px',
        fontWeight: 500,
        opacity: 0.5,
        color: isMyMessage ? COLORS.white : COLORS.gray2,
      },
      columnBox: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: 'flex-start',
        width: "100%",
        gap: "4px",
      },
      progressBar: {
        position: 'relative',
        height: '2px',
        maxHeight: '2px',
        backgroundColor: `rgba(255, 255, 255, 0.2)`,
        marginTop: '-8px',
        overflow: 'hidden',
        width: '100%',
      },
      progress: {
        position: 'absolute',
        height: '100%',
        transition: 'width 0.2s ease-in-out',
        backgroundColor: COLORS.white
      },
      removeButton: {
        width: '40px',
        height: '30px'
      },
      icon: {
        width: '24px',
        height: '24px'
      },
    })
  );
  const classes = useStyles();
  const statusIcon = (status: MessageStatusEnum) => {
    switch (status) {
      case MessageStatusEnum.SendUnRead:
        return <TwoTicks color={COLORS.light_gray_stroke} opacity={isMyMessage ? 0.2 : 0.6} />;
      case MessageStatusEnum.SendRead:
        return <TwoTicks color={isMyMessage ? COLORS.white : COLORS.brand_blue} opacity={0.8} />;
      case MessageStatusEnum.SendError:
        return <ErrorSign />;
      default:
        return null;
    }
  };

  return (
    <>
      {message &&
        !isMessageUploadingType(message) ?
        <div key={message.id}
          className={`${classes.message} ${isMyMessage ? classes.myMessage : classes.theirMessage}`}
          onClick={() => isDoc && downloadDoc(documentId, messageText)}>
          {isDoc ? <div className={classes.docBox}>
            {isImageFile(messageText) ?
              <Img color={isMyMessage ? COLORS.white : COLORS.black} />
              :
              <Docs color={isMyMessage ? COLORS.white : COLORS.black} />
            }
            <span className={classes.messageFontDoc}>{message.message}</span>
          </div>
            :
            <span className={classes.messageFont}>{message.message}</span>
          }
          <div className={classes.timeBox}>
            <span>{statusIcon(messageStatus)}</span>
            <span className={classes.messageTime}>{timeConverter(messageDate)}</span>
          </div>
        </div>
        :
        <div key={message.id}
          className={`${classes.message} ${isMyMessage ? classes.myMessage : classes.theirMessage}`}
          style={{ minWidth: "240px" }}
        >
          <div className={classes.docBox}>

            <div className={classes.columnBox} style={{ gap: '10px' }}>
              <div className={classes.docBox}>
                <div className={classes.icon}>
                  {isImageFile(messageText) ?
                    <Img color={isMyMessage ? COLORS.white : COLORS.black} /> :
                    <Docs color={isMyMessage ? COLORS.white : COLORS.black} />
                  }
                </div>
                <div className={classes.columnBox}>
                  <span className={classes.messageFontDoc}>{message.message}</span>
                  <span className={classes.messageFontDoc}>{`${message.loadedMB} из ${message.totalMB} мб`}</span>
                </div>
              </div>
              <div className={classes.progressBar}>
                <div
                  className={classes.progress}
                  style={{ width: `${message.progress}%` }}
                />
              </div>
            </div>
            <IconButtonMain
              title="Прервать загрузку"
              className={classes.removeButton}
              color='noBgWhite'
              style={{ padding: '3px' }}
              aria-label="close"
              icon={<Close />}
              handleClick={handleCancel} />
          </div>
          <div className={classes.timeBox}>
            <span>{statusIcon(messageStatus)}</span>
            <span className={classes.messageTime}>{timeConverter(messageDate)}</span>
          </div>
        </div>
      }
    </>
  );
};

export default ChatMessage;
