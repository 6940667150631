import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { memo, useState } from "react";
import Avatar from "../../../../app/components/avatar";
import { chatListType, TypeChat } from "../../../../core/api/chatsApi";
import { ROLES } from "../../../../core/consts/common";
import { dateWithTimeConverter } from "../../../../core/helpers/dateConverter";
import { CurrentUserState } from "../../../../core/types/currentUser";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    boxSender: {
      padding: theme.spacing(2, 3),
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      "&:hover": {
        backgroundColor: "#F2F2F2",
        cursor: "pointer",
      },
    },
    messageInfoWrap: {
      maxWidth: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
    },
    messageInfo: {
      paddingRight: "5px",
      color: "#B0BFC6",
      wordBreak: "break-all",
      width: "fit-content",
      overflow: "hidden",
    },
    message: {
      wordBreak: "break-all",
      width: "fit-content",
      overflow: "hidden",
      height: "18px",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      display: "inline-block",
    },
    bankName: {
      padding: "5px 10px",
      borderRadius: "4px",
      backgroundColor: "#F2F2F2",
      maxWidth: "fit-content",
      marginLeft: "15px",
    },
    messagesCount: {
      width: "fit-content",
      padding: "2px 8px",
      backgroundColor: "#309A90",
      borderRadius: "10px",
      color: "#ffffff",
      marginRight: "10px",
      justifyContent: "center",
    },
  })
);

type Props = {
  handleChatRouteClick: (chat: chatListType) => void;
  chat: chatListType;
  initialUser: CurrentUserState;
  avatar: string;
};

const Message: React.FC<Props> = (props) => {
  const classes = useStyles();
  const [unReadMes, setUnReadMes] = useState<number>(
    props.chat.countUnreadMessage
  );

  const handleMessageClick = () => {
    props.handleChatRouteClick(props.chat);
    setUnReadMes(0);
  };

  return (
    <Box
      className={classes.boxSender}
      onClick={handleMessageClick}
      key={props.chat.id}
    >
      <Box style={{ flexBasis: "90%" }}>
        <Box
          className={classes.messageInfoWrap}
          style={{ justifyContent: "space-between" }}
        >
          <Typography
            variant="h4"
            component="p"
            style={{ display: "inline-block", marginBottom: "5px" }}
          >
            {props.chat.typeChat === TypeChat.Bank &&
              props.initialUser.role === ROLES.PARTNER
              ? props.chat.nameChat
              : props.chat.typeChat === TypeChat.ManagerChat
                ? props.initialUser.role === ROLES.MANAGER
                  ? `Чат с ${props.chat.roleInterlocutor === "agent"
                    ? "агентом"
                    : "клиентом"
                  } ${props.chat.nameInterlocutor}`
                  : "Чат с менеджером"
                : props.chat.nameChat === "Техподдержка"
                  ? "Чат с техподдержкой"
                  : props.chat.productName}
            {props.chat.leadId
              ? ` по заявке ${props.chat.leadId}`
              : props.chat.typeChat === TypeChat.Role &&
                props.initialUser.role === ROLES.MANAGER
                ? props.chat.nameChat
                : ""}
            {props.chat.bankName && (
              <Typography
                variant="body2"
                component="span"
                className={classes.bankName}
              >
                {props.chat.bankName}
              </Typography>
            )}
          </Typography>
          <Typography
            variant="body2"
            component="p"
            style={{
              display: "inline-block",
              color: "#B0BFC6",
              paddingRight: "15px",
            }}
          >
            {unReadMes > 0 && (
              <Typography
                variant="body2"
                component="span"
                className={classes.messagesCount}
              >
                +{unReadMes}
              </Typography>
            )}
            {dateWithTimeConverter(props.chat.lastTimeMessage)}
          </Typography>
        </Box>
        <Box className={classes.messageInfoWrap}>
          <Typography
            variant="body2"
            component="span"
            className={classes.messageInfo}
            style={{
              maxWidth: `${props.chat.lastMessageYou ? "fit-content" : "50%"}`,
            }}
          >
            {props.chat.lastMessageYou ? "Вы: " : props.chat.nameInterlocutor}
          </Typography>
          <Typography
            variant="body2"
            component="span"
            className={classes.message}
            style={{
              maxWidth: `${props.chat.lastMessageYou ? "540px" : "540px"}`,
            }}
          >
            {props.chat.lastMessage}
          </Typography>
        </Box>
      </Box>
      <Avatar
        size="medium"
        info={props.avatar}
        currentChatName={props.initialUser.nameFull}
      />
      &nbsp;&nbsp;&nbsp;
      <svg
        width="10"
        height="18"
        viewBox="0 0 10 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.25 1.5L8.75 9L1.25 16.5"
          stroke="#D0D9DE"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Box>
  );
};

export default memo(Message);
