import { TOKEN_NAME } from '../consts/common';

class TokenService {
    //Устанавливает куки с именем name и значением value, с настройкой path=/ по умолчанию
    // _setCookie(name: string, value: string, options: any) {
    //     options = {
    //         path: '/',
    //         ...options
    //     };

    //     // if (options.expires instanceof Date) {
    //     //     options.expires = options.expires.toUTCString();
    //     // }

    //     let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

    //     for (let optionKey in options) {
    //         updatedCookie += "; " + optionKey;
    //         let optionValue = options[optionKey];
    //         if (optionValue !== true) {
    //             updatedCookie += "=" + optionValue;
    //         }
    //     }

    //     document.cookie = updatedCookie;
    // }

    _setCookie = (name: string, value: string, days: number, path?: string, domain?: string) => {
        let cookie = `${name}=${encodeURIComponent(value)}`;
        // Add expiry date
        if (days) {
            const expiry = new Date();
            expiry.setDate(expiry.getDate() + days);
            cookie += `; expires=${expiry.toUTCString()}`;
        }

        if (path) cookie += `; path=${path}`;
        // console.log("setCookie process?.env?.REACT_APP_DOMAIN", process?.env?.REACT_APP_DOMAIN)

        if (process.env.NODE_ENV === "production") {
            cookie += `; domain=.${process?.env?.REACT_APP_DOMAIN ?? "finlab.ru"}`;
        }

        // Set an HTTP cookie
        // console.log("setCookie cookie:", cookie)
        document.cookie = cookie;
    };

    // возвращает куки с указанным name,
    // или undefined, если ничего не найдено
    _getCookie(name: string) {
        const cookies = document.cookie.split(';');
        // console.log("cookies", cookies)
        let findedValue: string | undefined = undefined;
        for (let i = 0; i < cookies.length; i++) {
            let c = cookies[i].trim().split('=');
            if (c[0] === name) {
                // return c[1];             
                findedValue = c[1];
            }
        }
        return findedValue;
    }

    _deleteCookie(name: string) {
        // console.log(`COOKIE_NAME=${name}; expires=Thu, 01 Jan 1970 00:00:00 GMT; domain=.${process?.env?.REACT_APP_DOMAIN ? process?.env?.REACT_APP_DOMAIN : "finlab.ru"}`)
        // document.cookie = `COOKIE_NAME=${name}; Max-Age=-1; path=/; domain=.${process?.env?.REACT_APP_DOMAIN ? process?.env?.REACT_APP_DOMAIN : "finlab.ru"}`;
        document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; domain=.${process?.env?.REACT_APP_DOMAIN ? process?.env?.REACT_APP_DOMAIN : "finlab.ru"}`;
        // document.cookie = `COOKIE_NAME=${name}; Max-Age=-1; path=/; domain=app.finlab.ru`;
        // document.cookie = `COOKIE_NAME=${name}; Max-Age=-1; path=/; domain=.finlab.ru`;
        // document.cookie = `COOKIE_NAME=${name}; Max-Age=-1; path=/; domain=.finlab-dev.finlab.internal`;
    }

    _removeCookie(name: string) {

        // const path = window.location.pathname;
        // const firstSegment = path.split('/')[1];
        // const pathForCookie = `/${firstSegment}`;

        // let cookieString = `${name}=; Max-Age=-1; path=${pathForCookie};`;
        let cookieString = `${name}=; Max-Age=-1; path=/;`;

        const isLocalhost = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';
        let domain;

        if (!isLocalhost) {
            domain = process?.env?.REACT_APP_DOMAIN ? process?.env?.REACT_APP_DOMAIN : "finlab.ru"
            cookieString += ` domain=.${domain};`;
        }

        if (window.location.protocol === 'https:') {
            cookieString += ` Secure; SameSite=None`;
        }

        document.cookie = cookieString;
        console.log(`Attempting to delete cookie: ${name} from domain: ${domain || 'localhost'}`);
    }

    setAccessToken(token: string) {
        this._setCookie(TOKEN_NAME, token, 3600, '/');
    }

    getAccessToken(): string | undefined {
        return this._getCookie(TOKEN_NAME) ?? undefined;
    }

    removeAccessToken() {
        this._deleteCookie(TOKEN_NAME);
    }

    setRefreshToken(token: string) {
        localStorage.setItem(TOKEN_NAME, token);
    }
    getRefreshToken() {
        return localStorage.getItem(TOKEN_NAME);
    }
    removeRefreshToken() {
        localStorage.removeItem(TOKEN_NAME);
    }
    parseJwt = (token: string) => {
        try {
            return JSON.parse(atob(token.split('.')[1]));
        } catch (e) {
            return null;
        }
    };


}

const tokenService = new TokenService();

export default tokenService;


