import { DataForOrderActionTypes, InnForOrderState, PushDataForOrderAction, SetProposalIdForDeletePayload } from '../../types/dataForOrder';

export const pushDataForOrder = (payload: PushDataForOrderAction) => {
    return { type: DataForOrderActionTypes.DATA_FOR_ORDER_PUSH, payload };
}
export const pushDataDraftForOrder = (payload: number[] | []) => {
    return { type: DataForOrderActionTypes.DATA_FOR_DRAFT_ORDER_PUSH, payload };
}

export const pushRealProposalIdsForOrder = (payload: number[] | []) => {
    return { type: DataForOrderActionTypes.REAL_PROPOSAL_IDS, payload };
}

export const pushInnForOrder = (payload: InnForOrderState) => {
    return { type: DataForOrderActionTypes.INN_FOR_ORDER_PUSH, payload };
}

export const pushIsNewBasedOnOldForOrder = (payload: boolean) => {
    return { type: DataForOrderActionTypes.SET_IS_NEW_BASED_ON_OLD, payload };
}

export const setProposalIdForDeleteAccordion = (payload: SetProposalIdForDeletePayload) => {
    return { type: DataForOrderActionTypes.PROPOSAL_ID_FOR_DELETE, payload };
}

export const deleteBankFromBankList = (proposalId: number) => {
    return { type: DataForOrderActionTypes.DELETE_BANK_FROM_BANK_LIST, proposalId };
}

export const setRequiredFields = (requiredFields: string[]) => {
    return { type: DataForOrderActionTypes.SET_REQUIRED_FIELDS, requiredFields };
}
