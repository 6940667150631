import React, {
  lazy,
  memo,
  Suspense,
} from 'react';

import clsx from 'clsx';
import { Helmet } from 'react-helmet';
import { helmetJsonLdProp } from 'react-schemaorg';
import { Organization } from 'schema-dts';

import {
  Box,
  Container,
  createStyles,
  Divider,
  Grid,
  Hidden,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';

import TmpMap from './tmpMapPlaceholder.png';
import CommonBack from '../../app/components/commonBack/CommonBack';

const YandexMap = lazy(() => import("../../app/components/yandex-map"));
const Footer = lazy(() => import("../../app/components/footer"));

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      alignItems: "center",
      maxWidth: "100vw",
      padding: "0",
      margin: "0",
    },
    box: {
      margin: "0 auto",
      backgroundColor: "transparent",
      maxWidth: "1280px",
      [theme.breakpoints.down("lg")]: {
        padding: "0px 30px",
      },
      [theme.breakpoints.down("sm")]: {
        padding: "0 16px",
      },
    },
    gridWrap: {},
    title: {
      padding: "40px 0 25px",
    },
    address: {
      padding: "15px 10px",
      position: "relative",
      "&:before": {
        content: "''",
        height: "70%",
        width: "1px",
        display: "block",
        backgroundColor: "#309A90",
        position: "absolute",
        left: 0,
        bottom: "16px",
      },
    },
    addressLink: {
      textDecoration: "none",
      display: "block",
      marginTop: "5px",
    },
    addressWrap: {
      display: "flex",
      flexWrap: "wrap",
      [theme.breakpoints.down("lg")]: {
        flexDirection: "column",
      },
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
      },
    },
    addrtesSubWrap: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      [theme.breakpoints.down("lg")]: {
        marginBottom: "25px",
      },
    },
    addressBox: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "100%",
      [theme.breakpoints.down("lg")]: {
        flexDirection: "row",
      },
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
      },
    },
  })
);
const previousPhoneNumber = "+7 (495) 920-70-29";
export const PhoneNumber = "8 (800) 500-35-93";
export const PhoneNumberHref = "tel:88005003593";

const Contacts: React.FC = () => {
  const classes = useStyles();

  return (
    <Container className={classes.container}>
      <Helmet
        script={[
          helmetJsonLdProp<Organization>({
            "@context": "https://schema.org",
            "@type": "WorkersUnion",
            telephone: PhoneNumber,
            email: "info@finlab.ru",
            address: {
              "@type": "PostalAddress",
              streetAddress:
                "пр-д Марьиной Рощи 3-й, д. 40, стр. 1, этаж/помещ./ком. 15/I/6",
              addressLocality: "Москва",
            },
          }),
        ]}
      >
        <title>Контакты - Finlab</title>
        <meta
          name="description"
          content="Вся контактная информация компании Finlab - мы специализируемся на подборе кредитный и лизинговых предложений"
          data-react-helmet="true"
        />
      </Helmet>
      <Box my={4} maxWidth="lg" className={classes.box}>
        <CommonBack pageName={"Контакты"} />
        <Typography variant="h1" component="h1" className={classes.title}>
          Контакты
        </Typography>
      </Box>
      <Grid className={clsx(classes.box, classes.addressWrap)}>
        <Grid item xl={4} xs={12} className={classes.addrtesSubWrap}>
          <Typography
            variant="subtitle1"
            component="p"
            style={{ paddingBottom: "40px" }}
          >
            Мы готовы ответить на любые <br /> ваши вопросы
          </Typography>
          <Box className={classes.addressBox}>
            <Typography
              variant="body1"
              component="p"
              style={{ color: "#828282" }}
              className={classes.address}
            >
              Москва
              <Typography
                variant="h4"
                component="span"
                style={{ display: "block", marginTop: "5px" }}
              >
                3-й проезд Марьиной Рощи, 40с1, 15 этаж, помещение I, комната 6
              </Typography>
            </Typography>
            <Typography
              variant="body1"
              component="p"
              style={{ color: "#828282", padding: "15px 0", marginTop: "10px" }}
            >
              Номер для связи
              <Typography
                color="primary"
                variant="h4"
                component="a"
                href={PhoneNumberHref}
                className={classes.addressLink}
              >
                {PhoneNumber}
              </Typography>
            </Typography>
            <Typography
              variant="body1"
              component="p"
              style={{ color: "#828282", padding: "15px 0", marginTop: "10px" }}
            >
              Электронная почта
              <Typography
                color="primary"
                variant="h4"
                component="a"
                href="mailto:info@finlabexpert"
                className={classes.addressLink}
              >
                info@finlab.ru
              </Typography>
            </Typography>
          </Box>
        </Grid>
        {(
          <Grid
            item
            xl={8}
            xs={12}
            style={{
              display: "block",
              width: "100%",
              minHeight: "300px",
              height: "auto",
            }}
          >
            <Suspense fallback={<div> </div>}>
              <YandexMap />
            </Suspense>
          </Grid>
        ) || (
            <Grid
              item
              xl={8}
              xs={12}
              style={{
                display: "block",
                width: "100%",
                minHeight: "300px",
                height: "auto",
                backgroundImage: `url(${TmpMap})`,
              }}
            ></Grid>
          )}
      </Grid>
      <Hidden only={["sm", "xs"]}>
        <Box my={4} maxWidth="lg" className={classes.box}>
          <Divider variant="middle" style={{ margin: "100px 0 50px" }} />
        </Box>
      </Hidden>
      <Suspense fallback={<div> </div>}>
        <Box my={4} maxWidth="lg" className={classes.box}>
          <Footer />
        </Box>
      </Suspense>
    </Container>
  );
};

export default memo(Contacts);
