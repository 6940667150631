import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import useIsMobile from "../../../../../../core/hooks/useIsMobile";
import { actions, useAppSelector } from "../../../../../../core/store";
import * as selectors from "../../../../../../core/store/selectors";
import {
  AddEditModalValuesMultiple,
  setCurrentEditModalValuesMultiple,
  setCurrentEditModalValuesNEW
} from "../../../../../../core/store/thunk/newOrder";
import { rootState } from "../../../../../../core/types/rootState";
import withFormHocs from "../../../../../new-order/hoc/withFormHocs";
import { FieldModal, PropsField } from "../../../../../new-order/types/bigForm";
import classes from "../../../../NewOrderRedesign.module.scss";
import ModalField from "../modal-field/ModalField";

type Props = PropsField & {
  value?: FieldModal | FieldModal[];
};

const GroupField: React.FC<Props> = ({
  field,
  propsalId,
  value,
  error,
  classNameShowIf,
  propsalIdsReal,
  handleSaveDraftOnBlur,
}) => {
  const history = useHistory();
  const [isError, setIsError] = useState<boolean>();
  const isDisplay = useSelector((state: rootState) => state.bigForm.isDisplay);
  const groupFieldsErrors = useSelector(selectors.groupFieldsErrors);
  const dispatch = useDispatch();
  const { currentModalData, currentEditModal } = useAppSelector(
    (state) => state.newOrder
  );

  const isMobile = useIsMobile();
  // const [modalIndexes, setModalIndexes] = useState([0]);

  // const handleAddNewModal = (
  //   e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  // ) => {
  //   e.stopPropagation();
  //   history.push({
  //     pathname: history.location.pathname,
  //     search: `?modal=${field.name}`,
  //   });
  //   actions.setCurrentModalData({
  //     propsalId: propsalId,
  //     modalName: field.name,
  //     index: undefined,
  //     isMultiple: field.isMultiple,
  //   });
  // };

  const handleAddNewModal = () => {
    dispatch(AddEditModalValuesMultiple(field.name, undefined));
    // setModalIndexes([...modalIndexes, modalIndexes.length]);
  };

  const handleDeleteModalByIndex = (modalIndex: number) => {
    if (modalIndex !== undefined) {
      console.log("setCurrentEditModalValuesMultiple delete modalIndex", modalIndex);
      actions.deleteMultipleModalAccordion({
        propsalId: propsalId,
        fieldName: field.name,
        index: modalIndex,
      });
      if (handleSaveDraftOnBlur) {
        console.log("setCurrentEditModalValuesMultiple delete on blur modalIndex", modalIndex);
        handleSaveDraftOnBlur();
      }
    }
    // const newModalIndexes = [...modalIndexes];
    // setModalIndexes(newModalIndexes.slice(0, newModalIndexes.length - 1));
  };

  function safeJSONParse(str: string, fallback = []) {
    try {
      return JSON.parse(str);
    } catch (e) {
      console.log("Error parsing JSON:", e);
      return fallback;
    }
  }

  useEffect(() => {
    // if (field.isMultiple && field.values) {
    //   const arr = JSON.parse(field.values);
    //   if (Array.isArray(arr)) {
    //     const newIndexes = arr.map((el, i) => i);
    //     setModalIndexes(newIndexes);
    //   }
    // } else {
    //   setModalIndexes([0]);
    // }

    // console.log("currentModalData?.modalName", currentModalData?.modalName);
    // console.log("field?.name", field?.name);

    if (currentModalData?.modalName !== field?.name) {
      // console.log("setCurrentModalData propsalIdsReal", propsalIdsReal);
      actions.setCurrentEditModalMultiple({
        values: undefined,
        errors: undefined,
        modalIndex: 0,
      });
      actions.setCurrentModalData({
        propsalId: propsalId,
        modalName: field.name,
        index: undefined,
        isMultiple: field.isMultiple,
        proposals: propsalIdsReal,
      });

      // dispatch(
      actions.setCurrentEditModal({
        values: undefined,
        errors: undefined,
        modalIndex: 0,
      });
      // );

      if (field && field.isMultiple === false) {
        console.log("setCurrentEditModalValues", field);
        dispatch(setCurrentEditModalValuesNEW(field.name, undefined));
      } else if (field && field.isMultiple === true) {
        console.log("setCurrentEditModalValuesMultiple", field);
        if (typeof field.values === 'string') {
          field.values = safeJSONParse(field.values, []);
        }
        if (field.values.length > 0) {
          dispatch(setCurrentEditModalValuesMultiple(field.name, undefined));
        }
      }
    }
    return () => {
      // console.log("GroupField return");
      actions.setCurrentModalData({
        propsalId: undefined,
        modalName: undefined,
        index: undefined,
        isMultiple: undefined,
        proposals: undefined,
      });
      actions.setCurrentEditModalMultiple({
        values: undefined,
        errors: undefined,
        modalIndex: 0,
      });
    };
  }, [field]);

  useEffect(() => {
    if (groupFieldsErrors) {
      const propsalGroupField = groupFieldsErrors[propsalId];
      if (propsalGroupField) {
        if (propsalGroupField[field.name]) {
          setIsError(propsalGroupField[field.name]);
        } else {
          setIsError(undefined);
        }
      } else {
        setIsError(undefined);
      }
    }
  }, [groupFieldsErrors]);

  // useEffect(() => {
  //   console.log("GroupField new value", value);
  //   // if (value && !Array.isArray(value)) {
  //   //   setIsEmpty(Object.keys(value).length === 0);
  //   // } else if (value && Array.isArray(value)) {
  //   //   setIsEmpty(value.length === 0);
  //   // } else {
  //   //   setIsEmpty(false);
  //   // }
  // }, [value]);

  return (
    <>
      {field?.isMultiple ? (
        <>
          {currentEditModal.values?.map((el, index) => (
            <React.Fragment key={index}>
              {currentEditModal?.values?.[index] && (
                <ModalField
                  modal={field.name}
                  propsalId={propsalId}
                  modalIndex={index}
                  propsalIdsReal={propsalIdsReal}
                  isMultiple={true}
                />
              )}
              {/* logic to not deleting the only one */}
              {!field?.isRequeired ? (
                <div
                  className={`blue_link ${classes.end}`}
                  onClick={() => handleDeleteModalByIndex(index)}
                >
                  удалить
                </div>
              ) : (
                (currentEditModal && currentEditModal.values && currentEditModal.values.length > 1) &&
                <div
                  className={`blue_link ${classes.end}`}
                  onClick={() => handleDeleteModalByIndex(index)}
                >
                  удалить
                </div>
              )}
              {isMobile && <div className="line_x" />}
            </React.Fragment>
          ))}
          <button className="button_secondary_w100" onClick={handleAddNewModal}>
            Добавить
          </button>
        </>
      ) : (
        <ModalField
          modal={field.name}
          propsalId={propsalId}
          modalIndex={0}
          propsalIdsReal={propsalIdsReal}
          isMultiple={false}
        />
      )}
    </>
  );
};

// export default withFormHocs(React.memo(GroupField));
export default withFormHocs(GroupField);
