/**
 * Saves binary data as a file in the browser.
 * 
 * This function allows to save binary data (e.g., an array of bytes) as a file
 * directly from the browser. It creates a Blob from the data, generates a URL for
 * the Blob, and triggers a download by programmatically clicking an anchor element.
 * After the download is initiated, the URL is revoked to free up memory.
 * 
 * @param {any} data - The binary data to be saved as a file.
 * @param {string} fileName - The name of the file to be saved.
 */
export const downloadBlobFile = (function () {
  const a = document.createElement("a");
  document.body.appendChild(a);
  a.style.display = 'none';
  return function (data: any, fileName: string) {
    const blob = new Blob([data]);
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  };
})();
