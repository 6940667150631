import userApi, { UserComment } from "../api/userApi";
import { UserInfoType, userAgentInfoType, userClientInfoType, userListQueryType } from "../types/userTypes";
import introspectService from "./IntropsectService";

class UserService {
    blockUserService(id: string, block: boolean) {
        const body = { id, block }
        return userApi.blockUser(body)
            .then((res) => {
                return res
            })
            .catch((error) => {
                introspectService.checkToken(error.response.status.toString());
                console.log(error);
            })
    }

    addUserService(body: userClientInfoType | userAgentInfoType) {
        return userApi.addUser(body)
            .then((res) => {
                return res
            })
            .catch((error) => {
                introspectService.checkToken(error.response.status.toString());
                console.log(error);
            })
    }

    deleteUserService(id: string) {
        const body = { id: id }
        return userApi.deleteUser(body)
            .then((res) => {
                return res
            })
            .catch((error) => {
                introspectService.checkToken(error.response.status.toString());
                console.log(error);
            })
    }

    editUserService(body: userClientInfoType | userAgentInfoType) {
        return userApi.editUser(body)
            .then((res) => {
                return res
            })
            .catch((error) => {
                introspectService.checkToken(error.response.status.toString());
                console.log(error);
            })
    }

    getUserInfoService(id: string): Promise<UserInfoType> {
        return userApi.getUserInfo(id)
    }
    putUserInfoService(data: any): Promise<void> {
        return userApi.putUserInfo(data)
    }

    createCommentService(id: string, message: UserComment) {
        return userApi.createComment(id, message)
    }

    getUserRolesService() {
        return userApi.getUserRoles()
            .then((res) => {
                return res
            })
            .catch((error) => {
                introspectService.checkToken(error.response.status.toString());
                console.log(error);
            })
    }

    getUsersListService(Q: userListQueryType) {
        return userApi.getUsersList(Q)
            .then((res) => {
                return res
            })
            .catch((error) => {
                introspectService.checkToken(error.response.status.toString());
                console.log(error);
            })
    }


    setUserLimitService() {
        return userApi.setUserLimit()
            .then((res: any) => {
                return res
            })
            .catch((error) => {
                introspectService.checkToken(error.response.status.toString());
                console.log(error);
            })
    }

    changeUserVerificationService(B: {
        userId: string,
        set: boolean,
    }) {
        return userApi.changeVerification(B)
            .then((res) => {
                return res
            })
            .catch((error) => {
                introspectService.checkToken(error.response.status.toString());
                console.log(error);
            })
    }

    editUserZaChBizService(id: string) {
        return userApi.editUserZaChBiz(id)
            .then((res) => {
                return res
            })
            .catch((error) => {
                introspectService.checkToken(error.response.status.toString());
                console.log(error);
            })
    }
}

const userService = new UserService()
export default userService