import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import { Modal } from '@mui/material';
import React, { memo } from "react";
import ButtonCustom from "../../../UIcomponentsRedesign/ButtonCustom/ButtonCustom";
import IconButtonMain from "../../../UIcomponentsRedesign/IconButtonMain/IconButtonMain";
import { COLORS } from "../../../core/consts/redesign/commonRedesign";
import { Close } from "../../../core/consts/redesign/icons";

/* commonly used confirm modal */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      outline: 'none',
      position: "absolute",
      width: "600px",
      height: "fit-content",
      top: "50%",
      left: "calc(50% + 60px)",
      // maxWidth: "780px",
      transform: "translate(-50%, -50%)",
      backgroundColor: COLORS.white,
      border: "none",
      padding: "24px",
      borderRadius: "8px",
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: '16px',
      [theme.breakpoints.down(1024)]: {
        width: "360px",
        left: "50%",
      }
    },
    box: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "baseline",
      width: "100%",
    },
    title: {
      fontSize: '24px',
      lineHeight: '25px',
      fontWeight: 600,
      color: COLORS.black,
      margin: 0,
      padding: 0
    },
    text: {
      fontSize: '16px',
      lineHeight: '21px',
      fontWeight: 500,
      color: COLORS.black,
    },
    extraInfo: {
      color: COLORS.brand_blue,
      textTransform: 'lowercase',
    },
    divider: {
      minHeight: '2px',
      width: '100%',
      color: COLORS.light_gray_bg,

    },
    btnMenu: {
      margin: "0 auto",
      padding: "18px 36px",
      display: "block",
      "& svg": {
        fontSize: "42px",
      },
    },
    buttonArea: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      gap: '8px',
      [theme.breakpoints.down(1024)]: {
        flexDirection: 'column'
      }
    },
  })
);

type PropsType = {
  handleConfirm?: () => void;
  title?: string;
  text?: string;
  handleCancel?: () => void;
  confBtnText?: string;
  cancelBtnText: string;
  open: boolean;
  setModalProps?: (prev: boolean | ((prev: boolean) => boolean)) => void;
  handleClose?: () => void;
  extraInfo?: boolean;
  textButtonHandle?: () => void;
  textTextBtn?: string;
  leadId?: number;
  handleDelLead?: (leadId: number) => void;
  isKros?: boolean;
  extraSubText?: React.ReactNode;
  extraButtonText?: string;
  extraButtonClick?: () => void;
  isExtraCloseModal?: boolean;
  disableCancelButton?: boolean;
  disableConfirmButton?: boolean
};

const ConfirmModalNew: React.FC<PropsType> = (props) => {
  const classes = useStyles();

  const handleClose = () => {
    props.handleClose && props.handleClose();
    props.setModalProps && props.setModalProps(false);
    props.isExtraCloseModal && props.handleConfirm && props.handleConfirm();
  };

  const handleCancel = () => {
    if (props.leadId !== undefined && props.handleDelLead) {
      props.handleDelLead(props.leadId);
    } else {
      props.handleCancel && props.handleCancel();
    }
  };

  const handleConfirm = () => {
    props.handleConfirm && props.handleConfirm();
  };

  return (
    <Modal
      open={props.open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className={classes.paper}>
        <div className={classes.box}>
          <h3
            className={classes.title}
          >
            {props.title}
          </h3>
          {props.isKros && (
            <IconButtonMain
              color='noBgGray'
              aria-label="close"
              icon={<Close color={COLORS.gray2} />}
              handleClick={props.extraButtonText ? handleCancel : handleClose} />
          )}
        </div>
        <span className={classes.text}>
          {props.text}
        </span>
        {props.extraSubText && props.extraSubText}
        <div className={classes.buttonArea}>
          <ButtonCustom
            color="ghost"
            handleClick={handleCancel}
            disabled={props.disableCancelButton}
            fullWidth
          >
            {props.cancelBtnText}
          </ButtonCustom>
          {props.confBtnText && (
            <ButtonCustom
              color="primary"
              handleClick={handleConfirm}
              disabled={props.disableConfirmButton}
              fullWidth
            >
              {props.confBtnText}
            </ButtonCustom>
          )}
        </div>
        {props.textButtonHandle && props.textTextBtn && (
          <ButtonCustom
            color="text_button"
            handleClick={props.textButtonHandle}
            fullWidth
          // className={classes.textBtn}
          >
            {props.textTextBtn}
          </ButtonCustom>
        )}
      </div>
    </Modal>
  );
};

export default memo(ConfirmModalNew);
