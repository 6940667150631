import ListItemIcon from '@material-ui/core/ListItemIcon';
import clsx from 'clsx';
import React from "react";
import { NavLink } from 'react-router-dom';
import { useStylesSideMenu } from "../../useStylesSideMenu";

type Props = {
  title?: string;
  url?: string;
  onClick?: () => void;
  isProductActive?: boolean
}

export const MenuItemForPhone: React.FC<Props> = (props) => {
  const classes = useStylesSideMenu();

  return (
    <>
      {props.url ?
        <NavLink
          className={clsx(classes.navlink_mob, {
            [classes.navlink_mob_active]: props.isProductActive,
          })}
          activeClassName={classes.navlink_mob_active}
          to={props?.url}>
          <ListItemIcon className={classes.menuIcon}>{props.children}</ListItemIcon>
        </NavLink>
        :
        <div className={classes.navlink_mob} onClick={() => props.onClick && props.onClick()}>
          <ListItemIcon className={classes.menuIcon}>{props.children}</ListItemIcon>
        </div>}
    </>
  )
} 