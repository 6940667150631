import React from "react";
import { useHistory } from "react-router-dom";
import { useAppThunkDispatch } from "../../../../../../core/store";
import {
  setModalValueField,
  validateModal,
} from "../../../../../../core/store/thunk/newOrder";
import { FieldType } from "../../../../../new-order/types/bigForm";
import classes from "../../../../NewOrderRedesign.module.scss";
import Fields from "../../Fields";
import { TabsData } from "./ModalField";

type Props = {
  modalField: FieldType;
  modalName: string;
  propsalId: number; // в новой реализации просто номер шага, потому ниже добавляю реальный id
  propsalIdsReal?: number[];
  tab: string;
  setTab: (tab: string) => void;
  modalIndex?: number;
  tabsData?: TabsData;
  tabsError: string[];
  setOpen: (isOpen: boolean) => void;
  setShowPreloader: (isShow: boolean) => void;
  isMultiple: boolean;
};

const ModalFieldBody: React.FC<Props> = (props) => {
  const history = useHistory();
  const dispatch = useAppThunkDispatch();

  const handleSubmit = async () => {
    props.setShowPreloader(true);
    dispatch(validateModal())
      .then((res) => {
        if (res.errorsCount === 0) {
          dispatch(setModalValueField());
          props.setShowPreloader(false);
          props.setOpen(false);
          history.push({
            pathname: history.location.pathname,
            search: ``,
          });
        } else {
          props.setShowPreloader(false);
        }
      })
      .catch((err) => {
        console.log(err);
        props.setShowPreloader(false);
      });
  };

  const handleCheckValidation = () => {
    props.setShowPreloader(true);
    dispatch(validateModal())
      .then(() => {
        props.setShowPreloader(false);
      })
      .catch((err) => {
        console.log(err);
        props.setShowPreloader(false);
      });
  };

  // const handleDeleteAccordion = () => {
  //   if (props.modalIndex !== undefined && props.propsalId) {
  //     actions.deleteMultipleModalAccordion({
  //       // propsalId,
  //       propsalId: props.propsalId,
  //       // fieldName: field.name,
  //       fieldName: props?.modalName ?? props?.modalField?.name,
  //       index: props.modalIndex,
  //     });
  //   } else {
  //     props.propsalId &&
  //       actions.deleteModalAccordion({
  //         // propsalId,
  //         propsalId: props.propsalId,
  //         // fieldName: field.name,
  //         fieldName: props?.modalName ?? props?.modalField?.name,
  //       });
  //   }
  // };

  // useEffect(() => {
  //   return () => {
  //     actions.setCurrentModalData({
  //       propsalId: undefined,
  //       modalName: undefined,
  //       index: undefined,
  //       isMultiple: undefined,
  //     });
  //   };
  // }, []);

  // useEffect(() => {
  //   console.log("ModalFieldBody new props.modalField", props.modalField);
  // }, [props.modalField]);

  return (
    <>
      {/* {props.tabsData?.tabs && props.tabsData?.tabs.length > 0 && (
        <ModalFieldBodyTabs
          modalField={props.modalField}
          tabsData={props.tabsData}
          propsalId={props.propsalId}
          modalIndex={props.modalIndex}
          tabsError={props.tabsError}
          tab={props.tab}
          setTab={props.setTab}
          setOpen={props.setOpen}
          setShowPreloader={props.setShowPreloader}
        />
      )} */}

      {props.tabsData?.tabs && props.tabsData?.tabs.length > 0
        ? props.tabsData?.tabs.map((tabItem, index) => (
          <React.Fragment key={index}>

            <div className={classes.category}>{tabItem}</div>
            {props.modalField.childs
              .filter((elem) => elem.category === tabItem)
              .map((child) => {
                return (
                  <Fields
                    key={child.name}
                    field={child}
                    parentName={props.modalField.name}
                    propsalId={props.propsalId}
                    indexGroupField={Number(props.modalIndex)}
                    parentId={props.modalField.fieldId}
                    propsalIdsReal={props.propsalIdsReal}
                    isMultiple={props?.isMultiple}
                  />
                );
              })}
          </React.Fragment>
        ))
        : props.modalField.childs.map((child) => {
          return (
            <Fields
              key={child.name}
              field={child}
              parentName={props.modalField.name}
              propsalId={props.propsalId}
              indexGroupField={Number(props.modalIndex)}
              parentId={props.modalField.fieldId}
              isMultiple={props?.isMultiple}
            />
          );
        })}
      {/* <div className={classes.buttonsTabs}>
        <Button variant="outlined" onClick={handleCheckValidation}>
          <Typography
            variant="body1"
            component="span"
            style={{ color: "inherit", textTransform: "none" }}
          >
            Проверка
          </Typography>
        </Button>
        <Button variant="outlined" onClick={handleSubmit}>
          <Typography
            variant="body1"
            component="span"
            style={{ color: "inherit", textTransform: "none" }}
          >
            Сохранить
          </Typography>
        </Button>
      </div> */}
      {/* {props.modalField?.isMultiple && props.modalField?.values && (
        <>
          <div className={`blue_link ${classes.end}`}>удалить</div>
        </>
      )}
      {props.modalField?.isMultiple && (
        <button className="button_secondary_w100">Добавить</button>
      )} */}
    </>
  );
};

export default React.memo(ModalFieldBody);
