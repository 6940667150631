import React, { ChangeEvent, memo, useEffect, useState } from "react";

import clsx from "clsx";
import { v4 } from "uuid";

import {
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

import Pagination from '@material-ui/lab/Pagination';
import ChatModalManager from "../../app/components/chat-modal-manager";
import HeaderNew from "../../app/components/redesign/Header/HeaderNew";
import managerService from "../../core/services/ManagerService";
import {
  clientAgentInfoType,
  clientsAgentsListType,
} from "../../core/types/managerApiTypes";
import ClientCard from "./components/client-card";
import ClientInfoModal from "./components/client-info-modal";
import useStylesManagerClients from "./useStylesManagerClients";

/* Redesign. clients page for manager and admin*/

type ClientsSelect = {
  userId: string;
  userName: string;
  inn: string | null;
};

const ManagerClientsPage: React.FC = () => {
  const classes = useStylesManagerClients();
  const [openChatModal, setOpenChatModal] = useState(false);
  const [searchInn, setSearchInn] = useState("");
  const [searchCompanyName, setSearchCompanyName] = useState("");
  const [searchRegion, setSearchRegion] = useState<string>("all");
  const [isAgents, setIsAgents] = useState(false);
  const [isClients, setIsClients] = useState(false);
  const [isClientsAgents, setIsClientsAgents] = useState(false);
  const [searchClientTypes, setSearchClientTypes] = useState("all");
  const [searchStatus, setSearchStatus] = useState("all");
  const [clientsData, setCleintsData] = useState<clientsAgentsListType>();
  const [currentChatId, setCurrentChatId] = useState<string>();
  const [currentChatClientName, setCurrentChatClientName] = useState<string>();
  const [currentClientId, setCurrentClientId] = useState<string>();
  const [currentChatRole, setCurrentChatRole] = useState<string>();
  const [openClientInfoModal, setOpenClientInfoModal] = useState(false);
  const [agentsData, setAgentsData] = useState<ClientsSelect[]>([]);
  const [selectValue, setSelectValue] = useState<ClientsSelect>({
    userName: "",
    inn: "",
    userId: "",
  });
  const [agentsValue, setAgentsValue] = useState("");
  const [clientId, setClientId] = useState<string>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalAmount, setTotalAmount] = useState<number>(0);
  const clientsPerPage = 32;
  const [currentClients, setCurrentClients] = useState<clientAgentInfoType[]>();
  const [allClientsLength, setAllClientsLength] = useState<number>(0);

  const description = `Всего клиентов ${allClientsLength}`

  const paginate = (event: React.ChangeEvent<unknown>, pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleChatModalOpen = (
    id: number,
    inn: string,
    role: string,
    clientId?: string
  ) => {
    if (id === 0) {
      setCurrentClientId(clientId);
      setCurrentChatClientName(inn);
      setCurrentChatRole(role === "Клиент" ? "client" : "agent");
      setOpenChatModal(true);
      setOpenClientInfoModal(false);
    } else {
      setCurrentClientId(undefined);
      setCurrentChatId(id.toString());
      setCurrentChatClientName(inn);
      setCurrentChatRole(role === "Клиент" ? "client" : "agent");
      setOpenChatModal(true);
      setOpenClientInfoModal(false);
    }
  };

  const handleModalChatClose = () => {
    setOpenChatModal(false);
  };

  const handleClientInfoModalOpen = (userId: string, chatId: number) => {
    setClientId(userId);
    setCurrentChatId(chatId.toString());
    setOpenClientInfoModal(true);
  };

  const handleClientInfoModalClose = () => {
    setOpenClientInfoModal(false);
  };

  useEffect(() => {
    let Q = {
      Inn: searchInn,
      Region: searchRegion === "all" ? undefined : searchRegion,
      CompanyName: searchCompanyName === "all" ? undefined : searchCompanyName,
      clientType: searchClientTypes === "all" ? undefined : searchClientTypes,
      bAgents: isAgents,
      bClients: isClients,
      bAgentsClients: isClientsAgents,
      agent_id: agentsValue,
      count: clientsPerPage,
      offset: (currentPage - 1) * clientsPerPage,
    };

    managerService
      .getClietnsAgetnsListService(Q)
      .then((res: clientsAgentsListType) => {

        setCleintsData(res);
        setTotalAmount(res.pagination.total)
        setAgentsData(res.agents);
        setCurrentClients(searchStatus === "all" ? res.clients : res.clients.filter((client) => client.status === searchStatus));
        if (searchRegion === "all" && !searchCompanyName && searchClientTypes === "all" && agentsData.length === 0 && !isAgents && !isClients && !isClientsAgents && !searchInn) { setAllClientsLength(res.pagination.total) }

      })
      .catch((error) => {

        console.log(error);
      });
  }, [
    searchInn,
    searchRegion,
    searchClientTypes,
    isAgents,
    isClients,
    searchCompanyName,
    isClientsAgents,
    searchStatus,
    currentClientId,
    openClientInfoModal,
    agentsValue,
    currentPage
  ]);

  useEffect(() => {
    setCurrentPage(1);
  }, [
    totalAmount,
    searchInn,
    searchRegion,
    searchClientTypes,
    isAgents,
    isClients,
    searchCompanyName,
    isClientsAgents,
    searchStatus,]);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [currentPage])

  const handleRemoveFilters = () => {
    setSearchInn("");
    setSearchCompanyName("");
    setSearchRegion("all");
    setSearchClientTypes("all");
    setSearchStatus("all");
    setIsClients(false);
    setIsAgents(false);
    setIsClientsAgents(false);
    setAgentsValue("");
    setSelectValue({ userName: "", inn: "", userId: "" });
  };

  const handleChoiceClient = (
    _: ChangeEvent<{}>,
    value: ClientsSelect | string
  ) => {
    if (value && typeof value !== "string") {
      setAgentsValue(value.userId);
      setSelectValue(value);
    }
  };

  return (
    <div>
      <HeaderNew title={"Клиенты"} description={description} />
      <Container className={classes.container}>
        <Box className={clsx(classes.searchWrap, classes.box)}>
          <TextField
            label="ИНН клиента"
            color="primary"
            type="search"
            variant="standard"
            onChange={(e) => setSearchInn(e.currentTarget.value)}
            className={classes.search}
            value={searchInn}
          />
          <TextField
            label="Наименование клиента"
            color="primary"
            type="search"
            variant="standard"
            onChange={(e) => setSearchCompanyName(e.currentTarget.value)}
            value={searchCompanyName}
            className={classes.search}
          />
          <Select
            color="secondary"
            value={searchRegion}
            className={classes.select}
            onChange={(e: any) => setSearchRegion(e.target.value)}
          >
            <MenuItem value={"all"}>Все регионы</MenuItem>
            {clientsData &&
              clientsData.regions &&
              clientsData.regions.map((region: string) => {
                return (
                  <MenuItem key={v4()} value={region}>
                    {region}
                  </MenuItem>
                );
              })}
          </Select>
          <Select
            color="secondary"
            value={searchClientTypes}
            className={classes.select}
            onChange={(e: any) => setSearchClientTypes(e.target.value)}
          >
            <MenuItem key={v4()} value={"all"}>
              Все типы
            </MenuItem>
            <MenuItem key={v4()} value={"ul"}>
              Юридические лица
            </MenuItem>
            <MenuItem key={v4()} value={"ip"}>
              ИП
            </MenuItem>
          </Select>
          <Select
            color="secondary"
            value={searchStatus}
            className={classes.select}
            onChange={(e: any) => setSearchStatus(e.target.value)}
          >
            <MenuItem value={"all"}>Все статусы</MenuItem>
            {clientsData &&
              clientsData.clientStatuses &&
              clientsData.clientStatuses.map((clientStatus: string) => (
                <MenuItem key={v4()} value={clientStatus}>
                  {clientStatus}
                </MenuItem>
              ))}
          </Select>
          <Button
            className={classes.resetFiltersBtn}
            variant="text"
            onClick={handleRemoveFilters}
          >
            <Typography
              variant="body2"
              component="span"
              style={{
                color: "#306F9A",
                textTransform: "none",
                lineHeight: "2",
              }}
            >
              Сбросить фильтры
            </Typography>
          </Button>
        </Box>
        <Box className={clsx(classes.searchWrap, classes.filtersWrapper)}>
          <div className={classes.selectAutocompleteWrapper}>
            <Autocomplete
              onChange={handleChoiceClient}
              value={selectValue}
              freeSolo
              getOptionLabel={(option) => option.userName}
              options={agentsData}
              classes={{
                option: classes.optionSelect,
              }}
              disableClearable
              renderInput={(params) => <TextField {...params} label="Закрепление клиентов" />}
              className={classes.selectAutocomplete}
              renderOption={(option, state) => {
                return (
                  <Box
                    component="li"
                    {...state}
                    className={classes.boxSelectOptions}
                  >
                    <Typography
                      variant="body1"
                      component="p"
                      className={classes.selectOptionsLabel}
                    >
                      {option.userName}
                    </Typography>
                    {option.inn && (
                      <Typography
                        variant="body2"
                        component="p"
                        className={classes.selectOptionsInn}
                      >
                        ИНН: {option.inn}
                      </Typography>
                    )}
                    <Divider />
                  </Box>
                );
              }}
            />
          </div>
          <FormGroup className={classes.checkBoxWrap}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={isClients}
                  onChange={() => {
                    setIsClients(!isClients);
                  }}
                />
              }
              label="Показать только клиентов"
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={isAgents}
                  onChange={() => {
                    setIsAgents(!isAgents);
                  }}
                />
              }
              label="Показать только агентов"
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={isClientsAgents}
                  onChange={() => {
                    setIsClientsAgents(!isClientsAgents);
                  }}
                />
              }
              label="Показать только клиентов агента"
            />
          </FormGroup>
        </Box>
        <Typography
          className={classes.countUsers}
          variant="body2"
          component="p"
        >
          Найдено пользователей: {`${totalAmount || 0}`}
        </Typography>
        {clientsData ? (
          <Box className={classes.clientsWrap}>
            <Grid className={clsx(classes.gridTitles, classes.box)}>
              <Grid item xl={3} lg={3} md={3}>
                <Typography
                  className={classes.gridTitle}
                  variant="body2"
                  component="span"
                >
                  Наименование/ИНН
                </Typography>
              </Grid>
              <Grid item xl={3} lg={3} md={3}>
                <Typography
                  className={classes.gridTitle}
                  variant="body2"
                  component="span"
                >
                  Регион и время
                </Typography>
              </Grid>
              <Grid item xl={2} lg={2} md={2}>
                <Typography
                  className={classes.gridTitle}
                  variant="body2"
                  component="span"
                >
                  Дата регистрации
                </Typography>
              </Grid>
              <Grid item xl={3} lg={3} md={3}>
                <Typography
                  className={classes.gridTitle}
                  variant="body2"
                  component="span"
                >
                  Агент
                </Typography>
              </Grid>
              <Grid item xl={2} lg={2} md={2}>
                <Typography
                  className={classes.gridTitle}
                  variant="body2"
                  component="span"
                >
                  Статус
                </Typography>
              </Grid>
              <Grid item xl={2} lg={2} md={2}></Grid>
            </Grid>
            {currentClients &&
              currentClients?.map(
                (client: clientAgentInfoType, index: number) => {
                  return (
                    <Box key={client.userId} className={classes.clientWrap}>
                      <ClientCard
                        client={client}
                        handleChatModalOpen={handleChatModalOpen}
                        handleClientInfoModalOpen={handleClientInfoModalOpen}
                        clientChatId={client.chatIdWithClient}
                      />
                      {index < clientsData.clients.length - 1 && (
                        <Divider className={classes.divider} />
                      )}
                    </Box>
                  );
                }
              )}
          </Box>
        ) : (
          <Box className={clsx(classes.clientsWrap, classes.box)}>
            <Box className={classes.noLeadsWrap}>
              <Typography
                style={{ margin: "auto" }}
                component="h4"
                variant="h4"
              >
                У вас пока нет клиентов.
              </Typography>
            </Box>
          </Box>
        )}
        <Pagination
          count={
            Math.ceil(
              (totalAmount && clientsPerPage)
                ? (totalAmount / clientsPerPage)
                : 0
            )
          }
          siblingCount={2}
          boundaryCount={1}
          style={{ marginTop: '30px', alignSelf: 'flex-end' }}
          page={currentPage}
          classes={{ ul: classes.pagination }}
          onChange={paginate}
          variant="outlined"
          shape="rounded"
          hidePrevButton
          hidden={(totalAmount < clientsPerPage + 1)}
        />
      </Container>
      {openChatModal && (
        <ChatModalManager
          open={openChatModal}
          close={handleModalChatClose}
          currentChatId={currentChatId!}
          clientName={currentChatClientName}
          clientId={currentClientId}
          clientRole={currentChatRole}
        />
      )}
      {openClientInfoModal && clientId && (
        <ClientInfoModal
          open={openClientInfoModal}
          close={handleClientInfoModalClose}
          clientId={clientId}
          currentChatId={currentChatId!}
          handleChatModalOpen={handleChatModalOpen}
        />
      )}
    </div>
  );
};

export default memo(ManagerClientsPage);
