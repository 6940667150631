import {
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  Paper,
  TextField,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import React, { memo, useEffect, useState } from "react";
import { useHistory } from "react-router";
import HeaderNew from "../../app/components/redesign/Header/HeaderNew";
import { ROUTES } from "../../core/consts/common";
import { SearchIcon } from "../../core/consts/images/SearchIcon";
import { BalanceSign } from "../../core/consts/images/balanceSign";
import { DollarSign } from "../../core/consts/images/dollarSign";
import { FileSign } from "../../core/consts/images/fileSIgn";
import { InfoSign } from "../../core/consts/images/infoSign";
import BackMan from "./images/backMan.png";
import BackCircle from "./images/circle.png";

/* Redesign. client scoring */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: '40px 80px',
      margin: "0 auto",
      boxSizing: "border-box",
      maxWidth: '100%'
    },
    paper: {
      padding: theme.spacing(12, 6),
      marginBottom: "40px",
      backgroundImage: `url(${BackMan}), url(${BackCircle})`,
      backgroundPosition: "bottom 0px right 15%, bottom 0px right 10%",
      backgroundRepeat: "no-repeat",
      borderRadius: "20px",
      boxShadow: "none",
    },
    promoWrap: {
      maxWidth: "50%",
      width: "665px",
    },
    form: {
      // display: 'flex',
      paddingTop: "40px",
      width: "100%",
      // justifyContent: 'space-between',
      // alignItems: 'center',
      boxSizing: "border-box",
      // backgroundColor: 'transparent',
      [theme.breakpoints.down("sm")]: {},
    },
    button: {
      flexBasis: "40%",
      height: "51px",
      borderRadius: "4px",
      boxSizing: "border-box",
      marginTop: '20px',
      padding: "0 35px",
      [theme.breakpoints.down("sm")]: {},
    },
    input: {
      background: "rgba(255, 255, 255, 0.01)",
      borderRadius: "4px 4px 0px 0px",
      width: "560px",
      maxWidth: "80%",
      height: "51px",
      marginRight: "25px",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    cardsWrap: {
      padding: "30px",
    },
    infoWrap: {
      maxWidth: "314px",
      paddingLeft: theme.spacing(2),
    },
    grid: {
      display: "flex",
      justifyContent: "space-between",
      padding: theme.spacing(3, 0),
    },
    gridItem: {
      backgroundColor: "#ffffff",
      borderRadius: "10px",
      padding: "32px 30px",
      display: "flex",
      "&:not(:first-child)": {
        marginLeft: theme.spacing(2),
      },
    },
  })
);

const ClientCounterpartyPage: React.FC = () => {
  const classes = useStyles();
  const [isDisabled, setIsDisabled] = useState(true);
  const [value, setValue] = useState("");
  const [isUL, setIsUL] = useState(false);
  const [isIP, setIsIP] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const history = useHistory();

  const handleSearhClick = () => {
    history.push(`${ROUTES.CLIENT}/counterparty-check/${value}`, {
      searchVal: value,
      isUL: isUL,
      isIP: isIP,
      isActive: isActive,
    });
  };

  useEffect(() => {
    if (value !== "") setIsDisabled(false);
  }, [value]);

  const handleChange = (event: any) => {
    setValue(event.target.value);
  };

  return (
    <div>
      <HeaderNew title={"Проверка контрагента"} />
      <Container className={classes.container}>
        <Box>
          <Paper className={classes.paper}>
            <Box className={classes.promoWrap}>
              <Typography variant="h2" component="h2">
                Мы поможем вам работать с людьми, которым можно доверять
              </Typography>
              <Typography
                variant="subtitle1"
                component="p"
                style={{ paddingTop: "25px" }}
              >
                Сообщим о важных изменениях в бизнесе контрагента, снизим
                потенциальные угрозы для вашего бизнеса, раскроем финансовые
                показатели и предоставим выписку из ЕГР
              </Typography>
              <form
                className={classes.form}
                noValidate
                onSubmit={handleSearhClick}
              >
                <TextField
                  onChange={handleChange}
                  name="search"
                  className={classes.input}
                  label="Поиск по названию компании, ИНН, ОГРН или ФИО"
                  required
                  color="secondary"
                  type={"text"}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={isDisabled}
                  className={classes.button}
                  onClick={handleSearhClick}
                >
                  <Typography
                    variant="body1"
                    component="span"
                    style={{ color: "inherit", textTransform: "none" }}
                  >
                    Найти
                  </Typography>
                </Button>
                <FormGroup style={{ display: "block", marginTop: "10px" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={() => setIsActive(!isActive)}
                        checked={isActive}
                        color="primary"
                      />
                    }
                    label="Только действующие"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={() => setIsUL(!isUL)}
                        checked={isUL}
                        color="primary"
                      />
                    }
                    label="ЮЛ"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={() => setIsIP(!isIP)}
                        checked={isIP}
                        color="primary"
                      />
                    }
                    label="ИП"
                  />
                </FormGroup>
              </form>
            </Box>
          </Paper>
          <Divider />
          <Box className={classes.cardsWrap}>
            <Typography variant="h4" component="h4">
              Поможем проверить контрагента по всем параметрам
            </Typography>
            <Grid className={classes.grid}>
              <Grid item xl={3} className={classes.gridItem}>
                <InfoSign />
                <Box className={classes.infoWrap}>
                  <Typography
                    variant="body1"
                    component="h5"
                    style={{ fontWeight: "500" }}
                  >
                    Общая информация
                  </Typography>
                  <Typography
                    variant="body2"
                    component="p"
                    style={{ paddingTop: "20px" }}
                  >
                    Уставный капитал, режим налогооблажения, численность
                    сотрудников, информация о руководителях и многое другое
                  </Typography>
                </Box>
              </Grid>
              <Grid item xl={3} className={classes.gridItem}>
                <FileSign />
                <Box className={classes.infoWrap}>
                  <Typography
                    variant="body1"
                    component="h5"
                    style={{ fontWeight: "500" }}
                  >
                    Выписка из ЕГР
                  </Typography>
                  <Typography
                    variant="body2"
                    component="p"
                    style={{ paddingTop: "20px" }}
                  >
                    Документ, содержащий сведения о компании, занесенные в
                    Единый Государственный Реестр ЮЛ и ИП, подписанный ЭЦП ФНС{" "}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xl={3} className={classes.gridItem}>
                <DollarSign />
                <Box className={classes.infoWrap}>
                  <Typography
                    variant="body1"
                    component="h5"
                    style={{ fontWeight: "500" }}
                  >
                    Финансы
                  </Typography>
                  <Typography
                    variant="body2"
                    component="p"
                    style={{ paddingTop: "20px" }}
                  >
                    Бухгалтерский баланс, финансовые результаты, изменения
                    капитала, движение денежных средств, целевое использование
                    средств
                  </Typography>
                </Box>
              </Grid>
              <Grid item xl={3} className={classes.gridItem}>
                <BalanceSign />
                <Box className={classes.infoWrap}>
                  <Typography
                    variant="body1"
                    component="h5"
                    style={{ fontWeight: "500" }}
                  >
                    Суды, проверки, госконтракты
                  </Typography>
                  <Typography
                    variant="body2"
                    component="p"
                    style={{ paddingTop: "20px" }}
                  >
                    Активные и завершенные проверки, инфоррмация о выигранных
                    госконтрактах, арбитражные дела контрагента
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </div>
  );
};

export default memo(ClientCounterpartyPage);
