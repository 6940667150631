import adminApi from "../api/adminApi";

class AdminService {
    getAdminApplicationService(bank:string, product:string, status: any, sortByBank: any): Promise<any> {
        return adminApi.getAdminApplicationList(bank, product, status, sortByBank)
    }
    getAdminFiltersService(): Promise<any> {
        return adminApi.getAdminFiltersList()
    }
    putAdminUpdateProposalService(id:number, isActive?:boolean, showOnMainPage?:boolean): Promise<any> {
        return adminApi.putAdminUpdateProposal(id, isActive, showOnMainPage)
    }
}

const adminService = new AdminService()
export default adminService
