import React, { memo, useEffect, useState } from "react";
import { FormType } from "../../enum";
import { stringParser } from "../../../core/utils/queryParsers";
import { useQuery } from "../../../core/hooks/useQuery";
import RegistrationClient from "./components/registration-client";
import RegistrationAgent from "./components/registration-agent";
import {
  Button,
  Dialog,
  IconButton,
  Typography,
  DialogProps,
  makeStyles,
  createStyles,
  Theme,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import userActivationService from "../../../core/services/UserActivationService";
import { useHistory } from "react-router";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      dialog: {
        maxWidth: "100%",
        [theme.breakpoints.down(780)]: {
          "& div": {
            "& .MuiDialog-paperWidthFalse": {
              width: "100%",
              maxWidth: "100%",
              padding: "0",
              margin: "0",
              height: "100vh",
            },
          },
          "&:nth-child(3n)>div": {
            maxWidth: "100%",
            "&>div": {
              width: "100%",
              maxWidth: "100%",
              padding: "0",
              margin: "0",
              height: "100vh",
            },
          },
        },
      },
      paper: {
        display: "block",
        width: "780px",
        backgroundColor: theme.palette.background.paper,
        borderRadius: "10px",
        height: "fit-content",
        padding: "50px 75px",
        [theme.breakpoints.down(780)]: {
          borderRadius: "0",
          maxWidth: "100%",
        },
        [theme.breakpoints.down(480)]: {
          padding: "35px 16px",
        },
      },
      register: {
        maxWidth: "100%",
        backgroundColor: theme.palette.background.paper,
        border: "none",
      },
      formBox: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
      },
      box: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        maxWidth: "100%",
      },
      titleBox: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "baseline",
        "& h2": {
          fontSize: "32px",
        },
        [theme.breakpoints.down(780)]: {
          "& h2": {
            fontSize: "28px",
          },
        },
        [theme.breakpoints.down(480)]: {
          flexWrap: "wrap",
          flexDirection: "column",
        },
      },
      title: {
        marginBottom: "25px",
        [theme.breakpoints.down("sm")]: {
          marginBottom: "16px",
        },
      },
      subtitle: {
        marginBottom: "25px",
        [theme.breakpoints.down("sm")]: {
          marginBottom: "16px",
        },
      },
      linkFormChange: {
        margin: "3% 0",
        cursor: "pointer",
      },
      link: {
        textDecoration: "none",
        color: theme.palette.primary.main,
      },
      button: {
        margin: 0,
        width: "130px",
        height: "51px",
        marginBottom: "3%",
        [theme.breakpoints.down(480)]: {
          width: "165px",
          maxWidth: "50%",
        },
      },
      error: {
        marginTop: "15px",
        color: "red",
      },
      arrowBackBtn: {
        display: "flex",
        justifyContent: "space-start",
        padding: "0 0 25px 0",
        "& svg": {
          fill: "#000000",
          fontSize: "22px",
        },
        [theme.breakpoints.up(480)]: {
          display: "none",
        },
      },
      closeBtn: {
        "& svg": {
          fill: "#000000",
          fontSize: "22px",
        },
        [theme.breakpoints.down(480)]: {
          display: "none",
        },
      },
    }),
  { index: 1 }
);

export enum ClientType {
  Client = "client",
  Agent = "agent",
}

const queryParsers = {
  formType: stringParser<FormType>(),
  user: stringParser<ClientType>(),
};

export type FormTypeClient = {
  phone: string;
};

export type FormTypeAgent = {
  phone: string;
  agentType: string;
};

export type FormTypes = FormTypeClient | FormTypeAgent;


type BadRequestType = {
  phone: string;
};

const RegistrationModalDetail: React.FC = () => {
  const [errMessage, setErrMessage] = useState<string>("");
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = useState<boolean>(false);
  const [registrationType, setRegistrationType] = useState<ClientType>();
  const [scroll, setScroll] = React.useState<DialogProps["scroll"]>("body");
  const { formType, user } = useQuery(queryParsers);
  const [isFetching, setFetching] = useState(false);

  useEffect(() => {
    if (formType === FormType.RegistrationDetail) {
      setOpen(true);
      setScroll("body");
    }
    if (user === ClientType.Agent) {
      setRegistrationType(ClientType.Agent);
    }
    if (user === ClientType.Client) {
      setRegistrationType(ClientType.Client);
    }
  }, [formType]);

  const handleClose = () => {
    setOpen(false);
    setRegistrationType(undefined);
    setErrMessage("");
    history.push({
      pathname: history.location.pathname,
    });
  };

  const handleLogin = () => {
    setRegistrationType(undefined);
    setErrMessage("");
    history.push({
      pathname: history.location.pathname,
      search: `?formType=${FormType.LoginFirstEmail}`,
    });
  };

  const openPinCodeModal = () => {
    setRegistrationType(undefined);
    setErrMessage("");
    history.push({
      pathname: history.location.pathname,
      search: `?formType=${FormType.LoginSecondPhone}`,
    });
  };

  const handleSubmitClient = (
    data: FormTypes,
    setBadRequestFields: (
      data: BadRequestType | ((data: BadRequestType) => BadRequestType)
    ) => void
  ) => {
    const registerData = {
      ...data,
      role: registrationType,
      isPhoneCheck: true,
    };
    setFetching(true);
    userActivationService
      .registration(registerData)
      .then((res) => {
        openPinCodeModal();
        setFetching(false);
      })
      .catch((err) => {
        console.log(err);
        setFetching(false);
        if (err.request.status === 500) {
          setErrMessage("Ошибка на сервере.");
        } else if (err.request.status === 429) {
          setErrMessage("Попробуйте через 10 секунд");
        } else if (err.request.status === 400) {
          if (JSON.parse(err.request.response).errors.length > 0) {
            setErrMessage("");
            JSON.parse(err.request.response).errors.forEach((el: any) => {
              setBadRequestFields((state: BadRequestType) => ({
                ...state,
                phone: "",
                [el.field]: el.message,
              }));
            });
          } else {
            setErrMessage("Ошибка.");
          }
        } else {
          setErrMessage("Ошибка.");
        }
      });
  };

  const handleSubmitAgent = (
    data: FormTypeAgent,
    setBadRequestFields: (
      data: BadRequestType | ((data: BadRequestType) => BadRequestType)
    ) => void
  ) => {
    const registerData = {
      ...data,
      role: registrationType,
      isPhoneCheck: true,
    };
    setFetching(true);

    userActivationService
      .registrationAgent(registerData)
      .then((res) => {
        openPinCodeModal();
        setFetching(false);
      })
      .catch((err) => {
        setFetching(false);
        if (err.request.status === 500) {
          setErrMessage("Ошибка на сервере.");
        } else if (err.request.status === 429) {
          setErrMessage("Попробуйте через 10 секунд");
        } else if (err.request.status === 400) {
          if (JSON.parse(err.request.response).errors.length > 0) {
            setErrMessage("");
            JSON.parse(err.request.response).errors.forEach((el: any) => {
              setBadRequestFields((state: BadRequestType) => ({
                ...state,
                phone: "",
                [el.field]: el.message,
              }));
            });
          } else {
            setErrMessage("Ошибка.");
          }
        } else {
          setErrMessage("Ошибка.");
        }
      });
  };

  return formType === FormType.RegistrationDetail ? (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll={"body"}
      fullScreen={false}
      className={classes.dialog}
      maxWidth={false}
    >
      <div className={classes.paper}>
        <div className={classes.register}>
          <div className={classes.titleBox}>
            <IconButton
              className={classes.arrowBackBtn}
              aria-label="close"
              onClick={handleClose}
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography
              variant="h2"
              component="h2"
              className={classes.title}
              id="title-login"
            >
              Регистрация
            </Typography>
            <IconButton
              aria-label="close"
              className={classes.closeBtn}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <Typography
            variant="body1"
            component="p"
            className={`${classes.subtitle}`}
            id="description-login"
          >
            У вас уже есть аккаунт?&nbsp;
            <span
              onClick={handleLogin}
              className={`${classes.link} ${classes.linkFormChange}`}
            >
              Войдите
            </span>
          </Typography>
          <Typography
            variant="body2"
            component="p"
            style={{ fontSize: "12px" }}
          >
            Вы...
          </Typography>
          <div className={classes.box} style={{ justifyContent: "flex-start" }}>
            <Button
              variant={
                registrationType === ClientType.Client
                  ? "contained"
                  : "outlined"
              }
              className={classes.button}
              onClick={() => {
                setRegistrationType(ClientType.Client);
              }}
              color="secondary"
            >
              <Typography
                component="span"
                variant="body1"
                style={{
                  color: "inherit",
                  textTransform: "none",
                  lineHeight: "3",
                }}
              >
                Клиент
              </Typography>
            </Button>
            <Button
              className={classes.button}
              onClick={() => {
                setRegistrationType(ClientType.Agent);
              }}
              variant={
                registrationType === ClientType.Agent ? "contained" : "outlined"
              }
              color="secondary"
            >
              <Typography
                component="span"
                variant="body1"
                style={{
                  color: "inherit",
                  textTransform: "none",
                  lineHeight: "3",
                }}
              >
                Агент
              </Typography>
            </Button>
          </div>
          {registrationType === ClientType.Client && (
            <RegistrationClient
              handleSubmit={handleSubmitClient}
              isFetching={isFetching}
            />
          )}
          {registrationType === ClientType.Agent && (
            <RegistrationAgent
              handleSubmit={handleSubmitAgent}
              isFetching={isFetching}
            />
          )}
          {errMessage && (
            <Typography
              variant="body1"
              component="p"
              className={`${classes.error}`}
            >
              {errMessage}
            </Typography>
          )}
        </div>
      </div>
    </Dialog>
  ) : null;
};

export default memo(RegistrationModalDetail);
