import React, { Suspense, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Route, Switch } from "react-router-dom";
import { rootState } from "../../core/types/rootState";
import LoginNew from "../../pages/loginNew/LoginNew";
import NotFoundPage from "../../pages/not-found/routing";
import partnerApi from "../api/partnerApi";
import userApi, { UtmInitBodyType } from "../api/userApi";
import { CookiesEnum, ROUTES, UTM_EVENT_ENUM } from "../consts/common";
import { useQuery } from "../hooks/useQuery";
import tokenService from "../services/TokenService";
import { stringParser } from "../utils/queryParsers";

const QueryParsers = {
  click_id: stringParser<string>(),
  user_id: stringParser<string>(),
  utm_source: stringParser<string>(),
  utm_medium: stringParser<string>(),
  utm_campaign: stringParser<string>(),
};

type propsType = {
  match: any;
};

const MainRoutes: React.FC<propsType> = ({ match }) => {
  const { click_id, user_id, utm_source, utm_medium, utm_campaign } =
    useQuery(QueryParsers);
  const history = useHistory();
  const isAuth = useSelector((s: rootState) => s.currentUser.isAuth);

  const sendUtmRef = async (utm_source: string, utm_medium: string) => {
    if (!(utm_source && utm_medium)) return;
    const existFollowingLinkEventId = tokenService._getCookie(
      CookiesEnum.followingLinkEventId
    );
    if (existFollowingLinkEventId) return;
    try {
      const body: UtmInitBodyType = {
        utmSource: utm_source,
        utmMedium: utm_medium,
        utmRawData: window.location.href,
        event: UTM_EVENT_ENUM.FollowingLink,
      };
      if (utm_campaign) {
        body.utmCampaign = utm_campaign;
      }
      const followingLinkEventId = await userApi.sendUtm(body);
      if (followingLinkEventId) {
        // localStorage.setItem("followingLinkEventId", followingLinkEventId);
        tokenService._setCookie(
          CookiesEnum.followingLinkEventId,
          followingLinkEventId,
          1
        );
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (utm_source && utm_medium) {
      sendUtmRef(utm_source, utm_medium);
    }
    if (click_id) {
      localStorage.setItem("cid", click_id);
      if (user_id) {
        localStorage.setItem("uid", user_id);
      }
      partnerApi
        .sendClickId(click_id)
        .then((response) => { })
        .catch((error) => {
          console.log("send click id -->", error);
        });
    } else if (!isAuth && history.location.pathname.split("").pop() !== "/") {
      history.push(history.location.pathname + "/");
    }
  }, [history, match]);

  return (
    <>
      <Suspense fallback={<div> </div>}>
        <Switch>
          <Route component={LoginNew} path={ROUTES.LOGIN} exact />
          <Route component={LoginNew} path={ROUTES.MAIN} exact />
          {NotFoundPage}
        </Switch>
      </Suspense>
    </>
  );
};

export default <Route component={MainRoutes} path={ROUTES.MAIN} />;
