import {
  Box,
  Container,
  Grid,
  Paper,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import React, { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import HeaderNew from "../../app/components/redesign/Header/HeaderNew";
import userService from "../../core/services/UserService";
import { rootState } from "../../core/types/rootState";
import { UserInfoType } from "../../core/types/userTypes";

/* Redesign PartnerProfilePage component. */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      maxWidth: "100%",
      padding: '40px 80px',
      margin: "0 auto",
      boxSizing: "border-box",
    },
    grid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, minmax(200px, 1fr))',
      gap: '30px',
      flexWrap: "wrap",
    },
    profileCard: {
      flexBasis: "49.3%",
      padding: "30px",
      borderRadius: "10px",
      position: "relative",
      boxShadow: "none",
    },
  })
);

const PartnerProfile: React.FC = () => {
  const classes = useStyles();
  const initialUser = useSelector((state: rootState) => state.currentUser);
  const [user, setUser] = useState<UserInfoType>();
  const userId = initialUser ? initialUser.userId : "";
  const fullName = `${initialUser?.nameFull} ${initialUser?.role}`

  useEffect(() => {
    initialUser.userId &&
      userService
        .getUserInfoService(userId.toString())
        .then((res: UserInfoType) => {
          setUser(res);
        })
        .catch((error: any) => {
          console.log(error);
        });
  }, [initialUser, userId]);
  return (
    <div>
      <HeaderNew title={fullName} description={user?.bank?.name} />
      <Container className={classes.container}>
        <Box style={{ minHeight: "calc(100vh - 105px)" }}>
          <Grid className={classes.grid}>
            {user?.name && (
              <Paper className={classes.profileCard}>
                <Typography variant="body2" component="h3">
                  ФИО
                </Typography>
                <Typography
                  variant="body1"
                  style={{ color: "#101010", marginTop: "16px" }}
                  component="h3"
                >{`${user?.last_name} ${user?.name} ${user.family_name && user.family_name
                  }`}</Typography>
              </Paper>
            )}
            <Paper className={classes.profileCard}>
              <Typography variant="body2" component="h3">
                Отдел
              </Typography>
              <Typography
                variant="body1"
                style={{ color: "#101010", marginTop: "16px" }}
                component="h3"
              >
                {user?.bank?.name}
              </Typography>
            </Paper>
            <Paper className={classes.profileCard}>
              <Typography variant="body2" component="h3">
                Электронная почта
              </Typography>
              <Typography
                variant="body1"
                style={{ color: "#101010", marginTop: "16px" }}
                component="h3"
              >
                {user?.email}
              </Typography>
            </Paper>
            <Paper className={classes.profileCard}>
              <Typography variant="body2" component="h3">
                Номер для связи
              </Typography>
              <Typography
                variant="body1"
                style={{ color: "#101010", marginTop: "16px" }}
                component="h3"
              >
                {user?.phone}
              </Typography>
            </Paper>
          </Grid>
        </Box>
      </Container>
    </div>
  );
};

export default memo(PartnerProfile);
