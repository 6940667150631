import React from "react";
import { useSelector } from "react-redux";
import { rootState } from "../../../core/types/rootState";
import * as selectors from "../../../core/store/selectors";
import { Field, FieldType } from "../types/bigForm";
import { setInitialValueField, showIfCheck } from "../helpers/helpers";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { setValueField } from "../../../core/store/thunk/newOrder";
import { useAppThunkDispatch } from "../../../core/store";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    showIfHidden: {
      position: "absolute",
      opacity: 0,
      visibility: "hidden",
    },
    showIfVisible: {
      position: "static",
      opacity: 1,
      visibility: "visible",
    },
  })
);

type RequiredProps = {
  field: FieldType;
  propsalId: number;
  parentName?: string;
  indexGroupField?: number;
};

type Props<T> = T & RequiredProps;

const withShowIfFieldModal = <T extends Object>(Component: React.FC<T>) => {
  return (props: Props<T>) => {
    const classes = useStyles();
    const dispatch = useAppThunkDispatch();
    const dependentFieldValue = useSelector((state: rootState) =>
      selectors.currentModalFieldValue(state, {
        fieldName: props.field?.show_if[0].Field,
      })
    );
    const [prevDependentValue, setPrevDependentValue] =
      React.useState(dependentFieldValue);

    React.useEffect(() => {
      // if (prevDependentValue !== dependentFieldValue) {
      if (prevDependentValue?.toString() !== dependentFieldValue?.toString()) {
        setPrevDependentValue(dependentFieldValue);
        const emptyValue = setInitialValueField(props.field);
        dispatch(
          setValueField(
            props.field.name,
            emptyValue,
            props.propsalId,
            props.parentName ? props.parentName : undefined,
            props.indexGroupField !== undefined
              ? props.indexGroupField
              : undefined
          )
        );
      }
    }, [dependentFieldValue]);

    if (dependentFieldValue !== undefined) {
      const isVisible = showIfCheck(
        dependentFieldValue as Exclude<Field, string[]>,
        props.field?.show_if
      );

      return (
        <Component
          {...props}
          classNameShowIf={
            isVisible ? classes.showIfVisible : classes.showIfHidden
          }
        />
      );
    }

    return <Component {...props} />;
  };
};

export default withShowIfFieldModal;
