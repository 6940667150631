import { ErrorField, ErrorsResponse } from "../types/bigForm";

export const handleValidationCommonField = (
  validationRequest: ErrorsResponse[],
  commonFields: string[]
) => {
  const commonFieldsError = new Set<ErrorField>();
  for (const validationErrors in validationRequest) {
    if (
      Object.prototype.hasOwnProperty.call(validationRequest, validationErrors)
    ) {
      const commonErrors = validationRequest[validationErrors].errors.filter(
        (field) =>
          commonFields.find((commonField) => commonField === field.field)
      );
      commonErrors.forEach((err) => commonFieldsError.add(err));
    }
  }
  return Array.from(commonFieldsError);
};

export const handleValidationCommonFieldNEW = (
  validationRequest: ErrorsResponse[],
  commonFields: string[]
) => {
  const commonFieldsError = new Set<ErrorField>();
  for (const validationErrors in validationRequest) {
    if (
      Object.prototype.hasOwnProperty.call(validationRequest, validationErrors)
    ) {
      const commonErrors = validationRequest[validationErrors].errors.filter(
        (field) =>
          commonFields.find((commonField) => commonField === field.field)
      );
      commonErrors.forEach((err) => commonFieldsError.add(err));
    }
  }
  // console.log("handleValidationCommonFieldNEW Array.from(commonFieldsError)",Array.from(commonFieldsError))
  return Array.from(commonFieldsError);
};
