import { Box } from "@mui/material";
import CircularProgress, {
  CircularProgressProps,
  circularProgressClasses,
} from "@mui/material/CircularProgress";
import { COLORS } from "../core/consts/redesign/commonRedesign";

interface SpinnerMainProps extends CircularProgressProps {
  fixedPosition?: boolean;
}

const SpinnerMain = ({ fixedPosition = false, ...props }: SpinnerMainProps) => {
  return (
    <div
      style={{
        width: "100%",
        minHeight: "70vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        gap: "16px",
        ...(fixedPosition && {
          minHeight: "100vh",
          position: "fixed",
          top: 0,
          left: 0,
          backgroundColor: "transparent",
          zIndex: 9999,
        }),
      }}
    >
      <Box sx={{ position: "relative" }}>
        <CircularProgress
          variant="determinate"
          sx={{
            color: (theme) =>
              COLORS.light_gray_stroke,
          }}
          size={32}
          thickness={4}
          {...props}
          value={100}
        />
        <CircularProgress
          variant="indeterminate"
          disableShrink
          sx={{
            color: (theme) =>
              theme.palette.mode === "light"
                ? COLORS.brand_blue
                : COLORS.brand_blue,
            animationDuration: "550ms",
            position: "absolute",
            left: 0,
            [`& .${circularProgressClasses.circle}`]: {
              strokeLinecap: "round",
            },
          }}
          size={32}
          thickness={4}
          {...props}
        />
      </Box>
      {!fixedPosition &&
        <span style={{
          fontFamily: 'Gilroy',
          fontSize: '16px',
          lineHeight: '18px',
          fontWeight: 500,
        }}>загружаем данные</span>}
    </div>
  );
};

export default SpinnerMain;
