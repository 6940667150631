export type BigFormState = {
    errorsFieldsArray: string[]
    isDisplay: boolean
    propsalsId: number[]
}

export type FetchUpdateErrorsArrayPayload = {
    errorsFieldsArray: string[]
}

export type SetIsDisplayInAccordionPayload = {
    isDisplay: boolean
}

export type SetPropsalsIdPayload = {
    propsalsId: number[]
}

export enum bigFormActionTypes {
    UPDATE_ERRORS_ARRAY = 'UPDATE_ERRORS_ARRAY',
    DISPLAY_SIGNALS_IN_ACCORDION='DISPLAY_SIGNALS_IN_ACCORDION',
    SET_PROPSALS_ID = 'SET_PROPSALS_ID'
}

interface FetchUpdateErrorsArray {
    type: bigFormActionTypes.UPDATE_ERRORS_ARRAY;
    payload: FetchUpdateErrorsArrayPayload
}

interface SetIsDisplayInAccordion {
    type: bigFormActionTypes.DISPLAY_SIGNALS_IN_ACCORDION;
    payload: SetIsDisplayInAccordionPayload
}

interface SetPropsalsId {
    type: bigFormActionTypes.SET_PROPSALS_ID;
    payload: SetPropsalsIdPayload
}

export type BigFormAction = FetchUpdateErrorsArray | SetIsDisplayInAccordion | SetPropsalsId

export type SendNewOrderResponse = {
    ids: number[]
}