export type Manager = {
  id: string;
  fio: string;
  phone: string;
  email: string;
  avatar_url: string;
};

export interface CurrentUserState {
  userId: string;
  isAuth: boolean;
  phone: string;
  inn: string;
  role: string;
  companyName?: string;
  manager: Manager | null;
  nameFull: string;
  email: string;
  is_manager_approval: boolean | undefined;
  isAgentLimited?: boolean | undefined;
  status: string | undefined;
  code: string;
}

export type SetCurrentUserPayload = {
  userId: string;
  isAuth: boolean;
  phone: string;
  inn: string;
  role: string;
  companyName?: string,
  manager: Manager | null;
  nameFull: string;
  email: string;
  is_manager_approval: boolean | undefined;
  status: string | undefined;
};

export enum CurrentUserTypes {
  SET_USER = "SET_USER",
  SET_CODE = "SET_CODE",
  SET_AGENT_LIMIT = "SET_AGENT_LIMIT"
}
export interface SetCurrentUserAction {
  type: CurrentUserTypes.SET_USER;
  payload?: SetCurrentUserPayload;
}

export interface SetCurrentUserCode {
  type: CurrentUserTypes.SET_CODE;
  payload: string;
}

export interface SetCurrentUserAgentLimit {
  type: CurrentUserTypes.SET_AGENT_LIMIT;
  payload: boolean;
}

export type CurrentUserActions = SetCurrentUserAction | SetCurrentUserCode | SetCurrentUserAgentLimit;
