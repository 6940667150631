export const LOGO = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="84"
    height="24"
    viewBox="0 0 84 24"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 0C1.34315 0 0 1.34315 0 3V21C0 22.6569 1.34315 24 3 24H21C22.6569 24 24 22.6569 24 21V3C24 1.34315 22.6569 0 21 0H3ZM10.8941 2C10.5156 2 10.2087 2.30685 10.2087 2.68538C10.2087 3.0639 10.5156 3.37076 10.8941 3.37076H19.6635L10.4821 12.5468C10.2156 12.8132 10.2147 13.2451 10.4801 13.5126C10.7469 13.7815 11.1814 13.7824 11.4494 13.5146L20.6342 4.33511V12.8315C20.6342 13.2086 20.94 13.5143 21.3171 13.5143C21.6943 13.5143 22 13.2086 22 12.8315V2.87287C22 2.3908 21.6092 2 21.1271 2H10.8941Z"
      fill="#4794EF"
    />
    <path
      d="M41.26 9V19H39.52V10.68H35.82V19H34.08V10.68H32.48V9H34.08V8.7C34.08 6.06 35.54 4.74 38.46 4.74C39.0067 4.74 39.6933 4.82667 40.52 5V6.58C39.7067 6.47333 39.02 6.42 38.46 6.42C37.5533 6.42 36.8867 6.59333 36.46 6.94C36.0333 7.27333 35.82 7.86 35.82 8.7V9H41.26Z"
      fill="#001317"
    />
    <path
      d="M48.9055 8.74C50.0788 8.74 51.0188 9.11333 51.7255 9.86C52.4321 10.5933 52.7855 11.5933 52.7855 12.86V19H51.0455V12.96C51.0455 12.1467 50.8255 11.52 50.3855 11.08C49.9455 10.6267 49.3388 10.4 48.5655 10.4C47.6988 10.4 46.9988 10.6733 46.4655 11.22C45.9321 11.7533 45.6655 12.58 45.6655 13.7V19H43.9255V9H45.6655V10.44C46.3721 9.30667 47.4521 8.74 48.9055 8.74Z"
      fill="#001317"
    />
    <path d="M55.2145 19V4.4H56.9545V19H55.2145Z" fill="#001317" />
    <path
      d="M67.8891 9H69.6291V19H67.8891V17.28C67.0224 18.6 65.7624 19.26 64.1091 19.26C62.7091 19.26 61.5157 18.7533 60.5291 17.74C59.5424 16.7133 59.0491 15.4667 59.0491 14C59.0491 12.5333 59.5424 11.2933 60.5291 10.28C61.5157 9.25333 62.7091 8.74 64.1091 8.74C65.7624 8.74 67.0224 9.4 67.8891 10.72V9ZM64.3291 17.58C65.3424 17.58 66.1891 17.24 66.8691 16.56C67.5491 15.8667 67.8891 15.0133 67.8891 14C67.8891 12.9867 67.5491 12.14 66.8691 11.46C66.1891 10.7667 65.3424 10.42 64.3291 10.42C63.3291 10.42 62.4891 10.7667 61.8091 11.46C61.1291 12.14 60.7891 12.9867 60.7891 14C60.7891 15.0133 61.1291 15.8667 61.8091 16.56C62.4891 17.24 63.3291 17.58 64.3291 17.58Z"
      fill="#001317"
    />
    <path
      d="M77.7853 8.74C79.1853 8.74 80.3786 9.25333 81.3653 10.28C82.352 11.2933 82.8453 12.5333 82.8453 14C82.8453 15.4667 82.352 16.7133 81.3653 17.74C80.3786 18.7533 79.1853 19.26 77.7853 19.26C76.132 19.26 74.872 18.6 74.0053 17.28V19H72.2653V5H74.0053V10.72C74.872 9.4 76.132 8.74 77.7853 8.74ZM77.5653 17.58C78.5653 17.58 79.4053 17.24 80.0853 16.56C80.7653 15.8667 81.1053 15.0133 81.1053 14C81.1053 12.9867 80.7653 12.14 80.0853 11.46C79.4053 10.7667 78.5653 10.42 77.5653 10.42C76.552 10.42 75.7053 10.7667 75.0253 11.46C74.3453 12.14 74.0053 12.9867 74.0053 14C74.0053 15.0133 74.3453 15.8667 75.0253 16.56C75.7053 17.24 76.552 17.58 77.5653 17.58Z"
      fill="#001317"
    />
  </svg>
);