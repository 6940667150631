import { Button, makeStyles } from "@material-ui/core";
import React, { memo } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router";
import Header from "../../app/components/header";
import { ROUTES } from "../../core/consts/common";
import image404 from "./images/404.png";
import bottomGround from "./images/bottomGround.png";


const useStyles = makeStyles((theme) => ({
  wrapper: {
    height: '100vh',
    maxWidth: '100%',
    zIndex: 3,
  },
  container: {
    width: '100%',
    height: '100%',
    position: 'relative',
    backgroundColor: '#EDF2F5',
    backgroundImage: `url(${image404})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "100% 50%",
    [theme.breakpoints.down(1600)]: {
      backgroundSize: "80%",
    },
    [theme.breakpoints.down(1200)]: {
      backgroundSize: "auto 60%",
      backgroundPosition: "70% center",
    },
    [theme.breakpoints.down(800)]: {
      backgroundSize: "auto 45%",
      backgroundPosition: "60% 75%",
    },
    [theme.breakpoints.down(500)]: {
      backgroundSize: "140%",
      backgroundPosition: "0 90%",
    }
  },
  headerContainer: {
    height: '151px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down(1000)]: {

    },
    [theme.breakpoints.down(500)]: {
      height: '91px',
    }
  },
  header: {
    height: '100%',
    width: '1290px',
    [theme.breakpoints.down(1400)]: {
      width: '100%',
      padding: '0 22px'
    }
  },
  contentWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  content: {
    zIndex: 2,
    marginTop: '31px',
    width: '1290px',
    [theme.breakpoints.down(1400)]: {
      width: '100%',
      padding: '0 22px'
    },
    [theme.breakpoints.down(500)]: {
      marginTop: '8px',
      padding: '0 16px'
    }

  },
  title: {
    color: '#131313',
    fontSize: '48px',
    fontFamily: "Gilroy",
    fontWeight: 600,
    width: '630px',
    [theme.breakpoints.down(1200)]: {
      fontSize: '32px',
      width: '468px',
    },
    [theme.breakpoints.down(800)]: {
      width: '350px',
    },
    [theme.breakpoints.down(500)]: {
      width: '100%',
      fontSize: '28px'
    }
  },
  subTitle: {
    width: '550px',
    color: '#131313',
    fontSize: '18px',
    fontFamily: "Gilroy",
    fontWeight: 500,
    marginTop: '31px',
    [theme.breakpoints.down(1200)]: {
      width: '468px',
      fontSize: '16px',
      marginTop: '25px',
    },
    [theme.breakpoints.down(800)]: {
      width: '350px',
      maxWidth: '100%'
    },
    [theme.breakpoints.down(500)]: {
      width: '100%',
      marginTop: '16px'
    }
  },
  button: {
    background: "#306F9A",
    marginTop: '50px',
    color: "white",
    cursor: "pointer",
    textTransform: "none",
    borderRadius: "4px",
    padding: "10px 15px",
    fontSize: "14px",
    [theme.breakpoints.down(500)]: {
      marginTop: '24px',
      width: '100%'
    }
  },
  bottomGround: {
    position: 'absolute',
    zIndex: 0,
    bottom: '0',
    left: 0,
    width: '100%',
    [theme.breakpoints.down(800)]: {
      height: '30%'
    },
    [theme.breakpoints.down(500)]: {
      display: 'none'
    }
  },
  image: {
    width: '100%',
    objectFit: 'contain',
    [theme.breakpoints.down(800)]: {
      objectFit: 'cover',
      height: '100%'
    }
  }
}));

const NotFoundPage: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();

  const handleClick = () => {
    history.push(ROUTES.MAIN);
  };

  return (
    <div className={classes.wrapper}>
      <Helmet>
        <title>Страница не найдена</title>
        <meta name="prerender-status-code" content="404" />
      </Helmet>
      <div className={classes.container}>
        <div className={classes.headerContainer}>
          <div className={classes.header}>
            <Header />
          </div>
        </div>
        <div className={classes.contentWrapper}>
          <div className={classes.content}>
            <div className={classes.title}>Ой! Страница  не найдена, или  находится в разработке</div>
            <div className={classes.subTitle}>Мы приложим все усилия, чтобы ее вернуть, а пока  можете вернуться на главную страницу</div>
            <Button
              className={classes.button}
              onClick={handleClick}
              variant="contained"
            >
              На главную
            </Button>
          </div>
        </div>
        <div className={classes.bottomGround}>
          <img src={bottomGround} className={classes.image} />
        </div>
      </div>
    </div>
  );
};

export default memo(NotFoundPage);
