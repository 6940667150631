import {
  ProposalDataType,
  SendDataType,
} from "../../pages/admin-create-product/AdminCreateProduct";
import { http } from "../infrastructure/Https";
import tokenService from "../services/TokenService";
import {
  FiltersType,
  GetPropsQueryType,
  GetPropsUniversalQueryType,
  InnerProductDetailsData,
  ProposalCalculatorBodyType,
  ProposalCalculatorType,
  RecommendedType,
  ResponseDataType,
} from "../types/proposalTypes";

const ROOT_URL = "/api/v1/proposal";
const ROOT_URL2 = "/api/v1/proposalV2";
type CardInfoType = {
  title: string;
  description: string;
};

export type Filters = {
  objectives: string[],
  purchaseType: string[],
  garantiaType: string[],
  factoringType: string[],
};

type proposalCardCondition = {
  title: string,
  description: string,
  weight: number,
  minValue: number,
  maxValue: number,
  minSuffix: string,
  maxSuffix: string,
};

export type GetCardInfoResponse = {
  cardInfo: CardInfoType[];
  fileId: number | null;
  image: string;
  isActive: boolean;
  like: number;
  newTagName: string | null;
  proccessProposalCard: string;
  proposalInfo: {
    productsInfo: ProposalDataType[];
  };
  reference: string;
  seoDescription: string | null;
  showOnMainPage: boolean;
  tagId: null | number;
  tariffFileName: null | string;
  view: number;
  createLKFields: boolean;
  disableLKFields: boolean;
  id: number;
  name: string;
  productId: number;
  bankId: number;
  srokMin: number;
  srokMax: number;
  rateMin: string;
  rateMax: string;
  sumMin: number;
  sumMax: number;
  isPromoted: true;
  srokMinType: number;
  srokMaxType: number;
  isRealCard: true;
  bestOfMonth: true;
  tagIds: {
    tagId: number,
    name: string
  }[];
  realProposalId: number | null;
  filters: Filters;
  tariffFileSize: number;
  isLKPartner: true;
  isState: true;
  alias: string;
  feeMin: string;
  feeMax: string;
  postponementMin: number;
  postponementMax: number;
  proposalCardCondition: proposalCardCondition[];
};

class ProposalsApi {
  getBestProposals(
    prodict_id: number,
    sort_type: string,
    Q?: any
  ): Promise<ResponseDataType> {
    return http.get(
      `${ROOT_URL}s/best/${prodict_id.toString()}/${sort_type}`,
      Q
    );
  }

  //TODO: get filters for product
  getProductsFilters(productId: number): Promise<FiltersType> {
    const accessToken = tokenService.getAccessToken();
    return http.get(
      `${ROOT_URL}s/filter/config/${productId}`,
      null,
      accessToken
    );
  }

  // новая универсальная ручка
  getAllProposalsUniversal(
    Q: GetPropsUniversalQueryType
  ): Promise<ResponseDataType> {
    return http.post(`${ROOT_URL}s`, null, Q);
  }

  // расчет для предложения по выбранным параметрам калькулятора
  getProposalCalculator(body: ProposalCalculatorBodyType): Promise<number> {
    return http.post(`${ROOT_URL}Calculator`, null, body);
  }

  // параметры калькулятора для предложения
  getProposalCalculatorParameters(
    proposalId: number
  ): Promise<ProposalCalculatorType> {
    return http.get(`${ROOT_URL}CalculatorParameters`, {
      proposalId: proposalId,
    });
  }

  getCompareProposals(Q: any): Promise<any> {
    return http.post(
      `${ROOT_URL}/compare`, null, { id: Q }
    );
  }

  getInnerProposalPageInfo(
    proposalid?: string,
    alias?: string
  ): Promise<InnerProductDetailsData> {
    return proposalid
      ? http.get(`${ROOT_URL}/detail?proposalid=${proposalid}`)
      : http.get(`${ROOT_URL}/detail?alias=${alias}`);
  }

  increaseProposalLikes(id: string): Promise<string> {
    return http.put(`${ROOT_URL}/${id}/like`);
  }

  getPopularProposals(Q: GetPropsQueryType): Promise<ResponseDataType> {
    return http.get(`${ROOT_URL}s/popular`, Q);
  }
  getProfitableProposals(Q: GetPropsQueryType): Promise<ResponseDataType> {
    return http.get(`${ROOT_URL}s/profitable`, Q);
  }

  getRecommendedProposals(  //TODO: remove unused handle
    inn?: string,
    bankIds?: number[],
    filters?: any,
    productid?: number
  ): Promise<RecommendedType[]> {
    const accessToken = tokenService.getAccessToken();
    // console.log("api getRecommendedProposals filters", filters)
    const params = new URLSearchParams();
    if (bankIds) bankIds.map((i) => params.append("bankIds", `${i}`));
    if (inn) params.append("inn", `${inn}`);
    if (productid) params.append("productid", `${productid}`);
    if (filters?.cost) {
      params.append("SumMin", `${filters.cost[0]}`);
      params.append("SumMax", `${filters.cost[1]}`);
    }
    if (filters?.time) params.append("Srok", `${filters.time}`);
    if (filters?.objective) params.append("Objective", filters.objective);
    if (filters?.boughtType) params.append("PurchaseType", filters.boughtType);
    if (filters?.bgType) params.append("GarantiaType", filters.bgType);
    if (filters?.factoringType)
      params.append("FactoringType", filters.factoringType);
    if (filters?.city) params.append("name", filters.city);
    // console.log("api getRecommendedProposals params", params.toString())

    return http.getWithParams(
      `${ROOT_URL}s/recommended`,
      params,
      null,
      accessToken
    );
  }

  getAllProposals(Q: GetPropsQueryType): Promise<ResponseDataType> {
    return http.get(`${ROOT_URL}s`, Q);
  }

  increaseProposalViews(id: string): Promise<string> {
    return http.put(`${ROOT_URL}/${id}/view`);
  }

  getCardInfo(id: string): Promise<GetCardInfoResponse> {
    const accessToken = tokenService.getAccessToken();
    return http.get(`${ROOT_URL2}/${id}`, undefined, accessToken);
  }
  postNewCardInfo(data: SendDataType, srokType: any[]): Promise<any> {
    const accessToken = tokenService.getAccessToken();
    const formData = new FormData();
    const proposalData = {
      // Terms: data.termsData,
      ProductsInfo: data.proposalsData,
      // ProductDescription: data.productDescription,
    };
    formData.append("Proposal.Name", data.title);
    formData.append("Proposal.ProductId", data.productId);
    formData.append("Proposal.BankId", data.bankId);
    formData.append(
      "Proposal.TagIdsString",
      JSON.stringify(data.tagIds)
    )
    formData.append(
      "Proposal.ProposalInfoString",
      JSON.stringify(proposalData)
    );
    formData.append(
      "Proposal.CreateLKFields",
      JSON.stringify(data.cardViewData.createLKFields)
    );
    if (data.filters) {
      formData.append("Proposal.FiltersString", JSON.stringify(data.filters));
    }
    formData.append("Proposal.Image", data.image);
    formData.append(
      "Proposal.IsActive",
      JSON.stringify(data.cardViewData.isActive)
    );
    formData.append(
      "Proposal.ShowOnMainPage",
      JSON.stringify(data.cardViewData.showOnMainPage)
    );
    if (data.cardViewData.newTagName) {
      formData.append("Proposal.NewTagName", data.cardViewData.newTagName);
    } else if (data.cardViewData.proposalTag !== "Без стикера") {
      formData.append("Proposal.TagId", data.cardViewData.proposalTag);
    }
    formData.append("Proposal.Like", JSON.stringify(data.cardViewData.like));
    formData.append("Proposal.View", JSON.stringify(data.cardViewData.view));
    if (data.cardViewData.reference) {
      formData.append("Proposal.Reference", data.cardViewData.reference);
    }
    formData.append(
      "Proposal.ProccessProposalCard",
      data.cardViewData.proccessProposalCard
    );
    formData.append("Proposal.SEODescription", data.seoDescription);
    if (data.file) {
      formData.append("TariffFile", data.file);
    }
    if (data.realProposalId) {
      formData.append("Proposal.RealProposalId", JSON.stringify(data.realProposalId));
    }
    formData.append(
      "Proposal.IsPromoted",
      data.isPromoted.toString()
    );
    formData.append(
      "Proposal.BestOfMonth",
      data.bestOfMonth.toString()
    );
    if (data.descriptionFields) {
      if (data.productId === "1002") {
        formData.append(
          "Proposal.CardInfoString",
          JSON.stringify(data.descriptionFields)
        );
      } else {
        data.descriptionFields.forEach((el: any) => {
          if (el.title === "Взнос" || el.title === "Первонач. взнос") {
            if (el.maxValue || el.maxValue === 0)
              formData.append(
                "Proposal.FeeMax",
                JSON.stringify(el.maxValue).replace(".", ",")
              );
            if (el.minValue || el.minValue === 0)
              formData.append(
                "Proposal.FeeMin",
                JSON.stringify(el.minValue).replace(".", ",")
              );
          }
          if (el.title === "Сумма") {
            if (el.maxValue || el.maxValue === 0)
              formData.append(
                "Proposal.SumMax",
                JSON.stringify(el.maxValue).replace(".", ",")
              );
            if (el.minValue || el.minValue === 0)
              formData.append(
                "Proposal.SumMin",
                JSON.stringify(el.minValue).replace(".", ",")
              );
          }
          if (el.title === "Срок") {
            if (el.maxValue || el.maxValue === 0)
              formData.append(
                "Proposal.SrokMax",
                JSON.stringify(el.maxValue).replace(".", ",")
              );
            if (el.minValue || el.minValue === 0)
              formData.append(
                "Proposal.SrokMin",
                JSON.stringify(el.minValue).replace(".", ",")
              );
            el.maxSuffix &&
              formData.append(
                "Proposal.SrokMaxType",
                JSON.stringify(
                  +srokType.find((elem) => elem.text === el.maxSuffix).key
                )
              );
            el.minSuffix &&
              formData.append(
                "Proposal.SrokMinType",
                JSON.stringify(
                  +srokType.find((elem) => elem.text === el.minSuffix).key
                )
              );
          }
          if (el.title === "Отсрочка" || el.title === "Льготный период") {
            if (el.maxValue || el.maxValue === 0)
              formData.append(
                "Proposal.PostponementMax",
                JSON.stringify(el.maxValue).replace(".", ",")
              );
            if (el.minValue || el.minValue === 0)
              formData.append(
                "Proposal.PostponementMin",
                JSON.stringify(el.minValue).replace(".", ",")
              );
            el.maxSuffix &&
              formData.append(
                "Proposal.SrokMaxType",
                JSON.stringify(
                  +srokType.find((elem) => elem.text === el.maxSuffix).key
                )
              );
            el.minSuffix &&
              formData.append(
                "Proposal.SrokMinType",
                JSON.stringify(
                  +srokType.find((elem) => elem.text === el.minSuffix).key
                )
              );
          }
          if (el.title === "Ставка") {
            if (el.maxValue || el.maxValue === 0)
              formData.append(
                "Proposal.RateMax",
                JSON.stringify(el.maxValue).replace(".", ",")
              );
            if (el.minValue || el.minValue === 0)
              formData.append(
                "Proposal.RateMin",
                JSON.stringify(el.minValue).replace(".", ",")
              );
          }
        });
      }
    }

    return http.post(`${ROOT_URL2}`, null, formData, accessToken);
  }

  putNewCardInfo(
    data: SendDataType,
    srokType: any[],
    id: number
  ): Promise<any> {
    const accessToken = tokenService.getAccessToken();
    const formData = new FormData();
    const proposalData = {
      // Terms: data.termsData,
      ProductsInfo: data.proposalsData,
      // ProductDescription: data.productDescription,
    };
    formData.append("Proposal.Id", JSON.stringify(id));
    formData.append("Proposal.Name", data.title);
    formData.append("Proposal.ProductId", data.productId);
    formData.append("Proposal.BankId", data.bankId);
    formData.append(
      "Proposal.TagIdsString",
      JSON.stringify(data.tagIds)
    )
    formData.append(
      "Proposal.ProposalInfoString",
      JSON.stringify(proposalData)
    );
    data.cardViewData.fileId ||
      (data.cardViewData.fileId === 0 &&
        formData.append(
          "Proposal.FileId",
          JSON.stringify(data.cardViewData.fileId)
        ));
    formData.append("Proposal.Image", data.image);
    formData.append(
      "Proposal.IsActive",
      JSON.stringify(data.cardViewData.isActive)
    );
    formData.append(
      "Proposal.ShowOnMainPage",
      JSON.stringify(data.cardViewData.showOnMainPage)
    );
    if (data.cardViewData.newTagName) {
      formData.append("Proposal.NewTagName", data.cardViewData.newTagName);
    } else if (data.cardViewData.proposalTag !== "Без стикера") {
      formData.append("Proposal.TagId", data.cardViewData.proposalTag);
    }
    formData.append(
      "Proposal.CreateLKFields",
      JSON.stringify(data.cardViewData.createLKFields)
    );
    if (data.filters) {
      formData.append("Proposal.FiltersString", JSON.stringify(data.filters));
    }
    formData.append("Proposal.Like", JSON.stringify(data.cardViewData.like));
    formData.append("Proposal.View", JSON.stringify(data.cardViewData.view));
    if (data.cardViewData.reference) {
      formData.append("Proposal.Reference", data.cardViewData.reference);
    }
    formData.append(
      "Proposal.ProccessProposalCard",
      data.cardViewData.proccessProposalCard
    );
    formData.append("Proposal.SEODescription", data.seoDescription);
    if (data.file) {
      formData.append("TariffFile", data.file);
    }
    if (data.realProposalId) {
      formData.append("Proposal.RealProposalId", JSON.stringify(data.realProposalId));
    }
    formData.append(
      "Proposal.IsPromoted",
      data.isPromoted.toString()
    );
    formData.append(
      "Proposal.BestOfMonth",
      data.bestOfMonth.toString()
    );
    if (data.descriptionFields) {
      if (data.productId === "1002") {
        formData.append(
          "Proposal.CardInfoString",
          JSON.stringify(data.descriptionFields)
        );
      } else {
        data.descriptionFields.forEach((el: any) => {
          if (el.title === "Взнос" || el.title === "Первонач. взнос") {
            if (el.maxValue || el.maxValue === 0)
              formData.append(
                "Proposal.FeeMax",
                JSON.stringify(el.maxValue).replace(".", ",")
              );
            if (el.minValue || el.minValue === 0)
              formData.append(
                "Proposal.FeeMin",
                JSON.stringify(el.minValue).replace(".", ",")
              );
          }
          if (el.title === "Сумма") {
            if (el.maxValue || el.maxValue === 0)
              formData.append(
                "Proposal.SumMax",
                JSON.stringify(el.maxValue).replace(".", ",")
              );
            if (el.minValue || el.minValue === 0)
              formData.append(
                "Proposal.SumMin",
                JSON.stringify(el.minValue).replace(".", ",")
              );
          }
          if (el.title === "Срок") {
            if (el.maxValue || el.maxValue === 0)
              formData.append(
                "Proposal.SrokMax",
                JSON.stringify(el.maxValue).replace(".", ",")
              );
            if (el.minValue || el.minValue === 0)
              formData.append(
                "Proposal.SrokMin",
                JSON.stringify(el.minValue).replace(".", ",")
              );
            el.maxSuffix &&
              formData.append(
                "Proposal.SrokMaxType",
                JSON.stringify(
                  +srokType.find((elem) => elem.text === el.maxSuffix).key
                )
              );
            el.minSuffix &&
              formData.append(
                "Proposal.SrokMinType",
                JSON.stringify(
                  +srokType.find((elem) => elem.text === el.minSuffix).key
                )
              );
          }
          if (el.title === "Отсрочка" || el.title === "Льготный период") {
            if (el.maxValue || el.maxValue === 0)
              formData.append(
                "Proposal.PostponementMax",
                JSON.stringify(el.maxValue).replace(".", ",")
              );

            if (el.minValue || el.minValue === 0)
              formData.append(
                "Proposal.PostponementMin",
                JSON.stringify(el.minValue).replace(".", ",")
              );
            el.maxSuffix &&
              formData.append(
                "Proposal.SrokMaxType",
                JSON.stringify(
                  +srokType.find((elem) => elem.text === el.maxSuffix).key
                )
              );
            el.minSuffix &&
              formData.append(
                "Proposal.SrokMinType",
                JSON.stringify(
                  +srokType.find((elem) => elem.text === el.minSuffix).key
                )
              );
          }
          if (el.title === "Ставка") {
            if (el.maxValue || el.maxValue === 0)
              formData.append(
                "Proposal.RateMax",
                JSON.stringify(el.maxValue).replace(".", ",")
              );
            if (el.minValue || el.minValue === 0)
              formData.append(
                "Proposal.RateMin",
                JSON.stringify(el.minValue).replace(".", ",")
              );
          }
        });
      }
    }

    return http.put(`${ROOT_URL2}`, null, formData, accessToken);
  }

  getAllTags(): Promise<any> {
    const accessToken = tokenService.getAccessToken();
    return http.get(`${ROOT_URL}s/tag/get`, undefined, accessToken);
  }
}

const proposalsApi = new ProposalsApi();
export default proposalsApi;
