import { Theme, Typography, createStyles, makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    comment: {
      paddingTop: "25px",
    },
    text: {
      wordWrap: "break-word",
    },
    dateBox: {
      display: "flex",
      paddingBottom: "5px",
    },
    dateValue: {
      fontFamily: "Gilroy",
      fontWeight: 500,
      marginRight: "15px",
      color: "#617E8C",
    },
  })
);

type PropsType = {
  date: string;
  text: string;
};

const Comment: React.FC<PropsType> = (props) => {
  const classes = useStyles();

  const date = new Date(props.date).toLocaleDateString("ru-RU", {
    hour: "2-digit",
    minute: "2-digit",
  });

  return (
    <div className={classes.comment}>
      <div className={classes.dateBox}>
        <Typography variant="body2" component="p" className={classes.dateValue}>
          {date}
        </Typography>
      </div>
      <div>
        <Typography className={classes.text} variant="body1" component="p">
          {props.text}
        </Typography>
      </div>
    </div>
  );
};

export default React.memo(Comment);
