import React from "react";
import { compose } from "redux";
import { FieldType } from "../types/bigForm";
import withFormData from "./withFormData";
import withFormDataModal from "./withFormDataModal";
import withShowIfField from "./withShowIfField";
import withShowIfFieldModal from "./withShowIfFieldModal";

type RequiredProps = {
  field: FieldType;
  propsalId: number;
  parentName?: string;
  indexGroupField?: number;
};

type Props<T> = T & RequiredProps;

const withFormHocs = <T extends Object>(Component: React.FC<T>) => {
  return (props: Props<T>) => {
    // if (props.parentName === "GeneralDirector") {
    //   console.log("withFormHocs props.parentName", props.parentName);
    // }
    if (props.parentName || props.indexGroupField) {
      if (props.field?.show_if.length > 0) {
        const WithHoc = compose(
          withShowIfFieldModal,
          withFormDataModal
        )(Component) as React.FC<Props<T>>;
        return <WithHoc {...props} />;
      }
      const WithHoc = withFormDataModal(Component);
      return (
        <WithHoc
          {...props}
          field={props.field}
          parentName={props.parentName}
          propsalId={props.propsalId}
          indexGroupField={props.indexGroupField}
        />
      );
    }
    if (!props.parentName && !props.indexGroupField) {
      if (props.field?.show_if.length > 0) {
        const WithHoc = compose(
          withShowIfField,
          withFormData
        )(Component) as React.FC<Props<T>>;
        return (
          <WithHoc
            {...props}
            field={props.field}
            parentName={props.parentName}
            propsalId={props.propsalId}
            indexGroupField={props.indexGroupField}
          />
        );
      }
      const WithHoc = withFormData(Component);
      return (
        <WithHoc
          {...props}
          field={props.field}
          parentName={props.parentName}
          propsalId={props.propsalId}
          indexGroupField={props.indexGroupField}
        />
      );
    }
    return <Component {...props} />;
  };
};

export default withFormHocs;
