import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import React, { useRef, useState } from "react";
import IconButtonMain from "../../../UIcomponentsRedesign/IconButtonMain/IconButtonMain";
import { TextAreaMessage } from "../../../UIcomponentsRedesign/TextAreaMessage";
import { Clip } from "../../../core/consts/redesign/icons";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    messageInput: {
    },
    container: {
      display: "flex",
      flexDirection: 'row',
      gap: "16px",
      width: "100%",
      alignItems: "flex-end",
      boxSizing: "border-box",
      padding: "8px",
      position: "relative",
    },
    inputArea: {
      resize: "none",
    },

    button: {
      width: "20%",
      height: "51px",
      position: "absolute",
      right: 0,
      top: " 50%",
      transform: "translateY(-50%)",
      boxSizing: "border-box",
      padding: "0 35px",
    },
    fileUpload: {
      "&:hover": {
        cursor: "pointer",
      },
      position: "absolute",
      bottom: "17px",
      right: "35px",
      width: "20px",
      height: "20px",
      display: "inline-block",
      background: "white",
      border: "none",
      padding: "0 10px",
    },
  })
);

type PropsType = {
  handleSelectFile: any;
  handleMessageSend: any;
};

const ChatInput: React.FC<PropsType> = (props) => {
  const classes = useStyles();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [currentMessage, setCurrentMessage] = useState<string>("");
  const [isSendDisabled, setIsSendDisabled] = useState<boolean>(true);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (event.shiftKey) {
        setCurrentMessage(currentMessage + '\n');
      } else {
        if (currentMessage.trim()) {
          props.handleMessageSend(currentMessage.trim());
          setCurrentMessage("");
          setIsSendDisabled(true);
        }
      }
    }
  }

  const handleMessageChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const message = event.target.value;
    setCurrentMessage(message);
    setIsSendDisabled(message === "");
  };

  const handleMessageSend = () => {
    props.handleMessageSend(currentMessage);
    setCurrentMessage("");
  };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  return (
    <div className={classes.container}>
      <IconButtonMain
        title="Загрузить документ"
        color="noBgGray"
        style={{
          width: '24px',
          height: '24px',
          marginLeft: "8px",
          marginBottom: "12px"
        }}
        icon={<Clip />}
        handleClick={handleButtonClick}
      />
      <input
        ref={fileInputRef}
        type="file"
        onChange={props.handleSelectFile}
        id="upload"
        hidden
      />
      <TextAreaMessage
        placeholder="Ваше сообщение..."
        onKeyDown={handleKeyDown}
        onChange={handleMessageChange}
        className={classes.messageInput}
        value={currentMessage}
        handleMessageSend={handleMessageSend}
        isSendDisabled={isSendDisabled}
      />
    </div>
  );
};

export default ChatInput;
