import { memo, useEffect, useState } from "react";
import authApi from "../../../core/api/authApi";
import classes from "../LoginNew.module.scss";

type Props = {
  phone?: string;
  email?: string;
};

const Counter: React.FC<Props> = ({ phone, email }) => {
  const [count, setCount] = useState(30);
  const [codeMessage, setCodeMessage] = useState<string | null>(null);

  useEffect(() => {
    let timerId: NodeJS.Timeout;
    if (count > 0) {
      timerId = setTimeout(() => setCount(count - 1), 1000);
    }
    return () => clearTimeout(timerId);
  }, [count]);

  const handleRefreshCode = () => {
    setCodeMessage("");
    if (!count) {
      setCount(30);
      if (phone) {
        authApi
          .checkPhoneCode(true, phone)
          .then(() => {
            setCodeMessage("Код отправлен повторно");
            setTimeout(() => {
              setCodeMessage(null);
            }, 5000);
          })
          .catch(() => {
            setCodeMessage("Не удалось повторно отправить код");
            setTimeout(() => {
              setCodeMessage(null);
            }, 5000);
          });
      } else if (email) {
        authApi
          .checkPhoneCode(false, undefined, email)
          .then(() => {
            setCodeMessage("Код отправлен повторно");
            setTimeout(() => {
              setCodeMessage(null);
            }, 5000);
          })
          .catch(() => {
            setCodeMessage("Не удалось повторно отправить код");
            setTimeout(() => {
              setCodeMessage(null);
            }, 5000);
          });
      }
    }
  };

  return (
    <>
      <div
        onClick={handleRefreshCode}
        className={
          count !== 0 ? classes.linkFormChange : classes.blue_text
        }
      >
        Отправить код повторно {count !== 0 && `(через ${count} сек)`}
      </div>
      {codeMessage && <div className="error">{codeMessage}</div>}
    </>
  );
};

export default memo(Counter);
