import { Box, Button, Container, InputAdornment, TextField, Theme, Typography, createStyles, makeStyles } from "@material-ui/core";
import React, { memo, useEffect, useState } from "react";
import { useHistory } from 'react-router';
import HeaderNew from "../../app/components/redesign/Header/HeaderNew";
import { ROUTES } from "../../core/consts/common";
import { SearchIcon } from "../../core/consts/images/SearchIcon";
import ClientCounterpartyCard from "./components/client-counterparty-card";

/* Redesign */

const useStyles = makeStyles((theme: Theme) => createStyles({
    container: {
        margin: '0 auto',
        boxSizing: 'border-box',
        padding: '40px 80px',
    },
    search: {
        flexBasis: '100%',
        backgroundColor: '#ffffff',
        borderRadius: '10px',
        padding: '20px',
        '& input': {
            marginTop: '20px',
            marginLeft: '-20px',
            width: '80%',
            height: '100%',
        },
        boxSizing: 'border-box'
    },
    form: {
        display: 'flex',
        width: '100%',
        height: '85px',
        justifyContent: 'space-between',
        alignItems: 'center',
        boxSizing: 'border-box',
        backgroundColor: '#ffffff',
        position: 'relative',
        borderRadius: '10px',
        margin: '20px 0',
        '&>div>div:before': {
            display: 'none',
        },
        '& div::before': {
            display: 'none',
        },
        '& div::after': {
            display: 'none',
        },
        '& input': {
            padding: '0 45px 25px',
            width: '80%',
            height: '100%',
        },
    },
    button: {
        marginRight: '20px',
        flexBasis: '10%',
        height: '51px',
        position: 'absolute',
        right: 0,
        top: ' 50%',
        transform: 'translateY(-50%)',
        boxSizing: 'border-box',
        padding: '0 35px',
    },
    content: {
        padding: '30px',
        height: '100%'
    }
}))

const ClientCounterpartyCardPage: React.FC = (props: any) => {
    const history = useHistory()
    useEffect(() => {
        if (!props.location.state) {
            history.push('/client/counterparty-check')
        }
    }, [props.location.state])
    const classes = useStyles()
    const [searchValue, setSearchValue] = useState<string>(props.location.state?.searchVal || '')
    const isUL = props.location.state?.isUL || false
    const isIP = props.location.state?.isIP || false
    const isActive = props.location.state?.isActive || true
    const kontragentId = props.match.params.id
    const [isDisabled, setIsDisablled] = useState(true);
    const [inputError, setInputError] = useState(false)

    const handleSearchChange = (e: any) => {
        setSearchValue(e.target.value)
        if (searchValue !== '') setIsDisablled(false)
    }

    const handleSubmit = (event: any) => {
        event.preventDefault();
        setInputError(false);
        if (searchValue === '') setInputError(true)
        else {
            history.push(`${ROUTES.CLIENT}/counterparty-check/${searchValue}`,
                {
                    searchVal: searchValue,
                    isUL: isUL,
                    isIP: isIP,
                    isActive: isActive,
                })
        }
    }

    return (
        <div>
            <HeaderNew title={"Проверка контрагента"} showGoBack />
            <Container className={classes.container}>
                <Box className={classes.content} >
                    <form
                        className={classes.form}
                        noValidate
                        onSubmit={handleSubmit}
                    >
                        <TextField
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment style={{ marginLeft: '30px' }} position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            color='primary'
                            type="search"
                            variant="standard" style={{ width: '100%' }}
                            onChange={handleSearchChange}
                            className={classes.search}
                            defaultValue={searchValue}
                            error={inputError}
                            helperText={inputError ? 'Необходимо ввести значение' : ''}
                        />
                        <Button
                            color='primary'
                            variant="text"
                            type="submit"
                            disabled={isDisabled}
                            className={classes.button}>
                            <Typography variant="body1" component='span' style={{ color: 'inherit', textTransform: 'none' }}>
                                Найти
                            </Typography>
                        </Button>
                    </form>
                    <Typography variant="h4" component='h4' style={{ padding: '10px 30px' }}>
                        Общая информация
                    </Typography>
                    <ClientCounterpartyCard
                        id={kontragentId}
                    />
                </Box>
            </Container>
        </div>
    )
}

export default memo(ClientCounterpartyCardPage)