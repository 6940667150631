import { http } from '../infrastructure/Https';
import tokenService from '../services/TokenService';

const ROOT_URL = "/api/v1/";

export enum FrequencyUnitEnum {
  day = "day",
  week = "week",
  no = "no",
}

export enum DaysOfWeekEnum {
  Mo = "Mo",
  Tu = "Tu",
  We = "We",
  Th = "Th",
  Fr = "Fr",
  Sa = "Sa",
  Su = "Su",
}

export type NotificationTimeEnum = "all_day" | "on_time";

export enum NotificationTypeEnum {
  emailPortal = "emailPortal",
  email = "email",
  portal = "portal",
}

export type NoteType = {
  id?: number;
  leadId?: number;
  text: string;
  creationDate?: string;
  isNotificate: boolean;

  notificationStartDate?: string; //дата первого уведомления
  actualNotificatiionDateTime?: string; // дата следующего уведомления. if null - reminder is expired and off

  frequencyUnit?: FrequencyUnitEnum; //тип частоты уведомления(день, неделя, нет)
  frequencyCount?: string | number; //единица частоты уведомления (например, 10 - каждые 10 дней или недель в зависимости от frequencyUnit )

  minutes?: string | number;
  hours?: string | number;
  days?: DaysOfWeekEnum[]; // дни недели (для еженедельного напоминания)
  notificateType?: NotificationTypeEnum;

  isExpired?: boolean;
  isToday?: boolean;
};

export type ResponseNoteType = {
  count: number;
  offset: number;
  onlyUnread: boolean;
  leadId: number;
}

export type CommonNotesInfoType = {
  notes: NoteType[];
  notesCount: number;
  isExpired: boolean;
  isToday: boolean;
}
class NoteApi {
  getNotesList(leadId: number | string): Promise<NoteType[]> {
    const accessToken = tokenService.getAccessToken();
    return http.get(`${ROOT_URL}getleadnotifications/leadid/${leadId}`, undefined, accessToken);
  }

  createNote(
    note: NoteType
  ): Promise<ResponseNoteType | string> {
    const accessToken = tokenService.getAccessToken();
    return http.post(`${ROOT_URL}createleadnotification`, null, note, accessToken);
  }

  // getNoteById(
  //   body?: { noteId?: string; }
  // ): Promise<NoteType> {
  //   const accessToken = tokenService.getAccessToken();
  //   return http.get(`${ROOT_URL}lalala`, body, accessToken);
  // }

  changeNote(note: NoteType): Promise<ResponseNoteType | any> {
    const accessToken = tokenService.getAccessToken();
    return http.put(`${ROOT_URL}updateleadnotification`, null, note, accessToken);
  }

  deleteNote(noteId: number | string): Promise<ResponseNoteType | string> {
    const accessToken = tokenService.getAccessToken();
    return http.delete(`${ROOT_URL}deleteleadnotification/${noteId}`, null, null, accessToken);
  }
}

const noteApi = new NoteApi();
export default noteApi;
