import {
  Box,
  Button,
  Container,
  Divider,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { v4 } from "uuid";
import ChatModalManager from "../../app/components/chat-modal-manager";
import ChatModal from "../../app/components/chat-modal/ChatModal";
import Preloader from "../../app/components/preloader";
import HeaderNew from "../../app/components/redesign/Header/HeaderNew";
import { TypeChat, chatListType } from "../../core/api/chatsApi";
import {
  notificationType,
  notificationsListType,
} from "../../core/api/notificationsApi";
import { ROLES, ROUTES } from "../../core/consts/common";
import { SearchIcon } from "../../core/consts/images/SearchIcon";
import { dateWithTimeConverter } from "../../core/helpers/dateConverter";
import chatService from "../../core/services/ChatsService";
import notificationsService from "../../core/services/NotificationService";
import { actions } from "../../core/store";
import { rootState } from "../../core/types/rootState";
import { useStyles } from "./ClientMessagesPageStyles";
import MessagesList from "./components/messages-list/MessagesList";
import Notification from "./components/notification";

/* Redesign . messaging for all */

const ClientMessagesPage: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const initialUser = useSelector((state: rootState) => state.currentUser);
  const [notificationsList, setNotificationsList] =
    useState<notificationType[]>();
  const [chatsList, setChatsList] = useState<chatListType[]>();
  const [chatsListSearch, setChatsListSearch] = useState<chatListType[]>();
  const [isRead, setIsRead] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [currentChatId, setCurrentChatId] = useState<string>();
  const [openManagerModal, setOpenManagerModal] = useState(false);
  const [openClientModal, setOpenClientModal] = useState(false);
  const [currentChatClientName, setCurrentChatClientName] = useState<string>();
  const [currentChatRole, setCurrentChatRole] = useState<string>();
  const [noNewMessages, setNoNewMessages] = useState<string>("");
  const [noNewNotifications, setNoNewNotifications] = useState<string>("");
  const [isManagerSupport, setIsManagerSupport] = useState<boolean>();
  const [isShowPreloader, setIsShowPreloader] = useState<boolean>(false);

  useEffect(() => {
    let Q = {
      onlyUnread: true,
    };
    //initialUser.role !== ROLES.MANAGER &&
    notificationsService
      .getNotificationsListService(Q)
      .then((res: notificationsListType) => {
        if (res.result.length > 0) {
          setNotificationsList(res.result);
        } else {
          setNoNewNotifications("У вас пока нет новых уведомлений");
        }
        setIsShowPreloader(false);
      })
      .catch((error) => {
        console.log(error);
        setIsShowPreloader(false);
      });
  }, [initialUser.role, initialUser.userId]);

  useEffect(() => {
    setIsShowPreloader(true);
    chatService
      .getChatsListService()
      .then((res: chatListType[]) => {
        res.length > 0
          ? setChatsList(
            res
              .filter((chat: chatListType) => {
                return chat.countUnreadMessage > 0 && chat.lastMessage;
              })
              .concat(
                res.filter((chat: chatListType) => {
                  return chat.countUnreadMessage === 0 && chat.lastMessage;
                })
              )
          )
          : setNoNewMessages("У вас пока нет новых сообщений");
        chatsList?.length === 0 &&
          setNoNewMessages("У вас пока нет новых сообщений");
        setIsShowPreloader(false);
      })
      .catch((error) => {
        setIsShowPreloader(false);
        console.log(error);
      });
  }, [isSearch]);

  const handleReadAll = () => {
    notificationsList?.forEach((notification) => {
      notification.actionId !== "1001" &&
        notificationsService
          .readNotification(notification.id.toString())
          .then((res) => {
            notification.actionId !== "1001" && setIsRead(true);
          })
          .catch((error) => {
            console.log(error);
          });
    });
  };

  const handleChatRouteClick = (chat: chatListType) => {
    if (chat.leadId !== null) {
      actions.setCurrentChatData({
        nameChat: chat.nameChat,
        bankName: chat.bankName,
        avatar_url: chat.avatar_url,
      });
      chat.typeChat === "Bank"
        ? actions.setCurrentChatData({
          isMyMessageBank: chat.lastMessageYou,
          lastMessageBank: chat.lastMessage,
          lastTimeMessageBank: chat.lastTimeMessage,
          countUnreadMessageBank: chat.countUnreadMessage,
        })
        : actions.setCurrentChatData({
          isMyMessage: chat.lastMessageYou,
          lastMessage: chat.lastMessage,
          lastTimeMessage: chat.lastTimeMessage,
          countUnreadMessage: chat.countUnreadMessage,
        });
      if (initialUser.role === ROLES.PARTNER) {
        history.push(`${ROUTES.PARTNER}/application/1/${chat.leadId}`);
      } else {
        history.push(
          `${initialUser.role === ROLES.CLIENT
            ? ROUTES.CLIENT
            : initialUser.role === ROLES.MANAGER
              ? ROUTES.MANAGER
              : initialUser.role === ROLES.AGENT && ROUTES.AGENT
          }/product/1/${chat.productId}/${chat.leadId}` //TODO: fix tab here
        );
      }
    }
    if (chat.leadId === null && chat.typeChat === "Role") {
      if (initialUser.role === ROLES.MANAGER) {
        setCurrentChatId(chat.id.toString());
        setCurrentChatClientName(chat.nameChat);
        setCurrentChatRole(chat.roleInterlocutor);
        setOpenManagerModal(true);
        setIsManagerSupport(true);
      } else
        history.push(
          `${initialUser.role === ROLES.CLIENT
            ? ROUTES.CLIENT
            : initialUser.role === ROLES.PARTNER
              ? ROUTES.PARTNER
              : ROUTES.AGENT
          }/support`
        );
    }
    if (chat.leadId === null && chat.typeChat === TypeChat.ManagerChat) {
      if (initialUser.role === ROLES.MANAGER) {
        setCurrentChatId(chat.id.toString());
        setCurrentChatClientName(chat.nameChat);
        setCurrentChatRole(chat.roleInterlocutor);
        setOpenManagerModal(true);
        setIsManagerSupport(false);
      } else {
        setOpenClientModal(true);
      }
    }
  };

  const handleSearchChange = (e: any): void => {
    setIsSearch(e.target.value !== "");
    setChatsListSearch(
      chatsList?.filter((chat: chatListType) => {
        return (
          (chat.nameChat && chat.nameChat.indexOf(e.target.value) !== -1) ||
          (chat.productName &&
            chat.productName.indexOf(e.target.value) !== -1) ||
          (chat.leadId && chat.leadId.toString().indexOf(e.target.value) !== -1)
        );
      })
    );
  };

  const handleClientChatClose = () => {
    setOpenClientModal(false);
  };

  return (
    <div>
      <HeaderNew title={"Сообщения"} />
      <Container className={classes.container}>
        <Box className={classes.pageWrap}>
          <Paper
            className={classes.paper}
            style={{ marginRight: "30px", width: "70%" }}
          >
            <div className={classes.searchContainer}>
              <TextField
                label="Поиск"
                color="primary"
                type="search"
                variant="standard"
                style={{ width: "calc(100% - 55px)", margin: "30px 25px 10px" }}
                onChange={handleSearchChange}
                className={classes.search}
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      style={{ position: "relative", left: "15px", top: "8px" }}
                      position="start"
                    >
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <Divider />
            </div>
            {isShowPreloader ? (
              <div style={{ height: "calc(100% - 85px)" }}>
                <Preloader preloaderType={"block"} sizePixels={"120px"} />
              </div>
            ) : chatsList && chatsList?.length > 0 ? (
              <MessagesList
                isSearch={isSearch}
                handleChatRouteClick={handleChatRouteClick}
                chatsListSearch={chatsListSearch}
                chatsList={chatsList}
                initialUser={initialUser}
              />
            ) : (
              <Typography
                component="span"
                variant="body1"
                style={{ display: "block", padding: "15px 0 0 30px" }}
              >
                {noNewMessages}
              </Typography>
            )}
          </Paper>
          {/* initialUser.role !== ROLES.MANAGER && */}
          {
            <Paper className={classes.paper} style={{ width: "38%" }}>
              <Box
                style={{
                  backgroundColor: "#ffffff",
                  zIndex: 35,
                  padding: "30px",
                  display: "flex",
                  justifyContent: "space-between",
                  position: "sticky",
                  top: "0",
                }}
              >
                <Typography
                  style={{ padding: "15px 0", boxSizing: "border-box" }}
                  variant="h4"
                  component="h4"
                >
                  Уведомления
                </Typography>
                {notificationsList && notificationsList.length > 2 && (
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={handleReadAll}
                  >
                    <Typography
                      component="span"
                      variant="body1"
                      style={{
                        color: "#306F9A",
                        textTransform: "none",
                        lineHeight: "2",
                      }}
                    >
                      Прочитать все
                    </Typography>
                  </Button>
                )}
              </Box>
              <Box className={classes.notificationsBox}>
                {notificationsList && notificationsList.length > 0 ? (
                  notificationsList.map((notification) => {
                    return (
                      <Notification
                        key={v4()}
                        propsalName={notification.proposal.name}
                        date={dateWithTimeConverter(notification.dateAdd)}
                        bankName={notification.proposal.bank.name}
                        id={notification.id}
                        text={notification.text}
                        isRead={isRead}
                        leadId={notification.leadId}
                        productId={notification.productId}
                        actionId={notification.actionId}
                      />
                    );
                  })
                ) : (
                  <Typography
                    component="p"
                    variant="body1"
                    style={{ display: "block", paddingLeft: "30px" }}
                  >
                    {noNewNotifications}
                  </Typography>
                )}
              </Box>
            </Paper>
          }
        </Box>
      </Container>
      {openManagerModal && (
        <ChatModalManager
          open={openManagerModal}
          close={setOpenManagerModal}
          currentChatId={currentChatId!}
          clientName={currentChatClientName}
          clientRole={currentChatRole}
          isManagerSupport={isManagerSupport}
        />
      )}
      {openClientModal && (
        <ChatModal open={openClientModal} close={handleClientChatClose} />
      )}
    </div>
  );
};

export default memo(ClientMessagesPage);
