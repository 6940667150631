import { CurrentUserTypes, SetCurrentUserPayload } from '../../types/currentUser';

export const setCurrentUser = (payload: SetCurrentUserPayload) => {
  return { type: CurrentUserTypes.SET_USER, payload };
};

export const setCurrentUserCode = (payload: string) => {
  return { type: CurrentUserTypes.SET_CODE, payload };
};

export const setCurrentUserAgentLimit = (payload: boolean) => {
  return { type: CurrentUserTypes.SET_AGENT_LIMIT, payload };
};