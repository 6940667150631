import { chatActionTypes, dataForChats } from "../../types/chatsTypes";

export const setChat = (payload: dataForChats) => {
  return { type: chatActionTypes.ADD_AND_UPDATE_CHAT, payload };
};


export const removeChat = (id: number) => {
  return {
    type: chatActionTypes.REMOVE_CHAT,
    payload: id
  };
};

export const cleanChats = () => {
  return { type: chatActionTypes.CLEAN_USER_CHATS };
};