import React, {Fragment, memo, useEffect} from 'react';
import {PropsWithChildren} from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {rootState} from '../types/rootState';

const ConnectedRouter: React.FC<PropsWithChildren<unknown>> = (props) => {
    const route = useSelector((state: rootState) => state.route.route);
    const history = useHistory();

    useEffect(() => {
        history.push(route);
    }, [route, history])
    
    return <Fragment>{props.children}</Fragment>
}

export default memo(ConnectedRouter);