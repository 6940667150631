import React, { memo, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Grid, Hidden, Typography } from "@material-ui/core";
import { ROUTES } from "../../../core/consts/common";
import { NavigationLinksList } from "./consts";
import CreditsCardIcon from "../../../core/consts/images/CreditsCardIcon";
import FactoringIcon from "../../../core/consts/images/FactoringIcon";
import AwardIcon from "../../../core/consts/images/AwardIcon";
import ClockIcon from "../../../core/consts/images/ClockIcon";
import BillIcon from "../../../core/consts/images/BiilIcon";
import ArrowRight from "../../../core/consts/images/ArrowRight";
import { MainNavigationStyles } from "./MainNavigationStyles";
import clsx from "clsx";
import ConsumerCredit from "../../../core/consts/images/consumerCredit";
import CreditCard from "../../../core/consts/images/cretitCard";
import Mortgage from "../../../core/consts/images/mortgage";
import BigArrow from "./images/BigArrow";
import { PhoneNumber } from "../../../pages/contacts/Contacts";

type propsType = {
  position: boolean;
  mobileProductsClick?: (type: string) => void;
};

const productUrls = [
  {
    name: "Кредитование",
    url: "/credits/",
    icon: <CreditsCardIcon />,
  },
  {
    name: "Банковские гарантии",
    url: "/bankovskiye-garantii/",
    icon: <AwardIcon />,
  },
  {
    name: "Факторинг",
    url: "/factoring/",
    icon: <FactoringIcon />,
  },
  {
    name: "Лизинг",
    url: "/lizing/",
    icon: <ClockIcon />,
  },
  {
    name: "Открытие расчетного счета",
    url: "/otkritie-raschetnogo-scheta/",
    icon: <BillIcon />,
  },
];

const clientsUrl = [
  {
    name: "Потребительский кредит",
    url: "/potrebcredits/",
    icon: <ConsumerCredit />,
  },
  {
    name: "Кредитная карта",
    url: "/creditcard/",
    icon: <CreditCard />,
  },
  {
    name: "Ипотека",
    url: "/ipoteka/",
    icon: <Mortgage />,
  },
];

const MainNavigation: React.FC<propsType> = (props) => {
  const classes = MainNavigationStyles();

  const handleLinkClickScrollUp = () => {
    console.log("@@@@@@@@@@@@@ MainNavigation scroll")
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };
  const [showModal, setShowModal] = useState<null | string | number>(null);

  const handleMouseLeave = (info: number) => {
    setShowModal(info);
  };

  const handleClickProducts = (type: string) => {
    props.mobileProductsClick && props.mobileProductsClick(type);
  };

  const location = useLocation();

  const handleMouseEnter = (hoverElement: string) => {
    setShowModal(hoverElement);
  };

  return (
    <div
      className={`${props.position ? classes.rootHeader : classes.rootFooter}`}
    >
      <Grid
        container
        className={`${props.position ? classes.containerHeader : classes.containerFooter
          }`}
      >
        {props.position ? (
          <>
            <Grid
              onClick={handleLinkClickScrollUp}
              item
              className={classes.linkWrap}
              xs={2}
            >
              <NavLink
                className={`${props.position ? clsx(classes.navlinkHeader, classes.navlinkHeaderFirst) : classes.navlinkFooter
                  }`}
                activeClassName={`${props.position
                    ? classes.navlink_activeHeader
                    : classes.navlink_activeFooter
                  }`}
                to={ROUTES.ABOUT}
              >
                {NavigationLinksList.about}
              </NavLink>
            </Grid>
            <Grid
              onMouseEnter={() => handleMouseEnter("products")}
              onClick={handleLinkClickScrollUp}
              item
              className={classes.linkWrap}
              xs={2}
              onMouseLeave={() => handleMouseLeave(1)}
            >
              <div className={classes.hiddenPhone}>
                <NavLink
                  onClick={() => handleClickProducts("products")}
                  className={`${props.position
                      ? classes.navlinkHeaderProduct
                      : classes.navlinkFooter
                    }`}
                  activeClassName={`${props.position
                      ? classes.navlink_activeHeader
                      : classes.navlink_activeFooter
                    }`}
                  to={ROUTES.PRODUCTS}
                  style={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  {NavigationLinksList.business}
                  {props.position && (
                    <span className={"navigation-arrow"}>
                      <BigArrow
                        className={showModal === 1 ? classes.animationBack : ""}
                      />
                    </span>
                  )}
                </NavLink>
              </div>
              <div
                onClick={() => handleClickProducts("products")}
                className={classes.navlinkHeaderDiv}
                style={!props.position ? { display: "none" } : {}}
              >
                {NavigationLinksList.business}
                <span className={classes.arrow}>
                  {props.position && <ArrowRight />}
                </span>
              </div>
              {props.position && showModal === "products" && (
                <div style={{ zIndex: 100 }} className={classes.menuModal}>
                  <div className={classes.menuModalContainer}>
                    {productUrls.map((el, index) => {
                      return (
                        <NavLink
                          className={classes.linkItem}
                          to={el.url}
                          key={index}
                        >
                          <div>
                            <div
                              className={
                                location.pathname === el.url
                                  ? classes.linkItemActive
                                  : ""
                              }
                            />
                            {el.icon}
                            <Typography
                              component={"div"}
                              className={classes.linkName}
                            >
                              {el.name}
                            </Typography>
                          </div>
                          {/* <ArrowRight /> */}
                        </NavLink>
                      );
                    })}
                  </div>
                </div>
              )}
            </Grid>
            <Grid
              onMouseEnter={() => handleMouseEnter("clients")}
              onClick={handleLinkClickScrollUp}
              item
              className={classes.linkWrap}
              xs={2}
              onMouseLeave={() => handleMouseLeave(2)}
            >
              <div className={classes.hiddenPhone}>
                <NavLink
                  onClick={() => handleClickProducts("private")}
                  className={`${props.position
                      ? classes.navlinkHeaderProduct
                      : classes.navlinkFooter
                    }`}
                  activeClassName={`${props.position
                      ? classes.navlink_activeHeader
                      : classes.navlink_activeFooter
                    }`}
                  to={ROUTES.PRIVATE_CLIENTS}
                  style={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  {NavigationLinksList.clients}
                  {props.position && (
                    <span className={"navigation-arrow"}>
                      <BigArrow
                        className={showModal === 2 ? classes.animationBack : ""}
                      />
                    </span>
                  )}
                </NavLink>
              </div>
              <div
                onClick={() => handleClickProducts("private")}
                className={classes.navlinkHeaderDiv}
                style={!props.position ? { display: "none" } : {}}
              >
                {NavigationLinksList.clients}
                <span className={classes.arrow}>
                  {props.position && <ArrowRight />}
                </span>
              </div>
              {props.position && showModal === "clients" && (
                <div
                  style={{ zIndex: 100 }}
                  className={clsx(classes.menuModal, classes.menuModalClient)}
                >
                  <div className={classes.menuModalContainer}>
                    {clientsUrl.map((el, index) => {
                      return (
                        <NavLink
                          className={classes.linkItem}
                          to={el.url}
                          key={index}
                        >
                          <div>
                            <div
                              className={
                                location.pathname === el.url
                                  ? classes.linkItemActive
                                  : ""
                              }
                            />
                            {el.icon}
                            <Typography
                              component={"div"}
                              className={classes.linkName}
                            >
                              {el.name}
                            </Typography>
                          </div>
                          {/* <ArrowRight /> */}
                        </NavLink>
                      );
                    })}
                  </div>
                </div>
              )}
            </Grid>
            <Grid
              onClick={handleLinkClickScrollUp}
              item
              className={classes.linkWrap}
              xs={2}
            >
              <NavLink
                className={`${props.position ? classes.navlinkHeader : classes.navlinkFooter
                  }`}
                activeClassName={`${props.position
                    ? classes.navlink_activeHeader
                    : classes.navlink_activeFooter
                  }`}
                to={ROUTES.AGENTS}
              >
                {NavigationLinksList.agents}
              </NavLink>
            </Grid>
            <Grid
              onClick={handleLinkClickScrollUp}
              item
              className={classes.linkWrap}
              xs={2}
            >
              <NavLink
                className={`${props.position ? classes.navlinkHeader : classes.navlinkFooter
                  }`}
                activeClassName={`${props.position
                    ? classes.navlink_activeHeader
                    : classes.navlink_activeFooter
                  }`}
                to={ROUTES.CONTACTS}
              >
                {NavigationLinksList.contacts}
              </NavLink>
            </Grid>
          </>
        ) : (
          <>
            <div className={classes.navlinkFooter_wrapper}>
              <div className={classes.navlinkFooter_content}>
                <div className={classes.navlinkFooter_title}>Информация</div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <NavLink
                    className={`${classes.navlinkFooter}`}
                    to={ROUTES.ABOUT}
                  >
                    {NavigationLinksList.about}
                  </NavLink>
                  <NavLink
                    className={`${classes.navlinkFooter}`}
                    to={ROUTES.AGENTS}
                  >
                    {NavigationLinksList.agents}
                  </NavLink>
                  <NavLink
                    className={`${classes.navlinkFooter}`}
                    to={ROUTES.CONTACTS}
                  >
                    {NavigationLinksList.contacts}
                  </NavLink>
                </div>
              </div>
              <div className={classes.navlinkFooter_container}>
                <div className={classes.navlinkFooter_products}>
                  <div className={classes.navlinkFooter_title}>Продукты</div>
                  <NavLink
                    className={`${classes.navlinkFooter}`}
                    to={ROUTES.PRODUCTS}
                  >
                    Бизнесу и ИП
                  </NavLink>
                  <NavLink
                    className={`${classes.navlinkFooter}`}
                    to={ROUTES.PRIVATE_CLIENTS}
                  >
                    Частным клиентам
                  </NavLink>
                </div>
              </div>
            </div>
          </>
        )}
      </Grid>
      <Hidden only={["md", "sm", "xs"]}>
        <Grid item className={clsx(classes.linkWrap, classes.phone)} xs={2}>
          <div
            className={`${props.position ? classes.navlinkHeader : classes.displayNone
              }`}
          >
            <Typography
              color="primary"
              style={{
                fontSize: "16px",
                fontWeight: 600,
                textDecoration: "none",
                marginRight: "10px",
              }}
              variant="h3"
              component="a"
              href="tel:88005003593"
            >
              {PhoneNumber}
            </Typography>
          </div>
        </Grid>
      </Hidden>
    </div>
  );
};

export default memo(MainNavigation);
