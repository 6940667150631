import React, { memo } from "react";
import { makeStyles, createStyles, Theme, Grid, Typography } from "@material-ui/core";
import { konteragentsListType } from '../../../../core/api/konteragentApi'

const useStyles = makeStyles((theme: Theme) => createStyles({
    grid: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    gridItem: {
        display: 'flex',
        flexDirection: 'column',
    }
}))

//(str:string|((prev:string)=>string)) => void
const CounterpartyShortCard: React.FC<konteragentsListType> = (props) => {
    const classes = useStyles()

    return (
        <Grid className={classes.grid}>
            <Grid item className={classes.gridItem} xl={2} lg={2} md={2}>
                <Typography variant="body1" component='span'>
                    {props.companyName}
                </Typography>
            </Grid>
            <Grid item className={classes.gridItem} xl={2} lg={2} md={2}>
                <Typography variant="body2" component='span' style={{ color: '#617E8C' }}>
                    ИНН
                </Typography>
                <Typography variant="body1" component='span'>
                    {props.inn}
                </Typography>
            </Grid>
            <Grid item className={classes.gridItem} xl={2} lg={2} md={2}>
                <Typography variant="body2" component='span' style={{ color: '#617E8C' }}>
                    Дата регистрации
                </Typography>
                <Typography variant="body1" component='span'>
                    {props.registerDate}
                </Typography>
            </Grid>
            <Grid item className={classes.gridItem} xl={4} lg={4} md={4}>
                <Typography variant="body2" component='span' style={{ color: '#617E8C' }}>
                    Адрес
                </Typography>
                <Typography variant="body1" component='span'>
                    {props.registerAddress}
                </Typography>
            </Grid>
            <Grid item className={classes.gridItem} xl={2} lg={2} md={2}
                style={{ textAlign: 'right' }}>
                <Typography variant="h3" component='p' >
                    <Typography variant="body1" component='span' style={{ color: '#306F9A' }}>
                        Проверить&nbsp;&nbsp;</Typography>
                        <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 13L7 7L0.999999 1" stroke="#131313" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </Typography>
            </Grid>
        </Grid>
    )
}

export default memo(CounterpartyShortCard)