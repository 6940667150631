import {
  Button,
  createStyles,
  Dialog,
  IconButton,
  makeStyles,
  MenuItem,
  Select,
  Theme,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import leadsService from "../../../core/services/LeadsService";
import partnerService from "../../../core/services/PartnerService";

type propsType = {
  isOpen: boolean;
  setChangeStatusModal: (isOpen: boolean) => void;
  setRequstStatus: (isStatus: boolean) => void;
  requstStatus: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: "relative",
      backgroundColor: "white",
      width: "780px",
      height: "342px",
      padding: "50px 75px",
    },
    title: {
      color: "#131313",
      fontFamily: "Gilroy",
      fontWeight: 600,
      fontSize: "32px",
    },
    subTitle: {
      marginTop: "25px",
      fontSize: "16px",
      fontFamily: "Gilroy",
      fontWeight: 500,
      color: "#131313",
    },
    selector: {
      marginTop: "30px",
      width: "300px",
    },
    changeStatusBtn: {
      background: "#306F9A",
      borderRadius: "4px",
      padding: "18px 36px",
      marginTop: "25px",
      width: "300px",
    },
    text: {
      textTransform: "none",
      color: "white",
      fontSize: "14px",
      fontFamily: "Gilroy",
      fontWeight: 500,
    },
    box: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "baseline",
      width: "100%",
    },
    paper: {
      background: "linear-gradient(114.44deg, #942BD5 0%, #B239CC 100%)",
      border: "none",
      padding: "50px 80px 50px 75px",
      width: "780px",
    },
  })
);

const ChangeStatusModal: React.FC<propsType> = ({
  isOpen,
  setChangeStatusModal,
  requstStatus,
  setRequstStatus,
}) => {
  const classes = useStyles();
  const [status, setStatus] = useState("Запрос документа");
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setStatus(event.target.value as string);
  };
  const handleClose = () => {
    setChangeStatusModal(false);
  };

  useEffect(() => {
    if (requstStatus === true) {
      setChangeStatusModal(false);
    }
  }, [requstStatus]);

  const location = useLocation();
  const [statuses, setStatuses] = useState<null | any>(null);
  useEffect(() => {
    leadsService.getStatuses().then((data) => {
      setStatuses(data);
    })
  }, [])

  const applicationId = location.pathname.split("/").pop();

  const hanleClickChangeStatus = () => {
    if (status) {
      partnerService
        .putLeadStatus(+applicationId!, status)
        .then((res: any) => {
          setRequstStatus(true);
        })
        .catch((error: any) => {
          setRequstStatus(false);
        });
    }
  };
  return (
    <Dialog onClose={handleClose} maxWidth={false} open={isOpen}>
      <div className={classes.container}>
        <div className={classes.title}>Изменение статуса</div>
        <IconButton
          style={{ position: "absolute", top: "33px", right: "50px" }}
          aria-label="close"
          onClick={handleClose}
        >
          <CloseIcon style={{ color: "#D0D9DE" }} />
        </IconButton>
        <div className={classes.subTitle}>
          Клиент увидит измененный статус в своем личном кабинете
        </div>
        <Select
          onChange={handleChange}
          value={status}
          className={classes.selector}
        >
          {statuses && statuses.map((el: any) => {
            return (
              <MenuItem key={el.key} value={el.key}>
                {el.text}
              </MenuItem>
            )
          })}
        </Select>
        <div>
          <Button
            color={"primary"}
            className={classes.changeStatusBtn}
            variant="contained"
            onClick={hanleClickChangeStatus}
          >
            <Typography
              variant={"subtitle2"}
              className={classes.text}
              component={"span"}
              style={{ fontSize: "16px" }}
            >
              Изменить
            </Typography>
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default ChangeStatusModal;
