import newsApi from "../api/newsApi";
import { newsType } from "../types/newsTypes";

class NewsService {

    getCategoryListService() {
        return newsApi.getCategoryList()
            .then((res: any) => {
                return res
            })
            .catch((error) => {
                console.log(error);
            })
    }

    addCategoryService(B: { name: string, parent: number }) {
        return newsApi.addCategory(B)
            .then((res: any) => {
                return res
            })
            .catch((error) => {
                console.log(error);
            })
    }

    getNewsListService(Q: { category?: number, count?: number, offset?: number, Sort?: string, Statuses?: string }) {
        return newsApi.getNewsList(Q)
            .then((res: any) => {
                return res
            })
            .catch((error) => {
                console.log(error);
            })
    }
    postNewsService(data: any) {
        return newsApi.postNews(data)
            .then((res: any) => {
                return res
            })
            .catch((error) => {
                console.log(error);
            })
    }
    putNews(data: any, id: string) {
        return newsApi.putNews(data, id)
            .then((res: any) => {
                return res
            })
            .catch((error) => {
                console.log(error);
            })
    }
    putPartDataService(id: number, isActive: boolean) {
        return newsApi.putPartData(id, isActive)
            .then((res: any) => {
                return res
            })
            .catch((error) => {
                console.log(error);
            })
    }
    getNewsImageService(id: string) {
        return newsApi.getNewsImage(id)
            .then((res: any) => {
                return res
            })
            .catch((error) => {
                console.log(error);
            })
    }
    addNewsService(B: newsType) {
        return newsApi.addNews(B)
            .then((res: any) => {
                return res
            })
            .catch((error) => {
                console.log(error);
            })
    }

    getNewsByIdService(id: number) {
        return newsApi.getNewsById(id)
            .then((res: any) => {
                return res
            })
            .catch((error) => {
                console.log(error);
            })
    }
    getNewsForAdminByIdService(id: number) {
        return newsApi.getNewsForAdminById(id)
            .then((res: any) => {
                return res
            })
            .catch((error) => {
                console.log(error);
            })
    }

    getNewsOtherService(Q: { NewsId?: number, category?: number, count?: number, offset?: number }) {
        return newsApi.getNewsOther(Q)
            .then((res: any) => {
                return res
            })
            .catch((error) => {
                console.log(error);
            })
    }

    getTagsListService() {
        return newsApi.getTagsList()
            .then((res: any) => {
                return res
            })
            .catch((error) => {
                console.log(error);
            })
    }

    addTagService(B: { name: string }) {
        return newsApi.addTag(B)
            .then((res: any) => {
                return res
            })
            .catch((error) => {
                console.log(error);
            })
    }
}

const newsService = new NewsService()
export default newsService