export function detectDevice(): string {
  const userAgentRes = navigator.userAgent;
  let result = "desk";
  // if (/IEMobile|Windows Phone|Lumia/i.test(userAgentRes)) {
  //   result = "Windows Phone";
  // } else if (/iPhone|iP[oa]d/.test(userAgentRes)) {
  //   result = "iOS";
  // } else if (/Android/.test(userAgentRes)) {
  //   result = "Android";
  // } else if (/BlackBerry|PlayBook|BB10/.test(userAgentRes)) {
  //   result = "BlackBerry";
  // } else if (/Mobile Safari/.test(userAgentRes)) {
  //   result = "Mobile Safari";
  // } else if (
  //   /webOS|Mobile|Tablet|Opera Mini|\bCrMo\/|Opera Mobi/i.test(userAgentRes)
  // ) {
  //   result = "mob";
  // }
  return result;
}
