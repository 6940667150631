import { Theme, createStyles, makeStyles } from "@material-ui/core";
import { KeyboardEvent, memo, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { FormType } from "../../../app/enum";
import { ROLES, ROUTES } from "../../../core/consts/common";
import authService from "../../../core/services/AuthService";
import { useAppSelector } from "../../../core/store";
import { rootState } from "../../../core/types/rootState";
import styles from "../LoginNew.module.scss";
import Counter from "./Counter";

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      codeContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "start",
        maxWidth: "240px",
      },
      inputCode: {
        borderRadius: "0",
        textAlign: "center",
        padding: "0",
        fontSize: "30px",
        marginRight: "8px",
        width: "40px",
        height: "51px",
        border: "none",
        outline: "none",
        color: "#131313",
        "-webkit-text-fill-color": "#131313",
        transform: "translateZ(0)",
        "-webkit-transform": "translateZ(0px)",
        boxShadow: "0px -1px 0px 0px rgba(0, 0, 0, 0.42) inset",
        "-webkit-box-shadow": "0px -1px 0px 0px rgba(0, 0, 0, 0.42) inset",
        "-moz-box-shadow": "0px -1px 0px 0px rgba(0, 0, 0, 0.42) inset",
        transition: "box-shadow 0.2s linear",
        "&:hover": {
          boxShadow: "0px -2px 0px 0px #214D6B inset",
        },
        "&:focus": {
          boxShadow: "0px -2px 0px 0px #306f9a inset",
        },
        "-moz-appearance": "none",
        "&::-webkit-outer-spin-button": {
          "-webkit-appearance": "none",
          margin: 0,
        },
        "-webkit-appearance": "none",
        "&::-webkit-inner-spin-button": {
          "-webkit-appearance": "none",
          margin: 0,
        },
        "&:before": {
          "-webkit-user-select": "initial",
          "-khtml-user-select": "initial",
          "-moz-user-select": "initial",
          "-ms-user-select": "initial",
          "user-select": "initial",
        },
        "&:after": {
          "-webkit-user-select": "initial",
          "-khtml-user-select": "initial",
          "-moz-user-select": "initial",
          "-ms-user-select": "initial",
          "user-select": "initial",
        },
      },
    }),
  { index: 1 }
);

type Props = {
  phone: string;
};

const CODE_NUMBER = 5;
const CODE_LENGTH = new Array(CODE_NUMBER).fill(0);

const updateArray = (arr: Array<string>, i: number, val: string) => {
  const copy = [...arr];
  copy[i] = val;
  return copy;
};

const detectDevice = (): string => {
  const userAgentRes = navigator.userAgent;
  let result = "desk";
  // if (/IEMobile|Windows Phone|Lumia/i.test(userAgentRes)) {
  //   result = "Windows Phone";
  // } else if (/iPhone|iP[oa]d/.test(userAgentRes)) {
  //   result = "iOS";
  // } else if (/Android/.test(userAgentRes)) {
  //   result = "Android";
  // } else if (/BlackBerry|PlayBook|BB10/.test(userAgentRes)) {
  //   result = "BlackBerry";
  // } else if (/Mobile Safari/.test(userAgentRes)) {
  //   result = "Mobile Safari";
  // } else if (
  //   /webOS|Mobile|Tablet|Opera Mini|\bCrMo\/|Opera Mobi/i.test(userAgentRes)
  // ) {
  //   result = "mob";
  // }
  return result;
};

const PhoneFormWithCode: React.FC<Props> = ({ phone }) => {
  const inputRefs = useRef(new Array(CODE_NUMBER));
  const classes = useStyles();
  const { cart } = useAppSelector((store) => store);
  const [code, setCode] = useState<string[]>(["", "", "", "", ""]);
  const [errMessage, setErrMessage] = useState<string>("");
  const userId = useSelector((s: rootState) => s.currentUser.userId);
  const history = useHistory();

  const handleChoiceRoutes = (role: string) => {
    if (detectDevice() === "desk") {
      switch (role) {
        case ROLES.CLIENT:
          if (cart && cart.applications.length > 0) {
            history.push(`${ROUTES.CLIENT}/form-new-order`);
            return;
          }
          history.push(`${ROUTES.CLIENT}/products`);
          break;
        case ROLES.AGENT:
          if (cart && cart.applications.length > 0) {
            history.push(`${ROUTES.AGENT}/form-new-order`);
            return;
          }
          history.push(`${ROUTES.AGENT}/products`);
          break;
        case ROLES.PARTNER:
          history.push(`${ROUTES.PARTNER}/profile`);
          break;
        case ROLES.MANAGER:
          history.push(`${ROUTES.MANAGER}/profile`);
          break;
        case ROLES.ADMIN:
          history.push(`${ROUTES.ADMIN}/profile`);
          break;
        default: {
          history.push({
            pathname: history.location.pathname,
            search: `?formType=${FormType.LoginClientOrAgent}`,
          });
        }
      }
    } else if (role === "NotInn") {
      history.push({
        pathname: history.location.pathname,
        search: `?formType=${FormType.LoginClientOrAgent}`,
      });
    } else {
      history.push({
        pathname: history.location.pathname,
        search: `?formType=${FormType.LoginBlockMob}`,
      });
    }
  };

  const handleSubmit = () => {
    const validCode = code.join("").trim();
    if (validCode.length !== CODE_NUMBER) {
      setErrMessage("Введен неверный код");
    } else {
      authService
        .confirmCode(true, userId, validCode)
        .then((role) => {
          handleChoiceRoutes(role);
          window.localStorage.setItem("isRegistration", "true");
        })
        .catch(() => {
          setErrMessage("Введен неверный код");
          setCode(["", "", "", "", ""]);
          inputRefs?.current?.[0]?.focus();
        });
    }
  };

  useEffect(() => {
    inputRefs?.current?.[0]?.focus();
  }, []);

  useEffect(() => {
    const validCode = code.join("").trim();
    if (validCode.length === 5) {
      handleSubmit();
    }
  }, [code]);

  const onChangeHandler = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const oldCode = code[index];
    const newCode = e.target.value.trim().replace(oldCode, "");
    const inputs = inputRefs?.current;
    if (newCode < "0" || newCode > "9") {
      return;
    }
    if (index < inputs.length - 1) {
      inputs[index + 1].focus();
    }
    if (newCode.length > 1) {
      let indexCode = index;
      let result = code;
      for (let i = 0; i < newCode.length; i++) {
        if (indexCode < 5) {
          result = updateArray(result, indexCode, newCode[i]);
          indexCode++;
        }
      }
      setCode(result);
      if (inputs[indexCode]) {
        inputs[indexCode].focus();
      } else {
        inputs[code.length - 1]?.focus();
      }
      return;
    }
    if (code[index] !== "") {
      setCode(updateArray(code, index + 1, newCode));
    } else {
      setCode(updateArray(code, index, newCode));
    }
  };

  const handleKeyDown = (event: KeyboardEvent, index: number) => {
    if (event.code === "ArrowLeft" && index > 0) {
      inputRefs?.current?.[index - 1]?.focus();
      return;
    } else if (event.code === "ArrowRight" && index < 4) {
      inputRefs?.current?.[index + 1]?.focus();
      return;
    } else if (event.code !== "Backspace") {
      return;
    }
    if (code[index] && index > 0) {
      setCode(updateArray(code, index, ""));
      inputRefs?.current?.[index - 1]?.focus();
    } else if (code[index] && index === 0) {
      setCode(updateArray(code, index, ""));
    } else if (index > 0) {
      inputRefs?.current?.[index - 1]?.focus();
    }
  };

  return (
    <form
      className={styles.main_column}
      onSubmit={handleSubmit}
      noValidate
      autoComplete="off"
    >
      <div className={styles.confirm_desc}>
        Мы отправили код на указанный номер телефона. Пожалуйста, введите его в
        поле ниже.
      </div>

      <div>
        <div className={styles.gray_text}>Введите код из СМС</div>
        <div className={classes.codeContainer}>
          {CODE_LENGTH.map((v, index) => (
            <input
              key={index}
              ref={(ref) => (inputRefs.current[index] = ref)}
              className={classes.inputCode}
              onChange={(e) => onChangeHandler(e, index)}
              value={code[index]}
              onKeyDown={(e) => handleKeyDown(e, index)}
              inputMode="numeric"
            />
          ))}
        </div>
        {errMessage && <div className="error">{errMessage}</div>}
      </div>

      <Counter phone={phone} />
    </form>
  );
};

export default memo(PhoneFormWithCode);
