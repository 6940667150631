import React, { memo, useRef, useLayoutEffect } from "react";
import { Typography, Box } from "@material-ui/core";
import { messageType } from "../../../../../core/api/chatsApi";
import { DoubleApproveGreen } from "../../../../../core/consts/images/DoubleApproveGreen";
import { DoubleApproveGrey } from "../../../../../core/consts/images/DoubleApproveGrey";
import clsx from "clsx";
import { DocIcon } from "../../../../../core/consts/images/DocIcon";
import { useStyles } from "../../ChatWindowStyles";

type propsType = {
  chatType?: string;
  chatHelloMessage?: string;
  messagesList: messageType[];
  downloadDoc: (id: string, name: string) => void;
};

const ChatMessages: React.FC<propsType> = (props) => {
  const classes = useStyles();
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const today = new Date();

  const scrollToBottom = () => {
    console.log("@@@@@@@@@@@@@ ChatMessages scroll")
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useLayoutEffect(scrollToBottom, [props.messagesList.length]);

  const dateConverter = (date: string): string => {
    let tmpDate = new Date(date);
    let utcDate = new Date(
      Date.UTC(
        tmpDate.getFullYear(),
        tmpDate.getMonth(),
        tmpDate.getDate(),
        tmpDate.getHours(),
        tmpDate.getMinutes(),
        tmpDate.getSeconds()
      )
    );
    return utcDate.toLocaleDateString("ru-RU", {
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  return (
    <div
      className={classes.messagesBox}
      style={{
        padding: `${props.chatType === "support" && "0px 30px"}`,
        height: `${
          props.chatType === "support"
            ? "calc(100vh - 356px)"
            : "calc(100vh - 480px)"
        }`,
      }}
    >
      {props.chatHelloMessage && props.messagesList.length === 0 && (
        <Box
          className={clsx(classes.messageBox, classes.fromMessageBox)}
          style={{ display: "flex", flexDirection: "column" }}
        >
          <Typography
            variant="body2"
            component="p"
            style={{ fontSize: "12px", color: "#B0BFC6" }}
          >
            Сегодня
          </Typography>
          <Typography
            variant="body1"
            component="p"
            className={clsx(classes.messageText, classes.fromMessageText)}
          >
            {props.chatHelloMessage}
            <Typography variant="body2" component="span">
              {today.toLocaleDateString("ru-RU", {
                hour: "2-digit",
                minute: "2-digit",
              })}
            </Typography>
          </Typography>
        </Box>
      )}
      {props.messagesList.map((message: messageType) => {
        let isDoc = message.documentId !== "0";
        return (
          <Box
            onClick={() =>
              isDoc && props.downloadDoc(message.documentId, message.message)
            }
            className={clsx(classes.messageBox, {
              [classes.myMessageBox]: message.isMyMessage,
              [classes.docBox]: isDoc,
              [classes.fromMessageBox]: !message.isMyMessage,
            })}
            key={message.id}
          >
            {message.isMyMessage &&
              (message.isRead ? <DoubleApproveGreen /> : <DoubleApproveGrey />)}
            <Typography
              variant="body2"
              component="p"
              className={clsx(classes.messageText, {
                [classes.myMessageText]: message.isMyMessage,
                [classes.fromMessageText]: !message.isMyMessage,
              })}
            >
              {isDoc && <DocIcon />} {message.message}
              <Typography
                variant="body2"
                component="span"
                style={{ color: "#B0BFC6",wordWrap: "break-word", }}
              >
                {dateConverter(message.dateMessage)}
              </Typography>
            </Typography>
          </Box>
        );
      })}
      <div ref={messagesEndRef} />
    </div>
  );
};

export default memo(ChatMessages);
