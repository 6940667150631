import chatsApi, { ChatByType, TypeChat, chatListType } from "../api/chatsApi"


class ChatService {
    getChatsListService(Q?: { excludetype: TypeChat }): Promise<chatListType[]> {
        return chatsApi.getChatsList(Q)
    }

    getMessagesListByIdService(id: string): Promise<ChatByType> {
        return chatsApi.getMessagesListById(id)
    }

    getMessagesListByLeadIdService(leadid: number): Promise<ChatByType> {
        return chatsApi.getMessagesListByLeadId(leadid)
    }

    getMessagesListManagerChatByLeadIdService(leadid?: number, userid?: string): Promise<ChatByType> {
        let Q = leadid ? {
            leadid: leadid
        } : userid ? {
            userid: userid
        } : null
        return chatsApi.getMessagesListManagerChatByLeadId(Q)
    }

    getMessagesListByChatTypeService(type: string): Promise<ChatByType> {
        return chatsApi.getMessagesListByChatType(type)
    }

    getDocFromChatService(id: string): Promise<any> {
        return chatsApi.getDocFromChat(id)
    }

    getChatsCountService() {
        return chatsApi.getChatsCount()
    }

    addDocToChatService(chatid: string, document: any): Promise<any> {
        return chatsApi.addDocToChat(chatid, document)
    }
    uploadDocToChatService(
        chatid: string,
        document: any,
        onProgress: (percentage: number, loadedMB: string, totalMB: string) => void,
        signal: AbortSignal //to stop uploading
    ): Promise<any> {
        return chatsApi.uploadDocToChat(chatid, document, onProgress, signal)
    }
    getLeadMessagesCountService(leadid: string): Promise<{ count: number }> {
        return chatsApi.getLeadMessagesCount(leadid)
    }

    addMessageToChatService(B: { chatId: number, text: string }): Promise<{ messageId: number }> {
        return chatsApi.addMessageToChat(B)
    }

    readIncomeMessagesByChatIdService(B: { chatId: number }): Promise<{ countRead: number }> {
        return chatsApi.readIncomeMessagesByChatId(B)
    }
}

const chatService = new ChatService()
export default chatService