import { ChatActions, ChatsState, chatActionTypes } from "../../types/chatsTypes";

const initialState: ChatsState = [];
export const chatsReducer = (state = initialState, action: ChatActions): ChatsState => {
  switch (action.type) {
    case chatActionTypes.ADD_AND_UPDATE_CHAT:
      const existingChatIndex = state.findIndex(chat => chat.chatId === action.payload.chatId);
      if (existingChatIndex !== -1) {
        return state.map((chat, index) =>
          index === existingChatIndex ? { ...chat, ...action.payload } : chat
        );
      } else {
        return [...state, action.payload];
      }

    case chatActionTypes.REMOVE_CHAT:
      return state.filter(chat => chat.chatId !== action.payload);

    case chatActionTypes.CLEAN_USER_CHATS:
      return [];

    default:
      return state;
  }
};
