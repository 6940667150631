import { Theme, createStyles, makeStyles } from "@material-ui/core";
import { useEffect, useState } from "react";
import { COLORS } from "../../core/consts/redesign/commonRedesign";
import { ArrowDown, ArrowUp } from "../../core/consts/redesign/icons";
import IconButtonMain from "../IconButtonMain/IconButtonMain";

/* Redesign. custom accordion */

const useStyles = makeStyles((theme: Theme) => createStyles({
  container: {
    backgroundColor: COLORS.white,
    display: "flex",
    flexDirection: "column",
    width: "100%",
    padding: '24px',
    gap: '24px',
    alignItems: 'flex-start',
    justifyContent: 'center',
    borderRadius: '8px',
  },
  titleRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    cursor: 'pointer'
  },
  content: {
    width: '100%',
    padding: "0"
  },
}));

type CustomAccordionProps = {
  id: string;
  style?: React.CSSProperties;
  titleChildren: React.ReactNode;
  contentChildren: React.ReactNode;
  bottomChildren?: React.ReactNode;
  titleDivider?: boolean;
  open?: boolean;
  setShowMainStatus?: React.Dispatch<React.SetStateAction<boolean>>;
}

const CustomAccordion: React.FC<CustomAccordionProps> = ({
  id,
  style,
  titleChildren,
  contentChildren,
  bottomChildren,
  titleDivider,
  open,
  setShowMainStatus
}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState<boolean>(false);

  useEffect(() => {
    if (expanded) {
      setShowMainStatus && setShowMainStatus(false)
    } else (
      setShowMainStatus && setShowMainStatus(true)
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expanded])

  useEffect(() => {
    if (open) {
      setExpanded(true)
    }
  }, [open])

  return (
    <div className={classes.container} id={id} style={style}>
      <div className={classes.titleRow} onClick={() => setExpanded(!expanded)}>
        {titleChildren}
        <IconButtonMain
          color="noBgGray"
          handleClick={() => setExpanded(!expanded)}
          style={{ width: '24px', height: '24px' }}
          icon={!expanded ? <ArrowDown /> : <ArrowUp />} />
      </div>
      {titleDivider && expanded && <div className="line_x" />}
      {expanded &&
        <div className={classes.content}>{contentChildren}</div>}
      {bottomChildren}
    </div>
  );
};
export default (CustomAccordion)