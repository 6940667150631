import { CartActionTypes } from "../../types/cart";
import { ResponseItemDataType } from "../../types/proposalTypes";

export const pushToCart = (payload: ResponseItemDataType) => {
  return { type: CartActionTypes.ADD_TO_CART, payload };
};

export const pushToCartArray = (payload: ResponseItemDataType[]) => {
  return { type: CartActionTypes.ADD_TO_CART_ARRAY, payload };
};

export const deleteFromCart = (payload: ResponseItemDataType) => {
  return { type: CartActionTypes.DELETE_FROM_CART, payload };
};

export const clearCart = () => {
  return { type: CartActionTypes.CLEAR_CART };
};

export const changeIsOpenCart = (payload: boolean) => {
  return { type: CartActionTypes.CHANGE_IS_OPEN_CART, payload };
};
