import { isToday } from 'date-fns';

const ensureString = (date: any): string => {
  return typeof date === 'string' ? date : String(date);
};

const converter = (date: string): Date => {
  const dateString = ensureString(date);
  return new Date(dateString.endsWith('Z') ? dateString : `${dateString}Z`);
  // return new Date(dateString)
};

export const dateWithTimeConverter = (date: string): string => {
  const utcDate = converter(date);
  return utcDate.toLocaleDateString("ru-RU", {
    hour: "2-digit",
    minute: "2-digit",
  });
};

export const dateConverter = (date: string): string => {
  const utcDate = converter(date);
  return utcDate.toLocaleDateString("ru-RU");
};

export const dateConverterLong = (date: string): string => {
  if (!date) return "";
  const monthNames = [
    "января", "февраля", "марта", "апреля", "мая", "Июня",
    "июля", "августа", "сентября", "октября", "ноября", "декабря"
  ];
  const utcDate = converter(date);
  return (`${utcDate.getDate()} ${monthNames[utcDate.getMonth()]} ${utcDate.getFullYear()} в 
  ${utcDate?.getUTCHours()?.toString()?.length === 1 ? "0" : ""}${utcDate.getUTCHours()}:${utcDate?.getMinutes()?.toString().length === 1 ? "0" : ""}${utcDate.getMinutes()}`);
};

export const dateConverterLongNoTime = (date: string): string => {
  if (!date) return "";
  const monthNames = [
    "января", "февраля", "марта", "апреля", "мая", "Июня",
    "июля", "августа", "сентября", "октября", "ноября", "декабря"
  ];
  const utcDate = converter(date);
  return (`${utcDate.getDate()} ${monthNames[utcDate.getMonth()]} ${utcDate.getFullYear()}`);
};

export const timeConverter = (date: string): string => {
  const utcDate = converter(date);
  const hours = utcDate.getHours().toString().padStart(2, '0');
  const minutes = utcDate.getMinutes().toString().padStart(2, '0');
  return (`${hours}:${minutes}`);
};

export const checkIsToday = (date: string): boolean => {
  const utcDate = converter(date);
  return isToday(utcDate);
};

// dd month yyyy - hh:mm
export const formatDateTime = (date: string) => {
  const tmpDate = converter(date);
  const formatTwoDigits = (num: number) => num.toString().padStart(2, '0');

  const day = formatTwoDigits(tmpDate.getDate());
  const monthIndex = tmpDate.getMonth();
  const russianMonths = [
    "января", "февраля", "марта", "апреля", "мая", "июня",
    "июля", "августа", "сентября", "октября", "ноября", "декабря"
  ];
  const month = russianMonths[monthIndex];
  const year = tmpDate.getFullYear();
  const hours = formatTwoDigits(tmpDate.getHours());
  const minutes = formatTwoDigits(tmpDate.getMinutes());

  return `${day} ${month} ${year} - ${hours}:${minutes}`;
};
