import {
  Container,
  Typography
} from "@material-ui/core";
import React, { memo, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { v4 } from "uuid";
import ButtonCustom from "../../UIcomponentsRedesign/ButtonCustom/ButtonCustom";
import CustomAccordion from "../../UIcomponentsRedesign/CustomAccordion/CustomAccordion";
import IconButtonMain from "../../UIcomponentsRedesign/IconButtonMain/IconButtonMain";
import SpinnerMain from "../../UIcomponentsRedesign/SpinnerMain";
import StatusCloud from "../../UIcomponentsRedesign/StatusCloud/StatusCloud";
import ConfirmModalRedesign from "../../app/components/confirm-modal-redesign/ConfirmModalRedesign";
import ErrorModal from "../../app/components/error-modal/ErrorModal";
import HeaderNew from "../../app/components/redesign/Header/HeaderNew";
import BottomMenu from "../../app/components/redesign/Header/components/BottomMenu";
import { CommonNotesInfoType, FrequencyUnitEnum } from '../../core/api/noteApi';
import { COLORS, ROLES, ROUTES } from "../../core/consts/redesign/commonRedesign";
import { Dots, Info } from "../../core/consts/redesign/icons";
import { dateConverter } from "../../core/helpers/dateConverter";
import useIsMobile from "../../core/hooks/useIsMobile";
import leadsService from "../../core/services/LeadsService";
import noteService from "../../core/services/NoteService";
import notificationsService from "../../core/services/NotificationService";
import { actions } from "../../core/store";
import { editDocsCount } from "../../core/store/action-creators/countsData";
import {
  DocsStatusEnum,
  LeadDocPackType,
  fieldsProposalItemsType,
  groupFieldsProposalItemsType,
  leadFullInfo,
  leadStatusHistoryType,
} from "../../core/types/leadsApiTypes";
import { clientStatuses } from "../../core/types/managerApiTypes";
import { rootState } from "../../core/types/rootState";
import ChangeStatusModal from "../partner-current-application/components/ChangeStatusModal";
import { useStyles } from "./ClientProductDetailsStyles";
import ChatBlock from "./components/ChatBlock/ChatBlock";
import Documents from "./components/Documents/Documents";
import InfoBlock from "./components/InfoBlock/InfoBlock";
import NotesBlock from "./components/NotesBlock/NotesBlock";
import Notifications from "./components/Notifications/Notifications";
import StatusBlock from "./components/StatusBlock/StatusBlock";
import TabPanel from "./components/TabsPanel/TabPanel";
import TabsPanel from "./components/TabsPanel/TabsPanel";

/* Redesign. Client lead page */

type PropsType = {
  match: {
    params: {
      tab: string;
      leadId: string;
      productId: string;
    };
  };
};

const ClientProductDetails: React.FC<PropsType> = (props) => {
  const classes = useStyles();
  const leadId = props.match.params.leadId;
  const history = useHistory();
  const initialUser = useSelector((state: rootState) => state.currentUser);
  const isClient = initialUser.role === ROLES.CLIENT;
  const isAgent = initialUser.role === ROLES.AGENT;
  const isManager = initialUser.role === ROLES.MANAGER;
  const isAdmin = initialUser.role === ROLES.ADMIN;
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const [confModalOpen, setConfModalOpen] = useState<boolean>(false);
  const [isDeleteSupport, setDeleteSupport] = useState<boolean>(false);
  const [bottomMenuShown, setBottomMenuShown] = useState<boolean>(false);
  const [showMainStatus, setShowMainStatus] = useState<boolean>(true);
  const [lead, setLead] = useState<leadFullInfo | null>(null);
  const [leadStatusHistory, setLeadStatusHistory] = useState<
    leadStatusHistoryType[]
  >([]);
  const [commonFields, setCommonFields] = useState<fieldsProposalItemsType[]>([]);
  const [groupFields, setGroupFields] = useState<groupFieldsProposalItemsType[]>([]);
  const [notificationsUnreadCount, setNotificationsUnreadCount] = useState<number>(0);
  const [resetUnread, setResetUnread] = useState<boolean>(false)
  const [allDocPacksStatus, setAllDocPacksStatus] = useState<DocsStatusEnum>(DocsStatusEnum.None);
  const tab = props.match.params.tab;
  const [valueDetails, setValueDetails] = useState<number>(parseInt(tab));
  const [requstStatus, setRequstStatus] = useState<boolean>(false);
  const [isChangeStatusModal, setChangeStatusModal] = useState(false);
  const [errorModal, setErrorModal] = useState<boolean>(false);
  const handleDetailsChange = (e: any, newValue: number) => {
    setValueDetails(newValue);
  };
  const [isArchive, setArchive] = useState(false);
  const [commonNotesInfo, setCommonNotesInfo] = useState<CommonNotesInfoType | null>(null)

  const archiveLead = lead?.isArchive;
  const fullTitle = `${"Заявка"} ${leadId}`;
  const docsNoNeed = lead?.docsStatus === DocsStatusEnum.None;
  const leadDate = lead && `от ${dateConverter(lead?.dateAdd)}`;
  const archiveStatus = archiveLead ? ' (в архиве)' : '';
  const fullLeadDate = leadDate + archiveStatus;

  const bankIcon = lead?.bank.icon;
  const bankName = lead?.bank.name;
  const leadName = lead?.proposalName;
  const status = lead && lead?.status;
  const statusText = lead && lead?.statusFontColor;
  const statusBg = lead && lead?.statusColor;
  const memoizedLeadId = useMemo(() => parseInt(leadId), [leadId]);

  useEffect(() => {
    if (+leadId !== 0) {
      leadsService
        .getLeadByIdService(leadId)
        .then((res: leadFullInfo) => {
          if (res) {
            setLead(res);
            setAllDocPacksStatus(res.docsStatus)
            setLeadStatusHistory(res.leadStatusHistory);
            setCommonFields(res.fieldsProposalItems);
            if (res.groupFieldsProposalItems.length > 0) {
              setGroupFields(res.groupFieldsProposalItems)
            }
            setArchive(res.isArchive);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [leadId]);

  //count needed docs
  useEffect(() => {
    if (allDocPacksStatus === DocsStatusEnum.None) return
    leadId &&
      leadsService
        .getLeadDocPacksService(+leadId)
        .then((res: LeadDocPackType[]) => {
          if (res) {
            const requiredDocsCount = res.filter(doc => doc?.isRequired).length;
            const noEmptyFileDocsCount = res.filter(doc => doc?.isRequired && (doc?.files?.length > 0)).length;
            const neededDocCount = requiredDocsCount - noEmptyFileDocsCount;
            const leadIdNumber = +leadId
            dispatch(editDocsCount({
              leadId: leadIdNumber,
              countNeededDocs: neededDocCount,
              countUnreadMessage: 0, countUnreadNotifications: 0
            }));

          }
        })
        .catch((error) => {
          setErrorModal(true)
          console.log(error);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leadId, allDocPacksStatus]);

  //get unread notifications number for this lead
  useEffect(() => {
    let queryParams = { onlyUnread: true, leadId };
    if (initialUser.status === clientStatuses.NotCompleteRegistration) return;
    notificationsService
      .getNotificationsListService(queryParams)
      .then(res => {
        setNotificationsUnreadCount(res.pagination.total);
      })
      .catch(console.error)
  }, [leadId, initialUser.role, initialUser.status]);

  //get manager notes
  useEffect(() => {
    if (+leadId !== 0 && isManager) {
      getNotes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leadId]);

  const generateTabNames = (lead: leadFullInfo, docsNoNeed: boolean, isManager: boolean) => {
    let tabNames = ["Информация", "Чат", "Уведомления"];
    if (!docsNoNeed) {
      tabNames.splice(1, 0, "Документы");
    }

    if (lead && docsNoNeed) {
      tabNames = ["Информация", "Чат", "Уведомления"];
    }

    if (isManager) {
      tabNames.push("Заметки");
    }
    return tabNames;
  };

  const tabNames = lead
    ? generateTabNames(lead, docsNoNeed, isManager)
    : ["Информация", "Чат", "Уведомления"];

  const handleBottomMenuToggle = () => {
    setBottomMenuShown(!bottomMenuShown);
  };

  const items = [
    { text: "Создать заявку на основе этой", onClick: () => handleNewOrderBaseOnOldClick() },
    { text: "Отозвать заявку", onClick: () => handleDelClick() },
  ];

  const handleClickNewOrder = () => {
    if (isClient) {
      history.push(`${ROUTES.CLIENT}${ROUTES.NEW_ORDER}`);
    } else if (isAgent) {
      history.push(`${ROUTES.AGENT}${ROUTES.NEW_ORDER}`);
    }
  };

  const handlePushToProducts = () => {
    if (isClient) {
      history.push(`${ROUTES.CLIENT}${ROUTES.PRODUCTS}`);
    } else if (isAgent) {
      history.push(`${ROUTES.AGENT}${ROUTES.PRODUCTS}`);
    }
  };

  const handleNewOrderBaseOnOldClick = async () => {
    dispatch(actions.pushDataDraftForOrder([+leadId]));
    if (lead) {
      dispatch(
        actions.pushDataForOrder({
          banksList: [
            {
              name: lead.proposalName,
              id: lead.bank.id,
              bankName: lead.bank.name,
            },
          ],
          inn: lead.data?.Inn ?? "",
        })
      );
    }
    dispatch(actions.pushIsNewBasedOnOldForOrder(true));
    handleClickNewOrder();
  };

  const handleDelClick = () => {
    leadsService.getDeleteInfoByIdService(leadId)
      .then((res) => {
        setDeleteSupport(res.is_delete_supported);
        setConfModalOpen(true);
      })
      .catch((error) => {
        console.error(error)
      })
  };

  const deleteLeadHandler = () => {
    leadId &&
      leadsService
        .deleteLeadByIdService(leadId)
        .then((res: leadFullInfo) => { })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          handlePushToProducts();
          setConfModalOpen(false);
        });
  };

  const getNotes = async () => {
    if (!leadId) return;
    const res = await noteService.getNotes(leadId);
    if (!res) {
      const commonNotesInfo: CommonNotesInfoType = {
        notes: [],
        notesCount: 5,
        isExpired: true,
        isToday: true,
      };
      setCommonNotesInfo(commonNotesInfo);
      return;
    };
    let isExpired = false;
    let isToday = false;
    const formattedNotes = res.map(elem => {
      if (elem?.isNotificate && elem?.frequencyUnit === FrequencyUnitEnum.no
        && elem?.isExpired === true) {
        isExpired = true;
      }
      if (elem?.isNotificate && elem?.frequencyUnit === FrequencyUnitEnum.no
        && elem?.isToday === true) {
        isToday = true;
      }

      if (elem?.actualNotificatiionDateTime) {
        //time localization
        const actualNotificationDate = new Date(elem.actualNotificatiionDateTime);

        const now = new Date();
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        const actualNotificationLocalDateString = actualNotificationDate.toLocaleDateString();

        if (elem?.isNotificate && elem?.frequencyUnit === FrequencyUnitEnum.no &&
          actualNotificationLocalDateString === today.toLocaleDateString()) {

          isToday = true;
          if (now > actualNotificationDate) {
            isExpired = true;
          }
        }
      }

      if (elem.hours !== undefined && elem.hours !== null) {
        elem.hours = elem.hours.toString().padStart(2, '0');
      }
      if (elem.minutes !== undefined && elem.minutes !== null) {
        elem.minutes = elem.minutes.toString().padStart(2, '0');
      }
      return {
        ...elem,
        isExpired,
        isToday
      };
    })

    const commonNotesInfo: CommonNotesInfoType = {
      notes: formattedNotes,
      notesCount: formattedNotes.length,
      isExpired: isExpired,
      isToday: isToday,
    }
    setCommonNotesInfo(commonNotesInfo);
  }

  return (
    <div>
      <HeaderNew
        title={fullTitle}
        description={lead && fullLeadDate}
        centeredTitle
        moveTitle={isManager || isAdmin}
        showGoBack>
        {(isClient || isAgent) && (
          isMobile ?
            (<>
              <IconButtonMain
                color="noBgGray"
                handleClick={() => handleBottomMenuToggle()}
                icon={<Dots color={COLORS.gray2} />}
              />
              {bottomMenuShown ?
                <BottomMenu
                  title={fullTitle}
                  items={items}
                  onClose={() => handleBottomMenuToggle()} />
                : null}
            </>
            ) : (
              <>
                <ButtonCustom
                  color="primary"
                  style={{ marginRight: "16px" }}
                  handleClick={handleNewOrderBaseOnOldClick}>
                  Создать заявку на основе этой
                </ButtonCustom>
                {!archiveLead &&
                  <ButtonCustom
                    color="ghost"
                    handleClick={handleDelClick}>
                    Отозвать заявку
                  </ButtonCustom>
                }
              </>))}
      </HeaderNew>

      <Container className={classes.container}>
        {lead ?
          <div className={classes.content}>
            <div className={classes.statusBlock}>
              <CustomAccordion
                id='lead_title'
                setShowMainStatus={setShowMainStatus}
                titleDivider
                style={{ padding: isMobile ? '24px 16px 0' : '24px 24px 0' }}
                titleChildren={
                  <div className={classes.titleContainer}>
                    {bankIcon && <img className={classes.imgIcon} src={bankIcon} alt="Bank icon" />}
                    <div className={classes.columnBox}>
                      <div className={classes.rowBox}>
                        <Typography variant="body1" style={{ color: COLORS.gray2 }}>{bankName}</Typography>
                        <Typography variant="body1" style={{ fontWeight: 600 }}>{leadName}</Typography>
                      </div>
                      {showMainStatus &&
                        <StatusCloud
                          lowercase
                          id="main-status"
                          status={status}
                          statusBg={statusBg}
                          statusTextColor={statusText}
                        />}
                      {archiveLead &&
                        <StatusCloud
                          lowercase
                          status={'В архиве'}
                          statusBgRgba="rgba(0, 19, 23, 0.16)"
                          statusTextColorRgba={COLORS.black}
                        />}
                    </div>
                  </div>
                }
                contentChildren={
                  <StatusBlock
                    isActive={Boolean(lead && lead.isActive)}
                    leadStatusHistory={leadStatusHistory} />
                }
              />
              <div className={classes.containerTabs}>
                <TabsPanel
                  name={tabNames}
                  handleChange={handleDetailsChange}
                  value={valueDetails}
                  leadId={memoizedLeadId}
                  lead={lead}
                  notesCount={isManager && commonNotesInfo ? commonNotesInfo.notesCount ?? null : null}
                  notificationsUnreadCount={notificationsUnreadCount}
                  resetUnread={resetUnread}
                  currentLeadArchive={isArchive}
                />
              </div>
            </div>
            {tabNames.map((tabName, index) => (
              <React.Fragment key={v4()}>
                {valueDetails === index && tabName === "Информация" && (
                  <TabPanel value={valueDetails} index={index}>
                    <InfoBlock commonFields={commonFields} groupFields={groupFields} />
                  </TabPanel>
                )}

                {!docsNoNeed && valueDetails === index && tabName === "Документы" && (
                  <TabPanel value={valueDetails} index={index}>
                    <Documents
                      leadId={memoizedLeadId}
                      isArchive={isArchive}
                      allDocPacksStatus={allDocPacksStatus}
                      setAllDocPacksStatus={setAllDocPacksStatus}
                    />
                  </TabPanel>
                )}

                {valueDetails === index && tabName === "Чат" && (
                  <TabPanel value={valueDetails} index={index}>
                    <ChatBlock
                      leadId={memoizedLeadId}
                      isArchive={isArchive}
                    />
                  </TabPanel>
                )}

                {valueDetails === index && tabName === "Уведомления" && (
                  <TabPanel value={valueDetails} index={index}>
                    <Notifications
                      leadId={memoizedLeadId}
                      setResetUnread={setResetUnread}
                    />
                  </TabPanel>
                )}

                {valueDetails === index && tabName === "Заметки" && (
                  <TabPanel value={valueDetails} index={index}>
                    <NotesBlock
                      leadId={memoizedLeadId}
                      commonNotesInfo={commonNotesInfo}
                      getNotes={getNotes}
                    />
                  </TabPanel>
                )}
              </React.Fragment>
            ))}
          </div>
          :
          <SpinnerMain />
        }
      </Container>
      {isChangeStatusModal && (
        <ChangeStatusModal
          requstStatus={requstStatus}
          setRequstStatus={setRequstStatus}
          setChangeStatusModal={setChangeStatusModal}
          isOpen={isChangeStatusModal}
        />
      )}
      {/* {isArchiveModal && (
        <ArchiveStatusModal
          isOpen={isArchiveModal}
          setCloseModal={setArchiveModal}
          handleArchive={handleClickArchive}
          archiveRequestStatus={archiveRequestStatus}
          setArchiveRequestStatus={setArchiveRequestStatus}
        />
      )} */}

      {confModalOpen && (
        <ConfirmModalRedesign
          handleConfirm={deleteLeadHandler}
          title={"Отозвать заявку"}
          handleCancel={() => setConfModalOpen(false)}
          handleClose={() => setConfModalOpen(false)}
          text={
            "Вы действительно хотите отозвать заявку? Ваша заявка будет перемещена в архив без возможности восстановления."
          }
          confBtnText="Отозвать заявку"
          cancelBtnText="Вернуться к заявке"
          open={confModalOpen}
          extraSubText={
            !isDeleteSupport ? (
              <div className={classes.alertBox}>
                <div className={classes.infoIcon}><Info color={COLORS.white} /></div>
                <span className={classes.alertText}>{"Банк не поддерживает функцию отзыва, данная заявка будет перемещена в архив, но информация о заявке останется в Банке"}</span>
              </div>
            ) : (
              ""
            )
          }
        />
      )}
      {errorModal && <ErrorModal isOpen={errorModal} />}
    </div>
  );
};

export default memo(ClientProductDetails);
