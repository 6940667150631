type Props = {
    className?: string;
}

const BigArrow = ({ className }: Props) => {
  return (
    <svg
      width="13"
      height="7"
      viewBox="0 0 13 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M6.01 7.00001L12.02 0.990014L11.032 6.24657e-05L6.01001 5L0.988951 0L0 0.989014L6.01 7.00001Z"
        fill="#131313"
      />
    </svg>
  );
};

export default BigArrow;
