import React, { memo, useEffect, useState } from "react";
import { FormType } from "../../enum";
import UncleImg from "./uncle.png";
import { stringParser } from "../../../core/utils/queryParsers";
import { useQuery } from "../../../core/hooks/useQuery";
import {
  Dialog,
  IconButton,
  Typography,
  makeStyles,
  createStyles,
  Theme,
  Tooltip,
  Button,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useHistory } from "react-router";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import clsx from "clsx";

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      dialog: {
        maxWidth: "100%",
        borderRadius: "10px",
        [theme.breakpoints.down(840)]: {
          "& div": {
            "& .MuiDialog-paperWidthFalse": {
              width: "100%",
              maxWidth: "100%",
              padding: "0",
              margin: "0",
              height: "100vh",
            },
          },
          "&:nth-child(3n)>div": {
            maxWidth: "100%",
            borderRadius: '10px',
            "&>div": {
              width: "100%",
              maxWidth: "100%",
              padding: "0",
              margin: "0",
              height: "100vh",
            },
          },
        },
      },
      paperMUI: {
        borderRadius: "10px",
      },
      paper: {
        boxSizing: "border-box",
        display: "flex",
        justifyContent: "flex-start",
        width: "780px",
        backgroundColor: theme.palette.background.paper,
        borderRadius: "10px",
        height: "587px",
        [theme.breakpoints.down(840)]: {
          justifyContent: "center",
          borderRadius: "0",
          maxWidth: "100%",
        },
      },
      closeWrapper: {
        width: "250px",
        height: "41px",
        display: "flex",
        justifyContent: "flex-end",
        marginBottom: "50px",
      },
      content: {
        paddingTop: "50px",
        width: "400px",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        [theme.breakpoints.down(840)]: {
          paddingTop: "33px",
        },
      },
      preview: {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        overflow: "hidden",
        paddingTop: "50px",
        position: "relative",
        width: "380px",
        background: "#EDF2F5",
        "&::after": {
          position: "absolute",
          content: '""',
          backgroundImage: `url(${UncleImg})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "left bottom",
          top: "248px",
          width: "285px",
          height: "339px",
          left: "50%",
          transform: "translate(-50%, 0)",
          zIndex: "3",
        },
        "&::before": {
          position: "absolute",
          content: '""',
          background: "#DBE5EB",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "left bottom",
          borderRadius: "90%",
          top: "344px",
          width: "419px",
          height: "419px",
          left: "50%",
          transform: "translate(-50%, 0)",
          zIndex: "2",
        },
        [theme.breakpoints.down(840)]: {
          display: "none",
        },
      },
      title: {
        marginBottom: "50px",
        [theme.breakpoints.down("sm")]: {
          marginBottom: "16px",
        },
        [theme.breakpoints.down(840)]: {
          marginTop: "35px",
          marginBottom: "114px",
          paddingLeft: "16px",
          alignSelf: "flex-start",
        },
      },
      subTitle: {
        marginBottom: "37px",
        [theme.breakpoints.down("sm")]: {
          marginBottom: "24px",
        },
      },
      button: {
        margin: 0,
        width: "360px",
        height: "51px",
        [theme.breakpoints.down(480)]: {
          width: "328px",
        },
      },
      buttonTop: {
        marginBottom: "13px",
      },
      previewTitle: {
        marginBottom: "15px",
      },
      previewText: {
        width: "332px",
        textAlign: "center",
      },
      footer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "204px",
        marginTop: "182px",
        [theme.breakpoints.down(840)]: {
          marginTop: "142px",
        },
      },
      link: {
        fontSize: "16px",
        fontWeight: 500,
        cursor: "pointer",
        textDecoration: "none",
        color: theme.palette.primary.main,
      },
      arrowBackBtn: {
        display: "flex",
        alignSelf: "flex-start",
        paddingLeft: "16px",
        "& svg": {
          fill: "#000000",
          fontSize: "22px",
        },
        [theme.breakpoints.up(840)]: {
          display: "none",
        },
      },
      closeBtn: {
        "& svg": {
          fill: "#000000",
          fontSize: "22px",
        },
        [theme.breakpoints.down(840)]: {
          display: "none",
        },
      },
    }),
  { index: 1 }
);

const queryParsers = {
  formType: stringParser<FormType>(),
};


const RegistrationModal: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = useState<boolean>(false);
  const { formType } = useQuery(queryParsers);

  useEffect(() => {
    if (formType === FormType.Registration) {
      setOpen(true);
    }
  }, [formType]);


  const handleClose = () => {
    history.push({
      pathname: history.location.pathname,
    });
  };

  const handleLogin = () => {
    history.push({
      pathname: history.location.pathname,
      search: `?formType=${FormType.LoginFirstEmail}`,
    });
  };

  const handleClientRegistration = () => {
    history.push({
      pathname: history.location.pathname,
      search: `?formType=${FormType.RegistrationDetail}&user=client`,
    });
  };

  const handleAgentRegistration = () => {
    history.push({
      pathname: history.location.pathname,
      search: `?formType=${FormType.RegistrationDetail}&user=agent`,
    });
  };

  return formType === FormType.Registration  ? (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll={"body"}
      fullScreen={false}
      className={classes.dialog}
      maxWidth={false}
      classes={{  
        paper: classes.paperMUI 
      }}
    >
      <div className={classes.paper}>
        <div className={classes.content}>
          <IconButton
            className={classes.arrowBackBtn}
            aria-label="close"
            onClick={handleClose}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography component="h2" variant="h2" className={classes.title}>
            Регистрация
          </Typography>
          <Typography component="h4" variant="h4" className={classes.subTitle}>
            Продолжить как...
          </Typography>
          <Tooltip
            title="Вы юрлицо, которое хочет оформить банковский продукт"
            placement="bottom"
          >
            <Button
              color="primary"
              variant="outlined"
              onClick={handleClientRegistration}
              className={clsx(classes.button, classes.buttonTop)}
            >
              <Typography
                component="span"
                variant="body1"
                style={{
                  color: "inherit",
                  textTransform: "none",
                  lineHeight: "3",
                }}
              >
                Клиент
              </Typography>
            </Button>
          </Tooltip>
          <Tooltip
            title="У вас есть клиенты юрлица, для которых вы хотите оформить банковские продукты и получить комиссию"
            placement="bottom"
          >
            <Button
              color="primary"
              variant="outlined"
              onClick={handleAgentRegistration}
              className={classes.button}
            >
              <Typography
                component="span"
                variant="body1"
                style={{
                  color: "inherit",
                  textTransform: "none",
                  lineHeight: "3",
                }}
              >
                Агент
              </Typography>
            </Button>
          </Tooltip>
          <div className={classes.footer}>
            <Typography
              component="span"
              variant="body1"
              style={{
                color: "inherit",
                textTransform: "none",
              }}
              className={classes.previewText}
            >
              У вас уже есть аккаунт?
            </Typography>
            <div className={classes.link} onClick={handleLogin}>
              Войдите
            </div>
          </div>
        </div>
        <div className={classes.preview}>
          <div className={classes.closeWrapper}>
            <IconButton
              aria-label="close"
              className={classes.closeBtn}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <Typography
            component="h4"
            variant="h4"
            className={classes.previewTitle}
          >
            Добро пожаловать!
          </Typography>
          <Typography
            component="span"
            variant="body1"
            className={classes.previewText}
          >
            Мы дорожим каждым клиентом на нашем сервисе finlab
          </Typography>
        </div>
      </div>
    </Dialog>
  ) : null;
};

export default memo(RegistrationModal);
