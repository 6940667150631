
type Props = {
    isActive?: boolean;
    isImportant?: boolean;
}
const ArticlesOptionSlider: React.FC<Props> = ({ isActive, isImportant }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="20" viewBox="0 0 40 20" fill="none">
            <rect
                width="40"
                height="20"
                rx="3"
                fill={isActive || isImportant ? "#306F9A" : "#D0D9DE"}
            />
            <rect
                x={isActive || isImportant ? "21" : "1"}
                y="1"
                width="18"
                height="18"
                rx="3"
                fill="white" />
        </svg>
    );
};

export default ArticlesOptionSlider;