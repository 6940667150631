import { Checkbox } from "@material-ui/core";
import { useEffect, useState } from "react";
import { ArrowDownIcon } from "../../core/consts/redesign/icons/ArrowDown";
import { ArrowUpIcon } from "../../core/consts/redesign/icons/ArrowUp";
import { LoopIcon } from "../../core/consts/redesign/icons/Loop";
import CustomPopover from "../CustomPopover/CustomPopover";
import classes from "./SelectCustom.module.scss";

type PropsType = {
  label?: string;
  placeHolder?: string;
  value: string[];
  handleChange: any;
  error?: string;
  success?: boolean;
  errorMessage?: string;
  iconPre?: any;
  // isClean?: boolean;
  options: string[];
  count?: number;
  isSearch?: boolean;
  noBorder?: boolean;
  isFullWidth?: boolean;
  disabled?: boolean;
  isRequired?: boolean;
  className?: string;
};

const SelectMultiCustom = ({
  label,
  placeHolder,
  value,
  handleChange,
  error,
  success,
  errorMessage,
  iconPre,
  // isClean,
  isRequired,
  options,
  count,
  isSearch,
  noBorder,
  isFullWidth,
  disabled,
  className
}: PropsType) => {
  const [focus, setFocus] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [filteredOptions, setFilteredOptions] = useState(options);
  const popoverId = `popover-${label}`;

  useEffect(() => {
    if (searchText.length > 0) {
      const temp = options.filter((elem) =>
        elem.toLowerCase().includes(searchText.toLowerCase())
      );
      setFilteredOptions(temp);
    } else {
      setFilteredOptions(options);
    }
  }, [searchText, options]);

  const handleClick = (val: string) => {
    // console.log(" handleClick value", value);
    setIsOpen(false);
    setFocus(true)
    if (value.find((elem) => elem === val)) {
      const temp = [...value];
      const newValue = temp.filter((elem) => elem !== val);
      handleChange(newValue);
    } else {
      handleChange([...value, val]);
    }
  };

  return (
    <>
      {isOpen && (
        <div className={classes.modal_backdrop} onClick={() => setIsOpen(false)} />
      )}
      <div
        onClick={() => setIsOpen(!isOpen)}
        // onBlur={() => setTimeout(() => setIsOpen(false), 0)}
        className={`${classes.control_box} ${isFullWidth ? classes.w100 : ""
          }  ${className}`}
      >
        <div
          className={`${classes.input_box} ${classes.w100} 
          ${error ? classes.error_box : ""} 
          ${value && value?.length > 0 && !error ? classes.filled : ""}
          ${isOpen ? classes.focusBox : ""}
          ${disabled ? classes.disabled_box : ''}`}
          key={`key_${label}`}
        >
          {label && (
            <div
              className={
                `${(value && value.length > 0) || focus ? classes.label_small : classes.label_big}`
              }
            >
              {label}
              {isRequired && <span style={{ color: '#DC5103' }}> *</span>}
            </div>
          )}

          <div className={`${classes.flex_betw} ${isOpen ? classes.flex_betwMargin : ""}`}>
            <input
              className={`${classes.input} ${label ? "" : classes.input_without_label}`}
              value={value.join("; ")}
              onChange={(e) => {
                handleChange(e.target.value);
              }}
              // placeholder={placeHolder ?? ""}
              onFocus={() => setFocus(true)}
              onBlur={() => setFocus(false)}
              readOnly
              onClick={() => setIsOpen(!isOpen)}
            />
            {/* <div className="spacer" /> */}
            <div onClick={() => setIsOpen(!isOpen)} className={classes.input_icon}>
              {isOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
            </div>
            {label && <CustomPopover id={popoverId} label={label} margin='35px' />}
          </div>

          {isOpen && (
            <>
              <div className={classes.modal_back}>
                <div className={classes.options_block} onBlur={() => setIsOpen(false)}>

                  <div className={classes.option_inner}>
                    <div className={classes.options_header}>
                      {label}
                      <div className={classes.options_header_count}>
                        ({options.length})
                      </div>
                    </div>
                    {isSearch && (
                      <div className={classes.search_box}>
                        <LoopIcon />
                        <input
                          className={`${classes.input}`}
                          value={searchText}
                          onChange={(e) => {
                            setSearchText(e.target.value);
                          }}
                          placeholder={"поиск по названию"}
                          onFocus={() => setFocus(true)}
                          onBlur={() => setFocus(false)}
                        />
                        <div className="spacer" />
                        {/* <div onClick={() => setSearchText("")}>
                    <CloseForInputIcon />
                  </div> */}
                      </div>
                    )}
                    {filteredOptions.map((elem) => (
                      <div
                        key={elem}
                        className={classes.input_option}
                        onFocus={() => setFocus(true)}
                        onBlur={() => setFocus(false)}
                        onClick={() => {
                          handleClick(elem);
                        }}
                      >
                        <div>{elem}</div>
                        <div>
                          <Checkbox
                            color="primary"
                            checked={
                              value.find((el) => elem === el) ? true : false
                            }
                          />
                        </div>
                      </div>
                    ))}

                    <div className={classes.button_block}>
                      {value.length > 0 && (
                        <button
                          className={`button_gray ${classes.button}`}
                          onClick={() => handleChange([])}
                        >
                          сбросить ({value.length})
                        </button>
                      )}
                      <button
                        className={`button_primary_w100 ${value.length > 0 ? classes.button : classes.w100
                          }`}
                        onClick={() => setIsOpen(false)}
                      >
                        применить
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        {error && (
          <div className={classes.error} key="error">
            {error}
          </div>
        )}
      </div>
    </>
  );
};

export default SelectMultiCustom;
