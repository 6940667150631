export const Logo = ({ className }: { className: string }) => (
  <svg
    width="67"
    height="19"
    viewBox="0 0 67 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M10.896 3.384H14.016V0.623999H10.896V3.384ZM2.472 18H5.376V7.824H8.688V5.424H5.328V4.728C5.328 3.408 5.904 2.808 7.008 2.808C7.632 2.808 8.136 2.928 8.712 3.12V0.671999C8.064 0.48 7.368 0.335999 6.36 0.335999C5.112 0.335999 4.176 0.671999 3.504 1.344C2.832 2.016 2.472 3.072 2.472 4.488V5.4H0.864V7.824H2.472V18ZM11.016 18H13.92V5.328H11.016V18ZM17.7146 18H20.6186V10.848C20.6186 8.88 21.7946 7.704 23.4746 7.704C25.2026 7.704 26.1866 8.832 26.1866 10.8V18H29.0906V9.936C29.0906 6.984 27.4346 5.064 24.5786 5.064C22.6106 5.064 21.4346 6.096 20.6186 7.296V5.328H17.7146V18ZM32.7654 18H35.6694V0.48H32.7654V18ZM50.0966 18V10.488C50.0966 7.104 48.2726 5.136 44.5286 5.136C42.4646 5.136 41.0966 5.568 39.7046 6.192L40.4966 8.52C41.6486 8.04 42.7046 7.728 44.1206 7.728C46.1366 7.728 47.2406 8.688 47.2406 10.44V10.68C46.2566 10.368 45.2726 10.152 43.7366 10.152C40.7846 10.152 38.6006 11.496 38.6006 14.304C38.6006 16.896 40.7126 18.264 43.1126 18.264C45.0326 18.264 46.3526 17.472 47.2166 16.44V18H50.0966ZM47.2886 13.416C47.2886 15 45.8486 16.08 43.9286 16.08C42.5606 16.08 41.4806 15.408 41.4806 14.208C41.4806 12.936 42.5606 12.192 44.3846 12.192C45.5126 12.192 46.5446 12.408 47.2886 12.696V13.416ZM60.7506 18.264C63.7746 18.264 66.6546 15.888 66.6546 11.688C66.6546 7.44 63.7506 5.064 60.7506 5.064C58.7106 5.064 57.3906 6.12 56.4546 7.44V0.48H53.5506V18H56.4546V16.032C57.3426 17.208 58.6626 18.264 60.7506 18.264ZM60.0786 15.744C58.1106 15.744 56.3826 14.136 56.3826 11.688C56.3826 9.216 58.1106 7.584 60.0786 7.584C62.0466 7.584 63.7026 9.192 63.7026 11.64C63.7026 14.184 62.0706 15.744 60.0786 15.744Z"
      fill="#306F9A"
    />
  </svg>
);

export const BigLogo = () => (
  <svg
    width="99"
    height="28"
    viewBox="0 0 99 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.344 5.076H20.024V0.935999H15.344V5.076ZM2.708 27H7.064V11.736H12.032V8.136H6.992V7.092C6.992 5.112 7.856 4.212 9.512 4.212C10.448 4.212 11.204 4.392 12.068 4.68V1.008C11.096 0.719999 10.052 0.503998 8.54 0.503998C6.668 0.503998 5.264 1.008 4.256 2.016C3.248 3.024 2.708 4.608 2.708 6.732V8.1H0.296V11.736H2.708V27ZM15.524 27H19.88V7.992H15.524V27ZM25.5719 27H29.9279V16.272C29.9279 13.32 31.6919 11.556 34.2119 11.556C36.8039 11.556 38.2799 13.248 38.2799 16.2V27H42.6359V14.904C42.6359 10.476 40.1519 7.596 35.8679 7.596C32.9159 7.596 31.1519 9.144 29.9279 10.944V7.992H25.5719V27ZM48.1482 27H52.5042V0.719999H48.1482V27ZM74.1449 27V15.732C74.1449 10.656 71.4089 7.704 65.7929 7.704C62.6969 7.704 60.6449 8.352 58.5569 9.288L59.7449 12.78C61.4729 12.06 63.0569 11.592 65.1809 11.592C68.2049 11.592 69.8609 13.032 69.8609 15.66V16.02C68.3849 15.552 66.9089 15.228 64.6049 15.228C60.1769 15.228 56.9009 17.244 56.9009 21.456C56.9009 25.344 60.0689 27.396 63.6689 27.396C66.5489 27.396 68.5289 26.208 69.8249 24.66V27H74.1449ZM69.9329 20.124C69.9329 22.5 67.7729 24.12 64.8929 24.12C62.8409 24.12 61.2209 23.112 61.2209 21.312C61.2209 19.404 62.8409 18.288 65.5769 18.288C67.2689 18.288 68.8169 18.612 69.9329 19.044V20.124ZM90.1258 27.396C94.6618 27.396 98.9818 23.832 98.9818 17.532C98.9818 11.16 94.6258 7.596 90.1258 7.596C87.0658 7.596 85.0858 9.18 83.6818 11.16V0.719999H79.3258V27H83.6818V24.048C85.0138 25.812 86.9938 27.396 90.1258 27.396ZM89.1178 23.616C86.1658 23.616 83.5738 21.204 83.5738 17.532C83.5738 13.824 86.1658 11.376 89.1178 11.376C92.0698 11.376 94.5538 13.788 94.5538 17.46C94.5538 21.276 92.1058 23.616 89.1178 23.616Z"
      fill="#306F9A"
    />
  </svg>
);

export const MobMenu = () => (
  <svg
    width="27"
    height="19"
    viewBox="0 0 27 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.625 18.25H9.125V15.3333H26.625V18.25ZM26.625 10.9583H0.375V8.04167H26.625V10.9583ZM26.625 3.66667H9.125V0.75H26.625V3.66667Z"
      fill="#131313"
    />
  </svg>
);
