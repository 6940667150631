import proposalsApi from "../api/proposalsApi";
import {
  FiltersType,
  GetPropsQueryType,
  GetPropsUniversalQueryType,
  InnerProductDetailsData,
  ProposalCalculatorBodyType,
  ProposalCalculatorType,
  RecommendedType,
  ResponseDataType
} from "../types/proposalTypes";

class ProposalService {
  getBestPropsService(
    prodict_id: number,
    sort_type: string,
    Q?: any
  ): Promise<ResponseDataType> {
    return proposalsApi.getBestProposals(prodict_id, sort_type, Q);
  }

  getProductFilters(Q: number): Promise<FiltersType> {
    return proposalsApi.getProductsFilters(Q);
  }

  getInnerProposalPageInfoService(
    proposalid?: string,
    alias?: string
  ): Promise<InnerProductDetailsData> {
    return proposalsApi.getInnerProposalPageInfo(proposalid, alias);
  }

  addProposalLikeService(id: string): Promise<string> {
    return proposalsApi.increaseProposalLikes(id);
  }

  getPopularPropsService(queryParams: GetPropsQueryType): Promise<ResponseDataType> {
    return proposalsApi.getPopularProposals(queryParams);
  }

  getProfitablePropsService(queryParams: GetPropsQueryType): Promise<ResponseDataType> {
    return proposalsApi.getProfitableProposals(queryParams);
  }

  getRecommendedPropsService(
    inn?: string,
    bankIds?: number[],
    filters?: any,
    productid?: number
  ): Promise<RecommendedType[]> {
    return proposalsApi.getRecommendedProposals(
      inn,
      bankIds,
      filters,
      productid
    );
  }

  getAllPropsService(Q: GetPropsQueryType): Promise<ResponseDataType> {
    return proposalsApi.getAllProposals(Q);
  }

  addProposalViewService(id: string): Promise<string> {
    return proposalsApi.increaseProposalViews(id);
  }

  // новая универсальная ручка
  getPropsServiceUniversal(
    queryParams: GetPropsUniversalQueryType
  ): Promise<ResponseDataType> {
    return proposalsApi.getAllProposalsUniversal(queryParams);
  }

  // расчет для предложения по выбранным параметрам калькулятора
  getProposalCalculator(body: ProposalCalculatorBodyType): Promise<number> {
    return proposalsApi.getProposalCalculator(body);
  }

  // параметры калькулятора для предложения
  getProposalCalculatorParameters(
    proposalId: number
  ): Promise<ProposalCalculatorType> {
    return proposalsApi.getProposalCalculatorParameters(proposalId);
  }

}

const proposalService = new ProposalService();
export default proposalService;
