import { ResponseItemDataType } from "./proposalTypes";

export type CartState = {
  applications: ResponseItemDataType[];
  isOpenCart: boolean;
};

export enum CartActionTypes {
  ADD_TO_CART = "ADD_TO_CART",
  DELETE_FROM_CART = "DELETE_FROM_CART",
  CLEAR_CART = "CLEAR_CART",
  CHANGE_IS_OPEN_CART = "CHANGE_IS_OPEN_CART",
  ADD_TO_CART_ARRAY = "ADD_TO_CART_ARRAY",
}

interface AddToCart {
  type: CartActionTypes.ADD_TO_CART;
  payload: ResponseItemDataType;
}
interface AddToCartArray {
  type: CartActionTypes.ADD_TO_CART_ARRAY;
  payload: ResponseItemDataType[];
}
interface DeleteFromCart {
  type: CartActionTypes.DELETE_FROM_CART;
  payload: ResponseItemDataType;
}

interface ClearCart {
  type: CartActionTypes.CLEAR_CART;
}
interface ChangeIsOpenCart {
  type: CartActionTypes.CHANGE_IS_OPEN_CART;
  payload: boolean;
}

export type CartActions =
  | AddToCart
  | AddToCartArray
  | DeleteFromCart
  | ClearCart
  | ChangeIsOpenCart;

export type CartItemForCookieType = {
  idOpen: number | string; //id
  id: number | string; //realProposalId
  sum?: number;
  term?: number;
  name?: string;
  bankName?: string;
};
