import React, { memo, useEffect, useState } from "react";

import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  createStyles,
  IconButton,
  ListItemIcon,
  makeStyles,
  Theme,
  Tooltip,
  Typography
} from "@material-ui/core";
import { MessageNotification } from "../../../core/api/chatsApi";
import { ROLES, ROUTES } from "../../../core/consts/common";
import { HomeIcon } from "../../../core/consts/images/HomeIcon";
import { LogoutIcon } from "../../../core/consts/images/LogoutIcon";
import { MessageIcon } from "../../../core/consts/images/MessageIcon";
import {
  DevStand,
  DevStandApp,
  ProdStand
} from "../../../core/consts/redesign/commonRedesign";
import authService from "../../../core/services/AuthService";
import chatService from "../../../core/services/ChatsService";
import notificationsService from "../../../core/services/NotificationService";
import { actions } from "../../../core/store";
import { clearCart } from "../../../core/store/action-creators/cart";
import { clientStatuses } from "../../../core/types/managerApiTypes";
import { rootState } from "../../../core/types/rootState";
import ButtonCustom from "../../../UIcomponentsRedesign/ButtonCustom/ButtonCustom";
import ChatModal from "../chat-modal";
import ChatModalManager from "../chat-modal-manager";
import ChatModalNew from "../chat-modal-new/ChatModalNew";
import ConfirmModalRedesign from "../confirm-modal-redesign/ConfirmModalRedesign";
import NotificationsContainer from "../notifications-container";

/** 
 * Header subcomponent with buttons home, logout, chat. Common. Used only for all logged in user roles.
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: "0 auto",
      display: "flex",
      alignItems: "center",
    },
    icons: {
      margin: " auto 0 auto auto",
      "&>span": {
        display: "none",
        width: "fit-content",
        padding: "0 5px",
        background: " rgba(19, 19, 19, 0.5)",
        borderRadius: "2px",
        position: "absolute",
        color: "#ffffff",
        lineHeight: "1.8",
        bottom: "30px",
        right: "4%",
      },
    },
    messages: {
      position: "absolute",
      width: "29px",
      height: "19px",
      backgroundColor: "#309A90",
      borderRadius: "10px",
      color: "#ffffff",
      top: "5px",
      left: "25px",
      justifyContent: "center",
    },
    active: {
      "& svg path": {
        stroke: "#B0BFC6",
      },
    },
  })
);

type PropsType = {
  handleLogout?: any;
};

type ManagerChatData = {
  clientName?: string;
  chatId?: number;
};

const HeaderAuth: React.FC<PropsType> = (props) => {
  const classes = useStyles();
  const [openLogoutModal, setOpenLogoutModal] = useState(false);
  const [messagesCount, setMessagesCount] = useState(0);
  const [messages, setMessages] = useState<MessageNotification[]>([]);
  const [notificationsCount, setNotificationsCount] = useState(0);
  const [openChatModal, setOpenChatModal] = React.useState<boolean>(false);
  const [isChatOpen, setIsChatOpen] = useState<boolean>(false);
  const [managerChatData, setManagerChatData] =
    React.useState<ManagerChatData>();
  const [checked, setChecked] = useState(false);
  const dispatch = useDispatch();

  const initialUser = useSelector((state: rootState) => state.currentUser);

  const history = useHistory();
  const handleLogoutOpen = () => {
    setOpenLogoutModal(true);
  };

  const handleLogoutCancel = () => {
    setOpenLogoutModal(false);
  };

  const handleChatClose = () => {
    setOpenChatModal(false);
  };

  const openChat = () => {
    setIsChatOpen(true);
  };

  const closeChat = () => {
    setIsChatOpen(false)
    actions.cleanCurrentChatData();
  }

  useEffect(() => {
    if (initialUser.status === clientStatuses.NotCompleteRegistration) return;
    chatService
      .getChatsCountService()
      .then((res) => {
        setMessagesCount(res.count);
        if (res.newMessages) {
          setMessages(res.newMessages);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [checked, initialUser.status]);

  useEffect(() => {
    if (initialUser.status === clientStatuses.NotCompleteRegistration) return;
    const timer = setInterval(() => {
      setChecked(!checked);
    }, 30000);
    return () => timer && clearInterval(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialUser.status]);

  useEffect(() => {
    if (initialUser.status === clientStatuses.NotCompleteRegistration) return;
    initialUser.role !== ROLES.MANAGER &&
      notificationsService
        .getNotificationsCount()
        .then((res: { count: number }) => {
          setNotificationsCount(res.count);
        })
        .catch((error) => {
          console.log(error);
        });
  }, [checked, initialUser.role, initialUser.status]);

  const handleLogout = () => {
    dispatch(clearCart());
    authService
      .logout()
      .then((res: any) => {
        history.push(ROUTES.MAIN);
      })
      .catch((error: any) => {
        console.log(error);
      });
    setOpenLogoutModal(false);
    props.handleLogout && props.handleLogout();
  };

  const handleCloseChat = () => {
    setOpenChatModal(false);
  };

  const handleOpenChat = (
    isOpen: boolean,
    clientName: string,
    chatId: number
  ) => {
    setOpenChatModal(isOpen);
    setManagerChatData({
      chatId,
      clientName,
    });
  };

  return (
    <>
      <div className={classes.root}>
        {/* <AddInnModal /> */}
        {initialUser.role !== ROLES.MANAGER &&
          initialUser.role !== ROLES.PARTNER &&
          initialUser.role !== ROLES.ADMIN &&
          initialUser.status !== clientStatuses.NotCompleteRegistration && (
            <ButtonCustom color="text_button"
              handleClick={() => openChat()}
            >
              Чат с менеджером
            </ButtonCustom>
          )}
        <ListItemIcon className={classes.icons}>
          {initialUser.role !== ROLES.ADMIN &&
            initialUser.status !== clientStatuses.NotCompleteRegistration && (
              <Tooltip title="Сообщения" placement="bottom">
                <IconButton
                  onClick={() =>
                    history.push(
                      initialUser.role === ROLES.CLIENT
                        ? `${ROUTES.CLIENT}/messages`
                        : initialUser.role === ROLES.AGENT
                          ? `${ROUTES.AGENT}/messages`
                          : initialUser.role === ROLES.PARTNER
                            ? `${ROUTES.PARTNER}/messages`
                            : initialUser.role === ROLES.MANAGER
                              ? `${ROUTES.MANAGER}/messages`
                              : `${ROUTES.ADMIN}/messages`
                    )
                  }
                  style={{ position: "relative" }}
                  className={clsx({
                    [classes.active]:
                      history.location.pathname.indexOf("messages") !== -1,
                  })}
                >
                  <MessageIcon />
                  {messagesCount + notificationsCount > 0 && (
                    <Typography
                      variant="body2"
                      component="span"
                      className={classes.messages}
                    >
                      {messagesCount + notificationsCount}
                    </Typography>
                  )}
                </IconButton>
              </Tooltip>
            )}
          <a
            href={
              window.location.href.includes(DevStandApp) ||
                window.location.href.includes("localhost")
                ? DevStand
                : ProdStand
            }
          >
            <Tooltip title="На главную страницу" placement="bottom">
              <IconButton>
                <HomeIcon />
              </IconButton>
            </Tooltip>
          </a>

          <Tooltip title="Выход" placement="bottom">
            <IconButton onClick={handleLogoutOpen}>
              <LogoutIcon />
            </IconButton>
          </Tooltip>
        </ListItemIcon>
        <ConfirmModalRedesign
          handleConfirm={handleLogout}
          title="Покинуть профиль"
          text="Вы действительно хотите выйти из профиля?"
          handleCancel={handleLogoutCancel}
          confBtnText="Выйти"
          cancelBtnText="Остаться"
          open={openLogoutModal}
          setModalProps={setOpenLogoutModal}
        />
        {initialUser.role !== ROLES.MANAGER &&
          initialUser.role !== ROLES.ADMIN && (
            <ChatModal open={openChatModal} close={handleChatClose} />
          )}
        <NotificationsContainer
          notifications={messages}
          position="rightTop"
          isOpenChat={openChatModal}
          openChatModal={handleOpenChat}
        />
        {initialUser.role === ROLES.MANAGER &&
          managerChatData?.chatId &&
          managerChatData?.clientName && (
            <ChatModalManager
              open={openChatModal}
              close={handleCloseChat}
              currentChatId={String(managerChatData.chatId)}
              clientName={managerChatData.clientName}
            />
          )}
      </div>
      {isChatOpen &&
        <ChatModalNew
          chatWithManager
          open={isChatOpen}
          handleClose={closeChat} />
      }
    </>
  );
};

export default memo(HeaderAuth);
