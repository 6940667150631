import authApi, { ConfirmInnRDType, ConfirmRoleRDType } from "../api/authApi";
import userApi, { UtmBodyType } from "../api/userApi";
import { CookiesEnum, ROUTES, UTM_EVENT_ENUM } from "../consts/common";
import { actions, store } from "../store";
import { AuthState } from "../types/auth";
import { UserInfoType } from "../types/userTypes";
import introspectService from "./IntropsectService";
import { routeService } from "./RouteService";
import tokenService from "./TokenService";
import userService from "./UserService";

class AuthService {
  login(userName: string, password: string) {
    return authApi
      .authWidthEmail(userName, password)
      .then((data: any) => {
        tokenService.removeAccessToken();
        tokenService.removeRefreshToken();
        tokenService.setAccessToken(data.access_token);
        tokenService.setRefreshToken(data.refresh_token);
        actions.fetchAuth({
          ...data,
          isAuth: true,
          currentId: tokenService.parseJwt(data.access_token).nameid,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  logout() {
    return authApi
      .logout()
      .then((data: any) => {
        tokenService.removeRefreshToken();
        tokenService.removeAccessToken();
        actions.setCurrentUser({
          isAuth: false,
          inn: "",
          userId: "",
          phone: "",
          role: "",
          companyName: "",
          manager: null,
          nameFull: "",
          email: "",
          is_manager_approval: undefined,
          status: undefined,
        });
        return data;
      })
      .catch((error) => {
        console.log(error);
        tokenService.removeAccessToken();
        tokenService.removeRefreshToken();
        actions.setCurrentUser({
          isAuth: false,
          inn: "",
          userId: "",
          phone: "",
          role: "",
          companyName: "",
          manager: null,
          nameFull: "",
          email: "",
          is_manager_approval: undefined,
          status: undefined,
        });
      });
  }

  refreshLogin() {
    return authApi
      .refreshAuth()
      .then((data: any) => {
        tokenService.removeRefreshToken();
        tokenService.setRefreshToken(data.refresh_token);
        tokenService.removeAccessToken();
        tokenService.setAccessToken(data.access_token);
        actions.fetchAuth({ ...data });
        return userService
          .getUserInfoService(tokenService.parseJwt(data.access_token).nameid)
          .then((res: UserInfoType) => {
            actions.setCurrentUser({
              isAuth: true,
              inn: res.inn,
              userId: tokenService.parseJwt(data.access_token).nameid,
              phone: res.phone,
              role: res.roles.role_name,
              companyName: res.companyName,
              manager: res.manager ? res.manager : null,
              nameFull: `${res.name} ${res.last_name}`,
              email: res.email,
              is_manager_approval: res.is_manager_approval,
              status: res.status,
            });
            return res.roles.role_name;
          });
      })
      .catch((error) => {
        introspectService.checkToken(error.response.status.toString());
        routeService.push(ROUTES.MAIN);
      });
  }

  // подтверждение кода авторизации /auth/confirmV2
  confirmCode(isPhoneCheck: boolean, userId: string, code: string) {
    const followingLinkEventId = tokenService._getCookie(
      CookiesEnum.followingLinkEventId
    );
    return authApi
      .confirmCode(isPhoneCheck, userId, code, followingLinkEventId)
      .then((response: AuthState) => {
        if (response?.access_token) {
          tokenService.removeAccessToken();
          tokenService.removeRefreshToken();
          tokenService.setRefreshToken(response.refresh_token);
          tokenService.setAccessToken(response.access_token);
          tokenService._setCookie(CookiesEnum.role, response.addInfo.role, 1);
          actions.fetchAuth({ ...response });
          return userService
            .getUserInfoService(userId)
            .then((res: UserInfoType) => {
              actions.setCurrentUser({
                isAuth: true,
                inn: res.inn,
                userId: tokenService.parseJwt(response.access_token).nameid,
                phone: res.phone,
                role: res.roles.role_name,
                companyName: res.companyName,
                manager: res.manager ? res.manager : null,
                nameFull: `${res.name} ${res.last_name}`,
                email: res.email,
                is_manager_approval: res.is_manager_approval,
                status: res.status,
              });
              actions.setAuthInfo({
                code: response.addInfo.code,
                hasInn: response.addInfo.hasInn,
                isPhoneCheck: response.addInfo.isPhoneCheck,
                userType: response.addInfo.userType,
              });
              localStorage.setItem(CookiesEnum.code, response.addInfo.code);
              tokenService._setCookie(CookiesEnum.name, res.name, 1);
              tokenService._setCookie(CookiesEnum.last_name, res.last_name, 1);
              return res.roles.role_name;
            });
        } else {
          actions.setAuthInfo({
            code: response.addInfo.code,
            hasInn: response.addInfo.hasInn,
            isPhoneCheck: response.addInfo.isPhoneCheck,
            userType: response.addInfo.userType,
          });
          localStorage.setItem(CookiesEnum.code, response?.addInfo?.code ?? "");

          return "NotInn";
        }
      });
  }

  // registerV2 выбор роли, на этом этапе должен вернуться токен и прокинуть в кабинет
  confirmRole(rd: ConfirmRoleRDType) {
    return authApi.confirmRole(rd).then((response: AuthState) => {
      if (response?.access_token) {
        tokenService.removeAccessToken();
        tokenService.removeRefreshToken();
        tokenService.setRefreshToken(response.refresh_token);
        tokenService.setAccessToken(response.access_token);
        actions.fetchAuth({ ...response });
        tokenService._setCookie(CookiesEnum.role, response.addInfo.role, 1);

        return userService
          .getUserInfoService(rd.userId)
          .then((res: UserInfoType) => {
            actions.setCurrentUser({
              isAuth: true,
              inn: res.inn,
              userId: tokenService.parseJwt(response.access_token).nameid,
              phone: res.phone,
              role: res.roles.role_name,
              manager: res.manager ? res.manager : null,
              nameFull: `${res.name} ${res.last_name}`,
              email: res.email,
              is_manager_approval: res.is_manager_approval,
              status: res.status,
            });
            actions.setAuthInfo({
              code: response.addInfo.code,
              hasInn: response.addInfo.hasInn,
              isPhoneCheck: response.addInfo.isPhoneCheck,
              userType: response.addInfo.userType,
            });
            localStorage.setItem(CookiesEnum.code, response.addInfo.code);
            tokenService._setCookie(CookiesEnum.name, res.name, 1);
            tokenService._setCookie(CookiesEnum.last_name, res.last_name, 1);
            return res.roles.role_name;
          });
      } else {
        return "NotInn";
      }
    });
  }

  // auth/confirmInnV2 заполнение доп информации и завершение регистрации
  confirmInn(rd: ConfirmInnRDType) {
    return authApi.confirmInn(rd).then((data: AuthState) => {
      if (data.addInfo.hasInn) {
        tokenService.removeAccessToken();
        tokenService.removeRefreshToken();
        tokenService.setRefreshToken(data.refresh_token);
        tokenService.setAccessToken(data.access_token);
        actions.fetchAuth({ ...data });

        return userService
          .getUserInfoService(rd.userId)
          .then((res: UserInfoType) => {
            actions.setCurrentUser({
              isAuth: true,
              inn: res.inn,
              userId: tokenService.parseJwt(data.access_token).nameid,
              phone: res.phone,
              role: res.roles.role_name,
              companyName: res.companyName,
              manager: res.manager ? res.manager : null,
              nameFull: `${res.name} ${res.last_name}`,
              email: res.email,
              is_manager_approval: res.is_manager_approval,
              status: res.status,
            });
            tokenService._setCookie(CookiesEnum.name, res.name, 1);
            tokenService._setCookie(CookiesEnum.last_name, res.last_name, 1);
            return res.roles.role_name;
          });
      } else {
        return "NotInn";
      }
    });
  }

  keyGenerateService(B: { inn: string }): Promise<{
    userId: string;
    key: number;
  }> {
    return authApi.keyGenerate(B);
  }

  signatureCheckService(B: { userId: string; sign: string }): Promise<any> {
    return authApi.signatureCheck(B).then((data: any) => {
      tokenService.removeAccessToken();
      tokenService.removeRefreshToken();
      tokenService.setRefreshToken(data.refresh_token);
      tokenService.setAccessToken(data.access_token);
      actions.fetchAuth({ ...data });
      return userService
        .getUserInfoService(B.userId)
        .then((res: UserInfoType) => {
          actions.setCurrentUser({
            isAuth: true,
            inn: res.inn,
            userId: tokenService.parseJwt(data.access_token).nameid,
            phone: res.phone,
            role: res.roles.role_name,
            manager: res.manager ? res.manager : null,
            nameFull: `${res.name} ${res.last_name}`,
            email: res.email,
            is_manager_approval: res.is_manager_approval,
            status: res.status,
          });
          return res.roles.role_name;
        });
    });
  }
}

const authService = new AuthService();

export default authService;
