import React from "react";
import InputMain from "../../../../../UIcomponentsRedesign/InputMain/InputMain";
import { InputTypeEnum } from "../../../../../core/consts/redesign/commonRedesign";
import { useAppThunkDispatch } from "../../../../../core/store";
import { handleAutoFillFields } from "../../../../../core/store/thunk/newOrder";
import { formatters } from "../../../../../core/utils/inputTypeRules";
import withFormHocs from "../../../../new-order/hoc/withFormHocs";
import { PropsField } from "../../../../new-order/types/bigForm";

type Props = PropsField & {
  value?: string;
  info?: string;
  sumText?: string;
  regex?: string;
  inputType?: InputTypeEnum;
};

/**onChange и value должны быть обязательно */
const StringField: React.FC<Props> = ({
  field,
  propsalId,
  value,
  text,
  info,
  sumText,
  error,
  onChange,
  parentName,
  indexGroupField,
  isVisible,
  classNameShowIf,
  inputType,
  handleSaveDraftOnBlur,
}) => {
  const dispatch = useAppThunkDispatch();

  const hasShowIf = field.show_if.length > 0;

  const onChangeValueInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputType = field.inputType;
    const value = e.target.value;
    const formatter = formatters[inputType as InputTypeEnum];
    const formattedValue = formatter ? formatter(value, inputType) : value;

    e.target.value = formattedValue; //to display modified value

    if (onChange) {
      onChange(
        field.name,
        e.target.value,
        propsalId,
        parentName && parentName,
        indexGroupField && indexGroupField
      );
    }
  };

  const handleOnBlur = () => {
    if (field.autoFillUrl && value !== undefined && value.length > 0) {
      dispatch(
        handleAutoFillFields(
          field.autoFillUrl,
          value,
          propsalId,
          field.name,
          parentName,
          indexGroupField
        )
      );
    }
    //delay to fill autofill fields before validation
    if (handleSaveDraftOnBlur) {
      setTimeout(() => {
        handleSaveDraftOnBlur();
      }, 500)
    }
  };

  return (
    <>
      {field.upSeparator && (hasShowIf ? isVisible : true) && <div className="line_x" />}
      <InputMain
        label={text}
        value={value ?? ""}
        info={info}
        sumText={sumText}
        inputType={inputType}
        onChange={onChangeValueInput}
        isFullWidth={true}
        onBlur={handleOnBlur}
        disabled={field.isClientBlock}
        isRequired={field.isRequeired}
        error={error}
        className={classNameShowIf ? classNameShowIf : ""}
      />
      {field.downSeparator && (hasShowIf ? isVisible : true) && <div className="line_x" />}
    </>
  );
};

export default withFormHocs(React.memo(StringField));
