import { makeStyles, createStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: "20px",
      borderRadius: "10px",
    },
    search: {
      boxSizing: "border-box",
      margin: "22px 22px 12px",
      "& div::before": {
        display: "none",
      },
      "& div::after": {
        display: "none",
      },
      "& input": {
        paddingBottom: "15px",
        width: "80%",
        height: "100%",
      },
    },
    grid: {
      backgroundColor: "#ffffff",
      borderRadius: "10px",
      width: "100%",
      height: "100%",
      minHeight: "calc(100vh - 285px)",
      margin: "0 !important",
      justifyContent: "space-between",
    },
    gridItem: {
      backgroundColor: "#ffffff",
      flexBasis: "49%",
      height: "100%",
    },
    gridItemChat: {
      flexBasis: "49%",
      // '&>div': {
      //     height: '100%',
      //     minHeight: 'calc(100vh - 315px)',
      // }
    },
    messageInfoWrap: {
      flexBasis: "80%",
    },
    messageInfoSubWrap: {
      maxWidth: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start"
    },
    messageInfo: {
      fontSize: "10px",
      minWidth: "fit-content",
      position: "absolute",
      top: "10%",
      right: "5%",
    },
    messageSender: {     
      paddingRight: "5px",
      color: "#B0BFC6",
      wordBreak: "break-all",
      width: "fit-content",
      overflow: "hidden",      
    },
    message: {
      wordBreak: "break-all",
      width: "fit-content",
      overflow: "hidden",
      height: "18px",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      display: "inline-block",
      overflowWrap: "anywhere",
    },
    messagesCount: {
      width: "fit-content",
      padding: "2px 8px",
      backgroundColor: "#309A90",
      borderRadius: "10px",
      color: "#ffffff",
      marginRight: "10px",
      justifyContent: "center",
    },
    boxSender: {
      padding: "20px",
      display: "flex",
      alignItems: "center",
      position: "relative",
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "#F2F2F2",
      },
    },
    boxSenderGrey: {
      position: "relative",
      backgroundColor: "#F2F2F2",
    },
    messageText: {
      maxWidth: "80%",
      padding: "25px",
      borderRadius: "5px",
      backgroundColor: "#F2F2F2",
    },
    messagesBox: {
      maxHeight: "50vh",
      overflowY: "scroll",
      scrollbarWidth: "thin",
      "&::-webkit-scrollbar": {
        width: "0.2em",
        height: "0.52em",
      },
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(208 217 222 / 88%)",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#D0D9DE",
        outline: "0.2px solid #D0D9DE",
      },
    },
    partnerChatsBoxHidden: {
      position: "absolute",
      visibility: "hidden",
      top: "0",
      left: "0",
      zIndex: 0,
      height: "0",
    },
    partnerChatsBoxVisible: {
        position: "static",
        visibility: "visible",
        
    },
    chatsBox: {
      maxHeight: "50vh",
      overflowY: "scroll",
      scrollbarWidth: "thin",
      "&::-webkit-scrollbar": {
        width: "0.2em",
        height: "0.52em",
      },
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(208 217 222 / 88%)",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#D0D9DE",
        outline: "0.2px solid #D0D9DE",
      },
    },
    messageBox: {
      display: "flex",
      alignItems: "end",
      justifyContent: "flex-end",

      margin: "7px 5px 7px 0",
    },
  })
);
