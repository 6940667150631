import { makeStyles, Typography } from "@material-ui/core";
import React, { memo } from "react";
import AvatarIcon from "../../../core/consts/images/avatarInner.png";
import { COLORS } from "../../../core/consts/redesign/commonRedesign";

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  avatar: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    objectFit: "cover",
  },
  userName: {
    width: "40px",
    height: "40px",
    backgroundColor: COLORS.warning,
    color: COLORS.white,
    textTransform: "uppercase",
    borderRadius: "50%",
    lineHeight: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "16px",
    overflow: "hidden",
  },
}));

type PropsType = {
  info?: string;
  url?: string;
  currentChatName?: string;
  style?: React.CSSProperties;
};

const AvatarNew: React.FC<PropsType> = ({ info, url, currentChatName, style }) => {
  const classes = useStyles();
  const initials = info && info.split(' ').filter(Boolean).slice(0, 2).map(name => name[0]).join('');

  return (
    <div style={{ ...style }} className={classes.container}>
      {url ? (
        <img
          className={classes.avatar}
          alt={currentChatName || "profile avatar"}
          src={url}
        />
      ) : (
        <Typography variant="h3" className={classes.userName}>
          {initials || <img alt={currentChatName || "profile avatar"} src={AvatarIcon} />}
        </Typography>
      )}
    </div>
  );
};

export default memo(AvatarNew);
