import { Theme, makeStyles } from "@material-ui/core";
import { COLORS } from "../../../core/consts/redesign/commonRedesign";

export const useStyles = makeStyles((theme: Theme) => ({
  ////note card///
  noteBox: {
    padding: '24px 16px',
    margin: "4px auto",
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    gap: '24px',
    background: COLORS.white,
    borderRadius: '8px'
  },
  reminderColumn: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    gap: '4px',
    alignItems: "center",
    justifyContent: "flex-start",
    [theme.breakpoints.down(1024)]: {
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "center",
    },
  },
  reminderRow: {
    display: "flex",
    flexDirection: "row",
    // width: "100%",
    gap: '8px',
    alignItems: "center",
    justifyContent: "flex-start",
  },
  reminderTitle: {
    fontFamily: 'Gilroy',
    fontStyle: 'normal',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '21px',
    color: COLORS.black,
  },
  noteText: {
    fontFamily: 'Gilroy',
    fontStyle: 'normal',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '21px',
    color: COLORS.black,
  },
  buttonBox: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    alignItems: "flex-start",
    justifyContent: "space-between",
    [theme.breakpoints.down(1024)]: {
      flexDirection: "column",
      gap: '24px',
    },
  },
  buttons: {
    display: "flex",
    flexDirection: "row",
    gap: '24px',
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  dateBox: {
    fontFamily: 'Gilroy',
    fontStyle: 'normal',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '21px',
    color: COLORS.light_gray_stroke,
  },

  /////note create box///
  createBox: {
    padding: '16px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    gap: '16px',
    background: COLORS.white,
    borderRadius: '8px',
    // [theme.breakpoints.down(1024)]: {
    //   overflowY: 'auto',
    //   maxHeight: '100vh',
    // },
  },
  buttonsCreate: {
    display: "flex",
    width: '100%',
    flexDirection: "row",
    gap: '8px',
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  dateRow: {
    display: "flex",
    width: '100%',
    flexDirection: "row",
    gap: '16px',
    alignItems: "flex-start",
    justifyContent: "flex-start",
    [theme.breakpoints.down(1024)]: {
      flexDirection: "column",
    },
  },
  dateRowSmall: {
    display: "flex",
    width: '100%',
    flexDirection: "row",
    gap: '16px',
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  timeRow: {
    display: "flex",
    width: '50%',
    flexDirection: "row",
    gap: '6px',
    alignItems: "center",
    justifyContent: "flex-start",
    [theme.breakpoints.down(1024)]: {
      width: '100%',
    },
  },
  daysBox: {
    display: "flex",
    flexWrap: 'wrap',
    width: '100%',
    flexDirection: "row",
    gap: '24px',
    alignItems: "center",
    justifyContent: "flex-start",
    padding: '16px 0'
  },
  dayCheckboxes: {
    display: "flex",
    flexDirection: "row",
    gap: '8px',
    alignItems: "center",
    justifyContent: "flex-start",
  },
  dayCheckbox: {
    width: '24px',
    height: '24px'
  },
  dayCheckboxText: {
    fontFamily: 'Gilroy',
    fontStyle: 'normal',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '21px',
    color: COLORS.black
  },
  weekend: {
    color: COLORS.alert
  },

  /////note create modal
  paper: {
    outline: 'none',
    position: "absolute",
    width: "800px",
    top: "47%",
    left: "calc(50% + 60px)",
    transform: "translate(-50%, -50%)",
    backgroundColor: COLORS.white,
    border: "none",
    borderRadius: "8px",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    maxHeight: '100vh',
    [theme.breakpoints.down(1024)]: {
      left: "50%",
      overflowY: 'auto',
    },
    [theme.breakpoints.down(760)]: {
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      transform: "translateX(0%)",
      margin: 0,
      borderRadius: 0
    }
  },
  titleBox: {
    position: 'relative',
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    margin: '22px 0',
    padding: "0px 16px",
    [theme.breakpoints.down(1024)]: {
      padding: "0px"
    },
  },
  box: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: 'center',
    width: "100%",
    position: "relative",
  },
  closeButton: {
    width: "24px",
    height: "24px",
    position: "absolute",
    right: "8px",
    [theme.breakpoints.down(1024)]: {
      padding: "0px",
      position: 'absolute',
      left: "8px",
      top: "50%",
      transform: 'translateY(-50%)',
    },
  },
  title: {
    fontFamily: 'Gilroy',
    fontSize: '21px',
    fontWeight: 500,
    lineHeight: '28px',
    color: COLORS.black,
    [theme.breakpoints.down(1024)]: {
      flex: 1,
      textAlign: 'center',
      marginLeft: '32px',
      whiteSpace: 'pre-wrap',
    },
  }
}));
