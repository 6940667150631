import { makeStyles, createStyles, Theme } from "@material-ui/core";

export const useStylesClientInfoModal = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      maxWidth: "100%",
      [theme.breakpoints.down(780)]: {
        "& div": {
          "& .MuiDialog-paperWidthFalse": {
            width: "100%",
            maxWidth: "100%",
            padding: "0",
            margin: "0",
            height: "100vh",
          },
        },
        "&:nth-child(3n)>div": {
          maxWidth: "100%",
          "&>div": {
            width: "100%",
            maxWidth: "100%",
            padding: "0",
            margin: "0",
            height: "100vh",
          },
        },
      },
    },
    paper: {
      display: "block",
      width: "780px",
      backgroundColor: theme.palette.background.paper,
      borderRadius: "10px",
      height: "fit-content",
      padding: "50px 75px",
      [theme.breakpoints.down(780)]: {
        borderRadius: "0",
        maxWidth: "100%",
      },
      [theme.breakpoints.down(400)]: {
        padding: "35px 16px",
      },
    },
    box: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "baseline",
      width: "100%",
    },
    title: {
      padding: "38px 30px ",
    },
    gridItems: {
      display: "flex",
      width: "100%",
      padding: "7.5px 0",
      flexWrap: "wrap",
    },
    gridItem: {
      display: "flex",
      flexDirection: "column",
      padding: "7.5px 0",
    },
    gridTitle: {
      display: "flex",
      flexDirection: "column",
      padding: "7.5px 0",
    },
    passportText: {
      maxWidth: "550px",
    },
    fileBox: {
      width: "100%",
    },
    downloadFileTitle: {
      paddingTop: "30px",
    },
    agentBox: {
      display: "flex",
      flexDirection: "column",
    },
    agentWrap: {
      display: "flex",
      justifyContent: "space-between",
    },
    checkBox: {
      display: "flex",
    },
    dividerAboveComments: {
      margin: "40px 0 30px 0",
    },
    btn: {
      "&:hover": {
        background: "none",
      },
    },
    editLink: {
      color: "#306F9A",
      fontWeight: 600,
      cursor: "pointer",
    },
  })
);
