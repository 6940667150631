import notificationsApi, { notificationsListType } from "../api/notificationsApi";
import introspectService from './IntropsectService';

class NotificationService {

    //all notifications
    getNotificationsCount(): Promise<{ count: number }> {
        return notificationsApi.getNotificationsCount()
            .then((res: any) => {
                return res
            })
            .catch(error => {
                introspectService.checkToken(error.response.status.toString())
            })
    }


    getNotificationsListService(Q: { count?: number, offset?: number, onlyUnread?: boolean }): Promise<notificationsListType> {
        return notificationsApi.getNotificationsList(Q)
            .then((res: any) => {
                return res
            })
            .catch(error => {
                introspectService.checkToken(error.response.status.toString())
            })
    }

    readNotification(id: string) {
        return notificationsApi.readNotification(id)
            .then((res: any) => {
                return res
            })
            .catch(error => {
                introspectService.checkToken(error.response.status.toString())
            })
    }
}

const notificationsService = new NotificationService()
export default notificationsService
