import {
  FormControlLabel,
  Theme,
  createStyles,
  withStyles
} from "@material-ui/core";
import Switch, { SwitchClassKey, SwitchProps } from '@material-ui/core/Switch';
import React from "react";
import InfoPopover from "../../../../../app/components/info-popover/InfoPopover";
import { COLORS } from "../../../../../core/consts/redesign/commonRedesign";
import { useAppThunkDispatch } from "../../../../../core/store";
import { handleAutoFillFields } from "../../../../../core/store/thunk/newOrder";
import withFormHocs from "../../../../new-order/hoc/withFormHocs";
import { PropsField } from "../../../../new-order/types/bigForm";
interface Styles extends Partial<Record<SwitchClassKey, string>> {
  focusVisible?: string;
}

interface SwitchIosProps extends SwitchProps {
  classes: Styles;
}
const IOSSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      boxSizing: 'border-box',
      width: '40px',
      height: '26px',
      padding: 0,
      margin: '8px',
      display: 'flex',
      alignItems: 'center',
      '&:hover': {
        opacity: 0.9
      },
    },
    switchBase: {
      padding: '4px',
      color: COLORS.gray2,
      top: '1px',
      '&$checked': {
        transform: 'translateX(16px)',
        color: COLORS.white,
        '& + $track': {
          backgroundColor: COLORS.brand_blue,
          border: `2px solid ${COLORS.brand_blue}`,
          opacity: 1,
        },
      },
    },
    thumb: {
      width: '16px',
      height: '16px',
      boxShadow: 'none',
    },
    track: {
      borderRadius: 26 / 2,
      border: `2px solid ${COLORS.gray2}`,
      backgroundColor: COLORS.white,
      opacity: 1,
      height: 'calc(100% - 4px)',
      transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
  }),
)(({ classes, ...props }: SwitchIosProps) => {
  return (
    <Switch
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});


type Props = Omit<PropsField, "text"> & {
  value?: boolean;
  info?: string;
};

const BooleanField: React.FC<Props> = ({
  field,
  propsalId,
  value,
  info,
  error,
  onChange,
  parentName,
  indexGroupField,
  classNameShowIf,
  isVisible,
  errorClassName,
  handleSaveDraftOnBlur,
}) => {
  const dispatch = useAppThunkDispatch();
  const hasShowIf = field.show_if.length > 0;
  const onChangeValue = (checked: boolean) => {
    if (onChange) {
      onChange(
        field.name,
        checked,
        propsalId,
        parentName && parentName,
        indexGroupField && indexGroupField
      );
    }
  };

  const handleOnChange = (event: React.ChangeEvent<{}>, checked: boolean) => {
    onChangeValue(checked);
  };

  const handleOnBlur = () => {
    if (field.autoFillUrl && value !== undefined) {
      dispatch(
        handleAutoFillFields(
          field.autoFillUrl,
          value,
          propsalId,
          field.name,
          parentName,
          indexGroupField
        )
      );
    }
    //delay to fill autofill fields before validation
    if (handleSaveDraftOnBlur) {
      setTimeout(() => {
        handleSaveDraftOnBlur();
      }, 500)
    }
  };

  return (
    <>
      {field.upSeparator && (hasShowIf ? isVisible : true) && <div className="line_x" />}
      <div id={field.text} style={{ marginLeft: '4px', width: '100%' }} className={classNameShowIf}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
          <FormControlLabel
            disabled={field.isClientBlock}
            control={
              <IOSSwitch
                checked={value}
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                color="primary"
              />
            }
            label={
              <span
                style={{
                  fontSize: "16px",
                  lineHeight: "21px",
                  overflow: "hidden",
                  margin: 0,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {field.text}
              </span>
            }
            labelPlacement="end"
          />
          {field.text && info && <InfoPopover id={field.text} text={field.text} info={info} />}
        </div>
        {error && error?.length && <div style={{ color: COLORS.alert }} className={errorClassName}>{error}</div>}
      </div>
      {field.downSeparator && (hasShowIf ? isVisible : true) && <div className="line_x" />}
    </>
  );
};

export default withFormHocs(React.memo(BooleanField));
