import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  TextField,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import { useFormik } from "formik";
import React, { memo, useState } from "react";
import NumberFormat from "react-number-format";
import * as Yup from "yup";
import AgreementModal from "../../../agreement-modal";
import { FormTypeClient } from "../../RegistrationDetails";
import { RegisterFormCommon } from "../../consts";

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      inputBox: {
        display: "flex",
        flexDirection: "column",
        marginTop: "25px",
        maxWidth: "100%",
      },
      iconCheckRadio: {
        "& svg": {
          fontSize: "24px",
        },
      },
      submit: {
        marginTop: "35px",
        width: "300px",
        [theme.breakpoints.down(400)]: {
          maxWidth: "100%",
        },
      },
      textField: {
        width: "300px",
        height: "51px",
        fontFamily: "Gilroy",
        fontWeight: 500,
        marginBottom: "25px",
        [theme.breakpoints.down(400)]: {
          maxWidth: "100%",
        },
      },
      offerta: {
        color: "#306F9A",
        "&:hover": {
          cursor: "pointer",
          textDecoration: "underline",
        },
      },
      offertaWrap: {
        width: "250px",
        display: "block",
        color: '#131313',
        fontSize: '12px',
        fontFamily: "Gilroy",
        fontWeight: 500,
        [theme.breakpoints.down(400)]: {
          maxWidth: "calc(100% - 23px)",
        },
      },
    }),
  { index: 1 }
);

type BadRequestType = {
  phone: string;
};

type Props = {
  handleSubmit: (
    data: FormTypeClient,
    setBadRequestFields: (
      data: BadRequestType | ((data: BadRequestType) => BadRequestType)
    ) => void
  ) => void;
  isFetching: boolean;
};
const RegistrationClient: React.FC<Props> = ({ handleSubmit, isFetching }) => {
  const classes = useStyles();
  const [message, setMessage] = useState<string>("");
  const [checkedSubmit, setCheckedSubmit] = useState<boolean>(false);
  const [agreementOpen, setAgreementOpen] = useState(false);
  const [phone, setPhone] = useState({ number: "" });
  const [badRequestFields, setBadRequestFields] = useState<BadRequestType>({
    phone: "",
  });
  const clickId = localStorage.getItem("cid");
  const cid = clickId ? { cid: clickId } : {};

  const userId = localStorage.getItem("uid");
  const uid = userId ? { c2MUserId: userId } : {};

  const formik = useFormik({
    initialValues: {
      phone: "",
    },
    validationSchema: Yup.object({
    }),
    onSubmit: (values) => {
      if (checkedSubmit) {
        const val = {
          phone: phone.number,
          ...cid,
          ...uid
        };
        setMessage("");
        handleSubmit(val, setBadRequestFields);
      } else {
        setMessage("Подтвердите согласие на обработку персональных данных");
      }
    },
  });

  const isDisableBtn = () => {
    if (
      phone.number &&
      checkedSubmit
    ) {
      return false;
    } else return true;
  };

  const handleCheckedSubmit = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedSubmit(event.target.checked);
  };

  const handleAgreementModal = () => {
    setAgreementOpen(!agreementOpen);
  };

  const handleChangePhone = (event: React.ChangeEvent<HTMLInputElement>) => {
    let localPhoneNumber = event.target.value;
    const phoneNumberLength = localPhoneNumber.replaceAll(" ", "").length;
    if (phoneNumberLength === 16 && localPhoneNumber[1] !== "7") {
      localPhoneNumber =
        "+7" + localPhoneNumber.substring(2, localPhoneNumber.length);
    } else if (localPhoneNumber[1] !== "7" && localPhoneNumber !== "") {
      localPhoneNumber =
        "+7" + localPhoneNumber.substring(1, localPhoneNumber.length);
    } else if (localPhoneNumber === "") {
      localPhoneNumber = "";
    }
    setPhone({ number: localPhoneNumber });
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className={classes.inputBox}>
        <NumberFormat
          id="phone"
          label={
            <Typography variant="body1" style={{ color: "#828282" }}>
              {RegisterFormCommon.phoneLabel}
            </Typography>
          }
          customInput={TextField}
          type="tel"
          format="+# (###) ###-##-##"
          placeholder={RegisterFormCommon.phone}
          className={classes.textField}
          value={phone.number}
          error={Boolean(badRequestFields.phone)}
          helperText={badRequestFields.phone}
          onChange={handleChangePhone}
          inputMode="numeric"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <Box>
          <FormControlLabel
            style={{
              boxSizing: "border-box",
              maxWidth: "100%",
              padding: 0,
              alignItems: "flex-start",
            }}
            control={
              <Checkbox
                id="offerta"
                checked={checkedSubmit}
                onChange={handleCheckedSubmit}
                color="primary"
                className={classes.iconCheckRadio}
              />
            }
            label={
              <Typography
                variant="body2"
                component="span"
                style={{ fontSize: "12px", marginTop: '5px' }}
                className={classes.offertaWrap}
              >
                Я даю своё {' '}
                <span
                  className={classes.offerta}
                  onClick={handleAgreementModal}
                >
                  согласие на обработку персональных данных
                </span>
              </Typography>
            }
          />
        </Box>
        <Typography variant="body1" component="p" style={{ color: "red" }}>
          {message}
        </Typography>
        {isFetching ? (
          <div
            style={{
              marginTop: "35px",
              display: "flex",
              justifyContent: "center",
              width: "300px",
            }}
          >
            {" "}
            <CircularProgress />{" "}
          </div>
        ) : (
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={isDisableBtn()}
            className={classes.submit}
            style={isDisableBtn() ? { backgroundColor: "#E0E0E0" } : {}}
          >
            <Typography
              component="span"
              variant="body1"
              style={{
                color: "#ffffff",
                textTransform: "none",
                lineHeight: "3",
              }}
            >
              Получить код
            </Typography>
          </Button>
        )}
      </div>
      <AgreementModal
        isOpen={agreementOpen}
        clientType="client"
        handleClose={handleAgreementModal}
      />
    </form>
  );
};

export default memo(RegistrationClient);
