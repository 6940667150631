import React, { memo } from "react";
import {
  makeStyles,
  createStyles,
  Theme,
  Grid,
  Typography,
  Box,
} from "@material-ui/core";
import { PartnerLeadType } from "../../../../core/types/partnerApiTypes";
import clsx from "clsx";
import { useHistory } from "react-router";
import { ROUTES } from "../../../../core/consts/common";
import partnerService from "../../../../core/services/PartnerService";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: "relative",
      marginTop: "20px",
      padding: "30px",
      borderRadius: "10px",
      backgroundColor: "#ffffff",
      "& div>div>p>span": {
        opacity: 0,
      },
      "&:hover": {
        cursor: "pointer",
        "& div>div>p>span": {
          opacity: 1,
        },
        "& svg": {
          "& path": {
            stroke: "#306F9A",
          },
        },
      },
    },
    grid: {
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
      alignItems: "flex-start",
    },
    gridItem: {
      display: "flex",
      flexDirection: "column",
    },
    title: {
      color: "#617E8C",
      paddingBottom: "5px",
    },
    link: {
      margin: "auto 0",
    },
    newLead: {
      padding: "2px 8px",
      borderRadius: "10px",
      color: "#ffffff",
      backgroundColor: "#309A90",
      position: "absolute",
      bottom: "92%",
      left: "30px",
    },
  })
);

const LeadCard: React.FC<PartnerLeadType> = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const strToData = (strDate: string): string => {
    let date = new Date(strDate);
    return date.toLocaleDateString("ru-RU");
  };

  const handleRouteClick = () => {
    history.push(`${ROUTES.PARTNER}/application/0/${props.id}`);
    partnerService
      .takeOffLeadFlagNewService(props.id)
      .then((res) => { })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Box className={classes.paper} onClick={handleRouteClick}>
      {props.isNew && (
        <Typography
          variant="body2"
          component="span"
          className={classes.newLead}
        >
          Новая заявка
        </Typography>
      )}
      <Grid className={classes.grid}>
        <Grid item className={classes.gridItem} xl={1} lg={1} md={1}>
          <Typography
            variant="body2"
            component="span"
            className={classes.title}
          >
            Продукт
          </Typography>
          <Typography variant="body1" component="span">
            {props.product.name}
          </Typography>
        </Grid>
        <Grid item className={classes.gridItem} xl={2} lg={2} md={2}>
          <Typography
            variant="body2"
            component="span"
            className={classes.title}
          >
            Вид продукта
          </Typography>
          <Typography variant="body1" component="span">
            {props.proposal.title}
          </Typography>
        </Grid>
        <Grid item className={classes.gridItem} xl={1} lg={1} md={1}>
          <Typography
            variant="body2"
            component="span"
            className={classes.title}
          >
            Сумма
          </Typography>
          <Typography variant="body1" component="span">
            {props.sum}
          </Typography>
        </Grid>
        <Grid item className={classes.gridItem} xl={3} lg={3} md={3}>
          <Typography
            variant="body2"
            component="span"
            className={classes.title}
          >
            Клиент
          </Typography>
          <Typography variant="body1" component="span">
            {props.companyName}
          </Typography>
        </Grid>
        <Grid item className={classes.gridItem} xl={2} lg={2} md={2}>
          <Typography
            variant="body2"
            component="span"
            className={classes.title}
          >
            Дата
          </Typography>
          <Typography variant="body1" component="span">
            {strToData(props.createDate)}
          </Typography>
        </Grid>
        <Grid item className={classes.gridItem} xl={2} lg={2} md={2}>
          <Typography
            variant="body2"
            component="span"
            className={classes.title}
          >
            Статус
          </Typography>
          <Typography style={{
            background: props.statusColor ? `#${props.statusColor}` : "#70B2E2", color: props.statusFontColor ? `#${props.statusFontColor}` : 'white', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '4px', padding: '3px 6px', maxWidth: '80%', width: 'fit-content',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }} variant="body1" component="span">
            {props.status}
          </Typography>
        </Grid>
        <Grid
          item
          className={clsx(classes.gridItem, classes.link)}
          xl={1}
          lg={1}
          md={1}
          style={{ textAlign: "right" }}
        >
          <Typography variant="h3" component="p">
            <Typography
              variant="body1"
              component="span"
              style={{ color: "#306F9A" }}
            >
              Перейти&nbsp;&nbsp;
            </Typography>
            <svg
              width="8"
              height="14"
              viewBox="0 0 8 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 13L7 7L0.999999 1"
                stroke="#131313"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default memo(LeadCard);
