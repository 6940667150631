import referenceApi from "../api/referenceApi";

class ReferenceService {
  getListService(data: string) {
    return referenceApi
      .getList(data)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        console.log(error);
      });
  }
  getOptionsService(key: string) {
    return referenceApi
      .getOptions(key)
      .then((response) => {
        return response;
      })
  }
  getRegionsListService() {
    return referenceApi
      .getRegionsList()
      .then((res) => {
        return res;
      })
      .catch((error) => {
        console.log(error);
      });
  }
  getCitiesListService(code: string) {
    return referenceApi
      .getCitiesList(code)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        console.log(error);
      });
  }
  getCitiesVTBListService(code: string) {
    return referenceApi
      .getCitiesVTBList(code)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        console.log(error);
      });
  }
  getGarantTypeListService() {
    return referenceApi
      .getGarantTypesList()
      .then((res) => {
        return res;
      })
      .catch((error) => {
        console.log(error);
      });
  }
  getContractTypeListService() {
    return referenceApi
      .getContractTypesList()
      .then((res) => {
        return res;
      })
      .catch((error) => {
        console.log(error);
      });
  }
  getDocumentTypeListService() {
    return referenceApi
      .getDocumentTypesList()
      .then((res) => {
        return res;
      })
      .catch((error) => {
        console.log(error);
      });
  }
  getOrgFormsTypeListService() {
    return referenceApi
      .getOrgFormTypeList()
      .then((res) => {
        return res;
      })
      .catch((error) => {
        console.log(error);
      });
  }
  getGarantFormsTypeListService() {
    return referenceApi
      .getGarantFormTypeList()
      .then((res) => {
        return res;
      })
      .catch((error) => {
        console.log(error);
      });
  }
  getReputation() {
    return referenceApi
      .getReputation()
      .then((res) => {
        return res;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getDeliveryCity(code: string) {
    return referenceApi
      .getDeliveryCity(code)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        console.log(error);
      });
  }
  getIpoticaSlidersData(configData: string) {
    return referenceApi
      .getIpoticaSlidersData(configData)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        console.log(error);
      });
  }
  getDeliveryStreet(city: string) {
    return referenceApi
      .getDeliveryStreet(city)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        console.log(error);
      });
  }
}

const referenceService = new ReferenceService();
export default referenceService;
