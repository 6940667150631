import DateFnsUtils from "@date-io/date-fns";
import { Theme, createStyles, makeStyles } from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import ruLocale from "date-fns/locale/ru";
import { memo, useEffect, useRef, useState } from "react";
import { COLORS } from "../core/consts/redesign/commonRedesign";
import { ArrowDown, Calendar } from "../core/consts/redesign/icons";
/* read only date picker, without date typing */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    error: {
      fontSize: "12px",
      fontWeight: 500,
      lineHeight: "16px",
      color: COLORS.alert,
    },
    label: {
      color: `${COLORS.gray2} !important`,
      fontSize: "21px",
      lineHeight: "21px",
      fontWeight: 500,
      padding: "16px 12px 3px 12px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: "100%",
    },
    labelSmall: {
      top: "-18px",
      left: "0px",
      fontSize: "16px",
      lineHeight: "16px",
      fontWeight: 500,
    },
    datePicker: {
      whiteSpace: "nowrap",
      msTextOverflow: "hidden",
      width: "100%",
      minWidth: "170px",
      minHeight: "63px",
      boxSizing: "border-box",
      textOverflow: "ellipsis",
      "& .MuiInputLabel-formControl": {
        top: '2px',
        left: '-1px'
      },
      "& .MuiFormControl-root": {
        minHeight: "63px",
        padding: "14px 12px 6px 16px",
      },
      "& .MuiFormLabel-root": {
        fontSize: "21px",
        lineHeight: "21px",
        fontWeight: 500,
      },
      "& .MuiButtonBase-root": {
        height: "50px",
        marginTop: "30px",
      },
      "& .MuiInputBase-root": {
        margin: 0,
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        width: "100%",
        boxSizing: "border-box",
        minHeight: "63px",
        padding: "12px 0 3px 16px",
        borderRadius: "4px",
        border: `1px solid ${COLORS.light_gray_stroke}`,
        position: "relative",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        "&:hover": {
          border: `1px solid ${COLORS.brand_blue}`,
        },
        "&:focused": {
          border: `1px solid ${COLORS.brand_blue}`,
        },
        "&.Mui-disabled": {
          border: `1px solid ${COLORS.light_gray_stroke}`,
        },
      },
      "& .MuiInputBase-input": {
        marginTop: "14px",
      },
      "& .MuiInput-underline:before, & .MuiInput-underline:after": {
        display: "none",
      },
      "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
        display: "none",
      },
      "& .MuiIconButton-label": {
        marginTop: "14px",
      },
      "& .MuiIconButton-root": {
        "&:hover": {
          backgroundColor: "transparent",
        },
        "&:active": {
          backgroundColor: "transparent !important",
        },
        "&:checked": {
          backgroundColor: "transparent !important",
        },
        "&:visited": {
          backgroundColor: "transparent !important",
        }
      },
      "& .MuiTouchRipple-root": {
        color: 'transparent'
      },
    },
    datePickerWithValue: {
      "& .MuiInputBase-root": {
        border: `1px solid ${COLORS.success}`,
      },
    },
    withError: {
      "& .MuiInputBase-root": {
        border: `1px solid ${COLORS.alert}`,
      },
    },
    popover: {
      backgroundColor: COLORS.black,
      display: "flex",
      flexDirection: "column",
      width: "100%",
      padding: "10px 16px",
      alignItems: "flex-start",
      justifyContent: "center",
      borderRadius: "4px 4px 4px 0px",
    },
  })
);

type Props = {
  isRequired?: boolean;
  value: Date | null;
  label?: string;
  onChange: (date: Date | null) => void;
  disabled?: boolean;
  error?: string;
  disablePast?: boolean;
  arrowIcon?: boolean
};

const DateInputCustom: React.FC<Props> = ({
  isRequired,
  value,
  label,
  onChange,
  disabled,
  error,
  disablePast,
  arrowIcon
}) => {
  const inlineClass = useStyles();
  const [dateState, setDateState] = useState<Date | null>(null);
  const [focus, setFocus] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const labelRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const hasValueClass = dateState ? inlineClass.datePickerWithValue : '';
  const hasValueClassLabel = focus || (value && value.toString().length > 0) || dateState || (inputRef.current && inputRef.current.value)
    ? inlineClass.labelSmall
    : '';

  const errorClass = error ? inlineClass.withError : '';
  useEffect(() => {
    if (value) {
      const utcDate = new Date(value);
      setDateState(utcDate);
    }
  }, [value]);

  const handleFocus = () => setFocus(true);
  const handleBlur = () => setFocus(false);
  const isValidDate = (dateString: string): boolean => {
    const regex = /^\d{2}\.\d{2}\.\d{4}$/;
    if (!regex.test(dateString)) {
      return false;
    }
    const [day, month, year] = dateString.split('.').map(Number);
    if (month < 1 || month > 12) return false;
    if (day < 1 || day > 31) return false;
    if (year < 2000 || year > 2100) return false;
    const date = new Date(year, month - 1, day);
    return date.getFullYear() === year && date.getMonth() === month - 1 && date.getDate() === day;
  };

  const onChangeValueInput = (date: Date | null, value?: string | null) => {
    if (value && !isValidDate(value)) {
      setDateState(null);
      onChange(null);
      return;
    }

    if (onChange) {
      if (date && !isNaN(date.getTime())) {
        setDateState(date);
        onChange(date);
      } else {
        setDateState(null);
        onChange(null);
      }
    }
  };

  return (
    <>
      <div style={{ display: "flex", flexDirection: "column", width: "100%", gap: "8px" }}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
          <div style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
            <KeyboardDatePicker
              label={
                <div
                  ref={labelRef}
                  className={`${inlineClass.label} ${hasValueClassLabel}`}
                  style={{
                    maxWidth: "95%",
                    position: "relative",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {label}
                  {isRequired && <span style={{ color: "#DC5103" }}>*</span>}
                </div>
              }
              autoOk
              disabled={disabled}
              // disablePast={disablePast}
              color="primary"
              variant="inline"
              format="dd.MM.yyyy"
              invalidDateMessage={""}
              // maxDateMessage={""}
              // minDateMessage={""}
              keyboardIcon={
                <div onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)} >
                  {arrowIcon ?
                    <ArrowDown color={isHovered ? COLORS.brand_blue : COLORS.gray2} />
                    :
                    <Calendar color={isHovered ? COLORS.brand_blue : undefined} />}
                </div>
              }
              className={`${inlineClass.datePicker} ${hasValueClass} ${errorClass}`}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              InputLabelProps={{
                shrink: true,
                className: inlineClass.label,
              }}
              InputProps={{
                inputRef: inputRef,
                readOnly: true,
              }}
              value={dateState}
              onChange={onChangeValueInput}
              onFocus={handleFocus}
              onBlur={handleBlur}
            />
          </div>
          {error && error?.length && (
            <div className={`error_input ${inlineClass.error}`}>{error}</div>
          )}
        </MuiPickersUtilsProvider>
      </div>
    </>
  );
};

export default memo(DateInputCustom);
