import { Theme, createStyles, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAppThunkDispatch } from "../../../core/store";
import * as selectors from "../../../core/store/selectors";
import { setValueField } from "../../../core/store/thunk/newOrder";
import { rootState } from "../../../core/types/rootState";
import { setInitialValueField, showIfCheck } from "../helpers/helpers";
import { Field, FieldType } from "../types/bigForm";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    showIfHidden: {
      position: "absolute",
      opacity: 0,
      visibility: "hidden",
    },
    showIfVisible: {
      position: "static",
      opacity: 1,
      visibility: "visible",
    },
  })
);

type RequiredProps = {
  field: FieldType;
  propsalId: number;
  parentName?: string;
  indexGroupField?: number;
};

type Props<T> = T & RequiredProps;

const withShowIfField = <T extends Object>(Component: React.FC<T>) => {
  return (props: Props<T>) => {
    const classes = useStyles();
    const dispatch = useAppThunkDispatch();
    const dependentFieldValue = useSelector((state: rootState) =>
      selectors.fieldValue(state, {
        fieldName: props.field?.show_if[0].Field,
        propsalId: props.propsalId,
        parentName: props.parentName ? props.parentName : undefined,
        indexGroupField:
          props.indexGroupField !== undefined
            ? props.indexGroupField
            : undefined,
      })
    );
    const [prevDependentValue, setPrevDependentValue] =
      React.useState(dependentFieldValue);

    const [isVisible, setIsVisible] = useState<boolean>(true);

    useEffect(() => {
      const newVisibility = showIfCheck(
        dependentFieldValue as Exclude<Field, string[]>,
        props.field?.show_if
      );

      if (isVisible !== newVisibility) {
        setIsVisible(newVisibility);
      }

    }, [dependentFieldValue, props.field]);

    useEffect(() => {
      // Check if field has just become invisible
      if (!isVisible && prevDependentValue !== dependentFieldValue) {
        setPrevDependentValue(dependentFieldValue);
        const emptyValue = setInitialValueField(props.field);
        dispatch(
          setValueField(
            props.field.name,
            emptyValue,
            props.propsalId,
            props.parentName || undefined,
            props.indexGroupField || undefined
          )
        );
      }
    }, [isVisible, dependentFieldValue, props.field]);

    const classNameShowIf = isVisible ? classes.showIfVisible : classes.showIfHidden;

    return (
      <Component
        {...props}
        isVisible={isVisible}
        classNameShowIf={classNameShowIf}
        dependentFieldValue={dependentFieldValue}
      />
    );
  };
};


export default withShowIfField;
