import React, { memo, useState, KeyboardEvent } from "react";
import {
  Typography,
  Box,
  Button,
  TextField,
  makeStyles,
  Theme,
  createStyles,
} from "@material-ui/core";
import { Camera } from "../../Camera";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    messageInput: {
      width: "calc(100% - 165px)",
      "& textarea": {
        paddingRight: "45px",
        overflowY: "scroll",
        "&::-webkit-scrollbar": {
          width: "0.2em",
          height: "0.2em",
        },
        "&::-webkit-scrollbar-track": {
          boxShadow: "inset 0 0 6px rgba(208 217 222 / 88%)",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#D0D9DE",
          outline: "0.2px solid #D0D9DE",
        },
      },
    },
    form: {
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
      alignItems: "center",
      boxSizing: "border-box",
      backgroundColor: "#ffffff",
      position: "relative",
      borderRadius: "10px",
      "&>div>div:before": {
        display: "none",
      },
    },
    button: {
      width: "20%",
      height: "51px",
      position: "absolute",
      right: 0,
      top: " 50%",
      transform: "translateY(-50%)",
      boxSizing: "border-box",
      padding: "0 35px",
    },
    fileUpload: {
      "&:hover": {
        cursor: "pointer",
      },
      position: "absolute",
      bottom: "17px",
      right: "35px",
      width: "20px",
      height: "20px",
      display: "inline-block",
      background: "white",
      border: "none",
      padding: "0 10px",
    },
  })
);

type propsType = {
  handleSelectFile: any;
  handleMessageSend: any;
};

const MessageInput: React.FC<propsType> = (props) => {
  const classes = useStyles();
  const [currentMessage, setCurrentMessage] = useState<string>("");
  const [isDisabled, setIsDisablled] = useState(true);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement> & { keyCode: number, key: string}) => {
    const { keyCode } = event
    const isEnterPressed = keyCode === 13
    const isCtrlPressed = event.ctrlKey
    const isSendMessagePressed = isEnterPressed && isCtrlPressed
    if (isSendMessagePressed) {
      handleMessageSend()
    }
  }

  const handleMessageChange = (event: any) => {
    setCurrentMessage(event.target.value);
    setIsDisablled(event.target.value === "");
  };

  const handleMessageSend = () => {
    props.handleMessageSend(currentMessage);
    setCurrentMessage("");
  };
  const [fill, setFill] = useState('')
  return (
    <Box className={classes.form}>
      <Box style={{ position: "relative", width: "80%" }}>
        <TextField
          label="Ваше сообщение..."
          color="primary"
          variant="outlined"
          onKeyDown={handleKeyDown}
          onChange={handleMessageChange}
          className={classes.messageInput}
          value={currentMessage}
          multiline={true}
          maxRows={4}
          minRows={1}
          style={{ width: "100%" }}
        />
        <label onMouseMove={() => setFill('#617E8C')} onMouseLeave={() => setFill('')} className={classes.fileUpload}>
          <Camera fill={fill} />
          <input
            type="file"
            onChange={props.handleSelectFile}
            id="upload"
            hidden
          />
        </label>
      </Box>
      <Button
        color="primary"
        variant="text"
        disabled={isDisabled}
        className={classes.button}
        onClick={handleMessageSend}
      >
        <Typography
          variant="body1"
          component="span"
          style={{ color: "inherit", textTransform: "none" }}
        >
          Отправить
        </Typography>
      </Button>
    </Box>
  );
};

export default memo(MessageInput);
