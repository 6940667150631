import {
  Button,
  createStyles,
  Dialog,
  IconButton,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useEffect } from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: "white",
      width: "780px",
      padding: "50px 75px",
      position: "relative",
    },
    title: {
      color: "#131313",
      fontFamily: "Gilroy",
      fontWeight: 600,
      fontSize: "32px",
    },
    subTitle: {
      marginTop: "25px",
      fontSize: "16px",
      fontFamily: "Gilroy",
      fontWeight: 500,
      color: "#131313",
      width: "70%",
    },
    btnText: {
      border: "1px solid #306F9A",
      padding: "18px 36px",
      marginTop: "30px",
    },
    textDoc: {
      fontSize: "16px",
      color: "#306F9A",
      fontFamily: "Gilroy",
      fontWeight: 500,
      textTransform: "none",
    },
    text: {
      textTransform: "none",
      color: "white",
      fontSize: "14px",
      fontFamily: "Gilroy",
      fontWeight: 500,
    },
    changeStatusBtn: {
      background: "#306F9A",
      borderRadius: "4px",
      padding: "18px 36px",
      marginLeft: "15px",
    },
  })
);

type propsType = {
  isOpen: boolean;
  setCloseModal: (isClose: boolean) => void;
  handleArchive: () => void;
  archiveRequestStatus: boolean;
  setArchiveRequestStatus: (archiveRequestStatus: boolean) => void;
};

const ArchiveStatusModal: React.FC<propsType> = ({
  isOpen,
  setCloseModal,
  handleArchive,
  archiveRequestStatus,
  setArchiveRequestStatus,
}) => {
  const classes = useStyles();
  const handleClose = () => {
    setCloseModal(false);
  };
  useEffect(() => {
    if (archiveRequestStatus === true) {
      setCloseModal(false);
    }
  }, [archiveRequestStatus]);
  const handleClickArchive = () => {
    setArchiveRequestStatus(true)
    handleArchive();
  }
  return (
    <Dialog maxWidth={false} open={isOpen}>
      <div className={classes.container}>
        <div className={classes.title}>Архивирование заявки</div>
        <IconButton
          style={{ position: "absolute", top: "33px", right: "50px" }}
          aria-label="close"
          onClick={handleClose}
        >
          <CloseIcon style={{ color: "#D0D9DE" }} />
        </IconButton>
        <div className={classes.subTitle}>
          Текущая заявка будет перенесена в архив без возможности
          восстановления. Выдействительно хотите архивровать заявку?
        </div>
        <div style={{ marginTop: '30px' }}>
          <Button
            color={"primary"}
            className={classes.btnText}
            variant="text"
            style={{ marginTop: "0px" }}
            onClick={handleClickArchive}
          >
            <Typography
              variant={"subtitle2"}
              className={classes.textDoc}
              component={"span"}
            >
              Перенести в архив
            </Typography>
          </Button>
          <Button
            color={"primary"}
            className={classes.changeStatusBtn}
            variant="contained"
            onClick={handleClose}

          >
            <Typography
              variant={"subtitle2"}
              className={classes.text}
              component={"span"}
              style={{ fontSize: "16px" }}
            >
              Вернуться к заявке
            </Typography>
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default ArchiveStatusModal;
