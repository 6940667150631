import { createTheme } from '@material-ui/core';
import { COLORS } from './redesign/commonRedesign';


export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 480,
      md: 768,
      lg: 1280,
      xl: 1600,
    }
  },
  palette: {
    primary: {
      main: '#4794EF',
      light: '#C0D3E0',
      dark: '#77B5FD',
      contrastText: '#ffffff',
      '900': "#0d47a1",
    },
    info: {
      main: '#ffffff',
      light: '#C0D3E0',
      dark: '#214D6B',
      contrastText: '#ffffff',
      '900': "#0d47a1",
    },
    secondary: {
      main: '#131313',
      light: '#F2F2F2',
      dark: '#050505',
      contrastText: '#ffffff'
    },
    error: {
      main: '#D62D30',
      light: '#F2C0C0',
      dark: '#400D0E',
      contrastText: '#ffffff'
    },
    success: {
      main: '#69A042',
      light: '#C3D9B3',
      dark: '#2A401A',
      contrastText: '#ffffff'
    },
  },
  typography: {
    h1: {
      fontFamily: 'Gilroy',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '3rem', //48
      lineHeight: '1.2',
      color: COLORS.black,
    },
    h2: {
      fontFamily: 'Gilroy',
      fontStyle: 'normal',
      fontSize: '2rem',//32
      fontWeight: 600,
      lineHeight: '1.2',
      color: COLORS.black,
    },
    h3: {
      fontFamily: 'Gilroy',
      fontStyle: 'normal',
      fontSize: '24px', //24
      fontWeight: 600,
      lineHeight: '1.2',
      color: COLORS.black,
    },
    h4: {
      fontFamily: 'Gilroy',
      fontStyle: 'normal',
      fontSize: '18px', //18
      fontWeight: 500,
      lineHeight: '1.3',
      color: COLORS.black,
    },
    h5: {
      fontFamily: 'Gilroy',
      fontStyle: 'normal',
      fontSize: '1.13rem', //18
      fontWeight: 500,
      lineHeight: '1.3',
      color: COLORS.black,
    },
    subtitle1: {
      fontFamily: 'Gilroy',
      fontStyle: 'normal',
      fontSize: '18px', //18
      fontWeight: 500,
      lineHeight: '1.3',
      color: COLORS.black,
    },
    subtitle2: {
      fontFamily: 'Gilroy',
      fontStyle: 'normal',
      fontSize: '1rem', //16
      fontWeight: 500,
      lineHeight: '1.3',

    },
    body1: {
      fontFamily: 'Gilroy',
      fontStyle: 'normal',
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '1.3',
      color: COLORS.black,
    },
    body2: {
      fontFamily: 'Gilroy',
      fontStyle: 'normal',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '1.3',
      color: COLORS.black,
    },
  },

  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: '16px',
        fontFamily: 'Gilroy',
        fontWeight: 'normal',
        backgroundColor: 'rgba(19, 19, 19, 0.5)',
        borderRadius: '2px',
        color: '#ffffff',
      }
    },
  }
})
