import {TabErrorsActionTypes, TabsErrorsActions, TabsErrorsState} from '../../types/tabsErrors'

const initialState: TabsErrorsState = {
    errors: []
}

export const tabsErrorsReducer = (state = initialState, action: TabsErrorsActions) => {
    switch (action.type) {
        case TabErrorsActionTypes.SET_ERROR: {
            return {...state, errors: [action.payload]}
        }
        case TabErrorsActionTypes.CLEAR_ERRORS: {
            return initialState
        }
        default: {
            return state
        }
    }
}