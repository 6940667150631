import { productsId } from './../../app/enum';
import { http } from "../infrastructure/Https";
import tokenService from "../services/TokenService";
import {
  clientsAgentsListType,
  agentByInnType,
  leadsListType,
  actType,
  reqBodyDocUpload,
  ManagerDownloadDocArgs,
} from "../types/managerApiTypes";
import { QueryParamsGetLeads } from "../types/leadsApiTypes";

const ROOT_URL = "/api/v1/manager";

class ManagerApi {
  getClietnsAgetnsList(Q: {
    Inn?: string;
    Region?: string;
    CompanyName?: string;
    clientType?: string;
    bAgents?: boolean;
    bClients?: boolean;
    bAgentsClients?: boolean;
    count?: number;
    offset?: number;
  }): Promise<clientsAgentsListType> {
    const accessToken = tokenService.getAccessToken();
    return http.get(`${ROOT_URL}/agents`, Q, accessToken);
  }

  getActsList(): Promise<actType[]> {
    const accessToken = tokenService.getAccessToken();
    return http.get(`${ROOT_URL}/acts`, null, accessToken);
  }

  getAgentByInn(inn: string): Promise<agentByInnType> {
    const accessToken = tokenService.getAccessToken();
    return http.get(`${ROOT_URL}/agent/${inn}`, null, accessToken);
  }

  getLeadsListByProductId(Q: QueryParamsGetLeads): Promise<leadsListType> {
    const accessToken = tokenService.getAccessToken();
    return http.get(`${ROOT_URL}/leads`, Q, accessToken);
  }

  signActByManager(B: { actsids: number[] }): Promise<string> {
    const accessToken = tokenService.getAccessToken();
    return http.put(`${ROOT_URL}/acts/sign`, null, B, accessToken);
  }

  downloadDocument(args: ManagerDownloadDocArgs) {
    const accessToken = tokenService.getAccessToken();
    return http.getWithBlobHeader(
      `${ROOT_URL}/${args.userId}/documents/${args.documentType}`,
      null,
      accessToken
    );
  }
  downloadDocumentAdmin(fileId: string) {
    const accessToken = tokenService.getAccessToken();
    return http.getWithBlobHeader(
      `${ROOT_URL}/documents?DocumenType=tarifffile&DocumentId=${fileId}`,
      null,
      accessToken
    );
  }
  deleteDocument(args: ManagerDownloadDocArgs) {
    const accessToken = tokenService.getAccessToken();
    return http.delete(
      `${ROOT_URL}/${args.userId}/documents/${args.documentType}`,
      null,
      null,
      accessToken
    );
  }
  postDocument(args: ManagerDownloadDocArgs, file: File) {
    const accessToken = tokenService.getAccessToken();
    const formData = new FormData();
    formData.append('file', file)
    return http.post(
      `${ROOT_URL}/${args.userId}/documents/${args.documentType}`,
      null,
      formData,
      accessToken
    );
  }
  putDocument(args: ManagerDownloadDocArgs, file: File) {
    const accessToken = tokenService.getAccessToken();
    const formData = new FormData();
    formData.append('file', file)
    return http.put(
      `${ROOT_URL}/${args.userId}/documents/${args.documentType}`,
      null,
      formData,
      accessToken
    );
  }
  getStatisticData(info: any) {
    const accessToken = tokenService.getAccessToken();
    const params = new URLSearchParams();
    if (info.startPeriod){
      params.append("DateStart", info.startPeriod);
    }
    if (info.finishPeriod){
      params.append("DateEnd", info.finishPeriod);
    }
    if (info.productId && info.productId !== '0'){
      params.append("ProductId", info.productId);
    }
    if (info.leadType){
      params.append("LeadType", info.leadType);
    }
    if (info.sort){
      params.append("Sort", info.sort);
    }
    return http.get(`${ROOT_URL}/statistics`, params, accessToken);
  }
  uploadAgentActByManager(
    props: reqBodyDocUpload | Omit<reqBodyDocUpload, "ActId">,
    Act: File
  ): Promise<string> {
    const accessToken = tokenService.getAccessToken();
    const formData = new FormData();
    formData.append("Act", Act);
    for (const key in props) {
      formData.append(key, (props as unknown as Record<string, string>)[key]);
    }
    return http.postMultiple<undefined, any, any>(
      `${ROOT_URL}/acts/upload`,
      undefined,
      formData,
      accessToken
    );
  }
}

const managerApi = new ManagerApi();
export default managerApi;
