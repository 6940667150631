import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import ClientMessagesPage from '../../pages/client-messages/ClientMessagesPage';
import ClientSupportChat from '../../pages/client-support-chat/ClientSupportChat';
import NotFoundPage from '../../pages/not-found/routing';
import PartnerApplicationPage from '../../pages/partner-application/PartnerApplicationPage';
import PartnerCurrentApplicationPage from '../../pages/partner-current-application/PartnerCurrentApplicationPage';
import PartnerProfilePage from '../../pages/partner-profile/PartnerProfilePage';
import { ROUTES } from '../consts/common';
import { rootState } from '../types/rootState';
const PartnerRoutes = () => {
  const match = useRouteMatch();
  const isAuth = useSelector((s: rootState) => s.currentUser.isAuth)

  return (
    <>
      <Helmet>
        <title>Finlab - кабинет партнера</title>
        <meta
          name="description"
          content="Finlab.ru — финансовые услуги для бизнеса. Бесплатный подбор кредитов и 
                    лизинга для бизнеса, факторинг, банковские гарантии, РКО."
        />
      </Helmet>
      {
        isAuth ? (
          <Switch>
            <Route path={`${match.path}/profile`} component={PartnerProfilePage} exact />
            <Route path={`${match.path}/applications`} exact component={PartnerApplicationPage} />
            <Route path={`${match.path}/application/:tab/:leadId`} exact component={PartnerCurrentApplicationPage} />
            {/* <Route path={`${match.path}/application/:tab/:leadId`} exact component={ClientProductDetails} /> */}
            <Route path={`${match.path}/messages`} component={ClientMessagesPage} exact />
            <Route path={`${match.path}/support`} component={ClientSupportChat} exact />
            {NotFoundPage}
          </Switch>
        ) : <Redirect to={ROUTES.MAIN} />
      }
    </>
  )
}

export default (<Route component={PartnerRoutes} path={ROUTES.PARTNER} />)

