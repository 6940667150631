import React, { memo } from "react";
import { Typography, Box, Tooltip } from "@material-ui/core";
import { DocIcon } from "../../../../core/consts/images/DocIcon";
import { Comment } from "../../../../core/consts/images/Comment";
import { DocumentCardUseStyles } from "./DocumentCardUseStyles";
import { LeadDocType } from "../../../../core/types/leadsApiTypes";
import leadsService from "../../../../core/services/LeadsService";

type propsType = {
  doc: LeadDocType;
};

const saveByteArray = (function () {
  const a = document.createElement("a");
  document.body.appendChild(a);
  return function (data: any, name: string) {
    const blob = new Blob([data]),
      url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = name;
    a.click();
    window.URL.revokeObjectURL(url);
  };
})();

const DocumentCard: React.FC<propsType> = (props) => {
  const classes = DocumentCardUseStyles();

  const handleClickDoc = (id: number, name: string) => {
    leadsService
      .getLeadDocService(id)
      .then((res) => {
        saveByteArray(res, name);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Box className={classes.docWrap}>
      <Box className={classes.docSubWrap}>
        <Tooltip
          className={classes.tooltip}
          title={`${props.doc.doctype}`}
          placement="bottom-start"
        >
          <Box>
            {props.doc.isUserUploadPending ? (
              <Typography variant="body1" className={classes.fileUploaded}>
                <DocIcon />
                &nbsp;&nbsp;
                <span className={classes.documentEllipsis}>
                  {props.doc.doctype}
                </span>
              </Typography>
            ) : (
              <Typography
                className={classes.fileUploaded}
                onClick={() => handleClickDoc(props.doc.id, props.doc.name)}
                variant="body1"
              >
                <DocIcon />
                &nbsp;&nbsp;
                <span
                  style={{ color: "#306F9A" }}
                  className={classes.documentEllipsis}
                >
                  {props.doc.doctype}
                </span>
              </Typography>
            )}
          </Box>
        </Tooltip>
        {props.doc.comment && (
          <Tooltip
            className={classes.tooltip}
            title={`${props.doc.comment}`}
            placement="bottom-start"
          >
            <Typography variant="body1" component="span">
              &nbsp;&nbsp;
              <Comment />
            </Typography>
          </Tooltip>
        )}
      </Box>
      <Typography
        variant="body2"
        style={{ color: "#B0BFC6", whiteSpace: "nowrap" }}
      >
        {props.doc.isUserUploadPending
          ? "Ожидает загрузки"
          : props.doc.hasApplicantSignature
          ? "Подписан"
          : props.doc.isUserSignaturePending
          ? "Ожидает подписи"
          : "Загружен"}
      </Typography>
    </Box>
  );
};

export default memo(DocumentCard);
