export const ROUTES = {
  ABOUT: "/about/",
  ACTS: "/acts",
  ADMIN: "/admin",
  ADMIN_NEW_PRODUCT: "/new-product",
  ADMIN_EDIT_PRODUCTS: "/edit-products",
  ADMIN_EDIT_PRODUCT: "/edit-products/new-product",
  ADMIN_ALL_NEWS: "/edit-news",
  ADMIN_NEW_NEWS: "/edit-news/new-article",
  ADMIN_EDIT_NEWS: "/edit-news/new-article/",
  ADMIN_ALL_ARTICLES: "/edit-articles",
  ADMIN_NEW_ARTICLE: "/edit-articles/new-article",
  ADMIN_EDIT_ARTICLE: "/edit-articles/new-article/",
  ADMIN_STATISTIC: "/statistic",
  AGENT: "/agent",
  AGENTS: "/agents/",
  ARCHIVE: "/archive",
  ARTICLE: "/article/",
  ARTICLES_AUTHOR: "/articles/author/",
  ARTICLES: "/articles/",
  BANK_GAURANTEE: "/bankovskiye-garantii/",
  CLIENT_KNOWLEDGE_BASE: "/knowledge-base",
  CLIENT: "/client",
  CLIENTS: "/clients",
  CONSUMER_CREDIT: "/potrebcredits/",
  CONTACTS: "/contacts/",
  CREDIT_CARD: "/creditcard/",
  CREDIT: "/credits/",
  FACTORING: "/factoring/",
  KNOWLEDGE: "/knowledge-base",
  KNOWLEDGE_ARTICLE: "/knowledge-base/",
  LEASING: "/lizing/",
  LOGIN: "/login/",
  MAIN: "/",
  MANAGER: "/manager",
  MESSAGES: "/messages",
  MORTGAGE: "/ipoteka/",
  NEWS: "/news/",
  NEW_ORDER: "/new-order",
  NEW_ORDER_FORM: "/form-new-order",
  OTKRITIE_RASCHETNOGO_SCHETA: "/otkritie-raschetnogo-scheta/",
  PARTNER: "/partner",
  PARTNERS_APPLICATIONS: "/applications",
  PARTNERS_APPLICATION: "/application/",
  PERSONAL_DATA_PROCESSING_AGREEMENT: "/personal-data-processing-agreement/",
  PRIVATE_CLIENTS: "/privateclients/",
  PRODUCT_TYPE: "/producttype",
  PRODUCT: "/product",
  PRODUCTS: "/products",
  PROFILE: "/profile",
  RKO: "/rko/",
  SCORING: "/counterparty-check",
  SUPPORT: "/support",
  USER_AGREEMENT: "/user-agreement/",
};

export const TOKEN_NAME = "c2mFinanceTokenService1";

export enum CookiesEnum {
  cart = "cart",
  name = "name",
  last_name = "last_name",
  role = "role",
  code = "code",
  followingLinkEventId = "followingLinkEventId",
  cid = "cid",
  uid = "uid",
  CIDProduct = "CIDProduct"
}

export const ROLES = {
  CLIENT: "Клиент",
  AGENT: "Агент",
  PARTNER: "Партнер",
  MANAGER: "Менеджер",
  ADMIN: "Администратор",
};

export const CHAT_ROLES = {
  client:
    "https://secure.gravatar.com/avatar/593f878d696bba98357e1e91abc005ba?s=800&d=identicon",
  agent:
    "https://secure.gravatar.com/avatar/91c73603ef979e4fd670eb3758869318?s=800&d=identicon",
  PARTNER: "Partner",
  MANAGER: "Manager",
};

export enum ORGANIZATION_TYPE {
  bank = "Банк",
  factoringCompany = "Факторинговая компания",
  lizingCompany = "Лизинговая компания",
}
export enum ORGANIZATION_TYPE_OF_ENUM {
  "Банк" = "банка",
  "Факторинговая компания" = "факторинговой компании",
  "Лизинговая компания" = "лизинговой компании",
}

export enum UTM_EVENT_ENUM {
  FollowingLink = "FollowingLink",
  EnteredPhoneNumber = "EnteredPhoneNumber",
  ChoseRole = "ChoseRole",
  CompletedRegistration = "CompletedRegistration",
}

export enum AgentTypeEnum {
  fl = "fl", // физичесское лицо
  ip = "ip", //ИП
  ul = "ul", //юридическое лицо
}

export interface UsePaginationItem {
  onClick: React.ReactEventHandler;
  type: 'page' | 'first' | 'last' | 'next' | 'previous' | 'start-ellipsis' | 'end-ellipsis';
  page: number | null;
  selected: boolean;
  disabled: boolean;
}


export const termsOfUseLink = 'https://storage.finlab.ru/docs/polzovatelskoe-soglashenie-finlab.pdf';