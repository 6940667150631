import {
  Button,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import {
  useCallback,
  useEffect,
  useRef,
  useState
} from "react";
import { Link } from "react-router-dom";
import HeaderNew from "../../app/components/redesign/Header/HeaderNew";
import { ROUTES } from "../../core/consts/common";
import { dateConverter } from "../../core/helpers/dateConverter";
import newsService from "../../core/services/NewsService";
import referenceService from "../../core/services/ReferenceService";
import Eye from "../main-admin-page/images/Eye";

/* Redesign . Admin all news page */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      maxWidth: "100%",
      padding: '40px 80px',
      margin: "0 auto",
      boxSizing: "border-box",
    },
    filters: {
      display: "flex",
      gap: "15px",
      flexWrap: "wrap",
      alignItems: "center",
    },
    filter: {
      width: "300px",
      minWidth: "300px",
    },
    table: {
      padding: "30px 0",
      background: "white",
      borderRadius: "10px",
      marginTop: "30px",
    },
    tableHead: {
      display: "grid",
      gridTemplateColumns: "3fr 2fr 3fr 2fr 1fr",
      gridTemplateAreas: "'title author periods source options'",
      padding: "0 30px",
      gridGap: "20px",
      "& div": {
        color: "#617E8C",
        fontSize: "14px",
        fontFamily: "Gilroy",
        fontWeight: 600,
      },
    },
    title: {
      gridArea: "title",
      display: "flex",
      alignItems: "center",
    },
    author: {
      gridArea: "author",
    },
    periods: {
      gridArea: "periods",
    },
    source: {
      gridArea: "source",
    },
    bankBody: {
      marginTop: "10px",
    },
    tableElem: {
      display: "grid",
      gridTemplateColumns: "3fr 2fr 3fr 2fr 1fr",
      gridTemplateAreas: "'title author periods source options'",
      gridGap: "20px",
      height: "104px",
      borderBottom: "1px solid #D0D9DE",
      textDecoration: "none",
      alignItems: "center",
      cursor: "pointer",
      padding: "0 30px",
      "&:hover": {
        background: "#F2F2F2",
      },
      "& div": {
        color: "#131313",
      },
    },
    options: {
      gridArea: "options",
    },
    btnText: {
      color: "#306F9A",
      fontFamily: "Gilroy",
      fontWeight: 500,
      fontSize: "14px",
      cursor: "pointer",
      marginTop: "10px",
    },
    btnMenu: {
      whiteSpace: "nowrap",
      padding: "18px 36px",
      display: "block",
    },
    modal: {
      width: "343px",
      height: "130px",
      background: "white",
      position: "absolute",
      top: "70px",
      boxShadow: "0px 15px 20px rgba(0, 0, 0, 0.05)",
      borderRadius: "10px",
      padding: "20px 20px 25px 20px",
    },
    button: {
      height: "51px",
      width: "100%",
    },
    text: {
      color: "inherit",
      textTransform: "none",
    },
    btnDrop: {
      width: "100%",
      textTransform: "none",
      marginTop: "5px",
    },
    btnIcon: {
      fontSize: 18,
      color: "#D0D9DE",
      marginLeft: "7px",
    },
    control: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "30px",
      alignItems: "start",
    },
  })
);

const MainNewsPage = () => {
  const classes = useStyles();

  const [news, setNews] = useState<null | any>([]);
  const [isClickIcons, setClickIcons] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [choosenFilters, setChoosenFilters] = useState({
    newsFilter: "",
    newsStatus: "",
  });
  const [prevData, setPrevData] = useState({
    newsFilter: "",
    newsStatus: "",
  });
  const [newsFilter, setNewsFilter] = useState<any[]>([]);
  const [newsStatuses, setNewsStatuses] = useState<any[]>([]);
  const [isFirstRender, setFirstRender] = useState(true);

  const [offset, setOffset] = useState<number>(0);
  useEffect(() => {
    if (
      isFirstRender &&
      choosenFilters.newsFilter &&
      choosenFilters.newsStatus
    ) {
      handleClickShow(0);
      setFirstRender(false);
    }
  }, [choosenFilters]);
  useEffect(() => {
    if (offset && offset < totalCount / 25) {
      if (choosenFilters.newsFilter && choosenFilters.newsStatus) {
        newsService
          .getNewsListService({
            Sort: choosenFilters.newsFilter,
            Statuses: choosenFilters.newsStatus,
            offset: offset * 25,
            count: 25,
          })
          .then((res: any) => {
            try {
              const newData = news.concat(res.result);
              setNews(newData);
              setTotalCount(res.pagination.total);
            } catch (e) {
              console.log(e);
            }
          });
      }
    }
  }, [offset]);
  useEffect(() => {
    Promise.all([
      referenceService.getListService("getNewsFilter"),
      referenceService.getListService("getNewsStatuses"),
    ]).then((res: any[]) => {
      setNewsFilter(res[0]);
      setNewsStatuses(res[1]);
      setChoosenFilters((state) => ({
        ...state,
        newsFilter: res[0][0].key,
        newsStatus: res[1][0].key,
      }));
      setPrevData((state) => ({
        ...state,
        newsFilter: res[0][0].key,
        newsStatus: res[1][0].key,
      }));
    });
  }, []);
  const ref = useRef<null | HTMLDivElement>(null);
  const body = document.getElementsByTagName("body");
  const onScroll = () => {
    if (body[0].offsetHeight <= window.scrollY + window.innerHeight) {
      setOffset((state) => state + 1);
    }
  };
  window.onscroll = () => onScroll();

  const dropFilters = () => {
    setChoosenFilters((state) => ({
      ...state,
      newsFilter: newsFilter[0].key,
      newsStatus: newsStatuses[0].key,
    }));
    setPrevData((state) => ({
      ...state,
      newsFilter: newsFilter[0].key,
      newsStatus: newsStatuses[0].key,
    }));
    newsService
      .getNewsListService({
        Sort: newsFilter[0].key,
        Statuses: newsStatuses[0].key,
        offset: 0,
        count: 25,
      })
      .then((res: any) => {
        try {
          setNews(res.result);
          setTotalCount(res.pagination.total);
        } catch (e) {
          console.log(e);
        }
      });
  };

  const handleClickShow = (offset: number) => {
    if (choosenFilters.newsFilter && choosenFilters.newsStatus) {
      newsService
        .getNewsListService({
          Sort: choosenFilters.newsFilter,
          Statuses: choosenFilters.newsStatus,
          offset: offset * 25,
          count: 25,
        })
        .then((res: any) => {
          try {
            setNews(res.result);
            setTotalCount(res.pagination.total);
          } catch (e) {
            console.log(e);
          }
        });
    }
  };

  const handleClickBtn = () => {
    setPrevData((state) => ({
      ...state,
      newsFilter: choosenFilters.newsFilter,
      newsStatus: choosenFilters.newsStatus,
    }));
    handleClickShow(0);
  };

  const handleClickIcon = (id: number, isActive: boolean) => {
    newsService.putPartDataService(id, isActive).then(() => {
      setClickIcons(!isClickIcons);
      handleClickShow(offset);
    });
  };
  const handleChange = (event: React.ChangeEvent<any>) => {
    const { value, name } = event.target;
    setPrevData((state) => ({
      ...state,
      newsFilter: choosenFilters.newsFilter,
      newsStatus: choosenFilters.newsStatus,
    }));
    setChoosenFilters((state) => ({ ...state, [name]: value }));
    setOffset(0);
  };

  const isShowBtn = useCallback(() => {
    if (
      prevData.newsFilter !== choosenFilters.newsFilter ||
      prevData.newsStatus !== choosenFilters.newsStatus
    ) {
      return true;
    } else {
      return false;
    }
  }, [prevData, choosenFilters]);

  return (
    <div>
      <HeaderNew title={"Редактирование новостей"} />
      <Container className={classes.container}>
        <Grid>
          <div className={classes.control}>
            <div className={classes.filters}>
              <FormControl className={classes.filter}>
                <InputLabel></InputLabel>
                <Select
                  value={choosenFilters.newsStatus}
                  onChange={handleChange}
                  name={"newsStatus"}
                >
                  {newsStatuses.map((sortElem: any) => (
                    <MenuItem key={sortElem.key} value={sortElem.key}>
                      {sortElem.text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <div style={{ position: "relative" }}>
                <FormControl className={classes.filter}>
                  <InputLabel></InputLabel>
                  <Select
                    value={choosenFilters.newsFilter}
                    onChange={handleChange}
                    name={"newsFilter"}
                  >
                    {newsFilter.map((sortElem: any) => (
                      <MenuItem key={sortElem.key} value={sortElem.key}>
                        {sortElem.text}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {isShowBtn() && (
                  <div className={classes.modal}>
                    <Button
                      color={"primary"}
                      variant="contained"
                      className={classes.button}
                      onClick={handleClickBtn}
                    >
                      <Typography
                        variant={"subtitle2"}
                        className={classes.text}
                        component={"span"}
                      >
                        Показать предложения
                      </Typography>
                    </Button>
                    <Button
                      color={"primary"}
                      className={classes.btnDrop}
                      variant="text"
                      onClick={dropFilters}
                    >
                      <Typography variant={"subtitle2"}>
                        Сбросить фильтры
                      </Typography>
                      <CloseIcon className={classes.btnIcon} />
                    </Button>
                  </div>
                )}
              </div>
              <div onClick={dropFilters} className={classes.btnText}>
                Сбросить фильтры
              </div>
            </div>
            <Link
              to={`${ROUTES.ADMIN}/edit-news/new-article`}
            >
              <Button
                className={classes.btnMenu}
                color="primary"
                variant="outlined"
                style={{ display: "inline-block" }}
              >
                <Typography
                  variant="body1"
                  component="span"
                  style={{ color: "inherit", textTransform: "none" }}
                >
                  Добавить новость
                </Typography>
              </Button>
            </Link>
          </div>
          <div
            style={{
              color: "#131313",
              fontFamily: "Gilroy",
              fontWeight: 500,
              fontSize: "14px",
            }}
          >
            Найдено предложений: {totalCount}
          </div>
          <div ref={ref} className={classes.table}>
            <div className={classes.tableHead}>
              <div className={classes.title}>Заголовок новости</div>
              <div className={classes.author}>Автор</div>
              <div className={classes.periods}>
                Дата изменения / Дата создания
              </div>
              <div className={classes.source}>Источник</div>
              <div className={classes.options}></div>
            </div>
            <div className={classes.bankBody}>
              {news.length ?
                news.map((el: any, key: number) => {
                  return (
                    <Link
                      key={key}
                      to={`${ROUTES.ADMIN}/edit-news/new-article/${el.id}`}
                      className={classes.tableElem}
                    >
                      <div
                        className={classes.title}
                        style={{ fontWeight: "600" }}
                      >
                        <div> {el.title}</div>
                      </div>
                      <div className={classes.author}>{el.author.fio}</div>
                      <div className={classes.periods}>
                        {el.editDate ? dateConverter(el.editDate) : "-"} /{" "}
                        {dateConverter(el.createDate)}
                      </div>
                      <div className={classes.source}>{el.sourceText}</div>
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                        className={classes.options}
                      >
                        <IconButton
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleClickIcon(el.id, !el.isActive);
                          }}
                        >
                          <Eye isActive={el.isActive} />
                        </IconButton>
                      </div>
                    </Link>
                  );
                }) : ''}
            </div>
          </div>
        </Grid>
      </Container>
    </div>
  );
};

export default MainNewsPage;
