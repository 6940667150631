import {
  Box,
  Container,
  Tab,
  Tabs,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import React, { memo, useState } from "react";
import HeaderNew from "../../app/components/redesign/Header/HeaderNew";
import LeadsList from "./components/leads-list/LeadsList";

/* Redesign . all Partner's leads */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      maxWidth: "100%",
      padding: '40px 80px',
      margin: "0 auto",
      boxSizing: "border-box",
      minHeight: "100vh",
      height: "auto",
    },
    tab: {
      fontFamily: "Gilroy",
      fontWeight: 500,
      fontStyle: "normal",
      fontSize: "16px",
      lineHeight: "1.3",
      color: "#131313",
      textTransform: "none",
    },
    tabsWrap: {
      padding: "30px 30px 15px",
      position: "relative",
    },
    newLead: {
      padding: "2px 8px",
      borderRadius: "10px",
      color: "#ffffff",
      backgroundColor: "#309A90",
      position: "absolute",
      top: "25%",
      left: "7.5%",
    },
    tabs: {
      position: "relative",
      borderBottom: "1px solid #D0D9DE",
      display: "flex",
      maxWidth: "fit-content",
      [theme.breakpoints.down("md")]: {
        "&>div": {
          overflowX: "scroll!important",
          scrollbarWidth: "none",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        },
      },
    },
  })
);

const PartnerApplication: React.FC = () => {
  const classes = useStyles();
  const [value, setValue] = useState<number>(0);
  const [newLeads, setNewLeads] = useState<number>(0);
  const [allLeads, setAllLeads] = useState<number>(0);
  const [isNew, setIsNew] = useState<boolean | undefined>(true);
  const [isArchive, setIsArchive] = useState<boolean | undefined>(undefined);

  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
    switch (newValue) {
      case 0:
        setIsNew(true);
        setIsArchive(undefined);
        break;
      case 1:
        setIsNew(false);
        setIsArchive(undefined);
        break;
      case 2:
        setIsNew(false);
        setIsArchive(true);
        break;
      default:
        setIsNew(true);
        setIsArchive(undefined);
        break;
    }
  };

  const a11yProps = (index: number) => {
    return {
      id: `tab-${index}`,
      "aria-controls": `tabpanel-${index}`,
    };
  };

  return (
    <div>
      <HeaderNew title="Заявки" description={`Всего заявок: ${allLeads}`} />
      <Container className={classes.container}>
        <Box className={classes.tabsWrap}>
          {newLeads > 0 && (
            <Typography
              variant="body2"
              component="span"
              className={classes.newLead}
            >
              {newLeads}
            </Typography>
          )}
          <Tabs
            TabIndicatorProps={{ style: { background: "#309A90" } }}
            value={value}
            onChange={handleChange}
            aria-label="tabs"
            className={classes.tabs}
          >
            {["Новые", "Все заявки", "Архив"].map((tabName, index: number) => {
              return (
                <Tab
                  className={classes.tab}
                  label={tabName}
                  {...a11yProps(index)}
                  key={index}
                />
              );
            })}
          </Tabs>
        </Box>
        <LeadsList
          index={value}
          isNew={isNew}
          isArchive={isArchive}
          setNewLeads={setNewLeads}
          setAllLeads={setAllLeads}
        />
      </Container>
    </div>
  );
};

export default memo(PartnerApplication);
