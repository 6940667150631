export const PauseIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="1" y="1" width="22" height="22" rx="11" fill="#FFA800" />
    <rect
      x="1"
      y="1"
      width="22"
      height="22"
      rx="11"
      stroke="#FFA800"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <rect x="7" y="6" width="3" height="12" fill="white" />
    <rect x="14" y="6" width="3" height="12" fill="white" />
  </svg>
);
