import React from "react";
import InputMain from "../../../../../UIcomponentsRedesign/InputMain/InputMain";
import { useAppThunkDispatch } from "../../../../../core/store";
import { handleAutoFillFields } from "../../../../../core/store/thunk/newOrder";
import withFormHocs from "../../../../new-order/hoc/withFormHocs";
import { PropsField } from "../../../../new-order/types/bigForm";

type Props = PropsField & {
  value?: string;
};

const TextAreaField: React.FC<Props> = ({
  field,
  propsalId,
  value,
  text,
  error,
  onChange,
  parentName,
  isVisible,
  indexGroupField,
  classNameShowIf,
  handleSaveDraftOnBlur,
}) => {
  const dispatch = useAppThunkDispatch();

  const hasShowIf = field.show_if.length > 0;
  const onChangeValueInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e) {
      if (onChange) {
        onChange(
          field.name,
          e.target.value,
          propsalId,
          parentName && parentName,
          indexGroupField && indexGroupField
        );
      }
    }
  };
  const handleOnBlur = () => {
    if (field.autoFillUrl && value !== undefined) {
      dispatch(
        handleAutoFillFields(
          field.autoFillUrl,
          value,
          propsalId,
          field.name,
          parentName,
          indexGroupField
        )
      );
    }
    if (handleSaveDraftOnBlur) {
      handleSaveDraftOnBlur();
    }
  };

  return (
    <>
      {field.upSeparator && (hasShowIf ? isVisible : true) && <div className="line_x" />}
      <InputMain
        label={text}
        value={value ?? ""}
        onChange={onChangeValueInput}
        isFullWidth={true}
        onBlur={handleOnBlur}
        disabled={field.isClientBlock}
        isRequired={field.isRequeired}
        error={error}
        isTextArea={true}
        className={classNameShowIf}
      />
      {field.downSeparator && (hasShowIf ? isVisible : true) && <div className="line_x" />}
    </>
  );
};

export default withFormHocs(React.memo(TextAreaField));
