import React, { useState } from "react";
import { COLORS, InputTypeEnum } from "../../core/consts/redesign/commonRedesign";
import { TickIcon } from "../../core/consts/redesign/icons/Tick";
import { getFieldType, getInputName } from "../../core/utils/inputTypeRules";
import CustomPopover from "../CustomPopover/CustomPopover";
import classes from "./InputMain.module.scss";

type PropsType = {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  isFullWidth?: boolean;
  onBlur?: () => void;
  disabled?: boolean;
  error?: string;
  isRequired?: boolean;
  // isFilled?: boolean;\\
  inputType?: InputTypeEnum;
  className?: string;
};

const InputMainAutocomplete = ({
  value,
  onChange,
  label,
  isFullWidth,
  onBlur,
  disabled,
  error,
  isRequired,
  inputType,
  className,
}: PropsType) => {
  const ref = React.useRef<HTMLInputElement | null>(null);
  const [isFocused, setIsFocused] = useState(false);
  const popoverId = `popover-${label}`;
  const fieldName = inputType && getInputName(inputType);
  const fieldType = inputType && getFieldType(inputType);

  return (
    <div
      className={`${classes.control_box} ${isFullWidth ? classes.w100 : ""
        } ${className}`}
      onClick={() => {
        setIsFocused(true);
        ref.current?.focus();
      }}
      onBlur={() => {
        setIsFocused(false);
      }}
    >
      <div
        className={`${classes.input_box} ${classes.w100} 
          ${error ? classes.error_box : ""} 
          ${value?.length > 0 && !error ? classes.filled : ""}
          ${disabled ? classes.disabled_box : ""}`}
        key={`key_${label}`}
      >
        {label && (
          <div
            className={`${(value && value.length > 0) || isFocused
              ? classes.label_small
              : classes.label_big
              }`}
          >
            {label}
            {isRequired && <span style={{ color: "#DC5103" }}> *</span>}
          </div>
        )}
        <div className={classes.flex_betw}>
          <input
            ref={ref}
            key={label}
            // autoFocus
            className={`${classes.input} ${label ? "" : classes.input_without_label
              } ${isFocused || (value && value.length > 0) ? "" : classes.hide}`}
            value={value}
            onChange={onChange}
            onBlur={() => {
              // console.log("input onBlur");
              if (onBlur) {
                onBlur();
              }
            }}
            disabled={disabled}
            autoComplete={fieldName}
            type={fieldType}
            name={fieldName}
          />

          {value?.length > 0 && !error && !disabled ? (
            <div className={classes.tick}>
              <TickIcon color={COLORS.success} />
            </div>
          ) : null}
        </div>
        {label && <CustomPopover id={popoverId} label={label} value={value} />}
      </div>

      {error && (
        <div className="error_input" key="error">
          {error}
        </div>
      )}
    </div>
  );
};

export default React.memo(InputMainAutocomplete);
