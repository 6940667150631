import {
  Checkbox,
  FormControlLabel,
  IconButton,
  TextField,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import { ChangeEvent, memo, useMemo } from "react";
import SmallFileIcon from "../../../core/consts/images/SmallFileIcon";
import { documentType } from "../../../core/types/partnerApiTypes";

type propsType = {
  el: documentType;
  handleChangeData: (id: number, data: documentType) => void;
  id: number;
  docsAmount: number;
  handleDeleteDocForm: (id: number) => void;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      marginTop: "30px",
      borderRadius: "5px",
      // backgroundColor: "#F2F2F2",
      padding: "20px",
      position: "relative",
    },
    field: {
      width: "100%",
    },
    inputWrap: {
      display: "flex",
      justifyContent: "space-between",
    },
    inputBtn: {
      position: "relative",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "&::-webkit-file-upload-button": { cursor: "pointer" },
    },
    inputBtnText: {
      color: "#306F9A",
    },
  })
);
const acceptTypes = [".doc", ".docx", ".txt", ".pdf", ".xls", ".xlsx"];

const handleLongName = (docName: string): string[] => {
  let tmpArr = docName.split(".");
  let first = tmpArr.slice(0, tmpArr.length - 1).join(".");
  let second = tmpArr.slice(tmpArr.length - 1).join("");
  return [first, second];
};

const DocumentModalForm: React.FC<propsType> = ({
  el,
  handleChangeData,
  id,
  docsAmount,
  handleDeleteDocForm,
}) => {
  const classes = useStyles();
  const accept = useMemo(() => acceptTypes.join(","), [acceptTypes]);

  const onChangeFields = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    handleChangeData(id, { ...el, [name]: value });
  };

  const onChangeCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, name } = event.currentTarget;
    handleChangeData(id, { ...el, [name]: checked });
  };

  const onChangeFile = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      handleChangeData(id, { ...el, doc: event.target.files[0] });
    }
  };

  const handleDeleteDoc = () => {
    handleChangeData(id, { ...el, doc: null });
  };

  return (
    <div className={classes.content}>
      {docsAmount > 1 && (
        <IconButton
          style={{ position: "absolute", top: "-25px", right: "-25px" }}
          aria-label="close"
          onClick={() => handleDeleteDocForm(id)}
        >
          <CancelIcon style={{ color: "#B0BFC6" }} />
        </IconButton>
      )}
      <TextField
        onChange={onChangeFields}
        placeholder="Название документа"
        className={classes.field}
        value={el.fileName}
        name={"fileName"}
      />
      <TextField
        onChange={onChangeFields}
        placeholder="Комментарий"
        name={"Comment"}
        className={classes.field}
        style={{ marginTop: "25px" }}
        value={el.Comment}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={el.isFileNeeded}
            onChange={onChangeCheckBox}
            color="primary"
            name="isFileNeeded"
          />
        }
        style={{ marginTop: "20px", marginBottom: "20px" }}
        label="Прикрепить документ"
        value={el.isFileNeeded}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={el.isSignatureRequired}
            onChange={onChangeCheckBox}
            color="primary"
            name="isSignatureRequired"
          />
        }
        style={{ marginTop: "20px", marginBottom: "20px", marginLeft: "15px" }}
        label="Требуется ЭЦП"
        value={el.isFileNeeded}
      />
      {el.isFileNeeded && (
        <>
          <div style={{}} className={classes.inputWrap}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <SmallFileIcon />
              <Typography
                variant="body1"
                component="span"
                style={{
                  color: el.doc ? "#306F9A" : "#B0BFC6",
                  maxWidth: "380px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                &nbsp;&nbsp;
                {el.doc ? handleLongName(el.doc.name)[0] : "Документ не выбран"}
              </Typography>
              {el.doc && (
                <span
                  style={{
                    color: "#306F9A",
                  }}
                >
                  {handleLongName(el.doc.name)[1]}
                </span>
              )}
            </div>
            <div>
              <div className={classes.inputBtn}>
                <input
                  className={classes.inputBtn}
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    opacity: "0",
                    cursor: "pointer",
                  }}
                  type="file"
                  onChange={onChangeFile}
                  accept={accept}
                />
                <Typography
                  variant="body1"
                  component="span"
                  className={classes.inputBtnText}
                >
                  {el.doc ? "Заменить" : "Загрузить"}
                </Typography>
              </div>
              {el.doc && (
                <div
                  style={{
                    cursor: "pointer",
                    marginTop: "15px",
                    color: "#D62D30",
                  }}
                  onClick={handleDeleteDoc}
                >
                  Удалить
                </div>
              )}
            </div>
          </div>
          <div
            style={{
              color: "#B0BFC6",
              fontFamily: "Gilroy",
              fontWeight: 500,
              marginTop: "20px",
            }}
          >
            К загрузке доступны документы типов .doc .xls и .pdf
          </div>
        </>
      )}
    </div>
  );
};

export default memo(DocumentModalForm);
