import { Theme, createStyles, makeStyles } from "@material-ui/core";
import StatusCloud from "../../../../UIcomponentsRedesign/StatusCloud/StatusCloud";
import { COLORS } from "../../../../core/consts/redesign/commonRedesign";
import { Tick } from "../../../../core/consts/redesign/icons";
import { CloseForInputIcon } from "../../../../core/consts/redesign/icons/CloseForInput";
import { formatDateTime } from "../../../../core/helpers/dateConverter";
import { leadFullInfo, leadStatusHistoryType } from "../../../../core/types/leadsApiTypes";

type StatusLineProps = {
  status: leadStatusHistoryType;
  lead?: leadFullInfo | null;
  showTick?: boolean;
  showStick?: boolean;
  showErrorStick?: boolean;
  showEndOfStory?: boolean;
  showGreenStickForError?: boolean
}
const StatusLine: React.FC<StatusLineProps> = ({
  status,
  showTick,
  showStick,
  showErrorStick,
  showEndOfStory,
  showGreenStickForError
}) => {
  const backgroundStickColor = showGreenStickForError ? COLORS.success : (showErrorStick ? COLORS.alert : COLORS.success);
  const borderRoundColor = showErrorStick ? COLORS.alert : COLORS.gray2;

  const useStyles = makeStyles((theme: Theme) => createStyles({
    statusLine: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-start",
      gap: "16px",
      [theme.breakpoints.down(1024)]: {
        alignItems: "flex-start",
      },
    },
    textBox: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-start",
      gap: "8px",
      [theme.breakpoints.down(1024)]: {
        alignItems: "flex-start",
        flexDirection: "column",
        gap: "4px",
      },
    },
    tickIcon: {
      width: "12px",
      height: "12px",
    },
    emptyRound: {
      width: "24px",
      height: "24px",
      borderRadius: "24px",
      border: `2px solid ${borderRoundColor}`,
      backgroundColor: COLORS.white,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "relative",
      zIndex: 100
    },
    filledRound: {
      width: "24px",
      height: "24px",
      backgroundColor: showEndOfStory ? COLORS.alert : (showTick ? COLORS.success : COLORS.alert),
      borderRadius: "12px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "relative",
      zIndex: 100
    },
    text: {
      fontFamily: 'Gilroy',
      fontSize: '16px',
      lineHeight: '21px',
      fontWeight: 500,
      color: COLORS.gray2,
    },
    stick: {
      width: '2px',
      zIndex: 1,
      height: "40px",
      backgroundColor: backgroundStickColor,
      position: 'absolute',
      left: '11px',
      top: '23px',
      [theme.breakpoints.down(1024)]: {
        height: "62px",
        top: '24px',
      },
    },
    statusItem: {
      position: 'relative',
    }
  }));
  const classes = useStyles();

  return (
    <div className={classes.statusItem}>
      <div className={classes.statusLine}>
        {showTick && !showEndOfStory && (
          <div className={classes.filledRound}>
            <Tick color={COLORS.white} />
            {showStick && <div className={classes.stick} />}
          </div>
        )}
        {!showTick && !showEndOfStory && (
          <>
            <div className={classes.emptyRound} />
            {showStick && <div className={classes.stick} />}
          </>
        )}
        {showEndOfStory && (
          <div className={classes.filledRound}>
            <CloseForInputIcon color={COLORS.white} />
            {showErrorStick && <div className={classes.stick} />}
          </div>
        )}
        <div className={classes.textBox}>
          <span className={classes.text}>{formatDateTime(status.date)}</span>
          {status && <StatusCloud
            lowercase
            statusBg={status.statusColor}
            statusTextColor={status.statusFontColor}
            status={status.newStatus} />}
        </div>
      </div>
    </div>
  );
};
export default (StatusLine)