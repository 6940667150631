import registrationApi, {
  RegistrationFirstType,
  RegistrationSecondType,
} from "../api/registrationApi";
import { actions } from "../store";
import tokenService from "./TokenService";

class UserActivationService {
  registration(formData: RegistrationFirstType) {
    return registrationApi.registrarion(formData).then((data) => {
      actions.setCurrentUser({
        isAuth: false,
        inn: "",
        userId: data.id,
        phone: "",
        role: "",
        manager: null,
        nameFull: "",
        email: "",
        is_manager_approval: undefined,
        status: undefined
      });
      return data;
    });
  }
  registrationAgent(formData: any) {
    return registrationApi
      .registrationAgent(formData)
      .then((data) => {
        actions.setCurrentUser({
          isAuth: false,
          inn: "",
          userId: data.id,
          phone: "",
          role: "",
          manager: null,
          nameFull: "",
          email: "",
          is_manager_approval: undefined,
          status: undefined
        });
        return data;
      });
  }

  confirmRegistration(formData: RegistrationSecondType) {
    registrationApi
      .confirmRegistration(formData)
      .then((data: any) => {
        tokenService.removeAccessToken();
        tokenService.removeRefreshToken();
        tokenService.setAccessToken(data.access_token);
        tokenService.setRefreshToken(data.refresh_token);
        actions.fetchAuth(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }
}

const userActivationService = new UserActivationService();
export default userActivationService;
