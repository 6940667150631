import { ThemeProvider } from "@material-ui/core";
import React, { Suspense, memo, useEffect } from "react";
import { Switch, useHistory } from "react-router-dom";
import Layout from "../app/components/layout/Layout";
import NotFoundPage from "../pages/not-found/routing";
import "./App.scss";
import { ROUTES } from "./consts/common";
import { theme } from "./consts/themeDefault";
import AdminPageSubRoutes from "./routs/admin-sub-routes";
import AgentPageSubRoutes from "./routs/agent-sub-routes";
import ClientPageSubroutes from "./routs/client-sub-routes";
import MainRoutes from "./routs/main-routes";
import ManagerPageSubRoutes from "./routs/manager-sub-routes";
import PartnerSubRoutes from "./routs/partner-sub-routes";
import authService from "./services/AuthService";
import tokenService from "./services/TokenService";

const App: React.FC = (props: any) => {
  const history = useHistory();

  const refresh = async () => {
    const token = tokenService.getRefreshToken();
    if (!token) return;
    try {
      await authService.refreshLogin();
      history.push(history.location.pathname);
    } catch (e) {
      history.push(ROUTES.MAIN);
    }
  };
  useEffect(() => {
    refresh();
  }, [history]);

  return (
    <ThemeProvider theme={theme}>
      <Suspense fallback={<div> </div>}>
        <Layout>
          <Switch>
            {/* {role === ROLES.CLIENT && isAuth && {...ClientPageSubroutes}}
                {role === ROLES.AGENT && isAuth && {...AgentPageSubRoutes}} */}
            {ClientPageSubroutes}
            {AgentPageSubRoutes}
            {PartnerSubRoutes}
            {ManagerPageSubRoutes}
            {AdminPageSubRoutes}
            {MainRoutes}
            {NotFoundPage}
          </Switch>
        </Layout>
      </Suspense>
    </ThemeProvider>
  );
};

export default memo(App);
