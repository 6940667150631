export const LogoTextIcon = ({ color }: { color?: string }) => (
  <svg width="81" height="24" viewBox="0 0 81 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill={color ? color : "white"} d="M14.1204 7.42934V23.5801H11.3221V10.1427H5.37155V23.5801H2.5732V10.1427H0V7.42934H2.5732V6.94482C2.5732 2.68102 4.92124 0.549124 9.61733 0.549124C10.4965 0.549124 11.6008 0.689097 12.9303 0.969044V3.52086C11.6223 3.34859 10.518 3.26245 9.61733 3.26245C8.15919 3.26245 7.08702 3.5424 6.40083 4.10229C5.71465 4.64065 5.37155 5.58816 5.37155 6.94482V7.42934H14.1204Z" />
    <path fill={color ? color : "white"} d="M26.4163 7.00942C28.3033 7.00942 29.815 7.61238 30.9515 8.8183C32.088 10.0027 32.6563 11.6178 32.6563 13.6635V23.5801H29.8579V13.825C29.8579 12.5114 29.5041 11.4993 28.7965 10.7887C28.0888 10.0565 27.1132 9.69044 25.8694 9.69044C24.4756 9.69044 23.3499 10.1319 22.4921 11.0148C21.6344 11.8762 21.2055 13.2113 21.2055 15.0202V23.5801H18.4072V7.42934H21.2055V9.75505C22.342 7.92463 24.0789 7.00942 26.4163 7.00942Z" />
    <path fill={color ? color : "white"} d="M36.5628 23.5801V0H39.3612V23.5801H36.5628Z" />
    <path fill={color ? color : "white"} d="M56.9466 7.42934H59.745V23.5801H56.9466V20.8022C55.5528 22.9341 53.5264 24 50.8674 24C48.6159 24 46.6967 23.1817 45.1099 21.5451C43.5231 19.8869 42.7297 17.8735 42.7297 15.5047C42.7297 13.1359 43.5231 11.1332 45.1099 9.49664C46.6967 7.83849 48.6159 7.00942 50.8674 7.00942C53.5264 7.00942 55.5528 8.07537 56.9466 10.2073V7.42934ZM51.2212 21.2867C52.8509 21.2867 54.2126 20.7376 55.3062 19.6393C56.3998 18.5195 56.9466 17.1413 56.9466 15.5047C56.9466 13.8681 56.3998 12.5007 55.3062 11.4024C54.2126 10.2826 52.8509 9.72275 51.2212 9.72275C49.613 9.72275 48.2621 10.2826 47.1685 11.4024C46.0748 12.5007 45.528 13.8681 45.528 15.5047C45.528 17.1413 46.0748 18.5195 47.1685 19.6393C48.2621 20.7376 49.613 21.2867 51.2212 21.2867Z" />
    <path fill={color ? color : "white"} d="M72.8623 7.00942C75.1138 7.00942 77.033 7.83849 78.6198 9.49664C80.2066 11.1332 81 13.1359 81 15.5047C81 17.8735 80.2066 19.8869 78.6198 21.5451C77.033 23.1817 75.1138 24 72.8623 24C70.2033 24 68.1769 22.9341 66.7831 20.8022V23.5801H63.9847V0.969044H66.7831V10.2073C68.1769 8.07537 70.2033 7.00942 72.8623 7.00942ZM72.5084 21.2867C74.1167 21.2867 75.4676 20.7376 76.5612 19.6393C77.6548 18.5195 78.2016 17.1413 78.2016 15.5047C78.2016 13.8681 77.6548 12.5007 76.5612 11.4024C75.4676 10.2826 74.1167 9.72275 72.5084 9.72275C70.8787 9.72275 69.5171 10.2826 68.4235 11.4024C67.3299 12.5007 66.7831 13.8681 66.7831 15.5047C66.7831 17.1413 67.3299 18.5195 68.4235 19.6393C69.5171 20.7376 70.8787 21.2867 72.5084 21.2867Z" />
  </svg>
);


