import { Theme, Typography, createStyles, makeStyles } from "@material-ui/core";
import { v4 } from "uuid";
import { COLORS } from "../../../../core/consts/redesign/commonRedesign";
import { leadStatusHistoryType } from "../../../../core/types/leadsApiTypes";
import StatusLine from "./StatusLine";

const useStyles = makeStyles((theme: Theme) => createStyles({
  container: {
    backgroundColor: COLORS.white,
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignItems: 'flex-start',
    justifyContent: 'center',
    gap: "24px"
  },
  content: {
    width: '100%'
  },
}));

type StatusBlockProps = {
  leadStatusHistory: leadStatusHistoryType[];
  isActive: boolean
}
const StatusBlock: React.FC<StatusBlockProps> = ({
  leadStatusHistory,
  isActive

}) => {
  const classes = useStyles();
  const reversedHistory = [...leadStatusHistory].reverse();

  const entireLength = reversedHistory.length;
  const lastIndex = entireLength - 1;
  return (
    <div className={classes.container} >
      <Typography variant="body1" style={{ fontWeight: 600 }}>
        Статус заявки
      </Typography>
      {reversedHistory.map((item, index) => {
        const showErrorStick = item.newStatus === "Ошибка" || item.newStatus === "Отозвано";
        const showStick = index !== lastIndex && entireLength > 1;
        const showTick = index !== 0 || (!isActive && item.newStatus !== "Ошибка");
        const showGreenStickForError = (item.newStatus === "Ошибка" || item.newStatus === "Отозвано") && index !== 0;
        const showEndOfStory = (item.newStatus === "Ошибка" || item.newStatus === "Отозвано") && !isActive;

        return (
          item &&
          <StatusLine
            key={v4()}
            status={item}
            showStick={showStick}
            showErrorStick={showErrorStick}
            showTick={showTick}
            showEndOfStory={showEndOfStory}
            showGreenStickForError={showGreenStickForError} />
        );
      })}
    </div>
  );
};
export default (StatusBlock)