import { Theme, createStyles, makeStyles } from '@material-ui/core';
import { COLORS } from '../../../../core/consts/redesign/commonRedesign';

export const useHeaderStyles = makeStyles((theme: Theme) =>
  createStyles({
    appbar: {
      backgroundColor: COLORS.white,
      minHeight: '100%',
      height: '104px',
      padding: '24px 32px',
      boxShadow: '0px 2px 16px 0px rgba(184, 201, 224, 0.56)',
      // zIndex: 11,
      [theme.breakpoints.down(1024)]: {
        padding: '8px 1px 8px 16px',
        height: '64px'
      },
    },
    container: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      margin: 'auto 0'
    },
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down(1024)]: {
        height: 'fit-content',
      },
    },
    titleContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginRight: '35px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      gap: '16px',
      textOverflow: "ellipsis",
      [theme.breakpoints.down(1024)]: {
        gap: '8px'
      },
    },
    overflow: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: "ellipsis",
    },
    titleFlex: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center"
    },
    title: {
      // textTransform: 'lowercase',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: "ellipsis",
      fontFamily: 'Gilroy',
      fontSize: '32px',
      lineHeight: '36px',
      fontWeight: 500,
      [theme.breakpoints.down(1024)]: {
        fontSize: '24px',
        fontWeight: 600,
        lineHeight: '25px',
      },
    },
    titleCentered: {
      // textTransform: 'lowercase',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: "ellipsis",
      fontFamily: 'Gilroy',
      fontSize: '32px',
      lineHeight: '36px',
      fontWeight: 500,
      [theme.breakpoints.down(1024)]: {
        fontSize: '18px',
        fontWeight: 600,
        lineHeight: '21px',
      },
    },
    description: {
      color: COLORS.gray2,
      fontFamily: 'Gilroy',
      fontSize: '16px',
      lineHeight: '21px',
      fontWeight: 500,
    },
    descriptionCentered: {
      color: COLORS.gray2,
      fontFamily: 'Gilroy',
      fontSize: '16px',
      lineHeight: '21px',
      fontWeight: 500,
      [theme.breakpoints.down(1024)]: {
        fontSize: '14px',
        lineHeight: '19px',
      },
    },
    iconMargin: {
      // marginTop: "5px",
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: 'auto 0 50px 0'
    },

    //////////bottom menu ////////////
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      zIndex: 100,
    },
    appbarBottom: {
      position: "fixed",
      maxWidth: "98%",
      maxHeight: "100%",
      bottom: 0,
      top: 'auto',
      width: "100%",
      margin: "auto 4px 0",
      padding: "8px",
      borderRadius: "8px 8px 0 0",
      backgroundColor: COLORS.white,
      borderTop: `1px solid ${COLORS.light_gray_bg}`,
      zIndex: 101,
    },
    closeButton: {
      position: "absolute",
      top: -8,
      right: -8,
      cursor: "pointer",
    },
    titleBottom: {
      color: COLORS.black,
      fontSize: "16px",
      lineHeight: "21px",
      fontWeight: 600,
    },
    descriptionBottom: {
      color: COLORS.gray2,
      fontSize: "16px",
      lineHeight: "21px",
    },
    menuLinks: {
      listStyleType: "none",
      padding: 0,
      margin: "8px 0 0",
      "& li": {
        borderBottom: `2px solid ${COLORS.light_gray_bg}`,
        cursor: "pointer",
        color: COLORS.black,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        fontSize: "16px",
        marginTop: "8px",
        lineHeight: "21px",
        fontWeight: 500,
        height: "61px",
        "&:last-child": {
          borderBottom: "none",
        },
      },
    },
  })
);