import React from "react";
import InputMain from "../../../../../UIcomponentsRedesign/InputMain/InputMain";
import { InputTypeEnum } from "../../../../../core/consts/redesign/commonRedesign";
import { useAppThunkDispatch } from "../../../../../core/store";
import { handleAutoFillFields } from "../../../../../core/store/thunk/newOrder";
import { formatters } from "../../../../../core/utils/inputTypeRules";
import withFormHocs from "../../../../new-order/hoc/withFormHocs";
import { PropsField } from "../../../../new-order/types/bigForm";

interface NumberFormatValues {
  formattedValue: string;
  value: string;
  floatValue?: number;
}

type Props = PropsField & {
  value?: string;
  info?: string;
  regex?: string;
  inputType?: InputTypeEnum;
};

const NumberField: React.FC<Props> = ({
  field,
  propsalId,
  value,
  info,
  text,
  error,
  onChange,
  parentName,
  indexGroupField,
  className,
  classnamelabel,
  isVisible,
  classNameShowIf,
  dependentFieldValue,
  errorClassName,
  handleSaveDraftOnBlur,
}) => {
  const dispatch = useAppThunkDispatch();
  const displaySumText = field.sumText || "";

  const hasShowIf = field.show_if.length > 0;

  const onValueChangeFormatted = (values: NumberFormatValues) => {
    const { floatValue } = values;
    if (onChange && floatValue !== undefined) {
      const validNumbers = floatValue.toString();
      onChange(
        field.name,
        validNumbers,
        propsalId,
        parentName && parentName,
        indexGroupField && indexGroupField
      );
    }
  };

  const onChangeValueInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputType = field.inputType;
    const value = e.target.value;
    const formatter = formatters[inputType as InputTypeEnum];
    const formattedValue = formatter ? formatter(value, inputType) : value;

    e.target.value = formattedValue; //to display modified value

    if (onChange) {
      const validNumbers = e.target.value.replaceAll(" ", "");
      if (validNumbers.match(/^[0-9]*([.|,][0-9]*)?$/g)) {
        onChange(
          field.name,
          validNumbers,
          propsalId,
          parentName && parentName,
          indexGroupField && indexGroupField
        );
      }
    }
  };

  const handleOnBlur = () => {
    if (field.autoFillUrl && value !== undefined) {
      dispatch(
        handleAutoFillFields(
          field.autoFillUrl,
          value,
          propsalId,
          field.name,
          parentName && parentName,
          indexGroupField && indexGroupField
        )
      );
    }
    //delay to fill autofill fields before validation
    if (handleSaveDraftOnBlur) {
      setTimeout(() => {
        handleSaveDraftOnBlur();
      }, 500)
    }
  };

  return (
    <>
      {field.upSeparator && (hasShowIf ? isVisible : true) && <div className="line_x" />}
      <InputMain
        label={text}
        value={value ?? ""}
        info={info}
        onChange={onChangeValueInput}
        isFullWidth={true}
        onBlur={handleOnBlur}
        disabled={field.isClientBlock}
        isRequired={field.isRequeired}
        error={error}
        isNumber={true}
        numberFormatProps={{
          decimalSeparator: ",",
          decimalScale: 2,
          suffix: ` ${displaySumText}`,
          onValueChange: onValueChangeFormatted,
          isNumericString: true
        }}
        className={classNameShowIf}
      />
      {field.downSeparator && (hasShowIf ? isVisible : true) && <div className="line_x" />}
    </>
  );
};

export default withFormHocs(React.memo(NumberField));
