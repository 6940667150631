import { Box, createStyles, makeStyles, Theme } from "@material-ui/core";
import React, { memo } from "react";
import { COLORS } from "../../../../core/consts/redesign/commonRedesign";

/* Redesign. Tabs changer for products. */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabpanel: {
      height: ' fit-content',
      color: COLORS.white,
      '&>div': {
        height: ' fit-content',
        color: COLORS.white,
      },
    },
  })
);

type propTypes = {
  children: any;
  index: number;
  value: number;
};

const TabPanel: React.FC<propTypes> = (props) => {
  const classes = useStyles();
  const { children, value, index, ...other } = props;

  return (
    <div
      className={classes.tabpanel}
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

export default memo(TabPanel);
