import { Theme, createStyles, makeStyles } from "@material-ui/core";


export const useStylesAgentClients = makeStyles((theme: Theme) => createStyles({
    container: {
        padding: '40px 80px',
        margin: '0  auto',
        boxSizing: 'border-box',
        maxWidth: '100%',
    },
    box: {
        padding: '0 30px'
    },
    noLeadsWrap: {
        margin: '0 auto',
        paddingTop: '55px',
        paddingBottom: '35px',
        display: 'block',
        objectFit: 'cover',
        textAlign: 'center'
    },
    search: {
        maxWidth: '300px',
        width: '18%',
        height: '51px',
        marginRight: '25px',
        justifyContent: 'end',
        '&>label': {
            top: '-7px'
        },
    },
    select: {
        width: '18%',
        marginRight: '15px',
        '& div:focus': {
            backgroundColor: 'transparent',
        }
    },
    searchWrap: {
        display: 'flex',
        width: '100%',
        paddingTop: '30px',
    },
    addClientWrap: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'end',
        paddingTop: '30px',
    },
    clientsWrap: {
        minWidth: '100%',
        backgroundColor: '#ffffff',
        position: 'relative',
        maxHeight: '68vh',
        height: 'fit-content',
        minHeight: '100%',
        alignItems: 'center',
        borderRadius: '10px',
        overflowY: 'auto',
        boxShadow: 'none',
        padding: '30px',
        paddingTop: '0',
        '&::-webkit-scrollbar': {
            width: '0.5em',
            height: '0.52em',
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(208 217 222 / 88%)',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#D0D9DE',
            outline: '0.2px solid #D0D9DE',
        },
    },
    bodyContainer: {
        padding: '30px'
    },
    gridTitles: {
        display: 'flex',
        width: '100%',
        zIndex: 5,
        position: 'sticky',
        top: '0px',
        padding: '30px 0 30px 0',
        backgroundColor: '#ffffff',
    },
    gridTitle: {
        color: '#617E8C',
        fontWeight: 'normal',
    },
    gridItems: {
        display: 'flex',
        // width: '100%',
    },
    gridItem: {
        display: 'flex',
        flexDirection: 'column',
    },
    gridGardWrap: {
        display: 'flex',
        width: '100%',
        padding: '30px 0',
        '&:not(:last-child)': {
            borderBottom: '1px solid  #D0D9DE',
        },
    },
    divider: {
        margin: '30px 0',
    },
    gridItemFirst: {
        paddingBottom: '10px'
    }
}))