import { Typography } from "@material-ui/core";
import React from "react";
import {
  DevStand,
  DevStandApp,
  ProdStand,
} from "../../../core/consts/redesign/commonRedesign";

type propsType = {
  setOpen: (prev: boolean | ((prev: boolean) => boolean)) => void;
};
const BlockMobTemp: React.FC<propsType> = (props) => {
  const handleClose = () => {
    props.setOpen(false);
  };

  return (
    <>
      <Typography variant="body2" component="p">
        Личный кабинет пока не доступен для мобильных устройств, но мы активно
        работаем, чтобы как можно скорее предоставить вам полный функционал
        сервиса!
      </Typography>
      <a
        href={
          window.location.href.includes(DevStandApp) ||
          window.location.href.includes("localhost")
            ? DevStand
            : ProdStand
        }
        style={{ textDecoration: "none" }}
      >
        <button
          className="button_primary_w100"
          // onClick={handleClose}
        >
          Хорошо
        </button>
      </a>
    </>
  );
};

export default BlockMobTemp;
