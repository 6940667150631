import React, { memo, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ButtonCustom from "../../../../UIcomponentsRedesign/ButtonCustom/ButtonCustom";
import SpinnerMain from "../../../../UIcomponentsRedesign/SpinnerMain";
import ConfirmModalRedesign from "../../../../app/components/confirm-modal-redesign/ConfirmModalRedesign";
import ErrorModal from "../../../../app/components/error-modal/ErrorModal";
import { ROLES } from "../../../../core/consts/redesign/commonRedesign";
import useIsMobile from "../../../../core/hooks/useIsMobile";
import leadsService, { prepareFormData } from "../../../../core/services/LeadsService";
import { editDocsCount } from "../../../../core/store/action-creators/countsData";
import { DocsStatusEnum, LeadDocPackType, LoadingLeadDocInnerType, LoadingLeadDocPackType } from "../../../../core/types/leadsApiTypes";
import { rootState } from "../../../../core/types/rootState";
import { downloadBlobFile } from "../../../../core/utils/downloadBlobFile";
import { useStyles } from "./DocumentsStyles";
const DocumentCard = React.lazy(() => import("./DocumentCard"));
const abortControllers = new Map<number, AbortController>();

type Props = {
  leadId: number;
  isArchive: boolean;
  allDocPacksStatus: DocsStatusEnum;
  setAllDocPacksStatus: (status: DocsStatusEnum | ((prev: DocsStatusEnum) => DocsStatusEnum)) => void;
};

const Documents: React.FC<Props> = ({
  leadId,
  isArchive,
  allDocPacksStatus,
  setAllDocPacksStatus
}) => {
  const classes = useStyles();
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const initialUser = useSelector((state: rootState) => state.currentUser);
  const isManager = initialUser.role === ROLES.MANAGER;
  const [docPacksList, setDocPacksList] = useState<LeadDocPackType[]>();
  const [requiredDocPacksList, setRequiredDocPacksList] = useState<LeadDocPackType[]>();
  const [notRequiredDocPacksList, setNotRequiredDocPacksList] = useState<LeadDocPackType[]>();
  const [requiredDocsAmount, setRequiredDocsAmount] = useState<number>(0);
  const [uploadedDocsAmount, setUploadedDocsAmount] = useState<number>(0); // if even 1 file exist in doc pack
  const [renew, setRenew] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [uploadStatuses, setUploadStatuses] = useState<LoadingLeadDocPackType[]>([]);
  const [selectedFileName, setSelectedFileName] = useState<string>('');
  const [selectedFileId, setSelectedFileId] = useState<number>(0)
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [errorModal, setErrorModal] = useState<boolean>(false);
  const uploadedPackIds = docPacksList ? docPacksList.map(docPack => docPack.id) : [];
  const notAllUploaded = requiredDocsAmount !== uploadedDocsAmount;
  const allUploaded = requiredDocsAmount === uploadedDocsAmount
  const allUploadedAndSent = allDocPacksStatus === DocsStatusEnum.DocumentsSend
  const showButton = allDocPacksStatus !== DocsStatusEnum.DocumentsSend

  const fetchDocPacks = useCallback(async () => {
    try {
      const res = await leadsService.getLeadDocPacksService(leadId);
      if (res) {
        setDocPacksList(res);
        setRequiredDocPacksList(res.filter(doc => doc?.isRequired))
        setNotRequiredDocPacksList(res.filter(doc => !doc?.isRequired))
        setRequiredDocsAmount(res.filter(doc => doc?.isRequired).length);
        setUploadedDocsAmount(res.filter(doc => doc?.isRequired && (doc?.files?.length > 0)).length);
        const requiredDocsCount = res.filter(doc => doc?.isRequired).length;
        const noEmptyFileDocsCount = res.filter(doc => doc?.isRequired && (doc?.files?.length > 0)).length;
        const neededDocCount = requiredDocsCount - noEmptyFileDocsCount;
        dispatch(editDocsCount({
          leadId,
          countNeededDocs: neededDocCount,
          countUnreadMessage: 0, countUnreadNotifications: 0
        }));
      }
    } catch (error) {
      console.log(error);
      setErrorModal(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leadId]);

  useEffect(() => {
    if (leadId) {
      fetchDocPacks();
    }
  }, [leadId, renew, fetchDocPacks]);

  const tabHeaderTitle = () => {
    if (allUploadedAndSent) {
      return "Все необходимые документы отправлены";
    } else if (notAllUploaded || allUploaded) {
      return `Загружено ${uploadedDocsAmount} из ${requiredDocsAmount} необходимых документов`;
    } else {
      return "";
    }
  };

  const tabHeaderDescription = () => {
    if (allUploadedAndSent) {
      return "Пожалуйста, дождитесь ответа от банка";
    } else if (notAllUploaded || allUploaded) {
      return "Для отправки в банк нужно загрузить все необходимые документы";
    } else {
      return "";
    }
  };

  const handleFileUpload = (packId: number, files: File[] | LoadingLeadDocInnerType[], isRestart: boolean = false) => {
    const newFileStatuses = files.map((file, index) => {
      const id = isRestart && (file as LoadingLeadDocInnerType).id ? (file as LoadingLeadDocInnerType).id : Date.now() + index;
      const name = (file as File).name || (file as LoadingLeadDocInnerType).name;
      const size = +(file.size / 1024 / 1024).toFixed(2);
      const fileObject = file as File;
      return {
        id,
        packId,
        file: fileObject,
        name,
        size,
        progress: 0,
        loadedMB: '0',
        totalMB: (file.size / 1024 / 1024).toFixed(2) + ' мб',
        error: undefined
      };
    });
    setUploadStatuses(prevStatuses => {
      const existingPack = prevStatuses.find(pack => pack.packId === packId);
      if (existingPack) {
        return prevStatuses.map(pack =>
          pack.packId === packId
            ? { ...pack, files: isRestart ? pack.files : [...pack.files, ...newFileStatuses] }
            : pack
        );
      } else {
        return [...prevStatuses, { packId, files: newFileStatuses }];
      }
    });

    newFileStatuses.forEach((fileStatus) => {
      const formData = prepareFormData(packId, [fileStatus.file]);
      const abortController = new AbortController();
      abortControllers.set(fileStatus.id, abortController);

      leadsService.uploadFileService(
        packId,
        formData,
        (percentage, loadedMB, totalMB) => {
          setUploadStatuses(prevStatuses =>
            prevStatuses.map(pack =>
              pack.packId === packId
                ? {
                  ...pack,
                  files: pack.files.map(status =>
                    status.id === fileStatus.id
                      ? { ...status, progress: percentage, loadedMB, totalMB }
                      : status
                  )
                }
                : pack
            )
          );
        },
        abortController.signal)
        .then((response) => {
          const serverFileId = response[0].id;
          const serverFileSize = response[0].size;
          setUploadStatuses(prevStatuses =>
            prevStatuses.map(pack =>
              pack.packId === packId
                ? {
                  ...pack,
                  files: pack.files.map(status =>
                    status.id === fileStatus.id
                      ? { ...status, id: serverFileId, size: serverFileSize, progress: 100 }
                      : status
                  )
                }
                : pack
            )
          );
          setRenew(prev => !prev);
          // Remove the uploaded file status from uploadStatuses 2 ces delay
          setTimeout(() => {
            setUploadStatuses(prevStatuses =>
              prevStatuses.map(pack =>
                pack.packId === packId
                  ? {
                    ...pack,
                    files: pack.files.filter(status => status.id !== serverFileId)
                  }
                  : pack
              )
            );
          }, 2000);
          abortControllers.delete(fileStatus.id);
        })
        .catch(error => {
          console.error(`Upload failed for file ${fileStatus.id}:`, error);
          setUploadStatuses(prevStatuses =>
            prevStatuses.map(pack =>
              pack.packId === packId
                ? {
                  ...pack,
                  files: pack.files.map(status =>
                    status.id === fileStatus.id
                      ? { ...status, error: error.message }
                      : status
                  )
                }
                : pack
            )
          );
          abortControllers.delete(fileStatus.id);
        });

      abortController.signal.addEventListener('abort', () => {
        console.error('abort');
        setUploadStatuses(prevStatuses => prevStatuses.map(pack =>
          pack.packId === packId
            ? {
              ...pack,
              files: pack.files.filter(status => status.id !== fileStatus.id)
            }
            : pack
        ));
        abortControllers.delete(fileStatus.id);
      });
    });
  };


  const uploadFile = (packId: number, documentFiles: File[]) => {
    handleFileUpload(packId, documentFiles);
  };

  const restartUploadingFile = (packId: number, file: LoadingLeadDocInnerType) => {
    handleFileUpload(packId, [file], true);
  };

  const handleCancelUploading = (fileId: number) => {
    const abortController = abortControllers.get(fileId);
    if (abortController) {
      abortController.abort();
      abortControllers.delete(fileId);
    }
  };

  const uploadEds = (
    packId: number,
    documentFile: File
  ) => {
    leadsService.attachDocEdsService(packId, documentFile)
      .then((res) => {
        setRenew(!renew);
      })
      .catch((error) => {
        setErrorModal(true)
        console.error(error);
      });
  };

  const downloadFile = (
    docId: number,
    fileName: string
  ) => {
    setIsLoading(true)
    leadsService.getDocFileService(docId)
      .then((res) => {
        setRenew(!renew);
        downloadBlobFile(res, fileName);
      })
      .catch((error) => {
        setErrorModal(true)
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false)
      });
  };

  const deleteDocHandler = useCallback(
    (e: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>, fileId: number, fileName: string) => {
      e.stopPropagation();
      setSelectedFileId(fileId);
      setSelectedFileName(fileName);
      setDeleteModalOpen(true);
    },
    []
  );

  const closeDeleteModal = () => {
    setDeleteModalOpen(false);
    setSelectedFileName('');
    setSelectedFileId(0)
  };

  const deleteDoc = (fileId: number) => {
    leadsService
      .deleteDocService(fileId)
      .then((res) => {
        setRenew(!renew);
      })
      .catch((error: any) => {
        console.error(error);
        setErrorModal(true)
      })
      .finally(() => {
        closeDeleteModal()
      })
  };

  const handleSendToBankDocsClick = () => {
    const packIds = uploadedPackIds;
    let data = {
      leadId: leadId,
      documents: packIds
    }
    leadsService
      .sendDocPacksService(leadId.toString(), data)
      .then((res: DocsStatusEnum) => {
        setRenew(!renew);
        setAllDocPacksStatus(res)
      })
      .catch((error) => {
        console.log(error);
        setErrorModal(true)
      });
  };

  return (
    <div className={classes.docsContainer}>
      {docPacksList && docPacksList.length > 0 ? (
        <>
          {!isArchive ?
            <div className={classes.statusBox}>
              <div className={classes.descBox} style={{ gap: '4px' }}>
                <h4 className={classes.title}>
                  {tabHeaderTitle()}
                </h4>
                <span className={classes.text}>
                  {tabHeaderDescription()}
                </span>
              </div>
              {showButton && !isManager &&
                <ButtonCustom
                  color={isMobile ? "primary_w100" : "primary"}
                  disabled={notAllUploaded}
                  handleClick={() => handleSendToBankDocsClick()}
                  style={{ width: isMobile ? "100%" : "240px" }}>
                  Отправить в банк
                </ButtonCustom>}
            </div> : null}
          {requiredDocPacksList && requiredDocPacksList.length > 0 && requiredDocPacksList.map((doc: LeadDocPackType) => (
            <DocumentCard
              key={doc.id}
              doc={doc}
              showButton={showButton}
              uploadStatuses={uploadStatuses}
              handleCancelUploading={handleCancelUploading}
              handleDownloadFile={downloadFile}
              handleSelectDoc={uploadFile}
              handleSelectEds={uploadEds}
              handleDeleteDoc={(e: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>, fileId, fileName) => deleteDocHandler(e, fileId, fileName)}
              handleRestartUploading={restartUploadingFile}
              allDocPacksStatus={allDocPacksStatus}
              currentLeadArchive={isArchive}
            />
          ))}

          {notRequiredDocPacksList && notRequiredDocPacksList.length > 0 &&
            <>
              <div className={`${classes.docsContainer} ${classes.docsMargin}`}>
                <h4 className={classes.title}>
                  {'Дополнительные документы'}
                </h4>
                <span className={classes.text}>
                  {'Увеличивают вероятность одобрения банком'}
                </span>
              </div>
              {notRequiredDocPacksList.map((doc: LeadDocPackType) => (
                <DocumentCard
                  key={doc.id}
                  doc={doc}
                  showButton={showButton}
                  uploadStatuses={uploadStatuses}
                  handleCancelUploading={handleCancelUploading}
                  handleDownloadFile={downloadFile}
                  handleSelectDoc={uploadFile}
                  handleSelectEds={uploadEds}
                  handleDeleteDoc={(e: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>, fileId, fileName) => deleteDocHandler(e, fileId, fileName)}
                  handleRestartUploading={restartUploadingFile}
                  allDocPacksStatus={allDocPacksStatus}
                  currentLeadArchive={isArchive}
                />
              ))}
            </>}
        </>
      ) : (
        <SpinnerMain />
      )}
      {deleteModalOpen &&
        <ConfirmModalRedesign
          title="Удалить документ"
          text={`Вы действительно хотите удалить ${selectedFileName}?`}
          handleCancel={closeDeleteModal}
          handleConfirm={() => deleteDoc(selectedFileId)}
          confBtnText="Удалить"
          cancelBtnText="Отмена"
          open={deleteModalOpen}
        />
      }
      {isLoading &&
        <SpinnerMain fixedPosition />
      }
      {errorModal && <ErrorModal isOpen={errorModal} />}
    </div>
  );
};

export default memo(Documents);