import { createStyles, IconButton, makeStyles, Snackbar, Theme, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";


type propsType = {
  isOpen: boolean;
  handleClose: () => void;
  title: string;
  subTitle: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    text: {
      textTransform: "none",
      color: "white",
      fontSize: "14px",
      fontFamily: "Gilroy",
      fontWeight: 500,
    },
    box: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "baseline",
      width: "100%",
    },
    paper: {
      background: "linear-gradient(114.44deg, #942BD5 0%, #B239CC 100%)",
      border: "none",
      width: "436px",
      height: '111px',
      padding: '20px'
    },
  })
);

const CustomSnackBar: React.FC<propsType> = ({ isOpen, handleClose, title, subTitle }) => {
  const classes = useStyles();
  return (
    <Snackbar open={isOpen} autoHideDuration={3000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
      <div
        className={classes.paper}
        style={{
          background: "linear-gradient(114.44deg, #942BD5 0%, #B239CC 100%)",
        }}
      >
        <div>
          <div className={classes.box}>
            <Typography
              variant="body1"
              component="h3"
              style={{ color: "#fff", padding: "0 0 25px 0" }}
            >
              {title}
            </Typography>
            <IconButton
              style={{ position: "absolute", top: "5px", right: "5px" }}
              aria-label="close"
              onClick={handleClose}
            >
              <CloseIcon style={{ color: "#D0D9DE" }} />
            </IconButton>
          </div>
          <Typography variant="body2" component="p" style={{ color: "#fff", marginTop: '-10px' }}>
            {subTitle}
          </Typography>
        </div>
      </div>
    </Snackbar>
  )
}

export default CustomSnackBar;
