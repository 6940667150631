import { memo, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import { actions } from "../../../core/store";
import { FormType } from "../../../app/enum";
import authApi from "../../../core/api/authApi";
import classes from "../LoginNew.module.scss";
import { TextFieldMain } from "../../../core/consts/redesign/UIcomponents/TextFieldMain";
import { COLORS } from "../../../core/consts/redesign/commonRedesign";
import { TextFieldWhite } from "../../../core/consts/redesign/UIcomponents/TextFieldWhite";

type PropsType = {
  email?: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  isCheck?: boolean;
};

const re =
  /^(\s*)?([a-z0-9_-]+\.)*[a-z0-9_-]+@[a-z0-9_-]+(\.[a-z0-9_-]+)*\.[a-z]{2,6}(\s*)?$/;

const EmailForm: React.FC<PropsType> = ({ email, setEmail }) => {
  const [errMessage, setErrMessage] = useState<string | null>(null);
  const history = useHistory();

  const formik = useFormik({
    initialValues: {
      email: email ?? "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .matches(re, "Неверный формат email")
        .required("Обязательное поле"),
    }),
    onSubmit: (values) => {
      authApi
        .checkPhoneCode(false, undefined, values.email.trim())
        .then((res) => {
          setErrMessage(null);
          actions.setCurrentUser({
            isAuth: false,
            inn: "",
            userId: res.id,
            phone: "",
            role: "",
            manager: null,
            nameFull: "",
            email: "",
            is_manager_approval: undefined,
            status: undefined,
          });
          history.push({
            pathname: history.location.pathname,
            search: `?formType=${FormType.LoginSecondEmail}`,
          });
        })
        .catch((err) => {
          err && setErrMessage("Произошла ошибка, попробуйте позже.");
          err.response.status === 404 &&
            setErrMessage(
              "Пользователь с таким почтовым адресом не зарегистрирован"
            );
          err.response.status === 401 &&
            setErrMessage(
              "Необходимо подтвердить почту, пройдя по ссылке в письме, высланном на указанную почту"
            );
          err.response.status === 400 && setErrMessage("Некорректный email");
          err.response.status === (502 || 500) &&
            setErrMessage("Ошибка на сервере");
        });
    },
  });

  return (
    <>
      <form
        className={classes.main_column}
        onSubmit={formik.handleSubmit}
        noValidate
        autoComplete="off"
      >
        <div className={classes.input_box}>
          <TextFieldMain
            // variant="filled"
            fullWidth
            id="email"
            label={
              <div style={{ color: COLORS.gray, fontSize: "16px !important" }}>
                Электронная почта
              </div>
            }
            placeholder="example@yandex"
            autoFocus={true}
            className={classes.textFieldEmail}
            value={formik.values.email}
            onChange={(e) => {
              formik.handleChange(e);
              setEmail(e.target.value);
            }}
            inputMode="email"
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            // InputLabelProps={{
            //   shrink: true,
            // }}
            InputLabelProps={{ style: { fontSize: 20 } }}
            autoComplete="email"
          // fullWidth
          />
          {/* <TextFieldWhite
            // variant="filled"
            fullWidth
            id="email"
            label={
              <div style={{ color: COLORS.gray, fontSize: "16px !important" }}>
                Электронная почта
              </div>
            }
            placeholder="example@yandex"
            autoFocus={true}
            className={classes.textFieldEmail}
            value={formik.values.email}
            onChange={(e) => {
              formik.handleChange(e);
              setEmail(e.target.value);
            }}
            inputMode="email"
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            // InputLabelProps={{
            //   shrink: true,
            // }}
            InputLabelProps={{ style: { fontSize: 20 } }}
            // fullWidth
          /> */}
          {errMessage && <div className="error">{errMessage}</div>}
        </div>
        <button className="button_primary_w100" type="submit">
          Продолжить
        </button>
      </form>
    </>
  );
};

export default memo(EmailForm);
