import { CountsActions, countsActionTypes, dataForCounts } from "../../types/countsTypes";

export const editDocsCount = (payload: dataForCounts): CountsActions => ({
  type: countsActionTypes.EDIT_DOCS_COUNT,
  payload,
});

export const editChatsCount = (payload: dataForCounts): CountsActions => ({
  type: countsActionTypes.EDIT_CHATS_COUNT,
  payload,
});

export const editNotificationsCount = (payload: dataForCounts): CountsActions => ({
  type: countsActionTypes.EDIT_NOTIFICATIONS_COUNT,
  payload,
});

export const cleanUserCounts = (): CountsActions => ({
  type: countsActionTypes.CLEAN_USER_COUNTS,
});
