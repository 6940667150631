import { rootState } from "../../types/rootState";

export const selectNeededDocsCountByLeadId = (state: rootState, leadId: number) => {
  const leadCount = state.counts.find(count => count.leadId === leadId);
  return leadCount ? leadCount.countNeededDocs : 0;
};

export const selectChatsCountByLeadId = (state: rootState, leadId: number) => {
  const leadCount = state.counts.find(count => count.leadId === leadId);
  return leadCount ? leadCount.countUnreadMessage : 0;
};

export const selectNotificationsCountByLeadId = (state: rootState, leadId: number) => {
  const leadCount = state.counts.find(count => count.leadId === leadId);
  return leadCount ? leadCount.countUnreadNotifications : 0;
};
