import {
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import {
  useEffect,
  useState
} from "react";
import { Link } from "react-router-dom";
import HeaderNew from "../../app/components/redesign/Header/HeaderNew";
import articlesApi from "../../core/api/articlesApi";
import { ROUTES } from "../../core/consts/common";
import { dateConverter } from "../../core/helpers/dateConverter";
import ArticlesOptionSlider from "../main-admin-page/images/articlesOptionSlider";
import { article, author, rubric, tag } from "../../core/types/articlesTypes";

/* Redesign . Admin all articles page */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      maxWidth: "100%",
      padding: '40px 80px',
      margin: "0 auto",
      boxSizing: "border-box",
    },
    filters: {
      display: "flex",
      gap: "15px",
      flexWrap: "wrap",
      alignItems: "center",
    },
    filter: {
      width: "300px",
      minWidth: "300px",
    },
    table: {
      padding: "30px 0",
      background: "white",
      borderRadius: "10px",
      marginTop: "30px",
    },
    tableHead: {
      display: "grid",
      gridTemplateColumns: "3fr 2fr 2fr 3fr 2fr 1fr 1fr",
      gridTemplateAreas: "'title author rubric periods tag importantOption activeOption'",
      padding: "0 30px",
      gridGap: "20px",
      "& div": {
        color: "#617E8C",
        fontSize: "14px",
        fontFamily: "Gilroy",
        fontWeight: 500,
      },
    },
    title: {
      gridArea: "title",
      display: "flex",
      alignItems: "center",
    },
    author: {
      gridArea: "author",
    },
    rubric: {
      gridArea: "rubric",
    },
    periods: {
      gridArea: "periods",
    },
    tag: {
      gridArea: "tag",
    },
    bankBody: {
      marginTop: "10px",
    },
    tableElem: {
      display: "grid",
      gridTemplateColumns: "3fr 2fr 2fr 3fr 2fr 1fr 1fr",
      gridTemplateAreas: "'title author rubric periods tag importantOption activeOption'",
      gridGap: "20px",
      height: "104px",
      borderBottom: "1px solid #D0D9DE",
      textDecoration: "none",
      alignItems: "center",
      cursor: "pointer",
      padding: "0 30px",
      "&:hover": {
        background: "#F2F2F2",
      },
      "& div": {
        color: "#131313",
      },
    },
    importantOption: {
      gridArea: "importantOption",
    },
    activeOption: {
      gridArea: "activeOption",
    },
    btnText: {
      color: "#306F9A",
      fontFamily: "Gilroy",
      fontWeight: 500,
      fontSize: "14px",
      cursor: "pointer",
      marginTop: "10px",
    },
    btnMenu: {
      whiteSpace: "nowrap",
      padding: "18px 36px",
      display: "block",
    },
    modal: {
      width: "343px",
      background: "white",
      position: "absolute",
      top: "110px",
      boxShadow: "0px 15px 20px rgba(0, 0, 0, 0.05)",
      borderRadius: "10px",
      padding: "20px 20px 25px 20px",
    },
    button: {
      height: "51px",
      width: "100%",
    },
    text: {
      color: "inherit",
      textTransform: "none",
    },
    btnDrop: {
      width: "100%",
      textTransform: "none",
      marginTop: "5px",
    },
    btnIcon: {
      fontSize: 18,
      color: "#D0D9DE",
      marginLeft: "7px",
    },
    control: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "30px",
      alignItems: "start",
    },
  })
);

const MainArticlesPage = () => {

  const classes = useStyles();

  const [articles, setArticles] = useState<article[]>([]);

  const [totalCount, setTotalCount] = useState(0);
  const [importantId, setImportantId] = useState<number>();

  //new

  const emptyFilters = {
    status: undefined,
    important: undefined,
    tag: undefined,
    rubric: undefined,
    author: undefined,
  }
  const [choosenFilters, setChoosenFilters] = useState({
    status: undefined,
    important: undefined,
    tag: undefined,
    rubric: undefined,
    author: undefined,
  });

  const statusesList = [
    {
      "key": true,
      "text": "Активное"
    },
    {
      "key": false,
      "text": "Не активное"
    },
  ];

  const importantList = [
    {
      "key": true,
      "text": "Важное"
    },
    {
      "key": false,
      "text": "Не важное"
    },
  ];

  const [tagsList, setTagsList] = useState<tag[]>([]);
  const [rubricsList, setRubricsList] = useState<rubric[]>([]);
  const [authorsList, setAuthorsList] = useState<author[]>([]);

  //-----------

  const [isFirstRender, setFirstRender] = useState(true);

  const [offset, setOffset] = useState<number>(0);

  useEffect(() => {
    if (isFirstRender) {
      handleClickShow(0);
      setFirstRender(false);
    }
  }, [choosenFilters]);

  useEffect(() => {
    if (offset && offset < totalCount / 25) {
      articlesApi
        .getArticlesList({
          Sort: "CreateDate",
          offset: offset * 25,
          count: 25,
          ImportantId: importantId,
        })
        .then((res: any) => {
          try {
            const newData = articles.concat(res.result);
            setArticles(newData);
            setTotalCount(res.pagination.total);
            if (!importantId) { setImportantId(res.importantArticle.id) }
          } catch (e) {
            console.log(e);
          }
        });

    }
  }, [offset]);

  useEffect(() => {
    articlesApi.getArticlesRubrics().then((res: any) => setRubricsList(res));
    articlesApi.getArticlesAuthors().then((res: any) => setAuthorsList(res));
    articlesApi.getArticlesTags().then((res: any) => setTagsList(res));
  }, []);

  const body = document.getElementsByTagName("body");

  const onScroll = () => {
    if (body[0].offsetHeight <= window.scrollY + window.innerHeight) {
      setOffset((state) => state + 1);
    }
  };
  window.onscroll = () => onScroll();

  const dropFilters = () => {
    setChoosenFilters(() => ({
      ...emptyFilters,
    }));
    articlesApi
      .getArticlesList({
        Sort: "CreateDate",
        offset: 0,
        count: 25,
      })
      .then((res: any) => {
        try {
          setArticles(res.result);
          setTotalCount(res.pagination.total);
          if (!importantId) { setImportantId(res.importantArticle.id) }
        } catch (e) {
          console.log(e);
        }
      });
    setIsShowButton(false);
  };

  const handleClickShow = (offset: number) => {
    articlesApi
      .getArticlesList({
        Sort: "CreateDate",
        offset: offset * 25,
        count: 25,
        IsActive: choosenFilters.status,
        IsImportant: choosenFilters.important,
        Rubric: choosenFilters.rubric,
        Tag: choosenFilters.tag,
        Author: choosenFilters.author,
      })
      .then((res: any) => {
        try {
          setArticles(res.result);
          setTotalCount(res.pagination.total);
          if (!importantId) { setImportantId(res.importantArticle?.id) }
        } catch (e) {
          console.log(e);
        }
      });
  };

  const handleClickBtn = () => {
    handleClickShow(0);
    setIsShowButton(false);
  };

  const handleClickActive = (id: number, isActive: boolean) => {
    articlesApi.putPartData(id, isActive, undefined).then(() => {
      handleClickShow(offset);
    });
  };

  const handleClickImportant = (id: number, important: boolean) => {
    articlesApi.putPartData(id, undefined, important).then(() => {
      handleClickShow(offset);
    });
  };

  const handleChange = (event: React.ChangeEvent<any>) => {
    const { value, name } = event.target;
    setChoosenFilters((state) => ({ ...state, [name]: value }));
    setOffset(0);
    setIsShowButton(true);
  };

  const [isShowButton, setIsShowButton] = useState(false);

  // useEffect(() => { console.log(choosenFilters) }, [choosenFilters])

  return (
    <div>
      <HeaderNew title={"Редактирование статей"} />
      <Container className={classes.container}>
        <Grid>
          <div className={classes.control}>
            <div className={classes.filters}>
              {/*filter by status & importantly*/}

              <FormControl className={classes.filter}>
                <InputLabel>Только активные</InputLabel>
                <Select
                  value={choosenFilters.status}
                  onChange={handleChange}
                  name={"status"}
                >
                  {statusesList.map((sortElem: any) => (
                    <MenuItem key={sortElem.key} value={sortElem.key}>
                      {sortElem.text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl className={classes.filter}>
                <InputLabel>Важные</InputLabel>
                <Select
                  value={choosenFilters.important}
                  onChange={handleChange}
                  name={"important"}
                >
                  {importantList.map((sortElem: any) => (
                    <MenuItem key={sortElem.key} value={sortElem.key}>
                      {sortElem.text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl className={classes.filter}>
                <InputLabel>Все рубрики</InputLabel>
                <Select
                  value={choosenFilters.rubric}
                  onChange={handleChange}
                  name={"rubric"}
                >
                  {rubricsList.map((elem: rubric) => (
                    <MenuItem key={elem.id} value={elem.id}>
                      {elem.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl className={classes.filter}>
                <InputLabel>Все теги</InputLabel>
                <Select
                  value={choosenFilters.tag}
                  onChange={handleChange}
                  name={"tag"}
                >
                  {tagsList.map((elem: tag) => (
                    <MenuItem key={elem.id} value={elem.id}>
                      {elem.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl className={classes.filter}>
                <InputLabel>Все авторы</InputLabel>
                <Select
                  value={choosenFilters.author}
                  onChange={handleChange}
                  name={"author"}
                >
                  {authorsList.map((elem: author) => (
                    <MenuItem key={elem.id} value={elem.id}>
                      {`${elem.name} ${elem.surname}`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {/* {isShowButton && (
                <div className={classes.modal}>
                  <Button
                    color={"primary"}
                    variant="contained"
                    className={classes.button}
                    onClick={handleClickBtn}
                  >
                    <Typography
                      variant={"subtitle2"}
                      className={classes.text}
                      component={"span"}
                    >
                      Показать статьи
                    </Typography>
                  </Button>
                  <Button
                    color={"primary"}
                    className={classes.btnDrop}
                    variant="text"
                    onClick={dropFilters}
                  >
                    <Typography variant={"subtitle2"}>
                      Сбросить фильтры
                    </Typography>
                    <CloseIcon className={classes.btnIcon} />
                  </Button>
                </div>
              )} */}

              {isShowButton && <div onClick={handleClickBtn} className={classes.btnText}>
                Показать статьи
              </div>}

              <div onClick={dropFilters} className={classes.btnText}>
                Сбросить фильтры
              </div>
            </div>
            <Link
              to={`${ROUTES.ADMIN}/edit-articles/new-article`}
            >
              <Button
                className={classes.btnMenu}
                color="primary"
                variant="outlined"
                style={{ display: "inline-block" }}
              >
                <Typography
                  variant="body1"
                  component="span"
                  style={{ color: "inherit", textTransform: "none" }}
                >
                  Добавить статью
                </Typography>
              </Button>
            </Link>
          </div>
          <div
            style={{
              color: "#131313",
              fontFamily: "Gilroy",
              fontWeight: 500,
              fontSize: "14px",
            }}
          >
            Найдено статей: {totalCount}
          </div>
          <div
            // ref={ref}
            className={classes.table}>
            <div className={classes.tableHead}>
              <div className={classes.title}>Заголовок статьи</div>
              <div className={classes.author}>Автор</div>
              <div className={classes.rubric}>Рубрика</div>
              <div className={classes.periods}>Изменено / Создано</div>
              <div className={classes.tag}>{'Тег(и)'}</div>
              <div className={classes.importantOption}>Важная</div>
              <div className={classes.activeOption}>Активная</div>
            </div>
            <div className={classes.bankBody}>
              {articles.length ?
                articles.map((el: any, key: number) => {
                  return (
                    <Link
                      key={key}
                      to={`${ROUTES.ADMIN}/edit-articles/new-article/${el.id}`}
                      className={classes.tableElem}
                    >
                      <div
                        className={classes.title}
                        style={{ fontWeight: "600" }}
                      >
                        <div>{el.title}</div>
                      </div>
                      <div className={classes.author}>
                        {`${el.author.name} ${el.author.surname}`}
                      </div>
                      <div>
                        {el.rubrics.map((item: any) => item.name + ' ')}
                      </div>
                      <div className={classes.periods}>
                        {el.editDate ? dateConverter(el.editDate) : "-"} /{" "}
                        {dateConverter(el.createDate)}
                      </div>
                      <div className={classes.tag}>

                        {el.articlesTags.map((item: any) => item.name + ' ')}

                      </div>
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                        className={classes.importantOption}
                      >
                        <Button
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleClickImportant(el.id, !el.important);
                          }}
                        >
                          <ArticlesOptionSlider
                            isActive={el.important}
                          />
                        </Button>
                      </div>
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                        className={classes.activeOption}
                      >
                        <Button
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleClickActive(el.id, !el.isActive);
                          }}
                        >
                          <ArticlesOptionSlider
                            isActive={el.isActive}
                          />
                        </Button>
                      </div>
                    </Link>
                  );
                }) : ''}
            </div>
          </div>
        </Grid>
      </Container>
    </div>
  );
};

export default MainArticlesPage;
