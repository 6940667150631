import React, { memo, useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  Typography,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import styles from "../LoginNew.module.scss";
import { KeyTextType } from "../../../core/api/authApi";
import { COLORS } from "../../../core/consts/redesign/commonRedesign";

type propsType = {
  isOpen: boolean;
  clientType: string;
  handleClose: any;
  info: KeyTextType[];
};
const TooltipModalClientOrAgent: React.FC<propsType> = (props) => {
  const [open, setOpen] = useState(props.isOpen);
  useEffect(() => {
    setOpen(props.isOpen);
  }, [props.isOpen]);

  const handleClose = () => {
    props.handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="paper"
      // aria-labelledby="scroll-dialog-title"
      // aria-describedby="scroll-dialog-description"
      maxWidth={"sm"}
      // style={{maxWidth: "300px"}}
    >
      <DialogContent style={{ maxWidth: "486px" }}>
        <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
          <div
            style={{
              fontFamily: "Gilroy",
              color: COLORS.black,
              // padding: "8px",
            }}
          >
            {
              props.info?.find((el) => el.key === `${props.clientType}tooltip`)
                ?.text
            }
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <div
          className={styles.link_back}
          style={{ width: "100%", marginLeft: "10px" }}
        >
          <Button onClick={handleClose} color="primary">
            <div className="blue_link">Понятно</div>
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default memo(TooltipModalClientOrAgent);
