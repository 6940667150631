import { http } from "../infrastructure/Https";
import tokenService from "../services/TokenService";
import { QueryParamsGetLeads } from '../types/leadsApiTypes';
import { AgentActsData, AgentLeadsData } from './../types/agentTypes';

const ROOT_URL = '/api/v1/agent'


class AgentApi {
    getClientsList(Q: { Inn?: string, Region?: string, CompanyName?: string }) {
        const accessToken = tokenService.getAccessToken()
        return http.get(`${ROOT_URL}/clients`, Q, accessToken)
    }

    checkClientUl(Q: { Inn: string }) {
        const accessToken = tokenService.getAccessToken()
        return http.get(`${ROOT_URL}/checkul`, Q, accessToken)
    }

    registerClientUl(B: { inn: string }) {
        const accessToken = tokenService.getAccessToken()
        return http.post(`${ROOT_URL}/registerul`, null, B, accessToken)
    }

    getActs() {
        const accessToken = tokenService.getAccessToken()
        return http.get<null, AgentActsData>(`${ROOT_URL}/acts`, null, accessToken)
    }

    signActs(actsId: number[]) {
        const accessToken = tokenService.getAccessToken()
        const data = { "actsids": actsId }
        return http.put<null, typeof data, AgentActsData>(`${ROOT_URL}/acts/sign`, null, data, accessToken)
    }

    confirmSignActs(actsId: number[], code: string) {
        const accessToken = tokenService.getAccessToken()
        const data = {
            "actsids": actsId,
            "code": code
        }
        return http.put<null, typeof data, void>(`${ROOT_URL}/acts/sign/confirm`, null, data, accessToken)
    }

    cancelActs(actsId: number[]) {
        const accessToken = tokenService.getAccessToken()
        const data = {
            "actsids": actsId
        }
        return http.put<null, typeof data, void>(`${ROOT_URL}/acts/cancel`, null, data, accessToken)
    }

    getLeads(Q: QueryParamsGetLeads): Promise<AgentLeadsData> {
        const accessToken = tokenService.getAccessToken()
        return http.get<typeof Q, AgentLeadsData>(`${ROOT_URL}/leads`, Q, accessToken)
    }
}

const agentApi = new AgentApi()
export default agentApi
