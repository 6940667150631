import React, {
  memo,
  useEffect,
  useMemo,
  useState,
} from 'react';

import clsx from 'clsx';
import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  Box,
  Divider,
  Typography,
} from '@material-ui/core';

import Avatar from '../../../app/components/avatar';
import {
  ChatByType,
  TypeChat,
  messageType,
} from '../../../core/api/chatsApi';
import {
  CHAT_ROLES,
  ROLES,
} from '../../../core/consts/common';
import chatService from '../../../core/services/ChatsService';
import { actions } from '../../../core/store';
import { clientStatuses } from '../../../core/types/managerApiTypes';
import { rootState } from '../../../core/types/rootState';
import Preloader from '../../components/preloader';
import SupportModal from '../support-modal/SupportModal';
import { useStyles } from './ChatWindowStyles';
import ChatMessages from './components/chat-messages';
import MessageInput from './components/message-input';

// excludetype GET ​/api​/v1​/chats Получение списка чатов для текущего пользователя.
// none = 0 - дефолт, не должно быть, сбой и тп,
// chat =  1 - диалог с кем то,
// bank = все агенты или другая роль в системе,
// role= техподдрежка,
// managerchat = просто с менеджером,
// managerlead = с менеджером по заявке
type propsType = {
  chatId?: string;
  chatType?: string;
  leadId?: string;
  chatHelloMessage?: string;
  handleCloseModal?: () => void;
  clientId?: string;
  isInputNeeded?: boolean;
  isArchive?: boolean;
};

const saveByteArray = (function () {
  const a = document.createElement("a");
  document.body.appendChild(a);
  return function (data: any, name: string) {
    const blob = new Blob([data]),
      url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = name;
    a.click();
    window.URL.revokeObjectURL(url);
  };
})();

const ChatWindow: React.FC<propsType> = (props) => {
  const classes = useStyles();
  const [chatHelloMessage, setChatHelloMessage] = useState<string>("");
  const [currentChatAvatar, setCurrentChatAvatar] = useState<string>("");
  const [messagesList, setMessagesList] = useState<messageType[]>([]);
  const [currentChatId, setCurrentChatId] = useState<number | undefined>(
    props.chatId ? parseInt(props.chatId) : undefined
  );
  const [currentChatName, setCurrentChatName] = useState<string>();
  const [modalText, setModalText] = useState<string>(
    "Похоже с чатом возникли проблемы, пожалуйста, оставьте заявку на обратную связь, и мы вам обязательно ответим!"
  );
  const [renew, setRenew] = useState(true);
  const [unreadIncomeMessages, setUnreadIncomeMessages] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const initialUser = useSelector((state: rootState) => state.currentUser);
  const dispatch = useDispatch();
  const chatLength = useSelector(
    (s: rootState) => s.currentChat.chatMessagesLength
  );
  const chatId = useSelector((s: rootState) => s.currentChat.chatId);
  const resProcessing = (res: ChatByType) => {
    setMessagesList(res.chatMessages.reverse());
    chatLength !== res.chatMessages.length &&
      dispatch(
        actions.setCurrentChatData({
          chatMessagesLength: res.chatMessages.length,
          chatId: res.chatId,
        })
      );

    if (res.chatId) {
      setCurrentChatId(res.chatId);
    }
    setCurrentChatAvatar(
      initialUser.role !== ROLES.MANAGER &&
        initialUser.manager &&
        initialUser.manager.avatar_url &&
        (res.typeChat === TypeChat.ManagerLead || res.typeChat === TypeChat.ManagerChat)
        ? initialUser.manager && initialUser.manager.avatar_url
        : res.nameChat
          ? res.nameChat.indexOf("%") !== -1
            ? res.nameChat.split("%")[1]
            : res.nameChat.replaceAll('"', "")
          : "No Name"
    );
    setCurrentChatName(res.nameInterlocutor);
    if (res.typeChat === "Role")
      setCurrentChatAvatar(
        initialUser.role === ROLES.MANAGER
          ? res.nameInterlocutor
          : "Тех поддержка"
      );
    else if (res.typeChat === TypeChat.ManagerLead || res.typeChat === TypeChat.ManagerChat) {
      if (initialUser.role === ROLES.MANAGER)
        setCurrentChatAvatar(
          res.roleInterlocutor === "agent"
            ? CHAT_ROLES.agent
            : CHAT_ROLES.client
        );
    }

    setUnreadIncomeMessages(
      res.chatMessages.filter((message) => {
        return !message.isMyMessage && !message.isRead;
      }).length
    );

    if (res.typeChat === "Bank") {
      dispatch(
        actions.setCurrentChatData({
          bankName:
            initialUser.role !== ROLES.PARTNER
              ? res.nameChat
              : res.nameInterlocutor,
        })
      );
      let message = res.chatMessages[res.chatMessages.length - 1];
      res.chatMessages
        ? actions.setCurrentChatData({
          isMyMessageBank: message?.isMyMessage,
          lastMessageBank: message?.message,
          lastTimeMessageBank: message?.dateMessage,
          countUnreadMessageBank: unreadIncomeMessages,
        })
        : dispatch(
          actions.setCurrentChatData({
            isMyMessageBank: false,
            lastMessageBank: "",
            lastTimeMessageBank: "",
            countUnreadMessageBank: 0,
          })
        );
    }
    if (res.typeChat === TypeChat.ManagerLead) {
      dispatch(
        actions.setCurrentChatData({
          nameChat: res.nameInterlocutor,
          roleChat: res.roleInterlocutor,
        })
      );
      let message = res.chatMessages.pop();
      res.chatMessages
        ? dispatch(
          actions.setCurrentChatData({
            isMyMessage: message?.isMyMessage,
            lastMessage: message?.message,
            lastTimeMessage: message?.dateMessage,
            countUnreadMessage: unreadIncomeMessages,
          })
        )
        : dispatch(
          actions.setCurrentChatData({
            isMyMessage: false,
            lastMessage: "",
            lastTimeMessage: "",
            countUnreadMessage: 0,
          })
        );
    }
    res.chatMessages.length > 0 &&
      setChatHelloMessage(props.chatHelloMessage || "");
  };

  useEffect(() => {
    if (initialUser.status === clientStatuses.NotCompleteRegistration) {
      return;
    }
    if (props.clientId) {
      chatService
        .getMessagesListManagerChatByLeadIdService(undefined, props.clientId)
        .then((res: ChatByType) => {
          if ((res.chatId && res.chatId > 0) || props.chatId) {
            resProcessing(res);
          } else {
            setOpenModal(true);
          }
        })
        .catch((error: any) => {
          if (error.response && error.response.status === 400) {
            setModalText(error.response.data);
          }
          setOpenModal(true);
          console.log(error);
        });
    } else if (props.chatId) {
      chatService
        .getMessagesListByIdService(props.chatId)
        .then((res: ChatByType) => {
          if ((res.chatId && res.chatId > 0) || props.chatId) {
            resProcessing(res);
          } else {
            setOpenModal(true);
          }
        })
        .catch((error: any) => {
          if (error.response && error.response.status === 400) {
            setModalText(error.response.data);
          }
          setOpenModal(true);
          console.log(error);
        });
    } else if (props.chatType === "manager" && props.leadId !== "" && props.leadId !== undefined) {
      chatService
        .getMessagesListManagerChatByLeadIdService(+props.leadId)
        .then((res: ChatByType) => {
          if ((res.chatId && res.chatId > 0) || props.chatId) {
            resProcessing(res);
          } else {
            setOpenModal(true);
          }
        })
        .catch((error: any) => {
          if (error.response && error.response.status === 400) {
            setModalText(error.response.data);
          }
          setOpenModal(true);
          console.log(error);
        });
    } else if (
      props.chatType === "manager" &&
      props.leadId === "" &&
      initialUser.role !== ROLES.MANAGER
    ) {
      chatService
        .getMessagesListManagerChatByLeadIdService()
        .then((res: ChatByType) => {
          if ((res.chatId && res.chatId > 0) || props.chatId) {
            resProcessing(res);
          } else {
            setOpenModal(true);
          }
        })
        .catch((error: any) => {
          if (error.response && error.response.status === 400) {
            setModalText(error.response.data);
          }
          setOpenModal(true);
          console.log(error);
        });
    } else if (
      props.chatType === "" &&
      initialUser.role !== ROLES.MANAGER &&
      props.leadId !== ""
    ) {
      props.leadId &&
        chatService
          .getMessagesListByLeadIdService(+props.leadId)
          .then((res: ChatByType) => {
            if ((res.chatId && res.chatId > 0) || props.chatId) {
              resProcessing(res);
            } else {
              setOpenModal(true);
            }
          })
          .catch((error) => {
            if (error.response && error.response.status === 400) {
              setModalText(error.response.data);
            }
            setOpenModal(true);
            console.log(error);
          });
    } else if (props.chatType && props.chatType !== "manager") {
      chatService
        .getMessagesListByChatTypeService(props.chatType)
        .then((res: ChatByType) => {
          if ((res.chatId && res.chatId > 0) || props.chatId) {
            resProcessing(res);
          } else {
            setOpenModal(true);
          }
        })
        .catch((error: any) => {
          if (error.response && error.response.status === 400) {
            setModalText(error.response.data);
          }
          setOpenModal(true);
          console.log(error);
        });
    }
  }, [renew, initialUser]);

  //Смена renew для отправки нового запроса в useEffect выше, чтобы получить обновления.
  useEffect(() => {
    const timer = setInterval(() => {
      setRenew(!renew);
    }, 10000);
    return () => clearInterval(timer);
  });

  //Через 3 секунды устанавливает что сообщения прочитаны.
  useEffect(() => {
    setTimeout(() => {
      if (currentChatId && unreadIncomeMessages > 0) {
        readMessages(currentChatId);
        setUnreadIncomeMessages(0);
      }
    }, 3000);
  }, [currentChatId, unreadIncomeMessages]);

  const readMessages = (id: number) => {
    chatService
      .readIncomeMessagesByChatIdService({ chatId: id })
      .then((res) => {
        setRenew(!renew);
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          setModalText(error.response.data);
        }
        setOpenModal(true);
        console.log(error);
      });
  };

  const handleMessageSend = (message: string) => {
    const id = currentChatId || chatId
    if ((id && id > 0)) {
      const B = {
        chatId: id,
        text: message,
      };
      chatService
        .addMessageToChatService(B)
        .then((res: any) => {
          setRenew(!renew);
        })
        .catch((error) => {
          if (error.response && error.response.status === 400) {
            setModalText(error.response.data);
          }
          setOpenModal(true);
          console.log(error);
        });
    }
  };

  const downloadDoc = (id: string, name: string): void => {
    chatService
      .getDocFromChatService(id)
      .then((res: any) => {
        saveByteArray(res, name);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSelectFile = (event: any) => {
    currentChatId &&
      chatService
        .addDocToChatService(currentChatId?.toString(), event.target.files[0])
        .then((res: any) => {
          setRenew(!renew);
        })
        .catch((error) => {
          console.log(error);
        });
  };

  return (
    <Box className={classes.wrapBox} style={{ display: "flex" }}>
      {useMemo(
        () =>
          !currentChatAvatar && !currentChatName ? (
            <div
              style={{
                height: "100%",
                maxHeight: "82px",
                overflow: "hidden",
              }}
            >
              <Preloader preloaderType={"block"} sizePixels={"80px"} />
            </div>
          ) : (
            <Box
              className={clsx(classes.boxSender, {
                [classes.boxSupportSender]: props.chatType === "support",
              })}
            >
              <Avatar
                info={currentChatAvatar}
                size="small"
                currentChatName={currentChatName}
              />
              &nbsp;&nbsp;&nbsp;
              <Box className={classes.messageInfoWrap}>
                <Typography variant="h4" component="p">
                  {currentChatName}
                </Typography>
              </Box>
            </Box>
          ),
        [currentChatAvatar, currentChatName, props.chatType]
      )}
      <Divider />
      {useMemo(
        () =>
          !messagesList ? (
            <div style={{ height: "100%", padding: "215px 0" }}>
              <Preloader preloaderType={"block"} sizePixels={"120px"} />
            </div>
          ) : (
            <>
              <ChatMessages
                chatType={props.chatType}
                chatHelloMessage={chatHelloMessage}
                messagesList={messagesList}
                downloadDoc={downloadDoc}
              />
              {!props.isInputNeeded && !props.isArchive && (
                <div
                  style={{
                    padding: `${props.chatType === "support"
                      ? "30px 30px 0px"
                      : "30px 0px"
                      }`,
                  }}
                >
                  <MessageInput
                    handleSelectFile={handleSelectFile}
                    handleMessageSend={handleMessageSend}
                  />
                </div>
              )}
            </>
          ),
        [messagesList, chatLength, chatHelloMessage, props.chatType]
      )}

      {initialUser.status &&
        initialUser.status !== clientStatuses.NotCompleteRegistration && (
          <SupportModal
            isOpen={openModal}
            setOpenModal={setOpenModal}
            handleCloseChatWindowModal={props.handleCloseModal}
            text={`${typeof modalText === "string" ? modalText : "Что-то пошло не так"
              }`}
          />
        )}
    </Box>
  );
};

export default memo(ChatWindow);
