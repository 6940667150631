import {
  OutlinedInputProps,
  TextField,
  TextFieldProps,
  styled,
} from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import { COLORS } from "../commonRedesign";

export const TextFieldMain = styled((props: TextFieldProps) => (
  <TextField
    InputProps={{ disableUnderline: true } as Partial<OutlinedInputProps>}
    fullWidth
    inputProps={{ style: { fontSize: 21 } }}
    // InputLabelProps={{ style: { fontSize: 40 } }}
    {...props}
  // variant="filled"
  />
))(({ theme }) => {
  return {
    "& .MuiFormControl-root": {
      borderBottom: `2px solid ${COLORS.light_gray_bg} !important`,
    },
    "& .MuiInput-root": {
      color: COLORS.gray,
      backgroundColor: COLORS.white,
      fontSize: "21px",
    },

    ".MuiInputLabel-standard": {
      color: COLORS.gray,
      borderBottom: `2px solid ${COLORS.light_gray_bg}`,
    },
    ".MuiInputBase-adornedStart": {
      border: "none",
    },

    "& .MuiFilledInput-root": {
      fontSize: "21px",
      overflow: "hidden",
      height: "73px",
      minWidth: "150px",
      border: "none",
      backgroundColor: `${COLORS.white} !important`,
      borderBottom: `2px solid ${COLORS.light_gray_bg}`,
      color: COLORS.black,
      transition: theme.transitions.create([
        "border-color",
        "background-color",
        "box-shadow",
      ]),

      "&:before": {
        borderBottom: `2px solid ${COLORS.light_gray_bg}`,
      },
      "&:hover": {
        backgroundColor: "transparent",
        borderBottom: `2px solid ${COLORS.brand_blue}`,
      },
      "&.Mui-focused": {
        backgroundColor: COLORS.white,
        borderBottom: `2px solid ${COLORS.brand_blue}`,
        color: COLORS.black,
      },
      "&.Mui-disabled": {
        backgroundColor: COLORS.light_gray_bg,
        borderBottom: `2px solid ${COLORS.light_gray_stroke}`,
        // cursor: "not-allowed"
      },
      "&.Mui-error": {
        borderBottom: `2px solid ${COLORS.alert}`,
      },
      "&:placeholder": {
        color: "red",
      },
      "&:label": {
        color: "red",
      },

      "& .MuiFilledInput-underline": {
        // backgroundColor: "#FFF",
        fontSize: "21px",
        //undeline delete
        "&:before": {
          borderBottomColor: "transparent",
        },
        "&:hover:not(.Mui-disabled)::before": {
          borderBottomColor: "transparent",
        },
      },
    },

    "& .MuiFilledInput-input": {
      paddingLeft: "0px",
      fontSize: "21px",
      "&:focus": {
        backgroundColor: `${COLORS.white} !important`,
      },
      borderBottom: `2px solid ${COLORS.light_gray_bg}`,
    },

    "& .MuiInputLabel-root": {
      left: "-12px",
      top: "-8px",
      fontSize: "20px",
      "&:filled": {
        backgroundColor: COLORS.white,
      },
    },
  };
});

export const TextFieldMainForPhone = (props: TextFieldProps) => {
  return (
    <TextFieldMain
      InputProps={{ disableUnderline: true } as Partial<OutlinedInputProps>}
      {...props}
      fullWidth
      inputProps={{ style: { fontSize: 21 } }}
      InputLabelProps={{ style: { fontSize: 40 } }}
      variant="filled"
    />
  );
};

export const TextFieldDate = styled((props: any) => (
  <DatePicker
    InputProps={{ disableUnderline: true } as Partial<OutlinedInputProps>}
    slotProps={{
      textField: {
        // helperText: 'MM/DD/YYYY',
        disableUnderline: true,
      },
    }}
    disableUnderline={true}
    outline="none"
    {...props}
  />
))(({ theme }) => ({
  width: "100%",
  "& .MuiInputBase-input": {
    border: "none",
    color: COLORS.black,
    backgroundColor: COLORS.white,
  },
  "& .MuiFilledInput-root": {
    overflow: "hidden",
    height: "73px",
    minWidth: "150px",
    // maxWidth: "200px",
    border: "none",
    backgroundColor: `${COLORS.white} !important`,
    borderBottom: `2px solid ${COLORS.light_gray_bg}`,
    color: COLORS.black,
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),

    "&:before": {
      borderBottom: `2px solid ${COLORS.light_gray_bg}`,
    },
    "&:hover": {
      backgroundColor: "transparent",
      borderBottom: `2px solid ${COLORS.brand_blue}`,
    },
    "&.Mui-focused": {
      backgroundColor: COLORS.white,
      borderBottom: `2px solid ${COLORS.brand_blue}`,
      color: COLORS.black,
    },
    "&.Mui-disabled": {
      backgroundColor: COLORS.light_gray_bg,
      borderBottom: `2px solid ${COLORS.light_gray_stroke}`,
      // cursor: "not-allowed"
    },
    "&.Mui-error": {
      borderBottom: `2px solid ${COLORS.alert}`,
    },
    "&::placeholder": {
      color: "red",
    },
    "&::label": {
      color: "red",
    },

    "& .MuiFilledInput-underline": {
      backgroundColor: "#FFF",
      //undeline delete
      "&:before": {
        borderBottomColor: "transparent",
      },
      "&:hover:not(.Mui-disabled)::before": {
        borderBottomColor: "transparent",
      },
    },
  },

  "& .MuiFilledInput-input": {
    paddingLeft: "0px",
    "&:focus": {
      backgroundColor: COLORS.white,
    },
  },

  "& .MuiInputLabel-root": {
    left: "-12px",
  },

  "& .MuiFilledInput-underline": {
    //undeline delete
    "&:before": {
      borderBottomColor: "transparent",
    },
    "&:hover:not(.Mui-disabled)::before": {
      borderBottomColor: "transparent",
    },
  },

  "& .MuiInputAdornment-root": {
    backgroundColor: "#FFF",
    border: "none",
    //undeline delete
    "&:before": {
      borderBottomColor: "transparent",
    },
    "&:hover:not(.Mui-disabled)::before": {
      borderBottomColor: "transparent",
    },
  },
}));
