export const StatisticIcon = ({ color }: { color?: string }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_5514_29562)">
      <path fill={color ? color : "white"} d="M23 22H3C2.73478 22 2.48043 21.8946 2.29289 21.7071C2.10536 21.5196 2 21.2652 2 21V1C2 0.734784 1.89464 0.48043 1.70711 0.292893C1.51957 0.105357 1.26522 0 1 0C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1L0 21C0 21.7956 0.31607 22.5587 0.87868 23.1213C1.44129 23.6839 2.20435 24 3 24H23C23.2652 24 23.5196 23.8946 23.7071 23.7071C23.8946 23.5196 24 23.2652 24 23C24 22.7348 23.8946 22.4804 23.7071 22.2929C23.5196 22.1054 23.2652 22 23 22Z" />
      <path fill={color ? color : "white"} d="M15 20.0005C15.2652 20.0005 15.5196 19.8951 15.7071 19.7076C15.8947 19.5201 16 19.2657 16 19.0005V12.0005C16 11.7353 15.8947 11.4809 15.7071 11.2934C15.5196 11.1058 15.2652 11.0005 15 11.0005C14.7348 11.0005 14.4804 11.1058 14.2929 11.2934C14.1054 11.4809 14 11.7353 14 12.0005V19.0005C14 19.2657 14.1054 19.5201 14.2929 19.7076C14.4804 19.8951 14.7348 20.0005 15 20.0005Z" />
      <path fill={color ? color : "white"} d="M7 20.0005C7.26521 20.0005 7.51957 19.8951 7.7071 19.7076C7.89464 19.5201 8 19.2657 8 19.0005V12.0005C8 11.7353 7.89464 11.4809 7.7071 11.2934C7.51957 11.1058 7.26521 11.0005 7 11.0005C6.73478 11.0005 6.48043 11.1058 6.29289 11.2934C6.10536 11.4809 6 11.7353 6 12.0005V19.0005C6 19.2657 6.10536 19.5201 6.29289 19.7076C6.48043 19.8951 6.73478 20.0005 7 20.0005Z" />
      <path fill={color ? color : "white"} d="M19 20C19.2652 20 19.5196 19.8946 19.7071 19.7071C19.8947 19.5196 20 19.2652 20 19V7C20 6.73478 19.8947 6.48043 19.7071 6.29289C19.5196 6.10536 19.2652 6 19 6C18.7348 6 18.4804 6.10536 18.2929 6.29289C18.1054 6.48043 18 6.73478 18 7V19C18 19.2652 18.1054 19.5196 18.2929 19.7071C18.4804 19.8946 18.7348 20 19 20Z" />
      <path fill={color ? color : "white"} d="M11 20C11.2652 20 11.5196 19.8946 11.7071 19.7071C11.8947 19.5196 12 19.2652 12 19V7C12 6.73478 11.8947 6.48043 11.7071 6.29289C11.5196 6.10536 11.2652 6 11 6C10.7348 6 10.4804 6.10536 10.2929 6.29289C10.1054 6.48043 10 6.73478 10 7V19C10 19.2652 10.1054 19.5196 10.2929 19.7071C10.4804 19.8946 10.7348 20 11 20Z" />
    </g>
    <defs>
      <clipPath id="clip0_5514_29562">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
