import {
  Container
} from "@material-ui/core";
import { memo } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Preloader from "../../app/components/preloader";
import HeaderNew from "../../app/components/redesign/Header/HeaderNew";
import { ROLES, ROUTES } from "../../core/consts/common";
import * as selectors from "../../core/store/selectors";
import NewOrderRedesign from "../new-order-redesign/NewOrderRedesign";
import { useStylesNewOrder } from "./useStylesNewOrder";

/* Redesign */

const NewOrder: React.FC = () => {
  const history = useHistory();
  const classes = useStylesNewOrder();
  const role = useSelector(selectors.role);
  const isFetching = useSelector(selectors.isFetching);
  const propsalsList = useSelector(selectors.propsalsList);
  const draftPropsalIds = useSelector(selectors.draftPropsalIds);
  if (propsalsList.length === 0 && draftPropsalIds.length === 0) {
    switch (role) {
      case ROLES.CLIENT:
        history.push(`${ROUTES.CLIENT}/products`);
        break;
      case ROLES.AGENT:
        history.push(`${ROUTES.AGENT}/products`);
        break;
      case ROLES.MANAGER:
        history.push(`${ROUTES.MANAGER}/products`);
        break;
      case ROLES.ADMIN:
        history.push(`${ROUTES.ADMIN}/products`);
        break;
      default:
        break;
    }
  }

  return (
    <div>
      <HeaderNew title={"Новая заявка"} showGoBack={true} />
      <Container className={classes.container}>
        {isFetching && <Preloader preloaderType={"fixed"} />}
        <NewOrderRedesign />
      </Container>
    </div>
  );
};

export default memo(NewOrder);
