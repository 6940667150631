import {
  Box,
  Button,
  Container,
  InputAdornment,
  Paper,
  TextField,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import React, { memo, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { v4 } from "uuid";
import HeaderNew from "../../app/components/redesign/Header/HeaderNew";
import { konteragentsListType } from "../../core/api/konteragentApi";
import { ROUTES } from "../../core/consts/common";
import { SearchIcon } from "../../core/consts/images/SearchIcon";
import kontragentService from "../../core/services/KontragentService";
import CounterpartyShortCard from "./components/counterparty-short-card";

/* Redesign */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      margin: "0 auto",
      boxSizing: "border-box",
      padding: '40px 80px',
    },
    search: {
      flexBasis: "100%",
      backgroundColor: "#ffffff",
      boxSizing: "border-box",
      borderRadius: '10px',
      padding: '20px',
      "& input": {
        marginTop: "20px",
        marginLeft: "-20px",
        width: "80%",
        height: "100%",
      },
    },
    form: {
      display: "flex",
      width: "100%",
      height: "85px",
      justifyContent: "space-between",
      alignItems: "center",
      boxSizing: "border-box",
      backgroundColor: "#ffffff",
      position: "relative",
      borderRadius: "10px",
      margin: "20px 0",
      "&>div>div:before": {
        display: "none",
      },
      "& div::before": {
        display: "none",
      },
      "& div::after": {
        display: "none",
      },
      "& input": {
        padding: "0 45px 25px",
        width: "80%",
        height: "100%",
      },
    },
    button: {
      marginRight: '20px',
      flexBasis: "10%",
      height: "51px",
      position: "absolute",
      right: 0,
      top: " 50%",
      transform: "translateY(-50%)",
      boxSizing: "border-box",
      padding: "0 35px",
    },
    paper: {
      padding: theme.spacing(3),
      marginTop: theme.spacing(2),
      borderRadius: "10px",
      "& p": {
        color: "#13131391",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        "& span": {
          display: "none",
        },
      },
      "&:hover": {
        cursor: "pointer",
        "& p": {
          color: "#306F9A",
          "& svg>path": {
            stroke: "#306F9A",
          },
        },
        "& p>span": {
          display: "inline-block",
        },
      },
    },
  })
);

type PropsType = {
  location: {
    state: {
      searchVal: string;
      isUL: boolean;
      isIP: boolean;
      isActive: boolean;
    };
  };
};

const ClientCounterpartySearchPage: React.FC<PropsType> = (props) => {
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    if (!props.location.state) {
      history.push("/client/counterparty-check");
    }
  }, [props.location.state]);

  const [searchValue, setSearchValue] = useState<string>(
    props.location.state?.searchVal
  );
  const isUL = props.location.state?.isUL;
  const isIP = props.location.state?.isIP;
  const isActive = props.location.state?.isActive;
  const [kontragentSearchList, setkontragentSearchList] =
    useState<konteragentsListType[]>();
  const [isDisabled, setIsDisablled] = useState(true);
  const [inputError, setInputError] = useState(false);

  const handleSearchChange = (e: any) => {
    setSearchValue(e.target.value);
    if (searchValue !== "") setIsDisablled(false);
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    setInputError(false);
    if (searchValue === "") setInputError(true);
    else {
      kontragentService
        .searchKontragentService(searchValue, isUL, isIP, isActive)
        .then((res) => {
          setkontragentSearchList(res);
        })
        .catch((error) => {
          if (error.response.status === 404) setkontragentSearchList([]);
          console.log(error);
        });
    }
  };

  useEffect(() => {
    kontragentService
      .searchKontragentService(searchValue, isUL, isIP, isActive)
      .then((res) => {
        setkontragentSearchList(res.reverse());
      })
      .catch((error) => {
        if (error.response.status === 404) setkontragentSearchList([]);
        console.log(error);
      });
  }, []);

  const handleCounterpartyCardClick = (id: string) => {
    history.push(
      `${ROUTES.CLIENT}/counterparty-check/counterparty-info/${id}`,
      {
        searchVal: searchValue,
        isUL: isUL,
        isIP: isIP,
        isActive: isActive,
      }
    );
  };

  return (
    <div>
      <HeaderNew title={"Проверка контрагента"} showGoBack />
      <Container className={classes.container}>
        <Box style={{ minHeight: "calc(100vh - 150px)", padding: "30px" }}>
          <form className={classes.form} noValidate onSubmit={handleSubmit}>
            <TextField
              color="primary"
              type="search"
              variant="standard"
              style={{ width: "100%" }}
              onChange={handleSearchChange}
              className={classes.search}
              defaultValue={searchValue}
              error={inputError}
              helperText={inputError ? "Необходимо ввести значение" : ""}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    style={{ marginLeft: "30px" }}
                    position="start"
                  >
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <Button
              color="primary"
              variant="text"
              type="submit"
              disabled={isDisabled}
              className={classes.button}
            >
              <Typography
                variant="body1"
                component="span"
                style={{ color: "inherit", textTransform: "none" }}
              >
                Найти
              </Typography>
            </Button>
          </form>
          {kontragentSearchList && (
            <Typography
              variant="h4"
              component="h4"
              style={{ padding: "10px 30px" }}
            >
              {kontragentSearchList!.length > 0
                ? "Результаты поиска"
                : "К сожалению, мы ничего не нашли по вашему запросу"}
            </Typography>
          )}
          {kontragentSearchList &&
            kontragentSearchList!.length > 0 &&
            kontragentSearchList?.map((agent: konteragentsListType) => {
              return (
                agent.inn && (
                  <Paper
                    className={classes.paper}
                    onClick={() => handleCounterpartyCardClick(agent.id)}
                    key={v4()}
                  >
                    <CounterpartyShortCard
                      id={agent.id}
                      companyName={agent.companyName}
                      inn={agent.inn}
                      registerDate={agent.registerDate}
                      registerAddress={agent.registerAddress}
                    />
                  </Paper>
                )
              );
            })}
        </Box>
      </Container>
    </div>
  );
};

export default memo(ClientCounterpartySearchPage);
