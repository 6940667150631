import App from './core/App';
import {Provider} from 'react-redux';
import {store} from './core/store';
import {BrowserRouter as Router} from 'react-router-dom';
import ConnectedRouter from './core/components/ConnectedRouter';
import {hydrate, render} from "react-dom";

const appWithProviders = <Provider store={store}>
    <Router>
        <ConnectedRouter>
            <App />
        </ConnectedRouter>
    </Router>
</Provider>

const rootElement = document.getElementById("root");
if (rootElement !== null && rootElement.hasChildNodes()) {
    hydrate(appWithProviders, rootElement);
} else {
    render(appWithProviders, rootElement);
}
