import { TypeChat, messageType, messageUploadingType } from "../api/chatsApi";

export type dataForUserChat = {
  nameChat?: string,
  chatId?: number,
  bankName?: string,
  leadId?: number,
  avatar_url?: string,
  isMyMessage?: boolean,
  lastMessage?: string,
  lastTimeMessage?: string,
  countUnreadMessage?: number,
  chatMessages?: (messageType | messageUploadingType)[];
  chatType?: TypeChat,
  roleChat?: string,
  productName?: string,
  isMyMessageBank?: boolean,
  lastMessageBank?: string,
  lastTimeMessageBank?: string,
  countUnreadMessageBank?: number,
  chatMessagesLength?: number,
}

export enum currentChatType {
  SET_USER_CHAT = 'SET_USER_CHAT',
  CLEAN_USER_CHAT = 'CLEAN_USER_CHAT',
  ADD_MESSAGE = 'ADD_MESSAGE',
  UPDATE_MESSAGE = 'UPDATE_MESSAGE',
  REMOVE_MESSAGE = 'REMOVE_MESSAGE',
}

export interface SetCurrentChatAction {
  type: currentChatType.SET_USER_CHAT;
  payload?: dataForUserChat;
}
export interface CleanCurrentChatAction {
  type: currentChatType.CLEAN_USER_CHAT;
}

export interface AddMessageAction {
  type: typeof currentChatType.ADD_MESSAGE;
  payload: messageUploadingType;
}

export interface UpdateMessageAction {
  type: typeof currentChatType.UPDATE_MESSAGE;
  payload: messageUploadingType;
}

export interface RemoveMessageAction {
  type: typeof currentChatType.REMOVE_MESSAGE;
  payload: number;
}

export type ChatActions =
  | SetCurrentChatAction
  | CleanCurrentChatAction
  | AddMessageAction
  | UpdateMessageAction
  | RemoveMessageAction;