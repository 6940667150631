import { FrequencyUnitEnum } from "../api/noteApi";

export type leadFullInfo = {
  bank: {
    id: number;
    name: string;
    icon: string;
  };
  commission_amount: number;
  commission_percent: number;
  countChatLead: number;
  data: {
    additionalProp1: string;
    additionalProp2: string;
    additionalProp3: string;
    Inn?: string;
  };
  dateAdd: string;
  dateEdit: string;
  docsStatus: DocsStatusEnum;
  fieldsProposalItems: fieldsProposalItemsType[];
  groupFieldsProposalItems: groupFieldsProposalItemsType[];
  hasPartnerChat: boolean;
  hasSupportChat: boolean;
  id: number;
  isActive: boolean;
  isArchive: boolean;
  isAviableStatusChange: boolean;
  leadStatusHistory: leadStatusHistoryType[];
  proposalId: 0;
  proposalName: string;
  responsible: {
    userId: string;
    fio: string;
  };
  sendDocsState: string;
  status: string;
  statusColor: string;
  statusFontColor: string;
};

export enum DocsStatusEnum {
  None = 'None', //пока неизвестно нужны ли доки
  NeedDocuments = 'NeedDocuments', //запрос доков
  DocumentsPartLoad = 'DocumentPartLoad', //доки частично загружены
  DocumentsUpload = 'DocumentsUpload', //необходимы доки загружены полностью
  DocumentsNeedSend = 'DocumentsNeedSend', // доки нужно отправить в банк
  DocumentsSend = 'DocumentsSend' //доки отправлены
}

export type leadStatusHistoryType = {
  date: string;
  oldStatus: string;
  newStatus: string;
  statusColor?: string;
  statusFontColor?: string;
};

export type fieldsProposalItemsType = {
  name: string;
  textName: string;
  dataType: string;
  value: string;
  isSum: boolean;
  isMultiple: boolean;
};

export type groupFieldsProposalItemsType = {
  nameGroup: string;
  idGroup: string;
  codeGroup: string;
  childs: fieldsProposalItemsType[];
}
export type leadsListType = {
  leads: leadShortInfoType[];
};

export type leadStatusType = {
  leadstatus: string;
  isarchive: boolean;
  isbanknew: boolean;
  isdraft: boolean;
  isactive: boolean;
};

export type NotesShortInfoType = {
  isExpired: boolean;
  isToday: boolean;
  notesCount: number;
}

export type leadShortInfoType = {
  id: number;
  isActive: boolean;
  isArchive: boolean;
  isDraft: boolean;
  product: {
    id: number;
    name: string;
    order: number;
    alias?: string;
    isIndividual?: boolean;
  };
  proposalName: string;
  status: string;
  statusColor: string;
  statusFontColor: string;
  createDate: string;
  handleByHand: boolean;
  action: string;
  inn: string;
  docsStatus: DocsStatusEnum;
  sum: number;
  bank: {
    id: number;
    name: string;
    organizationType?: string;
    icon: string;
  };
  responsible: {
    userId: string;
    fio: string;
    inn: null | string;
  };
  leadStatusHistory: leadStatusHistoryType[];
  companyName: string;
  // responsibleManager: {
  //   id: null | string;
  //   fio: null | string;
  //   inn: null | string;
  // };
  leadNotifications: leadNotesInfo[],
};

export type leadNotesInfo = {
  id: number;
  actualNotificatiionDateTime: string | null;
  isNotificate: boolean;
  frequencyUnit: FrequencyUnitEnum;
}

export type LeadProductType = {
  id: number;
  name: string;
};

////---------Docs-------------
export type LeadDocPackType = {
  id: number,
  files: LeadDocInnerType[],
  isRequired: boolean,
  name: string,
  description: string,
  format: string,
  maxSize: number,
  maxFile: number,
  templates: LeadDocInnerType[],
  containEDS: boolean | null, //null - no need, false - need and doesnot uploaded, true - needed and uploaded
  IsAttachedSignature: boolean; //if contains eds
  isSend: boolean,
  isLocalSend: boolean //to show local state, not bank partner
}

export type LeadDocInnerType = {
  id: number,
  name: string,
  size: number,
}

export type LoadingLeadDocPackType = {
  packId: number,
  files: LoadingLeadDocInnerType[];
}

export type LoadingLeadDocEdsType = {
  packId: number;
  file: LoadingLeadDocInnerType;
};

export type LoadingLeadDocInnerType = {
  id: number,
  name: string,
  size: number,
  file: File,
  progress?: number;
  loadedMB?: string;
  totalMB?: string;
  error?: string; //uploading error message
};

export type LeadDocType = {
  id: number;
  name: string;
  doctype: string;
  isUserUploadPending: boolean;
  isUserSignaturePending: boolean;
  hasApplicantSignature: boolean;
  hasBankSignature: boolean;
  isCanBeRemoved: boolean;
  isAttachedSignature: boolean;
  isInquiredNow: boolean;
  comment: string;
  signatureId: number;
  signatureFileName: string;
  partnerExampleFileName: string;
  isBankFile: boolean;
};

export type QueryParamsGetLeads = {
  isArchive?: boolean;
  productId?: string | number;
  CompanyName?: string;
  inn?: string;
  sort?: string;
  Status?: string;
};

export type BodyParamsGetLeads = {
  inns?: string[];
  companyNames?: string[];
  statuses?: string[];
  banks?: string[];
  responsibles?: string[];
  productsIds?: number[];
  count?: number;
  offset?: number;
  isArchive: boolean;
  isActive?: boolean;
  isDraft?: boolean;
  sort?: string;
  dateStart?: string;
  dateEnd?: string;
};

export type AllLeadsData = {
  leads: leadShortInfoType[],
  pagination: {
    offset: number;
    count: number;
    total: number;
  }
  inns?: { inn: string, companyName: string }[];
  companyNames?: string[];
  statuses?: string[];
  banks?: string[];
  responsibles?: string[];
  products: { id: number; name: string }[];
}