import { leadShortInfoType } from './leadsApiTypes';

export const clientStatuses: Record<string, string> = {
  new: "Новый",
  verified: "Верифицирован",
  limit_exceeded: "Лимит превышен",
  UnConfirmed: "Аккаунт не подтверждён",
  NotCompleteRegistration: "Регистрация не завершена"
};

export type AgentsList = {
  inn: string | null;
  userId: string;
  userName: string;
};

export type clientsAgentsListType = {
  clientCount: number;
  clients: clientAgentInfoType[];
  regions: string[];
  clientTypes: string[];
  clientStatuses: string[];
  agents: AgentsList[];
  pagination: PaginationType;
};

export type clientAgentInfoType = {
  userId: string;
  companyName: string;
  clientType: string;
  inn: string;
  role: string;
  region: string;
  regionCode: number;
  timeZone: string;
  chatIdWithClient: number;
  localTime: string;
  register_date: string;
  status: string;
  agent: {
    id: string | null;
    fio: string | null;
    inn: string | null;
  };
};

export type leadsListType = {
  leads: leadShortInfoType[];
  companyNames: string[];
  statuses: string[];
};

export type actType = {
  id: number;
  companyName: string;
  inn: string;
  period: string;
  changeDate: string;
  status: string;
  documentId: number;
  sum: number;
  isSigned: boolean;
  isReadySigned: boolean;
};

export type agentByInnType = {
  userId: string;
  inn: string;
  companyName: string;
  ceo: string;
  okved: string;
  region: string;
};

export type reqBodyDocUpload = {
  UserId?: string;
  Year?: number;
  Month?: number;
  Sum?: number;
  ActId?: number;
};

export type ManagerDownloadDocArgs = {
  userId: string;
  documentType: "passport" | "protocol";
};

export type PaginationType = {
  count: number;
  offset: number;
  total: number;
};