import { Theme, createStyles, makeStyles } from "@material-ui/core";
import { COLORS } from "../../../core/consts/redesign/commonRedesign";


export const useStylesSideMenu = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      zIndex: 1200,
      flexShrink: 0,
      color: COLORS.white,
      backgroundColor: COLORS.black,
      boxSizing: 'border-box',
      width: '120px',
      transition: 'width 300ms cubic-bezier(0.4, 0, 0.6, 1)',
      overflowX: 'hidden',
    },
    drawerOpen: {
      width: '280px',
      zIndex: 1200,
      color: COLORS.white,
      backgroundColor: COLORS.black,
      '&>div>ul>div>a': {
        paddingLeft: '32px', //do not change
      }
    },
    logoBox: {
      margin: '38px auto 42px 19px'
    },
    linksBox: {
      padding: 0,
      margin: 0
    },
    toolbar: {
      margin: 'auto auto 50px 48px',
      '&:hover $hiddenText > span': {
        color: COLORS.brand_blue,
      },
      '&:hover $bottomArrow svg path': {
        fill: COLORS.brand_blue,
      },
    },
    bottomArrow: {
      padding: 0,
      margin: 0,
      boxSizing: 'border-box',
    },

    icon: {
      color: COLORS.white,
      display: 'flex',
      alignItems: 'center',
      alignSelf: 'center',
      marginRight: 16,
    },

    hiddenText: {
      display: 'flex',
      color: COLORS.white,
      fontSize: '14px',
      fontWeight: 600,
      alignItems: 'center',
      '&:hover': {
        color: COLORS.brand_blue
      },
    },
    ////////////////////////////////menu item //////////////////////////////////////////////////////////////////
    iconButton: {
      alignItems: 'center',
      justifyContent: 'flex-start',
      padding: '0',
      margin: '0',
      boxSizing: 'border-box',
      '&>a': {
        justifyContent: 'center',
      },
      '&:hover svg span': {
        color: COLORS.brand_blue
      }
    },
    iconButtonClose: {
      alignItems: 'center',
      justifyContent: 'center',
      padding: '0',
      margin: '0',
      boxSizing: 'border-box',
      '&>a': {
        justifyContent: 'center',
      },
      '&:hover svg': {
        color: COLORS.brand_blue
      }
    },
    navlink: {
      width: '100%',
      textDecoration: 'none',
      display: 'flex',
      alignItems: 'center',
      textAlign: 'left',
      height: '64px',
      justifyContent: 'left',
      position: 'relative',
      padding: '0',
      '&:hover': {
        color: COLORS.brand_blue,
      },
      '&:hover $menuText > span': {
        color: COLORS.brand_blue,
      },
      '&:hover $menuIcon svg path': {
        fill: COLORS.brand_blue,
      },
    },
    navlink_active: {
      width: '100%',
      display: 'flex',
      justifyContent: 'left',
      '&:before': {
        content: "''",
        height: '100%',
        width: '4px',
        display: 'block',
        backgroundColor: COLORS.brand_blue,
        position: 'absolute',
        left: 0,
      },
      '& div span': {
        // color: COLORS.brand_blue, //active link title color
      },
    },
    menuText: {
      textAlign: 'left',
      '&>span': {
        color: COLORS.white,
        fontSize: '14px',
        fontWeight: 600,
      },
    },
    menuIcon: {
      display: 'flex',
      justifyContent: 'center',
    },
    popover: {
      backgroundColor: COLORS.brand_blue,
      color: COLORS.white,
      zIndex: 1450,
      fontSize: '14px',
      lineHeight: '19px',
      fontWeight: 600,
      textAlign: 'center',
      padding: '9px 16px',
      borderRadius: '4px 4px 4px 0px',
    },
    //////////////////////////////////////mobile menu //////////////////////////////////
    bottomBar: {
      position: 'fixed',
      bottom: '0',
      left: '0',
      width: '100vw',
      height: '64px', //bar height
      backgroundColor: COLORS.black,
      zIndex: 25,
    },
    bottomBarHeight: {
      height: '130px',
    },
    mobMenu: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-around',
      padding: 0,
      margin: 0,
    },
    mobMenuBorder: {
      borderBottom: `1px solid ${COLORS.gray2}`,
    },
    navlink_mob: {
      width: '100%',
      textDecoration: 'none',
      display: 'flex',
      alignItems: 'center',
      textAlign: 'left',
      height: '64px',
      justifyContent: 'center',
      position: 'relative',
      padding: '0',
      '&:hover ': {
        color: COLORS.brand_blue
      },
    },
    navlink_mob_active: {
      '&:before': {
        content: "''",
        width: '100%',
        height: '4px',
        display: 'block',
        backgroundColor: COLORS.brand_blue,
        position: 'absolute',
        left: 0,
        top: 0
      },
    }
  })
)