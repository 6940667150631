import { http } from "../infrastructure/Https";
import tokenService from "../services/TokenService"

const ROOT_URL = '/api/v1/kontragent'

type lightType = {
  name: string,
  desc: string,
  value: string,
}

export type konteragentsListType = {
  id: string,
  companyName: string,
  inn: string,
  registerDate: string,
  registerAddress: string,
}

export type konteragentType = {
  companyName: string,
  companyAddress: string,
  inn: string,
  kpp: string,
  ogrn: string,
  manager: {
    fio: string,
    date: string,
  },
  okved: {
    code: string,
    title: string,
  },
  owners: {
    fio: string,
    date: string,
  }[],
  lights: {
    success: lightType[],
    warning: lightType[],
    danger: lightType[]
  },
  staffCount: number,
  staffYear: number,
  companyRegistationDate: string,
  mspStatus: string,
}

class KontragentApi {

  searchKontagentApi(Q: { search: string, isUL: boolean, isIP: boolean, isActive: boolean }): Promise<konteragentsListType[]> {
    const accessToken = tokenService.getAccessToken()
    return http.get(ROOT_URL, Q, accessToken)
  }

  getKontagentById(Id: string): Promise<konteragentType> {
    const accessToken = tokenService.getAccessToken()
    return http.get(`${ROOT_URL}/${Id}`, null, accessToken)
  }
}

const kontragentApi = new KontragentApi()
export default kontragentApi
