import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Paper,
  Theme,
  Typography,
  makeStyles,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import { v4 } from "uuid";
import { fieldsProposalItemsType } from "../../../../core/types/leadsApiTypes";

/* Candidate to be deleted */
const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    flexBasis: "100%",
    padding: "30px",
    boxSizing: "border-box",
    height: "fit-content",
    boxShadow: "none",
    borderRadius: "10px",
  },
  modalTextWrap: {
    display: "grid",
    gridTemplateColumns: "175px 1fr",
    gap: "20px",
    padding: "15px 0",
  },
  modalText: {
    justifySelf: "start",
  },
  modalTextValue: {},
  title: {
    margin: "0",
  },
  accordion: {
    padding: 0,
    boxShadow: "none",
    "&:before": {
      height: 0,
    },
  },
  summary: {
    color: "#131313",
    fontSize: "14px",
    padding: 0,
    fontFamily: "Gilroy",
    fontWeight: 500,
    justifyContent: "flex-start",
    "& div": {
      flexGrow: 0,
    },
  },
  text: {
    fontFamily: "Gilroy",
    fontWeight: 500,
    cursor: "pointer",
    "&:hover": {
      color: "#617E8C",
    },
  },
  detailsWrap: {
    overflowX: "hidden",
    scrollbarWidth: "thin",
    overflow: "hidden",
    "&::-webkit-scrollbar": {
      width: "0.2em",
      height: "0.52em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(208 217 222 / 88%)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#D0D9DE",
      outline: "0.2px solid #D0D9DE",
    },
  },
  btnMore: {
    marginTop: "40px",
    color: "#306F9A",
    cursor: "pointer",
  },
  accordionDetails: {
    display: 'block'
  }
}));

type Props = {
  filedsArr: any;
  title?: string;
  errorMessage?: string;
  proposalsItems: any;
  withFilter?: boolean;
};
const ClientDetail: React.FC<Props> = ({
  filedsArr,
  errorMessage,
  proposalsItems,
  withFilter = false,
  title = "Исполнитель",
}) => {
  const classes = useStyles();
  const [showMore, setShowMore] = useState(false);
  const [showMoreButton, setShowMoreButton] = useState(false);
  const clientDetailShowMore = useRef<null | any>(null);
  const handleClickBtn = () => {
    console.log("@@@@@@@@@@@@@ ClientDetail scroll")
    if (clientDetailShowMore) {
      clientDetailShowMore.current.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
      setShowMore((state) => !state);
    }
  };
  useEffect(() => {
    if (filedsArr) {
      if (clientDetailShowMore.current) {
        if (clientDetailShowMore.current.scrollHeight > clientDetailShowMore.current.clientHeight + 1) {
          setShowMoreButton(true);
        }
      }
    }
  }, [filedsArr]);

  const handleClickAccordion = () => {
    if (!showMoreButton) {
      setShowMore(state => !state);
    }

  }
  return (
    <Paper
      style={
        showMore
          ? { maxHeight: "100%" }
          : withFilter
            ? { maxHeight: "calc(100vh - 400px)" }
            : { maxHeight: "calc(100vh - 260px)" }
      }
      className={classes.paper}
    >
      <Typography variant="h4" component="h4" style={{ paddingBottom: "15px" }}>
        {title}
      </Typography>
      <div
        ref={clientDetailShowMore}
        style={
          showMore
            ? { maxHeight: "100%" }
            : withFilter
              ? { maxHeight: "calc(100vh - 550px)" }
              : { maxHeight: "calc(100vh - 410px)" }
        }
        className={classes.detailsWrap}
      >
        {filedsArr
          ? filedsArr.map((field: fieldsProposalItemsType) => {
            if (
              field.dataType !== "group" &&
              field.dataType !== "bool" &&
              field.value
            ) {
              let tmpVar: string[] | string = field.value;
              if (field.isSum) {
                tmpVar = new Intl.NumberFormat("ru", {
                  style: "currency",
                  currency: "RUB",
                  minimumFractionDigits: 2,
                }).format(parseInt(field.value));
              }
              if (field.dataType === "date") {
                let tmpArr = field.value.split("-");
                tmpVar = `${tmpArr[1]}.${tmpArr[2]}.${tmpArr[0]}`;
              }
              if (field.isMultiple) {
                tmpVar = field.value
                  .replace(/[[\]"]+/g, "")
                  .split(",")
                  .join(", ");
              }
              return (
                tmpVar.length > 0 && (
                  <Box className={classes.modalTextWrap} key={v4()}>
                    <Typography
                      key={v4()}
                      variant="body2"
                      component="p"
                      className={classes.modalText}
                    >
                      {field.textName}
                    </Typography>
                    <Typography
                      className={classes.modalText}
                      key={v4()}
                      variant="body2"
                      color="primary"
                      component="span"
                    >
                      {tmpVar.trim()}
                    </Typography>
                  </Box>
                )
              );
            } else return null;
          })
          : errorMessage && (
            <Typography
              variant="subtitle1"
              component="h5"
              style={{ paddingTop: "25px" }}
            >
              {errorMessage}
            </Typography>
          )}
        {proposalsItems && proposalsItems?.map((el: any, index: number) => {
          return (
            <Accordion className={classes.accordion} key={index}>
              <AccordionSummary
                onClick={handleClickAccordion}
                expandIcon={<ExpandMoreIcon />}
                className={classes.summary}
              >
                <Typography className={classes.text}>{el.nameGroup}</Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.accordionDetails}>
                <Box className={classes.detailsWrap}>
                  {el.childs.length
                    ? el.childs.map((child: any, key: number) => {
                      return (
                        <div key={key}>
                          {child.map((field: fieldsProposalItemsType) => {
                            if (
                              field.dataType !== "group" &&
                              field.dataType !== "bool" &&
                              field.value
                            ) {
                              let tmpVar: string[] | string = field.value;
                              if (field.isSum) {
                                tmpVar = new Intl.NumberFormat("ru", {
                                  style: "currency",
                                  currency: "RUB",
                                  minimumFractionDigits: 2,
                                }).format(parseInt(field.value));
                              }
                              if (field.dataType === "date") {
                                let tmpArr = field.value.split("-");
                                tmpVar = `${tmpArr[1]}.${tmpArr[2]}.${tmpArr[0]}`;
                              }
                              if (field.isMultiple) {
                                tmpVar = field.value
                                  .replace(/[[\]"]+/g, "")
                                  .split(",")
                                  .join(", ");
                              }
                              return (
                                tmpVar.length > 0 && (
                                  <Box className={classes.modalTextWrap} key={v4()}>
                                    <Typography
                                      key={v4()}
                                      variant="body2"
                                      component="p"
                                      className={classes.modalText}
                                    >
                                      {field.textName}
                                    </Typography>
                                    <Typography
                                      className={clsx(
                                        classes.modalText,
                                        classes.modalTextValue
                                      )}
                                      key={v4()}
                                      variant="body2"
                                      color="primary"
                                      component="span"
                                    >
                                      {tmpVar.trim()}
                                    </Typography>
                                  </Box>
                                )
                              );
                            } else return null;
                          })}
                          {el.childs.length - 1 !== key && <Divider style={{ margin: '14px 0' }} />}

                        </div>
                      )
                    })

                    : errorMessage && (
                      <Typography
                        variant="subtitle1"
                        component="h5"
                        style={{ paddingTop: "25px" }}
                      >
                        {errorMessage}
                      </Typography>
                    )}
                </Box>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </div>
      {showMoreButton && (
        <div className={classes.btnMore} onClick={handleClickBtn}>
          {showMore ? "Скрыть" : "Смотреть полностью"}
        </div>
      )}
    </Paper>
  );
};

export default ClientDetail;
