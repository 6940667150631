export const TwoTicksIcon = ({ color, opacity }: { color: string, opacity: number }) => (
  <svg width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.75314 2.10383C10.0143 1.78235 9.96548 1.31 9.644 1.0488C9.32253 0.787598 8.85017 0.836461 8.58897 1.15794L3.60085 7.29714L1.23811 5.27193C0.923613 5.00236 0.450138 5.03879 0.180571 5.35328C-0.0889955 5.66777 -0.0525743 6.14125 0.26192 6.41082L3.20929 8.93713C3.36291 9.06881 3.56322 9.13283 3.76473 9.11466C3.96625 9.09649 4.15187 8.99767 4.27946 8.84063L9.75314 2.10383ZM13.9637 2.10383C14.2249 1.78235 14.176 1.31 13.8545 1.0488C13.5331 0.787598 13.0607 0.836461 12.7995 1.15794L7.8502 7.24935L7.59612 6.99527C7.30322 6.70239 6.82834 6.7024 6.53546 6.9953C6.24257 7.28819 6.24258 7.76307 6.53548 8.05596L7.37758 8.89803C7.52773 9.04817 7.73449 9.12763 7.94655 9.11669C8.1586 9.10575 8.35609 9.00543 8.48999 8.84063L13.9637 2.10383Z"
      opacity={opacity}
      fill={color ? color : "#FFFFFF"} />
  </svg>
);
