import { TypeChat, messageType } from "../api/chatsApi";

export type ChatsState = dataForChats[];
export type dataForChats = {
  chatId: number,
  leadId: number,
  nameChat: string,
  typeChat: TypeChat,
  // productId?: number,
  // bankName?: string,
  avatar_url: string,
  lastMessage: string,
  lastTimeMessage: string,
  countUnreadMessage: number,
  roleInterlocutor?: string,
  nameInterlocutor?: string,
  chatMessages: messageType[];
}

export enum chatActionTypes {
  ADD_AND_UPDATE_CHAT = 'ADD_AND_UPDATE_CHAT',
  REMOVE_CHAT = 'REMOVE_CHAT',
  CLEAN_USER_CHATS = 'CLEAN_USER_CHATS',
}

interface SetChatAction {
  type: chatActionTypes.ADD_AND_UPDATE_CHAT;
  payload: dataForChats
}

interface RemoveChatAction {
  type: chatActionTypes.REMOVE_CHAT;
  payload: number;
}

interface CleanChatsAction {
  type: chatActionTypes.CLEAN_USER_CHATS;
}

export type ChatActions = SetChatAction | RemoveChatAction | CleanChatsAction;

