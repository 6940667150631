import { Button, Grid, IconButton, Modal, TextField, Typography } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import React, { memo, useEffect, useState } from "react";
import { GrayApproveSign } from "../../../../core/consts/images/GrayApproveSign";
import { GrayWarningSign } from "../../../../core/consts/images/GrayWarningSign";
import { ApproveSign } from "../../../../core/consts/images/approveSign";
import { WarningSign } from "../../../../core/consts/images/warningSign";
import { default as AgentService, default as agentService } from "../../../../core/services/AgentService";
import { useStylesAddClientModal } from "./useStylesAddClientModal";

type propsType = {
  isOpen: boolean,
  setCloseModal: () => void
}

type ResponseDataType = {
  companyName: string,
  ceo: string,
  region: string,
  okved: string,
  clientIsRegister: boolean,
  clientIsAlreadyList: boolean,
  errorStatus: string,
}
const AddClientModal: React.FC<propsType> = (props) => {
  const classes = useStylesAddClientModal()
  const [openModal, setOpenModal] = useState(props.isOpen)
  const [inputModalError, setInputModalError] = useState(false)
  const [valueModal, setValueModal] = useState('');
  const [clientData, setClientData] = useState<ResponseDataType>()
  const [isSent, setIsSent] = useState(false)

  useEffect(() => {
    setOpenModal(props.isOpen)
  }, [props.isOpen])

  const handleClose = () => {
    setOpenModal((prev) => !prev)
    props.setCloseModal()
    // setTimeout(() => props.setOpenModal(false), 2000)
  }

  const handleModalSubmit = (event: any) => {
    event.preventDefault();
    setInputModalError(false);
    if (valueModal === '') setInputModalError(true)
  }

  const handleAddClient = () => {
    let Q = {
      inn: valueModal
    }
    agentService.registerClientUlService(Q)
      .then((res) => {
        setIsSent(true)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  useEffect(() => {
    if (valueModal.length === 10 || valueModal.length === 12) {
      !inputModalError && valueModal.length > 0 && AgentService.checkClientUlService({ Inn: valueModal.toString() })
        .then((res: ResponseDataType) => {
          setClientData(res)
        })
        .catch((error: any) => {
          console.log(error)
        })
    }
  }, [valueModal, inputModalError])

  const handleModalChange = (event: any) => {
    if (!event.target.value.match(/^([0-9]{10}|[0-9]{12})$/) || ((event.target.value === ''))) {
      setInputModalError(true)
    }
    else {
      setInputModalError(false)
    }
    setValueModal(event.target.value)
  }

  return (
    <Modal
      className={classes.container}
      open={openModal}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <>
        {isSent
          ? <div className={classes.paperSuccess} style={{ background: 'linear-gradient(114.44deg, #942BD5 0%, #B239CC 100%)' }}>
            <div className={classes.boxSuccess}>
              <Typography variant="body1" component='h3' style={{ color: '#fff', padding: '15px 0 25px 0' }}>
                Клиент успешно добавлен
              </Typography>
              <IconButton aria-label="close" onClick={handleClose}>
                <CloseIcon style={{ color: '#fff' }} />
              </IconButton>
            </div>
            <Typography variant="body2" component='p' style={{ color: '#fff' }} >
              Теперь он будет отображаться в вашем списке клиентов
            </Typography>
          </div>
          : <div className={classes.paper} >
            <div className={classes.box}>
              <Typography variant="h3" component='h3' style={{ padding: '0 0 25px 0' }}>
                Добавление нового клиента
              </Typography>
              <IconButton aria-label="close" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </div>
            <Typography variant="body1" component='p' className={classes.text} >
              Введите ИНН клиента, которого вы хотите добавить
            </Typography>
            <form
              noValidate
              onSubmit={handleModalSubmit}
            >
              <TextField
                onChange={handleModalChange}
                className={classes.searchInnClient}
                label={'ИНН клиента'}
                required
                value={valueModal}
                error={inputModalError}
                color='secondary'
                type={'text'}
                helperText={inputModalError ? 'Только цифры 10 или 12 символов' : ''}
              />
            </form>
            {clientData && <div>
              {clientData.companyName && <Typography variant="h4" component='h4'>
                {clientData.companyName}
              </Typography>}
              <Grid container className={classes.gridInfo}>
                {clientData.ceo && <Grid container className={classes.gridInfoRow}>
                  <Grid item className={classes.titleBox}>
                    <Typography variant="body2" component='span' className={classes.gridInfoTitle}>
                      Генеральный директор
                    </Typography>
                  </Grid>
                  <Grid item className={classes.valueBox}>
                    <Typography variant="body2" component='span' className={classes.gridInfoValue}>
                      {clientData.ceo}
                    </Typography>
                  </Grid>
                </Grid>}
                {clientData.region && clientData.region !== ' ' && <Grid container className={classes.gridInfoRow}>
                  <Grid item className={classes.titleBox}>
                    <Typography variant="body2" component='span' className={classes.gridInfoTitle}>
                      Регион
                    </Typography>
                  </Grid>
                  <Grid item className={classes.valueBox}>
                    <Typography variant="body2" component='span' className={classes.gridInfoValue}>
                      {clientData.region}
                    </Typography>
                  </Grid>
                </Grid>}
                {clientData.okved && <Grid container className={classes.gridInfoRow}>
                  <Grid item className={classes.titleBox}>
                    <Typography variant="body2" component='span' className={classes.gridInfoTitle}>
                      ОКВЭД
                    </Typography>
                  </Grid>
                  <Grid item className={classes.valueBox}>
                    <Typography variant="body2" component='span' className={classes.gridInfoValue}>
                      {clientData.okved}
                    </Typography>
                  </Grid>
                </Grid>}
              </Grid>
              <Grid container className={classes.trafficLight}>
                {clientData.clientIsRegister
                  ? <Grid item className={classes.trafficLightRow}>
                    <WarningSign />
                    <Typography variant="body2" component='span' className={classes.isClientListText}>
                      Клиент уже зарегистрирован на портале
                    </Typography>
                  </Grid>
                  : <Grid item className={classes.trafficLightRow}>
                    <GrayWarningSign />
                    <Typography variant="body2" component='span' className={classes.isClientListText}>
                      Клиент не зарегистрирован на портале
                    </Typography>
                  </Grid>}
                {clientData.clientIsAlreadyList
                  ? <Grid item className={classes.trafficLightRow}>
                    <ApproveSign />
                    <Typography variant="body2" component='span' className={classes.isClientListText}>
                      Клиент есть в вашем списке
                    </Typography>
                  </Grid>
                  : <Grid item className={classes.trafficLightRow}>
                    <GrayApproveSign />
                    <Typography variant="body2" component='span' className={classes.isClientListText}>
                      Клиента нет в вашем списке
                    </Typography>
                  </Grid>}
                {clientData.errorStatus &&
                  <Grid item className={classes.trafficLightRow}>
                    <WarningSign />
                    <Typography variant="body2" style={{ color: '#131313 !important' }} component='span' className={classes.isClientListText}>
                      {clientData.errorStatus}
                    </Typography>
                  </Grid>
                }
              </Grid>
            </div>}
            <Button
              color='primary'
              variant="contained"
              type="submit"
              disabled={!clientData || ((clientData && clientData.clientIsAlreadyList) || (clientData && clientData.clientIsRegister) || (clientData && !!clientData.errorStatus))}
              className={classes.button}
              onClick={handleAddClient}
            >
              <Typography variant="body1" component='span' style={{ color: '#ffffff', textTransform: 'none', lineHeight: '2' }}>
                Добавить клиента
              </Typography>
            </Button>
          </div>}
      </>
    </Modal>
  )
}

export default memo(AddClientModal)