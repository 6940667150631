import { Theme, createStyles, makeStyles } from "@material-ui/core";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import InputMainAutocomplete from "../../../../../UIcomponentsRedesign/InputMain/InputMainAutocomplete";
import leadsApi from "../../../../../core/api/leadsApi";
import { COLORS, InputTypeEnum } from "../../../../../core/consts/redesign/commonRedesign";
import { useAppThunkDispatch } from "../../../../../core/store";
import { handleAutoFillFields } from "../../../../../core/store/thunk/newOrder";
import { formatters } from "../../../../../core/utils/inputTypeRules";
import withFormHocs from "../../../../new-order/hoc/withFormHocs";
import { PropsField } from "../../../../new-order/types/bigForm";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    error: {
      color: COLORS.alert,
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "21px",
    },
    select: {
      width: "100%",
      "&>div": {
        "&>.MuiInputLabel-formControl": {
          top: 0,
        },
        "&>.MuiInput-formControl": {
          marginTop: "5px",
        },
        "&>div": {
          "&>.MuiInputBase-input": {
            marginTop: "15px",
            marginBottom: "5px",
          },
          "&>.MuiAutocomplete-endAdornment": {
            top: "12px",
          },
        },
      },
    },
    suggestionsContainer: {
      width: "100%",
      position: 'relative',
    },
    boxSelectOptions: {
      margin: "9px 0 0",
      padding: "0",
      boxSizing: "border-box",
      listStyle: "none",
      cursor: "pointer",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      backgroundColor: COLORS.white,
      position: 'absolute',
      zIndex: 1000,
      maxHeight: '300px',
      overflowY: 'auto',
      top: '70%',
      left: 0,
      border: `1px solid ${COLORS.brand_blue}`,
      borderRadius: "0px 0px 8px 8px",
    },
    selectOptionsInn: {
      padding: "10px",
      color: COLORS.black,
      fontFamily: "Gilroy",
      fontSize: "16px",
      lineHeight: "21px",
      fontWeight: 500,
      "&:hover": {
        backgroundColor: COLORS.light_gray_bg,
      },
      '&:last-child': {
        borderBottom: 0,
      },
      '&:last-child:hover': {
        borderRadius: "0px 0px 8px 8px",
      },
      [theme.breakpoints.down(768)]: {
        padding: '8px'
      },
    },
  })
);

type Props = PropsField & {
  value?: string;
  info?: string;
  sumText?: string;
  regex?: string;
  inputType?: InputTypeEnum;
};


type SelectValue = {
  key: string;
  text: string;
};

/**onChange и value должны быть обязательно */
const StringSuggestionField: React.FC<Props> = ({
  field,
  propsalId,
  value = "",
  text,
  error,
  onChange,
  parentName,
  indexGroupField,
  isVisible,
  classNameShowIf,
  inputType,
  handleSaveDraftOnBlur,
}) => {
  const dispatch = useAppThunkDispatch();
  const classes = useStyles();
  const [inputValue, setInputValue] = useState<string>(value);
  const [suggestions, setSuggestions] = useState<SelectValue[]>([]);
  const [showSuggestions, setShowSuggestions] = useState<boolean>(false);
  const [selectedSuggestionForAutofill, setSelectedSuggestionForAutofill] = useState(false);
  const [isSuggestionSelected, setIsSuggestionSelected] = useState(false);

  const hasShowIf = field.show_if.length > 0;

  const fetchOptions = useCallback(
    debounce(async (searchText: string) => {
      if (searchText.length > 0 && field.suggestionUrl) {
        try {
          const response = await leadsApi.sendRequestFromField<SelectValue[]>(
            `${field.suggestionUrl}${searchText}`
          );
          setSuggestions(response);
        } catch (error) {
          console.error("Error fetching data:", error);
          setSuggestions([]);
        }
      } else {
        setSuggestions([]);
      }
    }, 350),
    [field.suggestionUrl]
  );

  useEffect(() => {
    fetchOptions(inputValue);
  }, [inputValue, fetchOptions]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputType = field.inputType;
    const newValue = event.target.value;
    const formatter = formatters[inputType as InputTypeEnum];
    const formattedValue = formatter ? formatter(newValue, inputType) : newValue;
    event.target.value = formattedValue; //to display modified value
    setInputValue(formattedValue);
    setShowSuggestions(true);

    if (newValue === '') {
      if (onChange) {
        onChange(field.name, '', propsalId, parentName, indexGroupField);
      }
    }
  };

  const handleSuggestionClick = (suggestion: SelectValue) => {
    setInputValue(suggestion.key);
    setIsSuggestionSelected(true);

    if (field.autoFillUrl) {
      dispatch(
        handleAutoFillFields(
          field.autoFillUrl,
          suggestion.key,
          propsalId,
          field.name,
          parentName,
          indexGroupField
        )
      );
      setSelectedSuggestionForAutofill(true);
    }

    if (onChange) {
      onChange(field.name, suggestion.key, propsalId, parentName, indexGroupField);
    }
  };

  const handleBlur = () => {
    if (field.autoFillUrl) {
      dispatch(
        handleAutoFillFields(
          field.autoFillUrl,
          inputValue, propsalId,
          field.name, parentName,
          indexGroupField)
      );

    }
    //delay to fill autofill fields before validation
    if (handleSaveDraftOnBlur) {
      setTimeout(() => {
        handleSaveDraftOnBlur();
      }, 500)
    }

    setTimeout(() => {
      setShowSuggestions(false);
      setSelectedSuggestionForAutofill(false);
      setIsSuggestionSelected(false);
    }, 200);
  };

  useEffect(() => {
    setInputValue(value || '');
  }, [value]);

  return (
    <>
      {field.upSeparator && (hasShowIf ? isVisible : true) && <div className="line_x" />}
      <div className={classes.suggestionsContainer}>
        <InputMainAutocomplete
          label={text}
          value={inputValue}
          inputType={inputType}
          onChange={handleInputChange}
          onBlur={handleBlur}
          error={error}
          isRequired={field.isRequeired}
          className={classNameShowIf}
        />
        {showSuggestions && suggestions.length > 0 && (
          <ul className={classes.boxSelectOptions}>
            {suggestions.map((suggestion, index) => (
              <li
                key={index}
                className={classes.selectOptionsInn}
                onClick={() => handleSuggestionClick(suggestion)}
              >
                {suggestion.key} - {suggestion.text}
              </li>
            ))}
          </ul>
        )}
      </div>
      {field.downSeparator && (hasShowIf ? isVisible : true) && <div className="line_x" />}
    </>
  );
};

export default withFormHocs(React.memo(StringSuggestionField));
