import { Box, Typography } from "@material-ui/core";
import React, { memo } from "react";
import * as uuid from "uuid";
import { DocIcon } from "../../../../../core/consts/images/DocIcon";
import { makeStyles, createStyles, Theme } from "@material-ui/core";
import { isConstructorDeclaration } from "typescript";

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      fileUploadWrap: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        [theme.breakpoints.down(400)]: {
          flexDirection: "column",
          alignItems: "baseline",
        },
      },
      document: {
        color: "#306F9A",
        display: "flex",
        alignItems: "center",
        "&:hover": {
          cursor: "pointer",
          color: "#204A66",
        },
      },
      documentEllipsis: {
        overflow: "hidden",
        maxWidth: "230px",
        textOverflow: "ellipsis ellipsis",
        display: "inline-block",
        whiteSpace: "nowrap",
      },
      fileBtnsUploadWrap: {
        [theme.breakpoints.down(400)]: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        },
      },
      fileUpload: {
        display: "flex",
        alignItems: "center",
        border: "none",
        outline: "none",
        color: "#131313",
        "&:hover": {
          cursor: "pointer",
        },
      },
      btnfileUpload: {
        position: "relative",
        color: "#306F9A",
        "&:hover": {
          cursor: "pointer",
        },
        [theme.breakpoints.down(400)]: {
          marginTop: "25px",
        },
      },
      btnDelfileUpload: {
        color: "#D62D30",
        marginTop: "10px",
        "&:hover": {
          cursor: "pointer",
        },
        [theme.breakpoints.down(400)]: {
          marginTop: "25px",
          marginLeft: "20px",
        },
      },
      inputfileUpload: {
        position: "absolute",
        width: "100%",
        height: "100%",
        opacity: 0,
        borderColor: "transparent",
        "&::-webkit-file-upload-button": { cursor: "pointer" },
        "&:hover": {
          cursor: "pointer",
        },
      },
    }),
  { index: 1 }
);

const handleLongName = (docName: string): string[] => {
  let tmpArr = docName.split(".");
  let first = tmpArr.slice(0, tmpArr.length - 1).join(".");
  let second = tmpArr.slice(tmpArr.length - 1).join("");
  return [first, second];
};

type Props = {
  file: any;
  onChangeFile: (changedFiles: any) => void;
  onClickFile?: () => void;
  isDisabled?:boolean;
};

const UploadFilesForm: React.FC<Props> = ({ file, onChangeFile, onClickFile, isDisabled=false }) => {
  const classes = useStyles();
  const id = uuid.v4();
  const handleSelectFile = (event: any) => {
    onChangeFile(event.target.files[0]);
  };

  const handleClickFile = (e: any) => {
    e.preventDefault()
    onClickFile && onClickFile()
  }
    

  const handleDeleteFile = () => {
    if (!isDisabled){
      onChangeFile(null);
    }
  };

  return (
    <Box my={2}>
      <Box className={classes.fileUploadWrap}>
        <label htmlFor={id}>
          <Typography
            className={classes.fileUpload}
            variant="body1"
            style={{ color: file ? "#306F9A" : "#B0BFC6" }}
          >
            <DocIcon />
            &nbsp;&nbsp;
            {file ? (
              <span onClick={(e: any) =>  handleClickFile(e)} className={classes.document}>
                <span className={classes.documentEllipsis}>
                  {handleLongName(file.name)[0]}
                </span>
                .{handleLongName(file.name)[1]}
              </span>
            ) : (
              <span>Документ не выбран</span>
            )}
          </Typography>
        </label>
        <Box className={classes.fileBtnsUploadWrap}>
          <Typography className={classes.btnfileUpload} style={isDisabled ? {opacity:0.6} : {}} variant="body1">
            <input
              id={id}
              name={id}
              type="file"
              disabled={isDisabled}
              className={classes.inputfileUpload}
              onChange={handleSelectFile}
            />
            {file ? "Заменить" : "Загрузить"}
          </Typography>
          {file && (
            <Typography
              onClick={handleDeleteFile}
              variant="body1"
              className={classes.btnDelfileUpload}
              style={isDisabled ? {opacity:0.6} : {}}
            >
              Удалить
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default memo(UploadFilesForm);
