import {
  Theme,
  createStyles,
  makeStyles
} from '@material-ui/core';
import React, {
  memo,
  useState
} from 'react';
import { useSelector } from 'react-redux';
import ChatModalNew from '../../../../app/components/chat-modal-new/ChatModalNew';
import ChatPreview from '../../../../app/components/chat-preview/ChatPreview';
import EmptyPage from '../../../../app/components/empty-page/EmptyPage';
import {
  ROLES
} from '../../../../core/consts/common';
import { COLORS } from '../../../../core/consts/redesign/commonRedesign';
import { actions } from '../../../../core/store';
import { rootState } from '../../../../core/types/rootState';
import { getChatTitle } from '../../../../core/utils/chatsUtils';

type propsType = {
  leadId: number;
  isArchive?: boolean;
};
const useStyles = makeStyles((theme: Theme) => createStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: '4px',
    width: "100%",
  },
}));

const ChatBlock: React.FC<propsType> = ({ leadId, isArchive }) => {
  const classes = useStyles();
  const initialUser = useSelector((state: rootState) => state.currentUser);
  const isManager = initialUser.role === ROLES.MANAGER;
  //filter chats for this lead
  const chats = useSelector((state: rootState) => {
    return state.chats.filter(chat => chat.leadId === leadId);
  });
  const [currentChatId, setCurrentChatId] = useState<number | null>(null);
  const [isChatOpen, setIsChatOpen] = useState(false);

  const openChat = (chatId: number) => {
    setCurrentChatId(chatId);
    setIsChatOpen(true);
  };

  const closeChat = () => {
    setIsChatOpen(false)
    actions.cleanCurrentChatData();
  }

  return (
    <>
      <div className={classes.container}>
        <>
          {chats.length > 0 ? (
            chats.map((chat) => {
              const lastMessage = chat.lastMessage || '';
              const lastMessageTime = chat.lastTimeMessage || '';
              const countUnreadMessage = chat.countUnreadMessage || 0;
              return (
                <ChatPreview
                  key={chat.chatId}
                  avatarUrl={chat.avatar_url}
                  title={getChatTitle(chat, isManager, leadId)}
                  lastMessageText={lastMessage}
                  lastMessageTime={lastMessageTime}
                  unreadCount={countUnreadMessage}
                  handleChatClick={() => { chat.chatId && openChat(chat.chatId) }}
                />
              )
            })
          ) : (
            <EmptyPage
              margin="80px"
              maxWidth="600px"
              gap="16px"
              titleColor={COLORS.gray2}
              title='Здесь будут находиться чаты по данной заявке'
              content="Все чаты по вашей заявке будут храниться на этом экране"
            />
          )}
        </>
      </div>
      {isChatOpen && currentChatId &&
        <ChatModalNew
          open={isChatOpen}
          handleClose={closeChat}
          chatId={currentChatId}
          archiveLead={isArchive} />
      }
    </>
  );
};

export default memo(ChatBlock);
