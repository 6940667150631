import React from "react";
import { useAppThunkDispatch } from "../../../../core/store";
import {
  sendCurrentDataFormToDraftNEW,
  validateField,
} from "../../../../core/store/thunk/newOrder";
import {
  FieldType,
  ValidateForThunkForm,
} from "../../../new-order/types/bigForm";
import classes from "../../NewOrderRedesign.module.scss";
import BooleanField from "./components/BooleanField";
import CurrencyField from "./components/CurrencyField";
import DateField from "./components/DateField";
import GroupField from "./components/GroupField/GroupField";
import NumberField from "./components/NumberField";
import SelectField from "./components/SelectField";
import StringField from "./components/StringField";
import StringMultipleField from "./components/StringMultipleField";
import StringSuggestionField from "./components/StringSuggestionField";
import TextAreaField from "./components/TextAreaField";
// import SelectField from "../../../new-order/components/fields/components/SelectField";
// import StringField from "../../../new-order/components/fields/components/StringField";

type Props = {
  field: FieldType;
  parentName?: string;
  propsalId: number;
  indexGroupField?: number;
  parentId?: number;
  stepId?: number;
  propsalIdsReal?: number[];
  isMultiple?: boolean;
  // proposals?: { id: number }[];
};

const Fields: React.FC<Props> = ({
  field,
  parentName,
  propsalId,
  indexGroupField,
  parentId,
  stepId,
  propsalIdsReal,
  isMultiple,
  // proposals,
}) => {
  const dispatch = useAppThunkDispatch();

  const handleSaveDraftOnBlur = async () => {
    // console.log("handleSaveDraftOnBlur propsalId", propsalId);
    // console.log("handleSaveDraftOnBlur propsalIdsReal:", propsalIdsReal);
    if (!(propsalIdsReal && propsalIdsReal?.length > 0)) return;
    dispatch(sendCurrentDataFormToDraftNEW(propsalId, propsalIdsReal));

    const rdValidate: ValidateForThunkForm = {
      fieldName: field.name,
      fieldId: field.fieldId,
      proposalId: propsalIdsReal?.[0] ?? propsalId,
    };
    if (parentId) {
      rdValidate.parentFieldId = parentId;
    }
    rdValidate.proposalIds = propsalIdsReal;
    if (stepId) {
      // rdValidate.stepId = stepId;
    }
    // console.log("handleSaveDraftOnBlur indexGroupField", indexGroupField);
    // console.log("handleSaveDraftOnBlur parentId", parentId);
    // console.log("handleSaveDraftOnBlur parentId", parentId);
    if (
      isMultiple &&
      (indexGroupField === 0 || (indexGroupField && indexGroupField >= 0))
    ) {
      rdValidate.index = indexGroupField;
    }

    dispatch(
      validateField(rdValidate, propsalIdsReal, propsalId, indexGroupField)
    );

    // if (!formValues || !propsalId) return;
    // const { dataForSend } = handleFieldsForSend(formValues);
    // const data = dataForSend.find((elem) => +elem?.proposalId === propsalId);
    // if (!data) return;
    // const rd: ValidateForm = {
    //   data: data.data,
    //   fieldName: field.name,
    //   fieldId: field.fieldId,
    //   proposalId: propsalIdReal?.toString() ?? propsalId.toString(),
    //   // fieldName: "",
    //   // fieldId: 888888,
    // };
    // if (parentId) {
    //   rd.parentFieldId = parentId;
    // }
    // try {
    //   const res = await leadsService.validateV5(rd);
    //   console.log(res);
    //   dispatch(handleErrorsPropsalFromServerForOneField(res, propsalId));
    // } catch (e) {
    //   console.log(e);
    // }

    // Сохранение промежуточных данных

    // propsalId &&
    // propsalId > 10 &&
    // dispatch(sendCurrentDataFormToDraft(propsalId));
  };

  return (
    <>
      {(() => {
        switch (field.dataType) {
          case "string":
            if (field.suggestionUrl && field.suggestionUrl.length > 0) {
              return (
                <StringSuggestionField
                  field={field}
                  text={`${field.text}`}
                  parentName={parentName}
                  propsalId={propsalId}
                  className={classes.field}
                  classnamelabel={classes.label}
                  errorClassName={classes.errorClassName}
                  inputType={field.inputType}
                  indexGroupField={indexGroupField}
                  handleSaveDraftOnBlur={handleSaveDraftOnBlur}
                />
              );
            }
            if (field.isMultiple) {
              return (
                <StringMultipleField
                  field={field}
                  text={`${field.text}`}
                  parentName={parentName}
                  propsalId={propsalId}
                  className={classes.field}
                  classnamelabel={classes.label}
                  errorClassName={classes.errorClassName}
                  indexGroupField={indexGroupField}
                  handleSaveDraftOnBlur={handleSaveDraftOnBlur}
                />
              );
            }
            return (
              <StringField
                field={field}
                text={`${field.text}`}
                info={field.info}
                sumText={field.sumText}
                inputType={field.inputType}
                parentName={parentName}
                propsalId={propsalId}
                className={classes.field}
                classnamelabel={classes.label}
                errorClassName={classes.errorClassName}
                indexGroupField={indexGroupField}
                handleSaveDraftOnBlur={handleSaveDraftOnBlur}
              />
            );
          case "textarea":
            return (
              <TextAreaField
                field={field}
                text={`${field.text}`}
                parentName={parentName}
                propsalId={propsalId}
                className={classes.field}
                classnamelabel={classes.label}
                errorClassName={classes.errorClassName}
                indexGroupField={indexGroupField}
                handleSaveDraftOnBlur={handleSaveDraftOnBlur}
              />
            );
          case "int":
          case "double":
            if (field.isSum) {
              return (
                <CurrencyField
                  field={field}
                  text={`${field.text}`}
                  info={field.info}
                  parentName={parentName}
                  propsalId={propsalId}
                  className={classes.field}
                  classnamelabel={classes.label}
                  errorClassName={classes.errorClassName}
                  indexGroupField={indexGroupField}
                  handleSaveDraftOnBlur={handleSaveDraftOnBlur}
                />
              );
            }
            return (
              <NumberField
                field={field}
                text={`${field.text}`}
                info={field.info}
                parentName={parentName}
                propsalId={propsalId}
                inputType={field.inputType}
                className={classes.field}
                classnamelabel={classes.label}
                errorClassName={classes.errorClassName}
                indexGroupField={indexGroupField}
                handleSaveDraftOnBlur={handleSaveDraftOnBlur}
              />
            );
          case "date":
            return (
              <DateField
                field={field}
                info={field.info}
                text={`${field.text}`}
                parentName={parentName}
                propsalId={propsalId}
                className={classes.field}
                classnamelabel={classes.label}
                isRequired={field.isRequeired}
                errorClassName={classes.errorClassName}
                indexGroupField={indexGroupField}
                handleSaveDraftOnBlur={handleSaveDraftOnBlur}
              />
            );
          case "bool":
            return (
              <BooleanField
                field={field}
                info={field.info}
                parentName={parentName}
                propsalId={propsalId}
                className={classes.field}
                classnamelabel={classes.label}
                errorClassName={classes.errorClassName}
                indexGroupField={indexGroupField}
                handleSaveDraftOnBlur={handleSaveDraftOnBlur}
              />
            );
          case "combobox":
            return (
              <SelectField
                field={field}
                text={`${field.text}`}
                info={field.info}
                parentName={parentName}
                propsalId={propsalId}
                className={classes.field}
                classnamelabel={classes.label}
                errorClassName={classes.errorClassName}
                indexGroupField={indexGroupField}
                handleSaveDraftOnBlur={handleSaveDraftOnBlur}
              />
            );
          case "group":
            return (
              <GroupField
                field={field}
                text={`${field.text} ${field.isRequeired
                  ? ' <span class="required-asterisk"> *</span>'
                  : ""
                  }`}
                parentName={parentName}
                propsalId={propsalId}
                className={classes.field}
                classnamelabel={classes.label}
                errorClassName={classes.errorClassName}
                indexGroupField={indexGroupField}
                propsalIdsReal={propsalIdsReal}
                handleSaveDraftOnBlur={handleSaveDraftOnBlur}
              />
            );
        }
      })()}
    </>
  );
};

export default React.memo(Fields);
