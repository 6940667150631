import React, {
  Fragment,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState
} from "react";
import { Beforeunload } from "react-beforeunload";
import ConfirmModalRedesign from "../../app/components/confirm-modal-redesign/ConfirmModalRedesign";
import "../../core/consts/redesign/global.scss";
import { ArrowLeftIcon } from "../../core/consts/redesign/icons/ArrowLeft";
import { BasketIcon } from "../../core/consts/redesign/icons/Basket";
import { InfoIcon } from "../../core/consts/redesign/icons/InfoIcon";
import useIsMobile from "../../core/hooks/useIsMobile";
import { actions, useAppSelector, useAppThunkDispatch } from "../../core/store";
import {
  deletePropsalNEW, EResult, getDataForBigForm,
  sendDataFormNEW,
  validateStep
} from "../../core/store/thunk/newOrder";
import ButtonCustom from "../../UIcomponentsRedesign/ButtonCustom/ButtonCustom";
import {
  emptyParentAndChild,
  getParentAndChild, TStepAndStepIndex
} from "../new-order/helpers/helpers";
import {
  ErrorsResponse,
  PropsalData,
  ValidateStepForThunkForm
} from "../new-order/types/bigForm";
import BigForm from "./FormComponents/BigForm/BigForm";
import Success from "./FormComponents/Success";
import classes from "./NewOrderRedesign.module.scss";
import MobileStepper from "./Step/MobileStepper/MobileStepper";
import StepLine from "./Step/StepLine";




const NewOrderRedesign = () => {
  const [isConfirmDeleteModal, setConfirmModal] = useState(false);
  const [currentDeleteId, setCurrentDeleteId] = useState<number>(0);
  const { formData } = useAppSelector((state) => state.newOrder);
  const { realProposalIds } = useAppSelector((state) => state.dataForOrder);
  const [currentStep, setCurrentStep] = useState<PropsalData | null>(null);

  const [currentParentAndChild, setCurrentParentAndChild] =
    useState<TStepAndStepIndex>(emptyParentAndChild);
  const [result, setResult] = useState("no");
  const [isStepValid, setIsStepValid] = useState(true);
  const [isFinishStep, setIsFinishStep] = useState(false);
  const isMobile = useIsMobile();
  const dispatch = useAppThunkDispatch();

  // useEffect(() => {
  //   if (result === EResult.success) {
  //     setIsFinishStep(true);
  //   }
  // }, [result]);

  const onClickDeleteAccordion = (id: number) => {
    // dispatch(deletePropsal(id));
    dispatch(deletePropsalNEW(id))
      .then((r) => {
        goToNextNonInnerStep();
      })
      .catch((error) => {
        console.error("Proposal deletion failed:", error);
      });
  };

  //if a proposal was deleted while inner step is open, go to a common step. if no -> next not inner step
  const goToNextNonInnerStep = () => {
    if (!formData) return;
    // If the current step is marked as common, do nothing
    if (currentStep?.isCommon) return;

    const currentStepId = currentStep?.id;
    let nextStepIndex = -1;

    if (currentStepId !== undefined) {
      const currentIndex = formData.findIndex(
        (step) => step.id === currentStepId
      );
      if (currentIndex >= 0 && currentIndex < formData.length - 1) {
        nextStepIndex = currentIndex + 1;
      }
    }
    if (nextStepIndex === -1) {
      nextStepIndex = 0;
    }
    const nextStep = formData[nextStepIndex];
    setCurrentStep(nextStep);
  };

  const handleDeleteItem = (id: number) => {
    onClickDeleteAccordion(id);
    setConfirmModal(false);
  };

  const deleteOrder = (id: number) => {
    setCurrentDeleteId(id);
    setConfirmModal(true);
  };

  const handleChangeStep = async (step: PropsalData) => {
    setIsStepValid(true);
    await handleValidateStep();
    setCurrentStep(step);
  };

  // Основное получение данных
  useLayoutEffect(() => {
    dispatch(getDataForBigForm());
  }, [dispatch]);

  useEffect(() => {
    return () => {
      actions.deleteNewOrderData();
      actions.pushDataDraftForOrder([]);
      actions.pushDataForOrder({ banksList: [], inn: "" });
      actions.pushIsNewBasedOnOldForOrder(false);
      actions.setIsDisplaySignalInAccordion({ isDisplay: false });
      actions.clearGroupFieldError();
      actions.setAlertModal({
        tittle: undefined,
        text: undefined,
        isError: false,
        confModal: false,
        draftModal: false,
        isRemain: false,
        isPromptShow: true,
      });
      actions.pushRealProposalIdsForOrder([]);
    };
  }, []);

  useEffect(() => {
    if (currentStep && isStepValid === true) {
      window.scrollTo(0, 0);
    } else if (currentStep && isStepValid === false) {
      const el = document.querySelector("div.error_input");
      el?.scrollIntoView();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep]);

  useEffect(() => {
    if (formData && currentStep === null) {
      setCurrentStep(formData?.[0] ?? null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  useEffect(() => {
    if (currentStep) {
      setCurrentParentAndChild(getParentAndChild(formData, currentStep?.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep]);

  const isCurrentStepInner = (
    formData: PropsalData[] | undefined,
    currentStepId: number
  ): boolean => {
    if (!formData) {
      return false;
    }

    return formData.some((step) =>
      step.innerSteps?.some((innerStep) => innerStep.id === currentStepId)
    );
  };
  const currentStepIsInner = isCurrentStepInner(formData, currentStep?.id ?? 0);

  const handleValidateStep = async () => {
    if (!formData) return;
    const { parent, child } = getParentAndChild(formData, currentStep?.id);
    const currentStepValue = child ?? parent;
    if (!currentStepValue) return;
    const proposalIds = currentStepValue?.proposals?.map((el) => el.id);
    if (!proposalIds) return;
    const rdValidate: ValidateStepForThunkForm = {
      proposalId: currentStepValue.id,
      proposalIds,
      stepId: currentStepValue.id,
    };
    const res = await dispatch(validateStep(rdValidate, proposalIds));
    console.log("handleValidateStep res", res);
    return res;
  };

  const prevStep = async (isWithValid?: boolean) => {
    if (!formData || !currentStep) return;
    let res: ErrorsResponse | undefined = undefined;
    if (!isWithValid) {
      res = await handleValidateStep();
    }
    if (res?.isValid === false) {
      setIsStepValid(false);
      const update = { ...currentStep };
      setCurrentStep(null);
      setCurrentStep(update);
    } else {
      setIsStepValid(true);
      const { parent, child, parentIndex, childIndex } = getParentAndChild(
        formData,
        currentStep?.id
      );

      let currentMain = formData?.find((el) => el.id === currentStep?.id);

      if (currentMain && parent) {
        if (parent?.innerSteps && parent?.innerSteps?.length > 0) {
          setCurrentStep(parent?.innerSteps?.[parent?.innerSteps?.length - 1]);
        } else if (parentIndex !== null && parentIndex > 0) {
          const prev = formData?.[parentIndex - 1];
          setCurrentStep(prev);
        }
      } else {
        if (child && parent && parent?.innerSteps) {
          if (childIndex !== null && childIndex > 0) {
            setCurrentStep(parent?.innerSteps?.[childIndex - 1]);
          } else if (childIndex === 0) {
            if (parentIndex !== null && parentIndex > 0) {
              setCurrentStep(formData?.[parentIndex - 1]);
            }
          }
        }
      }
    }
  };

  const isExistPrevStep = useCallback(() => {
    if (!formData) return false;

    const { parent, child, parentIndex, childIndex } = getParentAndChild(
      formData,
      currentStep?.id
    );

    let currentMain = formData?.find((el) => el.id === currentStep?.id);

    if (currentMain && parent) {
      if (parent?.innerSteps && parent?.innerSteps?.length > 0) {
        return true;
      } else if (parentIndex !== null && parentIndex > 0) {
        return true;
      }
    } else {
      if (child && parent && parent?.innerSteps) {
        if (childIndex !== null && childIndex > 0) {
          return true;
        } else if (childIndex === 0) {
          if (parentIndex !== null && parentIndex > 0) {
            return true;
          }
        }
      }
    }
    return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep]);

  const nextStep = async (isWithValid?: boolean) => {
    if (!formData) return;
    let res: ErrorsResponse | undefined = undefined;
    if (!isWithValid) {
      res = await handleValidateStep();
    }
    if (res?.isValid === false) {
      setIsStepValid(false);
      const update = currentStep ? { ...currentStep } : null;
      setCurrentStep(null);
      setCurrentStep(update);
    } else {
      setIsStepValid(true);

      const { parent, child, parentIndex, childIndex } = getParentAndChild(
        formData,
        currentStep?.id ?? formData?.[0]?.id
      );

      let currentMain = formData?.find((el) => el.id === currentStep?.id);
      if (currentMain && parent) {
        if (parentIndex !== null && parentIndex < formData?.length - 1) {
          const next = formData[parentIndex + 1];
          if (next?.innerSteps && next?.innerSteps?.length > 0) {
            setCurrentStep(next.innerSteps[0]);
          } else setCurrentStep(next);
        }
      } else {
        if (child && parent && parent?.innerSteps) {
          if (
            childIndex !== null &&
            childIndex < parent?.innerSteps?.length - 1
          ) {
            setCurrentStep(parent?.innerSteps?.[childIndex + 1]);
          } else {
            setCurrentStep(parent);
          }
        }
      }
    }
  };

  const isExistNextStep = useCallback(() => {
    if (!formData) return false;
    const { parent, child, parentIndex, childIndex } = getParentAndChild(
      formData,
      currentStep?.id ?? formData?.[0]?.id
    );

    let currentMain = formData?.find(
      (el) => el.id === currentStep?.id ?? formData?.[0]?.id
    );
    if (currentMain && parent) {
      if (parentIndex !== null && parentIndex < formData?.length - 1) {
        return true;
      }
    } else {
      if (child && childIndex !== null && parent && parent?.innerSteps) {
        return true;
      }
    }
    return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep]);

  // поверяет, все ли валидны, кроме последнего
  const isAllStepsValid = () => {
    if (!formData) {
      return false;
    } else {
      let isValid = true;
      formData.forEach((element, index) => {
        if (element.innerSteps) {
          element.innerSteps.forEach((el) => {
            if (el.isFill === false) {
              isValid = false;
            }
          });
        }
        if (index !== formData.length - 1 && element.isFill === false) {
          isValid = false;
        }
      });
      return isValid;
    }
  };

  const finish = async () => {
    const res = await dispatch(sendDataFormNEW());
    if (res === EResult.success) {
      setCurrentStep(null);
      setIsFinishStep(true);
    }
    setResult(res);
  };

  const getNotFilledSteps = () => {
    const steps: any[] = [];
    const currentStepId = currentStep?.id;

    if (formData) {
      formData.forEach((element) => {
        if (element.innerSteps) {
          element.innerSteps.forEach((el) => {
            if (el.isFill === false && el.id !== currentStepId) {
              steps.push(el);
            }
          });
        }
        if (element.isFill === false && element.id !== currentStepId) {
          steps.push(element);
        }
      });
    }
    return steps;
  };

  return (
    <Beforeunload onBeforeunload={(event) => event.preventDefault()}>
      <>
        {formData && (
          <div className={classes.main} key={"bigform"}>
            <div className={classes.inner_box}>
              {!isFinishStep &&
                (!currentStep?.isCommon ? (
                  <>
                    <div className={classes.proposal_box}>
                      <div className={classes.proposal_title}>
                        <div className={classes.big_logo}>
                          <img
                            className={classes.big_logo_img}
                            src={currentStep?.image}
                            alt="bank icon"
                          />
                        </div>
                        {!currentStepIsInner && realProposalIds?.length > 1 && (
                          <div
                            className={classes.remove_box}
                            onClick={(event) => {
                              event.stopPropagation();
                              if (currentStep) {
                                deleteOrder(
                                  currentStep.id
                                );
                              }
                            }}
                          >
                            {!isMobile && <span>удалить предложение</span>}
                            <div className={classes.basket_box}>
                              <BasketIcon />
                            </div>
                          </div>
                        )}
                      </div>
                      <div className={classes.title}>{currentStep?.name}</div>
                    </div>
                    {/* <div className="line_x" /> */}
                  </>
                ) : (
                  <div className={classes.title}>{currentStep?.name}</div>
                ))}
              {currentStep && (
                <>
                  <div
                    className={classes.forma_box}
                    onClick={() => setIsStepValid(true)}
                  >
                    <BigForm
                      currentStep={currentStep}
                      setCurrentStep={handleChangeStep}
                    />
                  </div>

                  <div className={classes.flex_g20}>
                    {isExistPrevStep() &&
                      (isMobile ? (
                        <ButtonCustom
                          style={{ width: "64px", height: "64px" }}
                          color="ghost"
                          leftIcon={<ArrowLeftIcon />}
                          handleClick={prevStep}
                        />
                      ) : (
                        <div className={classes.w50}>
                          <button
                            className="button_ghost_w100 big_button"
                            onClick={() => prevStep(true)}
                          >
                            Предыдущий шаг
                          </button>
                        </div>
                      ))}
                    {isExistNextStep() && (
                      <div
                        className={
                          !isExistPrevStep() || isMobile
                            ? classes.w100
                            : classes.w50
                        }
                      >
                        {isStepValid ? (
                          <button
                            className="button_primary_w100 big_button"
                            onClick={() => nextStep(false)}
                          >
                            Следующий шаг
                          </button>
                        ) : (
                          <button
                            className="button_primary_w100 big_button"
                            onClick={() => nextStep(true)}
                          >
                            Пропустить и заполнить позже
                          </button>
                        )}
                      </div>
                    )}
                    {!isExistNextStep() && (
                      <div className={isMobile ? classes.w100 : classes.w50}>
                        <button
                          className="button_primary_w100 big_button"
                          onClick={finish}
                          disabled={!isAllStepsValid()}
                        >
                          Завершить
                        </button>
                      </div>
                    )}
                  </div>

                  {/* список незаполенных степов */}

                  {!isExistNextStep() && getNotFilledSteps().length > 0 ? (
                    <div className={classes.errorStepsBlock}>
                      <div>
                        <InfoIcon />
                      </div>
                      <div className={classes.errorStepsMap}>
                        <div>Чтобы отправить заявку необходимо заполнить</div>
                        {getNotFilledSteps().map((step, i, array) => (
                          <Fragment key={step.id}>
                            <div
                              className="blue_link"
                              onClick={() => setCurrentStep(step)}
                            >
                              {step.name}
                            </div>
                            {i !== array.length - 1 && "|"}
                          </Fragment>
                        ))}
                      </div>
                    </div>
                  ) : null}
                </>
              )}
              {/* {!currentStep && result} */}
              {isFinishStep && <Success />}
            </div>

            {isMobile ? (
              <>
                <div className={classes.right_box}>
                  <div className={classes.right_header}>
                    шаг{" "}
                    {currentParentAndChild.parentIndex !== null &&
                      currentParentAndChild.parentIndex + 1}{" "}
                    {currentParentAndChild.child
                      ? `.${currentParentAndChild.childIndex !== null &&
                      currentParentAndChild.childIndex + 1
                      }`
                      : currentParentAndChild?.parent?.innerSteps?.length
                        ? `.${currentParentAndChild?.parent?.innerSteps?.length + 1
                        }`
                        : ""}{" "}
                    из {formData?.length}
                    <h3 className={classes.mob_subtitle}>
                      {currentStep?.name}
                    </h3>
                  </div>
                  <MobileStepper
                    setCurrentStep={handleChangeStep}
                    currentStep={currentStep}
                    isFinishStep={isFinishStep}
                  />
                </div>
              </>
            ) : (
              <div className={classes.right_box}>
                <div className={classes.white_step_box}>
                  <div className={classes.right_header}>
                    <div>
                      шаг{" "}
                      {currentParentAndChild.parentIndex !== null &&
                        currentParentAndChild.parentIndex + 1}{" "}
                      {currentParentAndChild.child
                        ? `.${currentParentAndChild.childIndex !== null &&
                        currentParentAndChild.childIndex + 1
                        }`
                        : currentParentAndChild?.parent?.innerSteps?.length
                          ? `.${currentParentAndChild?.parent?.innerSteps?.length +
                          1
                          }`
                          : ""}{" "}
                      из {formData?.length}
                    </div>
                    <div className={classes.right_title}>Заполнение заявки</div>
                  </div>

                  {/* Общие шаги */}

                  {formData
                    ?.filter((el) => el.isCommon === true)
                    .map((elem, index, array) => (
                      <Fragment key={elem.id}>
                        <StepLine
                          step={elem}
                          index={index}
                          isCurrent={elem.id === currentStep?.id ? true : false}
                          isPrevFull={
                            array?.[index - 1]?.isFill === true ? true : false
                          }
                          setCurrentStep={handleChangeStep}
                          deleteOrder={deleteOrder}
                          isHeaderForStep={
                            elem?.innerSteps && elem?.innerSteps.length > 0
                              ? true
                              : false
                          }
                          isFinishStep={isFinishStep}
                        />
                        {elem?.innerSteps &&
                          elem?.innerSteps.length > 0 &&
                          elem.innerSteps.map((el, i) => (
                            <div style={{ paddingLeft: "30px" }}>
                              <StepLine
                                step={el}
                                index={i}
                                isCurrent={
                                  el.id === currentStep?.id ? true : false
                                }
                                isPrevFull={
                                  array?.[i - 1]?.isFill === true ? true : false
                                }
                                setCurrentStep={handleChangeStep}
                                deleteOrder={deleteOrder}
                                isInner={true}
                                isFinishStep={isFinishStep}
                              />
                            </div>
                          ))}
                      </Fragment>
                    ))}
                </div>

                {/* шаги конкретных предложений с подстепами  */}

                {formData
                  ?.filter((el) => el.isCommon === false)
                  ?.map((elem, index, array) => (
                    <Fragment key={elem.id}>
                      <div className={classes.white_step_box}>
                        <StepLine
                          step={elem}
                          index={index}
                          isCurrent={
                            elem.id === currentStep?.id && !elem.innerSteps
                              ? true
                              : false
                          }
                          isPrevFull={
                            array?.[index - 1]?.isFill === true ? true : false
                          }
                          setCurrentStep={handleChangeStep}
                          deleteOrder={deleteOrder}
                          isHeaderForStep={
                            elem?.innerSteps !== null &&
                              elem?.innerSteps.length > 0
                              ? true
                              : false
                          }
                          isFinishStep={isFinishStep}
                        />
                        {elem?.innerSteps && elem?.innerSteps.length > 0 && (
                          <>
                            {elem.innerSteps.map((el, i) => (
                              <div style={{ marginLeft: "30px" }}>
                                <StepLine
                                  step={el}
                                  index={i}
                                  isCurrent={
                                    el.id === currentStep?.id ? true : false
                                  }
                                  isPrevFull={
                                    array?.[i - 1]?.isFill === true
                                      ? true
                                      : false
                                  }
                                  setCurrentStep={handleChangeStep}
                                  deleteOrder={deleteOrder}
                                  isInner={true}
                                  isFinishStep={isFinishStep}
                                />
                              </div>
                            ))}

                            <StepLine
                              step={elem}
                              index={index}
                              isCurrent={
                                elem.id === currentStep?.id ? true : false
                              }
                              isPrevFull={
                                array?.[index - 1]?.isFill === true
                                  ? true
                                  : false
                              }
                              setCurrentStep={handleChangeStep}
                              deleteOrder={deleteOrder}
                              isFooterForStep={true}
                              isFinishStep={isFinishStep}
                            />
                          </>
                        )}
                      </div>
                    </Fragment>
                  ))}

                {result === EResult.success && (
                  <div className={classes.white_step_box}>
                    <StepLine
                      step={null}
                      index={0}
                      isCurrent={true}
                      isPrevFull={true}
                      setCurrentStep={handleChangeStep}
                      deleteOrder={deleteOrder}
                      isFinish={true}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        )}
        <ConfirmModalRedesign
          title={`Удалить предложение из заявки?`}
          text={`Вы действительно хотите убрать продукт из списка добавленных в заявку`}
          confBtnText={"Удалить предложение"}
          cancelBtnText={"Вернуться к заполнению"}
          handleCancel={() => setConfirmModal(false)}
          handleClose={() => setConfirmModal(false)}
          handleConfirm={() => handleDeleteItem(currentDeleteId)}
          open={isConfirmDeleteModal}
        />
      </>
    </Beforeunload>
  );
};

export default React.memo(NewOrderRedesign);
