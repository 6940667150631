export const BASE_URL = "http://frontend.finlab-dev.finlab.internal/";

export const ROUTES: Record<string, string> = {
  ABOUT: "/about/",
  ACTS: "/acts",
  ADMIN: "/admin",
  ADMIN_NEW_PRODUCT: "/new-product",
  ADMIN_EDIT_PRODUCTS: "/edit-products",
  ADMIN_EDIT_PRODUCT: "/edit-products/new-product",
  ADMIN_ALL_NEWS: "/edit-news",
  ADMIN_NEW_NEWS: "/edit-news/new-article",
  ADMIN_EDIT_NEWS: "/edit-news/new-article/",
  ADMIN_ALL_ARTICLES: "/edit-articles",
  ADMIN_NEW_ARTICLE: "/edit-articles/new-article",
  ADMIN_EDIT_ARTICLE: "/edit-articles/new-article/",
  ADMIN_STATISTIC: "/statistic",
  AGENT: "/agent",
  AGENTS: "/agents/",
  ARCHIVE: "/archive",
  ARTICLE: "/article/",
  ARTICLES_AUTHOR: "/articles/author/",
  ARTICLES: "/articles/",
  BANK_GAURANTEE: "/bankovskiye-garantii/",
  CLIENT_KNOWLEDGE_BASE: "/knowledge-base",
  CLIENT: "/client",
  CLIENTS: "/clients",
  CONSUMER_CREDIT: "/potrebcredits/",
  CONTACTS: "/contacts/",
  CREDIT_CARD: "/creditcard/",
  CREDIT: "/credits/",
  FACTORING: "/factoring/",
  KNOWLEDGE: "/knowledge-base",
  KNOWLEDGE_ARTICLE: "/knowledge-base/",
  LEASING: "/lizing/",
  LOGIN: "/login/",
  MAIN: "/",
  MANAGER: "/manager",
  MESSAGES: "/messages",
  MORTGAGE: "/ipoteka/",
  NEWS: "/news/",
  NEW_ORDER: "/new-order",
  NEW_ORDER_FORM: "/form-new-order",
  OTKRITIE_RASCHETNOGO_SCHETA: "/otkritie-raschetnogo-scheta/",
  PARTNER: "/partner",
  PARTNERS_APPLICATIONS: "/applications",
  PARTNERS_APPLICATION: "/application/",
  PERSONAL_DATA_PROCESSING_AGREEMENT: "/personal-data-processing-agreement/",
  PRIVATE_CLIENTS: "/privateclients/",
  PRODUCT_TYPE: "/producttype",
  PRODUCT: "/product",
  PRODUCTS: "/products",
  PROFILE: "/profile",
  RKO: "/rko/",
  SCORING: "/counterparty-check",
  SUPPORT: "/support",
  USER_AGREEMENT: "/user-agreement/",
};

export const TOKEN_NAME = "c2mFinanceTokenService";

export const ROLES = {
  CLIENT: "Клиент",
  AGENT: "Агент",
  PARTNER: "Партнер",
  MANAGER: "Менеджер",
  ADMIN: "Администратор",
};

export const roleTranslations: { [key: string]: string } = {
  "клиент": "CLIENT",
  "агент": "AGENT",
  "партнер": "PARTNER",
  "менеджер": "MANAGER",
  "администратор": "ADMIN"
};

export enum CookiesEnum {
  cart = "cart",
  filterParams = 'filterParams',
  name = "name",
  last_name = "last_name",
  role = "role",
  code = "code",
  followingLinkEventId = "followingLinkEventId",
  cid = "cid",
  uid = "uid",
  CIDProduct = "CIDProduct"
}

export enum SessionEnum {
  leadsFilterParams = 'leadsFilterParams',
}

export enum SearchParamsEnum {
  utm_source = "utm_source",
  utm_medium = "utm_medium",
  utm_campaign = "utm_campaign",
  click_id = "click_id",
  user_id = "user_id",
};

export enum FormType {
  LoginFirstPhone = "login-first-phone",
  LoginFirstEmail = "login-first-email",
  LoginSecondPhone = "login-second-phone",
  LoginSecondEmail = "login-second-email",
  LoginCertificate = "login-certificate",
  LoginCertificateCheck = "login-certificate-check",
  LoginBlockMob = "login-mob-block",
  Registration = "registration",
  RegistrationDetail = "registration-detail",
  LoginInn = "login-inn",
  LoginType = "login-type",
  LoginClientOrAgent = "login-client-or-agent",
}

export enum ProductsCodeEnum {
  credits = "credits",
  factoring = "factoring",
  lizing = "lizing",
  bankovskiye_garantii = "bankovskiye_garantii",

  rko = "rko",
  potrebcredits = "potrebcredits",
  creditcard = "creditcard",
  ipoteka = "ipoteka",
  business = "business",
  privateclients = "privateclients",
  agents = "agents",
  "bankovskiye-garantii" = "bankovskiye_garantii",
}


export type ObjectWithSameKeys<ValueType> = Record<ProductsCodeEnum, ValueType>;

export const ProductsAliasEnum: ObjectWithSameKeys<string> = {
  credits: "credits",
  factoring: "factoring",
  lizing: "lizing",
  bankovskiye_garantii: "bankovskiye-garantii",
  rko: "raschetno_kassovoe",
  potrebcredits: "potrebcredits",
  creditcard: "creditcard",
  ipoteka: "ipoteka",
  business: "business",
  privateclients: "privateclients",
  agents: "agents",
}
export const ProductsIdEnum: ObjectWithSameKeys<number> = {
  credits: 1000,
  factoring: 1001,
  lizing: 1004,
  bankovskiye_garantii: 1003,
  rko: 1002,
  // otkritie_raschetnogo_scheta: 1005,
  potrebcredits: 1006,
  creditcard: 1007,
  ipoteka: 1008,
  business: 0,
  privateclients: 0,
  agents: 0,
};

export const ProductsEnum: ObjectWithSameKeys<string> = {
  credits: "Кредитование",
  factoring: "Факторинг",
  lizing: "Лизинг",
  bankovskiye_garantii: "Банковские гарантии",
  rko: "Расчетно-кассовое обслуживание",

  // otkritie_raschetnogo_scheta: "открытие расчетного счета",
  potrebcredits: "Потребительский кредит",
  creditcard: "Кредитная карта",
  ipoteka: "Ипотека",
  business: "Бизнесу и ип",
  privateclients: "Частным клиентам",
  agents: "Агентам"
};

export const ProductsEnumRodPadej: ObjectWithSameKeys<string> = {
  credits: "Кредитa",
  factoring: "Факторинга",
  lizing: "Лизинга",
  bankovskiye_garantii: "Банковской гарантии",
  rko: "Расчетно-кассовое обслуживания",

  // otkritie_raschetnogo_scheta: "открытия расчетного счета",
  potrebcredits: "Потребительского кредита",
  creditcard: "Кредитной карты",
  ipoteka: "Ипотеки",
  business: "Бизнесу и ип",
  privateclients: "Частным клиентам",
  agents: "Агентам"
};

export const ProductsRouteEnum: ObjectWithSameKeys<string> = {
  credits: "CREDIT",
  factoring: "FACTORING",
  lizing: "LEASING",
  bankovskiye_garantii: "BANK_GAURANTEE",
  rko: "RASCHETNO_KASSOVOE",
  // otkritie_raschetnogo_scheta: "RASCHETNO_KASSOVOE",
  potrebcredits: "POTREB_CREDIT",
  creditcard: "CREDIT_CARD",
  ipoteka: "MORTGAGE",
  business: "PRODUCTS",
  privateclients: "PRIVATE_CLIENTS",
  agents: "AGENTS"
};

export const CHAT_ROLES = {
  client:
    "https://secure.gravatar.com/avatar/593f878d696bba98357e1e91abc005ba?s=800&d=identicon",
  agent:
    "https://secure.gravatar.com/avatar/91c73603ef979e4fd670eb3758869318?s=800&d=identicon",
  PARTNER: "Partner",
  MANAGER: "Manager",
};

export enum ORGANIZATION_TYPE {
  bank = "Банк",
  factoringCompany = "Факторинговая компания",
  lizingCompany = "Лизинговая компания",
}

export enum ORGANIZATION_TYPE_OF_ENUM {
  "Банк" = "банка",
  "Факторинговая компания" = "факторинговой компании",
  "Лизинговая компания" = "лизинговой компании",
}

export enum UTM_EVENT_ENUM {
  FollowingLink = "FollowingLink",
  EnteredPhoneNumber = "EnteredPhoneNumber",
  ChoseRole = "ChoseRole",
  CompletedRegistration = "CompletedRegistration",
}

export enum ClientType {
  Client = "client",
  Agent = "agent",
}

export const RegisterFormCommon = {
  lastName: "*Фамилия",
  firstName: "*Имя",
  patrName: "Отчество (если есть)",
  nameLabel: "*Ваше ФИО",
  phoneLabel: "*Номер телефона",
  phone: "+7 (800) 000-00-00",
  email: "*Адрес электронной почты",
  inn: "ИНН",
  innCompany: "*ИНН компании",
  innOrganisation: "*ИНН организации",
  password: "Пароль",
  code: "Код",
  codeLabel: "Введите код",
  bik: "*БИК",
  nalogs: "*Система налогооблажения",
  fact_adress: "*Фактический адрес",
  mail: "*Почтовый адрес",
  payment: "*р/c",
};

export const regFio = /^ *[А-Я][а-яА-Я-]{1,} *$/gm;

export enum COLORS {
  white = "#ffffff",
  light_gray_bg = "#ecf3f6",
  light_gray_stroke = "#c7d5e7",
  gray = "#96a8b8",

  gray2 = "#7990A5",
  black = "#001317",
  brand_blue = "#4794ef",
  light_blue = "#77b5fd",

  success = "#51b56b",
  warning = "#ffa800",
  alert = "#dc5103",
  magents = '#ee35f2',
  purple = '#7B61FF29',
}

export enum SortEnam {
  AgeAsc = "AgeAsc",
  AgeDesc = "AgeDesc",
  Status = "Status",
  CompanyNameAsc = "CompanyNameAsc",
  CompanyNameDesc = "CompanyNameDesc",
  SumASc = "SumASc",
  SumDesc = "SumDesc",
}

export const Sort = {
  [SortEnam.AgeAsc]: "Сначала новые",
  [SortEnam.AgeDesc]: "Сначала старые",
  [SortEnam.Status]: "По статусу",
  [SortEnam.CompanyNameAsc]: "По имени компании А - Я",
  [SortEnam.CompanyNameDesc]: "По имени компании Я - А",
  [SortEnam.SumASc]: "По большей сумме",
  [SortEnam.SumDesc]: "По меньшей сумме",
};

export enum InfoPartEnum {
  news = "news",
  articles = "articles",
}

export const CitiesEnum: Record<string, string> = {
  "nizhnii-novgorod": "Нижний Новгороде",
  novosibirsk: "Новосибирске",
  ekaterinburg: "Екатеринбурге",
  birsk: "Бирске",
  odincovo: "Одинцово",
  kirov: "Кирове",
  dubna: "Дубне",
  spb: "Санкт-Петербурге",
  moskva: "Москве",
  krasnodar: "Краснодаре",
  kazan: "Казани",
  cheliabinsk: "Челябинске",
  beloretck: "Белорецке",
  krasnoiarsk: "Красноярске",
  tumen: "Тюмени",
  omsk: "Омске",
  perm: "Перми",
  magnitogorsk: "Магнитогорске",
  samara: "Самаре",
  ufa: "Уфе",
};
export const Months = [
  "январь",
  "февраль",
  "март",
  "апрель",
  "май",
  "июнь",
  "июль",
  "август",
  "сентябрь",
  "октябрь",
  "ноябрь",
  "декабрь",
];

export const PhoneNumber = "+8 (800) 500-35-93";
export const PhoneNumberHref = "tel:78005003593";

export const DevStandApp = "http://frontend-old.finlab-dev.finlab.internal/";
export const DevStandAppDomain = "frontend-old.finlab-dev.finlab.internal";
export const DevStand = "http://frontend.finlab-dev.finlab.internal/";
export const ProdStandApp = "https://app.finlab.ru/";
export const ProdStandAppDomain = "app.finlab.ru";
export const ProdStand = "https://finlab.ru/";

// export const PercentDeal = 0.8;

export enum DealEnum {
  Min = 100000,
  Max = 200000000,
  Default = 1000000,
}

export enum PercentDeal {
  credits = 0.8,
  bankovskiye_garantii_bank = 4,
  bankovskiye_garantii = 30,
  lizing = 2.5,
  factoring_bank_year = 20,
  factoring = 5,
}

export enum DateSortType {
  sort_asc = "createdate_asc",
  sort_desc = "createdate_desc"
}

export enum InputTypeEnum {
  // radioButton = "radiobutton",
  inn = "inn", // digits 10 or 12
  email = "email", //no rule
  postalCode = "postal-code",  //6 digits
  address = "address", //no rule
  phone = "phone", //
  url = "url",
  currency = "currency", // digits no limit
  bik = "bik", //9 digits
  percent = "percent",
  ikz = "ikz",//36 digits
  passport = "pasport", //digits XXXX XXXXXX
  passportCode = "pasport-code", //issuied place code digits XXX-XXX
  passportSeries = "pasport-series",  //4 digits XXXX
  passportNumber = "pasport-number", //6 digits XXXXXX
  currentAccount = "current-account", //bank account 20 digits
  correspondentAccount = "correspondent-account", //bank account 20 digits
  snils = "snils" // 11 digits XXX-XXX-XXX XX
}

export const russianMonths = [
  'января', 'февраля', 'марта', 'апреля', 'мая', 'июня',
  'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'
];
