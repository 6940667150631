import createCachedSelector from "re-reselect";
import { createSelector } from "reselect";
import { getFormDataForCurrentStep, takeModalValue } from "../../../pages/new-order/helpers/helpers";
import { Field, FieldModal } from "../../../pages/new-order/types/bigForm";
import { rootState } from "../../types/rootState";

type FieldValueProps = {
  fieldName: string,
  propsalId: number,
  parentName?: string,
  indexGroupField?: number
}

export const formValues = (state: rootState) => state.newOrder.formValues

export const errorsFields = (state: rootState) => state.newOrder.errorsFields

export const alertModal = (state: rootState) => state.newOrder.alertModal

export const formErrorsCount = (state: rootState) => state.newOrder.countErrors

export const groupFieldsErrors = (state: rootState) => state.newOrder.errorsGroupFields

export const formData = (state: rootState) => state.newOrder.formData

export const isFetching = (state: rootState) => state.newOrder.isFetching

export const currentModalData = (state: rootState) => state.newOrder.currentModalData

export const currentEditModalValues = (state: rootState) => state.newOrder.currentEditModal.values

export const currentEditModalErrors = (state: rootState) => state.newOrder.currentEditModal.errors

export const fieldValue = createCachedSelector(
  [
    formValues,
    (_: rootState, props: FieldValueProps) => props.fieldName,
    (_: rootState, props: FieldValueProps) => props.propsalId,
    (_: rootState, props: FieldValueProps) => props.parentName,
    (_: rootState, props: FieldValueProps) => props.indexGroupField
  ],
  (formValues, fieldName, propsalId, parentName, indexGroupField): {} | [] | Field | FieldModal | FieldModal[] | undefined => {
    // console.log("fieldValue createCachedSelector formValues", formValues)
    if (formValues) {
      const fieldsValue = formValues?.[propsalId];
      if (parentName) {
        if (indexGroupField) {
          return takeModalValue(
            fieldsValue,
            parentName,
            fieldName,
            indexGroupField
          );
        }
        return takeModalValue(fieldsValue, parentName, fieldName);
      }
      return fieldsValue?.[fieldName];
    }
  }
)((_, props) => `
  ${props.fieldName || ''}
  ${props.propsalId || ''}
  ${props.parentName || ''}
  ${props.indexGroupField || ''}
`)

export const errorValue = createCachedSelector(
  [
    errorsFields,
    (_: rootState, props: FieldValueProps) => props.fieldName,
    (_: rootState, props: FieldValueProps) => props.propsalId,
    (_: rootState, props: FieldValueProps) => props.parentName,
    (_: rootState, props: FieldValueProps) => props.indexGroupField
  ],
  (errorsFields, fieldName, propsalId, parentName, indexGroupField) => {
    if (errorsFields) {
      const fieldsError = errorsFields?.[propsalId]?.fields;
      if (parentName) {
        if (indexGroupField) {
          return takeModalValue(
            fieldsError,
            parentName,
            fieldName,
            indexGroupField
          );
        }
        return takeModalValue(fieldsError, parentName, fieldName);
      }
      return fieldsError?.[fieldName];
    }
  }
)((_, props) => `
  ${props.fieldName || ''}
  ${props.propsalId || ''}
  ${props.parentName || ''}
  ${props.indexGroupField || ''}
`)

export const modalPropsalId = (state: rootState) =>
  state.newOrder.currentModalData.propsalId;

type CurrentModalPropsType = {
  fieldName?: string;
  modalIndex?: number;
}

export const currentModalFieldValue = createCachedSelector(
  [
    currentEditModalValues,
    // (_: rootState, props: { fieldName?: string }) => props.fieldName
    (_: rootState, props: CurrentModalPropsType) => props.fieldName,
    (_: rootState, props: CurrentModalPropsType) => props.modalIndex,
  ],
  // (values, fieldName) => {
  (values, fieldName, modalIndex) => {
    // return values[fieldName];
    if (values && fieldName) {
      return values?.[modalIndex ?? 0]?.[fieldName]
    }
  }
)((_, props) => `
  ${props.fieldName || ''}
`)

export const currentModalFieldError = createCachedSelector(
  [
    currentEditModalErrors,
    // (_: rootState, props: { fieldName?: string }) => props.fieldName
    (_: rootState, props: CurrentModalPropsType) => props.fieldName,
    (_: rootState, props: CurrentModalPropsType) => props.modalIndex,
  ],
  // (errors, fieldName) => {
  (errors, fieldName, modalIndex) => {
    if (errors && fieldName) {
      // return errors[fieldName]
      return errors?.[modalIndex ?? 0]?.[fieldName]
    }
  }
)((_, props) => `
  ${props.fieldName || ''}
`)

export const propsalErrors = createCachedSelector(
  [
    errorsFields,
    (_: rootState, props: { propsalId?: number }) => props.propsalId,
  ],
  (errors, propsalId) => {
    if (errors && propsalId !== undefined) {
      return errors[propsalId];
    }
  }
)(
  (_, props) => `
  ${props.propsalId || ""}
`
);

export const modalField = createSelector(
  formData,
  currentModalData,
  (formData, currentModalData) => {
    // console.log("createSelector formData", formData)
    // console.log("createSelector currentModalData", currentModalData)
    if (
      formData &&
      currentModalData.propsalId &&
      currentModalData.modalName
    ) {
      // let propsal = formData.find((data) => data.id === currentModalData.propsalId)
      // if (!propsal) {
      //   formData.forEach(elem => {
      //     console.log("formData.forEach elem?.innerSteps", elem?.innerSteps)
      //     if (elem?.innerSteps) {
      //       propsal = elem.innerSteps.find(pr => pr.id === currentModalData.propsalId)
      //     }
      //   })
      // }
      const propsal = getFormDataForCurrentStep(formData, currentModalData.propsalId);

      console.log("createSelector propsal", propsal)

      if (propsal) {
        return propsal.fields.find(
          (item) => item.name === currentModalData.modalName
        );
      }
    }
  }
)
