import { ReactNode } from "react";
import "../../core/consts/redesign/global.scss";

type PropType = {
  handleClick?: () => void;
  id?: string;
  disabled?: boolean;
  leftIcon?: ReactNode;
  rightIcon?: ReactNode | string;
  children?: ReactNode;
  style?: React.CSSProperties;
  handleClickEvent?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  color?: "primary" | "primary_w100" | "secondary" | "tertiary" | "ghost" | "ghost_w100" | "text_button" | "text_button__small" | "text_button_alert__small" | "gray";
  fullWidth?: boolean;
  type?: "button" | "reset" | "submit" | undefined;
};

const colorClassMap = {
  primary: "button_primary",
  primary_w100: "button_primary_w100",
  secondary: "button_secondary",
  tertiary: "button_tertiary",
  ghost: "button_ghost",
  ghost_w100: "button_ghost_w100",
  text_button: "text_button",
  text_button__small: "text_button__small",
  text_button_alert__small: "text_button_alert__small",
  gray: 'button_gray'
};

const ButtonCustom = ({
  id,
  handleClick,
  handleClickEvent,
  disabled = false,
  leftIcon,
  rightIcon,
  children,
  style,
  color,
  fullWidth = false,
  type
}: PropType) => {
  const buttonClassName = color ? colorClassMap[color] : "main_button";
  const buttonStyle = fullWidth ? { ...style, width: '100%' } : style;

  return (
    <button
      type={type}
      onClick={handleClick ? handleClick : handleClickEvent}
      className={buttonClassName}
      id={id ? id : `$buttonId`}
      disabled={disabled}
      style={buttonStyle}
    >
      {leftIcon}
      {children}
      {rightIcon}
    </button>
  );
};

export default ButtonCustom;
