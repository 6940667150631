import React, { memo, useState, useEffect } from "react";
import {
  Typography,
  IconButton,
  Box,
  Button,
  Grid,
  Dialog,
  FormControlLabel,
  Checkbox,
  Divider,
  TextField,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import userService from "../../../../core/services/UserService";
import { UserInfoType } from "../../../../core/types/userTypes";
import managerService from "../../../../core/services/ManagerService";
import clsx from "clsx";
import Comments from "../../../../app/components/comments";
import ConfirmModal from "../../../../app/components/confirm-modal";
import UploadFilesForm from "../../../../app/components/registration/components/upload-files-form/UploadFilesForm";
import { useStylesClientInfoModal } from "./useStylesClientInfoModal";
import EditField from "../edit-field";

type ErrorType = {
  field: string;
  message: string;
  type: string;
};

type PropsType = {
  close: () => void;
  open: boolean;
  clientId: string;
  currentChatId: string;
  handleChatModalOpen: (
    id: number,
    inn: string,
    role: string,
    clientId?: string
  ) => void;
};

type ChangedDataType = {
  inn: string;
  bik: string;
  tax_type: string;
  account: string;
  fact_address: string;
  post_address: string;
};

const ClientInfoModal: React.FC<PropsType> = (props) => {
  const classes = useStylesClientInfoModal();
  const [openModal, setOpenModal] = useState(props.open);
  const [userInfo, setUserInfo] = useState<UserInfoType>();
  const [agent, setAgent] = useState<boolean>();
  const [isUserVerified, setIsUserVerified] = useState<boolean>();
  const [changedFields, setChangedFields] = useState<ChangedDataType>({
    inn: "",
    bik: "",
    tax_type: "",
    account: "",
    fact_address: "",
    post_address: "",
  });
  const [changedFieldsErrors, setChangedFieldsErrors] =
    useState<ChangedDataType>({
      inn: "",
      bik: "",
      tax_type: "",
      account: "",
      fact_address: "",
      post_address: "",
    });

  const saveByteArray = (function () {
    const a = document.createElement("a");
    document.body.appendChild(a);
    return function (data: any, name: string) {
      const blob = new Blob([data]),
        url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = name;
      a.click();
      window.URL.revokeObjectURL(url);
    };
  })();

  const handleDownloadFile = (
    userId: string,
    documentType: "passport" | "protocol"
  ) => {
    managerService
      .downloadDocument({
        userId,
        documentType,
      })
      .then((res) => {
        if (res.headers["content-disposition"]) {
          const title: string = res.headers["content-disposition"];
          saveByteArray(
            res.data,
            title.split(" ")[1].replace("filename=", "").replace(";", "")
          );
        }
      })
      .catch((err) => {
        console.log("download document -->", err);
      });
  };

  useEffect(() => {
    userService
      .getUserInfoService(props.clientId)
      .then((res: UserInfoType) => {
        setUserInfo(res);
        setAgent(res.roles.role_name === "Агент");
        setIsUserVerified(res.is_manager_approval);
      })
      .catch((error) => console.log(error));
  }, [props.clientId]);

  const [isShowBtnSafe, setShowBtnSafe] = useState(false);
  const [isOpenExtraModal, setOpenExtraModal] = useState(false);
  useEffect(() => {
    if (userInfo) {
      if (
        changedFields.bik !== userInfo.bik ||
        changedFields.tax_type !== userInfo.tax_type ||
        changedFields.inn !== userInfo.inn ||
        changedFields.account !== userInfo.account ||
        changedFields.fact_address !== userInfo.fact_address ||
        changedFields.post_address !== userInfo.post_address
      ) {
        setShowBtnSafe(true);
      } else {
        setShowBtnSafe(false);
      }
    }
  }, [userInfo, changedFields]);

  const handleClickSafe = () => {
    userService
      .putUserInfoService({ ...changedFields, id: props.clientId })
      .then(() => {
        userService
          .getUserInfoService(props.clientId)
          .then((res: UserInfoType) => {
            setUserInfo(res);
            setAgent(res.roles.role_name === "Агент");
            setIsUserVerified(res.is_manager_approval);
          })
          .catch((error) => console.log("update user info ->", error));
        setChangedFieldsErrors((state) => ({
          ...state,
          inn: "",
          bik: "",
          tax_type: "",
          account: "",
          fact_address: "",
          post_address: "",
        }));
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          error?.response?.data?.errors?.forEach((err: ErrorType) => {
            if (err?.field && err?.message) {
              setChangedFieldsErrors((state) => ({
                ...state,
                [err?.field]: err?.message,
              }));
            }
          });
        }
      });
  };

  const handleChangeField = (name: string, value: string) => {
    setChangedFields((state) => ({ ...state, [name]: value }));
  };

  useEffect(() => {
    if (userInfo) {
      setChangedFields((state) => ({
        ...state,
        inn: userInfo.inn,
        bik: userInfo.bik,
        tax_type: userInfo.tax_type,
        account: userInfo.account,
        fact_address: userInfo.fact_address,
        post_address: userInfo.post_address,
      }));
    }
  }, [userInfo]);

  const handleUserVerify = async () => {
    if (userInfo) {
      await userService
        .changeUserVerificationService({
          userId: userInfo.id,
          set: !isUserVerified,
        })
        .then(() => {
          userService
            .getUserInfoService(props.clientId)
            .then((res: UserInfoType) => {
              setUserInfo(res);
              setAgent(res.roles.role_name === "Агент");
              setIsUserVerified(res.is_manager_approval);
            })
            .catch((error) => console.log(error));
        });
    }
  };
  const handleClose = () => {
    if (isShowBtnSafe) {
      setOpenExtraModal(true);
    } else {
      setOpenModal((prev) => !prev);
      props.close();
    }
  };

  const handleConfirm = () => {
    handleClickSafe();
    setOpenModal((prev) => !prev);
    props.close();
    setOpenExtraModal(false);
  };

  const handleUnConfirm = () => {
    setOpenModal((prev) => !prev);
    props.close();
    setOpenExtraModal(false);
  };

  const handleUpdateUserInfo = () => {
    userService
      .getUserInfoService(props.clientId)
      .then((res: UserInfoType) => {
        setUserInfo(res);
        setAgent(res.roles.role_name === "Агент");
        setIsUserVerified(res.is_manager_approval);
      })
      .catch((error) => console.log(error));
  };

  const EditFile = async (type: "passport" | "protocol", file: any) => {
    if (!file) {
      await managerService.deleteDocument({
        userId: userInfo?.id || "",
        documentType: type,
      });
    } else {
      if (userInfo?.passport) {
        await managerService.putDocument(
          {
            userId: userInfo?.id || "",
            documentType: type,
          },
          file
        );
      } else {
        await managerService.postDocument(
          {
            userId: userInfo?.id || "",
            documentType: type,
          },
          file
        );
      }
    }
    userService
      .getUserInfoService(props.clientId)
      .then((res: UserInfoType) => {
        setUserInfo(res);
        setAgent(res.roles.role_name === "Агент");
        setIsUserVerified(res.is_manager_approval);
      })
      .catch((error) => console.log(error));
  };

  return (
    <Dialog
      open={openModal}
      onClose={handleClose}
      className={classes.modal}
      scroll={"body"}
      fullScreen={false}
      maxWidth={false}
    >
      <Box className={classes.paper}>
        <Box className={classes.box}>
          <Typography
            variant="h3"
            component="h3"
            className={classes.title}
            style={{ padding: "0 0 25px 0" }}
          >
            Информация о клиенте
          </Typography>
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        {userInfo && (
          <Box className={classes.agentBox}>
            <Grid className={classes.gridItems}>
              <Grid item className={classes.gridItem} sm={6}>
              <Typography
                variant="body2"
                component="span"
                style={{ color: `${agent ? "#942BD4" : "#309A90"}` }}
              >
                {` ${userInfo?.roles.role_name} `}
              </Typography>
              </Grid>
              <Grid className={classes.gridItem} sm={6}>
              <Typography
                variant="body2"
                component="span"
                style={{
                  color:
                    userInfo.status === "Новый"
                      ? "#617E8C"
                      : userInfo.status === "Верифицирован"
                      ? "#69A042"
                      : "#D62D30",
                }}
              >
                {userInfo.status}
              </Typography>
              </Grid>
            </Grid>
            <FormControlLabel
              className={classes.checkBox}
              control={
                <Checkbox
                  checked={userInfo?.is_manager_approval}
                  onChange={handleUserVerify}
                  color="primary"
                />
              }
              label={
                <Typography style={{ fontSize: "12px" }}>
                  Пользователь верифицирован
                </Typography>
              }
            />
          </Box>
        )}
        {userInfo ? (
          <Grid className={classes.gridItems}>
            <Grid item className={classes.gridItem} xs={6}>
              <Typography variant="body2" component="span">
                ФИО
              </Typography>
            </Grid>
            <Grid item className={classes.gridItem} xs={6}>
              <Typography
                variant="body2"
                component="span"
                style={{ color: "#617E8C" }}
              >
                {userInfo.last_name} {userInfo.name} {userInfo.family_name}
              </Typography>
            </Grid>
            <Grid item className={classes.gridItem} xs={6}>
              <Typography variant="body2" component="span">
                ИНН
              </Typography>
            </Grid>
            <EditField
              fieldName="inn"
              onChange={handleChangeField}
              isEdit={false}
              inputValue={changedFields.inn}
              currentValue={userInfo.inn}
              error={changedFieldsErrors.inn}
            />
            <Grid item className={classes.gridItem} xs={6}>
              <Typography variant="body2" component="span">
                Электронная почта
              </Typography>
            </Grid>
            <Grid item className={classes.gridItem} xs={6}>
              <Typography
                variant="body2"
                component="span"
                style={{ color: "#617E8C" }}
              >
                {userInfo.email}
              </Typography>
            </Grid>
            <Grid item className={classes.gridItem} xs={6}>
              <Typography variant="body2" component="span">
                Номер для связи
              </Typography>
            </Grid>
            <Grid item className={classes.gridItem} xs={6}>
              <Typography
                variant="body2"
                component="span"
                style={{ color: "#617E8C" }}
              >
                {userInfo.phone}
              </Typography>
            </Grid>
            <Grid item className={classes.gridItem} xs={6}>
              <Typography variant="body2" component="span">
                Наименование компании
              </Typography>
            </Grid>
            <Grid item className={classes.gridItem} xs={6}>
              <Typography
                variant="body2"
                component="span"
                style={{ color: "#617E8C" }}
              >
                {userInfo.companyName}
              </Typography>
            </Grid>
            {agent && (
              <>
                <Grid item className={classes.gridItem} xs={6}>
                  <Typography variant="body2" component="span">
                    БИК
                  </Typography>
                </Grid>
                <Grid item className={classes.gridItem} xs={6}>
                  <EditField
                    fieldName={"bik"}
                    onChange={handleChangeField}
                    isEdit={true}
                    inputValue={changedFields.bik}
                    currentValue={changedFields.bik}
                    error={changedFieldsErrors.bik}
                  />
                </Grid>
                <Grid item className={classes.gridItem} xs={6}>
                  <Typography variant="body2" component="span">
                    Система налогооблажения
                  </Typography>
                </Grid>
                <Grid item className={classes.gridItem} xs={6}>
                  <EditField
                    fieldName={"tax_type"}
                    onChange={handleChangeField}
                    isEdit={true}
                    inputValue={changedFields.tax_type}
                    currentValue={changedFields.tax_type}
                    error={changedFieldsErrors.tax_type}
                  />
                </Grid>
                <Grid item className={classes.gridItem} xs={6}>
                  <Typography variant="body2" component="span">
                    р/c
                  </Typography>
                </Grid>
                <EditField
                  fieldName={"account"}
                  onChange={handleChangeField}
                  isEdit={true}
                  inputValue={changedFields.account}
                  currentValue={changedFields.account}
                  error={changedFieldsErrors.account}
                />
                <Grid item className={classes.gridItem} xs={6}>
                  <Typography variant="body2" component="span">
                    Фактический адрес
                  </Typography>
                </Grid>
                <Grid item className={classes.gridItem} xs={6}>
                  <EditField
                    fieldName={"fact_address"}
                    onChange={handleChangeField}
                    isEdit={true}
                    inputValue={changedFields.fact_address}
                    currentValue={changedFields.fact_address}
                    error={changedFieldsErrors.fact_address}
                  />
                </Grid>
                <Grid item className={classes.gridItem} xs={6}>
                  <Typography variant="body2" component="span">
                    Почтовый адрес
                  </Typography>
                </Grid>
                <Grid item className={classes.gridItem} xs={6}>
                  <EditField
                    fieldName={"post_address"}
                    onChange={handleChangeField}
                    isEdit={true}
                    inputValue={changedFields.post_address}
                    currentValue={changedFields.post_address}
                    error={changedFieldsErrors.post_address}
                  />
                </Grid>
                <Grid
                  item
                  className={clsx(classes.downloadFileTitle, classes.gridItem)}
                  xs={12}
                >
                  <Typography
                    variant="body2"
                    component="span"
                    className={classes.passportText}
                  >
                    Паспорта учредителей, владеющих в совокупности
                    долями/акциями, составляющими 25% и более уставного капитала
                    и единоличного исполнительного органа
                  </Typography>
                </Grid>
                <Grid item className={classes.gridItem} xs={12}>
                  <div className={classes.fileBox}>
                    <UploadFilesForm
                      isDisabled={isShowBtnSafe}
                      onClickFile={() =>
                        handleDownloadFile(userInfo.id, "passport")
                      }
                      file={userInfo.passport}
                      onChangeFile={EditFile.bind(null, "passport")}
                    />
                  </div>
                </Grid>
                <Grid
                  item
                  className={clsx(classes.downloadFileTitle, classes.gridItem)}
                  xs={12}
                >
                  <Typography variant="body2" component="span">
                    Протокол о назначении руководителя
                  </Typography>
                </Grid>
                <Grid item className={classes.gridItem} xs={12}>
                  <div className={classes.fileBox}>
                    <UploadFilesForm
                      isDisabled={isShowBtnSafe}
                      onClickFile={() =>
                        handleDownloadFile(userInfo.id, "protocol")
                      }
                      file={userInfo.protocol}
                      onChangeFile={EditFile.bind(null, "protocol")}
                    />
                  </div>
                </Grid>
              </>
            )}
          </Grid>
        ) : (
          ""
        )}
        {userInfo ? (
          <Button
            variant="text"
            color="primary"
            style={{ padding: "15px 0", display: "block" }}
            className={classes.btn}
            onClick={(event) => {
              event.stopPropagation();
              props.handleChatModalOpen(
                parseInt(props.currentChatId),
                userInfo.inn,
                userInfo.roles.role_name,
                userInfo.id
              );
            }}
          >
            <Typography
              variant="body1"
              component="span"
              style={{
                color: "inherit",
                textTransform: "none",
                lineHeight: "2",
              }}
            >
              Перейти в чат
            </Typography>
          </Button>
        ) : (
          ""
        )}
        {isShowBtnSafe && (
          <Button
            color="primary"
            variant="outlined"
            type="submit"
            onClick={handleClickSafe}
            style={{
              display: "block",
              marginTop: "15px",
              background: "#306F9A",
              color: "white",
              width: "300px",
              height: "51px",
            }}
          >
            <Typography
              component="span"
              variant="body1"
              style={{
                color: "inherit",
                textTransform: "none",
              }}
            >
              Сохранить изменения
            </Typography>
          </Button>
        )}

        <Divider className={classes.dividerAboveComments} />
        {userInfo && (
          <Comments
            id={userInfo.id}
            comments={userInfo.comments}
            updateComments={handleUpdateUserInfo}
          />
        )}
      </Box>
      {isOpenExtraModal && (
        <ConfirmModal
          handleConfirm={handleConfirm}
          title={"Подтвердите выход"}
          text={
            "В анкете, которую вы хотите покинуть, есть несохраненные данные. Вы хотите сохранить изменения перед выходом?"
          }
          handleCancel={() => setOpenExtraModal(false)}
          confBtnText={"Сохранить"}
          cancelBtnText="Вернуться к анкете"
          open={isOpenExtraModal}
          extraButtonText={"Не сохранять"}
          extraButtonClick={handleUnConfirm}
        />
      )}
    </Dialog>
  );
};

export default memo(ClientInfoModal);
