import managerApi from '../api/managerApi'
import { clientsAgentsListType, agentByInnType, leadsListType, actType, reqBodyDocUpload, ManagerDownloadDocArgs } from "../types/managerApiTypes"
import { QueryParamsGetLeads } from '../types/leadsApiTypes'

class ManagerService {
    getClietnsAgetnsListService(Q: {
        Inn?: string,
        Region?: string,
        CompanyName?: string,
        clientType?: string,
        bAgents?: boolean,
        bClients?: boolean,
        bAgentsClients?: boolean;
        count?: number;
        offset?: number;
    }): Promise<clientsAgentsListType> {
        return managerApi.getClietnsAgetnsList(Q)
    }

    getActsListService(): Promise<actType[]> {
        return managerApi.getActsList()
    }

    getAgentByInnService(inn: string): Promise<agentByInnType> {
        return managerApi.getAgentByInn(inn)
    }

    getLeadsListByProductIdService(Q: QueryParamsGetLeads): Promise<leadsListType> {
        return managerApi.getLeadsListByProductId(Q)
    }

    signActByManagerService(B: { actsids: number[] }): Promise<string> {
        return managerApi.signActByManager(B)
    }

    downloadDocument(args: ManagerDownloadDocArgs) {
        return managerApi.downloadDocument(args)
    }
    downloadDocumentAdmin(id:string) {
        return managerApi.downloadDocumentAdmin(id)
    }
    deleteDocument(args: ManagerDownloadDocArgs) {
        return managerApi.deleteDocument(args)
    }
    putDocument(args: ManagerDownloadDocArgs, file:File) {
        return managerApi.putDocument(args, file)
    }
    postDocument(args: ManagerDownloadDocArgs, file:File) {
        return managerApi.postDocument(args, file)
    }
    getStatisticService(info:any) {
        return managerApi.getStatisticData(info)
    }
    uploadAgentActByManagerService(data: reqBodyDocUpload, Act: File): Promise<string> {
        const actData = { ...data }
        return managerApi.uploadAgentActByManager(actData, Act)
    }

}

const managerService = new ManagerService()
export default managerService