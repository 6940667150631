import {
  CircularProgress,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import { useFormik } from "formik";
import React, { memo, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { regFio } from "../../../app/components/registration/consts";
import { FormType } from "../../../app/enum";
import { ConfirmInnRDType } from "../../../core/api/authApi";
import { ROLES, ROUTES } from "../../../core/consts/common";
import {
  COLORS,
  RegisterFormCommon,
} from "../../../core/consts/redesign/commonRedesign";
import { detectDevice } from "../../../core/helpers/detectDevice";
import authService from "../../../core/services/AuthService";
import { useAppThunkDispatch } from "../../../core/store";
import { setIsOpenInnModal } from "../../../core/store/action-creators";
import * as selectors from "../../../core/store/selectors";
import styles from "../LoginNew.module.scss";

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      inputBox: {
        display: "flex",
        flexDirection: "column",
        marginTop: "25px",
        maxWidth: "100%",
      },
      iconCheckRadio: {
        "& svg": {
          fontSize: "24px",
        },
      },
      submit: {
        marginTop: "35px",
        minWidth: "300px",
        width: "70%",
        [theme.breakpoints.down(400)]: {
          maxWidth: "100%",
        },
      },
      textField: {
        minWidth: "300px",
        width: "100%",
        height: "51px",
        [theme.breakpoints.down(400)]: {
          maxWidth: "100%",
        },
      },
      offerta: {
        color: "#306F9A",
        "&:hover": {
          cursor: "pointer",
          textDecoration: "underline",
        },
      },
      offertaWrap: {
        width: "250px",
        display: "block",
        color: "#131313",
        fontSize: "12px",
        fontFamily: "Gilroy",
        fontWeight: 500,
        [theme.breakpoints.down(400)]: {
          maxWidth: "calc(100% - 23px)",
        },
      },
      error: {
        marginTop: "15px",
        color: "red",
      },
      text: {
        padding: "0 0 0px 0",
        margin: "0",
        boxSizing: "border-box",
        maxWidth: "500px",
      },
      box: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "baseline",
        width: "100%",
      },
      title: {
        padding: "38px 30px ",
      },
    }),
  { index: 1 }
);

type BadRequestType = {
  lastName: string;
  firstName: string;
  middleName: string;
  email: string;
  inn: string;
};

type FormTypes = {
  lastName: string;
  name: string;
  familyName: string;
  email: string;
  inn: string;
};

type PropsType = {
  handleCloseCancel?: () => void;
  // setIsCodeMail: React.Dispatch<React.SetStateAction<boolean>>;
  // setEmailForConfirm: React.Dispatch<React.SetStateAction<string>>;
};

const AddInnForm: React.FC<PropsType> = ({
  handleCloseCancel,
}: // setIsCodeMail,
  // setEmailForConfirm,
  PropsType) => {
  const classes = useStyles();
  const initialUser = useSelector(selectors.initialUser);
  const code = useSelector(selectors.code);
  const dispatch = useAppThunkDispatch();
  const history = useHistory();

  const [message, setMessage] = useState<string>("");
  const [badRequestFields, setBadRequestFields] = useState<BadRequestType>({
    lastName: "",
    firstName: "",
    middleName: "",
    email: "",
    inn: "",
  });
  const [isFetching, setIsFething] = useState(false);
  const [errMessage, setErrMessage] = useState<string>("");
  const [isUlString, setIsUlString] = useState("физ");

  const formik = useFormik({
    initialValues: {
      inn: "",
      lastName: "",
      name: "",
      familyName: "",
      email: "",
    },
    validationSchema: Yup.object({
      lastName: Yup.string()
        .matches(regFio, "Проверьте наличие фамилии")
        .required("Обязательное поле"),
      name: Yup.string()
        .matches(regFio, "Проверьте наличие имени")
        .required("Обязательное поле"),
      email: Yup.string()
        .email("Не верный email")
        .required("Обязательное поле"),
    }),
    onSubmit: async (values) => {
      const rd = {
        inn: values.inn.trim(),
        lastName: values.lastName.trim(),
        name: values.name.trim(),
        familyName: values.familyName.trim(),
        email: values.email,
      };
      setMessage("");
      setErrMessage("");
      // setEmailForConfirm(values.email);
      await handleSubmitAddInn(rd, setBadRequestFields);
    },
  });

  const isDisableBtn = () => {
    if (
      formik.values.lastName.trim() &&
      formik.values.name.trim() &&
      formik.values.email.trim() &&
      formik.values.inn.trim()
    ) {
      return false;
    } else return true;
  };

  const handleChoiceRoutes = (role: string) => {
    if (detectDevice() === "desk") {
      switch (role) {
        case ROLES.CLIENT:
          history.push(`${ROUTES.CLIENT}/products`);
          break;
        case ROLES.AGENT:
          history.push(`${ROUTES.AGENT}/products`);
          break;
        case ROLES.PARTNER:
          history.push(`${ROUTES.PARTNER}/profile`);
          break;
        case ROLES.MANAGER:
          history.push(`${ROUTES.MANAGER}/profile`);
          break;
        case ROLES.ADMIN:
          history.push(`${ROUTES.ADMIN}/profile`);
          break;
        default: {
          // history.push({
          //   pathname: history.location.pathname,
          //   // search: `?formType=${FormType.LoginInn}`,
          //   search: `?formType=${FormType.LoginClientOrAgent}`,
          // });
        }
      }
    } else {
      history.push({
        pathname: history.location.pathname,
        search: `?formType=${FormType.LoginBlockMob}`,
      });
    }
  };

  const handleSubmitAddInn = async (
    data: FormTypes,
    setBadRequestFields: (
      data: BadRequestType | ((data: BadRequestType) => BadRequestType)
    ) => void
  ) => {
    setIsFething(true);
    let codeTemp = code;
    if (!codeTemp) {
      try {
        const codeFromStorage = localStorage.getItem("code");
        if (codeFromStorage) {
          codeTemp = codeFromStorage;
        }
      } catch (e) {
        console.log("error");
      }
    }
    const rd: ConfirmInnRDType = {
      ...data,
      userId: initialUser.userId,
      isPhoneCheck: true,
      code: codeTemp,
    };
    if (initialUser.role === ROLES.AGENT) {
      rd.agentType =
        isUlString === "физ"
          ? "fl"
          : isUlString === "ип"
            ? "ip"
            : isUlString === "юр"
              ? "ul"
              : "";
    }
    try {
      await authService.confirmInn(rd);

      // setIsCodeMail(true);
      //  authApi.checkPhoneCode(false, undefined, data.email.trim());
      dispatch(setIsOpenInnModal(false));
    } catch (err: any) {
      if (err.request.status === 500) {
        setErrMessage("Ошибка на сервере.");
      } else if (err.request.status === 429) {
        setErrMessage("Попробуйте через 10 секунд");
      } else if (err.request.status === 400) {
        if (JSON.parse(err.request.response).errors.length > 0) {
          setErrMessage("");
          JSON.parse(err.request.response).errors.forEach((el: any) => {
            console.log(el);
            setBadRequestFields((state: BadRequestType) => ({
              ...state,
              [el.field]: el.message,
            }));
          });
        } else {
          setErrMessage("Ошибка.");
        }
      } else {
        setErrMessage("Ошибка.");
      }
    } finally {
      setIsFething(false);
    }
  };

  return (
    <form onSubmit={formik.handleSubmit} className={styles.main_column}>
      <div>Для продолжения заполните данные о себе</div>

      <>
        {initialUser.role === ROLES.AGENT && (
          <div>
            <Typography
              variant="body2"
              component="p"
              style={{ fontSize: "12px" }}
            >
              Вы являетесь...
            </Typography>
            <FormControl component="fieldset">
              <RadioGroup
                onChange={(event) => {
                  setIsUlString(event.target.value);
                }}
                value={isUlString}
                name="isUl"
              >
                <FormControlLabel
                  value="физ"
                  control={
                    <Radio color="primary" className={classes.iconCheckRadio} />
                  }
                  style={{ marginRight: "0" }}
                  label={<Typography variant="body2">Физ. лицом</Typography>}
                />
                <FormControlLabel
                  value="ип"
                  control={
                    <Radio color="primary" className={classes.iconCheckRadio} />
                  }
                  style={{ marginRight: "0" }}
                  label={
                    <Typography variant="body2">
                      Индивидуальным предпринимателем
                    </Typography>
                  }
                />
                <FormControlLabel
                  value="юр"
                  control={
                    <Radio color="primary" className={classes.iconCheckRadio} />
                  }
                  style={{ marginRight: "0" }}
                  label={<Typography variant="body2">Юр.лицом</Typography>}
                />
              </RadioGroup>
            </FormControl>
          </div>
        )}
        <TextField
          id="lastName"
          label={
            <div style={{ color: COLORS.gray }}>
              {RegisterFormCommon.lastName}
            </div>
          }
          className={classes.textField}
          value={formik.values.lastName.trim()}
          error={formik.touched.lastName && Boolean(formik.errors.lastName)}
          helperText={formik.touched.lastName && formik.errors.lastName}
          onChange={formik.handleChange}
        />
        <TextField
          id="name"
          label={
            <div style={{ color: COLORS.gray }}>
              {RegisterFormCommon.firstName}
            </div>
          }
          className={classes.textField}
          value={formik.values.name.trim()}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
          onChange={formik.handleChange}
        />
        <TextField
          id="familyName"
          label={
            <div style={{ color: COLORS.gray }}>
              {RegisterFormCommon.patrName}
            </div>
          }
          className={classes.textField}
          value={formik.values.familyName.trim()}
          onChange={formik.handleChange}
        />
        <TextField
          id="inn"
          label={
            <div style={{ color: COLORS.gray }}>
              {RegisterFormCommon.innOrganisation}
            </div>
          }
          className={classes.textField}
          value={formik.values.inn.trim()}
          onChange={formik.handleChange}
          error={
            (formik.touched.inn && Boolean(formik.errors.inn)) ||
            !!badRequestFields.inn
          }
          helperText={
            (formik.touched.inn && formik.errors.inn) || badRequestFields.inn
          }
        />
        <TextField
          id="email"
          label={
            <div style={{ color: COLORS.gray }}>{RegisterFormCommon.email}</div>
          }
          className={classes.textField}
          value={formik.values.email}
          onChange={formik.handleChange}
          error={
            (formik.touched.email && Boolean(formik.errors.email)) ||
            !!badRequestFields.email
          }
          helperText={
            (formik.touched.email && formik.errors.email) ||
            badRequestFields.email
          }
        />
        {errMessage && <div className="error">{errMessage}</div>}
        <div className="error">{message}</div>
        {isFetching ? (
          <div
            style={{
              marginTop: "35px",
              display: "flex",
              justifyContent: "center",
              width: "300px",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <button
            color="primary"
            type="submit"
            disabled={isDisableBtn()}
            className="button_primary_w100"
          >
            Сохранить
          </button>
        )}
      </>
    </form>
  );
};

export default memo(AddInnForm);
