import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import Preloader from "../../../../../../app/components/preloader/Preloader";
import { useAppSelector } from "../../../../../../core/store";
import * as selectors from "../../../../../../core/store/selectors";
import {
  setModalValueFieldOnChange
} from "../../../../../../core/store/thunk/newOrder";
import { stringParser } from "../../../../../../core/utils/queryParsers";
import ModalFieldBody from "./ModalFieldBody";


export type TabsData = {
  tabs: string[];
  tabsFields: Record<string, string[]>;
};

type PropsType = {
  modal: string;
  modalIndex?: number;
  propsalId: number;
  propsalIdsReal?: number[];
  isMultiple: boolean;
};

const ModalField: React.FC<PropsType> = ({
  modal,
  modalIndex,
  propsalId,
  propsalIdsReal,
  isMultiple
}: PropsType) => {
  const queryParsers = {
    modal: stringParser<string>(),
    index: stringParser<string>(),
  };
  const dispatch = useDispatch();
  const [open, setOpen] = useState<boolean>(false);
  const [tabsData, setTabsData] = useState<TabsData>();
  // const { modal, index: modalIndex } = useQuery(queryParsers);
  const history = useHistory();
  const [openConfModal, setOpenConfModal] = useState(false);
  const modalPropsalId = useSelector(selectors.modalPropsalId);
  const modalErrors = useSelector(selectors.currentEditModalErrors);
  const modalField = useSelector(selectors.modalField);
  const [tab, setTab] = useState<string>("");
  const [tabsError, setTabsError] = useState<string[]>([]);
  const [showPreloader, setShowPreloader] = useState<boolean>(false);
  const { currentModalData } = useAppSelector((state) => state.newOrder);

  const handleClose = () => {
    setOpenConfModal(true);
  };

  const handleCloseConfirm = () => {
    dispatch(setModalValueFieldOnChange());
    // setOpen(false);
    // setOpenConfModal(false);
    // history.push({
    //   pathname: history.location.pathname,
    //   search: ``,
    // });
  };

  // useEffect(()=>{
  //   console.log("ModalField modalField", modalField)
  //   console.log("ModalField modal", modal)
  //   console.log("ModalField modalPropsalId", modalPropsalId)
  // })

  // useEffect(() => {
  //   console.log("modalfield new modal", modal);
  // }, [modal]);

  // useEffect(() => {
  //   console.log("modalfield new modal", modal);
  //   console.log("modalfield new modalIndex", modalIndex);
  //   if (modal) {
  //     if (modalIndex) {
  //       modalField &&
  //         actions.setCurrentModalData({
  //           propsalId: propsalId,
  //           modalName: modal,
  //           index: modalIndex,
  //           isMultiple: modalField?.isMultiple,
  //         });
  //     }

  //   }
  //   return () => {
  //     // dispatch(getDataForBigForm());
  //     actions.setCurrentEditModal({ values: undefined, errors: undefined });
  //   };
  //   // }, [modal, modalIndex]);
  // }, []);

  // useEffect(() => {
  // if (currentModalData) {
  //   if (modalIndex) {
  //     dispatch(setCurrentEditModalValues(modal, Number(modalIndex)));
  //   } else {
  //     dispatch(setCurrentEditModalValues(modal, undefined));
  //   }
  // }
  // return () => {
  //   actions.setCurrentEditModal({ values: undefined, errors: undefined });
  // };
  // }, [currentModalData]);
  // }, []);

  useEffect(() => {
    if (modalField) {
      const fields = modalField.childs;
      let tabsObj: Record<string, string[]> = {};
      for (let i = 0; i < fields.length; i++) {
        const field = fields[i];
        if (field.category) {
          if (!tabsObj.hasOwnProperty(field.category)) {
            tabsObj[field.category] = [];
          }
          tabsObj[field.category] = [...tabsObj[field.category], field.name];
        }
      }
      setTabsData({
        tabs: Object.keys(tabsObj),
        tabsFields: tabsObj,
      });
      setTab(Object.keys(tabsObj)[0]);
    }
  }, [modalField]);

  useEffect(() => {
    if (modalField) {
      const fields = modalField.childs;
      const tabsErr = new Set<string>();
      for (let i = 0; i < fields.length; i++) {
        const field = fields[i];
        if (field.category) {
          if (
            modalErrors &&
            modalErrors?.[modalIndex ?? 0]?.[field.name]?.length > 0
          ) {
            tabsErr.add(field.category);
          }
        }
      }
      setTabsError(Array.from(tabsErr));
    }
  }, [modalField, modalErrors]);

  // useEffect(() => {
  //   if (modalField && modal === modalField.name) {
  //     setOpen(true);
  //   }
  // }, [modal, modalField]);

  // const ref = useRef<null | any>(null);

  // useEffect(() => {
  //   ref.current?.scrollTo(0, 0);
  // }, [tab]);

  // const handleDeleteAccordion = () => {
  //   if (modalIndex !== undefined && modalPropsalId) {
  //     actions.deleteMultipleModalAccordion({
  //       // propsalId,
  //       propsalId: modalPropsalId,
  //       // fieldName: field.name,
  //       fieldName: modal ?? modalField?.name,
  //       index: modalIndex,
  //     });
  //   } else {
  //     modalPropsalId &&
  //       actions.deleteModalAccordion({
  //         // propsalId,
  //         propsalId: modalPropsalId,
  //         // fieldName: field.name,
  //         fieldName: modal ?? modalField?.name,
  //       });
  //   }
  // };
  // const handleDeleteModalByIndex = () => {
  //   if (modalIndex !== undefined && modalPropsalId) {
  //     actions.deleteMultipleModalAccordion({
  //       // propsalId,
  //       propsalId: modalPropsalId,
  //       // fieldName: field.name,
  //       fieldName: modal ?? modalField?.name,
  //       index: modalIndex,
  //     });
  //   }
  // };

  return (
    <>
      {/* {modalField && (
        <Dialog
          open={open}
          fullWidth={true}
          maxWidth="md"
          PaperProps={{ ref: ref }}
          onClose={handleClose}
        >
          {modal && modalPropsalId && (
            <div style={{ zIndex: 10000 }} className={classes.register}>
              <Box
                sx={{ bgcolor: "background.paper" }}
                style={{ position: "relative" }}
              >
                <Typography
                  style={{ width: "100%" }}
                  variant="h4"
                  component="h4"
                >
                  {modalField.text}
                </Typography>
                <ModalFieldBody
                  modalName={modal}
                  modalIndex={modalIndex}
                  propsalId={modalPropsalId}
                  modalField={modalField}
                  tabsData={tabsData}
                  tab={tab}
                  tabsError={tabsError}
                  setTab={setTab}
                  setOpen={setOpen}
                  setShowPreloader={setShowPreloader}
                />
                {showPreloader && <Preloader preloaderType={"absolute"} />}
              </Box>
            </div>
          )}
        </Dialog>
      )} */}
      {modalField && modal && modalPropsalId && (
        <>
          <ModalFieldBody
            modalName={modal}
            modalIndex={modalIndex}
            propsalId={modalPropsalId}
            modalField={modalField}
            tabsData={tabsData}
            tab={tab}
            tabsError={tabsError}
            setTab={setTab}
            setOpen={setOpen}
            setShowPreloader={setShowPreloader}
            key={`modalfield_${modalIndex}`}
            propsalIdsReal={propsalIdsReal}
            isMultiple={isMultiple}
          />
          {/* 
          {modalField?.isMultiple && (
            <>
              <div className={`blue_link ${classes.end}`} onClick={handleDeleteModalByIndex}>удалить</div>
            </>
          )} */}

          {showPreloader && <Preloader preloaderType={"absolute"} />}
        </>
      )}

      {/* <ConfirmModal
        title="Вы уверены, что хотите выйти?"
        text="Введеные данные не сохранятся"
        handleCancel={() => setOpenConfModal(false)}
        isKros={true}
        cancelBtnText="Остаться"
        confBtnText="Выйти"
        handleConfirm={handleCloseConfirm}
        open={openConfModal}
      /> */}
    </>
  );
};

export default React.memo(ModalField);
