import {
  Drawer,
  IconButton,
  List,
  Typography
} from "@material-ui/core";
import clsx from "clsx";
import React, { memo, useState } from "react";
import { useSelector } from "react-redux";
import { matchPath, useLocation } from "react-router-dom";
import { ROLES, ROUTES } from "../../../core/consts/common";
import { COLORS, DevStand, DevStandApp, ProdStand, roleTranslations } from "../../../core/consts/redesign/commonRedesign";
import { ArrowLeft, ArrowRight, Article, Bank, Briefcase, Clients, Docs, DotsHorizontal, Headphones, Incognito, News, Products, Profile, Statistic } from "../../../core/consts/redesign/icons";
import { LogoTextIcon } from "../../../core/consts/redesign/icons/LogoText";
import useIsMobile from "../../../core/hooks/useIsMobile";
import { rootState } from "../../../core/types/rootState";
import { MenuItemForPhone } from "./components/menu-item-for-phone/MenuItemForPhone";
import { MenuItemWithTooltip } from "./components/menu-item-with-tooltip/MenuItemWithTooltip";
import { useStylesSideMenu } from "./useStylesSideMenu";

const roleLinks = {
  [ROLES.CLIENT]: [
    { title: 'Заявки', url: `${ROUTES.CLIENT}${ROUTES.PRODUCTS}`, icon: <Briefcase /> },
    { title: 'Подбор предложений', url: `${ROUTES.CLIENT}${ROUTES.NEW_ORDER_FORM}`, icon: <Bank /> },
    // { title: 'База знаний', url: `${ROUTES.CLIENT}${ROUTES.KNOWLEDGE}`, icon: <Book /> },
    { title: 'Проверка контрагента', url: `${ROUTES.CLIENT}${ROUTES.SCORING}`, icon: <Incognito /> },
    { title: 'Поддержка', url: `${ROUTES.CLIENT}${ROUTES.SUPPORT}`, icon: <Headphones /> },
    { title: 'Ваш аккаунт', url: `${ROUTES.CLIENT}${ROUTES.PROFILE}`, icon: <Profile /> },
  ],
  [ROLES.AGENT]: [
    { title: 'Заявки', url: `${ROUTES.AGENT}${ROUTES.PRODUCTS}`, icon: <Briefcase /> },
    { title: 'Подбор предложений', url: `${ROUTES.AGENT}${ROUTES.NEW_ORDER_FORM}`, icon: <Bank /> },
    { title: 'Клиенты', url: `${ROUTES.AGENT}${ROUTES.CLIENTS}`, icon: <Clients /> },
    { title: 'Расчеты/Акты', url: `${ROUTES.AGENT}${ROUTES.ACTS}`, icon: <Docs /> },
    // { title: 'Проверка контрагента', url: `${ROUTES.AGENT}${ROUTES.SCORING}`, icon: <Incognito /> },
    // { title: 'База знаний', url: `${ROUTES.AGENT}${ROUTES.KNOWLEDGE}`, icon: <Book /> },
    { title: 'Поддержка', url: `${ROUTES.AGENT}${ROUTES.SUPPORT}`, icon: <Headphones /> },
    { title: 'Ваш аккаунт', url: `${ROUTES.AGENT}${ROUTES.PROFILE}`, icon: <Profile /> },
  ],
  [ROLES.MANAGER]: [
    { title: 'Заявки', url: `${ROUTES.MANAGER}${ROUTES.PRODUCTS}`, icon: <Briefcase /> },
    // { title: 'Подбор предложений', url: `${ROUTES.MANAGER}${ROUTES.NEW_ORDER_FORM}`, icon: <Bank /> }, //TODO: check. manager does not have this page
    { title: 'Пользователи', url: `${ROUTES.MANAGER}${ROUTES.CLIENTS}`, icon: <Clients /> },
    { title: 'Расчеты/Акты', url: `${ROUTES.MANAGER}${ROUTES.ACTS}`, icon: <Docs /> },
    { title: 'Поддержка', url: `${ROUTES.MANAGER}${ROUTES.SUPPORT}`, icon: <Headphones /> },
    { title: 'Ваш аккаунт', url: `${ROUTES.MANAGER}${ROUTES.PROFILE}`, icon: <Profile /> },
  ],
  [ROLES.PARTNER]: [
    { title: 'Заявки', url: `${ROUTES.PARTNER}${ROUTES.PARTNERS_APPLICATIONS}`, icon: <Briefcase /> },
    { title: 'Поддержка', url: `${ROUTES.PARTNER}${ROUTES.SUPPORT}`, icon: <Headphones /> },
    { title: 'Ваш аккаунт', url: `${ROUTES.PARTNER}${ROUTES.PROFILE}`, icon: <Profile /> },
  ],
  [ROLES.ADMIN]: [ // make sure to change index order if edit this list
    { title: 'Заявки', url: `${ROUTES.ADMIN}${ROUTES.PRODUCTS}`, icon: <Briefcase /> },
    // { title: 'Подбор предложений', url: `${ROUTES.ADMIN}${ROUTES.NEW_ORDER_FORM}`, icon: <Bank /> },
    { title: 'Клиенты', url: `${ROUTES.ADMIN}${ROUTES.CLIENTS}`, icon: <Clients /> },
    { title: 'Предложения', url: `${ROUTES.ADMIN}${ROUTES.ADMIN_EDIT_PRODUCTS}`, icon: <Products /> },
    { title: 'Новости', url: `${ROUTES.ADMIN}${ROUTES.ADMIN_ALL_NEWS}`, icon: <News /> },
    { title: 'Статьи', url: `${ROUTES.ADMIN}${ROUTES.ADMIN_ALL_ARTICLES}`, icon: <Article /> },
    { title: 'Статистика', url: `${ROUTES.ADMIN}${ROUTES.ADMIN_STATISTIC}`, icon: <Statistic /> },
    // { title: 'Инструменты', url: `${ROUTES.PARTNER}${ROUTES.SUPPORT}`, icon: <Tools /> }, //TODO: add link here
    // { title: 'Поддержка', url: `${ROUTES.PARTNER}${ROUTES.SUPPORT}`, icon: <Headphones /> },
    { title: 'Ваш аккаунт', url: `${ROUTES.ADMIN}${ROUTES.PROFILE}`, icon: <Profile /> },
  ],
};

const roleLinksMobile = {
  [ROLES.CLIENT]: [
    { title: 'Заявки', url: `${ROUTES.CLIENT}${ROUTES.PRODUCTS}`, icon: <Briefcase /> },
    { title: 'Подбор предложений', url: `${ROUTES.CLIENT}${ROUTES.NEW_ORDER_FORM}`, icon: <Bank /> },
    // { title: 'База знаний', url: `${ROUTES.CLIENT}${ROUTES.KNOWLEDGE}`, icon: <Book /> },
    // { title: 'Проверка контрагента', url: `${ROUTES.CLIENT}${ROUTES.SCORING}`, icon: <Incognito /> },
    // { title: 'Поддержка', url: `${ROUTES.CLIENT}${ROUTES.SUPPORT}`, icon: <Headphones /> },
    { title: 'Ваш аккаунт', url: `${ROUTES.CLIENT}${ROUTES.PROFILE}`, icon: <Profile /> },
  ],
  [ROLES.AGENT]: [
    { title: 'Заявки', url: `${ROUTES.AGENT}${ROUTES.PRODUCTS}`, icon: <Briefcase /> },
    { title: 'Подбор предложений', url: `${ROUTES.AGENT}${ROUTES.NEW_ORDER_FORM}`, icon: <Bank /> },
    // { title: 'Клиенты', url: `${ROUTES.AGENT}${ROUTES.CLIENTS}`, icon: <Clients /> },
    // { title: 'Расчеты/Акты', url: `${ROUTES.AGENT}${ROUTES.ACTS}`, icon: <Docs /> },
    // { title: 'Проверка контрагента', url: `${ROUTES.AGENT}${ROUTES.SCORING}`, icon: <Incognito /> },
    // { title: 'База знаний', url: `${ROUTES.AGENT}${ROUTES.KNOWLEDGE}`, icon: <Book /> },
    // { title: 'Поддержка', url: `${ROUTES.AGENT}${ROUTES.SUPPORT}`, icon: <Headphones /> },
    { title: 'Ваш аккаунт', url: `${ROUTES.AGENT}${ROUTES.PROFILE}`, icon: <Profile /> },
  ],
  [ROLES.MANAGER]: [
    { title: 'Заявки', url: `${ROUTES.MANAGER}${ROUTES.PRODUCTS}`, icon: <Briefcase /> },
    // { title: 'Подбор предложений', url: `${ROUTES.MANAGER}${ROUTES.NEW_ORDER_FORM}`, icon: <Bank /> }, //TODO: check. manager does not have this page
    { title: 'Пользователи', url: `${ROUTES.MANAGER}${ROUTES.CLIENTS}`, icon: <Clients /> },
    { title: 'Расчеты/Акты', url: `${ROUTES.MANAGER}${ROUTES.ACTS}`, icon: <Docs /> },
    { title: 'Поддержка', url: `${ROUTES.MANAGER}${ROUTES.SUPPORT}`, icon: <Headphones /> },
    { title: 'Ваш аккаунт', url: `${ROUTES.MANAGER}${ROUTES.PROFILE}`, icon: <Profile /> },
  ],
  [ROLES.PARTNER]: [
    { title: 'Заявки', url: `${ROUTES.PARTNER}${ROUTES.PARTNERS_APPLICATIONS}`, icon: <Briefcase /> },
    { title: 'Поддержка', url: `${ROUTES.PARTNER}${ROUTES.SUPPORT}`, icon: <Headphones /> },
    { title: 'Ваш аккаунт', url: `${ROUTES.PARTNER}${ROUTES.PROFILE}`, icon: <Profile /> },
  ],
  [ROLES.ADMIN]: [ // make sure to change index order if edit this list
    { title: 'Заявки', url: `${ROUTES.ADMIN}${ROUTES.PRODUCTS}`, icon: <Briefcase /> },
    // { title: 'Подбор предложений', url: `${ROUTES.ADMIN}${ROUTES.NEW_ORDER_FORM}`, icon: <Bank /> },
    { title: 'Клиенты', url: `${ROUTES.ADMIN}${ROUTES.CLIENTS}`, icon: <Clients /> },
    { title: 'Предложения', url: `${ROUTES.ADMIN}${ROUTES.ADMIN_EDIT_PRODUCTS}`, icon: <Products /> },
    { title: 'Новости', url: `${ROUTES.ADMIN}${ROUTES.ADMIN_ALL_NEWS}`, icon: <News /> },
    { title: 'Статьи', url: `${ROUTES.ADMIN}${ROUTES.ADMIN_ALL_ARTICLES}`, icon: <Article /> },
    { title: 'Статистика', url: `${ROUTES.ADMIN}${ROUTES.ADMIN_STATISTIC}`, icon: <Statistic /> },
    // { title: 'Инструменты', url: `${ROUTES.PARTNER}${ROUTES.SUPPORT}`, icon: <Tools /> }, //TODO: add link here
    // { title: 'Поддержка', url: `${ROUTES.PARTNER}${ROUTES.SUPPORT}`, icon: <Headphones /> },
    { title: 'Ваш аккаунт', url: `${ROUTES.ADMIN}${ROUTES.PROFILE}`, icon: <Profile /> },
  ],
};

const SideMenu: React.FC = () => {
  const initialUser = useSelector((state: rootState) => state.currentUser);
  const role = initialUser.role.toLocaleLowerCase();
  const userRole = roleTranslations[role];
  const isAdmin = initialUser.role === ROLES.ADMIN;
  const [open, setOpen] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const classes = useStylesSideMenu();
  const isMobile = useIsMobile();
  const location = useLocation();


  //index admins links
  const adminLinksToShow = [2, 3, 4, 1];
  const adminLinksToShowMore = [6, 5, 0];
  const handleDrawerClose = () => {
    setOpen(!open);
  };

  const handleShowMore = () => {
    setShowMore(!showMore);
  }

  const checkIsActive = (location: any, title: string, url: string) => {
    if (title === 'Заявки') {
      const pathsToCheck = [ROUTES.PRODUCTS, ROUTES.PRODUCT, ROUTES.ARCHIVE];

      const isMatchingPath = pathsToCheck.some((path) => {
        const fullPath = `${userRole}${path}`;
        return location.pathname.includes(path) || location.pathname.includes(fullPath);
      });

      if (isMatchingPath) {
        return true;
      }

      return matchPath(location.pathname, { path: url }) !== null;
    }
    return false
  };

  // const isActive = checkIsActive(location);

  return (
    <>
      {isMobile ?
        (
          <div className={clsx(classes.bottomBar, {
            [classes.bottomBarHeight]: showMore,
            [classes.bottomBar]: !showMore
          })}>
            {/* admin menu */}
            {isAdmin ? (
              roleLinksMobile[ROLES.ADMIN].length && (
                <>
                  {/* expanded bottom menu */}
                  {showMore ? (
                    <List className={clsx(classes.mobMenu, classes.mobMenuBorder)}>
                      {adminLinksToShowMore.map((index) => {
                        const link = roleLinksMobile[ROLES.ADMIN][index];
                        const isActive = checkIsActive(location, link.title, link.url);
                        return (
                          <MenuItemForPhone
                            key={index + 3}
                            title={link.title}
                            url={link.url}
                            isProductActive={isActive}
                          >
                            {link.icon}
                          </MenuItemForPhone>
                        );
                      })}
                    </List>
                  ) : null}
                  {/* main bottom admin's links */}
                  <List className={classes.mobMenu}>
                    {adminLinksToShow.map((index) => {
                      const link = roleLinksMobile[ROLES.ADMIN][index];
                      const isActive = checkIsActive(location, link.title, link.url);
                      return (
                        <MenuItemForPhone
                          key={index + 3}
                          title={link.title}
                          url={link.url}
                          isProductActive={isActive}
                        >
                          {link.icon}
                        </MenuItemForPhone>
                      );
                    })}
                    <MenuItemForPhone
                      onClick={handleShowMore}>
                      <DotsHorizontal />
                    </MenuItemForPhone>
                  </List>
                </>
              )
            ) : (
              // other roles
              roleLinks[initialUser.role] && (
                <List className={classes.mobMenu}>
                  {roleLinksMobile[initialUser.role].map((link, index) => {
                    const isActive = checkIsActive(location, link.title, link.url);
                    return (
                      <MenuItemForPhone
                        key={index}
                        title={link.title}
                        url={link.url}
                        isProductActive={isActive}
                      >
                        {link.icon}
                      </MenuItemForPhone>
                    )
                  }
                  )}
                </List>
              )
            )}

          </div>
        ) : (
          <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
              [classes.drawerOpen]: open,
              [classes.drawer]: !open,
            })}
            classes={{
              paper: clsx({
                [classes.drawerOpen]: open,
                [classes.drawer]: !open,
              }),
            }}
            onMouseLeave={() => {
              setOpen(false);
            }}
          >
            <div className={classes.logoBox}>
              <a
                href={
                  window.location.href.includes(DevStandApp) ||
                    window.location.href.includes("localhost") ? DevStand : ProdStand
                }>
                <LogoTextIcon />
              </a>
            </div>
            {roleLinks[initialUser.role] && (
              <List className={classes.linksBox}>
                {roleLinks[initialUser.role].map((link, index) => {
                  const isActive = checkIsActive(location, link.title, link.url);
                  return (
                    <MenuItemWithTooltip
                      key={index}
                      title={link.title}
                      open={open}
                      url={link.url || ''}
                      isProductActive={isActive}
                    >
                      {link.icon}
                    </MenuItemWithTooltip>
                  )
                })}
              </List>
            )}
            <div className={classes.toolbar}>
              <IconButton className={classes.bottomArrow} onClick={handleDrawerClose}>
                {open ? (
                  <Typography className={classes.hiddenText}>
                    <div className={classes.icon} >
                      <ArrowLeft color={COLORS.white} />
                    </div>
                    Свернуть меню
                  </Typography>
                ) : (
                  <ArrowRight color={COLORS.white} />
                )}
              </IconButton>
            </div>
          </Drawer>
        )}
    </>
  );
};

export default memo(SideMenu);
