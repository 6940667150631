import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapBox: {
      display: "flex",
      flexDirection: "column",
    },
    boxSender: {
      padding: "20px 10px",
      display: "flex",
      alignItems: "center",
    },
    boxSupportSender: {
      padding: "20px 30px",
    },
    boxSenderActive: {
      backgroundColor: "#F2F2F2",
    },
    messageText: {
      maxWidth: "80%",
      padding: "15px 80px 15px 15px",
      position: "relative",
      // wordWrap: "anywhere",
      overflowWrap: "anywhere",
      color: "#151515",
      whiteSpace: "pre-line",
      "& span": {
        fontSize: "10px",
        position: "absolute",
        right: "5px",
        bottom: "5%",
      },
    },
    myMessageText: {
      borderRadius: "5px",
      backgroundColor: "#F2F2F2",
    },
    fromMessageText: {
      borderRadius: "5px",
      backgroundColor: "#ffffff",
      border: "1px solid #D0D9DE",
    },
    myMessageDoc: {
      borderRadius: "5px",
      backgroundColor: "#F2F2F2",
    },
    fromMessageDoc: {
      borderRadius: "5px",
      backgroundColor: "#ffffff",
      border: "1px solid #D0D9DE",
    },
    messagesBox: {
      flex: "auto",
      overflow: "auto",
      display: "flex",
      flexDirection: "column",
      scrollbarWidth: "thin",
      "& >div:first-child": {
        marginTop: "auto !important",
      },
      "&::-webkit-scrollbar": {
        width: "0.2em",
        height: "0.52em",
      },
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(208 217 222 / 88%)",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#D0D9DE",
        outline: "0.2px solid #D0D9DE",
      },
    },
    docBox: {
      "&:hover": {
        cursor: "pointer",
        "& p": {
          backgroundColor: "#d9d9d9",
        },
      },
    },
    messagesCount: {
      width: "fit-content",
      padding: "2px 8px",
      backgroundColor: "#309A90",
      borderRadius: "10px",
      color: "#ffffff",
      marginRight: "10px",
      justifyContent: "center",
    },
    messageInfoWrap: {
      position: "relative",
      flexBasis: "80%",
    },
    messageInfo: {
      fontSize: "10px",
      minWidth: "fit-content",
      position: "absolute",
      top: "0",
      right: "0",
      whiteSpace: "nowrap",
    },
    messageBox: {
      display: "flex",
      margin: "7px 5px 7px 0",
    },
    myMessageBox: {
      alignItems: "end",
      justifyContent: "flex-end",
    },
    fromMessageBox: {
      alignItems: "start",
      justifyContent: "flex-start",
    },
  })
);
