import { Box, createStyles, makeStyles, Theme } from "@material-ui/core";
import { memo } from "react";
import { chatListType, TypeChat } from "../../../../core/api/chatsApi";
import { CHAT_ROLES, ROLES } from "../../../../core/consts/common";
import { CurrentUserState } from "../../../../core/types/currentUser";
import Message from "../message";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    notificationsBox: {
      height: "80%",
      borderRadius: "10px",
    },
  })
);

type Props = {
  handleChatRouteClick: (chat: chatListType) => void;
  isSearch: boolean;
  chatsListSearch: chatListType[] | undefined;
  chatsList: chatListType[];
  initialUser: CurrentUserState;
};

const MessagesList: React.FC<Props> = (props) => {
  const classes = useStyles();

  return (
    <Box className={classes.notificationsBox}>
      {(props.isSearch ? props.chatsListSearch : props.chatsList)?.map(
        (chat: chatListType, id: number) => {
          let avatar: string = "";
          if (chat.typeChat === TypeChat.Role) {
            avatar =
              props.initialUser.role === ROLES.MANAGER
                ? chat.avatar_url
                : "Тех поддержка";
          }
          if (
            chat.typeChat === TypeChat.ManagerChat ||
            chat.typeChat === TypeChat.ManagerLead
          ) {
            if (props.initialUser.role === ROLES.MANAGER) {
              avatar =
                chat.roleInterlocutor === "agent"
                  ? CHAT_ROLES.agent
                  : CHAT_ROLES.client;
            } else {
              if (props.initialUser.manager) {
                avatar = props.initialUser.manager.avatar_url;
              }
            }
          }

          if (chat.typeChat === TypeChat.Bank) {
            avatar = chat.nameInterlocutor;
          }
          return (
            <Message
              key={id}
              handleChatRouteClick={props.handleChatRouteClick}
              chat={chat}
              initialUser={props.initialUser}
              avatar={avatar}
            />
          );
        }
      )}
    </Box>
  );
};

export default memo(MessagesList);
