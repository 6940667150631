import {
  ErrorsData,
  FormField,
  PropsalData,
} from "../../../pages/new-order/types/bigForm";
import {
  DeleteFromFormPropsalPayload,
  DeleteModalAccordionPayload,
  DeleteMultipleModalAccordionPayload,
  SetAlertModalPayload,
  SetCurrentEditModalMultiplePayload,
  SetCurrentEditModalPayload,
  SetCurrentModalDataPayload,
  SetCurrentModalErrorsPayload,
  SetCurrentModalIndexPayload,
  SetErrorsFormCountPayload,
  SetGroupFieldErrorPayload,
  SetIsFetchingPayload,
  SetModalFieldPayload,
  SetMultipleModalFieldPayload,
  SetPropsalErrorsPayload,
  SetValueFieldCurrentModalPayload,
  UpdateFieldValuePayload,
  newOrderActionTypes,
} from "../../types/NewOrderReducer";

export const setFormData = (payload: PropsalData[]) => {
  return { type: newOrderActionTypes.SET_FORM_DATA, payload };
};

export const setFormValues = (payload: Record<number, FormField>) => {
  return { type: newOrderActionTypes.SET_FORM_VALUES, payload };
};

export const setFormErrors = (payload: Record<number, ErrorsData>) => {
  return { type: newOrderActionTypes.SET_ERRORS_FIELDS, payload };
};

export const deleteNewOrderData = () => {
  return { type: newOrderActionTypes.DELETE_NEW_ORDER_DATA };
};

export const updateFieldValue = (payload: UpdateFieldValuePayload) => {
  return { type: newOrderActionTypes.UPDATE_FIELD_VALUE, payload };
};

export const deleteMultipleModalAccordion = (
  payload: DeleteMultipleModalAccordionPayload
) => {
  return { type: newOrderActionTypes.DELETE_MULTIPLE_MODAL_ACCORDION, payload };
};

export const deleteModalAccordion = (
  payload: DeleteModalAccordionPayload
) => {
  return { type: newOrderActionTypes.DELETE_MODAL_ACCORDION, payload };
};

export const setCurrentModalData = (
  payload: SetCurrentModalDataPayload
) => {
  return { type: newOrderActionTypes.SET_CURRENT_MODAL_DATA, payload };
};

export const setCurrentEditModal = (
  payload: SetCurrentEditModalPayload
) => {
  return { type: newOrderActionTypes.SET_CURRENT_EDIT_MODAL, payload };
};

export const setCurrentEditModalMultiple = (
  payload: SetCurrentEditModalMultiplePayload
) => {
  return { type: newOrderActionTypes.SET_CURRENT_EDIT_MODAL_MULTIPLE, payload };
};

export const setValueFieldCurrentModal = (
  payload: SetValueFieldCurrentModalPayload
) => {
  return { type: newOrderActionTypes.SET_VALUE_FIELD_CURRENT_MODAL, payload };
};

export const setCurrentModalIndex = (
  payload: SetCurrentModalIndexPayload
) => {
  return { type: newOrderActionTypes.SET_CURRENT_MODAL_INDEX, payload };
};

export const setModalField = (
  payload: SetModalFieldPayload
) => {
  return { type: newOrderActionTypes.SET_MODAL_FIELD, payload };
};

export const setModalMultipleField = (
  payload: SetMultipleModalFieldPayload
) => {
  return { type: newOrderActionTypes.SET_MULTIPLE_MODAL_FIELD, payload };
};

export const setPropsalErrors = (
  payload: SetPropsalErrorsPayload
) => {
  return { type: newOrderActionTypes.SET_PROPSAL_ERRORS, payload };
};

export const setCurrentModalErrors = (
  payload: SetCurrentModalErrorsPayload
) => {
  return { type: newOrderActionTypes.SET_CURRENT_MODAL_ERRORS, payload };
};

// export const addCurrentModalErrors = (
//   payload: SetCurrentModalErrorsPayload
// ) => {
//   return { type: newOrderActionTypes.ADD_CURRENT_MODAL_ERRORS, payload };
// };

export const setErrorsFormCount = (
  payload: SetErrorsFormCountPayload
) => {
  return { type: newOrderActionTypes.SET_ERRORS_FORM_COUNT, payload };
};

export const setIsFetching = (
  payload: SetIsFetchingPayload
) => {
  return { type: newOrderActionTypes.SET_IS_FETCHING, payload };
};

export const deleteFromFormPropsal = (
  payload: DeleteFromFormPropsalPayload
) => {
  return { type: newOrderActionTypes.DELETE_FROM_FORM_PROPSAL, payload };
};

export const setAlertModal = (
  payload: SetAlertModalPayload
) => {
  return { type: newOrderActionTypes.SET_ALERT_MODAL, payload };
};

export const setGroupFieldErrors = (
  payload: SetGroupFieldErrorPayload | undefined
) => {
  return { type: newOrderActionTypes.SET_GROUP_FIELD_ERROR, payload };
};

export const clearGroupFieldError = () => {
  return { type: newOrderActionTypes.CLEAR_GROUP_FIELD_ERROR };
};
