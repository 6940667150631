import {
  createStyles,
  makeStyles,
  Theme
} from '@material-ui/core';
import React, { memo } from 'react';
import { useLocation } from "react-router-dom";
import { ROUTES } from '../../../core/consts/common';
import { COLORS } from '../../../core/consts/redesign/commonRedesign';
import SideMenu from '../side-menu';

/* Layout component. defines header, layout and sidemenu.
*/

interface LayoutProps {
  children: React.ReactNode;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    layout: {
      minHeight: '100vh',
      backgroundColor: COLORS.light_gray_bg,
    },
    container: {
      marginLeft: '120px',
      display: 'flex',
      boxSizing: 'border-box',
      minHeight: '100%',
      height: '100vh',
      alignItems: 'center',
      flexDirection: 'column',
      [theme.breakpoints.down(1024)]: {
        marginLeft: 0
      },
    },
    bodyContainer: {
      flexGrow: 1,
      maxWidth: '100%',
      backgroundColor: COLORS.light_gray_bg,
      width: '100%',
      boxSizing: 'border-box',
    },
  })
);

const Layout: React.FC<LayoutProps> = ({
  children
}) => {
  const classes = useStyles();
  const location = useLocation();
  const isMainPage = location.pathname === ROUTES.MAIN;

  return (
    <>
      {!isMainPage ?
        (<div className={classes.layout}>
          <div className={classes.container}>
            <SideMenu />
            <main className={classes.bodyContainer}>{children}</main>
          </div>
        </div>
        ) : (
          <>{children}</>
        )}
    </>
  );
};
export default memo(Layout);