import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ROLES, ROUTES } from "../../../core/consts/redesign/commonRedesign";
import { rootState } from "../../../core/types/rootState";
import ConfirmModalRedesign from "../confirm-modal-redesign/ConfirmModalRedesign";

/* Redesign */

type PropsType = {
  isOpen: boolean;
};

const ErrorModal: React.FC<PropsType> = ({ isOpen }) => {
  const history = useHistory();
  const initialUser = useSelector((state: rootState) => state.currentUser);

  const routeToProducts = () => {
    switch (initialUser.role) {
      case ROLES.CLIENT:
        history.push(`${ROUTES.CLIENT}/products`);
        break;
      case ROLES.AGENT:
        history.push(`${ROUTES.AGENT}/products`);
        break;
      case ROLES.MANAGER:
        history.push(`${ROUTES.MANAGER}/products`);
        break;
      case ROLES.ADMIN:
        history.push(`${ROUTES.ADMIN}/products`);
        break;
      default:
        history.push(`/`);
    }
  };

  const routeToSupport = () => {
    switch (initialUser.role) {
      case ROLES.CLIENT:
        history.push(`${ROUTES.CLIENT}/support`);
        break;
      case ROLES.AGENT:
        history.push(`${ROUTES.AGENT}/support`);
        break;
      case ROLES.MANAGER:
        history.push(`${ROUTES.MANAGER}/support`);
        break;
      default:
        history.push(`/`);
    }
  };

  return (
    <ConfirmModalRedesign
      handleConfirm={routeToProducts}
      title={"Упс, что-то пошло не так"}
      handleCancel={routeToSupport}
      confBtnText="К заявкам"
      cancelBtnText="В техподдержку"
      isExtraCloseModal={true}
      open={isOpen} />
  );
};

export default ErrorModal;
