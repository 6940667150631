import React, { memo, useEffect, useState } from 'react';
import { createStyles, makeStyles, Box, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        preloaderFixed: {
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255, 255, 255, 0.65)',
            zIndex: 10000,
        },
        preloaderAbsolute: {
            position: 'absolute',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255, 255, 255, 0.65)',
            zIndex: 10000,
        },
        preloaderBlock: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
            backgroundColor: 'inherit',
        },
        loaderAbsolute: {
            display: 'block',
            position: 'relative',
            left: '50%',
            top: '50%',
            margin: '-75px 0 0 -75px',
            borderRadius: '50%',
            border: '3px solid transparent',
            borderTopColor: '#306F9A',
            '&::-webkit-animation': `$spin 2s ${theme.transitions.easing.easeInOut} infinite`,
            animation: `$spin 2s ${theme.transitions.easing.easeInOut} infinite`,
            '&:before': {
                content: "''",
                position: 'absolute',
                top: '5px',
                left: '5px',
                right: '5px',
                bottom: '5px',
                borderRadius: '50%',
                border: '3px solid transparent',
                borderTopColor: '#44A3E4',
                '&::-webkit-animation': `$spin 3s ${theme.transitions.easing.easeInOut} infinite`,
                animation: `$spin 3s ${theme.transitions.easing.easeInOut} infinite`,
            },
            '&:after': {
                content: "''",
                position: 'absolute',
                top: '15px',
                left: '15px',
                right: '15px',
                bottom: '15px',
                borderRadius: '50%',
                border: '3px solid transparent',
                borderTopColor: '#942BD4',
                '&::-webkit-animation': `$spin 1.5s ${theme.transitions.easing.easeInOut} infinite`,
                animation: `$spin 1.5s ${theme.transitions.easing.easeInOut} infinite`,
            }
        },
        loaderBlock: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
            margin: '0',
            borderRadius: '50%',
            border: '3px solid transparent',
            borderTopColor: '#306F9A',
            '&::-webkit-animation': `$spin 2s ${theme.transitions.easing.easeInOut} infinite`,
            animation: `$spin 2s ${theme.transitions.easing.easeInOut} infinite`,
            '&:before': {
                content: "''",
                position: 'absolute',
                top: '5px',
                left: '5px',
                right: '5px',
                bottom: '5px',
                borderRadius: '50%',
                border: '3px solid transparent',
                borderTopColor: '#44A3E4',
                '&::-webkit-animation': `$spin 3s ${theme.transitions.easing.easeInOut} infinite`,
                animation: `$spin 3s ${theme.transitions.easing.easeInOut} infinite`,
            },
            '&:after': {
                content: "''",
                position: 'absolute',
                top: '15px',
                left: '15px',
                right: '15px',
                bottom: '15px',
                borderRadius: '50%',
                border: '3px solid transparent',
                borderTopColor: '#942BD4',
                '&::-webkit-animation': `$spin 1.5s ${theme.transitions.easing.easeInOut} infinite`,
                animation: `$spin 1.5s ${theme.transitions.easing.easeInOut} infinite`,
            }
        },
        '@-webkit-keyframes spin': {
            '0%': {
                '&::-webkit-transform': 'rotate(0deg)',
                '&::-ms-transform': 'rotate(0deg)',
                transform: 'rotate(0deg)',
            },
            '100%': {
                '&::-webkit-transform': 'rotate(360deg)',
                '&::-ms-transform': 'rotate(360deg)',
                transform: 'rotate(360deg)',
            }
        },
        '@keyframes spin': {
            '0%': {
                // '&::-webkit-transform': 'rotate(0deg)',
                // '&::-ms-transform': 'rotate(0deg)',
                transform: 'rotate(0deg)',
            },
            '100%': {
                // '&::-webkit-transform': 'rotate(360deg)',
                // '&::-ms-transform': 'rotate(360deg)',
                transform: 'rotate(360deg)',
            }
        }
    })
);

type PreloaderType = 'absolute' | 'block' | 'fixed'

type Styles = {
    preloader: string,
    loader: string
}

type Props = {
    preloaderType: PreloaderType
    sizePixels?: string
}

const Preloader: React.FC<Props> = ({preloaderType, sizePixels = '150px'}) => {
    const classes = useStyles();
    const [styles, setStyles] = useState<Styles>({
        preloader: classes.preloaderAbsolute,
        loader: classes.loaderAbsolute
    })

    const checkPreloaderType = (preloaderType: PreloaderType) => {
        switch (preloaderType) {
            case 'fixed':
                return {
                    preloader: classes.preloaderFixed,
                    loader: classes.loaderAbsolute
                }
            case 'absolute':
                return {
                    preloader: classes.preloaderAbsolute,
                    loader: classes.loaderAbsolute
                }
            case 'block':
                return {
                    preloader: classes.preloaderBlock,
                    loader: classes.loaderBlock
                }
        }
    }

    useEffect(() => {
        setStyles(checkPreloaderType(preloaderType))
    }, [preloaderType])

    return (
        <Box className={styles.preloader}>
            <Box className={styles.loader} style={{width: sizePixels, height: sizePixels}}>

            </Box>
        </Box>
    );
};

export default memo(Preloader)