import { isObject } from "lodash";
import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { actions, ThunkAppDispatch } from "..";
import {
  filterObject,
  handleResponseForBigForm
} from "../../../pages/new-order/helpers/handleResponseForBigForm";
import {
  getParentAndChild,
  handleErrorFieldsOfModal,
  handleErrorFieldsOfModalNEWForOneField,
  handleFieldsForSend,
  handleFieldsForSendNEW,
  handleFieldsModalValidation,
  handleResponseValidationForm,
  handleResponseValidationFormNEW,
  handleResponseValidationFormNewForOneField,
  takeModalData
} from "../../../pages/new-order/helpers/helpers";
import { handleValidationCommonField, handleValidationCommonFieldNEW } from "../../../pages/new-order/helpers/validation";
import {
  ErrorsResponse,
  Field,
  FieldModal,
  FieldType,
  ValidateForm,
  ValidateForThunkForm,
  ValidateStepForThunkForm
} from "../../../pages/new-order/types/bigForm";
import leadsApi from "../../api/leadsApi";
import { CookiesEnum } from "../../consts/common";
import introspectService from "../../services/IntropsectService";
import leadsService from "../../services/LeadsService";
import tokenService from "../../services/TokenService";
import { SendNewOrderResponse } from "../../types/bigForm";
import { rootState } from "../../types/rootState";
import { delayMyPromise } from "../../utils/common";
import { NewOrderPrevType } from "../../utils/cookies";
import { clearCart } from "../action-creators/cart";
import { RootState } from "../reducers";

export type GetStateType = () => RootState;

export const deleteProposalIdFromCookie = (proposalId: number) => {
  if (!proposalId) return;
  const cookieCart = tokenService._getCookie(CookiesEnum.cart);
  if (cookieCart) {
    const tempCartFromCookieString = decodeURIComponent(cookieCart);
    const tempCartFromCookie: NewOrderPrevType[] = JSON.parse(
      tempCartFromCookieString
    );
    if (
      tempCartFromCookie &&
      tempCartFromCookie?.find((elem) => elem?.id === proposalId)
    ) {
      const newCart = tempCartFromCookie.filter(
        (elem) => elem?.id !== proposalId
      );
      tokenService._setCookie(
        CookiesEnum.cart,
        JSON.stringify(newCart),
        1,
        "/"
      );
    }
  }
};

/**Заправшивает и устанавливает все данные для формы пришедшие с бэка. */
export const getDataForBigForm =
  () => async (dispatch: ThunkAppDispatch, getState: GetStateType) => {
    try {
      actions.setIsFetching({
        isFetching: true,
      });
      // const propsalsIds = getState().dataForOrder.banksList.map(
      //   (propsal) => propsal.id
      // );

      const banksList = getState().dataForOrder.banksList;

      const propsalsIds = Array.from(new Set(banksList.map(proposal => proposal.id)));
      const innForOrder = getState().dataForOrder.inn;
      const draftLeadIds = getState().dataForOrder.draftIds;
      if (propsalsIds.length !== 0 || draftLeadIds.length !== 0) {
        const response = await leadsService.getLeadsBigFieldsService(
          propsalsIds,
          innForOrder,
          draftLeadIds
        );
        const { errorsData, formValues } = handleResponseForBigForm(response);
        actions.setFormValues(formValues);
        actions.setFormErrors(errorsData);
        actions.setFormData(response);
        actions.pushRealProposalIdsForOrder(draftLeadIds?.length > 0
          ? response?.find(el => el.isCommon === true)?.proposals?.map(el => el.id) ?? []
          : propsalsIds);
      }
      actions.setIsFetching({
        isFetching: false,
      });
    } catch (err: any) {
      console.log({ err });
      if (err.response && err.response.status) {
        introspectService.checkToken(err.response.status.toString());
      }
      actions.setIsFetching({
        isFetching: false,
      });
      if (err.response && err.response.data && err.response.data.errors) {
        const text = Array.isArray(err.response.data.errors)
          ? err.response.data.errors.map((i: any) => i.message).join(" ")
          : err.response.data.errors.message;
        actions.setAlertModal({
          tittle: "Что-то пошло не так",
          text,
          isError: true,
          confModal: true,
          draftModal: false,
          isRemain: false,
          isPromptShow: false,
        });
      } else {
        actions.setAlertModal({
          tittle: "Что-то пошло не так",
          text: "Мы уже в курсе проблемы, и работаем над ее решением!",
          isError: true,
          confModal: true,
          draftModal: false,
          isRemain: false,
          isPromptShow: false,
        });
      }
    }
  };

/**Устанавливает значение филда в форме. */
export const setValueField =
  (
    fieldName: string,
    value: Field,
    propsalId: number,
    parentName?: string,
    indexGroupField?: number
  ) =>
    async (dispatch: ThunkAppDispatch, getState: GetStateType) => {
      try {
        const formValues = getState().newOrder.formValues;
        // console.log("setValueField formValues", formValues)
        if (formValues) {
          const fieldsValue = formValues[propsalId];
          if (parentName) {
            const modalField = fieldsValue[parentName];
            if (Array.isArray(modalField)) {
              if (indexGroupField) {
                const newFieldArray: FieldModal[] = [
                  ...JSON.parse(JSON.stringify(modalField)),
                ];
                newFieldArray[indexGroupField][fieldName] = value;
                actions.updateFieldValue({
                  propsalId: propsalId,
                  field: { [parentName]: [...newFieldArray] },
                });
              } else {
                const newFieldArray: FieldModal[] = [
                  ...JSON.parse(JSON.stringify(modalField)),
                ];
                newFieldArray[0][fieldName] = value;
                actions.updateFieldValue({
                  propsalId: propsalId,
                  field: { [parentName]: [...newFieldArray] },
                });
              }
            } else if (!Array.isArray(modalField) && isObject(modalField)) {
              const newFieldArray: FieldModal = {
                ...JSON.parse(JSON.stringify(modalField)),
              };
              newFieldArray[fieldName] = value;
              actions.updateFieldValue({
                propsalId: propsalId,
                field: { [parentName]: { ...newFieldArray } },
              });
            }
          } else {
            actions.updateFieldValue({
              propsalId: propsalId,
              field: { [fieldName]: value },
            });
          }
        }
      } catch (e) {
        console.log(e);
      }
    };

/**Обработка и установка в стейт валидации предложения */
export const handleErrorsPropsalFromServer =
  (currentErrors: ErrorsResponse) =>
    (dispatch: ThunkAppDispatch, getState: GetStateType) => {
      const errors = getState().newOrder.errorsFields;
      // console.log("handleErrors errors", errors)
      // console.log("handleErrors currentErrors", currentErrors)
      const isDisplayError = getState().bigForm.isDisplay;
      if (errors) {
        const currentPropsal = errors[currentErrors.proposalId];
        // console.log("handleErrors currentPropsal", currentPropsal)
        if (!currentPropsal) return;
        const { groupFieldsErr, ...errorsPropsal } = handleResponseValidationForm(
          currentPropsal,
          currentErrors
        );
        console.log("handleErrors groupFieldsErr", groupFieldsErr)
        console.log("handleErrors errorsPropsal", errorsPropsal)
        const groupFieldPropsalErrors = groupFieldsErr
          ? { [currentErrors.proposalId]: groupFieldsErr }
          : { [currentErrors.proposalId]: undefined };
        console.log("!!! handleErrors groupFieldPropsalErrors", groupFieldPropsalErrors)
        actions.setGroupFieldErrors(groupFieldPropsalErrors);
        actions.setPropsalErrors({
          propsalId: currentErrors.proposalId,
          errors: errorsPropsal,
        });
        if (!isDisplayError) {
          actions.setIsDisplaySignalInAccordion({
            isDisplay: true,
          });
        }
      }
    };

/**Обработка и установка в стейт валидации предложения */
export const handleErrorsPropsalFromServerNEW =
  (currentErrors: ErrorsResponse) =>
    (dispatch: ThunkAppDispatch, getState: GetStateType) => {
      const errors = getState().newOrder.errorsFields;
      const formData = getState().newOrder.formData;
      console.log("handleErrorsPropsalFromServerNEW errors", errors)
      console.log("handleErrorsPropsalFromServerNEW currentErrors", currentErrors)
      const isDisplayError = getState().bigForm.isDisplay;
      if (errors) {
        const currentPropsal = errors[currentErrors.proposalId];
        console.log("handleErrorsPropsalFromServerNEW currentPropsal", currentPropsal)
        if (!currentPropsal) return;

        // !!!!!!!!!!тут добавить логику с индексом
        const { groupFieldsErr, ...errorsPropsal } = handleResponseValidationFormNEW(
          currentPropsal,
          currentErrors,
          formData
        );
        console.log("handleErrorsPropsalFromServerNEW groupFieldsErr", groupFieldsErr)
        console.log("handleErrorsPropsalFromServerNEW errorsPropsal", errorsPropsal)
        // const groupFieldPropsalErrors = groupFieldsErr
        //   ? { [currentErrors.proposalId]: groupFieldsErr }
        //   : { [currentErrors.proposalId]: undefined };
        // console.log("!!!shandleErrors groupFieldPropsalErrors", groupFieldPropsalErrors)
        // actions.setGroupFieldErrors(groupFieldPropsalErrors);
        actions.setPropsalErrors({
          propsalId: currentErrors.proposalId,
          errors: errorsPropsal,
        });

        // для добавления isFill в formdata 
        if (currentErrors && errorsPropsal && formData) {
          const newformData = [...formData];
          newformData.forEach(el => {
            if (el?.id === currentErrors.proposalId) {
              el.isFill = !errorsPropsal?.isErrors;
            }
          })
          actions.setFormData(newformData);
        }

        if (!isDisplayError) {
          actions.setIsDisplaySignalInAccordion({
            isDisplay: true,
          });
        }
      }
    };

/******NEW  Обработка и установка в стейт валидации предложения по результату валидации 1 поля*/
export const handleErrorsPropsalFromServerForOneField =
  (currentErrors: ErrorsResponse, stepId: number, fieldName?: string) =>
    (dispatch: ThunkAppDispatch, getState: GetStateType) => {
      const errors = getState().newOrder.errorsFields;
      console.log(" handleErrors ForOneField errors from state", errors)
      // const isDisplayError = getState().bigForm.isDisplay;
      if (errors) {
        const currentPropsal = errors[stepId];
        // console.log(" handleErrors ForOneField stepId", stepId)
        // console.log(" handleErrors ForOneField currentPropsal", currentPropsal)
        const commonErrors = handleResponseValidationFormNewForOneField(
          currentPropsal,
          currentErrors,
          fieldName
        );
        // console.log(" handleErrors ForOneField commonErrors", commonErrors)
        // const groupFieldPropsalErrors = groupFieldsErr
        //   ? { [formId]: groupFieldsErr }
        //   : { [formId]: undefined };
        // console.log(" handleErrors ForOneField groupFieldPropsalErrors", groupFieldPropsalErrors)
        // actions.setGroupFieldErrors(groupFieldPropsalErrors);
        actions.setPropsalErrors({
          propsalId: stepId,
          errors: commonErrors,
        });
        // if (!isDisplayError) {
        // actions.setIsDisplaySignalInAccordion({
        //   isDisplay: true,
        // });
        // }
      }
    };


/******NEW  Обработка и установка в стейт валидации предложения по результату валидации 1 поля*/
export const handleErrorsPropsalFromServerForStep =
  (currentErrors: ErrorsResponse) =>
    (dispatch: ThunkAppDispatch, getState: GetStateType) => {
      const errors = getState().newOrder.errorsFields;
      const formData = getState().newOrder.formData;
      // console.log("handleErrors errors", errors)
      console.log("handleErrorsPropsalFromServerForStep currentErrors", currentErrors)
      // const isDisplayError = getState().bigForm.isDisplay;
      if (errors) {
        const currentPropsal = errors[currentErrors.proposalId];
        // console.log("handleErrors currentPropsal", currentPropsal)
        if (!currentPropsal) return;
        const { groupFieldsErr, ...errorsPropsal } = handleResponseValidationFormNEW(
          currentPropsal,
          currentErrors,
          formData
        );
        console.log("handleErrorsPropsalFromServerForStep groupFieldsErr", groupFieldsErr)
        console.log("handleErrorsPropsalFromServerForStep errorsPropsal", errorsPropsal)
        // const groupFieldPropsalErrors = groupFieldsErr
        //   ? { [currentErrors.proposalId]: groupFieldsErr }
        //   : { [currentErrors.proposalId]: undefined };
        // console.log("!!!shandleErrors groupFieldPropsalErrors", groupFieldPropsalErrors)
        // actions.setGroupFieldErrors(groupFieldPropsalErrors);
        actions.setPropsalErrors({
          propsalId: currentErrors.proposalId,
          errors: errorsPropsal,
        });
        // if (!isDisplayError) {
        //   actions.setIsDisplaySignalInAccordion({
        //     isDisplay: true,
        //   });
        // }
      }
    };

/**Валидация всей формы. */
export const validateForm =
  () => (dispatch: ThunkAppDispatch, getState: GetStateType) => {
    try {
      actions.setIsFetching({
        isFetching: true,
      });
      const formValues = getState().newOrder.formValues;
      if (formValues) {
        const { dataForSend, commonFields } = handleFieldsForSend(formValues);
        const requestBody = dataForSend.map((propsalData) => {
          return leadsApi.sendDataForValidate<ErrorsResponse>(propsalData);
        });
        return Promise.all(requestBody)
          .then((validationDataRequest) => {
            if (commonFields) {
              const cmnFields = handleValidationCommonField(
                validationDataRequest,
                commonFields
              );
              const propsal = {
                errors: cmnFields,
                isValid: cmnFields.length > 0 ? false : true,
                proposalId: 0,
              };
              dispatch(handleErrorsPropsalFromServer(propsal));
            }
            const errorsCount = validationDataRequest.reduce<number[]>(
              (count, validationData) => {
                //Устанавливаем ошибки общих полей
                dispatch(handleErrorsPropsalFromServer(validationData));
                if (count[0]) {
                  count = [count[0] + validationData.errors.length];
                  return count;
                }
                count = [validationData.errors.length];
                return count;
              },
              []
            );
            actions.setErrorsFormCount({
              errorsCount: errorsCount[0],
            });
            actions.setIsFetching({
              isFetching: false,
            });
          })
          .catch((e) => {
            actions.setIsFetching({
              isFetching: false,
            });
            actions.setAlertModal({
              tittle: "Что-то пошло не так",
              text: "Мы уже в курсе проблемы, и работаем над ее решением!",
              isError: true,
              confModal: false,
              draftModal: true,
              isRemain: true,
              isPromptShow: false,
            });
            console.log("validate form -->", e);
          });
      }
    } catch (e) {
      console.log("Err:ValidateForm-->", e);
      actions.setIsFetching({
        isFetching: false,
      });
      actions.setAlertModal({
        tittle: "Что-то пошло не так",
        text: "Мы уже в курсе проблемы, и работаем над ее решением!",
        isError: true,
        confModal: true,
        draftModal: false,
        isRemain: false,
        isPromptShow: false,
      });
    }
  };

export const validateFormNEW =
  () => (dispatch: ThunkAppDispatch, getState: GetStateType) => {
    try {
      actions.setIsFetching({
        isFetching: true,
      });
      const formValues = getState().newOrder.formValues;
      const formData = getState().newOrder.formData;
      if (formValues && formData) {
        const realProposalIds = getState().dataForOrder.realProposalIds;
        const { dataForSend, commonFields } = handleFieldsForSendNEW(formValues, formData, realProposalIds);
        console.log("validateFormNEW dataForSend", dataForSend)
        console.log("validateFormNEW commonFields", commonFields)
        const data = dataForSend.filter((elem) => elem?.proposals?.[0]?.id && realProposalIds.includes(elem?.proposals?.[0]?.id));
        if (data?.length === 0) return;

        const requestBody = data.map((propsalData) => {
          return leadsApi.sendDataForValidateV5<ErrorsResponse>({
            data: propsalData.data,
            proposalId: propsalData.proposalId,
            proposalIds: [Number(propsalData.proposalId)],
          });
          // return leadsApi.sendDataForValidateV5<ErrorsResponse>(propsalData);
        });

        // для добавления isFill в formdata
        const newformData = [...formData];

        return Promise.all(requestBody)
          .then((validationDataRequest) => {
            if (commonFields) {
              // console.log("*************validationDataRequest", validationDataRequest)
              // const cmnFields = handleValidationCommonField(

              const cmnFields = handleValidationCommonFieldNEW(
                validationDataRequest,
                commonFields
              );

              console.log(`*************cmnFields map`, cmnFields)
              formData.forEach(step => {
                // console.log(`*************step`, step)
                // console.log(`*************cmnFields`, cmnFields)
                if (step?.isCommon === true) {
                  const cmnFieldsForStep = cmnFields.filter(cmnField => { // отбор полей для текущего степа
                    if (step.fields.find(field => field.name === cmnField.field)) {
                      return true;
                    } else {
                      return false;
                    }
                  })
                  console.log("*************cmnFieldsForStep", cmnFieldsForStep)
                  const propsal = {
                    errors: cmnFieldsForStep,
                    isValid: cmnFieldsForStep.length > 0 ? false : true,
                    proposalId: step.id,
                  };
                  console.log("*************propsal for dispatch", propsal)
                  dispatch(handleErrorsPropsalFromServerNEW(propsal));

                  newformData.forEach(el => {
                    if (el?.id === step.id) {
                      el.isFill = cmnFieldsForStep.length > 0 ? false : true
                    }
                  })
                }
              })

              actions.setFormData(newformData)

              // const propsal = {
              //   errors: cmnFields,
              //   isValid: cmnFields.length > 0 ? false : true,
              //   proposalId: 0,
              // };
              // dispatch(handleErrorsPropsalFromServer(propsal));
            }
            // console.log("validationDataRequest", validationDataRequest)
            const errorsCount = validationDataRequest.reduce<number[]>(
              (count, validationData) => {
                //Устанавливаем ошибки общих полей
                // console.log("validationData", validationData)
                dispatch(handleErrorsPropsalFromServerNEW(validationData));
                if (count[0]) {
                  count = [count[0] + validationData?.errors?.length];
                  return count;
                }
                count = [validationData?.errors?.length];
                return count;
              },
              []
            );
            console.log("errorsCount", errorsCount)

            actions.setErrorsFormCount({
              errorsCount: errorsCount[0],
            });
            actions.setIsFetching({
              isFetching: false,
            });
          })
          .catch((e) => {
            actions.setIsFetching({
              isFetching: false,
            });
            actions.setAlertModal({
              tittle: "Что-то пошло не так",
              text: "Мы уже в курсе проблемы, и работаем над ее решением!",
              isError: true,
              confModal: false,
              draftModal: true,
              isRemain: true,
              isPromptShow: false,
            });
            console.log("validate form -->", e);
          });
      }
    } catch (e) {
      console.log("Err:ValidateForm-->", e);
      actions.setIsFetching({
        isFetching: false,
      });
      actions.setAlertModal({
        tittle: "Что-то пошло не так",
        text: "Мы уже в курсе проблемы, и работаем над ее решением!",
        isError: true,
        confModal: true,
        draftModal: false,
        isRemain: false,
        isPromptShow: false,
      });
    }
  };

/**Обработка и установка в стейт валидации редактируемой модалки. */
export const handleErrorsModalFromServer =
  (currentErrors: ErrorsResponse, modalIndex?: number) =>
    (dispatch: ThunkAppDispatch, getState: GetStateType) =>
      new Promise((resolve) => {
        const errors = getState().newOrder.currentEditModal?.errors?.[modalIndex ?? 0];
        if (errors) {
          const errorsPropsal = handleErrorFieldsOfModal(currentErrors, errors);
          actions.setCurrentModalErrors({ errors: errorsPropsal });
        }
        resolve("ok");
      });

/**Обработка и установка в стейт валидации редактируемой модалки. */
export const handleErrorsModalFromServerNEWForOneField =
  (currentErrors: ErrorsResponse, modalIndex?: number, fieldname?: string) =>
    (dispatch: ThunkAppDispatch, getState: GetStateType) =>
      new Promise((resolve) => {
        const errors = getState().newOrder.currentEditModal?.errors?.[modalIndex ?? 0];
        // console.log("handleErrorsModalFromServerNEW errors", errors)
        // console.log("handleErrorsModalFromServerNEW currentErrors", currentErrors)
        if (errors) {
          const commonErrors = handleErrorFieldsOfModalNEWForOneField(currentErrors, errors, modalIndex);
          console.log("commonErrors", commonErrors)
          if (fieldname && currentErrors && currentErrors.isValid === true) {
            commonErrors[fieldname] = ""
          }
          actions.setCurrentModalErrors({ errors: commonErrors, modalIndex: modalIndex ?? 0 });
        }
        resolve("ok");
      });

type ValidateModalResult = {
  errorsCount: number;
};


/**Обработка и установка в стейт валидации редактируемой модалки c multiple = true (index есть в запросе) . */
export const handleErrorsModalMultipleFromServerNEW =
  (currentErrors: ErrorsResponse, modalIndex?: number, fieldname?: string) =>
    (dispatch: ThunkAppDispatch, getState: GetStateType) =>
      new Promise((resolve) => {
        // const errors = getState().newOrder.currentEditModal?.errors?.[modalIndex ?? 0];
        // console.log("handleErrorsModalMultipleFromServerNEW errors", errors)
        // console.log("handleErrorsModalMultipleFromServerNEW currentErrors", currentErrors)
        // if (errors) {
        //   const commonErrors = handleErrorFieldsOfModalNEW(currentErrors, errors, modalIndex);
        //   console.log("commonErrors", commonErrors)
        //   if (fieldname && currentErrors.isValid === true) {
        //     commonErrors[fieldname] = ""
        //   }
        //   actions.setCurrentModalErrors({ errors: commonErrors, modalIndex: modalIndex ?? 0 });
        // }

        console.log("handleErrorFieldsOfModalMultipleNEW currentErrors", currentErrors)
        const errorsArray: Record<string, string>[] = [];
        currentErrors.errors.forEach(error => {
          if (error?.index && error?.subField) {
            errorsArray[error.index][error.subField] = error.text
          }
        })
        console.log("handleErrorFieldsOfModalMultipleNEW errorsArray", errorsArray)

        resolve("ok");
      });

/**Валидация модалки. */
export const validateModal =
  (modalIndex?: number): ThunkAction<Promise<ValidateModalResult>, rootState, void, AnyAction> =>
    async (dispatch: ThunkAppDispatch, getState: GetStateType) => {
      return new Promise<ValidateModalResult>(async (resolve) => {
        try {
          const modalValues = getState().newOrder.currentEditModal.values?.[modalIndex ?? 0];
          const modalName = getState().newOrder.currentModalData.modalName;
          const propsalId = getState().newOrder.currentModalData.propsalId;
          const isMultiple = getState().newOrder.currentModalData.isMultiple;
          if (modalValues && modalName && propsalId) {
            const modalForValidate = handleFieldsModalValidation(modalValues);
            if (!Array.isArray(modalForValidate)) {
              const requestBody = {
                data: {
                  [modalName]: isMultiple
                    ? JSON.stringify([modalForValidate])
                    : JSON.stringify(modalForValidate),
                },
                fieldName: modalName,
                proposalId: String(propsalId),
              };
              const errorsResponse =
                await leadsApi.sendDataForValidate<ErrorsResponse>(requestBody);
              await dispatch(handleErrorsModalFromServer(errorsResponse));
              resolve({
                errorsCount: errorsResponse.errors.length,
              });
            }
          }
        } catch (e) {
          console.log(e);
        }
      });
    };


/**Сохраняет новую модалку в данные формы. */
export const setModalValueField =
  (modalIndex?: number) =>
    () => async (dispatch: ThunkAppDispatch, getState: GetStateType) => {
      try {
        const modalValues = getState().newOrder.currentEditModal;
        const modalData = getState().newOrder.currentModalData;
        if (
          modalValues.values &&
          modalValues.errors &&
          Object.values(modalValues.errors[0]).every((error) => error.length === 0)
        ) {
          if (
            !modalData.isMultiple &&
            modalData.propsalId &&
            modalValues.values &&
            modalValues.errors &&
            modalData.modalName
          ) {
            actions.setModalField({
              propsalId: modalData.propsalId,
              fieldName: modalData.modalName,
              value: modalValues.values,
              errors: modalValues.errors,
            });
          } else if (
            modalData.isMultiple &&
            modalData.indexEditModal !== undefined &&
            modalData.propsalId &&
            modalValues.values &&
            modalValues.errors &&
            modalData.modalName
          ) {
            actions.setModalMultipleField({
              propsalId: modalData.propsalId,
              fieldName: modalData.modalName,
              index: modalData.indexEditModal,
              // value: modalValues.values,
              // errors: modalValues.errors,
              value: modalValues.values?.[modalIndex ?? 0],
              errors: modalValues.errors?.[modalIndex ?? 0],
            });
          } else if (
            modalData.isMultiple &&
            !modalData.indexEditModal &&
            modalData.propsalId &&
            modalValues.values &&
            modalValues.errors &&
            modalData.modalName
          ) {
            actions.setModalMultipleField({
              propsalId: modalData.propsalId,
              fieldName: modalData.modalName,
              index: modalData.indexEditModal,
              // value: modalValues.values,
              // errors: modalValues.errors,
              value: modalValues.values?.[modalIndex ?? 0],
              errors: modalValues.errors?.[modalIndex ?? 0],
            });
          }
        }
      } catch (e) {
        console.log(e);
      }
    };

/**Сохраняет новую модалку в данные формы в любом случае, даже при частично заполненных полях. */
export const setModalValueFieldOnChange =
  (modalIndex?: number) =>
    () => async (dispatch: ThunkAppDispatch, getState: GetStateType) => {
      try {
        console.log("setModalValueFieldOnChange")
        const modalValues = getState().newOrder.currentEditModal;
        console.log("setModalValueFieldOnChange modalValues", modalValues);
        const modalData = getState().newOrder.currentModalData;
        console.log("setModalValueFieldOnChange modalData", modalData);
        if (modalValues.values && modalValues.errors) {
          if (
            !modalData.isMultiple &&
            modalData.propsalId &&
            modalValues.values &&
            modalValues.errors &&
            modalData.modalName
          ) {
            console.log("setModalValueFieldOnChange 1 actions.setModalField", modalValues.values);
            actions.setModalField({
              propsalId: modalData.propsalId,
              fieldName: modalData.modalName,
              value: modalValues.values,
              errors: modalValues.errors,
            });
          } else if (
            modalData.isMultiple &&
            modalData.indexEditModal !== undefined &&
            modalData.propsalId &&
            modalValues.values &&
            modalValues.errors &&
            modalData.modalName
          ) {
            actions.setModalMultipleField({
              propsalId: modalData.propsalId,
              fieldName: modalData.modalName,
              index: modalData.indexEditModal,
              // value: modalValues.values,
              // errors: modalValues.errors,
              value: modalValues.values?.[modalIndex ?? 0],
              errors: modalValues.errors?.[modalIndex ?? 0],
            });
          } else if (
            modalData.isMultiple &&
            !modalData.indexEditModal &&
            modalData.propsalId &&
            modalValues.values &&
            modalValues.errors &&
            modalData.modalName
          ) {
            actions.setModalMultipleField({
              propsalId: modalData.propsalId,
              fieldName: modalData.modalName,
              index: modalData.indexEditModal,
              // value: modalValues.values,
              // errors: modalValues.errors,
              value: modalValues.values?.[modalIndex ?? 0],
              errors: modalValues.errors?.[modalIndex ?? 0],
            });
          }
        }
      } catch (e) {
        console.log(e);
      }
    };

/**Устанавливает значения для текущей модалки. */
export const setCurrentEditModalValues =
  (modalName: string, indexGroupField?: number, propsalIdOuter?: number, modalIndex?: number) =>
    async (dispatch: ThunkAppDispatch, getState: GetStateType) => {
      try {
        const formValues = getState().newOrder.formValues;
        const formErrors = getState().newOrder.errorsFields;
        const formData = getState().newOrder.formData;
        const propsalId = propsalIdOuter ?? getState().newOrder.currentModalData?.propsalId;

        if (formValues && formErrors && propsalId) {
          const fieldsValue = formValues?.[propsalId];
          const fieldsErrors = formErrors[propsalId]?.fields;
          const modalField = fieldsValue?.[modalName];
          const modalFieldErrors = fieldsErrors[modalName];
          if (Array.isArray(modalField) && Array.isArray(modalFieldErrors)) {
            if (modalField.length > 0 && indexGroupField !== undefined) {
              actions.setCurrentEditModal({
                values: modalField[indexGroupField] as FieldModal,
                errors: modalFieldErrors[indexGroupField],
              });
            } else {
              const modalData = takeModalData(formData, propsalId, modalName);
              if (modalData) {
                actions.setCurrentEditModal({
                  values: modalData.fieldsWithValues,
                  errors: modalData.errorsFields,
                });
              }
            }
          } else if (
            !Array.isArray(modalField) &&
            isObject(modalField) &&
            !Array.isArray(modalFieldErrors) &&
            isObject(modalFieldErrors)
          ) {
            if (
              Object.keys(modalField).length > 0 &&
              Object.keys(modalFieldErrors).length > 0
            ) {
              actions.setCurrentEditModal({
                values: modalField as FieldModal,
                errors: modalFieldErrors,
              });
            } else {
              const modalData = takeModalData(formData, propsalId, modalName);
              if (modalData) {
                actions.setCurrentEditModal({
                  values: modalData.fieldsWithValues,
                  errors: modalData.errorsFields,
                });
              }
            }
          }
        }
      } catch (e) {
        console.log("Error:SetCurrentModalValue -->", e);
      }
    };

/**Устанавливает значения для текущей модалки. */
export const setCurrentEditModalValuesNEW =
  (modalName: string, indexGroupField?: number, propsalIdOuter?: number, modalIndex?: number) =>
    async (dispatch: ThunkAppDispatch, getState: GetStateType) => {
      try {
        const formValues = getState().newOrder.formValues;
        console.log("!!!setCurrentEditModalValues formValues", formValues)
        const formErrors = getState().newOrder.errorsFields;
        const formData = getState().newOrder.formData;
        const propsalId = propsalIdOuter ?? getState().newOrder.currentModalData?.propsalId;

        // console.log("!!!setCurrentEditModalValues formErrors", formErrors)
        // console.log("!!!setCurrentEditModalValues propsalId", propsalId)
        if (formValues && formErrors && propsalId) {
          const fieldsValue = formValues?.[propsalId];
          const fieldsErrors = formErrors[propsalId]?.fields;
          console.log("!!!setCurrentEditModalValues fieldsErrors", fieldsErrors)
          const modalField = fieldsValue?.[modalName];
          console.log("!!!setCurrentEditModalValues modalField", modalField)
          const modalFieldErrors = fieldsErrors[modalName];
          console.log("!!!setCurrentEditModalValues modalFieldErrors", modalFieldErrors)
          if (Array.isArray(modalField)
            // && Array.isArray(modalFieldErrors) TODO modalFieldErrors to array
            && (Array.isArray(modalFieldErrors) || typeof modalFieldErrors === 'object')
          ) {
            if (modalField.length > 0
              // && indexGroupField !== undefined
            ) {

              console.log("!!!setCurrentEditModalValues 1111 modalField", modalField)
              actions.setCurrentEditModal({
                values: modalField[indexGroupField ?? 0] as FieldModal,
                errors: Array.isArray(modalFieldErrors) ? modalFieldErrors?.[indexGroupField ?? 0] : modalFieldErrors,
              });
            } else {
              const modalData = takeModalData(formData, propsalId, modalName);
              console.log("!!!setCurrentEditModalValues 2222 modalData", modalData)
              if (modalData) {
                actions.setCurrentEditModal({
                  values: modalData.fieldsWithValues,
                  errors: modalData.errorsFields,
                });
              }
            }
          } else if (
            !Array.isArray(modalField) &&
            isObject(modalField) &&
            !Array.isArray(modalFieldErrors) &&
            isObject(modalFieldErrors)
          ) {
            if (
              Object.keys(modalField).length > 0 &&
              Object.keys(modalFieldErrors).length > 0
            ) {
              actions.setCurrentEditModal({
                values: modalField as FieldModal,
                errors: modalFieldErrors,
              });
            } else {
              const modalData = takeModalData(formData, propsalId, modalName);

              if (modalData) {
                console.log("!!!setCurrentEditModalValues 333 modalFieldErrors", modalFieldErrors)
                console.log("!!!setCurrentEditModalValues 333 modalData", modalData)
                actions.setCurrentEditModal({
                  values: modalData.fieldsWithValues,
                  errors: Object.keys(modalFieldErrors).length > 0 ? modalFieldErrors : modalData.errorsFields,
                });
              }
            }
          }
        }
      } catch (e) {
        console.log("Error:SetCurrentModalValue -->", e);
      }
    };

/**Устанавливает значения для текущей модалки для малтипл поля. NEW*/
export const setCurrentEditModalValuesMultiple =
  (modalName: string, indexGroupField?: number, propsalIdOuter?: number, modalIndex?: number) =>
    async (dispatch: ThunkAppDispatch, getState: GetStateType) => {
      try {
        const formValues = getState().newOrder.formValues;
        console.log("!!!setCurrentEditModalValuesMultiple formValues!", formValues)
        const formErrors = getState().newOrder.errorsFields;
        console.log("!!!setCurrentEditModalValuesMultiple formErrors!", formErrors)
        const formData = getState().newOrder.formData;
        const propsalId = propsalIdOuter ?? getState().newOrder.currentModalData?.propsalId;

        console.log("!!!setCurrentEditModalValuesMultiple propsalId!", propsalId)
        if (formValues && formErrors && propsalId) {
          const fieldsValue = formValues[propsalId];
          // console.log("!!!setCurrentEditModalValuesMultiple fieldsValue", fieldsValue)
          const fieldsErrors = formErrors[propsalId].fields;

          const modalField = fieldsValue[modalName];
          console.log("!!!setCurrentEditModalValuesMultiple modalField", modalField)
          const modalFieldErrors = fieldsErrors[modalName];
          console.log("!!!setCurrentEditModalValuesMultiple modalFieldErrors", modalFieldErrors)

          if (Array.isArray(modalField) && modalField.length > 0) {
            console.log("!!!setCurrentEditModalValuesMultiple 1111 modalField", modalField)
            actions.setCurrentEditModalMultiple({
              values: modalField as FieldModal[],
              errors: modalFieldErrors as Record<string, string>[],
            });
            // const fieldsValues: FieldModal[] = [];
            // const fieldsErrores: Record<string, string>[] = [];

            // modalField.forEach(el => {
            //   const modalData = takeModalData(formData, propsalId, modalName);
            //   console.log("*****setCurrentEditModalValuesMultiple modalData Values!!!", modalData)
            //   if (modalData) {
            //     fieldsValues.push(modalData.fieldsWithValues)
            //     fieldsErrores.push(modalData.errorsFields)
            //   }
            // })
            // actions.setCurrentEditModalMultiple({
            //   values: fieldsValues,
            //   errors: fieldsErrores,
            // });

          } else {
            const modalData = takeModalData(formData, propsalId, modalName);
            console.log("*****setCurrentEditModalValuesMultiple 222 modalData noValues", modalData)
            if (modalData) {
              actions.setCurrentEditModalMultiple({
                values: [modalData.fieldsWithValues],
                // errors: modalFieldErrors??[modalData.errorsFields],
                errors: modalFieldErrors ? modalFieldErrors as Record<string, string>[] : [modalData.errorsFields],
              });
            }
          }




          // if (Array.isArray(modalField) && Array.isArray(modalFieldErrors)) {
          //   if (modalField.length > 0 && indexGroupField !== undefined) {
          //     actions.setCurrentEditModal({
          //       values: modalField[indexGroupField] as FieldModal,
          //       errors: modalFieldErrors[indexGroupField],
          //     });
          //   } else {
          //     const modalData = takeModalData(formData, propsalId, modalName);
          //     console.log("setCurrentEditModalValues modalData", modalData)
          //     if (modalData) {
          //       actions.setCurrentEditModal({
          //         values: modalData.fieldsWithValues,
          //         errors: modalData.errorsFields,

          //       });
          //     }
          //   }
          // } else if (
          //   !Array.isArray(modalField) &&
          //   isObject(modalField) &&
          //   !Array.isArray(modalFieldErrors) &&
          //   isObject(modalFieldErrors)
          // ) {
          //   if (
          //     Object.keys(modalField).length > 0 &&
          //     Object.keys(modalFieldErrors).length > 0
          //   ) {
          //     actions.setCurrentEditModal({
          //       values: modalField as FieldModal,
          //       errors: modalFieldErrors,
          //     });
          //   } else {
          //     const modalData = takeModalData(formData, propsalId, modalName);
          //     if (modalData) {
          //       actions.setCurrentEditModal({
          //         values: modalData.fieldsWithValues,
          //         errors: modalData.errorsFields,
          //       });
          //     }
          //   }
          // }
        }
      } catch (e) {
        console.log("Error:SetCurrentModalValue -->", e);
      }
    };

/**Добавляет пустое поле для модалки. */
export const AddEditModalValuesMultiple =
  (modalName: string, modalIndex?: number, propsalIdOuter?: number,) =>
    async (dispatch: ThunkAppDispatch, getState: GetStateType) => {
      try {
        const formValues = getState().newOrder.formValues;
        const formErrors = getState().newOrder.errorsFields;
        const formData = getState().newOrder.formData;
        const propsalId = propsalIdOuter ?? getState().newOrder.currentModalData?.propsalId;

        if (formValues && formErrors && propsalId) {
          const modalData = takeModalData(formData, propsalId, modalName);
          if (modalData) {
            actions.setCurrentEditModal({
              values: modalData.fieldsWithValues,
              errors: modalData.errorsFields,
            });
          }
        }
      } catch (e) {
        console.log("Error:SetCurrentModalValue -->", e);
      }
    };

/**Отправка заявки для сохранения в черновик */
export const sendDataFormToDraft =
  () => async (dispatch: ThunkAppDispatch, getState: GetStateType) => {
    console.log("!!!!sendDataFormToDraft");
    try {
      actions.setIsFetching({
        isFetching: true,
      });
      const isNewBasedOnOld = getState().dataForOrder.isNewBasedOnOld;
      const formValues = getState().newOrder.formValues;
      if (formValues) {
        const userId = getState().currentUser.userId;
        const draftIds = getState().dataForOrder.draftIds;
        const managerId = getState().currentUser.manager?.id;
        const { dataForSend } = handleFieldsForSend(formValues);
        let requests: Promise<SendNewOrderResponse>[];
        if (draftIds.length > 0 && isNewBasedOnOld === false) {
          requests = dataForSend.map((propsalData, index) => {
            const requestBody = {
              draft: true,
              id: [draftIds[index]],
              data: propsalData.data,
              users: managerId ? [userId, managerId] : [userId],
            };
            return leadsApi.saveDraft(requestBody);
          });
        } else {
          requests = dataForSend.map((propsalData) => {
            const requestBody = {
              draft: true,
              proposalIds: [propsalData.proposalId],
              data: propsalData.data,
              users: managerId ? [userId, managerId] : [userId],
            };
            return leadsApi.sendNewOrder(requestBody);
          });
        }
        return Promise.all(requests)
          .then((response) => {
            dataForSend.forEach((elem) =>
              deleteProposalIdFromCookie(+elem?.proposalId)
            );

            const draftIds = response.map((draftPropsal) => {
              return draftPropsal.ids[0];
            });
            actions.setIsFetching({
              isFetching: false,
            });
            actions.setAlertModal({
              tittle: "Черновик сохранен",
              text: "Ваш черновик успешно сохранен в общем списке. Вы можете вернуться к заполнению в любое время.",
              isError: false,
              confModal: true,
              draftModal: false,
              isRemain: true,
              isPromptShow: false,
            });
            actions.pushDataDraftForOrder(draftIds);
            actions.pushIsNewBasedOnOldForOrder(false);
          })
          .catch((err) => {
            console.log({ err });
            if (err.response && err.response.status) {
              introspectService.checkToken(err.response.status.toString());
            }
            actions.setIsFetching({
              isFetching: false,
            });
            if (err.response && err.response.data && err.response.data.errors) {
              const text = Array.isArray(err.response.data.errors)
                ? err.response.data.errors.map((i: any) => i.message).join(" ")
                : err.response.data.errors.message;
              actions.setAlertModal({
                tittle: "Что-то пошло не так",
                text,
                isError: true,
                confModal: false,
                draftModal: true,
                isRemain: true,
                isPromptShow: false,
              });
            } else {
              actions.setAlertModal({
                tittle: "Что-то пошло не так",
                text: "Мы уже в курсе проблемы, и работаем над ее решением!",
                isError: true,
                confModal: false,
                draftModal: true,
                isRemain: true,
                isPromptShow: false,
              });
            }
          });
      }
    } catch (e) {
      actions.setIsFetching({
        isFetching: false,
      });
      actions.setAlertModal({
        tittle: "Что-то пошло не так",
        text: "Мы уже в курсе проблемы, и работаем над ее решением!",
        isError: true,
        confModal: true,
        draftModal: false,
        isRemain: false,
        isPromptShow: false,
      });
      console.log("Error:sendDataForDraft -->", e);
    }
  };

/**Функция сохранения текущих изменений в черновик (по ходу заполнеия форму при покидании инпута без закрытия модалки) */
export const sendCurrentDataFormToDraft =
  (propsalId: number) =>
    async (dispatch: ThunkAppDispatch, getState: GetStateType) => {
      // console.log("!!!!!sendCurrentDataFormToDraft");
      try {
        const isNewBasedOnOld = getState().dataForOrder.isNewBasedOnOld;
        const formValues = getState().newOrder.formValues;
        if (!formValues) return;
        const userId = getState().currentUser.userId;
        const draftIds = getState().dataForOrder.draftIds;
        const managerId = getState().currentUser.manager?.id;
        const currentModalData = getState().newOrder?.currentModalData;
        const currentModalValues = getState().newOrder?.currentEditModal?.values;
        // console.log("sendCurrentDataFormToDraft formValues", formValues);

        const { dataForSend } = handleFieldsForSend(formValues);
        // console.log("sendCurrentDataFormToDraft dataForSend", dataForSend);

        if (draftIds.length > 0 && isNewBasedOnOld === false) {
          const requestData = dataForSend.find(
            (propsalData, index) => +propsalData?.proposalId === propsalId
          );
          const requestDataIndex = dataForSend.findIndex(
            (propsalData, index) => +propsalData?.proposalId === propsalId
          );
          // console.log("sendCurrentDataFormToDraft requestData", requestData);
          if (!requestData) return;
          // добавляем в поле-модалку промежуточно полученные значения модалки
          const tempData = { ...requestData.data };
          if (
            currentModalData?.propsalId &&
            currentModalData?.modalName &&
            currentModalValues &&
            +requestData.proposalId === currentModalData?.propsalId
          ) {
            const modalForValidate =
              handleFieldsModalValidation(currentModalValues);
            if (!Array.isArray(modalForValidate)) {
              tempData[currentModalData?.modalName] = currentModalData?.isMultiple
                ? JSON.stringify([modalForValidate])
                : JSON.stringify(modalForValidate);
            }
          }
          const requestBody = {
            draft: true,
            id: [draftIds[requestDataIndex]],
            data: tempData,
            users: managerId ? [userId, managerId] : [userId],
          };
          await leadsApi.saveDraft(requestBody);
          dataForSend.forEach((elem) =>
            deleteProposalIdFromCookie(+elem.proposalId)
          );
        } else {
          let requests: Promise<SendNewOrderResponse>[];
          requests = dataForSend.map((propsalData) => {
            const requestBody = {
              draft: true,
              proposalIds: [propsalData.proposalId],
              data: propsalData.data,
              users: managerId ? [userId, managerId] : [userId],
            };
            return leadsApi.sendNewOrder(requestBody);
          });
          return Promise.all(requests)
            .then((response) => {
              dataForSend.forEach((elem) =>
                deleteProposalIdFromCookie(+elem.proposalId)
              );
              const draftIds = response.map((draftPropsal) => {
                return draftPropsal.ids[0];
              });
              actions.pushDataDraftForOrder(draftIds);
              actions.pushIsNewBasedOnOldForOrder(false);
            })
            .catch((err) => {
              console.log({ err });
              if (err.response && err.response.status) {
                introspectService.checkToken(err.response.status.toString());
              }
              if (err.response && err.response.data && err.response.data.errors) {
                const text = Array.isArray(err.response.data.errors)
                  ? err.response.data.errors.map((i: any) => i.message).join(" ")
                  : err.response.data.errors.message;
                actions.setAlertModal({
                  tittle: "Что-то пошло не так",
                  text,
                  isError: true,
                  confModal: false,
                  draftModal: true,
                  isRemain: true,
                  isPromptShow: false,
                });
              } else {
                actions.setAlertModal({
                  tittle: "Что-то пошло не так",
                  text: "Мы уже в курсе проблемы, и работаем над ее решением!",
                  isError: true,
                  confModal: false,
                  draftModal: true,
                  isRemain: true,
                  isPromptShow: false,
                });
              }
            });
        }
      } catch (e) {
        actions.setAlertModal({
          tittle: "Что-то пошло не так",
          text: "Мы уже в курсе проблемы, и работаем над ее решением!",
          isError: true,
          confModal: true,
          draftModal: false,
          isRemain: false,
          isPromptShow: false,
        });
        console.log("Error:sendDataForDraft -->", e);
      }
    };


/**Функция сохранения текущих изменений в черновик (по ходу заполнеия форму при покидании инпута без закрытия модалки) */
export const sendCurrentDataFormToDraftNEW =
  (propsalId: number, realPropsalsId?: number[]) =>
    async (dispatch: ThunkAppDispatch, getState: GetStateType) => {
      try {

        const isNewBasedOnOld = getState().dataForOrder.isNewBasedOnOld;
        const formValues = getState().newOrder.formValues;
        // console.log("***ToDraftNEW formValues", formValues);

        const formData = getState().newOrder.formData;
        // console.log("***ToDraftNEW formData", formData);
        if (!formValues || !formData) return;

        const userId = getState().currentUser.userId;
        const draftIds = getState().dataForOrder.draftIds;
        const managerId = getState().currentUser.manager?.id;
        console.log("***ToDraftNEW draftIds", draftIds);

        const currentModalData = getState().newOrder?.currentModalData;
        const currentModalValues = getState().newOrder?.currentEditModal?.values;
        console.log("***ToDraftNEW currentModalData", currentModalData);
        if (currentModalData?.modalName) {
          console.log("***ToDraftNEW 2 currentModalData", currentModalData);
          // setModalValueFieldOnChange(); //add
          const modalValues = getState().newOrder.currentEditModal;
          console.log("***ToDraftNEW modalValues", modalValues);
          const modalData = currentModalData;
          if (modalValues.values && modalValues.errors) {
            if (
              modalData.propsalId &&
              modalValues.values &&
              modalValues.errors &&
              modalData.modalName
            ) {
              console.log("***ToDraftNEW 1 actions.setModalField", modalValues.values);
              actions.setModalField({
                propsalId: modalData.propsalId,
                fieldName: modalData.modalName,
                value: modalValues.values,
                errors: modalValues.errors,
              });
            }
          }
        }

        // console.log("-----***ToDraftNEW currentModalValues", currentModalValues);
        const realProposalIds = getState().dataForOrder.realProposalIds;
        // console.log("***ToDraftNEW realProposalIds", realProposalIds)
        const { dataForSend } = handleFieldsForSendNEW(formValues, formData, realProposalIds);
        // console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!***ToDraftNEW dataForSend", dataForSend);

        console.log("***ToDraftNEW dataForSend", dataForSend)

        // добавляем в поле-модалку промежуточно полученные значения модалки
        let currentModalValuesForSend: string | undefined = undefined;
        if (
          currentModalData?.modalName &&
          currentModalValues
        ) {
          const modalForValidate = handleFieldsModalValidation(currentModalValues);
          console.log("***ToDraftNEW modalForValidate", modalForValidate)

          currentModalValuesForSend = JSON.stringify(currentModalData?.isMultiple && Array.isArray(modalForValidate)
            ? modalForValidate
            : Array.isArray(modalForValidate) ? modalForValidate?.[0]
              : modalForValidate);
          if (currentModalValuesForSend && currentModalData.modalName) {
            console.log("***ToDraftNEW currentModalValuesForSend", currentModalValuesForSend)
            console.log("***ToDraftNEW currentModalData?.proposals", currentModalData?.proposals)
            dataForSend.forEach(el => {
              console.log("***ToDraftNEW el.proposalId", el.proposalId)
              if (currentModalData?.proposals && currentModalData.proposals.find(modPrId => el.proposalId === modPrId)) {
                const tempData: any = { ...el.data };
                if (currentModalData.modalName) {
                  tempData[currentModalData.modalName] = currentModalValuesForSend
                };
                el.data = tempData;
              }
            })
          }
        }

        //********************************************** */ !!!!если уже есть черновики
        if (draftIds.length > 0 && isNewBasedOnOld === false) {

          const requestDataArray: ValidateForm[] = dataForSend.filter(
            (propsalData) => realPropsalsId?.includes(+propsalData?.proposalId)
          );
          console.log("***ToDraftNEW requestDataArray", requestDataArray)
          if (requestDataArray.length === 0) return;

          requestDataArray.forEach(async (requestData, index) => {

            const draftIndex = realProposalIds.findIndex(el => el === +requestData.proposalId)

            const requestBody = {
              draft: true,
              id: [draftIds[draftIndex]],
              // data: tempData,
              data: requestData.data,
              users: managerId ? [userId, managerId] : [userId],
            };
            console.log("***ToDraftNEW requestBody elem", requestBody);
            try {
              await leadsApi.saveDraft(requestBody);
            } catch (e) {
              console.log("saveDraftError", e)
            }
          })
          // при удачном раскладе удаляем из кук
          dispatch(clearCart());
          console.log('clear cart on draft creation')
          dataForSend.forEach((elem) =>
            deleteProposalIdFromCookie(+elem.proposalId)
          );
        } else {

          // ******************************************!!!!если первый раз сохраняем
          console.log("*******ToDraftNEW FIRST SAVE dataForSend", dataForSend)
          let requests: Promise<SendNewOrderResponse>[];
          requests = dataForSend.map((propsalData) => {
            const requestBody = {
              draft: true,
              proposalIds: [propsalData.proposalId],
              data: propsalData.data,
              users: managerId ? [userId, managerId] : [userId],
            };
            return leadsApi.sendNewOrder(requestBody);
          });


          return Promise.all(requests)
            .then((response) => {
              dataForSend.forEach((elem) =>
                deleteProposalIdFromCookie(+elem.proposalId)
              );
              const draftIds = response.map((draftPropsal) => {
                return draftPropsal.ids[0];
              });
              actions.pushDataDraftForOrder(draftIds);
              actions.pushIsNewBasedOnOldForOrder(false);
            })
            .catch((err) => {
              console.log({ err });
              if (err.response && err.response.status) {
                introspectService.checkToken(err.response.status.toString());
              }
              if (err.response && err.response.data && err.response.data.errors) {
                const text = Array.isArray(err.response.data.errors)
                  ? err.response.data.errors.map((i: any) => i.message).join(" ")
                  : err.response.data.errors.message;
                actions.setAlertModal({
                  tittle: "Что-то пошло не так",
                  text,
                  isError: true,
                  confModal: false,
                  draftModal: true,
                  isRemain: true,
                  isPromptShow: false,
                });
              } else {
                actions.setAlertModal({
                  tittle: "Что-то пошло не так",
                  text: "Мы уже в курсе проблемы, и работаем над ее решением!",
                  isError: true,
                  confModal: false,
                  draftModal: true,
                  isRemain: true,
                  isPromptShow: false,
                });
              }
            });
        }
      } catch (e) {
        actions.setAlertModal({
          tittle: "Что-то пошло не так",
          text: "Мы уже в курсе проблемы, и работаем над ее решением!",
          isError: true,
          confModal: true,
          draftModal: false,
          isRemain: false,
          isPromptShow: false,
        });
        console.log("Error:sendDataForDraft -->", e);
      } finally {
        dispatch(clearCart());
        console.log('clear cart on draft creation on finally')
      }
    };


/**Валидация одного поля NEW*/
export const validateField =
  (rd: ValidateForThunkForm, propsalIds: number[], stepId: number, indexGroupField?: number) => async (dispatch: ThunkAppDispatch, getState: GetStateType) => {
    // const formValues = getState().newOrder.formValues;
    // if (!formValues) return;
    // const { dataForSend } = handleFieldsForSend(formValues);
    const { formData, formValues } = getState().newOrder;
    if (!formValues || !formData) return;
    const realProposalIds = getState().dataForOrder.realProposalIds;
    const { dataForSend } = handleFieldsForSendNEW(formValues, formData, realProposalIds);
    // console.log("validateField dataForSend", dataForSend)

    const currentModalData = getState().newOrder?.currentModalData;
    const currentModalValues = getState().newOrder?.currentEditModal?.values;
    // console.log("validateField propsalIds", propsalIds)
    const data = dataForSend.find((elem) => elem?.proposals?.[0]?.id && propsalIds.includes(elem?.proposals?.[0]?.id));
    if (!data) return;

    const tempData = { ...data.data };

    // добавляем в поле-модалку промежуточно полученные значения модалки
    let currentModalValuesForSend: string | undefined = undefined;
    if (
      currentModalData?.modalName &&
      currentModalValues
    ) {
      const modalForValidate = handleFieldsModalValidation(currentModalValues);
      currentModalValuesForSend = JSON.stringify(currentModalData?.isMultiple && Array.isArray(modalForValidate) ? modalForValidate : Array.isArray(modalForValidate) ? modalForValidate?.[0] : modalForValidate);
      // if (currentModalData?.proposals?.includes(+requestData.proposalId))

      tempData[currentModalData?.modalName] = currentModalValuesForSend;
    }

    const rdwithData: ValidateForm = {
      data: tempData,
      ...rd,
    };
    // if (rd.parentFieldId){

    // }
    try {
      const res = await leadsService.validateV5(rdwithData);
      // console.log("validateV5 res", res);
      if (rdwithData?.parentFieldId) {
        dispatch(handleErrorsModalFromServerNEWForOneField(res, indexGroupField, rdwithData.fieldName))
      } else {
        dispatch(handleErrorsPropsalFromServerForOneField(res, stepId, rd?.fieldName ?? ""));
      }
    } catch (e) {
      console.log(e);
    }
  };

/**Валидация шага  NEW*/
export const validateStep =
  (rd: ValidateStepForThunkForm, propsalIds: number[]) => async (dispatch: ThunkAppDispatch, getState: GetStateType) => {
    const { formData, formValues } = getState().newOrder;
    if (!formValues || !formData) return;
    const realProposalIds = getState().dataForOrder.realProposalIds;
    console.log("validateStep realProposalIds", realProposalIds)
    const { dataForSend } = handleFieldsForSendNEW(formValues, formData, realProposalIds);
    console.log("validateStep dataForSend", dataForSend)
    const data = dataForSend.find((elem) => elem?.proposals?.[0]?.id && propsalIds.includes(elem?.proposals?.[0]?.id));
    console.log("validateStep dataForSend", dataForSend)
    const currentModalData = getState().newOrder?.currentModalData;
    const currentModalValues = getState().newOrder?.currentEditModal?.values;

    if (!data) return;

    const tempData = { ...data.data };

    // добавляем в поле-модалку промежуточно полученные значения модалки
    let currentModalValuesForSend: string | undefined = undefined;
    if (
      currentModalData?.modalName &&
      currentModalValues
    ) {
      const modalForValidate = handleFieldsModalValidation(currentModalValues);
      currentModalValuesForSend = JSON.stringify(currentModalData?.isMultiple && Array.isArray(modalForValidate) ? modalForValidate : Array.isArray(modalForValidate) ? modalForValidate?.[0] : modalForValidate);
      tempData[currentModalData?.modalName] = currentModalValuesForSend;
    }

    const rdwithData: ValidateForm = {
      data: tempData,
      ...rd,
    };

    try {
      const res = await leadsService.validateV5(rdwithData);
      const newformData = [...formData];
      const { parent, child } = getParentAndChild(formData, rd?.stepId);
      newformData.forEach(elem => {
        // if (el?.id === rd.stepId) {
        //   el.isFill = res?.isValid ?? false;
        //   el.isMissed = !res?.isValid;
        // }
        if (elem?.id === parent?.id) {
          if (child && parent?.innerSteps) {
            const newInnerSteps = [...parent.innerSteps];
            newInnerSteps.forEach(el => {
              if (el.id === rd.stepId) {
                el.isFill = res?.isValid ?? false;
                el.isMissed = !res.isValid
              }
            })
          } else {
            elem.isFill = res?.isValid ?? false;
            elem.isMissed = !res?.isValid;
          }
        }
      })
      actions.setFormData(newformData)
      if (res?.isValid) {
        // actions.setFormData(newformData)
      } else {
        // dispatch(handleErrorsPropsalFromServerForStep(res, rd.stepId));

        const propsal = {
          errors: res.errors,
          isValid: res.isValid,
          proposalId: rd.stepId,
        };

        dispatch(handleErrorsPropsalFromServerNEW(propsal));
      }
      return res;
    } catch (e) {
      console.log(e);
    }
  };

/**Функция сохранения текущих изменений в черновик (по ходу заполнеия форму при покидании инпута без закрытия модалки) */
export const sendCurrentDataFormToDraftAndValidate =
  (propsalId: number) =>
    async (dispatch: ThunkAppDispatch, getState: GetStateType) => {
      // console.log("!!!!!sendCurrentDataFormToDraft");
      try {
        const isNewBasedOnOld = getState().dataForOrder.isNewBasedOnOld;
        const formValues = getState().newOrder.formValues;
        if (!formValues) return;
        const userId = getState().currentUser.userId;
        const draftIds = getState().dataForOrder.draftIds;
        const managerId = getState().currentUser.manager?.id;
        const currentModalData = getState().newOrder?.currentModalData;
        const currentModalValues = getState().newOrder?.currentEditModal?.values;

        const { dataForSend } = handleFieldsForSend(formValues);

        if (draftIds.length > 0 && isNewBasedOnOld === false) {
          const requestData = dataForSend.find(
            (propsalData, index) => propsalData?.proposalId && +propsalData?.proposalId === propsalId
          );
          const requestDataIndex = dataForSend.findIndex(
            (propsalData, index) => propsalData?.proposalId && +propsalData?.proposalId === propsalId
          );
          if (!requestData) return;
          // добавляем в поле-модалку промежуточно полученные значения модалки
          const tempData = { ...requestData.data };
          if (
            currentModalData?.propsalId &&
            currentModalData?.modalName &&
            currentModalValues &&
            +requestData.proposalId === currentModalData?.propsalId
          ) {
            const modalForValidate =
              handleFieldsModalValidation(currentModalValues);
            if (!Array.isArray(modalForValidate)) {
              tempData[currentModalData?.modalName] = currentModalData?.isMultiple
                ? JSON.stringify([modalForValidate])
                : JSON.stringify(modalForValidate);
            }
          }
          const requestBody = {
            draft: true,
            id: [draftIds[requestDataIndex]],
            data: tempData,
            users: managerId ? [userId, managerId] : [userId],
          };
          await leadsApi.saveDraft(requestBody);
          dataForSend.forEach((elem) =>
            deleteProposalIdFromCookie(+elem.proposalId)
          );
        } else {
          let requests: Promise<SendNewOrderResponse>[];
          requests = dataForSend.map((propsalData) => {
            const requestBody = {
              draft: true,
              proposalIds: [propsalData.proposalId],
              data: propsalData.data,
              users: managerId ? [userId, managerId] : [userId],
            };
            return leadsApi.sendNewOrder(requestBody);
          });
          return Promise.all(requests)
            .then((response) => {
              dataForSend.forEach((elem) =>
                deleteProposalIdFromCookie(+elem.proposalId)
              );
              const draftIds = response.map((draftPropsal) => {
                return draftPropsal.ids[0];
              });
              actions.pushDataDraftForOrder(draftIds);
              actions.pushIsNewBasedOnOldForOrder(false);
            })
            .catch((err) => {
              console.log({ err });
              if (err.response && err.response.status) {
                introspectService.checkToken(err.response.status.toString());
              }
              if (err.response && err.response.data && err.response.data.errors) {
                const text = Array.isArray(err.response.data.errors)
                  ? err.response.data.errors.map((i: any) => i.message).join(" ")
                  : err.response.data.errors.message;
                actions.setAlertModal({
                  tittle: "Что-то пошло не так",
                  text,
                  isError: true,
                  confModal: false,
                  draftModal: true,
                  isRemain: true,
                  isPromptShow: false,
                });
              } else {
                actions.setAlertModal({
                  tittle: "Что-то пошло не так",
                  text: "Мы уже в курсе проблемы, и работаем над ее решением!",
                  isError: true,
                  confModal: false,
                  draftModal: true,
                  isRemain: true,
                  isPromptShow: false,
                });
              }
            });
        }
      } catch (e) {
        actions.setAlertModal({
          tittle: "Что-то пошло не так",
          text: "Мы уже в курсе проблемы, и работаем над ее решением!",
          isError: true,
          confModal: true,
          draftModal: false,
          isRemain: false,
          isPromptShow: false,
        });
        console.log("Error:sendDataForDraft -->", e);
      }
    };

/**Отправка заявки */
export const sendDataForm =
  () => async (dispatch: ThunkAppDispatch, getState: GetStateType) => {
    // console.log("!!!sendDataForm");
    try {
      await dispatch(validateForm());
      actions.setIsFetching({
        isFetching: true,
      });
      const formValues = getState().newOrder.formValues;
      const countErrors = getState().newOrder.countErrors;
      const isNewBasedOnOld = getState().dataForOrder.isNewBasedOnOld;
      if (countErrors === 0 && formValues) {
        const userId = getState().currentUser.userId;
        const draftIds = getState().dataForOrder.draftIds;
        const managerId = getState().currentUser.manager?.id;
        const { dataForSend } = handleFieldsForSend(formValues);
        let requests: Promise<SendNewOrderResponse>[];
        if (draftIds.length > 0 && isNewBasedOnOld === false) {
          requests = dataForSend.map((propsalData, index) => {
            const requestBody = {
              draft: false,
              id: [draftIds[index]],
              data: propsalData.data,
              users: managerId ? [userId, managerId] : [userId],
            };
            return leadsApi.saveDraft(requestBody);
          });
        } else {
          requests = dataForSend.map((propsalData) => {
            const requestBody = {
              draft: false,
              proposalIds: [propsalData.proposalId],
              data: propsalData.data,
              users: managerId ? [userId, managerId] : [userId],
            };
            return leadsApi.sendNewOrder(requestBody);
          });
        }
        const promises = requests.map((request) => delayMyPromise(request));
        return Promise.all(promises)
          .then((response) => {
            actions.setIsFetching({
              isFetching: false,
            });
            actions.pushIsNewBasedOnOldForOrder(false);
            actions.setAlertModal({
              tittle: "Ваша заявка создана!",
              text: "Отлично! Мы приняли вашу заявку, теперь вы можете отслеживать ее статус в разделе «Продукты» и загружать необходимые документы в подробном описании заявки.",
              isError: false,
              confModal: true,
              draftModal: false,
              isRemain: false,
              isPromptShow: false,
            });
          })
          .catch((err) => {
            console.log({ err });
            actions.setIsFetching({
              isFetching: false,
            });
            if (err.response && err.response.data && err.response.data.errors) {
              const text = Array.isArray(err.response.data.errors)
                ? err.response.data.errors.map((i: any) => i.message).join(" ")
                : err.response.data.errors.message;
              actions.setAlertModal({
                tittle: "Что-то пошло не так",
                text,
                isError: true,
                confModal: true,
                draftModal: false,
                isRemain: true,
                isPromptShow: false,
              });
            } else {
              actions.setAlertModal({
                tittle: "Что-то пошло не так",
                text: "Мы уже в курсе проблемы, и работаем над ее решением!",
                isError: true,
                confModal: true,
                draftModal: false,
                isRemain: true,
                isPromptShow: false,
              });
            }
          });
      } else {
        actions.setIsFetching({
          isFetching: false,
        });
      }
    } catch (err: any) {
      console.log({ err });
      actions.setIsFetching({
        isFetching: false,
      });
      if (err.response && err.response.data && err.response.data.errors) {
        const text = Array.isArray(err.response.data.errors)
          ? err.response.data.errors.map((i: any) => i.message).join(" ")
          : err.response.data.errors.message;
        actions.setAlertModal({
          tittle: "Что-то пошло не так",
          text,
          isError: true,
          confModal: true,
          draftModal: false,
          isRemain: false,
          isPromptShow: false,
        });
      } else {
        actions.setAlertModal({
          tittle: "Что-то пошло не так",
          text: "Мы уже в курсе проблемы, и работаем над ее решением!",
          isError: true,
          confModal: true,
          draftModal: false,
          isRemain: false,
          isPromptShow: false,
        });
      }
    }
  };

export enum EResult {
  success = "success",
  error = "error",
  errorCommon = "errorCommon"
}

/**Отправка заявки */
export const sendDataFormNEW =
  () => async (dispatch: ThunkAppDispatch, getState: GetStateType) => {

    console.log("!!!sendDataForm");
    try {
      await dispatch(validateFormNEW());

      actions.setIsFetching({
        isFetching: true,
      });
      const formValues = getState().newOrder.formValues;
      const formData = getState().newOrder.formData;
      const currentModalData = getState().newOrder?.currentModalData;
      const currentModalValues = getState().newOrder?.currentEditModal?.values;

      const countErrors = getState().newOrder.countErrors;
      const isNewBasedOnOld = getState().dataForOrder.isNewBasedOnOld;
      if (
        countErrors === 0 &&
        formValues && formData) {
        const userId = getState().currentUser.userId;
        const draftIds = getState().dataForOrder.draftIds;
        const managerId = getState().currentUser.manager?.id;
        const realProposalIds = getState().dataForOrder.realProposalIds;
        const { dataForSend } = handleFieldsForSendNEW(formValues, formData, realProposalIds);
        console.log("sendDataFormNEW dataForSend", dataForSend)

        // добавляем в поле-модалку промежуточно полученные значения модалки
        let currentModalValuesForSend: string | undefined = undefined;
        if (
          currentModalData?.modalName &&
          currentModalValues
        ) {
          const modalForValidate = handleFieldsModalValidation(currentModalValues);
          currentModalValuesForSend = JSON.stringify(currentModalData?.isMultiple && Array.isArray(modalForValidate)
            ? modalForValidate
            : Array.isArray(modalForValidate) ? modalForValidate?.[0]
              : modalForValidate);
          if (currentModalValuesForSend && currentModalData.modalName) {
            dataForSend.forEach(el => {
              if (currentModalData?.proposals && currentModalData.proposals.includes(+el.proposalId)) {
                const tempData: any = { ...el.data };
                if (currentModalData.modalName) {
                  tempData[currentModalData.modalName] = currentModalValuesForSend
                };
                el.data = tempData;
              }
            })
          }
        }

        let requests: Promise<SendNewOrderResponse>[];
        if (draftIds.length > 0 && isNewBasedOnOld === false) {
          requests = dataForSend.map((propsalData, index) => {
            const requestBody = {
              draft: false,
              id: [draftIds[index]],
              data: propsalData.data,
              users: managerId ? [userId, managerId] : [userId],
            };
            return leadsApi.saveDraft(requestBody);
          });
        } else {
          requests = dataForSend.map((propsalData) => {
            const requestBody = {
              draft: false,
              proposalIds: [propsalData.proposalId],
              data: propsalData.data,
              users: managerId ? [userId, managerId] : [userId],
            };
            return leadsApi.sendNewOrder(requestBody);
          });
        }
        const promises = requests.map((request) => delayMyPromise(request));
        return Promise.all(promises)
          .then((response) => {
            actions.setIsFetching({
              isFetching: false,
            });
            actions.pushIsNewBasedOnOldForOrder(false);
            // удаление из кук
            dispatch(clearCart());
            console.log('clear cart on sendDataFormNEW')
            tokenService._setCookie(
              CookiesEnum.cart,
              JSON.stringify([]),
              1,
              "/"
            );
            // actions.setAlertModal({
            //   tittle: "Ваша заявка создана!",
            //   text: "Отлично! Мы приняли вашу заявку, теперь вы можете отслеживать ее статус в разделе «Продукты» и загружать необходимые документы в подробном описании заявки.",
            //   isError: false,
            //   confModal: true,
            //   draftModal: false,
            //   isRemain: false,
            //   isPromptShow: false,
            // });
            return EResult.success
          })
          .catch((err) => {
            console.log({ err });
            actions.setIsFetching({
              isFetching: false,
            });
            if (err.response && err.response.data && err.response.data.errors) {
              const text = Array.isArray(err.response.data.errors)
                ? err.response.data.errors.map((i: any) => i.message).join(" ")
                : err.response.data.errors.message;
              actions.setAlertModal({
                tittle: "Что-то пошло не так",
                text,
                isError: true,
                confModal: true,
                draftModal: false,
                isRemain: true,
                isPromptShow: false,
              });
              return text;
            } else {
              actions.setAlertModal({
                tittle: "Что-то пошло не так",
                text: "Мы уже в курсе проблемы, и работаем над ее решением!",
                isError: true,
                confModal: true,
                draftModal: false,
                isRemain: true,
                isPromptShow: false,
              });
              return EResult.error;
            }
          });
      } else {
        actions.setIsFetching({
          isFetching: false,
        });
      }
    } catch (err: any) {
      console.log({ err });
      actions.setIsFetching({
        isFetching: false,
      });
      if (err.response && err.response.data && err.response.data.errors) {
        const text = Array.isArray(err.response.data.errors)
          ? err.response.data.errors.map((i: any) => i.message).join(" ")
          : err.response.data.errors.message;
        actions.setAlertModal({
          tittle: "Что-то пошло не так",
          text,
          isError: true,
          confModal: true,
          draftModal: false,
          isRemain: false,
          isPromptShow: false,
        });
        return text
      } else {
        actions.setAlertModal({
          tittle: "Что-то пошло не так",
          text: "Мы уже в курсе проблемы, и работаем над ее решением!",
          isError: true,
          confModal: true,
          draftModal: false,
          isRemain: false,
          isPromptShow: false,
        });
        return EResult.errorCommon;
      }
    }
  };
/**Удаление предложения по id */
export const deletePropsal =
  (propsalId: number) =>
    async (dispatch: ThunkAppDispatch, getState: GetStateType) => {
      const formValues = getState().newOrder.formValues;
      const formData = getState().newOrder.formData;
      const formErrors = getState().newOrder.errorsFields;

      if (formValues && formErrors && formData) {
        const propsalIds = Object.keys(formValues)
          .filter((id) => id !== String(propsalId))
          .map((val) => Number(val));
        const newFromValues = filterObject(formValues, propsalIds);
        const newFromErrors = filterObject(formErrors, propsalIds);
        const newFormData = formData.filter((data) => data.id !== propsalId);

        // для удаления черновика из списка
        const draftIds = getState().dataForOrder.draftIds;
        let propsalIdsIndex = Object.keys(formValues).findIndex(
          (id) => id === String(propsalId)
        );
        if (draftIds.length > 0) {
          if (Object.keys(formValues)?.[0] === "0") {
            propsalIdsIndex = propsalIdsIndex - 1;
          }
          const newDraftIds = draftIds.filter(
            (elem, index) => index !== propsalIdsIndex
          );
          dispatch(actions.pushDataDraftForOrder(newDraftIds));
        }

        // удаление из списка реальных id
        const realProposalIds = getState().dataForOrder.realProposalIds;
        const filterRealProposalsIds = realProposalIds.filter(el => el !== propsalId);
        dispatch(actions.pushRealProposalIdsForOrder(filterRealProposalsIds));


        actions.deleteFromFormPropsal({
          values: newFromValues,
          errors: newFromErrors,
          formData: newFormData,
        });
        actions.deleteBankFromBankList(propsalId);
      }
    };

/**Удаление предложения по id */
export const deletePropsalNEW =
  (propsalId: number) =>
    async (dispatch: ThunkAppDispatch, getState: GetStateType) => {
      const formValues = getState().newOrder.formValues;
      const formData = getState().newOrder.formData;
      const formErrors = getState().newOrder.errorsFields;
      if (formValues && formErrors && formData) {
        // const notCommonFormValues = Object.keys(formValues).filter(fv => +fv > 100)
        const notCommonFormDataIds = formData.filter(fd => fd.isCommon === false).map(el => el.id)

        // const propsalIds = Object.keys(formValues)
        //   .filter((id) => id !== String(propsalId))
        //   .map((val) => Number(val));
        const propsalIds = notCommonFormDataIds.filter((id) => id !== propsalId);

        // для удаления черновика из списка
        const draftIds = getState().dataForOrder.draftIds;
        // let propsalIdsIndex = Object.keys(formValues).findIndex(
        //   (id) => id === String(propsalId)
        // );
        let propsalIdsIndex = notCommonFormDataIds.findIndex(
          (id) => id === propsalId
        );
        let newDraftIds: number[] = [];
        if (draftIds.length > 0) {
          // if (Object.keys(formValues)?.[0] == "0") {
          //   propsalIdsIndex = propsalIdsIndex - 1;
          // }
          newDraftIds = draftIds.filter(
            (elem, index) => index !== propsalIdsIndex
          );
          // console.log("deletePropsalNEW newDraftIds", newDraftIds);
          dispatch(actions.pushDataDraftForOrder(newDraftIds));
        }
        // удаление из списка реальных id
        const realProposalIds = getState().dataForOrder.realProposalIds;
        const filterRealProposalsIds = realProposalIds.filter(el => el !== propsalId);
        dispatch(actions.pushRealProposalIdsForOrder(filterRealProposalsIds));

        // actions.deleteFromFormPropsal({
        //   values: newFromValues,
        //   errors: newFromErrors,
        //   formData: newFormData,
        // });
        // actions.deleteBankFromBankList(propsalId);

        // отправка данных и получение обновленных
        const propsalsIds = propsalIds;
        const innForOrder = getState().dataForOrder.inn;
        const draftLeadIds = newDraftIds;
        if (propsalsIds.length !== 0 || draftLeadIds.length !== 0) {
          const response = await leadsService.getLeadsBigFieldsService(
            propsalsIds,
            innForOrder,
            draftLeadIds
          );
          const { errorsData, formValues } = handleResponseForBigForm(response);
          actions.setFormValues(formValues);
          actions.setFormErrors(errorsData);
          actions.setFormData(response);
          actions.pushRealProposalIdsForOrder(propsalsIds);
        }
      }
    };

/**Установка предзаполненных данных */
export const handleAutoFillFields =
  (
    url: string,
    value: Field,
    propsalId: number,
    fieldName?: string,
    parentName?: string,
    indexGroupField?: number
  ) =>
    async (dispatch: ThunkAppDispatch, getState: GetStateType) => {
      const formValues = getState().newOrder.formValues;
      const draftIds = getState().dataForOrder.draftIds;
      const realProposalIds = getState().dataForOrder.realProposalIds;
      try {
        let requestUrl = url;
        if (url.includes("leadid") && formValues) {
          let propsalIdsIndex = realProposalIds.findIndex(id => Number(id) === Number(propsalId));
          let leadId;
          if (propsalIdsIndex >= 0 && propsalIdsIndex < draftIds.length) {
            leadId = draftIds[propsalIdsIndex];
          } else {
            leadId = draftIds[0] || null;
          }
          if (leadId && fieldName !== undefined) {
            requestUrl = `${url}${value}/${fieldName}/${leadId}`;
          } else {
            console.error("Lead ID or fieldName is undefined, cannot construct request URL.");
            return;
          }
        } else if (!url.includes("leadid")) {
          requestUrl = `${url}${value}`;
        }
        const fieldsValue = await leadsApi.sendRequestFromField<Record<string, string>>(requestUrl);

        if (!parentName) {
          const formValues = getState().newOrder.formValues;
          if (formValues) {
            const propsalValues = formValues[propsalId];
            Object.entries(fieldsValue).forEach(([name, fieldValue]) => {
              if (
                Object.keys(propsalValues).find((fieldName) => fieldName === name)
              ) {
                dispatch(
                  setValueField(
                    name,
                    fieldValue,
                    propsalId,
                    parentName,
                    indexGroupField
                  )
                );
              }
            });
          }
        } else {
          const modalValues = getState().newOrder.currentEditModal.values;
          if (modalValues && typeof indexGroupField !== 'undefined') {
            let targetObject = modalValues[indexGroupField];
            if (targetObject) {
              Object.entries(fieldsValue).forEach(([name, fieldValue]) => {
                if
                  (
                  Object.keys(targetObject).find((fieldName) => fieldName === name)
                ) {
                  actions.setValueFieldCurrentModal({
                    fieldName: name,
                    value: fieldValue,
                    modalIndex: indexGroupField,
                  });
                }
              });
            }
          }
        }
      } catch (e) {
        console.log("Field request-->", e);
      }
    };


/**Получение ошибок для текущего группового поля */
export const checkIsGroupFieldError =
  (
    field: FieldType,
    propsalId: number,
  ) => (dispatch: ThunkAppDispatch, getState: GetStateType) => {
    const errorsFieldsCurrent = getState().newOrder.errorsFields?.[propsalId];

    console.log("checkIsErrorField field.name", field.name);
    console.log("checkIsErrorField errorsFieldsCurrent", errorsFieldsCurrent);
    console.log(
      "checkIsErrorField errorsFieldsCurrent?.fields?.[field.name]",
      errorsFieldsCurrent?.fields?.[field.name]
    );
    if (!errorsFieldsCurrent) {
      return false;
    } else if (errorsFieldsCurrent.isErrors === false) {
      return false;
    } else {
      let isError: boolean = false;
      Object.entries(errorsFieldsCurrent.fields).forEach(([key, value]) => {
        if (key === field.name) {
          // перебираем все поля и ищем непустые значения
          if (Array.isArray(value)) {
            value.forEach((elem) => {
              Object.entries(elem).forEach(([k, v]) => {
                if (v?.length > 0) {
                  isError = true;
                }
              });
            });
          } else if (
            typeof value === "object" &&
            !Array.isArray(value) &&
            value !== null
          ) {
            Object.entries(value).forEach(([k, v]) => {
              if (v?.length > 0) {
                isError = true;
              }
            });
          }
        }
      });
      return isError;
    }
  }
