import {
    createStyles,
    makeStyles,
    Theme,
} from '@material-ui/core';

const useStylesManagerClients = makeStyles((theme: Theme) => createStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        padding: '40px 80px',
        margin: '0  auto',
        boxSizing: 'border-box',
        maxWidth: '100%',
    },
    box: {
        padding: '30px',
        borderRadius: '10px',
    },
    noLeadsWrap: {
        margin: '0 auto',
        paddingTop: '40px',
        paddingBottom: '40px',
        display: 'block',
        objectFit: 'cover',
        textAlign: 'center'
    },
    search: {
        maxWidth: '300px',
        width: '300px',
        height: '51px',
        justifyContent: 'end',
        '&>label': {
            top: '-7px'
        },
    },
    select: {
        width: '300px',
        height: '51px',
        '& div:focus': {
            backgroundColor: 'transparent',
        }
    },
    searchWrap: {
        display: 'flex',
        width: '90%',
        padding: '30px 30px 0 30px',
        flexWrap: 'wrap',
        gap: '15px',
    },
    filtersWrapper: {
        paddingTop: 0
    },
    checkBoxWrap: {
        padding: '25px 0 30px 15px',
        "&>label": {
            margin: '0',
            "&>span": {
                "&:first-child": {
                    padding: '0 10px 5px 0'
                }
            }
        }
    },
    countUsers: {
        paddingLeft: '40px'
    },
    addClientWrap: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'end',
        paddingTop: '30px',
    },
    clientsWrap: {
        // width: 'calc(100% - 60px)',
        // height: 'calc(100vh - 420px)',
        margin: '30px 0',
        overflow: 'auto',
        position: 'relative',
        scrollbarWidth: 'thin',
        maxWidth: '100%',
        boxShadow: 'none',
        '&::-webkit-scrollbar': {
            width: '0.5em',
            height: '0.52em',
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(208 217 222 / 88%)',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#D0D9DE',
            outline: '0.2px solid #D0D9DE',
        },
        '& div:last-child .MuiDivider-root': {
            display: "none",
        },
    },
    clientWrap: {
        width: '100%',
        backgroundColor: '#ffffff',
        '&:last-child': {
            borderBottomRightRadius: '10px',
            borderBottomLeftRadius: '10px',
        },
        '&:hover': {
            backgroundColor: '#F2F2F2',
            cursor: 'pointer',
        }
    },
    gridTitles: {
        display: 'flex',
        width: '100%',
        position: 'sticky',
        paddingTop: theme.spacing(3),
        top: 0,
        left: 0,
        zIndex: 15,
        backgroundColor: '#ffffff',
        borderRadius: '10px 0 0 0',
    },
    resetFiltersBtn: {
        padding: '0'
    },
    gridTitle: {
        color: '#617E8C',
        fontWeight: 'normal',
    },
    divider: {
        // margin: '25px 0',
    },
    optionSelect: {
        width: '100%',
        padding: '0',
        display: 'block',
    },
    selectAutocompleteWrapper: {
        marginTop: '25px'
    },
    selectAutocomplete: {
        width: '300px',
        '&>div': {
            '&>.MuiInputLabel-formControl': {
                top: 0
            },
            '&>.MuiInput-formControl': {
                marginTop: '5px'
            },
            '&>div': {
                '&>.MuiInputBase-input': {
                    marginTop: '15px',
                    marginBottom: '5px'
                },
                '&>.MuiAutocomplete-endAdornment': {
                    top: '12px'
                }
            }
        },
    },
    boxSelectOptions: {
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        '&:hover': {
            '&>p': {
                color: '#617E8C'
            }
        }
    },
    selectOptionsLabel: {
        paddingLeft: '15px',
        paddingTop: '15px',
        paddingBottom: '2px',
    },
    selectOptionsInn: {
        fontFamily: "Gilroy",
        fontWeight: 500,
        paddingLeft: '15px',
        paddingBottom: '15px'
    },
    pagination: {
        "& .MuiPaginationItem-page": {
            color: "#306F9A",
            border: "1px solid #306F9A",
            height: "50px",
            width: "50px",
            gap: "10px",
            fontSize: "16px",
            fontFamily: "Gilroy",
            fontWeight: 500,
            '&.Mui-selected': {
                background: "#306F9A",
                color: "#F2F2F2",
            },

        },

        "& li:last-child .MuiPaginationItem-page": {
            width: "125px",
            background: "#306F9A",
            color: '#F2F2F2',
        },

        "& li:last-child button::before": {
            content: "'Дальше'",
        },

        "& .MuiPaginationItem-page .MuiPaginationItem-icon": {
            display: 'none',
        },
    }
}))

export default useStylesManagerClients