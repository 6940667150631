import React, { memo } from "react";
import {
  Box,
  Typography,
  makeStyles,
  createStyles,
  Theme,
} from "@material-ui/core";
import { v4 } from "uuid";
import { leadStatusHistoryType } from "../../../../core/types/leadsApiTypes";
import { dateWithTimeConverter } from "../../../../core/helpers/dateConverter";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    statusesWrap: {
      padding: theme.spacing(3, 0),
    },
    statusWrap: {
      display: "flex",
      position: "relative",
      alignItems: "center",
      height: "50px",
      minHeight: "50px",
      "&:last-child div:last-child": {
        display: "none",
      },
    },
    statusBar: {
      width: "16px",
      height: "16px",
      minHeight: "16px",
      borderRadius: "50%",
      display: "block",
      border: "4px solid #E0E0E0",
      left: "0",
      backgroundColor: "#617E8C",
    },
    statusBarLine: {
      width: "2px",
      height: "100%",
      display: "block",
      backgroundColor: "#E0E0E0",
    },
    statusBarContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      height: "100%",
      marginTop: "50px",
    },
    statusDate: {
      color: "#617E8C",
      padding: "5px 10px",
      fontSize: "12px",
      backgroundColor: "transparent",
      margin: "15px 0 0",
    },
    statusActive: {
      backgroundColor: "#E0E0E0",
      borderRadius: "4px",
      color: "#ffffff",
      width: "max-content",
      padding: "5px",
      boxSizing: "border-box",
      margin: "15px 0 0 23px",
    },
  })
);

type propsType = {
  stages: leadStatusHistoryType[];
};

const Status: React.FC<propsType> = (props) => {
  const classes = useStyles();
  const capitalizeFirstLetter = (str: string) => {
    return str ? str.charAt(0).toUpperCase() + str.slice(1) : "No data";
  };

  return (
    <Box className={classes.statusesWrap}>
      {props.stages.map((item) => {
        return (
          item && (
            <Box key={v4()} className={classes.statusWrap}>
              <div className={classes.statusBarContainer}>
                <div className={classes.statusBar} />
                <div className={classes.statusBarLine} />
              </div>
              <Typography
                style={{background: item.statusColor ? `#${item.statusColor}`:  "#70B2E2", color:item.statusFontColor ? `#${item.statusFontColor}` : 'white', padding: "5px 10px"}}
                variant="body2"
                component="span"
                className={classes.statusActive}
              >
                {capitalizeFirstLetter(item.newStatus)}
              </Typography>
              <Typography
                variant="body2"
                component="span"
                className={classes.statusDate}
              >
                {dateWithTimeConverter(item.date)}
              </Typography>
            </Box>
          )
        );
      })}
    </Box>
  );
};

export default memo(Status);
