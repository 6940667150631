import React from 'react';

import clsx from 'clsx';

import { Typography } from '@material-ui/core';
import {
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

import { MessageNotification } from '../../../core/api/chatsApi';
import { DocIcon } from '../../../core/consts/images/DocIcon';
import Avatar from '../avatar';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    "@keyframes toastInTop": {
      from: {
        transform: "translateY(-100%)",
      },
      to: {
        transform: "translateY(0)",
      },
    },
    "@keyframes toastInBottom": {
      from: {
        transform: "translateY(100%)",
      },
      to: {
        transform: "translateY(0)",
      },
    },
    base: {
      width: "436px",
      marginBottom: "20px",
      cursor: "pointer",

      padding: "20px",
      borderRadius: "5px",
      background: "rgba(19, 19, 19, 0.5)",
      transition: ".6s ease",

      "&:hover": {
        background: "rgba(19, 19, 19, 0.8)",
      },
    },
    rightTop: {
      animation: "$toastInTop .7s",
    },
    rightBottom: {
      animation: "$toastInBottom .7s",
    },
    header: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      paddingBottom: "6px",
    },
    title: {
      color: "#FFFFFF",
      fontWight: 500,
    },
    closeBtn: {
      cursor: "pointer",
    },
    sender: {
      display: "grid",
      gridTemplateColumns: "40px 1fr",
      gridTemplateRows: "40px 1fr",
      gridTemplateAreas: "'avatar fullName''avatar bodyText'",
      columnGap: "10px",
    },
    avatar: {
      gridArea: "avatar",
      alignSelf: "start",
    },
    fullName: {
      alignSelf: "center",
      gridArea: "fullName",
      color: "#D0D9DE",
      fontWeight: 500,
    },
    bodyText: {
      gridArea: "bodyText",
      color: "#FFFFFF",
      overflow: "hidden",
      display: "-webkit-box",
      "-webkit-line-clamp": 3,
      "-webkit-box-orient": "vertical",
    },
    btnCommunication: {
      width: "100%",
      height: "51px",
      backgroundColor: "#FFFFFF",
    },
    fileContainer: {
      display: "flex",
      alignItems: "center",
      gap: "5px",
    },
    btnText: { color: "#131313", textTransform: "none" },
  })
);

type Props = {
  title?: string;
  bodyText: string;
  fullName: string;
  avatarUrl: string;
  position: "rightTop" | "rightBottom";
  message: MessageNotification;
  onClickMessage: (
    e: React.MouseEvent<HTMLElement>,
    massage: MessageNotification
  ) => void;
};

const NotificationMessage: React.FC<Props> = ({
  title = "Новое сообщение",
  bodyText,
  fullName,
  avatarUrl,
  position,
  message,
  onClickMessage,
}) => {
  const classes = useStyles();
  const [isVisible, setIsVisible] = React.useState<boolean>(true);

  const handleClose = (e: React.MouseEvent<HTMLElement>) => {
    setIsVisible(false);
    e.stopPropagation();
  };

  const handleOpenDialog = (e: React.MouseEvent<HTMLElement>) => {
    onClickMessage(e, message);
  };

  const isFile = bodyText.includes("filename=");

  return (
    <>
      {isVisible ? (
        <div
          onClick={handleOpenDialog}
          className={clsx(classes.base, classes[position])}
        >
          <div className={classes.header}>
            <Typography variant="body1" component="p" className={classes.title}>
              {title}
            </Typography>
            <div className={classes.closeBtn} onClick={handleClose}>
              <CloseIcon style={{ color: "#D0D9DE" }} />
            </div>
          </div>
          <div className={classes.sender}>
            <div className={classes.avatar}>
              <Avatar
                size="small"
                currentChatName={fullName}
                info={avatarUrl ? avatarUrl : fullName}
              />
            </div>
            <Typography
              variant="body2"
              component="p"
              className={classes.fullName}
            >
              {fullName}
            </Typography>

            {isFile ? (
              <div className={classes.fileContainer}>
                <DocIcon />
                <Typography
                  variant="body2"
                  component="p"
                  className={classes.bodyText}
                >
                  {bodyText.split("filename=")[1]}
                </Typography>
              </div>
            ) : (
              <Typography
                variant="body2"
                component="p"
                className={classes.bodyText}
              >
                {isFile ? <DocIcon /> : bodyText}
              </Typography>
            )}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default React.memo(NotificationMessage);
