import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const MainNavigationStyles = makeStyles((theme: Theme) =>
  createStyles({
    rootHeader: {
      flexGrow: 1,
      backgroundColor: "transparent",
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        flexGrow: 0,
      },
    },
    rootFooter: {
      flexGrow: 1,
      backgroundColor: "transparent",
    },
    containerHeader: {
      alignItems: "center",
      width: "100%",
    },
    containerFooter: {
      alignItems: "center",
      width: "100%",
      // [theme.breakpoints.down("md")]: {
      //
      // },
      [theme.breakpoints.down("xs")]: {
        justifyContent: "space-around",
        width: "100%",
        margin: "25px auto!important",
      },
      [theme.breakpoints.down(346)]: {},
    },
    navlinkFooter_content: {
      [theme.breakpoints.down(480)]: {
        textAlign: "center",
      },
      [theme.breakpoints.down(360)]: {
        textAlign: "left",
      },
      [theme.breakpoints.down("sm")]: {
        textAlign: "0 auto",
      },
    },
    navlinkHeaderDiv: {
      display: "none",
      [theme.breakpoints.down(1000)]: {
        display: "flex",
        fontSize: "16px",
        lineHeight: "24px",
        color: "#131313",
        whiteSpace: "nowrap",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        marginLeft: "0",
      },
    },
    navlinkHeaderProduct: {
      textDecoration: "none",
      fontSize: "16px",
      lineHeight: "130%",
      fontFamily: "Gilroy",
      fontWeight: 500,
      color: "#131313",
      whiteSpace: "nowrap",
      marginLeft: "25px",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        marginLeft: "0",
        display: "none",
      },
      "&:hover": {
        color: "#B0BFC6",
      },
    },
    phone: {
      marginLeft: "0px",
      [theme.breakpoints.down(1300)]: {
        display: "none",
      },
    },
    navlinkHeaderFirst: {
      [theme.breakpoints.down(1030)]: {
        marginLeft: '0px !important'
      }
    },
    navlinkHeader: {
      textDecoration: "none",
      fontSize: "16px",
      lineHeight: "24px",
      color: "#131313",
      whiteSpace: "nowrap",
      marginLeft: "25px",
      fontFamily: "Gilroy",
      fontWeight: 500,
      [theme.breakpoints.down(1100)]: {
        marginLeft: "20px",
      },
      [theme.breakpoints.down(1000)]: {
        marginLeft: "0",
      },
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        marginLeft: "0",
      },
      "&:hover": {
        color: "#B0BFC6",
      },
    },
    hiddenPhone: {
      [theme.breakpoints.down(1000)]: {
        display: "none",
      },
    },
    navlinkFooter: {
      textDecoration: "none",
      fontSize: "16px",
      lineHeight: "130%",
      color: "#131313",
      whiteSpace: "nowrap",
      marginBottom: "15px",
      [theme.breakpoints.down(360)]: {
        whiteSpace: "normal",
      },
    },
    navlinkFooter_title: {
      color: "#131313",
      fontFamily: "Gilroy",
      fontWeight: 600,
      fontSize: "16px",
      lineHeight: "130%",
      marginBottom: "15px",
      [theme.breakpoints.down(480)]: {
        textAlign: "center",
      },
      [theme.breakpoints.down(360)]: {
        textAlign: "left",
      },
    },
    navlinkFooter_container: {
      width: "370px",
      marginLeft: "80px",
      [theme.breakpoints.down(810)]: {
        marginLeft: "18px",
      },
      [theme.breakpoints.down(768)]: {
        width: "auto",
        marginLeft: "15px",
      },
    },
    navlinkFooter_wrapper: {
      display: "flex",
      [theme.breakpoints.down(655)]: {
        marginTop: "25px",
        display: "flex",
        justifyContent: "space-around",
        width: "100%",
      },
    },
    navlinkFooter_products: {
      display: "grid",
      gridTemplateColumns: "repeat(1, auto)",
      [theme.breakpoints.down("sm")]: {
        display: "flex",
        flexDirection: "column",
        gap: "5%",
      },
      [theme.breakpoints.down(480)]: {
        textAlign: "center",
      },
      [theme.breakpoints.down(360)]: {
        textAlign: "left",
      },
    },
    navlink_activeHeader: {
      position: "relative",
      "&:before": {
        content: '""',
        position: "absolute",
        bottom: "81px",
        height: "3px",
        width: "100%",
        display: "block",
        backgroundColor: "#309A90",
        left: "0",
        right: "0",
      },
      [theme.breakpoints.down("sm")]: {
        "&:before": {
          content: '""',
          position: "absolute",
          bottom: "0",
          height: "120%",
          width: "2px",
          display: "block",
          backgroundColor: "#309A90",
          left: "-25%",
        },
      },
    },
    arrow: {
      display: "none",
      [theme.breakpoints.down(1000)]: {
        display: "block",
        height: "24px",
      },
    },
    navlink_activeFooter: {
      color: "#B0BFC6",
    },
    menuModal: {
      position: "absolute",
      top: "20px",
      left: "-15px",
      paddingTop: "25px",
      height: "335px",
      width: "425px",
      zIndex: 2,
      display: "flex",
      justifyContent: "flex-end",
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    menuModalClient: {
      width: "389px !important",
      height: "210px !important",
    },
    menuModalContainer: {
      background: "white",
      padding: "22px 32px 32px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      width: "100%",
      maxWidth: "389px",
      height: "100%",
      boxShadow: "0px 5px 20px rgba(0, 0, 0, 0.05)",
      borderRadius: "5px",
    },
    linkName: {
      color: "#131313",
      fontFamily: "Gilroy",
      fontWeight: 500,
      fontSize: "16px",
      textDecoration: "none",
      marginLeft: "10px",
    },
    linkItem: {
      textDecoration: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginTop: "10px",
      position: "relative",
      "& div": {
        display: "flex",
        alignItems: "center",
      },
      "&:hover": {
        "& div": {
          color: "#B0BFC6 !important",
        },
        "& svg path": {
          fill: "#B0BFC6",
        },
        "& div svg path": {
          fill: "#B0BFC6",
        },
      },
    },
    linkWrap: {
      position: "relative",
      maxWidth: "fit-content",
      "& .navigation-arrow": {
        // position:'absolute',
        // right:'-25px',
      },
      "&:hover .navigation-arrow": {
        transform: "rotate(180deg)",
        animation: "$onRotate .3s",
        top: "3px",
      },
      [theme.breakpoints.down(1000)]: {
        padding: "10px 0",
        width: "100%",
        minWidth: "100%",
      },
    },
    animationBack: {
      animation: "$onRotateBack .3s",
    },
    "@keyframes onRotateBack": {
      from: {
        transform: "rotate(180deg)",
      },
      to: {
        transform: "rotate(0deg)",
      },
    },
    "@keyframes onRotate": {
      from: {
        transform: "rotate(0deg)",
        top: "0px",
      },
      to: {
        transform: "rotate(-180deg)",
        top: "3px",
      },
    },
    linkItemActive: {
      position: "absolute",
      left: "-33px",
      height: "100%",
      width: "3px",
      backgroundColor: "#309A90",
    },
    displayNone: {
      display: "none",
    },
  })
);
