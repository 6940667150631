import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

enum Method {
    Get = 'get',
    Delete = 'delete',
    Head = 'head',
    Options = 'options',
    Post = 'post',
    Put = 'put',
    Patch = 'patch',
}

type RequestConfig<Q, B> = Omit<AxiosRequestConfig, 'params' | 'data'> & {
    params?: Q;
    data?: B;
};

const requestMiddleware = async <Q, B, R>(config: RequestConfig<Q, B>): Promise<R> => {
    const axiosResponse = await axios.request<R>(config);
    return axiosResponse.data;
};

const requestMiddlewareWithHeader = async <Q, B, R>(config: RequestConfig<Q, B>): Promise<AxiosResponse<R>> => {
    const axiosResponse = await axios.request<R>(config);
    return axiosResponse;
};

const requestWithHeader = <Q, B, R>(config: RequestConfig<Q, B>) => requestMiddlewareWithHeader<Q, B, R>(config);

const request = <Q, B, R>(config: RequestConfig<Q, B>) => requestMiddleware<Q, B, R>(config);

const requestWithoutBody = (method: Method) => <Q, R>(url: string, query?: Q, token?: string) => {
    return request<Q, never, R>({
        method,
        url,
        params: query,
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
};

const requestWithBody = (method: Method) => <Q, B, R>(url: string, query?: Q, body?: B, token?: string) => {
    return request<Q, B, R>(
        token ? {
            method,
            url,
            params: query,
            data: body,
            headers: {
                Authorization: `Bearer ${token}`
            }
        } : {
            method,
            url,
            params: query,
            data: body,
        });
};

const requestWithParams = (method: Method) => <B, R>(url: string, paramsQuery?: any, body?: B, token?: string): Promise<R> => {
    return request(
        token ? {
            method,
            url,
            params: paramsQuery,
            data: body,
            headers: {
                Authorization: `Bearer ${token}`
            }
        } : {
            method,
            url,
            params: paramsQuery,
            data: body,
        });
}

const requestMultipartWithBody = (method: Method) => <Q, B, R>(url: string, query?: Q, body?: B, token?: string) => {
    return request<Q, B, R>(
        token ? {
            method,
            url,
            params: query,
            data: body,
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
            }
        } : {
            method,
            url,
            params: query,
            data: body,
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        });
};

const requestBlob = (method: Method) => <Q, B, R>(url: string, query?: Q, token?: string) => {
    return request<Q, B, R>(
        token ? {
            method,
            url,
            params: query,
            responseType: 'blob',
            headers: {
                Authorization: `Bearer ${token}`
            }
        } : {
            method,
            url,
            params: query,
            responseType: 'blob'
        });
};

const requestBlobWithHeader = (method: Method) => <Q, B, R>(url: string, query?: Q, token?: string) => {
    return requestWithHeader<Q, B, R>(
        token ? {
            method,
            url,
            params: query,
            responseType: 'blob',
            headers: {
                Authorization: `Bearer ${token}`
            }
        } : {
            method,
            url,
            params: query,
            responseType: 'blob'
        });
};

export const http = {
    get: requestWithoutBody(Method.Get),
    delete: requestWithBody(Method.Delete),
    head: requestWithoutBody(Method.Head),
    options: requestWithoutBody(Method.Options),
    post: requestWithBody(Method.Post),
    put: requestWithBody(Method.Put),
    putWithParams: requestBlobWithHeader(Method.Put),
    patch: requestWithBody(Method.Patch),
    getWithParams: requestWithParams(Method.Get),
    postMultiple: requestMultipartWithBody(Method.Post),
    getWithBlob: requestBlob(Method.Get),
    getWithBlobHeader: requestBlobWithHeader(Method.Get)
};