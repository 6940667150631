import React, { memo } from "react";
import { makeStyles, createStyles, Theme, Box, TextField, Select, MenuItem, FormControlLabel, Button, Checkbox, Typography } from "@material-ui/core";
import clsx from "clsx";
import { v4 } from "uuid";

const useStyles = makeStyles((theme: Theme) => createStyles({
    box: {
        padding: '0 30px'
    },
    search: {
        maxWidth: '300px',
        width: '18%',
        height: '51px',
        marginRight: '25px',
        justifyContent: 'end',
        '&>label': {
            top: '-7px'
        },
    },
    select: {
        width: '18%',
        marginRight: '15px',
        '& div:focus': {
            backgroundColor: 'transparent',
        }
    },
    searchWrap: {
        display: 'flex',
        width: '100%',
        padding: '30px',
        flexWrap: 'wrap',
    },
    checkBoxhWrap: {
        marginTop: '15px',
    },
}))

type PropsType = {
    statusList: string[] | undefined,
    productsList: string[] | undefined,
    setSearchStatus: (prev: string | ((prev: string) => string)) => void,
    searchStatus: string,
    setSearchProduct: (prev: string | ((prev: string | number) => string | number)) => void,
    searchProduct: number | string,
    searchInn: string,
    setSearchInn: (prev: string | ((prev: string) => string)) => void,
    searchPeriod: string,
    setSearchPeriod: (prev: string | ((prev: string) => string)) => void,
}

const Search: React.FC<PropsType> = (props) => {
    const classes = useStyles()

    const handleRemoveFilters = () => {
        props.setSearchInn('')
        props.setSearchPeriod('all')
        props.setSearchProduct('all')
        props.setSearchStatus('all')
    }

    const handleStatusChange = (e: any) => {
        props.setSearchStatus(e.target.value)
    }

    const handleProductChange = (e: any) => {
        props.setSearchProduct(e.target.value)
        props.setSearchStatus('all')
    }

    const handleInnChange = (e: any) => {
        if (e.target.value.match(/\d*$/) !== null) {
            props.setSearchInn(e.target.value)
        }
    }

    return (
        <Box className={clsx(classes.searchWrap, classes.box)}>
            <TextField
                label="ИНН клиента"
                color='primary'
                type="search"
                variant="standard"
                onChange={handleInnChange}
                className={classes.search}
                value={props.searchInn}
            />
            <Select
                color='secondary'
                value={props.searchProduct}
                className={classes.select}
                onChange={handleProductChange}
            >
                <MenuItem value={'all'}>Все продукты</MenuItem>
                {props.productsList && props.productsList.map((product, index) => {
                    return <MenuItem key={v4()} value={index}>{product}</MenuItem>
                })}
            </Select>
            <Select
                color='secondary'
                value={props.statusList?.indexOf(props.searchStatus) === -1 ? 'all' : props.searchStatus}
                className={classes.select}
                onChange={handleStatusChange}
            >
                <MenuItem value={'all'}>Все статусы завяки</MenuItem>
                {props.statusList && props.statusList.map((status) => {
                    return <MenuItem key={v4()} value={status}>{status}</MenuItem>
                })}
            </Select>
            <Select
                color='secondary'
                value={props.searchPeriod}
                className={classes.select}
                onChange={(e: any) => props.setSearchPeriod(e.target.value)}
            >
                <MenuItem value={'all'}>За все время</MenuItem>
                <MenuItem key={v4()} value={'day'}>За день</MenuItem>
                <MenuItem key={v4()} value={'week'}>За неделю</MenuItem>
                <MenuItem key={v4()} value={'month'}>За месяц</MenuItem>
                <MenuItem key={v4()} value={'year'}>За год</MenuItem>
            </Select>
            <Button
                variant='text'
                onClick={handleRemoveFilters}>
                <Typography variant="body1" component='span' style={{ color: '#306F9A', textTransform: 'none', lineHeight: '2' }}>
                    Сбросить фильтры
                </Typography>
            </Button>
            {/* <FormControlLabel className={classes.checkBoxhWrap} control={<Checkbox color="primary" checked={false} onChange={() => { alert('chanaged') }} />} label="Показать только ожидающие действий" /> */}
        </Box>
    )
}

export default memo(Search)
