import { ReactNode } from "react";
import "../../core/consts/redesign/global.scss";

type PropType = {
  icon: ReactNode | string;
  handleClick?: (...args: any[]) => void;
  handleClickEvent?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  title?: string;
  style?: React.CSSProperties;
  color?: "gray" | "blue" | "white" | "noBgBlack" | "noBgGray" | "noBgTransparent" | "noBgWhite" | "ghost";
  className?: string;
  ariaLabel?: string;
  buttonId?: string;
  ariaControls?: string | undefined;
  ariaExpanded?: boolean | undefined;
  ariaHasPopup?: "grid" | "listbox" | "menu" | "false" | "true" | "dialog" | "tree" | undefined;
  children?: ReactNode;
};

const colorClassMap = {
  blue: "icon_button_blue",
  white: "icon_button_white",
  gray: "icon_button_gray",
  ghost: "icon_button_ghost",
  noBgBlack: "icon_button_nobg_black",
  noBgGray: "icon_button_nobg_gray",
  noBgTransparent: "icon_button_nobg_gray_transparent",
  noBgWhite: 'icon_button_nobg_white'
};

const IconButtonMain = ({
  icon,
  handleClick,
  handleClickEvent,
  disabled = undefined,
  title,
  style,
  color,
  className,
  ariaLabel,
  buttonId,
  ariaControls,
  ariaExpanded,
  ariaHasPopup,
  children
}: PropType) => {
  const iconClassName = color ? colorClassMap[color] : "icon_button";
  const iconFullClass = className ? `${iconClassName} + ${className}` : `${iconClassName}`

  return (
    <button
      onClick={handleClick ? handleClick : handleClickEvent}
      style={style}
      title={title}
      className={iconFullClass}
      id={buttonId}
      disabled={disabled}
      aria-label={ariaLabel}
      aria-controls={ariaControls}
      aria-expanded={ariaExpanded}
      aria-haspopup={ariaHasPopup}
    >
      {icon}
      {children}
    </button>
  );
};

export default IconButtonMain;
