import { http } from "../infrastructure/Https";

const ROOT_URL = '/api/v1/reference'

export type Option = {
    key: string
    text: string
}

class ReferenceApi {
    getList(data: string) {
        return http.get(`${ROOT_URL}/${data}`)
    }
    getRegionsList() {
        return http.get(`${ROOT_URL}/regions`)
    }
    getCitiesList(code: string) {
        return http.get(`${ROOT_URL}/city/${code}`)
    }
    getCitiesVTBList(code: string) {
        return http.get(`${ROOT_URL}/cityvtb/${code}`)
    }
    getGarantTypesList() {
        return http.get(`${ROOT_URL}/garanttype`)
    }
    getContractTypesList() {
        return http.get(`${ROOT_URL}/contracttype`)
    }
    getOptions(key: string) {
        return http.get<null, Option[]>(`${ROOT_URL}/options/${key}`)
    }
    getIpoticaSlidersData(configName: string) {
        return http.get(`${ROOT_URL}/config/${configName}`)
    }
    getDocumentTypesList() {
        return http.get(`${ROOT_URL}/documenttype`)
    }
    getOrgFormTypeList() {
        return http.get(`${ROOT_URL}/orgformlist`)
    }
    getGarantFormTypeList() {
        return http.get(`${ROOT_URL}/garantform`)
    }
    getReputation() {
        return http.get(`${ROOT_URL}/reputation`)
    }
    getDeliveryCity(code: string) {
        return http.get(`${ROOT_URL}/fiascities/${code}`)
    }
    getDeliveryStreet(city: string) {
        return http.get(`${ROOT_URL}/fiasstreets`, {cityCode: city})
    }
}

const referenceApi = new ReferenceApi()
export default referenceApi

