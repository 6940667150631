import { Theme, Typography, createStyles, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import React, { memo } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import AvatarIcon from "../../../core/consts/images/avatarInner.png";
import { rootState } from "../../../core/types/rootState";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    userName: {
      width: "60px",
      height: "60px",
      backgroundColor: "#2B74B9",
      color: "#ffffff",
      textTransform: "uppercase",
      borderRadius: "50%",
      lineHeight: "2.5",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      // marginRight: '15px',
      boxSizing: "border-box",
      fontSize: "24px",
      letterSpacing: "0px",
      [theme.breakpoints.down("md")]: {
        fontSize: "20px",
        width: "50px",
        height: "50px",
        margin: "0 auto",
      },
    },
    userNameSmall: {
      width: "39px",
      height: "39px",
      fontSize: "16px",
      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
        width: "34px",
        height: "34px",
        margin: "0 auto",
      },
    },
    userNameMedium: {
      width: "45px",
      height: "45px",
      fontSize: "18px",
      [theme.breakpoints.down("md")]: {
        fontSize: "16px",
        width: "40px",
        height: "40px",
        margin: "0 auto",
      },
    },
    userNameClick: {
      "&:hover": {
        backgroundColor: "#204A66",
      },
    },
    avatar: {
      width: "48px",
      height: "48px",
      borderRadius: "50%",
      objectFit: "cover",
      [theme.breakpoints.down("md")]: {
        width: "50px",
        height: "50px",
      },
    },
    avatarSmall: {
      width: "39px",
      height: "39px",
    },
    avatarMedium: {
      width: "45px",
      height: "45px",
    },
    icon: {
      color: "#ffffff",
      height: "28px",
      width: "28px",
    },
  })
);

type PropsType = {
  info: string;
  url?: string;
  size?: string;
  currentChatName?: string;
};

const Avatar: React.FC<PropsType> = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const initialUser = useSelector((state: rootState) => state.currentUser);

  const name =
    props.info && typeof props.info === "string" ? props.info : "₽ $";

  const handleClick = () => {
    props.url && history.push(props.url);
  };

  return props.url ? (
    <div style={{ display: "inline-block" }} onClick={handleClick}>
      {name.indexOf("/") !== -1 || name.indexOf("\\") !== -1 ? (
        <img
          className={clsx(classes.avatar, {
            [classes.avatarMedium]: props.size === "medium",
            [classes.avatarSmall]: props.size === "small",
          })}
          alt={props.currentChatName ? props.currentChatName : "profile avatar"}
          src={`${name}`}
        />
      ) : (
        <Typography
          variant="h3"
          className={clsx(classes.userName, {
            [classes.userNameClick]: props.url,
            [classes.userNameMedium]: props.size === "medium",
            [classes.userNameSmall]: props.size === "small",
          })}
        >
          {initialUser.nameFull === "null null" ? (
            <img
              alt={
                props.currentChatName ? props.currentChatName : "profile avatar"
              }
              src={AvatarIcon}
            />
          ) : (
            <>
              {name[0]}
              {name.indexOf(" ") !== -1 && name[name.indexOf(" ") + 1]}
            </>
          )}
        </Typography>
      )}
    </div>
  ) : (
    <div style={{ display: "inline-block" }}>
      {name.indexOf("/") !== -1 || name.indexOf("\\") !== -1 ? (
        <img
          className={clsx(classes.avatar, {
            [classes.avatarMedium]: props.size === "medium",
            [classes.avatarSmall]: props.size === "small",
          })}
          alt={props.currentChatName ? props.currentChatName : "profile avatar"}
          src={`${name}`}
        />
      ) : (
        <Typography
          variant="h3"
          className={clsx(classes.userName, {
            [classes.userNameClick]: props.url,
            [classes.userNameMedium]: props.size === "medium",
            [classes.userNameSmall]: props.size === "small",
          })}
        >
          {initialUser.nameFull === "null null" ? (
            <img
              alt={
                props.currentChatName ? props.currentChatName : "profile avatar"
              }
              src={AvatarIcon}
            />
          ) : (
            <>
              {name[0]}
              {name.indexOf(" ") !== -1 && name[name.indexOf(" ") + 1]}
            </>
          )}
        </Typography>
      )}
    </div>
  );
};

export default memo(Avatar);
