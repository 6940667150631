import { InputAdornment, Tooltip } from "@material-ui/core";
import HelpTwoToneIcon from "@material-ui/icons/HelpTwoTone";
import { makeStyles, Theme, createStyles } from "@material-ui/core";

type HelperToolTipType = {
    title: string;
  };


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon : {
      fontSize: 14, 
      '&:hover': {
        color:'#B0BFC6'
      }
    }
  })
);
export const HelperToolTip: React.FC<HelperToolTipType> = ({ title }) => {
  const styles = useStyles();
    return (
      <InputAdornment position="end">
        <Tooltip title={title} placement="right">
          <HelpTwoToneIcon color='secondary' className={styles.icon} />
        </Tooltip>
      </InputAdornment>
    );
  };