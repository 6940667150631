import { Theme, createStyles, makeStyles } from "@material-ui/core";
import ChipInput from "material-ui-chip-input";
import React, { useState } from "react";
import { COLORS } from "../../../../../core/consts/redesign/commonRedesign";
import { useAppThunkDispatch } from "../../../../../core/store";
import { handleAutoFillFields } from "../../../../../core/store/thunk/newOrder";
import { HelperToolTip } from "../../../../new-order/helpers/helperTooltip";
import withFormHocs from "../../../../new-order/hoc/withFormHocs";
import { PropsField } from "../../../../new-order/types/bigForm";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      boxSizing: 'border-box',
      padding: '14px 12px 11px 16px',
      borderRadius: '4px',
      height: '63px',
      border: `1px solid ${COLORS.light_gray_stroke}`,
      '&:hover': {
        border: `1px solid ${COLORS.brand_blue}`,
      },
      '&:focused': {
        border: `1px solid ${COLORS.brand_blue}`,
      },
      '&.Mui-disabled': {
        border: `1px solid ${COLORS.light_gray_stroke}`,
      },
    },
    inputRoot: {
      border: 'none',
    },
    input: {
      border: 'none',
    },
    chipContainer: {
      boxSizing: 'border-box',
      border: 'none !important',
      display: 'flex',
      flexWrap: 'nowrap',
      marginTop: '5px !important',
      maxHeight: '40px',
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    label: {
      boxSizing: 'border-box',
      color: `${COLORS.gray2} !important`,
      padding: '14px 12px 8px 16px',
      fontSize: '16px',
      top: '-13px',
      whiteSpace: 'nowrap',
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    labelPosition: {
      top: '-5px'
    },
    helperText: {
      color: COLORS.alert,
    },
    chip: {
      margin: theme.spacing(0.5),
      backgroundColor: COLORS.gray2,
      color: COLORS.white
    },
    chipBoxWithValue: {
      border: `1px solid ${COLORS.success}`,
    },

    error: {
      color: COLORS.alert,
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "21px",
    },
  })
);

type Props = PropsField & {
  value?: string[];
};

/**onChange и value должны быть обязательно */
const StringMultipleField: React.FC<Props> = ({
  field,
  propsalId,
  value,
  text,
  error,
  onChange,
  parentName,
  indexGroupField,
  classNameShowIf,
  className,
  classnamelabel,
  errorClassName,
  handleSaveDraftOnBlur,
}) => {
  const inlineClass = useStyles();
  const [focus, setFocus] = useState(false)
  const hasValueClass = value && value?.length > 0 ? inlineClass.chipBoxWithValue : '';
  const hasValueClassLabel = focus || (value && value?.length > 0) ? inlineClass.labelPosition : '';
  const dispatch = useAppThunkDispatch();


  const handleFocus = (event: React.FocusEvent<HTMLElement>): void => {
    setFocus(true);
  };

  const handleBlur = () => {
    setFocus(false);
    if (field.autoFillUrl && value !== undefined && value.length > 0) {
      dispatch(
        handleAutoFillFields(
          field.autoFillUrl,
          value[0], //первое значения для автозаполнения
          propsalId,
          field.name,
          parentName,
          indexGroupField
        )
      );
    }
    //delay to fill autofill fields before validation
    if (handleSaveDraftOnBlur) {
      setTimeout(() => {
        handleSaveDraftOnBlur();
      }, 500)
    }

  };

  const handleAddValue = (chip: string) => {
    if (onChange) {
      if (value) {
        const newVal = [...value, chip];
        onChange(
          field.name,
          newVal,
          propsalId,
          parentName && parentName,
          indexGroupField && indexGroupField
        );
      } else {
        onChange(
          field.name,
          [chip],
          propsalId,
          parentName && parentName,
          indexGroupField && indexGroupField
        );
      }
    }
  };

  const handleDeleteValue = (chip: string, index: number) => {
    if (onChange) {
      if (value) {
        const newVal = value.filter((item, i) => i !== index);
        onChange(
          field.name,
          newVal,
          propsalId,
          parentName && parentName,
          indexGroupField && indexGroupField
        );
      }
    }
  };

  return (
    <>
      <ChipInput
        disableUnderline={true}
        onAdd={(chip) => {
          handleAddValue(chip);
        }}
        onDelete={(chip, index) => {
          handleDeleteValue(chip, index);
        }}
        InputProps={{
          endAdornment: field.info && <HelperToolTip title={field.info} />,
        }}
        label={
          <div style={{ maxWidth: '280px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {text}{field.isRequeired && <span style={{ color: '#DC5103' }}> *</span>}
          </div>
        }
        disabled={field.isClientBlock}
        fullWidth
        value={value as string[]}
        defaultValue={[]}
        classes={{
          root: `${inlineClass.root} ${hasValueClass}`,
          inputRoot: inlineClass.inputRoot,
          input: inlineClass.input,
          chipContainer: inlineClass.chipContainer,
          label: `${inlineClass.label} ${hasValueClassLabel}`,
          helperText: inlineClass.helperText,
          chip: inlineClass.chip
        }}
        InputLabelProps={{
          className: classnamelabel,
        }}
        onFocus={handleFocus}
        onBlur={() => {
          handleBlur();
        }}
        className={classNameShowIf}
      />
      {error && error.toString().length > 0 && (
        <div className={`error_input ${inlineClass.error}`}>{error}</div>
      )}
    </>
  );
};

export default withFormHocs(React.memo(StringMultipleField));
