
import { Theme, createStyles, makeStyles } from "@material-ui/core";
import { COLORS } from "../../../../core/consts/redesign/commonRedesign";

export const useStyles = makeStyles((theme: Theme) => createStyles({
  docsContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    gap: "4px"
  },
  docsMargin: {
    margin: '24px 0 12px 0',
    [theme.breakpoints.down(1024)]: {
      margin: '24px 16px 12px 16px',
    },
  },
  statusBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: 'space-between',
    width: "100%",
    gap: "24px",
    padding: "16px",
    background: COLORS.white,
    borderRadius: '8px',
    [theme.breakpoints.down(1024)]: {
      flexDirection: "column",
    },
  },
  descBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "65%",
    gap: "24px",
    [theme.breakpoints.down(1024)]: {
      width: "100%",
    },
  },
  smallBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "100%",
    gap: "4px",
  },
  uploadBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "31%",
    gap: "8px",
    [theme.breakpoints.down(1024)]: {
      width: "100%",
    },
  },
  uploadBoxSmall: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "100%",
    gap: 0,
  },
  title: {
    color: COLORS.black,
    fontSize: "21px",
    lineHeight: "28px",
    fontFamily: "Gilroy",
    fontWeight: 500,
    marginBottom: "4px"
  },
  text: {
    color: COLORS.gray2,
    fontSize: "16px",
    lineHeight: "21px",
    fontFamily: "Gilroy",
    fontWeight: 500,
  },
  tickIcon: {
    width: '20px',
    height: '20px',
    '& svg': {
      width: '100%',
      height: '107%'
    }
  },
  docBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    // width: "100%",
    gap: "8px",
  },
  ////////doc preview card ///////
  container: {
    display: 'flex',
    flexDirection: 'row',
    width: '376px',
    height: '56px',
    position: 'relative',
    alignItems: 'center',
    // justifyContent: 'space-between',
    padding: '8px',
    borderRadius: '8px',
    gap: '16px',
    marginBottom: '4px',
    backgroundColor: COLORS.white,
    '&:hover': {
      backgroundColor: COLORS.light_gray_bg,
      cursor: 'pointer'
    },
    [theme.breakpoints.down(420)]: {
      width: "100%",
    },
  },
  desc: {
    display: 'flex',
    flexDirection: 'column',
    width: '75%',
    gap: '1px',
  },
  sizeBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: 'flex-start',
    width: "100%",
    gap: "4px",
  },
  docTitle: {
    color: COLORS.black,
    fontSize: "16px",
    lineHeight: "21px",
    fontFamily: "Gilroy",
    fontWeight: 600,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  docSize: {
    color: COLORS.gray2,
    fontSize: "16px",
    lineHeight: "21px",
    fontFamily: "Gilroy",
    fontWeight: 500,
  },
  errorText: {
    color: COLORS.alert,
    fontSize: "16px",
    lineHeight: "21px",
    fontFamily: "Gilroy",
    fontWeight: 500,
  },
  removeButton: {
    width: '40px',
    height: '30px'
  },
  progressBar: {
    position: 'relative',
    height: '2px',
    maxHeight: '2px',
    backgroundColor: COLORS.gray2,
    marginTop: '-8px',
    overflow: 'hidden',
    width: '376px',
    [theme.breakpoints.down(420)]: {
      width: "100%",
    },
  },
  progress: {
    position: 'absolute',
    height: '100%',
    transition: 'width 0.2s ease-in-out',
  },
  progressGreen: {
    backgroundColor: COLORS.success,
  },
  progressYellow: {
    backgroundColor: COLORS.alert,
  }
}))
