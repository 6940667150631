import { Theme, createStyles, makeStyles } from "@material-ui/core";
import { COLORS } from "../../core/consts/redesign/commonRedesign";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: '40px 80px',
      margin: "0 auto",
      maxWidth: '1320px',
      boxSizing: "border-box",
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
      [theme.breakpoints.down(1024)]: {
        paddingBottom: '230px'
      },
      [theme.breakpoints.down(760)]: {
        padding: "0px 0px 230px",
      },
    },
    content: {
      width: '100%',
      maxWidth: '800px',
      [theme.breakpoints.down(760)]: {
        maxWidth: '100%',
      },
    },

    statusBlock: {
      backgroundColor: COLORS.white,
      display: 'flex',
      width: "100%",
      flexDirection: 'column',
      gap: '24px',
      alignItems: 'flex-start',
      justifyContent: 'center',
      borderRadius: '8px',
      marginBottom: '8px'
    },
    titleContainer: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      gap: '12px',
      alignItems: "flex-start",
      justifyContent: "center",
      [theme.breakpoints.down(1024)]: {
        // alignItems: "center",
      },
    },
    imgIcon: {
      width: '40px',
      height: '40px',
      borderRadius: '4px',
    },
    rowBox: {
      display: "flex",
      width: "100%",
      gap: '8px',
      [theme.breakpoints.down(1024)]: {
        flexDirection: "column",
        gap: '1px',
      },
    },
    columnBox: {
      display: "flex",
      flexDirection: "column",
      gap: '4px',
      width: "100%",
    },
    alertBox: {
      width: '100%',
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-start",
      gap: "8px",
      padding: "8px",
      borderRadius: "4px",
      background: COLORS.warning,
    },
    alertText: {
      fontFamily: 'Gilroy',
      fontStyle: 'normal',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '19px',
      color: COLORS.white,
    },
    infoIcon: {
      width: "14px",
    },
    containerTabs: {
      display: "flex",
      width: '99%',
      justifyContent: "space-between",
      marginLeft: '8px',
      [theme.breakpoints.down(1024)]: {
        marginLeft: '2px',
      },
    },
    containerForButtons: {
      display: "flex",
      justifyContent: "end",
    },
    changeStatusBtn: {
      background: "#306F9A",
      borderRadius: "4px",
      padding: "18px 36px",
      marginLeft: "15px",
      minWidth: "210px"
    },
    text: {
      textTransform: "none",
      color: "white",
      fontSize: "14px",
      fontFamily: "Gilroy",
      fontWeight: 500,
    },
    btnText: {
      border: "1px solid #306F9A",
      padding: "18px 36px",
      minWidth: "235px"
    },
    textDoc: {
      fontSize: "16px",
      color: "#306F9A",
      fontFamily: "Gilroy",
      fontWeight: 500,
      textTransform: "none",
    },
  })
);
