import React from "react";
import {
  makeStyles,
  Theme,
  createStyles,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridItem: {
      display: "flex",
      flexDirection: "column",
      padding: "7.5px 0",
    },
    editLink: {
      color: "#306F9A",
      fontWeight: 600,
      cursor: "pointer",
    },
  })
);

type PropsType = {
  currentValue: string | null;
  inputValue: string | null;
  onChange: (name: string, value: string) => void;
  isEdit: boolean;
  fieldName: string;
  error?: string;
};

const EditField: React.FC<PropsType> = (props) => {
  const classes = useStyles();
  const [isEdit, setIsEdit] = React.useState(false);
  const [value, setValue] = React.useState(props.inputValue);
  const [timer, setTimer] = React.useState(0);

  const handleChangeInput = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const currentValue = event.target.value;
    if (props.fieldName === "bik" || props.fieldName === "account") {
      if (currentValue.match(/^[0-9]*([.|,][0-9]*)?$/g)) {
        setValue(currentValue);
      }
    } else {
      setValue(currentValue);
    }
    window.clearTimeout(timer);
    const localTimer = window.setTimeout(() => {
      if (currentValue !== null) {
        if (props.fieldName === "bik" || props.fieldName === "account") {
          if (currentValue.match(/^[0-9]*([.|,][0-9]*)?$/g)) {
            props.onChange(props.fieldName, currentValue);
          }
        } else {
          props.onChange(props.fieldName, currentValue);
        }
      }
    }, 300);
    setTimer(localTimer);
  };

  React.useEffect(() => {
    if (props.inputValue !== value) {
      setValue(props.inputValue);
    }
  }, [props.inputValue]);

  React.useEffect(() => {
    if (props.currentValue === value) {
      setIsEdit(false);
    }
  }, [props.currentValue]);

  React.useEffect(() => {
    if (props.error) {
      setIsEdit(true);
    }
  }, [props.error]);

  const handleOnBlur = () => {
    if (props.currentValue === props.inputValue) {
      setIsEdit(false);
    }
    if (props.error || (!Boolean(props.currentValue) && !props.isEdit)) {
      return;
    }
    setIsEdit(false);
  };

  return (
    <Grid item className={classes.gridItem} xs={6}>
      {isEdit ? (
        <TextField
          autoFocus={true}
          onBlur={handleOnBlur}
          onChange={handleChangeInput}
          name={props.fieldName}
          value={value}
          error={Boolean(props.error)}
          helperText={Boolean(props.error) && props.error}
        />
      ) : Boolean(props.currentValue) ? (
        <Typography
          onClick={props.isEdit ? () => setIsEdit(true) : undefined}
          className={props.isEdit ? classes.editLink : undefined}
          variant="body2"
          component="span"
        >
          {props.currentValue}
        </Typography>
      ) : (
        <Typography
          onClick={() => setIsEdit(true)}
          variant="body2"
          component="span"
          className={classes.editLink}
        >
          Указать
        </Typography>
      )}
    </Grid>
  );
};

export default React.memo(EditField);
