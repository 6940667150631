import { makeStyles, createStyles, Theme } from "@material-ui/core";

export const CertificateStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      formBox: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        marginTop: "30px",
        maxWidth: "300px",
      },
      button: {
        margin: 0,
        width: "80%",
        height: "51px",
        marginTop: "25px",
      },
      subtitle: {
        marginBottom: "25px",
        maxWidth: "500px",
        [theme.breakpoints.down("sm")]: {
          marginBottom: "16px",
        },
      },
      optionsSelect: {
        width: "100%",
        padding: "0",
        display: "block",
        margin: "0",
      },
      boxSelectOptions: {
        margin: "0",
        padding: "15px 0",
        borderBottom: "1px solid #F2F2F2",
        backgroundColor: "#ffffff",
        "&:hover": {
          backgroundColor: "#ffffff",
          "& span": {
            color: "#617E8C",
          },
        },
      },
      selectOptionsName: {
        paddingLeft: "15px",
        paddingBottom: "5px",
        paddingTop: "5px",
        display: "block",
      },
      selectOptionsText: {
        paddingLeft: "15px",
        fontSize: "12px",
        display: "block",
      },
      certificaterWrap: {
        paddingTop: "30px",
      },
      certificateText: {
        color: "#617E8C",
        display: "block",
      },
      certificateExpireText: {
        color: "#F57C1F",
        display: "flex",
        alignItems: "center",
      },
      noCeritificatesText: {
        maxWidth: "500px",
        display: "block",
      },
    }),
  { index: 1 }
);
