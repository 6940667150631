import axios from "axios";
import { http } from "../infrastructure/Https";
import tokenService from "../services/TokenService";
import {
  PartnerLeadsResType,
  partnerApplicationsListQuery,
  documentType,
} from "../types/partnerApiTypes";

const ROOT_URL = "/api/v1/partner";

export type ReferencePartnerInfo = {
  partner: string;
  lead_id: string;
  payout: number;
};

class PartnerApi {
  //POST ​/api​/v1​/partner​/lead​/file Запросить файл и опционально подпись файла
  getFileAndSignature(B: {
    leadid: number;
    fileType: number;
    fileName: string;
    isSignatureRequired: boolean;
  }): Promise<string> {
    const accessToken = tokenService.getAccessToken();
    return http.post(`${ROOT_URL}/lead/file`, undefined, B, accessToken);
  }

  //DELETE ​/api​/v1​/partner​/lead​/file Удалить запрошенный файл если он не добавлен пользователем
  deleteFileIfNotDownloaded(B: {
    leadid: number;
    fileId: number;
  }): Promise<string> {
    const accessToken = tokenService.getAccessToken();
    return http.delete(`${ROOT_URL}/lead/file`, undefined, B, accessToken);
  }

  //POST ​/api​/v1​/partner​/lead​/filev2 Запросить файл и опционально подпись файла
  postLeadFile(data: documentType, leadId: any): Promise<string> {
    const accessToken = tokenService.getAccessToken();
    const formData = new FormData();
    formData.append("leadid", leadId);
    formData.append("fileName", data.fileName);
    formData.append("Comment", data.Comment);
    formData.append("isSignatureRequired", String(data.isSignatureRequired));
    // if (data.doc) formData.append("File", data.doc);
    return http.post(
      `${ROOT_URL}/lead/filev2`,
      undefined,
      formData,
      accessToken
    );
  }

  //PUT ​/api​/v1​/partner​/lead​/{leadid}​/archive Архивирование заявки
  putLeadArchive(leadid: number): Promise<string> {
    const accessToken = tokenService.getAccessToken();
    return http.put(
      `${ROOT_URL}/lead/${leadid}/archive`,
      undefined,
      undefined,
      accessToken
    );
  }

  // POST   ​/api​/v1​/partner​/lead​/file_example  Прикрепить/очистить пример документа на запрошенный документ
  attachFileExample(
    baseDocumentId: number,
    clear: boolean,
    data: documentType
  ): Promise<string> {
    const accessToken = tokenService.getAccessToken();
    const formData = new FormData();
    formData.append("baseDocumentId", baseDocumentId.toString());
    formData.append("clear", clear.toString());
    formData.append("documentFile", data.doc);
    return http.post(
      `${ROOT_URL}/lead/file_example`,
      undefined,
      formData,
      accessToken
    );
  }

  //PUT ​/api​/v1​/partner​/lead​/status Изменение статуса заявки
  postLeadStatus(leadid: number, newStatus: string): Promise<any> {
    const data = { leadid, newstatus: newStatus };
    const accessToken = tokenService.getAccessToken();
    return http.put(`${ROOT_URL}/lead/status`, undefined, data, accessToken);
  }

  //PUT ​/api​/v1​/partner​/lead​/status Изменение статуса заявки
  leadStatusChange(B: { leadid: number; newstatus: string }): Promise<string> {
    const accessToken = tokenService.getAccessToken();
    return http.put(`${ROOT_URL}/lead/status`, undefined, B, accessToken);
  }

  //GET ​/api​/v1​/partner​/lead Получение заявок банка
  getLeads(Q: partnerApplicationsListQuery): Promise<PartnerLeadsResType> {
    const accessToken = tokenService.getAccessToken();
    return http.get(`${ROOT_URL}/lead`, Q, accessToken);
  }

  //PUT​/api​/v1​/partner​/lead​/{leadid}​/read Снятие флага новый с заявки
  takeOffLeadFlagNew(leadid: number): Promise<string> {
    const accessToken = tokenService.getAccessToken();
    return http.put(
      `${ROOT_URL}/lead/${leadid}/read`,
      undefined,
      undefined,
      accessToken
    );
  }

  //POST/api​/v1​/user/c2mcallback/{cid} Отправка click id.
  sendClickId(cid: string): Promise<void> {
    return http.post(`/api/v1/user/c2mcallback/${cid}`);
  }
}

const partnerApi = new PartnerApi();
export default partnerApi;
