import { AppBar, Typography } from "@material-ui/core";
import IconButtonMain from "../../../../../UIcomponentsRedesign/IconButtonMain/IconButtonMain";
import "../../../../../core/consts/redesign/global.scss";
import { CloseIcon } from "../../../../../core/consts/redesign/icons/Close";
import { useHeaderStyles } from "../useHeaderStyles";

/** 
 * Mobile Bottom menu component. Can accept as many items as needed
 */

type MenuItemProp = {
  text: string;
  onClick: () => void;
}

type BottomMenuProps = {
  title: any;
  description?: string | null, //caption under the title
  items: MenuItemProp[];
  onClose: () => void;
};

const BottomMenu: React.FC<BottomMenuProps> = ({ title, description, items, onClose }) => {
  const classes = useHeaderStyles();

  return (
    <>
      <div className={classes.overlay} onClick={onClose}></div>
      <AppBar position="fixed" className={classes.appbarBottom}>
        <IconButtonMain color="noBgGray" className={classes.closeButton} icon={<CloseIcon />} handleClick={onClose} />
        <div>
          <Typography className={classes.titleBottom}>{title}</Typography>
          <Typography className={classes.descriptionBottom}>{description}</Typography>
        </div>
        <ul className={classes.menuLinks}>
          {items.map((item, index) => (
            <li key={index}
              onClick={() => { item.onClick(); onClose(); }}>
              {item.text}
            </li>
          ))}
        </ul>
      </AppBar>
    </>
  );
};

export default BottomMenu;
