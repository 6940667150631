import { actions } from '../store';
import { decode, encode, ParsedUrlQuery } from 'querystring';
import { isNotEmpty } from '../utils/common';

type PushQueryOptions = {
    reset?: boolean;
    shouldRefresh?: boolean;
    login?: boolean;
};

const getQuery = () => decode(window.location.search.slice(1));


class RouteService {
    push(url: string) {
        actions.setRoute(url)
    }

    pushWithQuery(path: string, query: ParsedUrlQuery, options?: PushQueryOptions) {
        const currentQuery = getQuery();
        const finalQuery = encode({
            ...(!options?.reset ? {
                ...currentQuery,
            } : {}),
            ...(options?.shouldRefresh ? {
                __timestamp: Date.now(),
            } : {}),
            ...query,
        });
        this.push([path, finalQuery].filter(isNotEmpty).join('?'));
    }

}

export const routeService = new RouteService();