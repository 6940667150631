import TextField, { TextFieldProps } from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import React, { useEffect, useRef, useState } from "react";
import { COLORS } from "../core/consts/redesign/commonRedesign";
import { TickIcon } from "../core/consts/redesign/icons/Tick";

interface ExtraProps {
  showCharacterCount?: boolean;
  characterLimit?: number;
}
type TextAreaCustomProps = TextFieldProps & ExtraProps;

const TextAreaCustomStyled = styled(({ showCharacterCount = false, characterLimit = 200, ...props }: TextAreaCustomProps) => {
  const textAreaRef = useRef<HTMLInputElement | null>(null);
  const [characterCount, setCharacterCount] = React.useState(0);
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    if (textAreaRef.current) {
      const element = textAreaRef.current;
      element.scrollTop = element.scrollHeight;
    }
    if (props.value && showCharacterCount) {
      setCharacterCount((props.value as string).length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (props.onChange) {
      if (showCharacterCount) {
        if (value.length > characterLimit) {
          props.onChange({
            ...event,
            target: {
              ...event.target,
              value: value.slice(0, characterLimit)
            }
          });
          setCharacterCount(characterLimit);
        } else {
          props.onChange(event);
          setCharacterCount(value.length);
        }
      } else {
        props.onChange(event);
      }
    }
  };

  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);


  return (
    <div style={{ position: "relative", width: '100%' }}>
      <TextField
        {...props}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        fullWidth
        multiline={true}
        maxRows={3}
        minRows={1}
        inputRef={textAreaRef}
        FormHelperTextProps={{
          ...props.FormHelperTextProps,
          style: {
            position: 'absolute',
            bottom: '-22px',
            left: 0,
            width: '100%',
            scrollMarginTop: '200px',
            textTransform: 'none',
            ...props.FormHelperTextProps?.style
          }
        }}
        InputProps={{
          style: {
            overflow: 'auto',
          }
        }}
        InputLabelProps={{
          ...props.InputLabelProps,
          children: props.required
            ? <>
              {props.InputLabelProps?.children}<span style={{ color: COLORS.alert }}> *</span>
            </> : props.InputLabelProps?.children,
        }}

      />
      {props.value && !props.error ? (
        <div style={{ position: "absolute", top: "8px", right: "8px", }}>
          <TickIcon color={COLORS.success} />
        </div>
      ) : null}
      {showCharacterCount && (isFocused || (props.value && (props.value as string).length > 0)) && (
        <div style={{ position: "absolute", bottom: "8px", right: "8px", fontSize: "12px", color: COLORS.light_gray_stroke }}>
          {characterCount}/{characterLimit}
        </div>
      )}
    </div>
  );
})(({ theme, value }) => {
  return {
    "& .MuiOutlinedInput-root": {
      overflow: "hidden",
      width: "100%",
      height: value ? 'fit-content' : '64px',
      cursor: 'pointer',
      backgroundColor: COLORS.white,
      color: COLORS.black,
      padding: value ? "32px 12px 22px 16px" : "18px 12px 22px 16px",
      transition: theme.transitions.create([
        "border-color",
        "background-color",
        "box-shadow",
      ]),
      border: value ? `1px solid ${COLORS.success}` : `1px solid ${COLORS.light_gray_stroke}`,
      "&:hover": {
        border: value ? `1px solid ${COLORS.success}` : `1px solid ${COLORS.brand_blue}`,
      },
      "&.Mui-focused": {
        border: `1px solid ${COLORS.brand_blue}`,
        height: 'fit-content',
        padding: "32px 12px 22px 16px",
      },
      "&.Mui-disabled": {
        backgroundColor: COLORS.light_gray_bg,
      },
      "&.Mui-error": {
        border: `1px solid red`,
      },
      "& fieldset": {
        border: "none",
      },
    },
    "& .MuiOutlinedInput-input": {
      // padding: "12px 15px 0px 0px",
      fontSize: "16px",
      fontWeight: "500",
      lineHeight: "21px",
      fontFamily: "Gilroy",
      // margin: "26px 8px 11px 2px",
    },
    "& .MuiInputLabel-root": {
      fontSize: "16px",
      fontWeight: "500",
      lineHeight: "21px",
      fontFamily: "Gilroy",
      textAlign: "left",
      position: "absolute",
      top: value ? "22px" : "6px",
      left: "0%",
      paddingLeft: '4px',
      color: COLORS.gray2,
      transform: value ? "translateY(-50%, -100%) scale(0.75)" : "translateY(0, 0) scale(1)",
      "&.Mui-focused": {
        transform: "translateY(-50%, -100%) scale(0.75)",
        top: "22px",
        color: COLORS.gray2,
      },
    },
    "& .MuiInput-root": {
      backgroundColor: COLORS.white,
      color: COLORS.black,
    },
    "& .MuiFormHelperText-root": {
      color: COLORS.alert,
      width: '100%',
      textTransform: 'none',
      scrollMarginTop: '200px',
      fontSize: "12px",
      fontWeight: "500",
      lineHeight: "16px",
      fontFamily: "Gilroy",
    },
  }
});
export const TextAreaCustom: React.FC<TextAreaCustomProps> = (props) => <TextAreaCustomStyled {...props} />;
