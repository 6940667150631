import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import { ExpandMoreOutlined } from "@material-ui/icons";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useState
} from "react";
import { useAppThunkDispatch } from "../../../../core/store";
import { checkIsGroupFieldError } from "../../../../core/store/thunk/newOrder";
import { FieldType, PropsalData } from "../../../new-order/types/bigForm";
import classes from "../../NewOrderRedesign.module.scss";
import Fields from "../Fields/Fields";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    accordion: {
      width: "100%",
      padding: 0,
      "&:before": {
        height: 0,
      },
      "&:after": {
        height: 0,
      },
    },
    accordionDetails: {
      "& .MuiAccordionDetails": {
        width: "100%",
      },
    },
  })
);

type Props = {
  propsal: PropsalData;
};

const FormAccordion: React.FC<Props> = ({ propsal }) => {
  const dispatch = useAppThunkDispatch();

  // const handleValidation = () => {
  //   dispatch(validateForm());
  // };
  const [isMoreThanOneGroupFielsAtStep, setIsMoreThanOneGroupFielsAtStep] =
    useState(false);
  const [currentGroupField, setCurrentGroupField] = useState<FieldType | null>(
    null
  );
  const inlineClasses = useStyles();

  const checkIsMoreThanOneGroupFielsAtStep = () => {
    if (propsal.fields.length === 0) {
      setIsMoreThanOneGroupFielsAtStep(false);
    } else {
      const gropFields = propsal.fields.filter(
        (field) => field.dataType === "group"
      );
      if (gropFields?.length > 0 && propsal.fields.length > 1) {
        setIsMoreThanOneGroupFielsAtStep(true);
      } else {
        setIsMoreThanOneGroupFielsAtStep(false);
      }
    }
  };

  const checkIsErrorField = useCallback((field: FieldType) => {
    return dispatch(checkIsGroupFieldError(field, propsal?.id));
  }, []);

  useEffect(() => checkIsMoreThanOneGroupFielsAtStep(), [propsal?.id]);

  return (
    <>
      {isMoreThanOneGroupFielsAtStep ? (
        <div className={classes.column}>
          {propsal?.fields.map((field: FieldType) => (
            <Fragment key={field.name}>
              {field?.dataType === "group" ? (
                <>
                  {/* <button
                    className="button_ghost_w100"
                    onClick={() =>
                      setCurrentGroupField(
                        currentGroupField?.name === field.name ? null : field
                      )
                    }
                    style={{
                      boxShadow: checkIsErrorField(field)
                        ? `2px 2px 8px 4px ${COLORS.alert}`
                        : "",
                    }}
                  >
                    {field.text}
                  </button> */}

                  <Accordion className={inlineClasses.accordion}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreOutlined />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                      onClick={() => {
                        setCurrentGroupField(
                          // currentGroupField?.name === field.name ? null : field
                          field
                        );
                      }}
                    >
                      <div
                        className={classes.proposal_box}
                        style={{ width: "100%" }}
                      >
                        <div className={classes.accordion_font}>
                          {field.text}
                        </div>
                        {checkIsErrorField(field) && (
                          <div className={classes.error}>
                            Есть незаполненные поля
                          </div>
                        )}
                      </div>
                    </AccordionSummary>

                    <AccordionDetails
                      className={inlineClasses.accordionDetails}
                    >
                      <div
                        className={classes.proposal_box}
                        style={{ width: "100%" }}
                      >
                        {field.fieldId === currentGroupField?.fieldId && (
                          <Fields
                            field={field}
                            propsalId={propsal.id}
                            stepId={propsal.stepId}
                            propsalIdsReal={
                              propsal.proposals
                                ? propsal.proposals.map((el) => el.id)
                                : [0]
                            }
                          />
                        )}
                      </div>
                    </AccordionDetails>
                  </Accordion>
                  {/* <div className="line_x" /> */}

                  {/* error */}

                  {/* {checkIsErrorField(field) && (
                    <div className={classes.error}>
                      Есть незаполненные поля{" "}
                    </div>
                  )} */}
                  {/* {field.fieldId === currentGroupField?.fieldId && (
                    <Fields
                      field={field}
                      propsalId={propsal.id}
                      stepId={propsal.stepId}
                      propsalIdsReal={
                        propsal.proposals
                          ? propsal.proposals.map((el) => el.id)
                          : [0]
                      }
                    />
                  )} */}
                </>
              ) : (
                <>
                  {/* {field.upSeparator && <div className="line_x" />} */}
                  <Fields
                    field={field}
                    // key={field.name}
                    propsalId={propsal.id}
                    stepId={propsal.stepId}
                    propsalIdsReal={
                      propsal.proposals
                        ? propsal.proposals.map((el) => el.id)
                        : [0]
                    }
                  // proposals={propsal.proposals}
                  />
                  {/* {field.downSeparator && <div className="line_x" />} */}
                </>
              )}
            </Fragment>
          ))}
        </div>
      ) : (
        propsal.fields.map((field: FieldType) => {
          return (
            <React.Fragment key={field.name}>
              {/* {field.upSeparator && <div className="line_x" />} */}
              <Fields
                field={field}
                // key={field.name}
                propsalId={propsal.id}
                stepId={propsal.stepId}
                propsalIdsReal={
                  propsal.proposals ? propsal.proposals.map((el) => el.id) : [0]
                }
              // proposals={propsal.proposals}
              />
              {/* {field.downSeparator && <div className="line_x" />} */}
            </React.Fragment>
          );
        })
      )}
    </>
  );
};

export default React.memo(FormAccordion);
