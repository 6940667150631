import { Theme, createStyles, makeStyles } from "@material-ui/core";
import React, { memo } from "react";
import ButtonCustom from "../../../UIcomponentsRedesign/ButtonCustom/ButtonCustom";
import { COLORS, DateSortType } from "../../../core/consts/redesign/commonRedesign";
import { Sort } from "../../../core/consts/redesign/icons";

/* Redesign . Sorting filter */
type PropsType = {
  items?: any;
  totalItems: number;
  choosingAmount?: number;
  onClickChoose?: () => void;
  onClickChooseAll?: () => void;
  onClickArchive?: () => void;
  onClickSort?: () => void;
  searchPeriod: string;
  setSearchPeriod: (value: DateSortType | ((prev: DateSortType) => DateSortType)) => void,
  children?: React.ReactNode;
  isProductPage?: boolean;
  isForNotifications?: boolean;
  extraMargin?: boolean;
  handleLineClick?: () => void
}

const SortingLine: React.FC<PropsType> = (props) => {
  const useStyles = makeStyles((theme: Theme) => createStyles({
    sortingLine: {
      margin: "40px 0 24px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      [theme.breakpoints.down(1024)]: {
        alignItems: "flex-end",
        maxWidth: "800px",
        margin: props.extraMargin ? "24px 16px" : "24px 0px",
      },
    },
    sortingBox: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      gap: "16px",
      [theme.breakpoints.down(1024)]: {
        gap: "8px",
        flexDirection: "column",
        alignItems: "flex-start",
      },
    },
    grayText: {
      fontSize: "16px",
      lineHeight: "21px",
      fontFamily: "Gilroy",
      fontWeight: 500,
      color: COLORS.gray2
    }
  }));

  const classes = useStyles();
  const toggleSort = () => {
    props.setSearchPeriod(prev => prev === DateSortType.sort_desc ? DateSortType.sort_asc : DateSortType.sort_desc);
  };
  const itemsAmount = props.totalItems;
  const lastDigit = itemsAmount % 10;
  const isTeen = itemsAmount >= 11 && itemsAmount <= 19;

  let word;

  if (props.isProductPage) {
    if (isTeen) {
      word = 'заявок';
    } else {
      switch (lastDigit) {
        case 1:
          word = 'заявка';
          break;
        case 2:
        case 3:
        case 4:
          word = 'заявки';
          break;
        default:
          word = 'заявок';
      }
    }
  } else if (props.isForNotifications) {
    if (isTeen) {
      word = 'уведомлений';
    } else {
      switch (lastDigit) {
        case 1:
          word = 'уведомление';
          break;
        case 2:
        case 3:
        case 4:
          word = 'уведомления';
          break;
        default:
          word = 'уведомлений';
      }
    }
  } else {

    if (isTeen) {
      word = 'предложений';
    } else {
      switch (lastDigit) {
        case 1:
          word = 'предложение';
          break;
        case 2:
        case 3:
        case 4:
          word = 'предложения';
          break;
        default:
          word = 'предложений';
      }
    }
  }

  return (
    <div className={classes.sortingLine} onClick={props.handleLineClick}>
      <div className={classes.sortingBox}>
        <span className={classes.grayText}>{itemsAmount}&nbsp;{word}</span>
        {/* {props.choosingAmount ? (
          <>
            <span className={classes.grayText}>&nbsp;/&nbsp;выбрано{props.choosingAmount}&nbsp;заявки</span>
            <ButtonCustom style={{ paddingLeft: 0, marginLeft: 0, height: '21px' }} handleClick={props.onClickChooseAll} color='text_button'>
              выбрать все
            </ButtonCustom>
            <ButtonCustom
              style={{
                padding: 0, margin: 0, minHeight: '21px'
              }}
              handleClick={props.onClickArchive}
              color='text_button'>
              переместить в архив
            </ButtonCustom>
          </>
        ) : (
          <ButtonCustom
            style={{
              padding: 0, margin: 0, minHeight: '21px'
            }}
            handleClick={props.onClickChoose}
            color='text_button'>
            выбрать несколько
          </ButtonCustom>
        )} */}
      </div>
      {(props.isProductPage || props.isForNotifications) &&
        <ButtonCustom
          style={{
            padding: 0, margin: 0, minHeight: '21px'
          }}
          handleClick={toggleSort}
          rightIcon={<Sort />}
          color='text_button'>
          {props.searchPeriod === "createdate_desc" ? "сначала новые" : "сначала старые"}
        </ButtonCustom>}
      {props.children}
    </div>
  )
}

export default memo(SortingLine)
