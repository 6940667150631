import React, { useEffect, useState } from "react";
import InfoPopover from "../../app/components/info-popover/InfoPopover";
import { COLORS } from "../../core/consts/redesign/commonRedesign";
import { ArrowDownIcon } from "../../core/consts/redesign/icons/ArrowDown";
import { ArrowUpIcon } from "../../core/consts/redesign/icons/ArrowUp";
import { FieldWithKey } from "../../pages/new-order/types/bigForm";
import CustomPopover from '../CustomPopover/CustomPopover';
import classes from "./SelectCustom.module.scss";

/* select with border */
export type OptionType = {
  value: string;
  name: string;
};

type PropsType = {
  label?: string;
  info?: string;
  placeHolder?: string;
  value: string;
  handleChange: any;
  error?: string;
  success?: boolean;
  errorMessage?: string;
  options?: string[];
  optionsObjects?: FieldWithKey[];
  count?: number;
  noBorder?: boolean;
  disabled?: boolean;
  classCustom?: string;
  isRequired?: boolean;
  icon?: any;
  isFullWidth?: boolean;
  className?: string;
  noPopover?: boolean
};

const SelectCustom = ({
  label,
  info,
  value = "",
  handleChange,
  error,
  options,
  optionsObjects,
  disabled,
  isRequired,
  icon,
  isFullWidth,
  noPopover = false
}: PropsType) => {
  const ref = React.useRef<HTMLInputElement | null>(null);
  const containerRef = React.useRef<HTMLDivElement | null>(null);
  const [focus, setFocus] = useState(false);
  const [showError, setShowError] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const popoverId = `popover-${label}`;
  const selectedOptionText = optionsObjects?.find((opt) => opt.key === value)?.text || "";

  useEffect(() => {
    if (!isOpen && error) {
      setShowError(true);
    } else {
      setShowError(false);
    }
  }, [isOpen, error]);

  const handleClick = (value: any) => {
    setIsOpen(false);
    if (ref.current) {
      setFocus(false)
      ref.current?.focus();
    }
    handleChange(value);
  };

  return (
    <>
      {isOpen && (
        <div className={classes.modal_backdrop}
          onClick={() => {
            setIsOpen(false);
            setFocus(false)
          }} />
      )}
      <div
        ref={containerRef}
        style={{
          width: isFullWidth ? "100%" : "",
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          cursor: 'pointer'
        }}
      >
        <div
          onClick={() => setIsOpen(!isOpen)}
          className={`${classes.input_box} ${classes.w100} 
          ${error ? classes.error_box : ""} 
          ${value && value?.length > 0 && !error ? classes.filled : ""}
          ${isOpen && !disabled ? classes.focusBox : ""}
          ${disabled ? classes.disabled_box : ''}`}
          key={`key_${label}`}
        >
          {label && (
            <div
              className={
                `${(value && value.length > 0) || focus ? classes.label_small : classes.label_big}`
              }
            >
              {label}
              {isRequired && <span style={{ color: '#DC5103' }}> *</span>}
            </div>
          )}
          <div className={`${classes.flex_betw} ${isOpen && !disabled ? classes.flex_betwMargin : ""}`}>
            <input
              style={{
                cursor: 'pointer'
              }}
              id={label}
              ref={ref}
              className={`${classes.input} ${label ? "" : classes.input_without_label}`}
              value={options ? (value || '') : (optionsObjects?.find((elem) => elem.key === value)?.text || '')}
              onChange={(e) => {
                handleChange(e.target.value);
              }}
              onFocus={() => {
                if (ref.current) {
                  setFocus(true)
                  ref.current?.focus();
                }
              }}
              onBlur={() => setFocus(false)}
              disabled={disabled}
              readOnly
              onClick={() => setIsOpen(!isOpen)}
            />
            {label && !noPopover && <CustomPopover id={popoverId} label={label} value={selectedOptionText} margin='35px' />}
            {!disabled && <div onClick={() => setIsOpen(!isOpen)} className={classes.input_icon}>
              {icon ? icon : isOpen ? <ArrowUpIcon color={COLORS.gray2} /> : <ArrowDownIcon color={COLORS.gray2} />}
            </div>}
          </div>

          {!disabled && isOpen && (
            <div className={classes.modal_back}>
              <div className={classes.options_block} onBlur={() => setIsOpen(false)}>
                {options && options?.map((elem) => (
                  <div
                    key={elem}
                    className={classes.input_option}
                    onClick={() => {
                      handleClick(elem);
                    }}
                  >
                    {elem}
                  </div>
                ))}
                {optionsObjects && optionsObjects?.map((elem) => (
                  <div
                    key={elem.key}
                    className={classes.input_option}
                    onClick={() => {
                      handleClick(elem.key);
                    }}
                  >
                    {elem.text}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        {label && info && <InfoPopover id={label} text={label} info={info} />}
      </div >
      {showError && (
        <div className="error_input" style={{ marginTop: '8px' }} key="error">
          {error}
        </div>
      )}
    </>
  );
};

export default React.memo(SelectCustom);
