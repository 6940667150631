import TextField, { TextFieldProps } from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import { COLORS } from "../core/consts/redesign/commonRedesign";
import { TickIcon } from "../core/consts/redesign/icons/Tick";

interface ExtraProps {
  tickIcon?: boolean;
  unitText?: string;
}

type TextFieldCustomProps = TextFieldProps & ExtraProps
export const TextFieldWithBorder = styled(({ tickIcon, unitText, ...props }: TextFieldCustomProps) => (
  <div style={{ width: '100%', position: 'relative', display: 'flex', alignItems: 'center' }}>
    <TextField
      {...props}
      fullWidth
      InputProps={{
        ...props.InputProps,
        endAdornment: (
          <>
            {tickIcon && props.value && !props.error ? (
              <TickIcon color={COLORS.success} />
            ) : null}
          </>
        ),
        style: { paddingRight: unitText ? '10px' : 'inherit' }
      }}
      FormHelperTextProps={{
        ...props.FormHelperTextProps,
        style: {
          position: 'absolute',
          // bottom: useIsMobile() ? "-35px" : '-20px',
          bottom: '-20px',
          left: 0,
          width: '100%',
          scrollMarginTop: '200px',
          textTransform: 'none',
          ...props.FormHelperTextProps?.style
        }
      }}
      InputLabelProps={{
        ...props.InputLabelProps,
        children: props.required ? <>{props.InputLabelProps?.children}<span style={{ color: COLORS.alert }}> *</span></> : props.InputLabelProps?.children,
      }}
    />
    {unitText && (
      <span style={{
        position: 'absolute',
        left: '34px',
        top: '30px',
        pointerEvents: 'none',
        color: COLORS.black,
        fontSize: "16px",
        fontWeight: "500",
        lineHeight: "21px",
        fontFamily: "Gilroy",
      }}>
        {unitText}
      </span>
    )}
  </div>
))(({ theme, value }) => {
  return {
    "& .MuiOutlinedInput-root": {
      overflow: "hidden",
      height: "63px",
      width: "100%",
      backgroundColor: COLORS.white,
      color: COLORS.black,
      padding: "11px 8px  11px 11px",
      transition: theme.transitions.create([
        "border-color",
        "background-color",
        "box-shadow",
      ]),
      border: value ? `1px solid ${COLORS.success}` : `1px solid ${COLORS.light_gray_stroke}`,
      "&:hover": {
        border: value ? `1px solid ${COLORS.success}` : `1px solid ${COLORS.brand_blue}`,
      },
      "&.Mui-focused": {
        border: `1px solid ${COLORS.brand_blue}`,
      },
      "&.Mui-disabled": {
        backgroundColor: COLORS.light_gray_bg,
      },
      "&.Mui-error": {
        border: `1px solid red`,
      },
      "& fieldset": {
        border: "none",
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: "4px 0px 0px",
      fontSize: "15px",
      fontWeight: "500",
      lineHeight: "21px",
      fontFamily: "Gilroy",
      margin: "26px 8px 11px 4px",
    },
    "& .MuiInputLabel-root": {
      fontSize: "16px",
      fontWeight: "500",
      lineHeight: "21px",
      fontFamily: "Gilroy",
      textAlign: "left",
      position: "absolute",
      top: value ? "18px" : "6px",
      left: "0%",
      paddingLeft: '2px',
      color: COLORS.gray2,
      transform: value ? "translateY(-50%, -100%) scale(0.75)" : "translateY(0, 0) scale(1)",
      "&.Mui-focused": {
        transform: "translateY(-50%, -100%) scale(0.75)",
        top: "18px",
        color: COLORS.gray2,
      },
    },
    "& .MuiInput-root": {
      backgroundColor: COLORS.white,
      color: COLORS.black,
    },
    "& .MuiFormHelperText-root": {
      color: COLORS.alert,
      width: '100%',
      textTransform: 'none',
      scrollMarginTop: '200px',
      fontSize: "12px",
      fontWeight: "500",
      lineHeight: "16px",
      fontFamily: "Gilroy",
    },
  }
});
