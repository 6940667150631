import { CurrentLeadActionTypes, CurrentLeadActions, CurrentLeadState } from "../../types/currentLeadTypes"

const initialState: CurrentLeadState = {}

export const currentLeadReducer = (
  state = initialState,
  action: CurrentLeadActions) => {
  switch (action.type) {
    case CurrentLeadActionTypes.SET_LEAD:
      return {
        ...state,
        ...action.payload,
      }
    default: {
      return state
    }
  }
}