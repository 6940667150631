export const RegisterFormCommon = {
    lastName: "*Фамилия",
    firstName: "*Имя",
    patrName: "Отчество (если есть)",
    nameLabel: '*Ваше ФИО',
    phoneLabel: '*Номер телефона',
    phone: '+7 (800) 000-00-00',
    email: '*Адрес электронной почты',
    inn: 'ИНН',
    innCompany: '*ИНН компании',
    innOrganisation: '*ИНН',
    password: 'Пароль',
    code: 'Код',
    codeLabel: 'Введите код',
    bik: '*БИК',
    nalogs: '*Система налогооблажения',
    fact_adress: '*Фактический адрес',
    mail: '*Почтовый адрес',
    payment: '*р/c'
}

export const regFio = /^ *[А-Я][а-яА-Я-]{1,} *$/gm