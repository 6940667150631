import { http } from "../infrastructure/Https";
import tokenService from "../services/TokenService";

const ROOT_URL = '/api/v1/notification'

export type notificationType = {
  id: number,
  dateAdd: string,
  isRead: boolean,
  action: string,
  actionId: string,
  leadId: number,
  productId: number,
  text: string,
  proposal: {
    id: number,
    bank: {
      id: number,
      name: string,
    },
    name: string,
    description: string,
  },
  forManager: boolean
}

export type notificationsListType = {
  result: notificationType[],
  pagination: {
    offset: number,
    count: number,
    total: number,
  }
}

class NotificationsApi {

  getNotificationsCount(): Promise<{ count: number }> {
    const accessToken = tokenService.getAccessToken()
    return http.get(`${ROOT_URL}/count`, null, accessToken)
  }

  getNotificationsList(Q: { count?: number, offset?: number, onlyUnread?: boolean }): Promise<notificationsListType | any> {
    const accessToken = tokenService.getAccessToken()
    return http.getWithParams(`${ROOT_URL}`, Q, null, accessToken)
  }

  readNotification(id: string) {
    const accessToken = tokenService.getAccessToken()
    return http.get(`${ROOT_URL}/${id}`, null, accessToken)
  }
}

const notificationsApi = new NotificationsApi()
export default notificationsApi