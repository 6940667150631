/**
 * Checks if the given filename corresponds to an image file.
 * 
 * This function determines whether a file is an image by checking its extension
 * against a list of known image file extensions. The extensions considered are:
 * 'jpg', 'jpeg', 'png', 'gif', 'bmp', and 'webp'.
 * 
 * @param {string} filename - The name of the file to check.
 * @returns {boolean} - Returns `true` if the file extension matches a known image extension, `false` otherwise.
 * 
 * @example
 * isImageFile('photo.jpg'); // Returns true
 * isImageFile('document.pdf'); // Returns false
 */
export const isImageFile = (filename: string) => {
  const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'];
  const extension = filename.split('.').pop()?.toLowerCase() ?? '';
  return imageExtensions.includes(extension);
}
