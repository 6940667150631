import React, { useEffect, useState } from "react";
import { getSystemInfo } from "crypto-pro";
import styles from "../LoginNew.module.scss";
import { CheckIcon } from "../../../core/consts/redesign/icons/Check";
import { WarningIcon } from "../../../core/consts/redesign/icons/Warning";
import { ArrowRightIcon } from "../../../core/consts/redesign/icons/ArrowRight";
import { COLORS } from "../../../core/consts/redesign/commonRedesign";



const detectBrowser = (): string => {
  const userAgentRes = navigator.userAgent;
  let result = "other";
  if (/Edge\/\d+/.test(userAgentRes)) {
    result = "Microsoft Edge";
  } else if (/MSIE 9/.test(userAgentRes)) {
    result = "Explorer 9";
  } else if (/MSIE 10/.test(userAgentRes)) {
    result = "Explorer 10";
  } else if (/MSIE 11/.test(userAgentRes) || /rv\:11/.test(userAgentRes)) {
    result = "Explorer 11";
  } else if (/MSIE\s\d/.test(userAgentRes)) {
    result = "Explorer 8 and below";
  } else if (/Firefox\W\d/.test(userAgentRes)) {
    result = "Firefox";
  } else if (/Chrom(e|ium)\W\d|CriOS\W\d/.test(userAgentRes)) {
    result = "Google Chrome";
  } else if (/\bSafari\W\d/.test(userAgentRes)) {
    result = "Safari";
  } else if (/\bOpera\W\d/.test(userAgentRes)) {
    result = "Opera";
  }
  return result;
};

const detectOS = (): string => {
  const userAgentRes = navigator.userAgent;
  let result = "other";
  if (/Windows NT 10/.test(userAgentRes)) {
    result = "Windows 10";
  } else if (/Windows NT 11/.test(userAgentRes)) {
    result = "Windows 11";
  } else if (/Windows NT 6\.0/.test(userAgentRes)) {
    result = "Windows Vista";
  } else if (/Windows NT 6\.1/.test(userAgentRes)) {
    result = "Windows 7";
  } else if (/Windows NT 6\.\d/.test(userAgentRes)) {
    result = "Windows 8";
  } else if (/Windows NT 5\.1/.test(userAgentRes)) {
    result = "Windows XP";
  } else if (/Windows NT [1-5]\./.test(userAgentRes)) {
    result = "other";
  } else if (/Mac/.test(userAgentRes)) {
    result = "macOS";
  } else if (/Linux/.test(userAgentRes)) {
    result = "linux";
  }
  return result;
};

type systemInfo = {
  cadesVersion: string;
  cspVersion: string;
};

const CheckComponents: React.FC = () => {
  const [systemInfo, setSystemInfo] = useState<systemInfo>();
  const [isOS, setIsOS] = useState<boolean>();
  const [nameOS, setNameOS] = useState<string>();
  const [isBrowser, setIsBrowser] = useState<boolean>();
  const [nameBrowser, setNameBrowser] = useState<string>();

  useEffect(() => {
    const browser = detectBrowser();
    const os = detectOS();
    setNameOS(os);
    setIsOS(os !== "other");
    setNameBrowser(browser);
    setIsBrowser(browser !== "other");

    getSystemInfo()
      .then((res: systemInfo) => {
        setSystemInfo(res);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <div className={styles.main_column}>
      <div>
        Для входа в личный кабинет с помощью сертификата, у вас должны быть
        установлены все необходимые компоненты
      </div>
      <div className={styles.certif_column}>
        <div
          className={
            systemInfo?.cadesVersion
              ? styles.certif_box_green
              : styles.certif_box
          }
        >
          <div className={styles.certif_title}>Криптографический плагин</div>
          {systemInfo?.cadesVersion ? (
            <div className={styles.certif_flex}>
              <div>Версия плагина: {systemInfo?.cadesVersion}</div>
              <div>
                <CheckIcon />
              </div>
            </div>
          ) : (
            <>
              <div>Не установлены плагины:</div>
              <div className={styles.certif_flex}>
                <div>
                  КриптоПро ЭЦП Browser plug-in <br />и расширение для браузера
                </div>
                <div>
                  <WarningIcon />
                </div>
              </div>
              <a
                href="https://cryptopro.ru/products/csp"
                target="_blank"
                rel="noopener"
                className="blue_link"
              >
                установить плагин
                <ArrowRightIcon color={COLORS.brand_blue} />
              </a>

              <div className={styles.certif_flex}>
                <div>CryptoPro Extension for CAdES Browser Plug-in</div>
                <div>
                  <WarningIcon />
                </div>
              </div>
              <a
                href="https://www.cryptopro.ru/products/cades/plugin"
                target="_blank"
                rel="noopener"
                className="blue_link"
              >
                установить плагин
                <ArrowRightIcon color={COLORS.brand_blue} />
              </a>
            </>
          )}
        </div>

        <div
          className={
            systemInfo?.cspVersion ? styles.certif_box_green : styles.certif_box
          }
        >
          <div className={styles.certif_title}>Криптографическое ПО</div>

          {systemInfo?.cspVersion ? (
            <div className={styles.certif_flex}>
              <div>Версия криптопровайдера: {systemInfo?.cspVersion}</div>
              <div>
                <CheckIcon />
              </div>
            </div>
          ) : (
            <>
              <div>Не установлены плагины:</div>
              <div className={styles.certif_flex}>
                <div>
                  Криптопровайдер с поддержкой алгоритмов шифрования ГОСТ 34,
                  10-2012, ГОСТ 28147-89
                </div>
                <div>
                  <WarningIcon />
                </div>
              </div>
              <a
                href="https://cryptopro.ru/products/csp"
                target="_blank"
                rel="noopener"
                className="blue_link"
              >
                установить плагин
                <ArrowRightIcon color={COLORS.brand_blue} />
              </a>
            </>
          )}
        </div>

        <div
          className={
            isOS && nameOS !== "other"
              ? styles.certif_box_green
              : styles.certif_box
          }
        >
          <div className={styles.certif_title}>Операционная система</div>
          <div className={styles.certif_flex}>
            <div> {nameOS}</div>
            {isOS && nameOS !== "other" ? (
              <div>
                <CheckIcon />
              </div>
            ) : (
              <div>
                <WarningIcon />
              </div>
            )}
          </div>
        </div>

        <div
          className={
            isBrowser && nameBrowser !== "other"
              ? styles.certif_box_green
              : styles.certif_box
          }
        >
          <div className={styles.certif_title}>Браузер</div>
          <div className={styles.certif_flex}>
            <div> {nameBrowser}</div>
            {isBrowser && nameBrowser !== "other" ? (
              <div className={styles.icon}>
                <CheckIcon />
              </div>
            ) : (
              <div className={styles.icon}>
                <WarningIcon />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default CheckComponents;
