import * as _ from "lodash";
import {
  ConditionType,
  ErrorsData,
  ErrorsFields,
  ErrorsFiledsModal,
  ErrorsGroupFields,
  ErrorsResponse,
  Field,
  FieldModal,
  FieldType,
  FieldWithKey,
  FormField,
  PropsalData,
  ValidateForm,
  ValueOf
} from "../types/bigForm";
import { FieldsModalData } from "./handleResponseForBigForm";

/** Возвращает значение филда модалки из редакса */
export const takeModalValue = (
  fieldsValue: ErrorsFields | FormField,
  parentName: string,
  fieldName: string,
  indexGroupField?: number
) => {
  const modalField = fieldsValue?.[parentName];
  if (modalField && Array.isArray(modalField)) {
    if (indexGroupField) {
      const arrayModalField = modalField[indexGroupField] as
        | FieldModal
        | Record<string, string>;
      if (typeof arrayModalField !== "string") {
        return arrayModalField?.[fieldName];
      }
    }
  } else if (!Array.isArray(modalField) && _.isObject(modalField)) {
    const arrayModalField = modalField as FieldModal | Record<string, string>;
    return arrayModalField?.[fieldName];
  }
};

const handleFieldModal = (field: FieldType) => {
  if (field.dataType === "string" && field.isMultiple) {
    return [];
  } else if (field.dataType === "string") {
    return "";
  } else if (field.dataType === "combobox" && field.isMultiple) {
    return [];
  } else if (field.dataType === "combobox") {
    return "";
  } else if (field.dataType === "bool") {
    return false;
  } else {
    return "";
  }
};

/**Создание филдов для новой модалки */
export const handleFieldsNewMultipleModal = (
  modalData: FieldType
): FieldsModalData | undefined => {
  try {
    return modalData.childs.reduce<FieldsModalData>(
      (fieldWithValue, field) => {
        fieldWithValue.fieldsWithValues[field.name] = handleFieldModal(field);
        fieldWithValue.errorsFields[field.name] = "";
        return fieldWithValue;
      },
      {
        fieldsWithValues: {},
        errorsFields: {},
      }
    );
  } catch (e) {
    console.log("handleFieldsNewMultipleModal-->", e);
  }
};

export const takeModalData = (
  formData: PropsalData[] | undefined,
  propsalId: number,
  modalName: string
) => {
  if (formData) {
    console.log("takeModalData     formData", formData);
    console.log("takeModalData     propsalId", propsalId);

    // let propsal = formData.find((propsal) => {
    //   return propsal.id === propsalId;
    // });
    // if (!propsal) {
    //   formData.forEach(elem => {
    //     if (elem?.innerSteps) {
    //       propsal = elem.innerSteps.find(pr => pr.id === propsalId)
    //     }
    //   })
    // }
    const propsal = getFormDataForCurrentStep(formData, propsalId);
    console.log("takeModalData     propsal", propsal);
    if (propsal) {
      const modalField = propsal.fields.find(
        (field) => field.name === modalName
      );
      console.log("takeModalData     modalField", modalField);
      if (modalField) {

        const modalFieldData = handleFieldsNewMultipleModal(modalField);
        console.log("takeModalData     modalFieldData", modalFieldData);
        if (modalFieldData) {
          return modalFieldData;
        }
      }
    }
  }
};

// Подготовка данных к валидации

const identifierArrayType = (arr: any[]): string | undefined => {
  if (arr.length > 0) {
    if (arr.every((element) => typeof element === "string")) {
      return "string";
    } else if (arr.every((element) => element.hasOwnProperty("key"))) {
      return "FieldWithKey";
    } else if (
      arr.every(
        (element) =>
          typeof element === "object" &&
          element !== null &&
          !Array.isArray(element)
      )
    ) {
      return "object";
    }
  } else {
    return undefined;
  }
};

export const handleFieldsModal = (orderFormDataModal: FieldModal) => {
  return Object.entries(orderFormDataModal).reduce<Record<string, string>>(
    (modalDataForValidation, [key, value]) => {
      if (
        _.isObject(value) &&
        !Array.isArray(value) &&
        value.hasOwnProperty("key") &&
        !_.isEmpty(value)
      ) {
        const dontEmptyObject = value as FieldWithKey;
        modalDataForValidation[key] = dontEmptyObject.key;
        // } else if (Array.isArray(value) ) {
        //     modalDataForValidation[key] = value.map((selectValue) => selectValue.key);
      } else {
        modalDataForValidation[key] = value.toString();
      }
      return modalDataForValidation;
    },
    {}
  );
};

//Валидация модалки
export const handleFieldsModalValidation = (
  data: FieldModal | FieldModal[]
) => {
  if (Array.isArray(data)) {
    return data.map((newOrderModalData) => {
      return handleFieldsModal(newOrderModalData);
    });
  } else {
    return handleFieldsModal(data);
  }
};

export const handleFieldForRequest = (value: ValueOf<FormField>) => {
  if (_.isObject(value)) {
    if (!Array.isArray(value) && value.hasOwnProperty("key")) {
      const valueWithKey = value as FieldWithKey;
      return valueWithKey.key;
    } else if (Array.isArray(value)) {
      if (value.length === 0) {
        return JSON.stringify(value);
      } else {
        const typeArray = identifierArrayType(value);
        switch (typeArray) {
          case "string":
            return JSON.stringify(value);
          case "FieldWithKey":
            return JSON.stringify(
              (value as FieldWithKey[]).map((fieldWithKey) => fieldWithKey.key)
            );
          case "object":
            return JSON.stringify(
              handleFieldsModalValidation(value as FieldModal[])
            );
        }
      }
    } else if (
      typeof value === "object" &&
      value !== null &&
      !Array.isArray(value)
    ) {
      if (Object.keys(value).length === 0) {
        return JSON.stringify(value);
      }
      return JSON.stringify(handleFieldsModalValidation(value as FieldModal));
    }
  }
  return value.toString();
};

export const handleFieldForRequestNEW = (value: ValueOf<FormField>, fieldName: string, propsal?: PropsalData) => {
  if (_.isObject(value)) {
    if (!Array.isArray(value) && value.hasOwnProperty("key")) {
      const valueWithKey = value as FieldWithKey;
      return valueWithKey.key;
    } else if (Array.isArray(value)) { // если массив
      // console.log("handleFieldForRequestNEW Array.isArray(value) value", value)
      // console.log("handleFieldForRequestNEW Array.isArray(value) fieldName", fieldName)
      // console.log("handleFieldForRequestNEW Array.isArray(value) propsal", propsal)
      const field = propsal?.fields?.find(el => el.name === fieldName);
      // console.log("handleFieldForRequestNEW Array.isArray(value) field", field)


      if (value.length === 0) {
        // для поля group не multiple чтоб не отправлять массив
        if (field && field.dataType === "group" && field.isMultiple === false) {
          return JSON.stringify({});
        } else {
          return JSON.stringify(value);
        }
      } else {
        const typeArray = identifierArrayType(value);
        switch (typeArray) {
          case "string":
            return JSON.stringify(value);
          case "FieldWithKey":
            return JSON.stringify(
              (value as FieldWithKey[]).map((fieldWithKey) => fieldWithKey.key)
            );
          case "object":
            const data = handleFieldsModalValidation(value as FieldModal[])
            // для поля group не multiple чтоб не отправлять массив
            if (Array.isArray(data) && field && field.dataType === "group" && field.isMultiple === false) {
              console.log("handleFieldForRequestNEW Array.isArray(value) 111 data?.[0]", data?.[0])
              return JSON.stringify(data?.[0] ?? {});
            } else {
              console.log("handleFieldForRequestNEW Array.isArray(value) 222 data", data)
              return JSON.stringify(data);
            }
        }
      }
    } else if (
      typeof value === "object" &&
      value !== null &&
      !Array.isArray(value)
    ) {
      if (Object.keys(value).length === 0) {
        return JSON.stringify(value);
      }
      return JSON.stringify(handleFieldsModalValidation(value as FieldModal));
    }
  }
  return value.toString();
};

//Добавляет общие поля при мультизаявке к каждому предложению.
export const addSharedFields = (
  sharedFields: Record<string, string>,
  allPropsals: ValidateForm[]
) => {
  return allPropsals.map((propsal) => {
    return {
      data: { ...propsal.data, ...sharedFields },
      proposalId: propsal.proposalId,
      // proposals: propsal?.proposals,
    };
  });
};

//Добавляет общие поля при мультизаявке к каждому предложению.
export const addSharedFieldsNEW = (
  sharedFields: Record<string, string>,
  allPropsals: ValidateForm[]
) => {
  // console.log('addSharedFieldsNEW sharedFields', sharedFields)
  // console.log('addSharedFieldsNEW allPropsals', allPropsals)
  return allPropsals.map((propsal) => {
    // console.log('addSharedFieldsNEW propsal', propsal)
    return {
      data: { ...propsal.data, ...sharedFields },
      proposalId: propsal.proposalId,
      proposals: propsal?.proposals,
    };
  });
};

/**Обработка полей для отправки на бек. */
export const handleFieldsForSend = (
  orderFormData: Record<number, FormField>
) => {
  const propsals = Object.entries(orderFormData).map<ValidateForm>(
    ([propsalId, fields]) => {
      const stringFields: Record<string, string> = {};
      for (const fieldName in fields) {
        if (Object.prototype.hasOwnProperty.call(fields, fieldName)) {
          stringFields[fieldName] = handleFieldForRequest(fields[fieldName]);
        }
      }
      return {
        data: stringFields,
        proposalId: propsalId,
      };
    }
  );
  const propsalSharedFields = propsals.find(
    (propsal) => propsal.proposalId === "0"
  );
  const propsalsWithoutSharedFields = propsals.filter(
    (propsal) => propsal.proposalId !== "0"
  );
  if (propsalSharedFields && propsalsWithoutSharedFields) {
    return {
      dataForSend: addSharedFields(
        propsalSharedFields.data,
        propsalsWithoutSharedFields
      ),
      commonFields: Object.keys(propsalSharedFields.data),
    };
  }
  return {
    dataForSend: propsals,
    commonFields: undefined,
  };
};

export type THandleFieldsForSendNEWResult = {
  dataForSend: ValidateForm[];
  commonFields: string[] | undefined;
}

/**Обработка полей для отправки на бек для валидации. */
export const handleFieldsForSendNEW = (
  formValues: Record<number, FormField>,//orderFormData=formValues
  formData: PropsalData[],
  realProposalIds: number[],
): THandleFieldsForSendNEWResult => {
  console.log("handleFieldsForSend NEW formValues", formValues)
  console.log("handleFieldsForSend NEW formData", formData)
  // const notCommonSteps = formData.filter(elem => elem.id < 100 && elem?.proposals
  //   && elem.proposals?.length && elem.proposals.length === 1)
  // // console.log("handleFieldsForSend NEW notCommonSteps", notCommonSteps)
  // // общие шаги из formData
  // const commonSteps = formData.filter(elem => elem.id < 100 && elem?.proposals
  //   && elem.proposals?.length && elem.proposals.length > 1)
  // // console.log("handleFieldsForSend NEW commonSteps", commonSteps)

  // // внутренние шаги (т.е. не общие) из formData (тут вычленяем, так как выносятся на верхний уровень в orderFormData)
  // formData.forEach(formDataElem => {
  //   if (formDataElem?.innerSteps?.length && formDataElem?.innerSteps?.length > 0) {
  //     formDataElem.innerSteps.forEach(inner => {
  //       if (inner.proposals?.length && inner.proposals.length === 1) {
  //         notCommonSteps.push(inner)
  //       }
  //     })
  //   }
  // })

  // находим необщие proposals в orderFormData
  // const notCommonOrderFormData = Object.entries(orderFormData).
  //   filter(([orderFormId, orderFormItem]) => notCommonSteps.find(step => step.id === +orderFormId))
  // console.log("handleFieldsForSend NEW map notCommonOrderFormData", notCommonOrderFormData)

  const propsals = Object.entries(formValues).map<ValidateForm>(
    ([propsalId, fields]) => {

      const propsal = getFormDataForCurrentStep(formData, +propsalId);
      console.log("handleFieldsForSend NEW map getFormDataForCurrentStep propsal", propsal)
      const proposalIds = propsal?.proposals;
      // console.log("handleFieldsForSend NEW map proposalIds", proposalIds)
      const stringFields: Record<string, string> = {};
      for (const fieldName in fields) {
        // console.log("handleFieldsForSend NEW map fieldName", fieldName)
        // console.log("handleFieldsForSend NEW map fields", fields)
        // console.log("handleFieldsForSend NEW map hasOwnProperty", Object.prototype.hasOwnProperty.call(fields, fieldName).toString())
        if (Object.prototype.hasOwnProperty.call(fields, fieldName)) {
          stringFields[fieldName] = handleFieldForRequestNEW(fields[fieldName], fieldName, propsal);
        }
      }
      // console.log("handleFieldsForSend NEW map stringFields", stringFields)





      // // добавляем в необщие поля значения внутренних шагов
      // if (notCommonOrderFormData?.length > 0) {
      //   const currentOrderFormDataForAdding = notCommonOrderFormData.
      //     filter(el => propsal?.innerSteps?.find(inner => inner.id === +el[0]))
      //   // console.log("handleFieldsForSend NEW currentOrderFormDataForAdding", currentOrderFormDataForAdding)
      //   currentOrderFormDataForAdding.forEach(([id, fields]) => {
      //     for (const fieldName in fields) {
      //       if (Object.prototype.hasOwnProperty.call(fields, fieldName)) {
      //         stringFields[fieldName] = handleFieldForRequest(fields[fieldName]);
      //       }
      //     }
      //   })
      //   for (const fieldName in fields) {
      //     if (Object.prototype.hasOwnProperty.call(fields, fieldName)) {
      //       stringFields[fieldName] = handleFieldForRequest(fields[fieldName]);
      //     }
      //   }
      // console.log("handleFieldsForSend NEW map stringField22222", stringFields)

      // }


      return {
        data: stringFields,
        proposalId: propsalId,
        proposals: proposalIds
      };
    }
  );
  // console.log("!!!!!handleFieldsForSendNEW propsals", propsals)
  // const realProposalIdsSet = new Set();
  // propsals.forEach(pr => pr.proposals?.forEach(el => realProposalIdsSet.add(el?.id)))
  // const realProposalIds = Array.from(realProposalIdsSet)

  // console.log("handleFieldsForSendNEW realProposalIds", realProposalIds);

  const sharedProposals = propsals.filter(
    // (propsal) => +propsal.proposalId < 100
    (propsal) => +propsal.proposalId < 100
    // && propsal.proposals?.length && propsal.proposals?.length > 1
  );
  // console.log("handleFieldsForSendNEW sharedProposals", sharedProposals);
  // const notSharedProposals = propsals.filter(
  //   (propsal) => +propsal.proposalId >= 100
  // );

  const allProposals: ValidateForm[] = []
  realProposalIds.forEach(realProposalId => {
    let proposalData: ValidateForm | null = null;
    const proposalById = propsals.find(proposal => proposal.proposalId == realProposalId);
    const sharedProposalsForCurrentRealProposalId = sharedProposals
      .filter(proposal => proposal.proposals?.find(el => el.id == realProposalId));
    console.log("handleFieldsForSendNEW map sharedProposalsForCurrentRealProposalId", sharedProposalsForCurrentRealProposalId)
    if (proposalById) {
      proposalData = proposalById;
    } else {
      proposalData = {
        data: {},
        proposalId: realProposalId as number,
        proposals: [{ id: realProposalId as number }]
      }
    }
    sharedProposalsForCurrentRealProposalId.forEach(shared => {
      if (proposalData) {
        proposalData = {
          ...proposalData,
          data: { ...proposalData.data, ...shared.data }
        }
      }
    })
    // console.log("handleFieldsForSendNEW map proposalData", proposalData)


    allProposals.push(proposalData);

  })
  // console.log("handleFieldsForSendNEW allProposals", allProposals)
  // const commonProposalIds = []
  // console.log("handleFieldsForSendNEW propsals", propsals)
  // if (sharedProposals && notSharedProposals) {
  //   let commonFieldsNames: string[] = [];
  //   let commonFieldsRecords: Record<string, string> = {};
  //   sharedProposals.forEach(elem => {
  //     commonFieldsNames = [...commonFieldsNames, ...(Object.keys(elem.data))];
  //     commonFieldsRecords = { ...commonFieldsRecords, ...elem.data }
  //   })
  //   // console.log("handleFieldsForSendNEW commonFieldsNames", commonFieldsNames)


  //   return {
  //     dataForSend: addSharedFieldsNEW(
  //       commonFieldsRecords,
  //       notSharedProposals
  //     ),
  //     commonFields: commonFieldsNames,
  //   };
  // }
  if (sharedProposals) {
    let commonFieldsNames: string[] = [];
    // let commonFieldsRecords: Record<string, string> = {};
    sharedProposals.forEach(elem => {
      commonFieldsNames = [...commonFieldsNames, ...(Object.keys(elem.data))];
      // commonFieldsRecords = { ...commonFieldsRecords, ...elem.data }
    })
    // console.log("handleFieldsForSendNEW commonFieldsNames", commonFieldsNames)


    return {
      // dataForSend: addSharedFieldsNEW(
      //   commonFieldsRecords,
      //   notSharedProposals
      // ),
      dataForSend: allProposals,
      commonFields: commonFieldsNames,
    };
  }
  return {
    dataForSend: propsals.filter(proposal => realProposalIds.includes(+proposal.proposalId)),
    commonFields: undefined,
  };
};

/**Обработка валидации модалки */
export const handleErrorFieldsOfModal = (
  currentErrors: ErrorsResponse,
  errors: ErrorsFiledsModal,
  indexCurrentModal?: number
) => {
  const errorsFieldsModal: ErrorsFiledsModal = {};
  for (const fieldName in errors) {
    if (Object.prototype.hasOwnProperty.call(errors, fieldName)) {
      const error = currentErrors.errors.find((errorField) => {
        if (indexCurrentModal !== undefined) {
          return (
            errorField.subField === fieldName &&
            errorField.index === indexCurrentModal
          );
        } else {
          return errorField.subField === fieldName;
        }
      });
      if (error) {
        errorsFieldsModal[fieldName] = error.text;
      } else {
        errorsFieldsModal[fieldName] = "";
      }
    }
  }
  return errorsFieldsModal;
};

/**Обработка валидации модалки */
export const handleErrorFieldsOfModalNEW = (
  currentErrors: ErrorsResponse, // новые ошибки
  errors: ErrorsFiledsModal | ErrorsFiledsModal[], // предыдущий стейт ошибок
  field: FieldType | null// текущее полe 
) => {
  console.log("handleErrorFieldsOfModalNEW currentErrors", currentErrors)
  console.log("handleErrorFieldsOfModalNEW errors", errors)
  let errorsFieldsModal: ErrorsFiledsModal[] = [];
  const prevStateErrors = Array.isArray(errors) ? errors?.[0] : errors;
  if (!prevStateErrors) {
    return errors;
  }
  Object.keys(prevStateErrors).forEach(el => prevStateErrors[el] = "")
  console.log("handleErrorFieldsOfModalNEW prevStateErrors", prevStateErrors)
  // for (const fieldName in prevStateErrors) {
  //   if (Object.prototype.hasOwnProperty.call(errors, fieldName)) {
  //     const error = currentErrors.errors.find((errorField) => {
  //       if (indexCurrentModal !== undefined) {
  //         return (
  //           errorField.subField === fieldName &&
  //           errorField.index === indexCurrentModal
  //         );
  //       } else {
  //         return errorField.subField === fieldName;
  //       }
  //     });
  //     if (error) {
  //       errorsFieldsModal[fieldName] = error.text;
  //     } else {
  //       errorsFieldsModal[fieldName] = "";
  //     }
  //   }
  // }
  if (currentErrors?.errors?.length && currentErrors?.errors?.length > 0) {
    currentErrors.errors.forEach(error => {
      // console.log(error)
      if (error.subField && Object.prototype.hasOwnProperty.call(prevStateErrors, error?.subField)) {
        const currentIndex = error?.index ?? 0;
        if (!errorsFieldsModal?.[currentIndex]) { // если нет под нужным индексом объекта, создаем его и пушим в массив
          const newItem = {
            ...prevStateErrors,
            [error.subField]: error.text
          };
          errorsFieldsModal.push(newItem);
        } else {
          errorsFieldsModal[error?.index ?? 0][error.subField] = error.text
        }
      }
    })
  }
  if (errorsFieldsModal?.length === 0 && Array.isArray(errors)) {
    errorsFieldsModal = errors;
  }
  // errorsFieldsModal.forEach(err => {
  //   for (const fieldName in prevStateErrors) {
  //     if (!err?.[fieldName]) {
  //       err[fieldName] = ""
  //     }
  //   }
  // })
  console.log("handleErrorFieldsOfModalNEW errorsFieldsModal", errorsFieldsModal)
  // возвращаю в случае не multiple объект, иначе дальше ломается логика
  return errorsFieldsModal?.length > 1 || field?.isMultiple === true
    ? errorsFieldsModal
    : errorsFieldsModal?.[0]
      ? errorsFieldsModal?.[0] :
      prevStateErrors;
};

/**Обработка валидации модалки (для одного поля) */
export const handleErrorFieldsOfModalNEWForOneField = (
  currentErrors: ErrorsResponse,
  errors: ErrorsFiledsModal,
  indexCurrentModal?: number
) => {
  console.log("handleErrorFieldsOfModalNEW errors (prev)", errors)
  console.log("handleErrorFieldsOfModalNEW currentErrors", currentErrors)

  const errorsFieldsModal: ErrorsFiledsModal = { ...errors };
  // const errorsFieldsModal: ErrorsFiledsModal[] = [];
  if (currentErrors?.errors?.length && currentErrors?.errors?.length > 0) {
    currentErrors.errors.forEach(error => {
      // console.log(error)
      if (error.subField) {
        errorsFieldsModal[error.subField] = error.text
        // errorsFieldsModal[error?.index ?? 0][error.subField] = error.text
      }
    })
  }

  // 
  // for (const fieldName in errors) {
  //   if (Object.prototype.hasOwnProperty.call(errors, fieldName)) {
  //     const error = currentErrors.errors.find((errorField) => {
  //       console.log("handleErrorFieldsOfModalNEW errorField", errorField)

  //       if (indexCurrentModal !== undefined) {
  //         return (
  //           errorField.subField === fieldName &&
  //           errorField.index === indexCurrentModal
  //         );
  //       } else {
  //         return errorField.subField === fieldName;
  //       }
  //     });
  //     console.log("handleErrorFieldsOfModalNEW error", error)
  //     if (error) {
  //       errorsFieldsModal[fieldName] = error.text;
  //     } else {
  //       errorsFieldsModal[fieldName] = "";
  //     }
  //   }
  // }
  return errorsFieldsModal;
};


const handleErrorGroupField = (
  fieldName: string,
  currentErrors: ErrorsResponse
) => {
  const groupFieldErr = currentErrors.errors.filter((error) => {
    return error.field === fieldName && error.subField === null;
  });
  if (groupFieldErr.length > 0) {
    return { [fieldName]: true };
  }
  return undefined;
};

/** Обработка ошибок с бэка для филдов */
export const handleResponseValidationForm = (
  errors: ErrorsData,
  errorsResponse: ErrorsResponse
) => {
  // if (!errors?.fields) return;
  //Эта переменная для отображения ошибок груповых филдов
  let groupFieldsErr: ValueOf<ErrorsGroupFields> | undefined;
  // console.log("handleResponseValidationForm errors", errors)
  const errorsPropsal = Object.entries(errors?.fields)?.reduce<ErrorsFields>(
    (errorFields, [name, value]) => {
      if (!Array.isArray(value) && !_.isObject(value)) {
        const error = errorsResponse.errors.find(
          (errorField) => errorField.field === name
        );
        if (error) {
          errorFields[name] = error.text;
        } else {
          errorFields[name] = "";
        }
      } else if (Array.isArray(value)) {
        if (groupFieldsErr) {
          handleErrorGroupField(name, errorsResponse) &&
            (groupFieldsErr = {
              ...groupFieldsErr,
              ...handleErrorGroupField(name, errorsResponse),
            });
        } else {
          handleErrorGroupField(name, errorsResponse) &&
            (groupFieldsErr = {
              ...handleErrorGroupField(name, errorsResponse),
            });
        }
        errorFields[name] = value.map((modalErrors, index) => {
          return handleErrorFieldsOfModal(errorsResponse, modalErrors, index);
        });
      } else if (!Array.isArray(value) && _.isObject(value)) {
        if (groupFieldsErr) {
          handleErrorGroupField(name, errorsResponse) &&
            (groupFieldsErr = {
              ...groupFieldsErr,
              ...handleErrorGroupField(name, errorsResponse),
            });
        } else {
          handleErrorGroupField(name, errorsResponse) &&
            (groupFieldsErr = {
              ...handleErrorGroupField(name, errorsResponse),
            });
        }
        errorFields[name] = handleErrorFieldsOfModal(errorsResponse, value);
      }
      return errorFields;
    },
    {}
  );
  return {
    ...errors,
    isErrors: errorsResponse.isValid,
    fields: errorsPropsal,
    groupFieldsErr,
  };
};

/** Обработка ошибок с бэка для филдов (степ и итог)*/
export const handleResponseValidationFormNEW = ( //same
  errors: ErrorsData,
  errorsResponse: ErrorsResponse,
  formData: PropsalData[] | undefined
) => {
  // if (!errors?.fields) return;
  //Эта переменная для отображения ошибок груповых филдов
  let groupFieldsErr: ValueOf<ErrorsGroupFields> | undefined;
  console.log("handleResponseValidationFormNEW errors", errors)
  console.log("handleResponseValidationFormNEW errorsResponse", errorsResponse)
  const errorsPropsal = Object.entries(errors?.fields)?.reduce<ErrorsFields>(
    (errorFields, [name, value]) => {
      console.log("handleResponseValidationFormNEW errorFields", errorFields)
      console.log("handleResponseValidationFormNEW name", name)
      console.log("handleResponseValidationFormNEW value", value)

      // ищем поле в formData с целью определения, multiple ли оно
      let field: FieldType | null = null;
      formData?.forEach(elem => {
        if (!field) {
          elem.fields.forEach(el => {
            if (el.name === name) {
              field = el;
            }
          })
          if (!field && elem.innerSteps) {
            elem.innerSteps.forEach(inner => {
              inner.fields.forEach(el => {
                if (el.name === name) {
                  field = el;
                }
              })
            })
          }
        }
      })
      console.log("handleResponseValidationFormNEW field", field)

      if (!Array.isArray(value) && !_.isObject(value)) {
        console.log("handleResponseValidationFormNEW 1111")
        const error = errorsResponse.errors.find(
          (errorField) => errorField.field === name
        );
        if (error) {
          errorFields[name] = error.text;
        } else {
          errorFields[name] = "";
        }
      } else if (Array.isArray(value) || _.isObject(value)) {
        console.log("handleResponseValidationFormNEW 2222 value", value)

        // надо ли 
        if (groupFieldsErr) {//?????
          handleErrorGroupField(name, errorsResponse) &&
            (groupFieldsErr = {
              ...groupFieldsErr,
              ...handleErrorGroupField(name, errorsResponse),
            });
        } else {
          handleErrorGroupField(name, errorsResponse) &&
            (groupFieldsErr = {
              ...handleErrorGroupField(name, errorsResponse),
            });
        }
        // !!!!!!!!!!!!!!!!тут надо новую логику с индексом
        // errorFields[name] = value.map((modalErrors, index) => {
        //   return handleErrorFieldsOfModalNEW(errorsResponse, modalErrors, index);
        // });
        console.log("handleResponseValidationFormNEW 2222 handleErrorFieldsOfModalNEW(errorsResponse, value, field)",
          handleErrorFieldsOfModalNEW(errorsResponse, value, field))
        errorFields[name] = handleErrorFieldsOfModalNEW(errorsResponse, value, field);
        // console.log("handleResponseValidationFormNEW 2222 errorFields[name]", errorFields[name])
      } else if (!Array.isArray(value) && _.isObject(value)) {
        console.log("handleResponseValidationFormNEW 333")
        if (groupFieldsErr) {
          handleErrorGroupField(name, errorsResponse) &&
            (groupFieldsErr = {
              ...groupFieldsErr,
              ...handleErrorGroupField(name, errorsResponse),
            });
        } else {
          handleErrorGroupField(name, errorsResponse) &&
            (groupFieldsErr = {
              ...handleErrorGroupField(name, errorsResponse),
            });
        }
        errorFields[name] = handleErrorFieldsOfModalNEW(errorsResponse, value, field);
      }
      return errorFields;
    },
    {}
  );
  return {
    ...errors,
    isErrors: !errorsResponse.isValid,//!добавила
    fields: errorsPropsal,
    groupFieldsErr,
  };
};

/** Обработка ошибок с бэка для филдов */
export const handleResponseValidationFormNewForOneField = (
  errors: ErrorsData,//предыдущие ошибки для текущего степа
  errorsResponse: ErrorsResponse, // новые ошибки
  fieldName?: string
) => {
  // if (!errors?.fields) return;
  //Эта переменная для отображения ошибок груповых филдов
  // let groupFieldsErr: ValueOf<ErrorsGroupFields> | undefined;
  console.log("handleResponseValidationFormNewForOneField errors", errors)
  console.log("handleResponseValidationFormNewForOneField errorsResponse", errorsResponse)
  console.log("handleResponseValidationFormNewForOneField fieldName", fieldName)

  const commonErrorsFields: ErrorsFields = (fieldName && errorsResponse.isValid === true) ? { ...errors.fields, [fieldName]: "" } : { ...errors.fields };
  if (!fieldName) {
    Object.keys(commonErrorsFields).forEach(el => {
      commonErrorsFields[el] = ""
    })
  }
  if (errorsResponse?.errors?.length > 0) {
    // errorsResponse?.errors.forEach(elem => {
    //   commonErrorsFields[elem.field] = elem.text;
    // })

    // приходит 4 одинаковые ошибки, потому:
    const errorResponceFirst = errorsResponse?.errors[0];
    commonErrorsFields[errorResponceFirst.field] = errorResponceFirst.text;
  }
  let isErrores = false;
  Object.keys(commonErrorsFields).forEach(el => {
    if (!!commonErrorsFields[el]) {
      isErrores = true;
      // return;
    }
  })
  const commonErrors: ErrorsData = { fields: commonErrorsFields, isErrors: isErrores, id: errors.id }
  return commonErrors;
};

// SHOF IF

//condition: eq - равно, lt - меньше, lte - меньше или равно, gt - больше, gte - больше или равно

type ShowIfValue = string | number | boolean;

const takeDependentField = (
  dependentField: Exclude<Field, string[]>,
  showIfValue: ShowIfValue
): string | boolean | {} | FieldWithKey[] => {
  if (
    Array.isArray(dependentField) &&
    dependentField.every((val) => val.hasOwnProperty("key"))
  ) {
    const fieldValue = dependentField.find((val) => val.key === showIfValue);
    if (fieldValue) {
      return fieldValue.key;
    }
  } else if (
    !Array.isArray(dependentField) &&
    typeof dependentField === "object" &&
    "key" in dependentField
  ) {
    return dependentField.key;
  }
  return dependentField;
};

export const checkIsVisible = (
  condition: string,
  dependentField: Exclude<Field, string[]>,
  showIfValue: ShowIfValue
) => {
  const incomeVal = takeDependentField(dependentField, showIfValue);
  if (incomeVal !== undefined && incomeVal !== null) {
    if (condition === "lt") {
      return showIfValue < incomeVal;
    } else if (condition === "lte") {
      return showIfValue <= incomeVal;
    } else if (condition === "gt") {
      return showIfValue > incomeVal;
    } else if (condition === "gte") {
      return showIfValue >= incomeVal;
    } else if (condition === "eq") {
      return showIfValue.toString() == incomeVal.toString();
    } else if (condition === "any") {
      if (typeof incomeVal === "boolean") {
        return incomeVal;
      } else if (_.isObject(incomeVal)) {
        return Object.keys(incomeVal).length > 0;
      }
      return (incomeVal?.length > 0);
    }
  }
};

export const showIfCheck = (
  dependentField: Exclude<Field, string[]>,
  showIfArr: ConditionType[]
): boolean => {
  let isVisible = false;
  showIfArr.forEach((showIf) => {
    if (checkIsVisible(showIf.condition, dependentField, showIf.Value)) {
      isVisible = true;
    }
  });
  return isVisible;
};

/** Проверяет какое пустое значение поставить филду */
export const setInitialValueField = (
  field: FieldType
): string | [] | {} | boolean => {
  if (field.childs.length > 0) {
    //если групповой филд (модалка) с множественным выбором
    if (field.isMultiple) {
      return [];
      //если групповой филд (модалка) с единственным выбором
    } else {
      return {};
    }
  } else if (field.dataType === "string" && field.isMultiple) {
    return [];
  } else if (field.dataType === "string") {
    return "";
  } else if (field.dataType === "combobox" && field.isMultiple) {
    return [];
  } else if (field.dataType === "combobox") {
    return "";
  } else if (field.dataType === "bool") {
    return false;
  } else {
    return "";
  }
};

export const getFormDataForCurrentStep = (
  formData: PropsalData[],
  id: number
) => {
  let current = formData?.find((el) => el.id === id);
  if (current) {
    return current;
  } else {
    formData?.forEach((fdElem) => {
      if (fdElem?.innerSteps && fdElem?.innerSteps?.length > 0) {
        const foundedFD = fdElem.innerSteps.find(
          (innerElem) => innerElem.id === id
        );
        if (foundedFD) {
          current = foundedFD;
        }
      }
    });
  }
  return current;
};


export type TStepAndStepIndex = {
  parentIndex: number | null;
  childIndex: number | null;
  parent: PropsalData | null;
  child: PropsalData | null;
};

export const emptyParentAndChild: TStepAndStepIndex = {
  parentIndex: null,
  childIndex: null,
  parent: null,
  child: null,
};

export const getParentAndChild = (
  formData: PropsalData[] | undefined,
  currentStepId: number | undefined
): TStepAndStepIndex => {
  if (!formData || !currentStepId) return emptyParentAndChild;
  let parentIndex = formData?.findIndex((el) => el.id === currentStepId);
  if (parentIndex >= 0) {
    const parent = formData.find((el) => el.id === currentStepId);
    return {
      parentIndex: parentIndex,
      childIndex: null,
      parent: parent ?? null,
      child: null,
    };
  } else {
    let currentChild: PropsalData | undefined;
    let currentParent: PropsalData | undefined;
    formData?.forEach((fdElem) => {
      if (fdElem?.innerSteps && fdElem?.innerSteps?.length > 0) {
        const foundedFD = fdElem.innerSteps.find(
          (innerElem) => innerElem.id === currentStepId
        );
        if (foundedFD) {
          currentChild = foundedFD;
          currentParent = fdElem;
        }
      }
    });
    if (currentChild && currentParent && currentParent?.innerSteps) {
      const childIndex = currentParent?.innerSteps?.findIndex(
        (el) => el.id === currentChild?.id
      );
      const parentIndex = formData.findIndex(
        (el) => el.id === currentParent?.id
      );
      if (childIndex >= 0 && parentIndex >= 0) {
        return {
          parentIndex: parentIndex,
          childIndex: childIndex,
          parent: currentParent,
          child: currentChild,
        };
      }
    }
  }
  return emptyParentAndChild;
};
