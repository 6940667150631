import DateFnsUtils from "@date-io/date-fns";
import { Theme, createStyles, makeStyles } from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import ruLocale from "date-fns/locale/ru";
import React, { useEffect, useRef, useState } from "react";
import CustomPopover from "../../../../../UIcomponentsRedesign/CustomPopover/CustomPopover";
import InfoPopover from "../../../../../app/components/info-popover/InfoPopover";
import { COLORS } from "../../../../../core/consts/redesign/commonRedesign";
import { Calendar } from "../../../../../core/consts/redesign/icons";
import { useAppThunkDispatch } from "../../../../../core/store";
import { handleAutoFillFields } from "../../../../../core/store/thunk/newOrder";
import withFormHocs from "../../../../new-order/hoc/withFormHocs";
import { PropsField } from "../../../../new-order/types/bigForm";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    error: {
      fontSize: "12px",
      fontWeight: 500,
      lineHeight: "16px",
      color: COLORS.alert,
    },
    label: {
      color: `${COLORS.gray2} !important`,
      // top: "-12px",
      fontSize: "21px",
      lineHeight: "21px",
      fontWeight: 500,
      padding: "16px 12px 3px 12px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: "100%",
    },
    labelSmall: {
      top: "-18px",
      left: "0px",
      fontSize: "16px",
      lineHeight: "16px",
      fontWeight: 500,
    },
    datePicker: {
      whiteSpace: "nowrap",
      msTextOverflow: "hidden",
      width: "100%",
      minWidth: "280px",
      minHeight: "63px",
      boxSizing: "border-box",
      // padding: '14px 12px 11px 16px',
      textOverflow: "ellipsis",
      "& .MuiFormControl-root": {
        minHeight: "63px",
        padding: "14px 12px 6px 16px",
      },
      "& .MuiFormLabel-root": {
        fontSize: "21px",
        lineHeight: "21px",
        fontWeight: 500,
      },
      "& .MuiButtonBase-root": {
        height: "50px",
        marginTop: "25px",
      },
      "& .MuiInputBase-root": {
        margin: 0,
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        width: "100%",
        boxSizing: "border-box",
        minHeight: "63px",
        padding: "12px 12px 3px 16px",
        borderRadius: "4px",
        border: `1px solid ${COLORS.light_gray_stroke}`,
        position: "relative",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        "&:hover": {
          border: `1px solid ${COLORS.brand_blue}`,
        },
        "&:focused": {
          border: `1px solid ${COLORS.brand_blue}`,
        },
        "&.Mui-disabled": {
          border: `1px solid ${COLORS.light_gray_stroke}`,
        },
      },
      "& .MuiInputBase-input": {
        marginTop: "10px",
      },
      "& .MuiInput-underline:before, & .MuiInput-underline:after": {
        display: "none",
      },
      "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
        display: "none",
      },
      "& .MuiIconButton-label": {
        marginTop: "14px",
      },
      "& .MuiIconButton-root": {
        "&:hover": {
          backgroundColor: "transparent",
        },
        "&:active": {
          backgroundColor: "transparent !important",
        },
        "&:checked": {
          backgroundColor: "transparent !important",
        },
        "&:visited": {
          backgroundColor: "transparent !important",
        }
      },
      "& .MuiTouchRipple-root": {
        color: 'transparent'
      },
    },
    datePickerWithValue: {
      "& .MuiInputBase-root": {
        border: `1px solid ${COLORS.success}`,
      },
    },
    withError: {
      "& .MuiInputBase-root": {
        border: `1px solid ${COLORS.alert}`,
      },
    },
    popover: {
      backgroundColor: COLORS.black,
      display: "flex",
      flexDirection: "column",
      width: "100%",
      padding: "10px 16px",
      alignItems: "flex-start",
      justifyContent: "center",
      borderRadius: "4px 4px 4px 0px",
    },
  })
);

type Props = PropsField & {
  isRequired?: boolean;
  value?: string;
  info?: string;
};

const DateField: React.FC<Props> = ({
  field,
  info,
  isRequired,
  propsalId,
  value,
  text,
  error,
  onChange,
  parentName,
  indexGroupField,
  isVisible,
  className,
  classnamelabel,
  classNameShowIf,
  errorClassName,
  handleSaveDraftOnBlur,
}) => {
  const inlineClass = useStyles();
  const [dateState, setDateState] = useState<Date | null>(null);
  const [focus, setFocus] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const labelRef = useRef<HTMLDivElement>(null);
  const [isOverflowed, setIsOverflowed] = useState(false);
  const inputRef = useRef<HTMLInputElement>();
  const dispatch = useAppThunkDispatch();

  const hasValueClass = dateState ? inlineClass.datePickerWithValue : "";
  const hasValueClassLabel = focus ||
    (value && value?.length > 0) ||
    dateState || (inputRef.current && inputRef.current.value) ? inlineClass.labelSmall : '';

  const hasShowIf = field.show_if.length > 0;
  const errorClass = error ? inlineClass.withError : "";
  const popoverId = `popover-${field.text}`;

  const isValidDate = (date: Date) => {
    return date instanceof Date && !isNaN(date.getTime());
  };

  useEffect(() => {
    const checkOverflow = () => {
      const label = labelRef.current;
      if (label) {
        const isOverflowing = label.offsetWidth < label.scrollWidth;
        setIsOverflowed(isOverflowing);
      }
    };
    checkOverflow();
    window.addEventListener('resize', checkOverflow);
    return () => window.removeEventListener('resize', checkOverflow);
  }, [text]);

  useEffect(() => {
    if (value) {
      setDateState(new Date(value));
    }
  }, [value]);

  const handleFocus = (event: React.FocusEvent<HTMLElement>): void => {
    setFocus(true);
  };

  const handleBlur = () => {
    setFocus(false);
    if (field.autoFillUrl && value !== undefined && value.length > 0) {
      dispatch(
        handleAutoFillFields(
          field.autoFillUrl,
          value,
          propsalId,
          field.name,
          parentName,
          indexGroupField
        )
      );
    }
    //delay to fill autofill fields before validation
    if (handleSaveDraftOnBlur) {
      setTimeout(() => {
        handleSaveDraftOnBlur();
      }, 500)
    }
  };

  const onChangeValueInput = (date: Date | null) => {
    const addZero = (val: number): string => {
      return val > 9 ? val.toString() : "0" + val;
    };

    if (onChange) {
      if (date && isValidDate(date)) {
        const dateForState = `${date.getFullYear()}-${addZero(
          date.getMonth() + 1
        )}-${addZero(date.getDate())}`;

        if (dateForState.replace(/-/g, '').length < 8) {
          setDateState(null);
          onChange(
            field.name,
            "",
            propsalId,
            parentName && parentName,
            indexGroupField && indexGroupField
          );
        } else {
          setDateState(new Date(dateForState));
          onChange(
            field.name,
            dateForState,
            propsalId,
            parentName && parentName,
            indexGroupField && indexGroupField
          );
        }
      } else {
        setDateState(null);
        onChange(
          field.name,
          "",
          propsalId,
          parentName && parentName,
          indexGroupField && indexGroupField
        );
      }
    }
  };
  return (
    <>
      {field.upSeparator && (hasShowIf ? isVisible : true) && <div className="line_x" />}
      <div className={classNameShowIf} style={{ display: "flex", flexDirection: "column", width: "100%", gap: "8px" }}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
          <div style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
            <KeyboardDatePicker
              label={
                <div
                  ref={labelRef}
                  className={`${inlineClass.label} ${hasValueClassLabel}`}
                  style={{
                    maxWidth: "95%",
                    position: "relative",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {text}
                  {isRequired && <span style={{ color: "#DC5103" }}>*</span>}
                  {isOverflowed && <CustomPopover id={popoverId} label={text} />}
                </div>
              }
              autoOk
              disabled={field.isClientBlock}
              color="primary"
              variant="inline"
              format="dd.MM.yyyy"
              invalidDateMessage={""}
              maxDateMessage={""}
              minDateMessage={""}
              keyboardIcon={
                <div onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)} >
                  <Calendar color={isHovered ? COLORS.brand_blue : undefined} />
                </div>
              }
              className={`${inlineClass.datePicker} ${hasValueClass} ${errorClass}`}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              InputLabelProps={{
                shrink: true,
                className: inlineClass.label,
              }}
              InputProps={{
                inputRef: inputRef,
              }}
              value={dateState}
              onChange={onChangeValueInput}
              onFocus={handleFocus}
              onBlur={() => {
                handleBlur();
              }}
            />
            {text && info && <InfoPopover id={text} text={text} info={info} />}
          </div>
          {error && error?.length && (
            <div className={`error_input ${inlineClass.error}`}>{error}</div>
          )}
        </MuiPickersUtilsProvider>
      </div>
      {field.downSeparator && (hasShowIf ? isVisible : true) && <div className="line_x" />}
    </>
  );
};

export default withFormHocs(React.memo(DateField));
