import {
  Button,
  Grid,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import clsx from "clsx";
import React, { memo } from "react";
import { useSelector } from "react-redux";
import { ROLES } from "../../../../core/consts/common";
import { clientAgentInfoType } from "../../../../core/types/managerApiTypes";
import { rootState } from "../../../../core/types/rootState";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridItems: {
      display: "flex",
      // width: "100%",
      padding: "25px 30px",
      alignItems: 'center',

    },
    gridItem: {
      display: "flex",
      flexDirection: "column",
    },
    gridGardWrap: {
      display: "flex",
      width: "100%",
      padding: "30px 0",
      "&:not(:last-child)": {
        borderBottom: "1px solid  #D0D9DE",
      },
    },
    gridItemFirst: {
      paddingBottom: "10px",
    },
  })
);

type PropsType = {
  client: clientAgentInfoType;
  clientChatId: number;
  handleChatModalOpen: (
    id: number,
    inn: string,
    role: string,
    clientId?: string
  ) => void;
  handleClientInfoModalOpen: (userId: string, chatId: number) => void;
};

const ClientCard: React.FC<PropsType> = (props) => {
  const classes = useStyles();
  const role = useSelector((state: rootState) => state.currentUser.role)
  return (
    <Grid
      className={classes.gridItems}
      onClick={() =>
        props.handleClientInfoModalOpen(props.client.userId, props.clientChatId)
      }
    >
      <Grid item className={classes.gridItem} xl={3} lg={3} md={3}>
        {Boolean(props.client.companyName) && <Typography
          className={classes.gridItemFirst}
          variant="body1"
          component="span"
          style={{ fontWeight: 600 }}
        >
          {props.client.companyName}
        </Typography>}
        <Typography
          variant="body2"
          component="span"
        >
          {Boolean(props.client.inn) && `ИНН: ${props.client.inn} `}
          <Typography
            variant="body2"
            component="span"
            style={{
              color: `${props.client.role === "Клиент" ? "#309A90" : "#942BD4"
                }`,
            }}
          >
            {` ${props.client.role} `}
          </Typography>
        </Typography>
      </Grid>
      <Grid item className={classes.gridItem} xl={3} lg={3} md={3}>
        <Typography
          className={classes.gridItemFirst}
          variant="body1"
          component="span"
        >
          {props.client.region}
        </Typography>
        <Typography variant="body2" component="span">
          {props.client.localTime}
        </Typography>
      </Grid>
      <Grid
        item
        className={clsx(classes.gridItem, classes.gridItemFirst)}
        xl={2}
        lg={2}
        md={2}
      >
        {props.client.register_date}
      </Grid>
      <Grid
        item
        className={clsx(classes.gridItem, classes.gridItemFirst)}
        xl={3}
        lg={3}
        md={3}
      >
        <Typography
          variant="body1"
          component="span"
          style={{ fontWeight: 500 }}
        >
          {Boolean(props.client.agent.fio) && props.client.agent.fio}
        </Typography>
        <Typography variant="body2" component="span">
          {Boolean(props.client.agent.inn) && `ИНН: ${props.client.agent.inn}`}
        </Typography>
      </Grid>
      <Grid
        item
        className={clsx(classes.gridItem, classes.gridItemFirst)}
        xl={2}
        lg={2}
        md={2}
      >
        <div
          style={
            props.client.status === "Верифицирован"
              ? { color: "#69A042" }
              : props.client.status === "Лимит превышен" || props.client.status === "Регистрация не завершена"
                ? { color: "#D62D30" }
                : { color: "#617E8C" }
          }
        >
          {props.client.status}
        </div>
      </Grid>
      <Grid
        item
        className={clsx(classes.gridItem, classes.gridItemFirst)}
        xl={2}
        lg={2}
        md={2}
        style={{
          position: "relative",
          color: "#306F9A",
          textTransform: "none",
          lineHeight: "2",
        }}
      >
        {role !== ROLES.ADMIN &&
          <Button
            variant="text"
            color="primary"
            onClick={(event) => {
              event.stopPropagation();
              props.handleChatModalOpen(
                props.client.chatIdWithClient,
                props.client.inn,
                props.client.role,
                props.client.userId
              );
            }}
          >
            <Typography
              variant="body1"
              component="span"
              style={{ color: "inherit", textTransform: "none", lineHeight: "1.3" }}
            >
              Перейти в чат
            </Typography>
          </Button>
        }
      </Grid>
    </Grid>
  );
};

export default memo(ClientCard);
