import React, { useState } from "react";
import EmptyPage from "../../../../app/components/empty-page/EmptyPage";
import NoteCard from "../../../../app/components/notes/NoteCard";
import NoteCreateBox from "../../../../app/components/notes/NoteCreateBox";
import { CommonNotesInfoType, NoteType } from "../../../../core/api/noteApi";
import { COLORS } from "../../../../core/consts/redesign/commonRedesign";
type Props = {
  commonNotesInfo: CommonNotesInfoType | null;
  getNotes: () => void;
  leadId: number;
};

const NotesBlock: React.FC<Props> = ({ commonNotesInfo, getNotes, leadId }) => {
  const [isEdit, setIsEdit] = useState(false);
  const [chosenNote, setChosenNote] = useState<NoteType | null>(null);

  const notesLimit = commonNotesInfo && commonNotesInfo.notes.length >= 10
  return (
    <>
      {(!notesLimit || isEdit) &&
        <NoteCreateBox
          getNotes={getNotes}
          leadId={leadId}
          note={chosenNote}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          setChosenNote={setChosenNote}
        />}
      {commonNotesInfo && commonNotesInfo.notes.length > 0 ?
        (commonNotesInfo?.notes.map((elem, index) => (
          <div key={elem.id}>
            <NoteCard
              note={elem}
              setChosenNote={setChosenNote}
              setIsEdit={setIsEdit}
              getNotes={getNotes}
            />
          </div>
        ))) :
        <EmptyPage
          margin="56px"
          maxWidth="600px"
          gap="16px"
          // style={{
          //   position: 'fixed',
          //   maxWidth: "600px",
          //   top: "44%",
          //   left: "calc(50% + 60px)",
          //   transform: "translate(-50%, -50%)",
          // }}
          titleColor={COLORS.gray2}
          title='Здесь будут находиться ваши заметки'
          content="Чтобы создать заметку или напоминание нажмите “Добавить напоминание” "
        />}
    </>
  );
};

export default NotesBlock;
