import { Theme, createStyles, makeStyles } from "@material-ui/core";
import { COLORS } from "../../../../core/consts/redesign/commonRedesign";

export const useStylesAddClientModal = makeStyles((theme: Theme) => createStyles({
  // choose: {
  //   textDecoration: 'underline',
  //   padding: '25px 0',
  //   color: '#306F9A',
  //   '&:hover': {
  //     cursor: 'pointer',
  //   }
  // },
  container: {
    height: '100%',
    overflow: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  box: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    width: '100%'
  },
  title: {
    fontWeight: 600,
    fontSize: '41px',
    lineHeight: '45px',
    [theme.breakpoints.down(1024)]: {
      fontSize: '24px',
      lineHeight: '25px',
    },
  },

  boxSuccess: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'start',
    width: '100%'
  },
  paper: {
    maxWidth: '780px',
    width: '40%',
    backgroundColor: theme.palette.background.paper,
    border: 'none',
    padding: '50px 75px',
    borderRadius: '10px',
    margin: 'auto',
    [theme.breakpoints.down('lg')]: {
      width: '45%',
    },
    [theme.breakpoints.down('md')]: {
      width: '50%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '60%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '90%',
    },
  },
  paperSuccess: {
    maxWidth: '465px',
    backgroundColor: theme.palette.background.paper,
    border: 'none',
    padding: '20px',
    borderRadius: '10px',
    margin: '25px auto',
  },
  text: {
    padding: '0 0 30px 0',
    margin: '0',
    boxSizing: 'border-box',
  },
  searchInnClient: {
    paddingBottom: '30px',
    width: '100%',
  },
  gridInfo: {
    width: '100%',
    paddingTop: '20px',
    display: 'flex',
    flexDirection: 'column',
  },
  gridInfoRow: {
    display: 'flex',
    gap: '24px',
    paddingBottom: '15px',
    [theme.breakpoints.down(1024)]: {
      flexDirection: "column",
      gap: "4px",
      width: "100%"
    },

  },
  titleBox: {
    width: '28%',
    [theme.breakpoints.down(1024)]: {
      width: "100%"
    },
  },
  valueBox: {
    maxWidth: '480px',
  },
  gridInfoTitle: {
    fontFamily: 'Gilroy',
    fontWeight: 500,
    color: COLORS.gray2,
  },
  gridInfoValue: {
    fontFamily: 'Gilroy',
    fontWeight: 500,
    color: '#617E8C',
  },
  trafficLight: {
    paddingTop: '15px',
    flexDirection: 'column',
  },

  alertBox: {
    width: '100%',
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "8px",
    height: "34px",
    padding: "8px",
    borderRadius: "4px"
  },

  alertText: {
    fontFamily: 'Gilroy',
    fontStyle: 'normal',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '19px',
    color: COLORS.white,
  },

  infoIcon: {
    width: "14px",
  },

  buttonBox: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    gap: "24px",
    [theme.breakpoints.down(1024)]: {
      flexDirection: "column",
      gap: "8px"
    },
  },
  button: {
    marginTop: '10px',
    width: '300px'
  },
  trafficLightRow: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '20px',
  },
  isClientListText: {
    paddingLeft: '16px',
  },
}))