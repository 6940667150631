import {
  AppBar,
  Typography
} from "@material-ui/core";
import clsx from "clsx";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import ButtonCustom from "../../../../UIcomponentsRedesign/ButtonCustom/ButtonCustom";
import IconButtonMain from "../../../../UIcomponentsRedesign/IconButtonMain/IconButtonMain";
import { ROLES, ROUTES } from "../../../../core/consts/common";
import { COLORS } from "../../../../core/consts/redesign/commonRedesign";
import { Dots, Plus } from "../../../../core/consts/redesign/icons";
import { ArrowLeftIcon } from "../../../../core/consts/redesign/icons/ArrowLeft";
import useIsMobile from "../../../../core/hooks/useIsMobile";
import userService from "../../../../core/services/UserService";
import { actions } from "../../../../core/store";
import { rootState } from "../../../../core/types/rootState";
import HeaderAuth from "../../header-auth/HeaderAuth";
import BottomMenu from "./components/BottomMenu";
import { useHeaderStyles } from "./useHeaderStyles";
/** 
 * New Header component. Redesign. Used only for all logged in user roles.
 */

type PropsType = {
  title: string, //main title text
  subTitle?: string, //additional title text
  description?: any, //caption under the title
  showGoBack?: boolean, //left arrow
  centeredTitle?: boolean,
  children?: any,
  moveTitle?: boolean
};

const HeaderNew: React.FC<PropsType> = ({
  title = '',
  subTitle = '',
  description = '',
  showGoBack = false,
  centeredTitle,
  children,
  moveTitle = false
}) => {
  const location = useLocation();
  const currentRoute = location.pathname;
  const classes = useHeaderStyles();
  const [bottomMenuShown, setBottomMenuShown] = useState<boolean>(false);
  const initialUser = useSelector((state: rootState) => state.currentUser);
  const isAuth = initialUser.isAuth;
  const dispatch = useDispatch();
  const history = useHistory();
  const isMobile = useIsMobile();
  const isClient = initialUser.role === ROLES.CLIENT;
  const isAgent = initialUser.role === ROLES.AGENT;
  const fullTitle = subTitle ? `${title} ${subTitle}` : `${title}`;
  const innText = description ? `ИНН ${description}` : null
  const isAdminEditProducts = useRouteMatch('/admin/edit-products');
  const isAdminNewProductPage = useRouteMatch('/admin/edit-products/new-product');
  const isProductsPage = /\bproducts\b/.test(currentRoute);
  const isProductPage = /\bproduct\b/.test(currentRoute);
  const isProfilePage = /\bprofile\b/.test(currentRoute);
  const isNewOrderPage = /\/new-order/.test(currentRoute);
  const isFormNewOrderPage = /\/form-new-order/.test(currentRoute);
  const isArchivePage = currentRoute.includes(ROUTES.ARCHIVE);

  const checkAgentLimit = useMemo(() => async () => {
    try {
      const res = await userService.setUserLimitService();
      dispatch(actions.setCurrentUserAgentLimit(res));
    } catch (error) {
      console.error(error);
    }
  }, [dispatch]);

  useEffect(() => {
    if (isAgent) {
      checkAgentLimit();
    }
  }, [checkAgentLimit, isAgent]);

  const handleClickArchive = () => {
    const roleRoutes = {
      [ROLES.CLIENT]: `${ROUTES.CLIENT}/archive`,
      [ROLES.AGENT]: `${ROUTES.AGENT}/archive`,
      [ROLES.MANAGER]: `${ROUTES.MANAGER}/archive`,
      [ROLES.ADMIN]: `${ROUTES.ADMIN}/archive`,
    };
    const route = roleRoutes[initialUser.role] || '/';
    history.push(route);
  };

  const handleLeaveProducts = () => {
    const roleRoutes = {
      [ROLES.CLIENT]: `${ROUTES.CLIENT}/products`,
      [ROLES.AGENT]: `${ROUTES.AGENT}/products`,
      [ROLES.MANAGER]: `${ROUTES.MANAGER}/products`,
      [ROLES.PARTNER]: `${ROUTES.PARTNER}/applications`,
      [ROLES.ADMIN]: `${ROUTES.ADMIN}/products`,
    };
    const route = roleRoutes[initialUser.role] || '/';
    history.push(route);
  };

  const goBack = () => {
    if (isNewOrderPage) {
      if (isClient) {
        history.push(`${ROUTES.CLIENT}/products`)
      } else if (isAgent) {
        history.push(`${ROUTES.AGENT}/products`)
      }
    }
    if (isProductPage) {
      handleLeaveProducts();
    }
    else {
      history.goBack();
    }
  };

  const handleNewOrderClick = async () => {
    if (isClient) {
      history.push(`${ROUTES.CLIENT}/form-new-order`);
    } else if (isAgent) {
      history.push(`${ROUTES.AGENT}/form-new-order`);
      // const res = await userService.setUserLimitService();
      // if (res === true) {
      //   setLimitModal(true);
      // } else if (res === false) {
      //   history.push(`${ROUTES.AGENT}/form-new-order`);
      // }
    }
  };

  const handleBottomMenuToggle = () => {
    setBottomMenuShown(!bottomMenuShown);
  };

  //bottom menu items for all products page
  const items = [
    { text: "Новая заявка", onClick: () => handleNewOrderClick() },
    { text: "Архив заявок", onClick: () => handleClickArchive() },
  ];

  const itemsArchive = [
    { text: "Новая заявка", onClick: () => handleNewOrderClick() },
  ];

  return (
    <AppBar position="sticky" className={classes.appbar}>
      {/* <AddInnModal /> */}
      <div className={classes.container}>
        {centeredTitle && isMobile ?
          <>
            {showGoBack &&
              <IconButtonMain
                className={classes.iconMargin}
                style={{ width: '24px' }}
                color="noBgBlack"
                handleClick={() => goBack()}
                icon={<ArrowLeftIcon />} />
            }
            <div className={clsx(classes.overflow, classes.titleFlex)} style={{ marginRight: moveTitle ? '15%' : '0' }}>
              <Typography variant='h1' component='h1' className={classes.titleCentered}>
                {fullTitle}
              </Typography>
              <Typography variant='caption' component='span' className={classes.descriptionCentered}>{description}</Typography>
            </div>
          </> :
          <div className={classes.titleContainer}>
            {showGoBack &&
              <IconButtonMain
                className={classes.iconMargin}
                style={{ width: '24px' }}
                color="noBgBlack"
                handleClick={() => goBack()} icon={<ArrowLeftIcon />} />
            }
            <div className={classes.overflow}>
              <Typography variant='h1' component='h1' className={classes.title}>
                {fullTitle}
              </Typography>
              <Typography variant='caption' component='span' className={classes.description}>{description}</Typography>
            </div>
          </div>}
        <div className={classes.buttonContainer} style={{ margin: centeredTitle ? 0 : "auto 0 auto auto" }}>
          {isMobile && (isProductsPage || (isArchivePage && (isClient || isAgent))) ?
            (
              <>
                <IconButtonMain color="blue" handleClick={() => handleNewOrderClick()} icon={<Plus />} />
                <IconButtonMain color="noBgGray" handleClick={() => handleBottomMenuToggle()} icon={<Dots color={COLORS.gray2} />} />
                {bottomMenuShown ?
                  <BottomMenu
                    title={fullTitle}
                    description={innText}
                    items={isArchivePage ? itemsArchive : items}
                    onClose={() => handleBottomMenuToggle()} />
                  : null}
              </>
            ) : (
              <>
                {isProductsPage && !isAdminEditProducts ? (
                  <>
                    {children}
                    <ButtonCustom color="text_button" handleClick={handleClickArchive}>
                      Архив заявок
                    </ButtonCustom>
                    {(isClient || isAgent) &&
                      <ButtonCustom color="primary" handleClick={handleNewOrderClick}>
                        Новая заявка
                      </ButtonCustom>}
                  </>
                ) : null}
                {isFormNewOrderPage || isProductPage || isProfilePage ? (
                  <>
                    {children}
                  </>
                ) : null}

                {isArchivePage && (isClient || isAgent) ? (
                  <ButtonCustom color="primary" handleClick={handleNewOrderClick}>
                    Новая заявка
                  </ButtonCustom>
                ) : null}

                {(isAdminNewProductPage || isProductsPage) && null}
              </>
            )}

          {isAuth && !isMobile && <HeaderAuth />}
        </div>
        {/* {isLimitModal &&
          <LimitConfirmModal
            isOpen={isLimitModal}
            onClose={() => setLimitModal(false)}
          />} */}
      </div>
    </AppBar >
  );
};

export default HeaderNew;