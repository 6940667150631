import { combineReducers } from 'redux';
import { rootState } from '../../types/rootState';
import { authReducer } from './authRerucer';
import { bigFormReducer } from './bigFormReducer';
import { CartReducer } from './cartReducer';
import { chatReducer } from './chatReducer';
import { chatsReducer } from './chatsReducer';
import { countsReducer } from './countsReducer';
import { currentLeadReducer } from './currentLeadReducer';
import { currentUserReducer } from './currentUserReducer';
import { dataForOrderReducer } from './dataForOrderReducer';
import { newOrderReducer } from './newOrderReducer';
import { routeReducer } from './routeReducer';
import { tabsErrorsReducer } from './tabsErrorsReducer';

export const rootReducer = combineReducers<rootState>({
    auth: authReducer,
    bigForm: bigFormReducer,
    currentLead: currentLeadReducer,
    currentUser: currentUserReducer,
    currentChat: chatReducer,
    chats: chatsReducer,
    route: routeReducer,
    dataForOrder: dataForOrderReducer,
    tabsErrors: tabsErrorsReducer,
    newOrder: newOrderReducer,
    cart: CartReducer,
    counts: countsReducer
})

export type RootState = ReturnType<typeof rootReducer>