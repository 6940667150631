export interface CurrentLeadState {
  id?: number;
  isActive?: boolean;
  isArchive?: boolean;
  isDraft?: boolean;
}

export type SetCurrentLeadPayload = {
  id?: number;
  isActive?: boolean;
  isArchive?: boolean;
  isDraft?: boolean;
}

export enum CurrentLeadActionTypes {
  SET_LEAD = 'SET_LEAD'
}

export interface SetCurrentLeadAction {
  type: CurrentLeadActionTypes.SET_LEAD;
  payload?: SetCurrentLeadPayload
}

export type CurrentLeadActions = SetCurrentLeadAction;
