import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
import { FormType } from "../../app/enum";
import ArchiveFilteredProductsPage from "../../pages/archive-filtered-product/routing";
import ClientCounterpartyCardPage from "../../pages/client-counterparty-card/routing";
import ClientCounterpartySearchPage from "../../pages/client-counterparty-search/routing";
import ClientCounterpartyPage from "../../pages/client-counterparty/routing";
import ClientMessagesPage from "../../pages/client-messages/routing";
import ClientProductDetails from "../../pages/client-product-details/routing";
import ClientProducts from "../../pages/client-products/routing";
import ClientProfilePage from "../../pages/client-profile/routing";
import ClientSupportChat from "../../pages/client-support-chat/routing";
import KnowledgeBasePage from "../../pages/knowledge-base/routing";
import KnowledgePage from "../../pages/knowledge-page/routing";
import NewOrder from "../../pages/new-order/routing";
import NotFoundPage from "../../pages/not-found/routing";
import ProposalListPage from "../../pages/proposalList/routing";
import { ROUTES } from "../consts/common";
import { rootState } from "../types/rootState";

const detectDevice = (): string => {
  const userAgentRes = navigator.userAgent;
  let result = "desk";
  // if (/IEMobile|Windows Phone|Lumia/i.test(userAgentRes)) {
  //   result = "Windows Phone";
  // } else if (/iPhone|iP[oa]d/.test(userAgentRes)) {
  //   result = "iOS";
  // } else if (/Android/.test(userAgentRes)) {
  //   result = "Android";
  // } else if (/BlackBerry|PlayBook|BB10/.test(userAgentRes)) {
  //   result = "BlackBerry";
  // } else if (/Mobile Safari/.test(userAgentRes)) {
  //   result = "Mobile Safari";
  // } else if (
  //   /webOS|Mobile|Tablet|Opera Mini|\bCrMo\/|Opera Mobi/i.test(userAgentRes)
  // ) {
  //   result = "mob";
  // }
  return result;
};

const ClientRoutes = () => {
  const match = useRouteMatch();
  const isAuth = useSelector((s: rootState) => s.currentUser.isAuth);
  const history = useHistory();

  const block = () => {
    history.push({
      pathname: ROUTES.MAIN,
      search: `?formType=${FormType.LoginBlockMob}`,
    });
  };

  return (
    <>
      <Helmet>
        <title>Finlab - кабинет клиента</title>
        <meta
          name="description"
          content="Finlab.ru — финансовые услуги для бизнеса. Бесплатный подбор кредитов и 
                    лизинга для бизнеса, факторинг, банковские гарантии, РКО."
        />
      </Helmet>
      {/* <LoginModal /> */}
      {detectDevice() !== "desk" ? (
        block()
      ) : isAuth ? (
        <Switch>
          <Route path={`${match.path}/new-order`} exact>
            {NewOrder}
          </Route>
          <Route path={`${match.path}/form-new-order`} exact>
            {/* {ClientNewOrderPage} */}
            {ProposalListPage}
          </Route>
          <Route path={`${match.path}/products`} exact>
            {ClientProducts}
          </Route>
          <Route path={`${match.path}/archive`} exact>
            {ArchiveFilteredProductsPage}
          </Route>
          <Route path={`${match.path}/product/:tab/:productId/:leadId`} exact>
            {ClientProductDetails}
          </Route>
          <Route path={`${match.path}/profile`} exact>
            {ClientProfilePage}
          </Route>
          <Route path={`${match.path}/knowledge-base`} exact>
            {KnowledgeBasePage}
          </Route>
          <Route path={`${match.path}/knowledge-base/:cardId?`} exact>
            {KnowledgePage}
          </Route>
          <Route path={`${match.path}/counterparty-check`} exact>
            {ClientCounterpartyPage}
          </Route>
          <Route path={`${match.path}/counterparty-check/:serachVal`} exact>
            {ClientCounterpartySearchPage}
          </Route>
          <Route
            path={`${match.path}/counterparty-check/counterparty-info/:id`}
            exact
          >
            {ClientCounterpartyCardPage}
          </Route>
          <Route path={`${match.path}/support`} exact>
            {ClientSupportChat}
          </Route>
          <Route path={`${match.path}/messages`} exact>
            {ClientMessagesPage}
          </Route>
          {NotFoundPage}
        </Switch>
      ) : (
        <Redirect to={ROUTES.MAIN} />
      )}
    </>
  );
};

export default <Route component={ClientRoutes} path={ROUTES.CLIENT} />;
