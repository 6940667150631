import { makeStyles, createStyles, Theme } from "@material-ui/core";

export const DocumentCardUseStyles = makeStyles((theme: Theme) =>
  createStyles({
    docWrap: {
      display: "flex",
      alignItems: "center",
      marginTop: "15px",
      height: "70px",
      borderBottom: "1px solid #D0D9DE",
      justifyContent: "space-between",
       position: "relative",
    },
    docSubWrap: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      maxWidth: '80%',
    },
    document: {
      color: "#306F9A",
      display: "flex",
      alignItems: "center",
      "&:hover": {
        cursor: "pointer",
        color: "#204A66",
      },
    },
    documentEllipsis: {
      overflow: "hidden",
      width:'min-content',
      maxWidth: "450px",
      textOverflow: "ellipsis ellipsis",
      display: "inline-block",
      whiteSpace: "nowrap",
      position:'static',
      color: "#131313",
    },
    fileUploaded: {
      display: "flex",
      alignItems: "center",
      border: "none",
      outline: "none",
      color: "#131313",
      
      "&:hover": {
        cursor: "pointer",
      },
    },
    docName: {
      color: "#306F9A",
      "&:hover": {
        color: "#204A66",
      },
    },
    tooltip: {
      display: 'flex',
      justifyContent: "flex-start",
      maxWidth: '80%',
      "&:hover": {
        cursor: "pointer",
      },
    },
  })
);
