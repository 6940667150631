import React, { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import InfoPopover from "../../app/components/info-popover/InfoPopover";
import { COLORS, InputTypeEnum } from "../../core/consts/redesign/commonRedesign";
import { TickIcon } from "../../core/consts/redesign/icons/Tick";
import { getFieldType, getInputName } from "../../core/utils/inputTypeRules";
import CustomPopover from "../CustomPopover/CustomPopover";
import classes from "./InputMain.module.scss";

type PropsType = {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  info?: string;
  sumText?: string;
  isFullWidth?: boolean;
  onBlur?: () => void;
  disabled?: boolean;
  error?: string;
  isTextArea?: boolean;
  isNumber?: boolean;
  currencyFormat?: boolean;
  numberFormatProps?: any;
  isRequired?: boolean;
  inputType?: InputTypeEnum;
  className?: string;
};

const InputMain = ({
  value,
  onChange,
  label,
  info,
  sumText = "",
  isFullWidth,
  onBlur,
  disabled,
  error,
  isTextArea,
  isNumber,
  currencyFormat = false,
  isRequired,
  numberFormatProps,
  inputType,
  className,
}: PropsType) => {
  const ref = React.useRef<HTMLInputElement | null>(null);
  const refNum = React.useRef<HTMLInputElement | null>(null);
  const refArea = React.useRef<HTMLTextAreaElement | null>(null);
  const [isFocused, setIsFocused] = useState(false);
  const popoverId = `popover-${label}`;

  const fieldName = inputType && getInputName(inputType);
  const fieldType = inputType && getFieldType(inputType);

  useEffect(() => {
    const textarea = refArea.current;
    const adjustTextareaHeight = (el: any) => {
      el.style.height = 'auto';
      el.style.height = `${el.scrollHeight}px`;
      el.style.marginTop = '10px';
      el.style.marginBottom = '10px';
    };

    if (textarea) {
      adjustTextareaHeight(textarea);
      const onInput = () => adjustTextareaHeight(textarea);
      textarea.addEventListener('input', onInput);

      return () => textarea.removeEventListener('input', onInput);
    }
  }, []);

  return (
    <div
      className={`${classes.control_box} ${isFullWidth ? classes.w100 : ""
        }  ${className}`}
      onClick={() => {
        setIsFocused(true);
        if (isTextArea && ref.current) {
          refArea.current?.focus();
        } else if (currencyFormat && refNum.current) {
          refNum.current?.focus();
        } else if (ref.current) {
          ref.current?.focus();
        }
      }}
    // onBlur={() => {
    //   setIsFocused(false);
    // }}
    >
      <div
        className={`${classes.input_box} ${classes.w100}           
          ${error ? classes.error_box : ""} 
          ${isFocused ? classes.input_focus_border : ""} 
          ${value?.length > 0 && !error ? classes.filled : ""}
          ${disabled ? classes.disabled_box : ""}`}
        key={`key_${label}`}
      >
        {label && (
          <div
            className={`${(value && value.length > 0) || isFocused
              ? classes.label_small
              : classes.label_big
              }`}
          >
            {label}
            {isRequired && <span style={{ color: "#DC5103" }}> *</span>}
          </div>
        )}

        <div className={classes.flex_betw}>
          {isTextArea ? (
            <textarea
              ref={refArea as any}
              id={label}
              // autoFocus
              rows={1}
              className={`${classes.input} ${classes.textarea} ${label ? "" : classes.input_without_label
                } ${isFocused || (value && value.length > 0) ? "" : classes.hide
                }`}
              value={value}
              onChange={onChange as any}
              onBlur={() => {
                if (onBlur) {
                  onBlur();
                }
              }}
              disabled={disabled}
              name={fieldName}
            />
          ) : currencyFormat ? (
            <NumberFormat
              getInputRef={(inputEl: any) => {
                refNum.current = inputEl;
              }}
              key={label}
              value={value}
              onChange={onChange}
              className={`${classes.input} ${label ? "" : classes.input_without_label
                } ${isFocused || (value && value.length > 0) ? "" : classes.hide
                }`}
              onBlur={() => {
                if (onBlur) {
                  onBlur();
                }
              }}
              name={fieldName}
              {...numberFormatProps}
            />
          ) : isNumber ? (
            <NumberFormat
              getInputRef={(inputEl: any) => {
                refNum.current = inputEl;
              }}
              key={label}
              value={value}
              onChange={onChange}
              className={`${classes.input} ${label ? "" : classes.input_without_label
                } ${isFocused || (value && value.length > 0) ? "" : classes.hide
                }`}
              onBlur={() => {
                if (onBlur) {
                  onBlur();
                }
              }}
              name={fieldName}
              {...numberFormatProps}
            />
          ) : (
            <input
              ref={ref}
              id={label}
              key={label}
              // autoFocus
              className={`${classes.input} ${label ? "" : classes.input_without_label
                } ${isFocused || (value && value.length > 0) ? "" : classes.hide
                }`}
              value={value}
              onChange={onChange}
              onBlur={() => {
                if (onBlur) {
                  onBlur();
                }
              }}
              disabled={disabled}
              autoComplete={fieldName}
              type={fieldType}
              name={fieldName}
            />
          )}
          {label && !info && (
            <CustomPopover id={popoverId} label={label} value={value} />
          )}
          {value?.length > 0 && !error ? (
            <div className={classes.tick}>
              <TickIcon color={COLORS.success} />
            </div>
          ) : null}
          {label && info && <InfoPopover id={label} text={label} info={info} />}
        </div>
      </div>
      {error && (
        <div className="error_input" key="error">
          {error}
        </div>
      )}
    </div>
  );
};

export default React.memo(InputMain);
