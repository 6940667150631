import React from 'react';

import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core/styles';

import { MessageNotification } from '../../../core/api/chatsApi';
import {
  ROLES,
  ROUTES,
} from '../../../core/consts/common';
import * as selectors from '../../../core/store/selectors';
import NotificationMessage from '../notification-massage';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    base: {
      position: "fixed",
      zIndex: 9999,
      display: "flex",
      flexDirection: "column-reverse",
    },
    rightTop: {
      right: "30px",
      top: "30px",
    },
    rightBottom: {
      right: "30px",
      bottom: "30px",
    },
  })
);

type Props = {
  notifications: MessageNotification[];
  position: "rightTop" | "rightBottom";
  isOpenChat: boolean;
  openChatModal: (isOpen: boolean, clientName: string, chatId: number) => void;
};

const NotificationsContainer: React.FC<Props> = ({
  notifications,
  position,
  isOpenChat,
  openChatModal,
}) => {
  const history = useHistory();
  const initialUser = useSelector(selectors.initialUser);
  const maxHeightNotification = 170;
  const classes = useStyles();
  const [isVisible, setIsVisible] = React.useState<boolean>(true);
  const [countNotifications, setCountNotifications] = React.useState<number>();

  React.useEffect(() => {
    setCountNotifications(
      Math.round(window.innerHeight / maxHeightNotification)
    );
  }, []);

  React.useEffect(() => {
    setIsVisible(true);
  }, [notifications]);

  const routeTo = (path: string, partnerPath?: string) => {
    switch (initialUser.role) {
      case ROLES.CLIENT:
        history.push(`${ROUTES.CLIENT}${path}`);
        break;
      case ROLES.AGENT:
        history.push(`${ROUTES.AGENT}${path}`);
        break;
      case ROLES.MANAGER:
        history.push(`${ROUTES.MANAGER}${path}`);
        break;
      case ROLES.ADMIN:
        history.push(`${ROUTES.ADMIN}${path}`);
        break;
      case ROLES.PARTNER:
        history.push(`${ROUTES.PARTNER}${partnerPath}`);
        break;
      default:
        break;
    }
  };

  const createPath = (message: MessageNotification) => {
    switch (message.typeChat) {
      case "Chat":
        break;
      case "Bank":
        if (message.leadId && message.productId) {
          routeTo(`/product/2/${message.productId}/${message.leadId}`, `/application/2/${message.leadId}`); //TODO: adjust here. tab is info tab number
          setIsVisible(false);
        }
        break;
      case "Role":
        routeTo("/support");
        setIsVisible(false);
        break;
      case "ManagerChat":
        if (!isOpenChat) {
          openChatModal(true, message.authorMessage, message.chatId)
        }
        // setManagerChatData({
        //   chatId: message.chatId,
        //   clientName: message.authorMessage,
        // });
        // setOpenChatModal(true);
        setIsVisible(false);
        break;
      case "ManagerLead":
        if (message.leadId && message.productId) {
          routeTo(`/product/2/${message.productId}/${message.leadId}`); //TODO: adjust here
          setIsVisible(false);
        }
        break;
      default:
        break;
    }
  };

  const onClickMessage = (
    e: React.MouseEvent<HTMLElement>,
    message: MessageNotification
  ) => {
    createPath(message);
  };

  return (
    <>
      {isVisible ? (
        <div className={clsx(classes.base, classes[position])}>
          {notifications &&
            notifications.length > 0 &&
            notifications.map((message, i) => {
              if (
                countNotifications &&
                i > notifications.length - countNotifications
              ) {
                return (
                  <NotificationMessage
                    key={i}
                    position={position}
                    bodyText={message.message}
                    fullName={message.authorMessage}
                    avatarUrl={message.avatarUrl}
                    message={message}
                    onClickMessage={onClickMessage}
                  />
                );
              } else {
                return null;
              }
            })}
        </div>
      ) : null}
    </>
  );
};

export default React.memo(NotificationsContainer);
