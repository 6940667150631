import { Breadcrumbs, Theme, Typography, createStyles, makeStyles } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import { ROUTES } from "../../../core/consts/common";
import { rubric, tag } from "../../../core/types/articlesTypes";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    breadcrumbsContainer: {
      display: "flex",
      paddingTop: "35px",
      paddingBottom: "25px",
      overflowX: 'auto',
      [theme.breakpoints.down("md")]: {
        paddingTop: "5px",
      },
      [theme.breakpoints.down("sm")]: {
        display: 'none',
      },
    },
  }))

type propsType = {
  pageName: string;
  isPrivateClients?: boolean;
  isNewsPage?: boolean;
  rubric?: rubric;
  tag?: tag;
  isGeoProduct?: string;
  productAlias?: string;
};

const firstLevelPages: string[] = [
  "О компании - FINLAB",
  "Агентам",
  "Контакты",
  "Бизнесу и ИП",
  "Частным клиентам",
  "Новости",
  "Статьи",
]

const CommonBack: React.FC<propsType> = ({ pageName, isPrivateClients = false, isNewsPage = false, rubric = undefined, isGeoProduct = undefined, productAlias = undefined, tag = undefined, }) => {

  const classes = useStyles();

  return (
    firstLevelPages.includes(pageName) ?
      <Breadcrumbs className={classes.breadcrumbsContainer}>
        <Link color="primary"
          to={ROUTES.MAIN}
          style={{ textDecoration: "none", display: "block", }}
        >
          <Typography
            color="primary"
            variant="body1"
            component="p"
            style={{ whiteSpace: "nowrap" }}
          >
            Главная
          </Typography>
        </Link>
        <Typography
          style={{
            color: "#B0BFC6",
            whiteSpace: "nowrap",
          }}>
          {pageName}
        </Typography>
      </Breadcrumbs> :
      <Breadcrumbs className={classes.breadcrumbsContainer}>
        <Link
          color="primary"
          to={ROUTES.MAIN}
          style={{
            textDecoration: "none",
            display: "block",
          }}
        >
          <Typography
            color="primary"
            variant="body1"
            component="p"
            style={{ whiteSpace: "nowrap" }}
          >
            Главная
          </Typography>
        </Link>
        <Link
          color="primary"
          to={isPrivateClients ?
            ROUTES.PRIVATE_CLIENTS :
            isNewsPage ?
              ROUTES.NEWS :
              rubric || tag ? ROUTES.ARTICLES : ROUTES.PRODUCTS}
          style={{
            textDecoration: "none",
            display: "block",
          }}
        >
          <Typography
            color="primary"
            variant="body1"
            component="p"
            style={{ whiteSpace: "nowrap" }}
          >
            {isPrivateClients ?
              `Частным клиентам` :
              isNewsPage ?
                'Новости' :
                rubric || tag ? `Статьи` : `Продукты`}
          </Typography>
        </Link>
        {/* {rubric && rubric?.name != pageName &&
          <Link
            color="primary"
            to={`${ROUTES.ARTICLES}${rubric?.alias}`}
            style={{
              textDecoration: "none",
              display: "block",
            }}
          >
            <Typography
              color="primary"
              variant="body1"
              component="p"
              style={{ whiteSpace: "nowrap" }}
            >
              {rubric?.name}
            </Typography>
          </Link>} */}
        {isGeoProduct && isGeoProduct !== pageName &&
          <Link
            color="primary"
            to={`${ROUTES.MAIN}${productAlias}`}
            style={{
              textDecoration: "none",
              display: "block",
            }}
          >
            <Typography
              color="primary"
              variant="body1"
              component="p"
              style={{ whiteSpace: "nowrap" }}
            >
              {isGeoProduct}
            </Typography>
          </Link>}
        <Typography
          style={{ color: "#B0BFC6", whiteSpace: "nowrap" }}
        >
          {pageName}
        </Typography>
      </Breadcrumbs>
  );
};
export default CommonBack;
