import { FieldModal } from "../../../pages/new-order/types/bigForm";
import {
  NewOrderAction,
  newOrderActionTypes,
  NewOrderState,
} from "../../types/NewOrderReducer";

const initialState: NewOrderState = {
  formValues: undefined,
  errorsFields: undefined,
  errorsGroupFields: undefined,
  countErrors: undefined,
  formData: undefined,
  currentModalData: {
    propsalId: undefined,
    modalName: undefined,
    indexEditModal: undefined,
    isMultiple: undefined,
    proposals: undefined,
  },
  isFetching: false,
  currentEditModal: {
    values: undefined,
    errors: undefined,
  },
  alertModal: {
    tittle: undefined,
    text: undefined,
    isError: false,
    confModal: false,
    draftModal: false,
    isRemain: false,
    isPromptShow: true,
  },
};

export const newOrderReducer = (
  state = initialState,
  action: NewOrderAction
) => {
  switch (action.type) {
    //Сетает все данные формы пришедшие с бека
    case newOrderActionTypes.SET_FORM_DATA:
      return {
        ...state,
        formData: action.payload,
      };
    //Удаляет все данные заявки.
    case newOrderActionTypes.DELETE_NEW_ORDER_DATA:
      return {
        ...state,
        formValues: undefined,
        errorsFields: undefined,
        countErrors: undefined,
        formData: undefined,
        isFetching: false,
      };
    //Сетает ошибки формы.
    case newOrderActionTypes.SET_ERRORS_FIELDS:
      return {
        ...state,
        errorsFields: action.payload,
      };
    //Сетает ошибки определенного предложения
    case newOrderActionTypes.SET_PROPSAL_ERRORS:
      const { propsalId, errors } = action.payload;
      return {
        ...state,
        errorsFields: {
          ...state.errorsFields,
          [propsalId]: errors,
        },
      };
    //Сетает значение процесса загрузки формы
    case newOrderActionTypes.SET_IS_FETCHING:
      return {
        ...state,
        isFetching: action.payload.isFetching,
      };
    //Сетает количество ошибок в форме
    case newOrderActionTypes.SET_ERRORS_FORM_COUNT:
      return {
        ...state,
        countErrors: action.payload.errorsCount,
      };
    //Сетает значения формы.
    case newOrderActionTypes.SET_FORM_VALUES:
      return {
        ...state,
        formValues: action.payload,
      };
    //Сетает значение филду в текущей модалки (т.е. открытой для редактирования)
    case newOrderActionTypes.SET_VALUE_FIELD_CURRENT_MODAL:
      // const { fieldName, value } = action.payload;
      // const { fieldName, value, modalIndex } = action.payload;
      if (state.currentEditModal.values === undefined) return state;
      const newValues = [...state.currentEditModal.values];
      const index = action.payload.modalIndex ?? 0;
      newValues[index][action.payload.fieldName] = action.payload.value;

      return {
        ...state,
        currentEditModal: {
          ...state.currentEditModal,
          // values: {
          // ...state.currentEditModal.values,
          // [fieldName]: value,
          // },
          values:
            newValues
          ,
        },
      };
    //Сетает ошибки в текущей модалки (т.е. открытой для редактирования)
    case newOrderActionTypes.SET_CURRENT_MODAL_ERRORS:
      const indexError = action.payload.modalIndex ?? 0;
      const newErrores = state.currentEditModal.errors ? [...state.currentEditModal.errors] : [];
      newErrores[indexError] = action.payload.errors;
      return {
        ...state,
        currentEditModal: {
          ...state.currentEditModal,
          // errors: action.payload.errors,
          errors: newErrores,
        },
      };
    //Сетаются значения для текущей модалки (которая редактируется или создается)
    case newOrderActionTypes.SET_CURRENT_MODAL_DATA:
      return {
        ...state,
        currentModalData: {
          ...state.currentModalData,
          propsalId: action.payload.propsalId,
          modalName: action.payload.modalName,
          indexEditModal: action.payload.index,
          isMultiple: action.payload.isMultiple,
          proposals: action.payload?.proposals,
        },
      };
    //Сетает значения текущей модалки.
    case newOrderActionTypes.SET_CURRENT_EDIT_MODAL:
      const newValuesCom = state.currentEditModal.values ? [...state.currentEditModal.values] : [];
      const newErroresCom = state.currentEditModal.errors ? [...state.currentEditModal.errors] : [];
      const indexCom = action.payload?.modalIndex ?? 0;
      // newValuesCom[indexCom] = action.payload.values!==undefined ? action.payload.values : [];
      return {
        ...state,
        currentEditModal: {
          // values: action.payload.values,
          // errors: action.payload.errors,
          values: action.payload.values ? [...newValuesCom, action.payload.values] : [...newValuesCom],
          errors: action.payload.errors ? [...newErroresCom, action.payload.errors] : [...newErroresCom]
        },
      };

    //Сетает значения текущей модалки.
    case newOrderActionTypes.SET_CURRENT_EDIT_MODAL_MULTIPLE:
      return {
        ...state,
        currentEditModal: {
          values: action.payload.values,
          errors: action.payload.errors
        },
      };
    // Записываются ошибки груповых филдов.
    case newOrderActionTypes.SET_GROUP_FIELD_ERROR:
      return {
        ...state,
        errorsGroupFields: { ...state.errorsGroupFields, ...action.payload },
      };
    // Записываются ошибки груповых филдов.
    case newOrderActionTypes.CLEAR_GROUP_FIELD_ERROR:
      return {
        ...state,
        errorsGroupFields: undefined,
      };
    //Сетает значение филда
    case newOrderActionTypes.UPDATE_FIELD_VALUE:
      if (state.formValues) {
        return {
          ...state,
          formValues: {
            ...state.formValues,
            [action.payload.propsalId]: {
              ...state.formValues[action.payload.propsalId],
              ...action.payload.field,
            },
          },
        };
      }
      return state;
    //Удаляет аккордион модалки с единственным выбором
    case newOrderActionTypes.DELETE_MODAL_ACCORDION:
      if (state.formValues) {
        return {
          ...state,
          formValues: {
            ...state.formValues,
            [action.payload.propsalId]: {
              ...state.formValues[action.payload.propsalId],
              [action.payload.fieldName]: {},
            },
          },
        };
      }
      return state;
    //Сетает значение для модалки
    case newOrderActionTypes.SET_MODAL_FIELD:
      if (state.formValues && state.errorsFields) {
        return {
          ...state,
          formValues: {
            ...state.formValues,
            [action.payload.propsalId]: {
              ...state.formValues[action.payload.propsalId],
              [action.payload.fieldName]: action.payload.value,
            },
          },
          // errorsFields: {
          //   ...state.errorsFields,
          //   [action.payload.propsalId]: {
          //     ...state.errorsFields[action.payload.propsalId],
          //     fields: {
          //       ...state.errorsFields[action.payload.propsalId].fields,
          //       [action.payload.fieldName]: action.payload.errors,
          //     },
          //   },
          // },
        };
      }
      return state;
    // Сетает значения для модалки с множественным выбором.
    case newOrderActionTypes.SET_MULTIPLE_MODAL_FIELD:
      if (state.formValues && state.errorsFields) {
        const { propsalId, fieldName, index, value, errors } = action.payload;
        if (index !== undefined) {
          const valueArray = state.formValues[propsalId][
            fieldName
          ] as FieldModal[];
          const errorsArray = state.errorsFields[propsalId].fields[
            fieldName
          ] as Record<string, string>[];
          valueArray[index] = value;
          errorsArray[index] = errors;
          return {
            ...state,
            formValues: {
              ...state.formValues,
              [propsalId]: {
                ...state.formValues[propsalId],
                [fieldName]: valueArray,
              },
            },
            errorsFields: {
              ...state.errorsFields,
              [propsalId]: {
                ...state.errorsFields[propsalId],
                fields: {
                  ...state.errorsFields[propsalId].fields,
                  [fieldName]: errorsArray,
                },
              },
            },
          };
        } else {
          return {
            ...state,
            formValues: {
              ...state.formValues,
              [propsalId]: {
                ...state.formValues[propsalId],
                [fieldName]: [
                  ...(state.formValues[propsalId][fieldName] as FieldModal[]),
                  value,
                ],
              },
            },
            errorsFields: {
              ...state.errorsFields,
              [propsalId]: {
                ...state.errorsFields[propsalId],
                fields: {
                  ...state.errorsFields[propsalId].fields,
                  [fieldName]: [
                    ...(state.errorsFields[propsalId].fields[
                      fieldName
                    ] as Record<string, string>[]),
                    errors,
                  ],
                },
              },
            },
          };
        }
      }
      return state;
    //Удаляет аккордион у модалки с множественным выбором
    case newOrderActionTypes.DELETE_MULTIPLE_MODAL_ACCORDION:
      // console.log("DELETE_MULTIPLE_MODAL_ACCORDION state.formValues", state.formValues)
      if (state.formValues) {
        const modalField = state.formValues[action.payload.propsalId][
          action.payload.fieldName
        ] as FieldModal[];

        // console.log("DELETE_MULTIPLE_MODAL_ACCORDION modalField", modalField)
        const newCurrentEditModal = {
          values: state.currentEditModal.values?.filter((el, i) => i !== action.payload?.index),
          errors: state.currentEditModal.errors?.filter((el, i) => i !== action.payload?.index)
        };
        if (Array.isArray(modalField)) {
          const newModalFieldValue = modalField.filter(
            (val, i) => i !== action.payload.index
          );
          return {
            ...state,
            formValues: {
              ...state.formValues,
              [action.payload.propsalId]: {
                ...state.formValues[action.payload.propsalId],
                [action.payload.fieldName]: newModalFieldValue,
              },
            },
            currentEditModal: newCurrentEditModal
          };
        } else {
          return {
            ...state,
            currentEditModal: newCurrentEditModal
          };
        }

      }
      return state;
    //Удаляет предложение
    case newOrderActionTypes.DELETE_FROM_FORM_PROPSAL:
      return {
        ...state,
        formValues: action.payload.values,
        errorsFields: action.payload.errors,
        formData: action.payload.formData,
      };
    //Устанавливает значения для модалки.
    case newOrderActionTypes.SET_ALERT_MODAL:
      return {
        ...state,
        alertModal: {
          tittle: action.payload.tittle,
          text: action.payload.text,
          isError: action.payload.isError,
          confModal: action.payload.confModal,
          draftModal: action.payload.draftModal,
          isRemain: action.payload.isRemain,
          isPromptShow: action.payload.isPromptShow,
        },
      };
    default:
      return state;
  }
};
