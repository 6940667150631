import {
  Box,
  Tab,
  Tabs,
  Theme,
  makeStyles
} from "@material-ui/core";
import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 } from "uuid";
import { ChatByType } from "../../../../core/api/chatsApi";
import { COLORS, ROLES } from "../../../../core/consts/redesign/commonRedesign";
import chatService from "../../../../core/services/ChatsService";
import { actions } from "../../../../core/store";
import { selectNeededDocsCountByLeadId } from "../../../../core/store/selectors/counts";
import {
  leadFullInfo
} from "../../../../core/types/leadsApiTypes";
import { clientStatuses } from "../../../../core/types/managerApiTypes";
import { rootState } from "../../../../core/types/rootState";
import { transformChatData } from "../../../../core/utils/chatsUtils";

/* Redesign. Simplified tabs for products */

const useStyles = makeStyles((theme: Theme) => ({
  appbar: {
    backgroundColor: COLORS.white,
    color: COLORS.success,
    textTransform: "none",
    padding: "0",
    boxShadow: "none",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    boxSizing: "border-box",
    width: "100%",
    borderRadius: '10px',
  },
  tabs: {
    position: "relative",
    // borderBottom: "1px solid #D0D9DE",
    display: "flex",
    [theme.breakpoints.down("md")]: {
      "&>div": {
        overflowX: "scroll !important",
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": {
          display: "none",
        },
      },
    },
  },
  tab: {
    fontFamily: "Gilroy",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "1.3",
    color: COLORS.gray2,
    textTransform: "none",
    width: "auto",
    minWidth: 0,
    "&.MuiTab-textColorInherit.Mui-selected": {
      color: COLORS.black,
      fontWeight: 600,
    },
    "&.MuiTab-textColorInherit": {
      opacity: 1
    }
  },
  messagesCount: {
    borderRadius: "10px",
    backgroundColor: COLORS.brand_blue,
    color: COLORS.white,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: "2px 4px",
    width: "fit-content",
    minWidth: "16px",
    height: '16px',
    fontSize: '14px',
  },
  button: {
    whiteSpace: "nowrap",
    textTransform: "none",
    minWidth: "fit-content",
    width: "184px",
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
    },
    [theme.breakpoints.down("sm")]: {
      position: "fixed",
      borderRadius: "50%",
      bottom: "13%",
      right: "3%",
      width: "50px",
      height: "50px",
      "&>span>span": {
        fontSize: "36px",
      },
    },
  },
}));

type PropsType = {
  name: string[];
  handleChange: any;
  value: number;
  leadId: number;
  lead: null | leadFullInfo;
  notesCount: number | null;
  notificationsUnreadCount: number;
  resetUnread?: boolean;
  currentLeadArchive: boolean
};

const TabsPanel: React.FC<PropsType> = ({
  name,
  handleChange,
  value,
  leadId,
  lead,
  notesCount,
  notificationsUnreadCount,
  resetUnread,
  currentLeadArchive
}) => {
  const classes = useStyles();
  const initialUser = useSelector((state: rootState) => state.currentUser);
  const needDocsCount = useSelector((state: rootState) => selectNeededDocsCountByLeadId(state, leadId));
  const isClient = initialUser.role === ROLES.CLIENT;
  const isAgent = initialUser.role === ROLES.AGENT;
  const isManager = initialUser.role === ROLES.MANAGER;
  const isPartner = initialUser.role === ROLES.PARTNER;
  const dispatch = useDispatch();
  const unreadChatsCount = useSelector((state: rootState) =>
    state.chats.filter(chat =>
      chat.leadId === leadId && (chat.countUnreadMessage ?? 0) > 0
    ).length
  );
  const [renew, setRenew] = useState<boolean>(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [currentTab, setCurrentTab] = useState("");
  const a11yProps = (index: number) => {
    return {
      id: `tab-${index}`,
      "aria-controls": `tabpanel-${index}`,
    };
  };

  //renew for chats
  useEffect(() => {
    if (lead && !lead.isArchive) {
      const timer = setInterval(() => {
        setRenew(prevRenew => !prevRenew);
      }, 3000);
      return () => clearInterval(timer);
    }
  }, [lead]);

  //sets lead related chats for the role
  useEffect(() => {
    if (lead) {
      if (initialUser.status === clientStatuses.NotCompleteRegistration) {
        return;
      }
      const fetchAndDispatchChat = (fetchServiceFunction: () => Promise<ChatByType>): void => {
        fetchServiceFunction()
          .then((res: ChatByType) => {
            dispatch(actions.setChat(transformChatData(res, leadId)));
          })
          .catch((error: any) => {
            console.error('Failed to fetch chat data:', error);
          });
      };
      const fetchManagerChat = () => chatService.getMessagesListManagerChatByLeadIdService(leadId, undefined);
      const fetchLeadChat = () => chatService.getMessagesListByLeadIdService(leadId);
      if (isClient || isAgent) {
        const fetchPromises = [fetchManagerChat()];
        if (lead.hasPartnerChat) {
          fetchPromises.push(fetchLeadChat());
        }
        Promise.all(fetchPromises)
          .then(([managerChatRes, leadChatRes]) => {
            dispatch(actions.setChat(transformChatData(managerChatRes, leadId)));
            if (lead.hasPartnerChat && leadChatRes) {
              dispatch(actions.setChat(transformChatData(leadChatRes, leadId)));
            }

          })
          .catch((error) => {
            console.error('Failed to fetch chat data:', error);
          });
      } else if (isManager) {
        fetchAndDispatchChat(() => chatService.getMessagesListManagerChatByLeadIdService(leadId, undefined));
      } else if (isPartner) {
        fetchAndDispatchChat(() => chatService.getMessagesListByLeadIdService(leadId));
      }
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [renew, initialUser, leadId]);

  const handleChangeTab = (e: any, newValue: number) => {
    setCurrentTab(newValue.toString());
    handleChange(e, newValue);
  };

  return (
    <Box className={classes.appbar}>
      <>
        <Tabs
          TabIndicatorProps={{ style: { background: COLORS.success } }}
          value={value}
          onChange={handleChangeTab}
          aria-label="tabs"
          className={classes.tabs}
        >
          {name.map((item, index) => {
            return (
              <Tab
                wrapped
                className={classes.tab}
                label={
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "4px",
                    }}
                  >
                    {item === "Документы" && !currentLeadArchive && needDocsCount > 0 &&
                      <div className={classes.messagesCount}>
                        <span>
                          {needDocsCount}
                        </span>
                      </div>
                    }
                    {item === "Уведомления" && notificationsUnreadCount > 0 && !resetUnread &&
                      <div className={classes.messagesCount}>
                        <span>
                          {notificationsUnreadCount}
                        </span>
                      </div>
                    }
                    {item === "Чат" && unreadChatsCount > 0 &&
                      <div className={classes.messagesCount}>
                        <span>
                          {unreadChatsCount}
                        </span>
                      </div>
                    }
                    {item === "Заметки" && notesCount! > 0 &&
                      <div className={classes.messagesCount}>
                        <span>
                          {notesCount}
                        </span>
                      </div>
                    }
                    <div>{item}</div>
                  </div>
                }
                {...a11yProps(index)}
                onClick={() => setCurrentTab(item)}
                key={v4()}
              />
            );
          })}
        </Tabs>
      </>
    </Box>
  );
};

export default memo(TabsPanel);
