import React, { useState } from "react";
import ButtonCustom from "../../../UIcomponentsRedesign/ButtonCustom/ButtonCustom";
import SpinnerMain from "../../../UIcomponentsRedesign/SpinnerMain";
import { DaysOfWeekEnum, FrequencyUnitEnum, NoteType } from "../../../core/api/noteApi";
import {
  dateConverterLong,
  dateConverterLongNoTime
} from "../../../core/helpers/dateConverter";
import noteService from "../../../core/services/NoteService";
import ConfirmModalRedesign from "../confirm-modal-redesign/ConfirmModalRedesign";
import { useStyles } from "./useNoteStyles";

const DaysOfWeekRu: { [index: string]: string } = {
  [DaysOfWeekEnum.Mo]: "Пн",
  [DaysOfWeekEnum.Tu]: "Вт",
  [DaysOfWeekEnum.We]: "Ср",
  [DaysOfWeekEnum.Th]: "Чт",
  [DaysOfWeekEnum.Fr]: "Пт",
  [DaysOfWeekEnum.Sa]: "Сб",
  [DaysOfWeekEnum.Su]: "Вс",
};


type Props = {
  note: NoteType;
  setChosenNote: React.Dispatch<React.SetStateAction<NoteType | null>>;
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
  getNotes: () => void;
};

const NoteCard: React.FC<Props> = ({
  note,
  setChosenNote,
  setIsEdit,
  getNotes,
}) => {
  const classes = useStyles()
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const reminder = note.isNotificate;
  const reminderStartDate = note.notificationStartDate
  const reminderSchedule = note.actualNotificatiionDateTime;
  const reminderCreationDate = note.creationDate
  const frequency = note.frequencyUnit;
  const expired = note.isExpired;
  const todayNote = note.isToday;

  const handleDeleteNote = async () => {
    if (!note?.id) return;
    setIsLoading(true);
    const res = await noteService.deleteNote(note?.id);
    if (res) {
      getNotes();
      setIsOpenDeleteModal(false)
      setIsLoading(false);
    }
  }

  const getFrequencyLabel = (note: NoteType) => {
    let frequencyLabel = ''
    if (note.frequencyUnit !== FrequencyUnitEnum.no) {
      if (note.frequencyUnit === FrequencyUnitEnum.day) {
        const count = note.frequencyCount === 1 ? '' : note.frequencyCount
        frequencyLabel = `(Повторять каждый ${count} день)`;
      } else if (note.frequencyUnit === FrequencyUnitEnum.week) {
        const count = note.frequencyCount === 1 ? '' : note.frequencyCount
        const daysInRussian = note.days?.map(day => DaysOfWeekRu[day]).join(', ');
        frequencyLabel = `(Повторять каждую ${count}  неделю в ${daysInRussian})`;
      }
    }
    return frequencyLabel
  }

  return (
    <>
      <div className={classes.noteBox}>
        {reminder && reminderStartDate &&
          <div className={classes.reminderColumn}>
            <div className={classes.reminderRow}>
              {reminder && frequency === FrequencyUnitEnum.no && (
                <>
                  {expired ? (
                    <div className="round_red_small" />
                  ) : todayNote ? (
                    <div className="round_orange_small" />
                  ) : null}
                </>
              )}
              <h4 className={classes.reminderTitle}>Напомнить &nbsp;
                {reminderSchedule ? (
                  dateConverterLong(reminderSchedule)
                ) : (
                  <>
                    {dateConverterLongNoTime(reminderStartDate)}
                  </>
                )
                }
              </h4>
            </div>
            {frequency !== FrequencyUnitEnum.no ?
              <span className={classes.reminderTitle}>
                {getFrequencyLabel(note)}
              </span>
              : null}
          </div>
        }
        <div className={classes.noteText}>{note.text}</div>
        <div className={classes.buttonBox}>
          <div className={classes.buttons}>
            <ButtonCustom
              color="text_button__small"
              handleClick={() => {
                setIsEdit(true);
                setChosenNote(note);
                window.scrollTo({ top: 0 });
              }}>
              Редактировать
            </ButtonCustom>
            <ButtonCustom
              color="text_button_alert__small"
              handleClick={() => setIsOpenDeleteModal(true)}>
              Удалить
            </ButtonCustom>
          </div>
          {reminderCreationDate &&
            <span className={classes.dateBox}>
              Создано &nbsp;
              {dateConverterLongNoTime(reminderCreationDate)}
            </span>}
        </div>
        {isLoading &&
          <SpinnerMain fixedPosition />
        }
      </div>
      <ConfirmModalRedesign
        handleConfirm={handleDeleteNote}
        title="Удалить заметку"
        text="Заметка будет удалена, если у заметки было напоминание оно будет отменено"
        handleCancel={() => setIsOpenDeleteModal(false)}
        confBtnText="Удалить"
        cancelBtnText="Отмена"
        open={isOpenDeleteModal}
      />
    </>
  );
};

export default NoteCard;
