import React, { memo, useEffect, useState } from "react";
import {
  makeStyles,
  createStyles,
  Grid,
  Theme,
  Typography,
  Tooltip,
  Box,
} from "@material-ui/core";
import kontragentService from "../../../../core/services/KontragentService";
import { konteragentType } from "../../../../core/api/konteragentApi";
import { ApproveSign } from "../../../../core/consts/images/approveSign";
import { AlertSign } from "../../../../core/consts/images/alertSign";
import { WarningSign } from "../../../../core/consts/images/warningSign";
import { v4 } from "uuid";
import clsx from "clsx";
// import { DownloadSign } from "../../../../core/consts/images/downloadSign";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grid: {
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
      alignItems: "flex-start;",
    },
    gridItem: {
      display: "flex",
      flexDirection: "column",
    },
    gridCard: {
      display: "flex",
      flexWrap: "wrap",
      backgroundColor: "#ffffff",
      borderRadius: "10px",
      padding: theme.spacing(3),
      marginTop: theme.spacing(3),
    },
    lightContainer: {
      height: "91%",
    },
    document: {
      backgroundColor: "#F2F2F2",
      padding: "10px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      marginTop: "15px",
    },
    lightsWrapper: {
      backgroundColor: "#ffffff",
      borderRadius: "10px",
      flexDirection: "column",
      marginTop: "24px",
    },
    commonValues: {
      paddingLeft: '30px'
    }
  })
);

type propsType = {
  id: string;
};
const ClientCounterpartyCardPage: React.FC<propsType> = (props) => {
  const classes = useStyles();
  const [kontragentInfo, setkontragentInfo] = useState<konteragentType>();
  useEffect(() => {
    kontragentService
      .getKontragentByIdService(props.id)
      .then((res: konteragentType) => {
        setkontragentInfo(res);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [props.id]);

  return (
    <Grid className={classes.grid}>
      <Grid
        item
        className={classes.gridItem}
        xl={6}
        lg={6}
        md={6}
        style={{ marginRight: "30px" }}
      >
        <Grid className={classes.gridCard}>
          <Typography
            variant="h4"
            component="h4"
            style={{ padding: "10px 0", display: "block", width: "100%" }}
          >
            Общее
          </Typography>
          <Grid item xl={4} lg={4} md={4}>
            {kontragentInfo?.companyName && (
              <Typography
                variant="body2"
                component="p"
                style={{ color: "#131313", padding: "10px 0" }}
              >
                Название
              </Typography>
            )}
            {kontragentInfo?.companyAddress && (
              <Typography
                variant="body2"
                component="p"
                style={{ color: "#131313", padding: "10px 0" }}
              >
                Адрес
              </Typography>
            )}
            {kontragentInfo?.inn && (
              <Typography
                variant="body2"
                component="p"
                style={{ color: "#131313", padding: "10px 0" }}
              >
                ИНН
              </Typography>
            )}
            {kontragentInfo?.kpp && (
              <Typography
                variant="body2"
                component="p"
                style={{ color: "#131313", padding: "10px 0" }}
              >
                КПП
              </Typography>
            )}
            {kontragentInfo?.ogrn && (
              <Typography
                variant="body2"
                component="p"
                style={{ color: "#131313", padding: "10px 0" }}
              >
                ОГРН
              </Typography>
            )}
            {kontragentInfo?.mspStatus && (
              <Typography
                variant="body2"
                component="p"
                style={{ color: "#131313", padding: "10px 0" }}
              >
                Статус контрагента в реестре СМСП
              </Typography>
            )}
          </Grid>
          <Grid item xl={8} lg={8} md={8} className={classes.commonValues}>
            {kontragentInfo?.companyName && (
              <Typography
                variant="body2"
                component="p"
                style={{ color: "#617E8C", padding: "10px 5px" }}
              >
                {kontragentInfo?.companyName}
              </Typography>
            )}
            {kontragentInfo?.companyAddress && (
              <Typography
                variant="body2"
                component="p"
                style={{ color: "#617E8C", padding: "10px 5px" }}
              >
                {kontragentInfo?.companyAddress}
              </Typography>
            )}
            {kontragentInfo?.inn && (
              <Typography
                variant="body2"
                component="p"
                style={{ color: "#617E8C", padding: "10px 5px" }}
              >
                {kontragentInfo?.inn}
              </Typography>
            )}
            {kontragentInfo?.kpp && (
              <Typography
                variant="body2"
                component="p"
                style={{ color: "#617E8C", padding: "10px 5px" }}
              >
                {kontragentInfo?.kpp}
              </Typography>
            )}
            {kontragentInfo?.ogrn && (
              <Typography
                variant="body2"
                component="p"
                style={{ color: "#617E8C", padding: "10px 5px" }}
              >
                {kontragentInfo?.ogrn}
              </Typography>
            )}
            {kontragentInfo?.mspStatus && (
              <Typography
                variant="body2"
                component="p"
                style={{ color: "#617E8C", padding: "10px 5px" }}
              >
                {kontragentInfo?.mspStatus}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid className={classes.gridCard}>
          {kontragentInfo?.manager && (
            <Typography
              variant="h4"
              component="h4"
              style={{ padding: "10px 0", display: "block", width: "100%" }}
            >
              Руководители
            </Typography>
          )}
          {kontragentInfo?.manager && (
            <Grid item xl={4} lg={4} md={4}>
              <Typography
                variant="body2"
                component="p"
                style={{ color: "#131313", padding: "10px 0" }}
              >
                {kontragentInfo?.manager.fio}
              </Typography>
            </Grid>
          )}
          {kontragentInfo?.manager && (
            <Grid item xl={8} lg={8} md={8}>
              <Typography
                variant="body2"
                component="p"
                style={{ color: "#617E8C", padding: "10px 0" }}
              >
                с {kontragentInfo?.manager.date}
              </Typography>
            </Grid>
          )}
          {kontragentInfo?.owners && kontragentInfo!.owners.length > 0 && (
            <Typography
              variant="h4"
              component="h4"
              style={{ padding: "10px 0", display: "block", width: "100%" }}
            >
              Учредители
            </Typography>
          )}
          {kontragentInfo?.owners && kontragentInfo!.owners.length > 0 && (
            <Grid item xl={4} lg={4} md={4}>
              {kontragentInfo?.owners.map((owner) => {
                return (
                  <Typography
                    variant="body2"
                    component="p"
                    style={{ color: "#131313", padding: "10px 0" }}
                    key={v4()}
                  >
                    {owner.fio}
                  </Typography>
                );
              })}
            </Grid>
          )}
          {kontragentInfo?.owners && kontragentInfo!.owners.length > 0 && (
            <Grid item xl={8} lg={8} md={8}>
              {kontragentInfo?.owners.map((owner) => {
                return (
                  <Typography
                    variant="body2"
                    component="p"
                    style={{ color: "#617E8C", padding: "10px 0" }}
                    key={v4()}
                  >
                    с {owner.date}
                  </Typography>
                );
              })}
            </Grid>
          )}
        </Grid>
        <Grid item xl={12} lg={12} md={12} style={{ display: "flex" }}>
          <Grid item xl={6} lg={6} md={6}>
            <Grid className={classes.gridCard} style={{ marginRight: "20px" }}>
              <Typography
                variant="h4"
                component="h4"
                style={{ padding: "10px 0", display: "block", width: "100%" }}
              >
                Дополнительные сведения
              </Typography>
              <Grid item xl={6} lg={6} md={6}>
                {kontragentInfo?.companyRegistationDate && (
                  <Typography
                    variant="body2"
                    component="p"
                    style={{ color: "#131313", padding: "10px 0" }}
                  >
                    Дата основания
                  </Typography>
                )}
                {kontragentInfo?.staffYear && (
                  <Typography
                    variant="body2"
                    component="p"
                    style={{ color: "#131313", padding: "10px 0" }}
                  >
                    Число сотрудников на {kontragentInfo?.staffYear || "1900"}{" "}
                    год
                  </Typography>
                )}
              </Grid>
              <Grid item xl={6} lg={6} md={6}>
                {kontragentInfo?.companyRegistationDate && (
                  <Typography
                    variant="body2"
                    component="p"
                    style={{ color: "#617E8C", padding: "10px 0" }}
                  >
                    {kontragentInfo?.companyRegistationDate}
                  </Typography>
                )}
                {kontragentInfo?.staffCount && (
                  <Typography
                    variant="body2"
                    component="p"
                    style={{ color: "#617E8C", padding: "10px 0" }}
                  >
                    {kontragentInfo?.staffCount || "0"} человека
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
          {kontragentInfo?.okved && (
            <Grid item xl={6} lg={6} md={6}>
              <Grid className={classes.gridCard}>
                <Typography
                  variant="h4"
                  component="h4"
                  style={{ padding: "10px 0", display: "block", width: "100%" }}
                >
                  Основной вид деятельности
                </Typography>
                {kontragentInfo?.okved.code && (
                  <Grid item xl={4} lg={4} md={4}>
                    <Typography
                      variant="body2"
                      component="p"
                      style={{ color: "#131313", padding: "10px 0" }}
                    >
                      Код ОКВЭД {kontragentInfo?.okved.code}
                    </Typography>
                  </Grid>
                )}
                {kontragentInfo?.okved.title && (
                  <Grid item xl={8} lg={8} md={8}>
                    <Typography
                      variant="body2"
                      component="p"
                      style={{ color: "#617E8C", padding: "10px 0" }}
                    >
                      {kontragentInfo?.okved.title}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid
        item
        className={clsx(classes.grid, classes.lightsWrapper)}
        xl={6}
        lg={6}
        md={6}
      >
        <div className={classes.lightContainer}>
          {kontragentInfo?.lights && (
            <Grid
              item
              className={classes.gridCard}
              xl={12}
              lg={12}
              md={12}
              style={{ marginRight: "20px", paddingTop: "0px" }}
            >
              <Typography
                variant="h4"
                component="h4"
                style={{
                  padding: "10px 0 25px",
                  display: "block",
                  width: "100%",
                }}
              >
                Светофор
              </Typography>
              {kontragentInfo?.lights.danger &&
                kontragentInfo!.lights.danger.length > 0 &&
                kontragentInfo?.lights.danger.map((light) => {
                  return (
                    <Tooltip
                      key={v4()}
                      title={`${light.desc}`}
                      placement="top-start"
                    >
                      <Box
                        style={{
                          display: " flex",
                          width: "100%",
                          marginTop: "10px",
                        }}
                      >
                        <Box
                          style={{
                            width: "10%",
                            textAlign: "center",
                            marginTop: "5px",
                          }}
                        >
                          <AlertSign />
                        </Box>
                        <Box style={{ width: "80%" }}>
                          <Typography
                            variant="body2"
                            component="p"
                            style={{ color: "#131313", padding: "5px 0" }}
                          >
                            {light.name.trim()}
                          </Typography>
                          <Typography
                            variant="body2"
                            component="p"
                            style={{ color: "#617E8C", padding: "5px 0" }}
                          >
                            {light.value.trim()}
                          </Typography>
                        </Box>
                      </Box>
                    </Tooltip>
                  );
                })}
              {kontragentInfo?.lights.warning &&
                kontragentInfo!.lights.warning.length > 0 &&
                kontragentInfo?.lights.warning.map((light) => {
                  return (
                    <Tooltip
                      key={v4()}
                      title={`${light.desc}`}
                      placement="top-start"
                    >
                      <Box
                        style={{
                          display: " flex",
                          width: "100%",
                          marginTop: "10px",
                        }}
                      >
                        <Box
                          style={{
                            width: "10%",
                            textAlign: "center",
                            marginTop: "5px",
                          }}
                        >
                          <WarningSign />
                        </Box>
                        <Box style={{ width: "80%" }}>
                          <Typography
                            variant="body2"
                            component="p"
                            style={{ color: "#131313", padding: "5px 0" }}
                          >
                            {light.name.trim()}
                          </Typography>
                          <Typography
                            variant="body2"
                            component="p"
                            style={{ color: "#617E8C", padding: "5px 0" }}
                          >
                            {light.value.trim()}
                          </Typography>
                        </Box>
                      </Box>
                    </Tooltip>
                  );
                })}
              {kontragentInfo?.lights.success &&
                kontragentInfo!.lights.success.length > 0 &&
                kontragentInfo?.lights.success.map((light) => {
                  return (
                    <Tooltip
                      key={v4()}
                      title={`${light.desc}`}
                      placement="top-start"
                    >
                      <Box
                        style={{
                          display: " flex",
                          width: "100%",
                          marginTop: "10px",
                        }}
                      >
                        <Box
                          style={{
                            width: "10%",
                            textAlign: "center",
                            marginTop: "5px",
                          }}
                        >
                          <ApproveSign />
                        </Box>
                        <Box style={{ width: "80%" }}>
                          <Typography
                            variant="body2"
                            component="p"
                            style={{ color: "#131313", padding: "5px 0" }}
                          >
                            {light.name.trim()}
                          </Typography>
                          <Typography
                            variant="body2"
                            component="p"
                            style={{ color: "#617E8C", padding: "5px 0" }}
                          >
                            {light.value.trim()}
                          </Typography>
                        </Box>
                      </Box>
                    </Tooltip>
                  );
                })}
            </Grid>
          )}
          {/*  <Grid item className={classes.gridItem} xl={6} lg={6} md={6}>
                <Grid className={classes.gridCard} >
                        <Typography variant="h4" component='h4' style={{ padding: '10px 0', display: 'block', width: '100%' }} >
                            Экспресс-анализ
                        </Typography>
                        <Grid style={{ display: ' flex' }}>
                            <Grid item xl={2} lg={2} md={2}>
                                <WarningSign />
                            </Grid>
                            <Grid item xl={10} lg={10} md={10}>
                                <Typography variant="body2" component='p' style={{ color: '#131313', padding: '5px 0' }}>
                                    Компания существует менее 5 лет
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid style={{ display: ' flex' }}>
                            <Grid item xl={2} lg={2} md={2}>
                                <AlertSign />
                            </Grid>
                            <Grid item xl={10} lg={10} md={10}>
                                <Typography variant="body2" component='p' style={{ color: '#131313', padding: '5px 0' }}>
                                    Основные средства отстутствуют
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid className={classes.gridCard}>
                        <Typography variant="h4" component='h4' style={{ padding: '10px 0', display: 'block', width: '100%' }} >
                            Отчеты
                        </Typography>
                        <Typography variant="body2" component='p' className={classes.document}  >
                            Выписка ЕГР <DownloadSign/>
                        </Typography>
                        <Typography variant="body2" component='p' className={classes.document}  >
                            Отчет по контрагенту <DownloadSign/>
                        </Typography>
                    </Grid> 
                </Grid>*/}
        </div>
      </Grid>
    </Grid>
  );
};

export default memo(ClientCounterpartyCardPage);
