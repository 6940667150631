export const ArrowDownIcon = ({ color }: { color?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.19953 9.22389C6.45829 8.90044 6.93026 8.848 7.25371 9.10676L11.9976 12.9019L16.7415 9.10676C17.065 8.848 17.5369 8.90044 17.7957 9.22389C18.0545 9.54734 18.002 10.0193 17.6786 10.2781L12.4661 14.448C12.1922 14.6671 11.803 14.6671 11.5291 14.448L6.31666 10.2781C5.99322 10.0193 5.94078 9.54734 6.19953 9.22389Z"
      fill={color ? color : "gray2"}
    />
  </svg>
);