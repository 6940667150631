import React, {
  memo,
  useEffect,
  useState,
} from 'react';

import {
  Box,
  createStyles,
  Divider,
  IconButton,
  makeStyles,
  Modal,
  Paper,
  Theme,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import ChatWindow from '../chat-window/ChatWindow';
/* should be refactored */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {},
    paper: {
      minHeight: "80%",
      maxHeight: "90%",
      position: "absolute",
      width: "50%",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: theme.palette.background.paper,
      border: "none",
      padding: theme.spacing(2, 4, 3),
      borderRadius: "10px",
      // '& hr+div': {
      //     height: '90%',
      // }
    },
    box: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "baseline",
      width: "100%",
    },
    title: {
      padding: "38px 30px ",
    },
  })
);

type PropsType = {
  close: any;
  currentChatId: string;
  open: boolean;
  clientName?: string;
  clientId?: string;
  clientRole?: string;
  isManagerSupport?: boolean;
};

const ChatModalManager: React.FC<PropsType> = (props) => {
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);
  const handleClose = () => {
    setOpenModal(false);
    props.close();
  };

  useEffect(() => {
    setOpenModal(props.open);
  }, [props.open]);
  return (
    <Modal open={openModal} onClose={handleClose} className={classes.modal}>
      <Paper className={classes.paper}>
        <Box className={classes.box}>
          {props.isManagerSupport ? (
            <Typography
              variant="h3"
              component="h3"
              className={classes.title}
              style={{ padding: "0 0 25px 0" }}
            >
              {`${props.clientName ? props.clientName : "Чат техподдержки"}`}
            </Typography>
          ) : (
            props.clientRole && props.clientName ? <Typography
              variant="h3"
              component="h3"
              className={classes.title}
              style={{ padding: "0 0 25px 0" }}
            >
              Чат с {`${props.clientRole === "agent" ? "агентом" : "клиентом"}`}{" "}
              инн: {`${props.clientName ? props.clientName : ""}`}
            </Typography>
              : <Typography
                variant="h3"
                component="h3"
                className={classes.title}
                style={{ padding: "0 0 25px 0" }}
              >
                Чат с {props.clientName}
              </Typography>

          )}
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider />
        <ChatWindow
          chatId={props.currentChatId}
          leadId="0"
          handleCloseModal={handleClose}
          clientId={props.clientId}
        />
      </Paper>
    </Modal>
  );
};

export default memo(ChatModalManager);
