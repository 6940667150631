import { ErrorsResponse, ValidateForm, ValidateFormModal } from "../../pages/new-order/types/bigForm";
import leadsApi from "../api/leadsApi";
import {
  AllLeadsData,
  BodyParamsGetLeads,
  LeadDocPackType,
  LeadDocType,
  leadFullInfo,
  leadStatusType,
  leadsListType,
} from "../types/leadsApiTypes";
import { individualIpotekaType } from "../types/productTypes";
import introspectService from "./IntropsectService";

export function prepareFormData(
  packId: number,
  documents: File[],
  // isAttachedSignature: boolean,
): FormData {
  const formData = new FormData();
  formData.append("DocumentPackId", packId.toString());
  // formData.append("IsAttachedSignature", isAttachedSignature.toString());

  documents.forEach((document) => {
    formData.append(`Documents`, document);
  });

  return formData;
}

class LeadsService {
  //-----------documents-----------
  getLeadDocPacksService(leadId: number): Promise<LeadDocPackType[]> {
    return leadsApi.getLeadDocPacks(leadId);
  }

  // uploadDocService(
  //   packId: number,
  //   documents: File[],
  //   // isAttachedSignature: boolean,
  // ): Promise<any> {
  //   const formData = prepareFormData(packId, documents);
  //   return leadsApi.uploadDoc(packId, formData);
  // }

  uploadFileService(
    packId: number,
    formData: FormData,
    onProgress: (percentage: number, loadedMB: string, totalMB: string) => void,
    signal: AbortSignal //to stop uploading
  ): Promise<any> {
    return leadsApi.uploadFile(packId, formData, onProgress, signal);
  }

  attachDocEdsService(
    packId: number,
    document: File
  ): Promise<any> {
    const formData = new FormData();
    formData.append("DocumentSignature", document);
    return leadsApi.attachDocEds(packId, formData);
  }

  deleteDocService(docId: number): Promise<any> {
    return leadsApi.deleteDoc(docId);
  }

  getDocFileService(
    docId: number,
    Q?: { partner_example: boolean }
  ): Promise<any> {
    return leadsApi.getDocFile(docId, Q);
  }

  sendDocPacksService(
    leadId: string,
    data: { leadId: number; documents: number[] }
  ): Promise<any> {
    return leadsApi.sendDocPacks(leadId, data);
  }

  ///old docs. TODO: remove than partner lead page is ready
  deleteLeadDocService(leadId: number, documentId: number): Promise<any> {
    return leadsApi.deleteLeadDoc(leadId, documentId);
  }

  replaceLeadDocService(
    leadId: number,
    documentId: number,
    document: any,
    IsAttachedSignature: boolean
  ): Promise<any> {
    return leadsApi.replaceLeadDoc(
      leadId,
      documentId,
      document,
      IsAttachedSignature
    );
  }

  getLeadDocService(
    document: number,
    Q?: { partner_example: boolean }
  ): Promise<any> {
    return leadsApi.getLeadDoc(document, Q);
  }

  getLeadDocActService(
    document: number,
    Q?: { partner_example: boolean }
  ): Promise<any> {
    return leadsApi.getLeadDocAct(document, Q);
  }

  getLeadDocsSendStatusService(
    leadId: number
  ): Promise<{ sendDocsState: string }> {
    return leadsApi.getLeadDocsSendStatus(leadId);
  }

  getLeadDocsService(leadId: number): Promise<LeadDocType[]> {
    return leadsApi.getLeadDocs(leadId);
  }

  attachDocSignatureService(
    leadId: number,
    documentid: number,
    document: any
  ): Promise<any> {
    return leadsApi.attachDocSignature(leadId, documentid, document);
  }

  sendDocsToBankService(leadid: string): Promise<any> {
    return leadsApi.sendDocsToBank(leadid);
  }
  getDocSignService(id: number, sid: number): Promise<any> {
    return leadsApi.getDocSign(id, sid);
  }

  //TODO: remove with all unused components
  sendIpotekaApplicationService(
    Q: individualIpotekaType | any,
    alias: string
  ): Promise<any> {
    return leadsApi.sendIpotekaApplication(Q, alias);
  }

  //---------------заявки-----------

  deleteLeadByIdService(leadId: string): Promise<leadFullInfo> {
    return leadsApi
      .deleteLeadById(leadId)
      .then((res: any) => {
        return res;
      })
      .catch((error) => {
        introspectService.checkToken(error.response.status.toString());
      });
  }

  getLeadStatusById(leadId: number): Promise<leadStatusType> {
    return leadsApi.getLeadStatusById(leadId).then((res: any) => {
      return res;
    });
  }
  getDeleteInfoByIdService(leadId: string): Promise<any> {
    return leadsApi.getDeleteInfoById(leadId).then((res: any) => {
      return res;
    });
  }

  getAllLeadsService(B: BodyParamsGetLeads): Promise<any> {
    return leadsApi.getAllLeads(B)
      .then((res: AllLeadsData) => {
        return res;
      })
      .catch(error => {
        introspectService.checkToken(error.response.status.toString())
      })
  }

  putLeadArchive(leadid: string) {
    return leadsApi
      .putLeadArchive(leadid)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getLeadByIdService(leadId: string): Promise<leadFullInfo> {
    return leadsApi
      .getLeadById(leadId)
      .then((res: any) => {
        return res;
      })
      .catch((error) => {
        console.log(error);
      })
  }

  getLeadsListByProductIdService(
    productId: string,
    archive?: boolean
  ): Promise<leadsListType> {
    return leadsApi
      .getLeadsListByProductId(productId, archive)
      .then((res: any) => {
        return res;
      })
      .catch((error) => {
        introspectService.checkToken(error.response.status.toString());
      });
  }

  getLeadsBigFieldsService(
    ordersList?: number[],
    inn?: string,
    draftOrdersArr?: number[]
  ) {
    const params = new URLSearchParams();
    if (draftOrdersArr && draftOrdersArr.length > 0) {
      // draftOrdersArr.map((i) => params.append("leadId", `${i}`));
      draftOrdersArr.map((i) => params.append("LeadIdList", `${i}`));
    }

    if (draftOrdersArr && draftOrdersArr.length === 0 && ordersList) {
      ordersList.map((i) => params.append("proposalIds", `${i}`));
    }
    params.append("inn", `${inn}`);
    return leadsApi.getLeadsBigFields(params);
  }

  getStatuses() {
    return leadsApi
      .getPartnerStatuses()
      .then((res: any) => {
        return res;
      })
      .catch((error) => {
        introspectService.checkToken(error.response.status.toString());
      });
  }

  validateV5(body: ValidateForm | ValidateFormModal): Promise<ErrorsResponse> {
    return leadsApi
      .sendDataForValidateV5(body)
      .then((res: any) => {
        return res;
      })
      .catch((error) => {
        introspectService.checkToken(error.response.status.toString());
      });
  }
}

const leadsService = new LeadsService();
export default leadsService;
