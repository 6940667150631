import {
  OutlinedInput,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import arrowDown from "../../../core/consts/images/chevron_down.png";
import arrowUp from "../../../core/consts/images/chevron_up.png";
import userService from "../../../core/services/UserService";
import { CommentsUser } from "../../../core/types/userTypes";
import Comment from "./components/Comment";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      display: "flex",
      justifyContent: "space-between",
      paddingBottom: "5px",
    },
    btnMore: {
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      height: "15px",
    },
    textBtnMore: {
      display: "flex",
      alignItems: "center",
      color: "#306F9A",
      fontWeight: 500,
      fontFamily: "Gilroy",
      paddingRight: "10px",
    },
    comments: {},
    createCommentArea: {
      display: "flex",
      alignItems: "center",
      padding: "30px 0",
    },
    addCommentInput: {
      background: "#FFFFFF",
      width: "545px",
      height: "30px",
      marginRight: "15px",
      borderRadius: "4px",
      borderColor: "#B0BFC6",
      "&>input": {
        padding: "11px 11px",
        fontSize: "14px",
        color: "black",
        fontFamily: "Gilroy",
        "&::-webkit-input-placeholder": {
          color: "#B0BFC6",
        },
        "&::-moz-placeholder": {
          color: "#B0BFC6",
        },
        "&:-moz-placeholder": {
          color: "#B0BFC6",
        },
        "&::-ms-input-placeholder": {
          color: "#B0BFC6",
        },
      },
    },
    addCommentBtn: {
      display: "flex",
      alignSelf: "center",
      color: "#306F9A",
      fontWeight: 500,
      cursor: "pointer",
    },
  })
);

type PropsType = {
  comments: CommentsUser[] | string | undefined;
  id: string;
  updateComments?: () => void;
};

const Comments: React.FC<PropsType> = (props) => {
  const classes = useStyles();
  const [showMore, setShowMore] = React.useState(false);
  const [searchText, setSearchText] = React.useState("");

  const handleClickBtn = () => {
    setShowMore((showMore) => !showMore);
  };

  const handleAddComment = () => {
    if (searchText.length > 0) {
      const bodyForRequest = {
        message: searchText,
      };
      userService
        .createCommentService(props.id, bodyForRequest)
        .then(() => {
          setSearchText("");
          props.updateComments && props.updateComments();
        })
        .catch((e) => {
          console.log("Err add comment request", e);
        });
    }
  };

  const onInputChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setSearchText(e.target.value);
  };
  return (
    <div>
      <div className={classes.header}>
        <Typography variant="h4" component="h4">
          Комментарии
        </Typography>
        {showMore ? (
          <div className={classes.btnMore} onClick={handleClickBtn}>
            <Typography
              className={classes.textBtnMore}
              variant="body1"
              component="p"
            >
              Скрыть
            </Typography>
            <img src={arrowUp} alt="стрелочка вверх" />
          </div>
        ) : (
          <div className={classes.btnMore} onClick={handleClickBtn}>
            <Typography
              className={classes.textBtnMore}
              variant="body1"
              component="p"
            >
              Показать все
            </Typography>
            <img src={arrowDown} alt="стрелочка вверх" />
          </div>
        )}
      </div>
      {showMore ? (
        <div>
          {props.comments &&
            typeof props.comments !== "string" &&
            props.comments.length > 0 && (
              <div className={classes.comments}>
                {props.comments.map((comment, index) => {
                  return (
                    <Comment
                      key={index}
                      date={comment.date}
                      text={comment.text}
                    />
                  );
                })}
              </div>
            )}
          <div className={classes.createCommentArea}>
            <OutlinedInput
              id="outlined-adornment-amount"
              value={searchText}
              color="primary"
              className={classes.addCommentInput}
              onChange={onInputChange}
              placeholder={"Ваш комментарий"}
            />
            <Typography
              variant="body2"
              component="p"
              className={classes.addCommentBtn}
              onClick={handleAddComment}
            >
              Добавить
            </Typography>
          </div>
        </div>
      ) : (
        <div>
          {props.comments &&
            typeof props.comments !== "string" &&
            props.comments.length > 0 && (
              <Comment
                date={props.comments[0]?.date}
                text={props.comments[0]?.text}
              />
            )}
        </div>
      )}
    </div>
  );
};

export default React.memo(Comments);
