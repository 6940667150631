import {
  ErrorsData,
  ErrorsGroupFields,
  Field,
  FieldModal,
  FormField,
  FormValues,
  PropsalData,
} from "../../pages/new-order/types/bigForm";

export type NewOrderState = {
  formValues: Record<number, FormField> | undefined;
  errorsFields: Record<number, ErrorsData> | undefined;
  errorsGroupFields: ErrorsGroupFields | undefined;
  countErrors: number | undefined;
  currentModalData: {
    propsalId: number | undefined;
    modalName: string | undefined;
    indexEditModal: number | undefined;
    isMultiple: boolean | undefined;
    proposals: number[] | undefined;
  };
  formData: PropsalData[] | undefined;
  isFetching: boolean;
  currentEditModal: {
    // values: FieldModal | undefined;
    values: FieldModal[] | undefined;
    // errors: Record<string, string> | undefined;
    errors: Record<string, string>[] | undefined;
  };
  alertModal: {
    tittle: string | undefined;
    text: string | undefined;
    isError: boolean;
    confModal: boolean;
    draftModal: boolean;
    isRemain: boolean;
    isPromptShow: boolean;
  };
};

export enum newOrderActionTypes {
  SET_FORM_VALUES = "SET_FORM_VALUES",
  DELETE_NEW_ORDER_DATA = "DELETE_NEW_ORDER_DATA",
  SET_ERRORS_FIELDS = "SET_ERRORS_FIELDS",
  SET_CURRENT_MODAL_ERRORS = "SET_CURRENT_MODAL_ERRORS",
  SET_FORM_DATA = "SET_FORM_DATA",
  DELETE_FROM_FORM_PROPSAL = "DELETE_FROM_FORM_PROPSAL",
  SET_CURRENT_MODAL_DATA = "SET_CURRENT_MODAL_DATA",
  UPDATE_FIELD_VALUE = "UPDATE_FIELD_VALUE",
  SET_PROPSAL_ERRORS = "SET_PROPSAL_ERRORS",
  SET_CURRENT_MODAL_INDEX = "SET_CURRENT_MODAL_INDEX",
  SET_MODAL_FIELD = "SET_MODAL_FIELD",
  SET_GROUP_FIELD_ERROR = "SET_GROUP_FIELD_ERROR",
  CLEAR_GROUP_FIELD_ERROR = "CLEAR_GROUP_FIELD_ERROR",
  SET_MULTIPLE_MODAL_FIELD = "SET_MULTIPLE_MODAL_FIELD",
  DELETE_MODAL_ACCORDION = "DELETE_MODAL_ACCORDION",
  SET_CURRENT_EDIT_MODAL = "SET_CURRENT_EDIT_MODAL",
  SET_CURRENT_EDIT_MODAL_MULTIPLE = "SET_CURRENT_EDIT_MODAL_MULTIPLE",
  SET_VALUE_FIELD_CURRENT_MODAL = "SET_VALUE_FIELD_CURRENT_MODAL",
  DELETE_MULTIPLE_MODAL_ACCORDION = "DELETE_MULTIPLE_MODAL_ACCORDION",
  SET_ERRORS_FORM_COUNT = "SET_ERRORS_FORM_COUNT",
  SET_IS_FETCHING = "SET_IS_FETCHING",
  SET_ALERT_MODAL = "SET_ALERT_MODAL",
}

export type UpdateFieldValuePayload = {
  propsalId: number;
  field: FormField;
};

export type DeleteFromFormPropsalPayload = {
  values: Record<number, FormField>;
  errors: Record<number, ErrorsData>;
  formData: PropsalData[];
};

export type SetCurrentModalErrorsPayload = {
  errors: Record<string, string>;
  // errors: Record<string, string>[];
  modalIndex?: number;
};

export type SetAlertModalPayload = {
  tittle: string | undefined;
  text: string | undefined;
  isError: boolean;
  confModal: boolean;
  draftModal: boolean;
  isRemain: boolean;
  isPromptShow: boolean;
};

export type SetPropsalErrorsPayload = {
  propsalId: number;
  errors: ErrorsData;
};

export type SetErrorsFormCountPayload = {
  errorsCount: number;
};

export type SetGroupFieldErrorPayload = ErrorsGroupFields;

export type SetIsFetchingPayload = {
  isFetching: boolean;
};

export type SetModalFieldPayload = {
  propsalId: number;
  fieldName: string;
  // value: FieldModal;
  // errors: Record<string, string>
  value: FieldModal[];
  errors: Record<string, string>[];
};

export type SetMultipleModalFieldPayload = {
  propsalId: number;
  fieldName: string;
  index: number | undefined;
  value: FieldModal;
  errors: Record<string, string>
  // value: FieldModal[];
  // errors: Record<string, string>[];
};

export type SetValueFieldCurrentModalPayload = {
  fieldName: string;
  value: Field;
  modalIndex?: number;
};

export type DeleteModalAccordionPayload = {
  propsalId: number;
  fieldName: string;
  modalIndex?: number;
};

export type SetCurrentModalDataPayload = {
  propsalId: number | undefined;
  modalName: string | undefined;
  index: number | undefined;
  isMultiple: boolean | undefined;
  proposals: number[] | undefined;
};

export type SetCurrentModalIndexPayload = {
  index: number | undefined;
};

export type SetCurrentEditModalPayload = {
  values: FieldModal | undefined;
  // values: FieldModal[] | undefined;
  errors: Record<string, string> | undefined;
  // errors: Record<string, string>[] | undefined;
  modalIndex?: number;
};

export type SetCurrentEditModalMultiplePayload = {
  values: FieldModal[] | undefined;
  errors: Record<string, string>[] | undefined;
  modalIndex?: number;
};

export type DeleteMultipleModalAccordionPayload = {
  propsalId: number;
  index: number;
  fieldName: string;
};

interface SetDataForm {
  type: newOrderActionTypes.SET_FORM_DATA;
  payload: PropsalData[];
}

interface DeleteDataForm {
  type: newOrderActionTypes.DELETE_NEW_ORDER_DATA;
}

interface SetErrorsFields {
  type: newOrderActionTypes.SET_ERRORS_FIELDS;
  payload: Record<number, ErrorsData>;
}

interface SetValuesForm {
  type: newOrderActionTypes.SET_FORM_VALUES;
  payload: Record<number, FormValues>;
}

interface UpdateFieldValue {
  type: newOrderActionTypes.UPDATE_FIELD_VALUE;
  payload: UpdateFieldValuePayload;
}

interface DeleteMultipleModalAccordion {
  type: newOrderActionTypes.DELETE_MULTIPLE_MODAL_ACCORDION;
  payload: DeleteMultipleModalAccordionPayload;
}

interface DeleteModalAccordion {
  type: newOrderActionTypes.DELETE_MODAL_ACCORDION;
  payload: DeleteModalAccordionPayload;
}

interface SetCurrentModalData {
  type: newOrderActionTypes.SET_CURRENT_MODAL_DATA;
  payload: SetCurrentModalDataPayload;
}

interface SetCurrentEditModal {
  type: newOrderActionTypes.SET_CURRENT_EDIT_MODAL;
  payload: SetCurrentEditModalPayload;
}

interface SetCurrentEditModalMultiple {
  type: newOrderActionTypes.SET_CURRENT_EDIT_MODAL_MULTIPLE;
  payload: SetCurrentEditModalMultiplePayload;
}

interface SetValueFieldCurrentModal {
  type: newOrderActionTypes.SET_VALUE_FIELD_CURRENT_MODAL;
  payload: SetValueFieldCurrentModalPayload;
}

interface SetCurrentModalIndex {
  type: newOrderActionTypes.SET_CURRENT_MODAL_INDEX;
  payload: SetCurrentModalIndexPayload;
}

interface SetModalField {
  type: newOrderActionTypes.SET_MODAL_FIELD;
  payload: SetModalFieldPayload;
}

interface SetMultipleModalField {
  type: newOrderActionTypes.SET_MULTIPLE_MODAL_FIELD;
  payload: SetMultipleModalFieldPayload;
}

interface SetPropsalErrors {
  type: newOrderActionTypes.SET_PROPSAL_ERRORS;
  payload: SetPropsalErrorsPayload;
}

interface SetCurrentModalErrors {
  type: newOrderActionTypes.SET_CURRENT_MODAL_ERRORS;
  payload: SetCurrentModalErrorsPayload;
}

interface SetErrorsFormCount {
  type: newOrderActionTypes.SET_ERRORS_FORM_COUNT;
  payload: SetErrorsFormCountPayload;
}

interface SetIsFetching {
  type: newOrderActionTypes.SET_IS_FETCHING;
  payload: SetIsFetchingPayload;
}

interface DeleteFromFormPropsal {
  type: newOrderActionTypes.DELETE_FROM_FORM_PROPSAL;
  payload: DeleteFromFormPropsalPayload;
}

interface SetAlertModal {
  type: newOrderActionTypes.SET_ALERT_MODAL;
  payload: SetAlertModalPayload;
}

interface SetGroupFieldError {
  type: newOrderActionTypes.SET_GROUP_FIELD_ERROR;
  payload: SetGroupFieldErrorPayload;
}

interface ClearGroupFieldError {
  type: newOrderActionTypes.CLEAR_GROUP_FIELD_ERROR;
}

export type NewOrderAction =
  | SetDataForm
  | DeleteDataForm
  | SetErrorsFields
  | SetValuesForm
  | UpdateFieldValue
  | DeleteMultipleModalAccordion
  | DeleteModalAccordion
  | SetCurrentModalData
  | SetCurrentEditModal
  | SetCurrentEditModalMultiple
  | SetValueFieldCurrentModal
  | SetCurrentModalIndex
  | SetModalField
  | SetMultipleModalField
  | SetPropsalErrors
  | SetCurrentModalErrors
  | SetErrorsFormCount
  | SetIsFetching
  | DeleteFromFormPropsal
  | SetAlertModal
  | SetGroupFieldError
  | ClearGroupFieldError;
