import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ROLES, ROUTES } from "../../../core/consts/common";
import Check from "../../../core/consts/images/checkbox.png";
import * as selectors from "../../../core/store/selectors";
import classes from "../NewOrderRedesign.module.scss";

const Success = () => {
  const role = useSelector(selectors.role);
  const history = useHistory();

  const handleReturnToProducts = () => {
    switch (role) {
      case ROLES.CLIENT:
        history.push(`${ROUTES.CLIENT}/products`);
        break;
      case ROLES.AGENT:
        history.push(`${ROUTES.AGENT}/products`);
        break;
      case ROLES.MANAGER:
        history.push(`${ROUTES.MANAGER}/products`);
        break;
      case ROLES.ADMIN:
        history.push(`${ROUTES.ADMIN}/products`);
        break;
      default:
        break;
    }
  };

  return (
    <div className={classes.success_container}>
      <div className={classes.icon_chech}>
        <img src={Check} alt="" />
      </div>
      <div className={classes.category}>все получилось!</div>
      <div>
        У вас все получилось. Наши специалисты проверят заявку перед
        оптравлением в банк(и) и сразу же отправят на согласование. Статус
        заявки можно отслеживать в разделе “Заявки”, также мы пришлем вам
        уведомление по электронной почте
      </div>

      <button
        className="button_primary_w100 green_button"
        onClick={handleReturnToProducts}
      >
        Отлично
      </button>
    </div>
  );
};

export default Success;
