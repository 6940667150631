import { Theme, createStyles, makeStyles } from "@material-ui/core";
import React from "react";
import { COLORS } from "../../../core/consts/redesign/commonRedesign";

/* Redesign . Common Empty Page component */
type PropsType = {
  style?: React.CSSProperties;
  margin?: string;
  titleColor: string;
  title?: string;
  content: string;
  maxWidth?: string;
  gap?: string;
  button?: React.ReactNode
}

const EmptyPage: React.FC<PropsType> = ({
  style,
  margin = '120px',
  titleColor,
  title,
  content,
  button,
  gap = "16px",
  maxWidth = '504px'
}) => {
  const useStyles = makeStyles((theme: Theme) => createStyles({
    container: {
      boxSizing: 'border-box',
      marginTop: margin,
      textAlign: "center",
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      gap: gap,
    },
    title: {
      marginTop: 0,
      fontSize: '41px',
      fontWeight: 600,
      lineHeight: '45px',
      color: titleColor,
      [theme.breakpoints.down(1024)]: {
        fontSize: '24px',
        lineHeight: '25px'
      }
    },
    text: {
      maxWidth: maxWidth,
      textAlign: 'center',
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '21px',
      color: COLORS.gray2,
      [theme.breakpoints.down(1024)]: {
        fontSize: '16px',
        lineHeight: '21px'
      }
    }
  }))
  const classes = useStyles();

  return (
    <div className={classes.container} style={style}>
      <h2 className={classes.title}>{title}</h2>
      <span className={classes.text}>{content}</span>
      {button}
    </div>
  )
}

export default EmptyPage;
