import { AddMessageAction, CleanCurrentChatAction, RemoveMessageAction, SetCurrentChatAction, UpdateMessageAction, currentChatType, dataForUserChat } from "../../types/currentChatsTypes";

const initialChatState: dataForUserChat = {
  nameChat: '',
  bankName: '',
  roleChat: '',
  isMyMessage: undefined,
  lastMessage: '',
  lastTimeMessage: '',
  productName: '',
  avatar_url: '',
  countUnreadMessage: 0,
  isMyMessageBank: undefined,
  lastMessageBank: '',
  lastTimeMessageBank: '',
  countUnreadMessageBank: 0,
  chatMessagesLength: 0,
  chatMessages: [],
}

export const chatReducer = (state = initialChatState,
  action: SetCurrentChatAction | CleanCurrentChatAction | AddMessageAction | UpdateMessageAction | RemoveMessageAction) => {
  switch (action.type) {
    case currentChatType.SET_USER_CHAT:
      return {
        ...state,
        ...action.payload,
      }
    case currentChatType.CLEAN_USER_CHAT:
      return initialChatState;

    case currentChatType.ADD_MESSAGE:
      return {
        ...state,
        chatMessages: [action.payload, ...(state.chatMessages ?? [])],
      };


    case currentChatType.UPDATE_MESSAGE:
      return {
        ...state,
        chatMessages: (state.chatMessages ?? []).map(msg =>
          msg.id === action.payload.id ? { ...msg, ...action.payload } : msg
        ),
      };

    case currentChatType.REMOVE_MESSAGE:
      return {
        ...state,
        chatMessages: (state.chatMessages ?? []).filter(msg => msg.id !== action.payload),
      };
    default:
      return state
  }
}