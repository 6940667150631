import { bigFormActionTypes, FetchUpdateErrorsArrayPayload, SetIsDisplayInAccordionPayload, SetPropsalsIdPayload } from '../../types/bigForm';

export const fetchUpdateErrorsArray = (payload: FetchUpdateErrorsArrayPayload) => {
    return {type: bigFormActionTypes.UPDATE_ERRORS_ARRAY, payload};
};

export const setIsDisplaySignalInAccordion = (payload: SetIsDisplayInAccordionPayload) => {
    return {type: bigFormActionTypes.DISPLAY_SIGNALS_IN_ACCORDION, payload};
};

export const setPropsalsId = (payload: SetPropsalsIdPayload) => {
    return {type: bigFormActionTypes.SET_PROPSALS_ID, payload};
};
