import { Box, Theme, Typography, createStyles, makeStyles } from "@material-ui/core";
import React, { memo, useEffect, useState } from "react";
import { v4 } from "uuid";
import partnerService from "../../../../core/services/PartnerService";
import { PartnerLeadType, PartnerLeadsResType, partnerApplicationsListQuery } from '../../../../core/types/partnerApiTypes';
import LeadCard from '../lead-card';
import Search from "../search";

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        flexGrow: 1,
        minHeight: '100%'
    },
    cardsWrap: {
        padding: '0 30px',
        maxHeight: 'calc(100vh - 380px)',
        overflow: 'auto',
    },
    paper: {
        marginTop: '20px',
        padding: '30px',
        borderRadius: '10px',
        backgroundColor: '#ffffff',

    },
}))

type PropsType = {
    index: number,
    isNew: boolean | undefined,
    isArchive: boolean | undefined,
    setNewLeads: (prev: number | ((prev: number) => number)) => void,
    setAllLeads: (prev: number | ((prev: number) => number)) => void,
}

const LeadsList: React.FC<PropsType> = (props) => {
    const classes = useStyles()
    const [searchInn, setSearchInn] = useState<string>('')
    const [searchPeriod, setSearchPeriod] = useState<string>('all')
    const [searchStatus, setSearchStatus] = useState<string>('all')
    const [searchProduct, setSearchProduct] = useState<number | string>('all')
    const [leadsList, setLeadsList] = useState<PartnerLeadType[]>()
    const [productsList, setProductsList] = useState<string[]>()
    const [noLeadsMessage, setNoLeadsMessage] = useState<string>('')
    const [statusList, setStatusList] = useState<string[]>([])
    const [queryType, setQueryType] = useState<boolean | number>(0)
    const Q: partnerApplicationsListQuery = {
        isnew: props.isNew,
        isarchive: props.isArchive,
        inn: undefined,
        period: undefined,
        product: undefined,
        Status: undefined,
    }
    useEffect(() => {
        if (typeof (queryType) === 'boolean') {
            Q.inn = searchInn.length > 0 ? searchInn : undefined;
            Q.period = searchPeriod === 'all' ? undefined : searchPeriod;
            Q.product = typeof (searchProduct) === 'string' ? undefined : searchProduct;
            Q.Status = searchStatus === 'all' ? undefined : searchStatus;
        }
        else {
            Q.inn = undefined;
            Q.period = undefined;
            Q.product = undefined;
            Q.Status = undefined;
        }

        partnerService.getLeadsService(Q)
            .then((res: PartnerLeadsResType) => {
                (res.leads.length === 0 && typeof (queryType) === 'number' && props.isNew && !props.isArchive) ? setNoLeadsMessage('У вас нет новых заявок') : setNoLeadsMessage('')
                if (typeof (queryType) === 'number') {
                    let tmpProducts: string[] = []
                    res.leads.map((lead) => {
                        tmpProducts[lead.product.id] = lead.product.name
                    })
                    setProductsList(tmpProducts)
                    setStatusList(res.statuses)
                    setLeadsList(res.leads.filter(lead => lead.isNew))
                    props.setAllLeads(res.leads.length)
                    props.setNewLeads(res.leads.filter(lead => lead.isNew).length)
                    setQueryType(true)
                }
                else if (typeof (queryType) === 'boolean') {
                    setStatusList(res.statuses)
                    setLeadsList(res.leads)
                }
            }
            ).catch((error: any) => {
                console.log(error)
            })
    }, [searchInn, searchPeriod, searchProduct, props.index, searchStatus])

    useEffect(() => {
        typeof (queryType) === 'boolean' && !queryType && setQueryType(true)
    }, [searchInn, searchPeriod, searchProduct, searchStatus])


    return (noLeadsMessage?.length > 0 ? <Typography
        variant="h4"
        component='p'
        style={{ margin: '15px 30px' }}>
        {noLeadsMessage}
    </Typography> : <div className={classes.root}>
        <Search
            statusList={statusList}
            productsList={productsList}
            setSearchStatus={setSearchStatus}
            searchStatus={searchStatus}
            setSearchProduct={setSearchProduct}
            searchProduct={searchProduct}
            searchInn={searchInn}
            setSearchInn={setSearchInn}
            searchPeriod={searchPeriod}
            setSearchPeriod={setSearchPeriod}
        />
        {leadsList?.length === 0 ?
            <Typography
                className={classes.paper}
                variant="h4"
                component='p'
                style={{ margin: '15px 30px' }}>
                Заявки с заданными параметрами отсутствуют
            </Typography>
            : <Box className={classes.cardsWrap}>
                {searchStatus === 'all' ? leadsList?.map((lead) => {
                    return <LeadCard
                        key={v4()}
                        {...lead}
                    />
                }) : leadsList?.map((lead) => {
                    return lead.status === searchStatus ? <LeadCard
                        key={v4()}
                        {...lead}
                    /> : null
                })}
            </Box>}
    </div>
    )
}

export default memo(LeadsList)
