import React from "react";

const CreditsCardIcon = () => {
  return (
    <svg
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.71647 15.6917H5.36647V16.0417V24.7917C5.36647 25.2713 5.55699 25.7312 5.89612 26.0704C6.23525 26.4095 6.69521 26.6 7.1748 26.6H27.5915C28.0711 26.6 28.531 26.4095 28.8702 26.0704C29.2093 25.7312 29.3998 25.2713 29.3998 24.7917V16.0417V15.6917H29.0498H5.71647ZM29.0498 13.475H29.3998V13.125V11.6667C29.3998 11.1871 29.2093 10.7271 28.8702 10.388C28.531 10.0489 28.0711 9.85833 27.5915 9.85833H7.1748C6.6952 9.85833 6.23525 10.0489 5.89612 10.388C5.55699 10.7271 5.36647 11.1871 5.36647 11.6667V13.125V13.475H5.71647H29.0498ZM14.4665 21.525H10.0915C9.79752 21.525 9.51561 21.4082 9.30776 21.2004C9.09991 20.9925 8.98314 20.7106 8.98314 20.4167C8.98314 20.1227 9.09991 19.8408 9.30776 19.633C9.51561 19.4251 9.79752 19.3083 10.0915 19.3083H14.4665C14.7604 19.3083 15.0423 19.4251 15.2502 19.633C15.458 19.8408 15.5748 20.1227 15.5748 20.4167C15.5748 20.7106 15.458 20.9925 15.2502 21.2004C15.0423 21.4082 14.7604 21.525 14.4665 21.525ZM7.1748 7.64167H27.5915C28.659 7.64167 29.6827 8.06573 30.4376 8.82056C31.1924 9.5754 31.6165 10.5992 31.6165 11.6667V24.7917C31.6165 25.8592 31.1924 26.8829 30.4376 27.6378C29.6827 28.3926 28.659 28.8167 27.5915 28.8167H7.1748C6.10731 28.8167 5.08353 28.3926 4.3287 27.6378C3.57387 26.8829 3.1498 25.8592 3.1498 24.7917V11.6667C3.1498 10.5992 3.57387 9.5754 4.3287 8.82056C5.08353 8.06573 6.10731 7.64167 7.1748 7.64167Z"
        fill="#131313"
        stroke="white"
        strokeWidth="0.7"
      />
    </svg>
  );
};

export default CreditsCardIcon;
