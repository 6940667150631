import React from "react";

const ArrowRight = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.713 12L9.70296 5.99L8.28796 7.403L12.888 12L8.28796 16.596L9.70196 18.01L15.713 12Z"
        fill="#131313"
      />
    </svg>
  );
};

export default ArrowRight;
