import { Button, Modal, Typography } from "@material-ui/core";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import React, { memo } from "react";
import IconButtonMain from "../../../UIcomponentsRedesign/IconButtonMain/IconButtonMain";
import { COLORS } from "../../../core/consts/redesign/commonRedesign";
import { Close } from "../../../core/consts/redesign/icons";

/* commonly used confirm modal */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: "absolute",
      width: "600px",
      top: "50%",
      left: "50%",
      maxWidth: "780px",
      transform: "translate(-50%, -50%)",
      backgroundColor: COLORS.white,
      border: "none",
      padding: "24px",
      borderRadius: "8px",
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: '16px',
      [theme.breakpoints.down(1024)]: {
        width: "360px",
      }
    },
    box: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "baseline",
      width: "100%",
    },

    btnMenu: {
      margin: "0 auto",
      padding: "18px 36px",
      display: "block",
      "& svg": {
        fontSize: "42px",
      },
    },
    text: {
    },
    title: {
      fontSize: '24px',
      lineHeight: '25px',
      fontWeight: 600,
      color: COLORS.black,
      margin: 0,
      padding: 0
    },
    textBtn: {
      color: "#306F9A",
      fontFamily: 'Gilroy',
      fontWeight: 500,
      width: "fit-content",
      cursor: "pointer",
      minWidth: "200px",
      marginTop: "40px",
      "&:hover": {
        color: "#204A66",
        transition: "all ease-in-out 0.3s",
      },
    },
  })
);

type PropsType = {
  handleConfirm?: () => void;
  handleConfirmDelLead?: (leadId: number) => void;
  leadId?: number;
  title?: string;
  text?: string;
  handleCancel: () => void;
  confBtnText?: string;
  cancelBtnText: string;
  open: boolean;
  isKros?: boolean;
  setModalProps?: (prev: boolean | ((prev: boolean) => boolean)) => void;
  handleClose?: () => void;
  textButtonHandle?: () => void;
  textTextBtn?: string;
  isExtraCloseModal?: boolean;
  extraSubText?: React.ReactNode;
  extraButtonText?: string;
  extraButtonClick?: () => void;
};

const ConfirmModal: React.FC<PropsType> = (props) => {
  const classes = useStyles();

  const handleClose = () => {
    props.handleClose && props.handleClose();
    props.setModalProps && props.setModalProps(false);
    props.isExtraCloseModal && props.handleConfirm && props.handleConfirm();
  };

  const handleCancel = () => {
    props.handleCancel();
  };

  const handleConfirm = () => {
    if (props.leadId !== undefined && props.handleConfirmDelLead) {
      props.handleConfirmDelLead(props.leadId);
    } else {
      props.handleConfirm && props.handleConfirm();
    }
  };

  return (
    <Modal
      open={props.open}
      onClose={props.extraButtonText ? handleCancel : handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className={classes.paper} style={props.extraButtonText ? { padding: "50px 50px" } : {}}>
        <div className={classes.box}>
          <h3
            className={classes.title}
          >
            {props.title}
          </h3>
          {props.isKros && (
            <IconButtonMain
              aria-label="close"
              icon={<Close color={COLORS.gray2} />}
              handleClick={props.extraButtonText ? handleCancel : handleClose} />
          )}
        </div>
        <Typography variant="body1" component="p" className={classes.text}>
          {props.text}
        </Typography>
        {props.extraSubText && props.extraSubText}
        <div style={{ marginTop: '40px' }}>
          {props.confBtnText && (props.handleConfirm || props.handleConfirmDelLead) && (
            <Button
              className={classes.btnMenu}
              color="primary"
              variant="outlined"
              style={{ display: "inline-block", marginRight: "25px" }}
              onClick={handleConfirm}
            >
              <Typography
                variant="body1"
                component="span"
                style={{ color: "inherit", textTransform: "none" }}
              >
                {props.confBtnText}
              </Typography>
            </Button>
          )}
          {props.extraButtonText && (
            <Button
              className={classes.btnMenu}
              color="primary"
              variant="outlined"
              style={{ display: "inline-block", marginRight: "25px" }}
              onClick={props.extraButtonClick}
            >
              <Typography
                variant="body1"
                component="span"
                style={{ color: "inherit", textTransform: "none" }}
              >
                {props.extraButtonText}
              </Typography>
            </Button>
          )}
          <Button
            className={classes.btnMenu}
            color="primary"
            variant="contained"
            style={{ display: "inline-block", boxShadow: "none" }}
            onClick={handleCancel}
          >
            <Typography
              variant="body1"
              component="span"
              style={{ color: "inherit", textTransform: "none" }}
            >
              {props.cancelBtnText}
            </Typography>
          </Button>
        </div>
        {props.textButtonHandle && props.textTextBtn && (
          <Typography
            variant="body1"
            component="p"
            onClick={props.textButtonHandle}
            className={classes.textBtn}
          >
            {props.textTextBtn}
          </Typography>
        )}
      </div>
    </Modal>
  );
};

export default memo(ConfirmModal);
