import { InputTypeEnum } from "../consts/redesign/commonRedesign";

const formatPassport = (value: string, inputType: string): string => {
  if (inputType !== InputTypeEnum.passport) return value;
  const digitsOnly = value.replace(/\D+/g, '');
  if (digitsOnly.length <= 10) {
    if (digitsOnly.length > 4) {
      return `${digitsOnly.slice(0, 4)} ${digitsOnly.slice(4)}`;
    }
    return digitsOnly;
  }

  return `${digitsOnly.slice(0, 4)} ${digitsOnly.slice(4, 10)}`;
};

const formatPassCode = (value: string, inputType: string) => {
  if (inputType !== InputTypeEnum.passportCode) return value;

  const digitsOnly = value.replace(/\D+/g, '');
  if (digitsOnly.length <= 3) {
    return digitsOnly;
  } else if (digitsOnly.length <= 6) {
    return digitsOnly.slice(0, 3) + '-' + digitsOnly.slice(3);
  }
  return digitsOnly.slice(0, 3) + '-' + digitsOnly.slice(3, 6);
};

// const formatPassSeries = (value: string, inputType: string): string => {
//   if (inputType !== InputTypeEnum.passportSeries) return value;
//   const digitsOnly = value.replace(/\D+/g, '');

//   if (digitsOnly.length > 4) {
//     return digitsOnly.slice(0, 4);
//   }
//   return digitsOnly;
// };

// const formatPassNumber = (value: string, inputType: string): string => {
//   if (inputType !== InputTypeEnum.passportNumber) return value;
//   const digitsOnly = value.replace(/\D+/g, '');

//   if (digitsOnly.length > 6) {
//     return digitsOnly.slice(0, 6);
//   }
//   return digitsOnly;
// };

const formatSnils = (value: string, inputType: string): string => {
  if (inputType !== InputTypeEnum.snils) return value;

  const digitsOnly = value.replace(/\D+/g, '');

  if (digitsOnly.length <= 3) {
    return digitsOnly;
  } else if (digitsOnly.length <= 6) {
    return digitsOnly.slice(0, 3) + '-' + digitsOnly.slice(3);
  } else if (digitsOnly.length <= 9) {
    return digitsOnly.slice(0, 3) + '-' + digitsOnly.slice(3, 6) + '-' + digitsOnly.slice(6);
  }
  return digitsOnly.slice(0, 3) + '-' + digitsOnly.slice(3, 6) + '-' + digitsOnly.slice(6, 9) + ' ' + digitsOnly.slice(9, 11);
};

// const formatPostCode = (value: string, inputType: string): string => {
//   if (inputType !== InputTypeEnum.postalCode) return value;
//   const digitsOnly = value.replace(/\D+/g, '');

//   if (digitsOnly.length > 6) {
//     return digitsOnly.slice(0, 6);
//   }
//   return digitsOnly;
// };

// const formatBik = (value: string, inputType: string): string => {
//   if (inputType !== InputTypeEnum.bik) return value;
//   const digitsOnly = value.replace(/\D+/g, '');

//   if (digitsOnly.length > 9) {
//     return digitsOnly.slice(0, 9);
//   }
//   return digitsOnly;
// };

// const formatIkz = (value: string, inputType: string): string => {
//   if (inputType !== InputTypeEnum.ikz) return value;
//   const digitsOnly = value.replace(/\D+/g, '');

//   if (digitsOnly.length > 36) {
//     return digitsOnly.slice(0, 36);
//   }
//   return digitsOnly;
// };

// const formatInn = (value: string, inputType: string): string => {
//   if (inputType !== InputTypeEnum.inn) return value;
//   const digitsOnly = value.replace(/\D+/g, '');

//   if (digitsOnly.length > 12) {
//     return digitsOnly.slice(0, 12);
//   }
//   return digitsOnly;
// };

const formatPhoneNumber = (value: string, inputType: string): string => {
  if (inputType !== InputTypeEnum.phone) return value;

  const cleanedValue = value.replace(/[ ()-]/g, '');

  const validCharacters = /^[0-9+]*$/;

  if (!validCharacters.test(cleanedValue)) {
    return '';
  }

  if (cleanedValue.startsWith('+7')) {
    return cleanedValue.slice(0, 12);
  } else {
    return cleanedValue.slice(0, 11);
  }
};

export const noopFormatter = (value: string) => value;

export const createFormatter = (length: number) => (value: string) => {
  const digitsOnly = value.replace(/\D+/g, '');
  return digitsOnly.length > length ? digitsOnly.slice(0, length) : digitsOnly;
};

export const formatters = {
  [InputTypeEnum.postalCode]: createFormatter(6),
  [InputTypeEnum.bik]: createFormatter(9),
  [InputTypeEnum.ikz]: createFormatter(36),
  [InputTypeEnum.inn]: createFormatter(12),
  [InputTypeEnum.correspondentAccount]: createFormatter(20),
  [InputTypeEnum.currentAccount]: createFormatter(20),
  [InputTypeEnum.phone]: formatPhoneNumber,
  [InputTypeEnum.snils]: formatSnils,
  [InputTypeEnum.passport]: formatPassport,
  [InputTypeEnum.passportCode]: formatPassCode,
  [InputTypeEnum.passportNumber]: createFormatter(6),
  [InputTypeEnum.passportSeries]: createFormatter(4),
  [InputTypeEnum.email]: noopFormatter,
  [InputTypeEnum.address]: noopFormatter,
  [InputTypeEnum.url]: noopFormatter,
  [InputTypeEnum.currency]: noopFormatter,
  [InputTypeEnum.percent]: noopFormatter,
};

export const getInputName = (inputType: InputTypeEnum) => {
  switch (inputType) {
    case InputTypeEnum.phone:
      return "phone";
    case InputTypeEnum.email:
      return "email";
    case InputTypeEnum.address:
      return "address";
    case InputTypeEnum.url:
      return "url";
    case InputTypeEnum.snils:
      return "snils";
    case InputTypeEnum.postalCode:
      return "postal-code";
    case InputTypeEnum.bik:
      return "bik";
    case InputTypeEnum.ikz:
      return "ikz";
    case InputTypeEnum.inn:
      return "inn";
    case InputTypeEnum.correspondentAccount:
      return "correspondent-account";
    case InputTypeEnum.currentAccount:
      return "current-account";
    case InputTypeEnum.passport:
      return "pasport-id";
    case InputTypeEnum.passportCode:
      return "pasport-code";
    case InputTypeEnum.passportSeries:
      return "pasport-series";
    case InputTypeEnum.passportNumber:
      return "pasport-number";
    case InputTypeEnum.currency:
      return "currency";
    case InputTypeEnum.percent:
      return "percent";
    default:
      return ""
  }
};

export const getFieldType = (inputType: InputTypeEnum) => {
  switch (inputType) {
    case InputTypeEnum.phone:
      return "tel";
    case InputTypeEnum.email:
      return "email";
    case InputTypeEnum.address:
      return "text";
    case InputTypeEnum.url:
      return "url";
    case InputTypeEnum.postalCode:
      return "postal-code";
    case InputTypeEnum.bik:
    case InputTypeEnum.passportSeries:
    case InputTypeEnum.passportNumber:
    case InputTypeEnum.currency:
    case InputTypeEnum.percent:
      return "number";
    default:
      return "text";
  }
};