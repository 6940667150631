/* eslint-disable react/jsx-pascal-case */
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { rootState } from "../../../../core/types/rootState";

import { useHistory } from "react-router-dom";
import ConfirmModalRedesign from "../../../../app/components/confirm-modal-redesign/ConfirmModalRedesign";
import { ROUTES } from "../../../../core/consts/common";
import { actions, useAppThunkDispatch } from "../../../../core/store";
import * as selectors from "../../../../core/store/selectors";
import { sendDataFormToDraft } from "../../../../core/store/thunk/newOrder";
import PromptModal from "../../../new-order/components/prompt-modal/PromptModal";
import { PropsalData } from "../../../new-order/types/bigForm";
import FormAccordion from "../form-accordion/FormAccordion";

type PropsType = {
  currentStep: PropsalData;
  // setCurrentStep: React.Dispatch<React.SetStateAction<PropsalData | null>>;
  setCurrentStep: (step: PropsalData) => void;
};

const BigForm: React.FC<PropsType> = ({ currentStep, setCurrentStep }) => {
  // const classes = useStylesBigForm();
  const dispatch = useAppThunkDispatch();
  const history = useHistory();
  const role = useSelector((s: rootState) => s.currentUser.role);
  const formData = useSelector(selectors.formData);
  const alertModal = useSelector(selectors.alertModal);

  // const handleAddToDraft = (
  //   setModalVisible: (isModalVisible: boolean) => void,
  //   setConfirmedNavigation: (isConfirmedNavigation: boolean) => void
  // ) => {
  //   dispatch(sendDataFormToDraft()).then(() => {
  //     setModalVisible(false);
  //     setConfirmedNavigation(true);
  //   });
  // };

  const handleAddToDraftForConfirm = () => {
    dispatch(sendDataFormToDraft()).then(() => {
      actions.setAlertModal({
        tittle: "Черновик сохранен",
        text: "Ваш черновик успешно сохранен в общем списке. Вы можете вернуться к заполнению в любое время.",
        isError: false,
        confModal: true,
        draftModal: false,
        isRemain: true,
        isPromptShow: false,
      });
    });
  };

  const handleCancel = () => {
    history.push(
      role === "Клиент"
        ? `${ROUTES.CLIENT}/products`
        : `${ROUTES.AGENT}/products`
    );
  };

  const handleModalClose = () => {
    if (alertModal.isRemain) {
      actions.setAlertModal({
        tittle: undefined,
        text: undefined,
        isError: false,
        confModal: false,
        draftModal: false,
        isRemain: false,
        isPromptShow: true,
      });
    } else {
      handleCancel();
      actions.setAlertModal({
        tittle: undefined,
        text: undefined,
        isError: false,
        confModal: false,
        draftModal: false,
        isRemain: false,
        isPromptShow: false,
      });
    }
  };

  const handleConfirm = () => {
    actions.setAlertModal({
      tittle: undefined,
      text: undefined,
      isError: false,
      confModal: false,
      draftModal: false,
      isRemain: false,
      isPromptShow: true,
    });
  };

  useEffect(() => {
    console.log("BigForm formData", formData)
  }, [formData])

  return (
    <>
      {formData && (
        <>
          <FormAccordion propsal={currentStep} />
        </>
      )}
      <ConfirmModalRedesign
        title={alertModal.tittle}
        text={alertModal.text}
        handleCancel={handleCancel}
        handleConfirm={handleConfirm}
        setModalProps={handleModalClose}
        confBtnText={alertModal.isRemain ? "Остаться" : undefined}
        cancelBtnText="К заявкам"
        open={alertModal.confModal}
      />
      <ConfirmModalRedesign
        title={alertModal.tittle}
        text={alertModal.text}
        handleCancel={handleCancel}
        handleConfirm={handleConfirm}
        setModalProps={handleModalClose}
        confBtnText={"Покинуть заявку"}
        cancelBtnText={"Продолжить заполнение"}
        open={alertModal.draftModal}
        textButtonHandle={handleAddToDraftForConfirm}
        textTextBtn="Сохранить как черновик"
      />
      {alertModal.isPromptShow && (
        <PromptModal />
      )}
    </>
  );
};

export default React.memo(BigForm);
