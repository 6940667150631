import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Field, FieldType } from "../types/bigForm";
import * as selectors from "../../../core/store/selectors";
import { setValueField } from "../../../core/store/thunk/newOrder";
import { rootState } from "../../../core/types/rootState";

type RequiredProps = {
  field: FieldType;
  propsalId: number;
  parentName?: string;
  indexGroupField?: number;
};

type Props<T> = T & RequiredProps;

const withFormData = <T extends Object>(Component: React.FC<T>) => {
  return (props: Props<T>) => {
    const dispatch = useDispatch();

    const value = useSelector((state: rootState) =>
      selectors.fieldValue(state, {
        fieldName: props.field.name,
        propsalId: props.propsalId,
      })
    );
    const error = useSelector((state: rootState) =>
      selectors.errorValue(state, {
        fieldName: props.field.name,
        propsalId: props.propsalId,
      })
    );

    const onChange = (
      fieldName: string,
      value: Field,
      propsalId: number,
      parentName?: string,
      indexGroupField?: number
    ) => {
      // console.log("document.activeElement", document.activeElement)
      dispatch(setValueField(fieldName, value, propsalId));
    };


    return (
      <Component {...props} value={value} error={error} onChange={onChange} />
    );
  };
};

export default withFormData;
