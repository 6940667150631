import { Theme, createStyles, makeStyles } from "@material-ui/core";
import { COLORS } from "../../core/consts/redesign/commonRedesign";

export const useStylesNewOrder = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: '96px 16px',
      margin: "0 auto",
      boxSizing: "border-box",
      maxWidth: '100%',
      minHeight: "100%",
      [theme.breakpoints.down(1024)]: {
        minHeight: "calc(100vh - 128px)",
        padding: '0',
        marginBottom: '64px',
        background: COLORS.white,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        paddingBottom: '172px'
      },
      [theme.breakpoints.down(760)]: {
        padding: '24px 8px 160px',
      },

    },
    content: {
      // height: 'auto',
      minHeight: '100vh',
      maxHeight: 'fit-content',
      padding: "0",
      display: "flex",
      flexWrap: "nowrap",
    },
    bigFormContainer: {
      // //из высоты экрана вычитается высота хедера.
      // height: 'calc(100vh - 105.8px)',
      height: '78vh',
      overflowY: 'auto',
      padding: "0",
      boxSizing: "border-box",
      width: "70%",
    },
    propsalsArea: {
      width: "472px",
      display: "flex",
      boxSizing: "border-box",
    },
    propsalsDivider: {
      margin: "0 30px",
      height: "100vh",
    },
    propsalsBox: {
      display: "flex",
      flexDirection: "column",
      padding: "30px 0",
      width: "100%",
    },
    listContainer: {
      marginBottom: '20px',
      maxHeight: "62vh",
      position: "sticky",
      overflowY: "auto",
      boxSizing: "border-box",
      padding: "0",
      "&::-webkit-scrollbar": {
        width: "0.2em",
        // height: "0.52em",
      },
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(208 217 222 / 88%)",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#D0D9DE",
        outline: "0.2px solid #D0D9DE",
      },
    },
    titleProduct: {
      padding: "0 30px 26px 0",
    },
    listItem: {
      // padding: '0 0 12px 0',
      width: "100%",
      backgroundColor: "#D0D9DE",
      borderRadius: "4px",
      padding: "10px",
      boxSizing: "border-box",
      display: "flex",
      justifyContent: "flex-start",
      "&:not(:first-child)": {
        marginTop: "10px",
      },
    },
    listIcon: {
      margin: "0",
      paddingRight: "10px",
      // padding: "0",
      boxSizing: "border-box",
      minWidth: "min-content",
      "& svg": {
        color: "#B0BFC6",
      },
      "&:hover": {
        cursor: "pointer",
        "& svg": {
          color: "#131313",
        },
      },
    },
    addedProducts: {
      // paddingLeft: '5px',
    },
    buttonArea: {
      bottom: 25,
      paddingTop: '25px',
      width: '22.5%',
      position: 'fixed',
      [theme.breakpoints.down(1024)]: {
        bottom: "100px",
      }
    },
    saveDraftButtonBox: {
      bottom: "88px",
      cursor: "pointer",
      width: "100%",
      minWidth: "200px",
      // fontFamily: 'Gotham Pro',
      // fontStyle: 'medium',
      // fontSize: '16px',
      margin: "25px 0",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    saveDraftButton: {
      color: "#306F9A",
      fontFamily: "Gilroy",
      fontWeight: 500,
      width: "fit-content",
      fontStyle: "medium",
      fontSize: "16px",
      "&:hover": {
        color: "#204A66",
        transition: "all ease-in-out 0.3s",
      },
    },
    newOrderButtonBox: {
      height: "58px",
    },
    newOrderButton: {
      width: "100%",
      textTransform: "none",
      borderRadius: "4px",
      minWidth: "200px",
      fontFamily: "Gilroy",
      fontWeight: 500,
      fontStyle: "medium",
      fontSize: "16px",
      // padding: "15px 0",
      height: "58px",
      boxSizing: "border-box",
    },
  })
);
