import { InputAdornment } from "@mui/material";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import { useEffect, useRef } from "react";
import { COLORS } from "../core/consts/redesign/commonRedesign";
import { SendArrow } from "../core/consts/redesign/icons";
import IconButtonMain from "./IconButtonMain/IconButtonMain";

interface ExtraProps {
  handleMessageSend: () => void;
  isSendDisabled: boolean;
}
type TextAreaCustomProps = TextFieldProps & ExtraProps;

export const TextAreaMessage = styled(({ handleMessageSend, isSendDisabled, ...props }: TextAreaCustomProps) => {
  const textAreaRef = useRef<HTMLInputElement | null>(null);
  useEffect(() => {
    if (textAreaRef.current) {
      const element = textAreaRef.current;
      element.scrollTop = element.scrollHeight;
    }
  }, [props.value]);

  return (
    <TextField
      {...props}
      color="primary"
      variant="outlined"
      fullWidth
      multiline={true}
      maxRows={3}
      minRows={1}
      hiddenLabel
      InputProps={{
        endAdornment: <InputAdornment position="end">
          <IconButtonMain
            color="noBgGray"
            style={{
              width: '24px',
              height: '24px'
            }}
            icon={<SendArrow />}
            handleClick={handleMessageSend}
            disabled={isSendDisabled}
          />
        </InputAdornment>
      }}
      inputRef={textAreaRef}
    />
  )
})(() => {
  return {
    "& .MuiOutlinedInput-root": {
      padding: "12px",
      width: "100%",
      backgroundColor: COLORS.light_gray_bg,
      border: '1px solid white',
      color: COLORS.black,
      fontSize: "16px",
      fontWeight: "500",
      lineHeight: "21px",
      fontFamily: "Gilroy",
      "&.Mui-focused, &:hover": {
        border: `1px solid ${COLORS.brand_blue}`,
      },
      "& fieldset": {
        border: "none",
      },
    },
    "& .MuiOutlinedInput-input": {
      fontSize: "16px",
      fontWeight: "500",
      lineHeight: "21px",
      fontFamily: "Gilroy",
      "&::placeholder": {
        color: COLORS.gray2,
        opacity: 1
      },
      scrollBehavior: "smooth",
    },
  }
});
