import { ThemeProvider, createTheme, useMediaQuery } from "@material-ui/core";
import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import IconButtonMain from "../../UIcomponentsRedesign/IconButtonMain/IconButtonMain";
import AgreementModal from "../../app/components/agreement-modal/AgreementModal";
import { FormType } from "../../app/enum";
import { ROLES, ROUTES, termsOfUseLink } from "../../core/consts/common";
import {
  COLORS,
  DevStand,
  DevStandApp,
  ProdStand,
} from "../../core/consts/redesign/commonRedesign";
import { ArrowLeftIcon } from "../../core/consts/redesign/icons/ArrowLeft";
import { ArrowRightIcon } from "../../core/consts/redesign/icons/ArrowRight";
import { CloseIcon } from "../../core/consts/redesign/icons/Close";
import { LOGO } from "../../core/consts/redesign/icons/LOGO";
import { useQuery } from "../../core/hooks/useQuery";
import authService from "../../core/services/AuthService";
import tokenService from "../../core/services/TokenService";
import { useAppSelector } from "../../core/store";
import { stringParser } from "../../core/utils/queryParsers";
import classes from "./LoginNew.module.scss";
import AddInnForm from "./components/AddInnForm";
import BlockMobTemp from "./components/BlockMobTemp";
import Certificate from "./components/Certificate";
import CheckComponents from "./components/CheckComponents";
import EmailForm from "./components/EmailForm";
import EmailFormWithCode from "./components/EmailFormWithCode";
import LoginClientOrAgentForm from "./components/LoginClientOrAgentForm";
import LoginTypesForm from "./components/LoginTypesForm";
import PhoneForm from "./components/PhoneForm";
import PhoneFormWithCode from "./components/PhoneFormWithCode";

const queryParsers = {
  formType: stringParser<FormType>(),
};

const theme = {
  palette: {
    primary: {
      main: COLORS.brand_blue,
      light: COLORS.brand_blue,
      dark: COLORS.brand_blue,
      contrastText: "#ffffff",
      "900": COLORS.brand_blue,
    },
    info: {
      main: COLORS.gray,
      light: COLORS.gray,
      dark: COLORS.gray,
      contrastText: "#ffffff",
      "900": COLORS.gray,
    },
    secondary: {
      main: COLORS.gray,
      light: COLORS.gray,
      dark: COLORS.gray,
      contrastText: "#ffffff",
    },
  },

  typography: {
    fontFamily: `"Gilroy", ""Arial", sans-serif`,
    fontSize: 19,
    fontWeightLight: 500,
    fontWeightRegular: 500,
    fontWeightMedium: 600,
  },

  components: {
    MuiInputLabel: {
      defaultProps: {
        sx: {
          fontSize: "16px !important",
        },
      },
    },
    MuiOutlinedInput: {
      defaultProps: {
        sx: {
          fontSize: "50px !important",
        },
      },
    },
  },
  // overrides: {
  //   MuiRadio: {
  //     root: {
  //       color: "green",
  //     },
  //     colorSecondary: {
  //       "&$checked": {
  //         color: "green",
  //       },
  //     },
  //   },
  // },
};

const muiTheme = createTheme(theme);

export const getToken: () => boolean = () => {
  const accessTokenLocal = tokenService.getAccessToken();
  let exp: number = 0;
  let isValidToken: boolean = false;
  if (accessTokenLocal) {
    try {
      const decoded = jwtDecode(accessTokenLocal);
      if (decoded) {
        const expD = decoded.exp;
        if (expD) {
          exp = expD;
          const now = new Date().getTime() / 1000;
          if (now < exp) {
            isValidToken = true;
          } else {
            isValidToken = false;
          }
        } else {
          exp = 0;
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  return isValidToken;
};

const LoginNew = () => {
  const history = useHistory();
  const isLagerThan480 = useMediaQuery("(min-width:480px)");
  const { access_token } = useAppSelector((state) => state.auth);
  const { role } = useAppSelector((state) => state.currentUser);
  const { cart } = useAppSelector((store) => store);
  const { formType } = useQuery(queryParsers);
  const [phone, setPhone] = useState<string>("");
  const [email, setEmail] = useState<string>("");

  const [agreementOpen, setAgreementOpen] = useState(false);
  const [open, setOpen] = useState<boolean>(false);

  const handleLogin = () => {
    history.push({
      pathname: history.location.pathname,
      search: `?formType=${FormType.LoginFirstPhone}`,
    });
  };

  const handleReturnFromTypes = () => {
    // const accessToken = tokenService.getAccessToken();
    // const isRestrationed = window.localStorage.getItem("isRegistration");

    history.push({
      pathname: history.location.pathname,
      search: `?formType=${
        // isRestrationed ? FormType.LoginFirstEmail :
        FormType.LoginFirstPhone
        }`,
    });
  };

  const handleAgreementModal = () => {
    setAgreementOpen(!agreementOpen);
  };

  // useEffect(() => {
  //   handleLogin();
  // }, [formType]);

  const detectDevice = (): string => {
    const userAgentRes = navigator.userAgent;
    let result = "desk";
    // if (/IEMobile|Windows Phone|Lumia/i.test(userAgentRes)) {
    //   result = "Windows Phone";
    // } else if (/iPhone|iP[oa]d/.test(userAgentRes)) {
    //   result = "iOS";
    // } else if (/Android/.test(userAgentRes)) {
    //   result = "Android";
    // } else if (/BlackBerry|PlayBook|BB10/.test(userAgentRes)) {
    //   result = "BlackBerry";
    // } else if (/Mobile Safari/.test(userAgentRes)) {
    //   result = "Mobile Safari";
    // } else if (
    //   /webOS|Mobile|Tablet|Opera Mini|\bCrMo\/|Opera Mobi/i.test(userAgentRes)
    // ) {
    //   result = "mob";
    // }
    return result;
  };

  const handleChoiceRoutes = (role: string) => {
    if (detectDevice() === "desk") {
      // console.log("handleChoiceRoutes role", role);
      switch (role) {
        case ROLES.CLIENT:
          if (cart && cart.applications.length > 0) {
            history.push(`${ROUTES.CLIENT}/form-new-order`);
            return;
          }
          history.push(`${ROUTES.CLIENT}/products`);
          break;
        case ROLES.AGENT:
          if (cart && cart.applications.length > 0) {
            history.push(`${ROUTES.AGENT}/form-new-order`);
            return;
          }
          history.push(`${ROUTES.AGENT}/products`);
          break;
        case ROLES.PARTNER:
          history.push(`${ROUTES.PARTNER}/profile`);
          break;
        case ROLES.MANAGER:
          history.push(`${ROUTES.MANAGER}/profile`);
          break;
        case ROLES.ADMIN:
          history.push(`${ROUTES.ADMIN}/profile`);
          break;
        default: {
          // history.push({
          //   pathname: history.location.pathname,
          //   // search: `?formType=${FormType.LoginInn}`,
          //   search: `?formType=${FormType.LoginClientOrAgent}`,
          // });
        }
      }
    }
    else {
      history.push({
        pathname: history.location.pathname,
        search: `?formType=${FormType.LoginBlockMob}`,
      });
    }
  };

  const refresh = async () => {
    const token = tokenService.getRefreshToken();
    if (!token) return;
    try {
      await authService.refreshLogin();
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const isValidToken = getToken();
    console.log("isValidToken", isValidToken);
    if (isValidToken) {
      if (role) {
        handleChoiceRoutes(role);
      }
      // else {
      //   refresh();
      // }
    }
  }, [role]);

  return (
    <ThemeProvider theme={muiTheme}>
      <div className={classes.layout}>
        <div className={classes.inner}>
          {isLagerThan480 ? (
            <div className={classes.header}>
              <a
                href={
                  window.location.href.includes(DevStandApp) ||
                    window.location.href.includes("localhost")
                    ? DevStand
                    : ProdStand
                }
              >
                <LOGO />
              </a>
              <a
                href={
                  window.location.href.includes(DevStandApp) ||
                    window.location.href.includes("localhost")
                    ? DevStand
                    : ProdStand
                }
              >
                <IconButtonMain
                  icon={<CloseIcon />}
                  color="blue"
                  handleClick={() => console.log("close")}
                />
              </a>
            </div>
          ) : (
            <div className={classes.header}>
              <div>
                {(formType === FormType.LoginType ||
                  formType === FormType.LoginCertificateCheck) && (
                    <div onClick={handleReturnFromTypes}>
                      <ArrowLeftIcon />
                    </div>
                  )}
              </div>
              <a
                href={
                  window.location.href.includes(DevStandApp) ||
                    window.location.href.includes("localhost")
                    ? DevStand
                    : ProdStand
                }
              >
                <CloseIcon />
              </a>
            </div>
          )}

          <div className={classes.login_layout}>
            <div className={classes.login_box}>
              {formType === undefined ||
                formType === FormType.LoginFirstPhone ||
                formType === FormType.LoginSecondPhone ||
                formType === FormType.LoginFirstEmail ||
                formType === FormType.LoginSecondEmail ||
                formType === FormType.LoginCertificate ||
                formType === FormType.LoginCertificateCheck ||
                formType === FormType.LoginBlockMob ||
                formType === FormType.LoginInn ||
                formType === FormType.LoginType ||
                formType === FormType.LoginCheckEmail ||
                formType === FormType.LoginClientOrAgent ? (
                <>
                  <div className={classes.main_column}>
                    <>
                      {(formType === FormType.LoginType ||
                        formType === FormType.LoginCertificateCheck) && (
                          <div
                            className="blue_link"
                            onClick={handleReturnFromTypes}
                          >
                            Вернуться назад
                          </div>
                        )}
                      <div className={classes.title}>
                        {formType === FormType.LoginBlockMob ? (
                          "Пожалуйста, войдите с компьютера"
                        ) : formType === FormType.LoginType ? (
                          "Выбрать способ входа"
                        ) : formType === FormType.LoginClientOrAgent ? (
                          "Регистрация"
                        ) : formType === FormType.LoginSecondPhone ? (
                          "Подтвердите номер"
                        ) : formType === FormType.LoginSecondEmail ? (
                          "Подтвердите e-mail"
                        ) : formType === FormType.LoginInn ? (
                          "Заполните данные"
                        ) : formType === FormType.LoginCheckEmail ? (
                          "Проверьте e-mail"
                        ) : formType === FormType.LoginFirstPhone ||
                          formType === undefined ? (
                          "Вход или регистрация"
                        ) : (
                          <>
                            Вход в личный <br /> кабинет
                          </>
                        )}
                      </div>
                    </>
                    {formType === FormType.LoginType && <LoginTypesForm />}

                    {(formType === undefined ||
                      formType === FormType.LoginFirstPhone) && (
                        <PhoneForm setPhone={setPhone} />
                      )}
                    {formType === FormType.LoginSecondPhone && (
                      <PhoneFormWithCode phone={phone} />
                    )}

                    {formType === FormType.LoginFirstEmail && (
                      <EmailForm setEmail={setEmail} />
                    )}
                    {formType === FormType.LoginCheckEmail && (
                      <EmailForm
                        setEmail={setEmail}
                        email={email}
                        isCheck={true}
                      />
                    )}
                    {formType === FormType.LoginSecondEmail && (
                      <EmailFormWithCode email={email} />
                    )}

                    {formType === FormType.LoginCertificate && <Certificate />}
                    {formType === FormType.LoginCertificateCheck && (
                      <CheckComponents />
                    )}

                    {formType === FormType.LoginClientOrAgent && (
                      <LoginClientOrAgentForm phone={phone} />
                    )}

                    {formType === FormType.LoginInn && <AddInnForm />}

                    {formType === FormType.LoginBlockMob && (
                      <BlockMobTemp setOpen={setOpen} />
                    )}

                    {(formType === undefined ||
                      formType === FormType.LoginFirstEmail ||
                      formType === FormType.LoginFirstPhone ||
                      formType === FormType.LoginCheckEmail ||
                      formType === FormType.LoginCertificate) && (
                        <>
                          <div
                            className={classes.flex_4}
                            onClick={() => {
                              history.push({
                                pathname: history.location.pathname,
                                search: `formType=${FormType.LoginType}`,
                              });
                            }}
                          >
                            <div className="blue_link">Войти другим способом</div>
                            <ArrowRightIcon color={COLORS.brand_blue} />
                          </div>
                          <p className={classes.black_text}>
                            Не шлем СПАМ, не передаем данные третьим лицам. Обрабатываем и храним информацию в соответствии с 152-ФЗ от 27.07.2006 г.
                          </p>
                          <div className={classes.confirm_desc}>
                            Нажимая "Продолжить", я даю согласие на обработку{" "}
                            <b
                              className={classes.blue_text}
                              onClick={handleAgreementModal}
                            >
                              персональных данных{" "}
                            </b>
                            {(formType === undefined ||
                              formType === FormType.LoginFirstPhone) &&
                              "и подтверждаю, что являюсь владельцем указанного номера телефона"}
                            {(formType === FormType.LoginFirstEmail ||
                              formType === FormType.LoginCheckEmail) &&
                              "и подтверждаю, что являюсь владельцем указанного емэйла"}
                          </div>
                        </>
                      )}
                  </div>
                  <AgreementModal
                    isOpen={agreementOpen}
                    clientType="client"
                    handleClose={handleAgreementModal}
                  />
                </>
              ) : (
                <>
                  {/* <button onClick={() => alert(document.cookie)}>cookie</button>
                  <button onClick={handleLogin}> В кабинет</button> */}
                </>
              )}
            </div>
            <p className={classes.about}>
              <b
                className={classes.blue_text_1}
              >
                finlab.ru
              </b> — маркетплейс финансовых продуктов для бизнеса.
              ООО “ФИНЛАБ” | ИНН 9715401960 | ОГРН 1217700262592 |
              <a
                href={termsOfUseLink}
                target="blank"
                className={classes.termsOfUse}
              >{` Пользовательское соглашение `}</a>
              | 8 (800) 500-35-93
            </p>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default LoginNew;
