import { Theme, Typography, createStyles, makeStyles } from "@material-ui/core";
import { v4 } from "uuid";
import CustomAccordion from "../../../../UIcomponentsRedesign/CustomAccordion/CustomAccordion";
import { COLORS } from "../../../../core/consts/redesign/commonRedesign";
import { fieldsProposalItemsType, groupFieldsProposalItemsType } from "../../../../core/types/leadsApiTypes";

const useStyles = makeStyles((theme: Theme) => createStyles({
  infoContainer: {
    display: "flex",
    flexDirection: "column",
    gap: '24px',
    width: "100%",
  },
  infoWrap: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '24px',
    [theme.breakpoints.down(1024)]: {
      flexDirection: 'column',
      gap: '4px',
      alignItems: 'flex-start',
    },
  },
  infoTitle: {
    width: '37%',
    color: COLORS.gray2,
    [theme.breakpoints.down(1024)]: {
      width: '100%',
    },
  },
  infoText: {
    width: '50%',
    [theme.breakpoints.down(1024)]: {
      width: '100%',
    },
  },
}));

type InfoBlockProps = {
  commonFields: fieldsProposalItemsType[];
  groupFields?: groupFieldsProposalItemsType[]
}
const InfoBlock: React.FC<InfoBlockProps> = ({
  commonFields,
  groupFields

}) => {
  const classes = useStyles();
  function formatFieldValue(field: fieldsProposalItemsType) {
    if (field.isSum) {
      return new Intl.NumberFormat("ru", {
        style: "currency",
        currency: "RUB",
        minimumFractionDigits: 2
      }).format(parseInt(field.value));
    } else if (field.dataType === "date") {
      const [year, month, day] = field.value.split("-");
      return `${day}.${month}.${year}`;
    } else if (field.isMultiple) {
      return field.value.replace(/[[\]"]+/g, "").split(",").join(", ");
    }
    return field.value;
  }
  return (
    <>
      {commonFields &&
        <CustomAccordion
          id='common_fields'
          open
          style={{ padding: '24px 16px' }}
          titleChildren={
            <Typography variant="body1" style={{ fontWeight: 600 }}>Общая информация</Typography>
          }
          contentChildren={
            <div className={classes.infoContainer}>
              {commonFields.map((field: fieldsProposalItemsType) => {
                if (field.dataType !== "group" && field.dataType !== "bool" && field.value) {
                  let formattedValue = formatFieldValue(field);
                  return (
                    formattedValue && (
                      <div className={classes.infoWrap} key={v4()}>
                        <Typography
                          key={v4()}
                          variant="body1"
                          component="p"
                          className={classes.infoTitle}
                        >
                          {field.textName}
                        </Typography>
                        <Typography
                          className={classes.infoText}
                          key={v4()}
                          variant="body1"
                          component="span"
                        >
                          {formattedValue}
                        </Typography>
                      </div>
                    )
                  );
                } else {
                  return null;
                }
              })}
            </div>
          }
        />
      }
      {groupFields && groupFields.map((el: any, index: number) => {
        return (
          <CustomAccordion
            id={`group-${index}`}
            key={v4()}
            titleChildren={
              <Typography variant="body1" style={{ fontWeight: 600 }}>{el.nameGroup}</Typography>
            }
            contentChildren={
              <div className={classes.infoContainer}>
                {el.childs.flat().map((field: fieldsProposalItemsType, fieldIndex: number) => {
                  if (field.dataType !== "group" && field.dataType !== "bool" && field.value) {
                    let formattedValue = formatFieldValue(field);
                    return (
                      formattedValue && (
                        <div className={classes.infoWrap} key={field.name}>
                          <Typography variant="body1" component="p" className={classes.infoTitle}>
                            {field.textName}
                          </Typography>
                          <Typography className={classes.infoText} variant="body1" component="span">
                            {formattedValue}
                          </Typography>
                        </div>
                      )
                    );
                  } else {
                    return null;
                  }
                })}
              </div>
            }
          />
        )
      })}
    </>
  );
};
export default (InfoBlock)