import { isArray, isObject } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SelectCustom from "../../../../../UIcomponentsRedesign/SelectCustom/SelectCustom";
import SelectMultiCustom from "../../../../../UIcomponentsRedesign/SelectCustom/SelectMultiCustom";
import leadsApi from "../../../../../core/api/leadsApi";
import referenceService from "../../../../../core/services/ReferenceService";
import { useAppThunkDispatch } from "../../../../../core/store";
import * as selectors from "../../../../../core/store/selectors";
import { handleAutoFillFields } from "../../../../../core/store/thunk/newOrder";
import { rootState } from "../../../../../core/types/rootState";
import withFormHocs from "../../../../new-order/hoc/withFormHocs";
import { FieldWithKey, PropsField } from "../../../../new-order/types/bigForm";

type Props = PropsField & {
  value?: FieldWithKey | FieldWithKey[];
  info?: string;
};

type SelectValue = {
  key: string;
  text: string;
};

const SelectField: React.FC<Props> = ({
  field,
  propsalId,
  value,
  info,
  text,
  error,
  onChange,
  parentName,
  indexGroupField,
  className,
  classNameShowIf,
  isVisible,
  errorClassName,
  handleSaveDraftOnBlur,
}) => {
  const region = useSelector((state: rootState) =>
    selectors.fieldValue(state, {
      fieldName: "RegionCode",
      propsalId: propsalId,
      parentName: parentName ? parentName : undefined,
      indexGroupField:
        indexGroupField !== undefined ? indexGroupField : undefined,
    })
  );
  const deliveryRegion = useSelector((state: rootState) =>
    selectors.fieldValue(state, {
      fieldName: "DeliveryRegion",
      propsalId: propsalId,
      parentName: parentName ? parentName : undefined,
      indexGroupField:
        indexGroupField !== undefined ? indexGroupField : undefined,
    })
  );
  const deliveryCity = useSelector((state: rootState) =>
    selectors.fieldValue(state, {
      fieldName: "DeliveryCity",
      propsalId: propsalId,
      parentName: parentName ? parentName : undefined,
      indexGroupField:
        indexGroupField !== undefined ? indexGroupField : undefined,
    })
  );
  const [selectData, setSelectData] = useState<FieldWithKey[]>([]);
  const [selectValue, setSelectValue] = useState<string[] | string>([]);
  const dispatch = useAppThunkDispatch();
  const hasShowIf = field.show_if.length > 0;

  useEffect(() => {
    if (field.isMultiple && Array.isArray(value)) {
      const newValue = value.map((obj) => obj.key);
      setSelectValue(newValue);
    } else if (typeof value === "string") {
      setSelectValue(value);
    } else if (isObject(value) && !Array.isArray(value) && value !== null) {
      const newValue = value.key;
      setSelectValue(newValue);
    }
  }, [value, field.isMultiple]);

  useEffect(() => {
    if (field.name === "DeliveryStreet" && deliveryCity) {
      const valueForRequest = deliveryCity as FieldWithKey;
      valueForRequest.key.length > 0 &&
        referenceService.getDeliveryStreet(valueForRequest.key).then((res) => {
          setSelectData(res as FieldWithKey[]);
        });
    }
  }, [deliveryCity, field.name]);

  useEffect(() => {
    if (field.name === "DeliveryCity") {
      if (deliveryRegion) {
        const valueForRequest = deliveryRegion as FieldWithKey;
        valueForRequest.key?.length > 0 &&
          referenceService.getDeliveryCity(valueForRequest.key).then((res) => {
            setSelectData(res as FieldWithKey[]);
          });
      }
    }
  }, [deliveryRegion, field.name]);

  useEffect(() => {
    if (region) {
      const valueForRequest = region as FieldWithKey;
      switch (field.name) {
        case "CityCode":
          valueForRequest.key?.length > 0 &&
            referenceService
              .getCitiesListService(valueForRequest.key)
              .then((res) => {
                setSelectData(res as FieldWithKey[]);
              });
          break;
        case "CityNameVTB":
          valueForRequest.key?.length > 0 &&
            referenceService
              .getCitiesListService(valueForRequest.key)
              .then((res) => {
                setSelectData(res as FieldWithKey[]);
              });
          break;
      }
    }
  }, [field.name, region]);


  const fetchOptions = useCallback(async () => {
    if (
      field.name !== "CityCode" &&
      field.name !== "CityNameVTB" &&
      field.name !== "DeliveryCity" &&
      field.name !== "DeliveryStreet"
    ) {
      try {
        const res = await leadsApi.sendRequestFromField<SelectValue[]>(
          `${field.url}`
        );
        setSelectData(res as FieldWithKey[]);
      } catch (error) {
        console.error("Error fetching data:", error);
        setSelectData([]);
      }
    } else {
      setSelectData([])
    }
  }, []);

  useEffect(() => {
    fetchOptions();
  }, []);

  const handleChangeSelect = (value: string[] | string) => {
    // console.log("handleChangeSelect value", value);
    if (onChange) {
      if (field.isMultiple && Array.isArray(value)) {
        //Если поле multiple true, то value массив
        const newValue = value;
        const currentValue = newValue.map((item) =>
          selectData.find((i) => i.key === item)
        );
        // console.log("handleChangeSelect currentValue mult", currentValue);
        if (currentValue) {
          onChange(
            field.name,
            currentValue as FieldWithKey[],
            propsalId,
            parentName && parentName,
            indexGroupField && indexGroupField
          );
        }
      } else if (typeof value === "string") {
        const currentValue = selectData.find((i) => i.key === value);
        // console.log("handleChangeSelect selectData one", selectData);
        // console.log("handleChangeSelect currentValue one", currentValue);
        if (currentValue) {
          onChange(
            field.name,
            currentValue,
            propsalId,
            parentName && parentName,
            indexGroupField && indexGroupField
          );
        }
      }
    }
  };

  const handleOnBlur = () => {
    if (field.autoFillUrl && value !== undefined) {
      dispatch(
        handleAutoFillFields(
          field.autoFillUrl,
          value,
          propsalId,
          field.name,
          parentName,
          indexGroupField
        )
      );
    }

    //delay to fill fields before validation
    if (handleSaveDraftOnBlur) {
      setTimeout(() => {
        handleSaveDraftOnBlur();
      }, 500)
    }
  };

  return (
    <>
      {field.upSeparator && (hasShowIf ? isVisible : true) && <div className="line_x" />}
      <div style={{ width: "100%" }} className={classNameShowIf} onBlur={handleOnBlur}>
        {field.isMultiple ? (
          <SelectMultiCustom
            label={text}
            value={
              isArray(selectValue)
                ? selectValue
                : selectValue?.length > 0
                  ? [selectValue]
                  : []
            }
            handleChange={handleChangeSelect}
            options={selectData.map((elem) => elem.text)}
            // isFullWidth={true}
            isRequired={field.isRequeired}
            success={selectValue.length > 0}
            error={error}
          />
        ) : (
          <SelectCustom
            label={text}
            info={info}
            value={isArray(selectValue) ? selectValue[0] : selectValue}
            handleChange={handleChangeSelect}
            disabled={field.isClientBlock}
            isRequired={field.isRequeired}
            optionsObjects={selectData}
            // isFullWidth={true}
            success={selectValue.length > 0}
            error={error}
          />
        )}
      </div>
      {field.downSeparator && (hasShowIf ? isVisible : true) && <div className="line_x" />}
    </>
  );
};

export default withFormHocs(React.memo(SelectField));
