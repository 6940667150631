import Checkbox from '@mui/material/Checkbox';
import clsx from "clsx";
import { ChangeEvent, useEffect, useState } from "react";
import ButtonCustom from "../../../UIcomponentsRedesign/ButtonCustom/ButtonCustom";
import DateInputCustom from "../../../UIcomponentsRedesign/DateInputCustom";
import SelectCustom from "../../../UIcomponentsRedesign/SelectCustom/SelectCustom";
import SpinnerMain from '../../../UIcomponentsRedesign/SpinnerMain';
import SwitcherCustom from "../../../UIcomponentsRedesign/SwitcherCustom";
import { TextAreaCustom } from "../../../UIcomponentsRedesign/TextAreaCustom";
import { TextFieldWithBorder } from "../../../UIcomponentsRedesign/TextFieldWithBorder";
import { DaysOfWeekEnum, FrequencyUnitEnum, NoteType, NotificationTimeEnum, NotificationTypeEnum } from "../../../core/api/noteApi";
import { COLORS } from "../../../core/consts/redesign/commonRedesign";
import { CheckBox, CheckedBox } from "../../../core/consts/redesign/icons";
import noteService from "../../../core/services/NoteService";
import { useStyles } from "./useNoteStyles";

const DaysOfWeekRu: { [index: string]: string } = {
  [DaysOfWeekEnum.Mo]: "Пн",
  [DaysOfWeekEnum.Tu]: "Вт",
  [DaysOfWeekEnum.We]: "Ср",
  [DaysOfWeekEnum.Th]: "Чт",
  [DaysOfWeekEnum.Fr]: "Пт",
  [DaysOfWeekEnum.Sa]: "Сб",
  [DaysOfWeekEnum.Su]: "Вс",
};

const TypeOfNotificationRu: { [index: string]: string } = {
  [NotificationTypeEnum.emailPortal]: "Уведомлять на портале и на E-mail",
  [NotificationTypeEnum.email]: "Уведомлять на E-mail",
  [NotificationTypeEnum.portal]: "Уведомлять на портале",
};

export type FieldWithKey = {
  key: string;
  text: string;
};

const optionsReminderTypes: FieldWithKey[] = Object.keys(TypeOfNotificationRu).map((key) => ({
  key: key,
  text: TypeOfNotificationRu[key],
}));


const optionsTimeTypes: FieldWithKey[] = [
  { key: "all_day", text: "Весь день" },
  { key: "on_time", text: "Ко времени" },
];

const repetitions: FieldWithKey[] = [
  { text: "Не повторять", key: FrequencyUnitEnum.no },
  { text: "Повторять по неделям", key: FrequencyUnitEnum.week },
  { text: "Повторять по дням", key: FrequencyUnitEnum.day },
];
type Props = {
  note?: NoteType | null;
  isEdit: boolean;
  setIsEdit?: React.Dispatch<React.SetStateAction<boolean>>;
  setChosenNote?: React.Dispatch<React.SetStateAction<NoteType | null>>;
  leadId?: number;
  getNotes?: () => void;
  isOpen?: boolean;
  handleCloseModal?: () => void;
  handleConfirmModal?: () => void;
};

const NoteCreateBox: React.FC<Props> = ({
  note,
  isEdit,
  setIsEdit,
  setChosenNote,
  leadId,
  getNotes,
  isOpen,
  handleCloseModal,
  handleConfirmModal
}) => {
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false);
  const [showInputs, setShowInputs] = useState<boolean>(false)
  const [reminders, setReminders] = useState<boolean>(false)
  const [text, setText] = useState("");
  const [date, setDate] = useState<Date | null>(new Date()); //today as default
  const [time, setTime] = useState<NotificationTimeEnum>("all_day")
  const [hours, setHours] = useState<string | number>("09");
  const [minutes, setMinutes] = useState<string | number>("00");
  const [frequencyUnit, setFrequencyUnit] = useState<FrequencyUnitEnum>(FrequencyUnitEnum.no);
  const [notificationType, setNotificationType] = useState<NotificationTypeEnum>(
    NotificationTypeEnum.emailPortal
  );
  const [frequencyCount, setFrequencyCount] = useState<string | number>("1");
  const initialDays: { [index: string]: boolean } = {
    [DaysOfWeekEnum.Mo]: false,
    [DaysOfWeekEnum.Tu]: false,
    [DaysOfWeekEnum.We]: false,
    [DaysOfWeekEnum.Th]: false,
    [DaysOfWeekEnum.Fr]: false,
    [DaysOfWeekEnum.Sa]: false,
    [DaysOfWeekEnum.Su]: false,
  };
  const [repeatDays, setRepeatDays] = useState<{ [index: string]: boolean }>(
    initialDays
  );

  useEffect(() => {
    if (isEdit && note) {
      setShowInputs(true);
      updateFormFromNote(note);
    } else {
      setShowInputs(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

  useEffect(() => {
    if (isOpen) {
      setShowInputs(true);
    }
  }, [isOpen]);

  const onChangeDateInput = (date: Date | null) => {
    if (date) {
      setDate(date);
    }
  };

  const handleSwitchChange = (checked: boolean) => {
    setReminders(checked);
  };

  const handleHoursChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (
      value === "" ||
      (/^\d+$/.test(value) && +value >= 0 && +value <= 24)
    ) {
      setHours(value);
    }
  };

  const handleMinutesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (
      value === "" ||
      (/^\d+$/.test(value) && +value >= 0 && +value <= 60)
    ) {
      setMinutes(value);
    }
  };
  const handleWeekCountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (
      value === "" ||
      (/^\d+$/.test(value) && +value >= 0 && +value <= 99)
    ) {
      setFrequencyCount(value);
    }
  };

  const getDaysForCheckBox = (days: DaysOfWeekEnum[]) => {
    const daysForm = { ...initialDays };
    days.forEach(elem => daysForm[elem] = true);
    return daysForm;
  }

  const updateFormFromNote = (note: NoteType) => {
    cleanForm();
    setReminders(note.isNotificate);
    setText(note.text);
    if (note.isNotificate) {
      if (note?.notificationStartDate) {
        setDate(new Date(note?.notificationStartDate))
      } else {
        setDate(null)
      };
      setFrequencyUnit(note?.frequencyUnit ?? FrequencyUnitEnum.no);
      if ((note.hours !== undefined && note.hours !== null && note.hours.toString() === '09') &&
        (note.minutes !== undefined && note.minutes !== null && note.minutes.toString() === '00')) {
        setTime("all_day");
        setHours("09");
        setMinutes("00");
      } else {
        setTime("on_time");
        setHours(note?.hours!);
        setMinutes(note?.minutes!);
      }
      setFrequencyCount(note?.frequencyCount ?? "1");
      if (note?.days) {
        setRepeatDays(getDaysForCheckBox(note.days) ?? initialDays);
      } else {
        setRepeatDays(initialDays);
      }
      setNotificationType(note?.notificateType ?? NotificationTypeEnum.emailPortal);
    }
  }

  const handlerChangeText = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (e.target.value.length <= 200) {
      setText(e.target.value);
    }
  };

  const cleanForm = () => {
    setReminders(false);
    setText("");
    setDate(new Date());
    setTime("all_day");
    setHours("09");
    setMinutes("00");
    setFrequencyUnit(FrequencyUnitEnum.no);
    setNotificationType(NotificationTypeEnum.emailPortal);
    setFrequencyCount("1");
    setRepeatDays(initialDays);
  };

  const cancel = (event?: React.MouseEvent | React.TouchEvent) => {
    handleCloseModal && handleCloseModal();
    if (event) {
      event.stopPropagation();
    }
    cleanForm();
    setIsEdit && setIsEdit(false);
    setChosenNote && setChosenNote(null);
    setShowInputs(false)
  };

  const getIsDisabled = () => {
    if (isLoading) {
      return true;
    } else if (text.length === 0) {
      return true;
    } else if (text && reminders === false) {
      return false;
    } else if (frequencyUnit === FrequencyUnitEnum.no && date && hours.toString().length > 0 && minutes.toString().length > 0) {
      return false;
    } else if (frequencyUnit === FrequencyUnitEnum.week && date && hours.toString().length > 0 && minutes.toString().length > 0 && frequencyCount &&
      Object.entries(repeatDays).find(elem => elem[1] === true)) {
      return false;
    } else if (frequencyUnit === FrequencyUnitEnum.day && date && hours.toString().length > 0 && minutes.toString().length > 0 && frequencyCount) {
      return false;
    } else {
      return true;
    }
  }

  const createOrUpdateNote = async () => {
    setIsLoading(true);
    const newNote: NoteType = {
      text: text,
      isNotificate: reminders,
    };

    if (reminders && date) {
      newNote.notificationStartDate = date.toISOString();
    }
    if (reminders) {
      newNote.hours = parseInt(hours.toString());
      newNote.minutes = parseInt(minutes.toString());
      newNote.frequencyUnit = frequencyUnit;
    }
    if (reminders && frequencyUnit !== FrequencyUnitEnum.no && frequencyCount) {
      newNote.frequencyCount = frequencyCount;
    } else {
      newNote.frequencyCount = 1;
    }
    if (reminders && frequencyUnit === FrequencyUnitEnum.week) {
      const arrDays: DaysOfWeekEnum[] = [];
      Object.entries(repeatDays).forEach(el => {
        if (el[1] === true) {
          arrDays.push(el[0] as DaysOfWeekEnum)
        }
      })
      newNote.days = arrDays;
    }
    if (reminders) {
      newNote.notificateType = notificationType;
    }
    if (!note && leadId) {
      newNote.leadId = leadId;

      const res = await noteService.createNote(newNote);
      if (res && getNotes) {
        getNotes();
        cancel();
      }
      handleConfirmModal && handleConfirmModal();
    } else if (note) {
      newNote.id = note.id;
      newNote.leadId = note?.leadId;
      const res = await noteService.changeNote(newNote);
      if (res && getNotes) {
        getNotes();
        cancel();
      }
      handleConfirmModal && handleConfirmModal(); //future feature edit note in modal
    }
    setShowInputs(false)
    setIsLoading(false);
  }

  return (
    <>
      <div className={classes.createBox} onClick={() => setShowInputs(true)}>
        <TextAreaCustom
          label="Добавить заметку"
          id="note-text"
          name="note-text"
          onChange={(e: any) => handlerChangeText(e)}
          value={text}
          showCharacterCount
        />
        {showInputs ?
          <>
            <SwitcherCustom
              text={"Напомнить"}
              onChangeValue={handleSwitchChange}
              value={reminders} />

            {reminders ?
              <>
                <div className={classes.dateRow}>
                  <div className={classes.dateRowSmall}>
                    <DateInputCustom
                      label={"Дата"}
                      value={date}
                      onChange={onChangeDateInput}
                      disablePast
                      arrowIcon
                    />
                    <SelectCustom
                      label={"Время"}
                      isFullWidth
                      noPopover
                      optionsObjects={optionsTimeTypes}
                      value={time}
                      handleChange={(value: string) =>
                        setTime(value as NotificationTimeEnum)
                      }
                    />
                  </div>
                  {time === "on_time" ?
                    <div className={classes.timeRow}>
                      <TextFieldWithBorder
                        inputMode="numeric"
                        id="reminder-hour"
                        name="reminder-hour"
                        value={hours}
                        label={"Часы"}
                        onChange={handleHoursChange}
                      />
                      <span style={{ color: COLORS.black }}>:</span>
                      <TextFieldWithBorder
                        inputMode="numeric"
                        id="reminder-minute"
                        name="reminder-minute"
                        value={minutes}
                        label={"Минуты"}
                        onChange={handleMinutesChange}
                      />
                    </div>
                    : null}
                </div>
                <div className={classes.dateRow}>
                  <SelectCustom
                    label={"Повтор"}
                    isFullWidth
                    noPopover
                    optionsObjects={repetitions}
                    value={frequencyUnit}
                    handleChange={(value: string) =>
                      setFrequencyUnit(value as FrequencyUnitEnum)
                    }
                  />
                  {frequencyUnit === FrequencyUnitEnum.week && (
                    <TextFieldWithBorder
                      inputMode="numeric"
                      id="frequency-week"
                      name="frequency-week"
                      value={frequencyCount}
                      label={"Повторять каждую"}
                      unitText={"неделю"}
                      onChange={handleWeekCountChange}
                    />
                  )}

                  {frequencyUnit === FrequencyUnitEnum.day && (
                    <TextFieldWithBorder
                      inputMode="numeric"
                      id="frequency-day"
                      name="frequency-day"
                      value={frequencyCount}
                      label={"Повторять каждый"}
                      unitText={"день"}
                      onChange={handleWeekCountChange}
                    />)
                  }
                </div>
                {frequencyUnit === FrequencyUnitEnum.week && (
                  <div className={classes.daysBox}>
                    {Object.keys(DaysOfWeekEnum).map((elem) => (
                      <div className={classes.dayCheckboxes} key={elem}>
                        <Checkbox
                          color="primary"
                          className={classes.dayCheckbox}
                          checked={repeatDays[elem]}
                          sx={{
                            padding: '0',
                            '&:hover': { bgcolor: 'transparent' },
                          }}
                          checkedIcon={<CheckedBox />}
                          icon={<CheckBox />}
                          onChange={(e) => {
                            const temp = { ...repeatDays };
                            temp[elem] = e.target.checked;
                            setRepeatDays(temp);
                          }}
                        />
                        <div
                          className={clsx(classes.dayCheckboxText, {
                            [classes.weekend]: elem === DaysOfWeekEnum.Sa || elem === DaysOfWeekEnum.Su,
                          })}
                        >
                          {DaysOfWeekRu[elem]}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                <SelectCustom
                  label='Уведомление'
                  isFullWidth
                  noPopover
                  optionsObjects={optionsReminderTypes}
                  value={notificationType}
                  handleChange={(value: string) =>
                    setNotificationType(value as NotificationTypeEnum)
                  }
                />
              </> : null}
            <div className={classes.buttonsCreate}>
              <ButtonCustom
                color="ghost"
                type="submit"
                handleClickEvent={(event: React.MouseEvent | React.TouchEvent) => cancel(event)}
                fullWidth
              >
                Отмена
              </ButtonCustom>
              <ButtonCustom
                color="primary"
                disabled={getIsDisabled()}
                handleClick={createOrUpdateNote}
                fullWidth
              >
                {note ? "Сохранить" : "Создать"}
              </ButtonCustom>
            </div>
          </>
          : null}
        {isLoading &&
          <SpinnerMain fixedPosition />
        }
      </div>
    </>
  );
};

export default NoteCreateBox;
