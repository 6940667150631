import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styles from "../LoginNew.module.scss";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { useSelector } from "react-redux";
import TooltipModalClientOrAgent from "./TooltipModalClientOrAgent";
import authApi, {
  ConfirmRoleRDType,
  KeyTextType,
} from "../../../core/api/authApi";
import { rootState } from "../../../core/types/rootState";
import { ClientType } from "../../../app/components/registration/components/registration-agent/RegistrationAgent";
import { CookiesEnum, ROLES, ROUTES } from "../../../core/consts/common";
import { FormType } from "../../../app/enum";
import authService from "../../../core/services/AuthService";
import { ArrowRightIcon } from "../../../core/consts/redesign/icons/ArrowRight";
import { COLORS } from "../../../core/consts/redesign/commonRedesign";
import tokenService from "../../../core/services/TokenService";
type Props = {
  phone: string;
};

const detectDevice = (): string => {
  const userAgentRes = navigator.userAgent;
  let result = "desk";
  // if (/IEMobile|Windows Phone|Lumia/i.test(userAgentRes)) {
  //   result = "Windows Phone";
  // } else if (/iPhone|iP[oa]d/.test(userAgentRes)) {
  //   result = "iOS";
  // } else if (/Android/.test(userAgentRes)) {
  //   result = "Android";
  // } else if (/BlackBerry|PlayBook|BB10/.test(userAgentRes)) {
  //   result = "BlackBerry";
  // } else if (/Mobile Safari/.test(userAgentRes)) {
  //   result = "Mobile Safari";
  // } else if (
  //   /webOS|Mobile|Tablet|Opera Mini|\bCrMo\/|Opera Mobi/i.test(userAgentRes)
  // ) {
  //   result = "mob";
  // }
  return result;
};

const LoginClientOrAgentForm = ({ phone }: Props) => {
  const history = useHistory();
  const [role, setRole] = useState("client");
  const [info, setInfo] = useState<KeyTextType[]>([]);
  const [message, setMessage] = useState<string>("");
  const { addInfo } = useSelector((state: rootState) => state.auth);
  const { currentUser } = useSelector((state: rootState) => state);
  const [errMessage, setErrMessage] = useState<string>("");
  const [chosenRoleForTooltip, setChosenRoleForTooltip] = useState(
    ClientType.Client
  );
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const getAuthInfo = async () => {
    try {
      const res = await authApi.getAuthInfo();
      if (res) {
        setInfo(res);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getAuthInfo();
    return () => {
      setErrMessage("");
    };
  }, []);

  const handleChoiceRoutes = (role: string) => {
    if (detectDevice() === "desk") {
      switch (role) {
        case ROLES.CLIENT:
          // history.push(`${ROUTES.CLIENT}/products`);
          history.push(`${ROUTES.CLIENT}/form-new-order`);
          break;
        case ROLES.AGENT:
          // history.push(`${ROUTES.AGENT}/products`);
          history.push(`${ROUTES.AGENT}/form-new-order`);
          break;
        case ROLES.PARTNER:
          history.push(`${ROUTES.PARTNER}/profile`);
          break;
        case ROLES.MANAGER:
          history.push(`${ROUTES.MANAGER}/profile`);
          break;
        case ROLES.ADMIN:
          history.push(`${ROUTES.ADMIN}/profile`);
          break;
        default: {
          // history.push({
          //   pathname: history.location.pathname,
          //   // search: `?formType=${FormType.LoginInn}`,
          //   search: `?formType=${FormType.LoginClientOrAgent}`,
          // });
        }
      }
    } else {
      history.push({
        pathname: history.location.pathname,
        search: `?formType=${FormType.LoginBlockMob}`,
      });
    }
  };

  const handleSubmit = async () => {
    setErrMessage("");
    // const clickId = localStorage.getItem("cid");//!!!!!!
    const clickId = tokenService._getCookie(CookiesEnum.cid);
    const cid = clickId ? { cid: clickId } : {};

    // const userId = localStorage.getItem("uid");
    const userId = tokenService._getCookie(CookiesEnum.uid);
    const uid = userId ? { c2MUserId: userId } : {};

    const CIDProduct = tokenService._getCookie(CookiesEnum.CIDProduct);
    try {
      const rd: ConfirmRoleRDType = {
        userId: currentUser?.userId,
        role: role,
        code: addInfo.code,
        phone: phone,
        // ...cid,
        // ...uid,
      };
      // if (CIDProduct) {
      //   rd.CIDProduct = CIDProduct;
      // }
      setMessage("");
      const resRole = await authService.confirmRole(rd);

      handleChoiceRoutes(resRole);
      // history.push({
      //   pathname: history.location.pathname,
      //   search: `?formType=${FormType.LoginInn}`,
      // });
    } catch (err: any) {
      console.log(err);
      console.log("err?.request?.response", err?.request?.response);
      if (err.request.status === 500) {
        setErrMessage("Ошибка на сервере.");
      } else if (err.request.status === 429) {
        setErrMessage("Попробуйте через 10 секунд");
      } else if (err.request.status === 400 && err?.request?.response?.errors) {
        setMessage(err?.request?.response?.errors?.[0]?.message);
      } else {
        setErrMessage("Ошибка.");
      }
    }
  };

  return (
    <div className={styles.main_column}>
      <div>Выберите, в качестве кого вы будете регистрироваться</div>
      <RadioGroup
        onChange={(e) => setRole(e.target.value)}
        value={role}
        className={styles.certif_column}
      >
        <div
          className={
            role === ClientType.Client
              ? styles.role_box_active
              : styles.role_box
          }
          style={{ padding: "20px" }}
        >
          <div className={styles.flex}>
            <FormControlLabel
              value={ClientType.Client}
              control={<Radio color="primary" />}
              defaultChecked
              label={<div className={styles.role_box_title}>Клиент</div>}
            />
          </div>
          <div>{info?.find((el) => el.key === "client")?.text}</div>
          <div
            className="blue_link"
            onClick={() => {
              setChosenRoleForTooltip(ClientType.Client);
              setTooltipOpen(true);
            }}
          >
            Подробнее
            <ArrowRightIcon color={COLORS.brand_blue} />
          </div>
        </div>

        <div
          className={
            role === ClientType.Agent ? styles.role_box_active : styles.role_box
          }
          style={{ padding: "20px" }}
        >
          <div className={styles.flex}>
            <FormControlLabel
              value={ClientType.Agent}
              control={<Radio color="primary" />}
              style={{ marginRight: "0" }}
              label={<div className={styles.role_box_title}>Агент</div>}
            />
          </div>
          <div>{info?.find((el) => el.key === "agent")?.text}</div>

          <div
            className="blue_link"
            onClick={() => {
              setChosenRoleForTooltip(ClientType.Agent);
              setTooltipOpen(true);
            }}
          >
            Подробнее
            <ArrowRightIcon color={COLORS.brand_blue} />
          </div>
        </div>
      </RadioGroup>
      <div style={{ width: "100%" }}>
        <button className="button_primary_w100" onClick={handleSubmit}>
          Зарегистрироваться
        </button>
        {errMessage && <div className="error">{errMessage}</div>}
      </div>
      <TooltipModalClientOrAgent
        isOpen={tooltipOpen}
        handleClose={() => setTooltipOpen(false)}
        clientType={chosenRoleForTooltip}
        info={info}
      />
    </div>
  );
};

export default LoginClientOrAgentForm;
