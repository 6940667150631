import { ChatByType, TypeChat } from "../api/chatsApi";
import { dataForChats } from "../types/chatsTypes";
export { };

/**
 * Transforms chat data from API response format to the format expected by the Redux store or UI components.
 * 
 * @param {ChatByType} res - The chat data from the API response.
 * @param {number} leadId - The lead ID associated with the chat, since it's not included in the response.
 * @returns {TransformedChatData} - The transformed chat data ready for use in the application.
 */

export const transformChatData = (res: ChatByType, leadId: number) => ({
  chatId: res.chatId,
  leadId: leadId,
  nameChat: res.nameChat,
  typeChat: res.typeChat,
  roleInterlocutor: res.roleInterlocutor,
  nameInterlocutor: res.nameInterlocutor,
  avatar_url: res.avatarUrl,
  chatMessages: res.chatMessages,
  lastMessage: res.chatMessages[0]?.message,
  lastTimeMessage: res.chatMessages[0]?.dateMessage,
  countUnreadMessage: res.chatMessages.reduce((count, message) => count + (!message.isRead && !message.isMyMessage ? 1 : 0), 0),
});


/**
 * Generates a title for a chat based on its type and manager status.
 * 
 * @param {Object} chat - The chat object.
 * @param {boolean} isManager - Whether the current user is a manager.
 * @param {string} leadId - Lead ID used for the title.
 * @returns {string} - The generated title for the chat.
 */
export const getChatTitle = (chat: dataForChats, isManager: boolean, leadId: number) => {
  let chatTitle = "";

  if (chat.typeChat === TypeChat.Bank) {
    chatTitle = `Менеджер ${chat.nameChat}`;
  } else if (chat.typeChat === TypeChat.ManagerLead) {
    if (!isManager) {
      chatTitle = `Личный менеджер Финлаб по заявке ${leadId}`;
    } else {
      chatTitle = `Чат по заявке ${leadId}`;
    }
  }

  return chatTitle;
};