export const CloseIcon = ({ color }: { color?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.21967 4.21967C4.51256 3.92678 4.98744 3.92678 5.28033 4.21967L12.25 11.1893L19.2197 4.21967C19.5126 3.92678 19.9874 3.92678 20.2803 4.21967C20.5732 4.51256 20.5732 4.98744 20.2803 5.28033L13.3107 12.25L20.2803 19.2197C20.5732 19.5126 20.5732 19.9874 20.2803 20.2803C19.9874 20.5732 19.5126 20.5732 19.2197 20.2803L12.25 13.3107L5.28033 20.2803C4.98744 20.5732 4.51256 20.5732 4.21967 20.2803C3.92678 19.9874 3.92678 19.5126 4.21967 19.2197L11.1893 12.25L4.21967 5.28033C3.92678 4.98744 3.92678 4.51256 4.21967 4.21967Z"
      fill={color ? color : "#96A8B8"}
    />
  </svg>
);
