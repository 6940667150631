import { messageUploadingType } from "../../api/chatsApi";
import { AddMessageAction, CleanCurrentChatAction, RemoveMessageAction, SetCurrentChatAction, UpdateMessageAction, currentChatType, dataForUserChat } from "../../types/currentChatsTypes";

export const setCurrentChatData = (payload: dataForUserChat): SetCurrentChatAction => ({
  type: currentChatType.SET_USER_CHAT,
  payload,
});

export const cleanCurrentChatData = (): CleanCurrentChatAction => ({
  type: currentChatType.CLEAN_USER_CHAT,
});

export const addMessage = (message: messageUploadingType): AddMessageAction => ({
  type: currentChatType.ADD_MESSAGE,
  payload: message,
});

export const updateMessage = (message: messageUploadingType): UpdateMessageAction => ({
  type: currentChatType.UPDATE_MESSAGE,
  payload: message,
});

export const removeMessage = (id: number): RemoveMessageAction => ({
  type: currentChatType.REMOVE_MESSAGE,
  payload: id,
});