import agentApi from "../api/agentApi";
import { AgentActsData, AgentLeadsData } from "../types/agentTypes";
import { QueryParamsGetLeads } from '../types/leadsApiTypes';
import introspectService from "./IntropsectService";

class AgentService {

    getClientsListService(Q: { Inn?: string, Region?: string, CompanyName?: string }) {
        return agentApi.getClientsList(Q)
            .then((res: any) => {
                return res
            })
            .catch(error => {
                introspectService.checkToken(error.response.status.toString())
                return 'Ошибка'
            })
    }

    checkClientUlService(Q: { Inn: string }) {
        return agentApi.checkClientUl(Q)
            .then((res: any) => {
                return res
            })
            .catch(error => {
                introspectService.checkToken(error.response.status.toString())
                return 'Ошибка'
            })
    }

    registerClientUlService(B: { inn: string }) {
        return agentApi.registerClientUl(B)
            .then((res: any) => {
                return res
            })
            .catch(error => {
                introspectService.checkToken(error.response.status.toString())
            })
    }

    getActsService(): Promise<AgentActsData | 'Ошибка'> {
        return agentApi.getActs()
            .then((res: AgentActsData) => {
                return res
            })
            .catch(error => {
                introspectService.checkToken(error.response.status.toString())
                return 'Ошибка'
            })
    }

    signActsService(actsId: number[]) {
        return agentApi.signActs(actsId)
            .then((res: AgentActsData) => {
                return res
            })
            .catch(error => {
                introspectService.checkToken(error.response.status.toString())
            })
    }

    confirmSignActsService(actsId: number[], code: string) {
        return agentApi.confirmSignActs(actsId, code)
            .then((res) => {
                return res
            })
            .catch(error => {
                introspectService.checkToken(error.response.status.toString())
            })
    }

    cancelActsService(actsId: number[]) {
        return agentApi.cancelActs(actsId)
            .then((res) => {
                return res
            })
            .catch(error => {
                introspectService.checkToken(error.response.status.toString())
            })
    }

    getLeadsService(Q: QueryParamsGetLeads): Promise<AgentLeadsData> {
        return agentApi.getLeads(Q)
    }

}

const agentService = new AgentService()
export default agentService
