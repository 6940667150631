import { CountsActions, CountsState, countsActionTypes } from "../../types/countsTypes";

const initialState: CountsState = [];

export const countsReducer = (state = initialState, action: CountsActions): CountsState => {
  switch (action.type) {
    case countsActionTypes.EDIT_DOCS_COUNT:
      return state.map(item =>
        item.leadId === action.payload.leadId
          ? { ...item, countNeededDocs: action.payload.countNeededDocs }
          : item
      ).concat(state.some(item => item.leadId === action.payload.leadId) ? [] : [action.payload]);

    case countsActionTypes.EDIT_CHATS_COUNT:
      return state.map(item =>
        item.leadId === action.payload.leadId
          ? { ...item, countUnreadMessage: action.payload.countUnreadMessage }
          : item
      ).concat(state.some(item => item.leadId === action.payload.leadId) ? [] : [action.payload]);

    case countsActionTypes.EDIT_NOTIFICATIONS_COUNT:
      return state.map(item =>
        item.leadId === action.payload.leadId
          ? { ...item, countUnreadNotifications: action.payload.countUnreadNotifications }
          : item
      ).concat(state.some(item => item.leadId === action.payload.leadId) ? [] : [action.payload]);

    case countsActionTypes.CLEAN_USER_COUNTS:
      return initialState;

    default:
      return state;
  }
};