import { http } from "../infrastructure/Https";
import tokenService from "../services/TokenService";

const ROOT_URL = '/api/v1/feedback/createTask'

class FeedbackApi {
    postFeedBackApi(B:any, isFileNeeded:boolean, loadedFiles:any){
        const formData = new FormData()
        formData.append('Title', B.title)
        formData.append('Text', B.text)
        formData.append('Inn', B.inn)
        formData.append('LeadId', B.leadId)
        formData.append('Email', B.email)
        formData.append('Phone', B.phone)
        formData.append('HasFile', String(isFileNeeded))
        loadedFiles.forEach((element: any) => {
            formData.append('File', element)

        });
        const accessToken = tokenService.getAccessToken()

        return http.postMultiple(ROOT_URL, null, formData,accessToken)
    }
}

const feedbackApi = new FeedbackApi()
export default feedbackApi