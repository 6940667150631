import { http } from '../infrastructure/Https';
import tokenService from '../services/TokenService';

const ROOT_URL = "/api/v1/chats";

export enum TypeChat {
  None = "None", // 0 - дефолт, не должно быть, сбой и тп,
  Chat = "Chat",// chat =  1 - диалог с кем то,
  Bank = "Bank",// bank = все агенты или другая роль в системе,
  Role = "Role", // role= техподдрежка,
  ManagerChat = "ManagerChat", // managerchat = просто с менеджером,
  ManagerLead = "ManagerLead"// managerlead = с менеджером по заявке
}

export enum MessageStatusEnum {
  SendUnRead = "SendUnRead",
  SendRead = "SendRead",
  SendError = "SendError"
}
export type chatListType = {
  id: number;
  avatar_url: string;
  nameChat: string;
  typeChat: TypeChat;
  leadId: number;
  productId: number;
  bankName: string;
  productName: string;
  isAnswer: boolean;
  lastMessage: string;
  lastMessageYou: boolean;
  countUnreadMessage: number;
  lastTimeMessage: string;
  leadStatus: string;
  roleInterlocutor: string;
  nameInterlocutor: string;
};

export type messageType = {
  id: number;
  dateMessage: string;
  message: string;
  isChange: boolean;
  isRead: boolean;
  isMyMessage: boolean;
  documentId: string;
  authorMessage: string;
  status: MessageStatusEnum,
};

export type messageUploadingType = Partial<messageType> & {
  id: number;
  progress?: number;
  loadedMB?: string;
  totalMB?: string;
};

export type ChatByType = {
  chatMessages: messageType[];
  chatId: number;
  leadId: number;
  nameChat: string;
  roleInterlocutor: string;
  nameInterlocutor: string;
  avatarUrl: string;
  typeChat: TypeChat;
  leadStatus: string;
};

export type MessageNotification = {
  chatId: number;
  leadId: number;
  typeChat: string; //change to enum
  id: number;
  message: string;
  avatarUrl: string;
  authorMessage: string;
  productId: number;
};

export type ChatsInfo = {
  count: number;
  newMessages: MessageNotification[];
};

//all user's chats. exclude - type of chat should be excluded
class ChatsApi {
  //chat with bank (partner) by lead id for client/agent and partner
  getMessagesListByLeadId(leadid: number): Promise<ChatByType> {
    const accessToken = tokenService.getAccessToken();
    return http.get(`${ROOT_URL}bylead/${leadid}`, null, accessToken);
  }

  //chat with your manager for client/agent AND for manager with client. leadID for lead chat, userID for personal chat
  getMessagesListManagerChatByLeadId(
    Q?: { leadid?: number; userid?: string } | null
  ): Promise<ChatByType> {
    const accessToken = tokenService.getAccessToken();
    return http.get(`${ROOT_URL}bymanager`, Q, accessToken);
  }

  //send message to chat
  addMessageToChat(B: {
    chatId: number;
    text: string;
  }): Promise<{ messageId: number } | any> {
    const accessToken = tokenService.getAccessToken();
    return http.post(`${ROOT_URL}/addmessage`, null, B, accessToken);
  }

  //mark all as read
  readIncomeMessagesByChatId(B: {
    chatId: number;
  }): Promise<{ countRead: number } | any> {
    const accessToken = tokenService.getAccessToken();
    return http.post(`${ROOT_URL}/allread`, null, B, accessToken);
  }

  getChatsList(Q?: { excludetype: TypeChat }): Promise<chatListType[]> {
    const accessToken = tokenService.getAccessToken();
    return http.get(ROOT_URL, Q, accessToken);
  }

  //chat by chat id
  getMessagesListById(id: string): Promise<ChatByType> {
    const accessToken = tokenService.getAccessToken();
    return http.get(`${ROOT_URL}byid/${id}`, null, accessToken);
  }

  //all unread messages
  getChatsCount() {
    const accessToken = tokenService.getAccessToken();
    return http.get<null, ChatsInfo>(`${ROOT_URL}/count`, null, accessToken);
  }

  //unread messages for this lead id
  getLeadMessagesCount(leadid: string): Promise<{ count: number }> {
    const accessToken = tokenService.getAccessToken();
    return http.get(`${ROOT_URL}/count/${leadid}`, null, accessToken);
  }

  //get docs from chat by doc id
  getDocFromChat(id: string): Promise<any> {
    const accessToken = tokenService.getAccessToken();
    return http.getWithBlob(`${ROOT_URL}/documents/${id}`, null, accessToken);
  }

  //send doc to chat
  addDocToChat(chatid: string, document: any): Promise<string> {
    const accessToken = tokenService.getAccessToken();
    const formData = new FormData();
    formData.append("Document.Document", document);
    return http.post(
      `${ROOT_URL}/${chatid}/documents`,
      null,
      formData,
      accessToken
    );
  }

  //send doc to chat with progress
  uploadDocToChat(
    chatid: string,
    document: any,
    onProgress: (percentage: number, loadedMB: string, totalMB: string) => void,
    signal: AbortSignal
  ) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append("Document.Document", document);
      const url = `${ROOT_URL}/${chatid}/documents`
      const xhr = new XMLHttpRequest();
      const accessToken = tokenService.getAccessToken();
      xhr.open('POST', url);
      xhr.setRequestHeader('Authorization', `Bearer ${accessToken}`);
      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          const percentage = (event.loaded / event.total) * 100;
          const loadedMB = (event.loaded / 1024 / 1024).toFixed(2);
          const totalMB = (event.total / 1024 / 1024).toFixed(2);
          onProgress(percentage, loadedMB, totalMB);
        }
      };
      xhr.onload = () => {
        if (xhr.status === 200) {
          // resolve(JSON.parse(xhr.responseText));
        } else {
          reject(`Upload failed: ${xhr.status} ${xhr.statusText}`);
        }
      };
      xhr.onerror = () => {
        reject('Network error occurred during the upload process');
      };
      // listen for the abort event
      signal.addEventListener('abort', () => {
        xhr.abort();
        reject(new Error('прервано'));
      });
      xhr.send(formData);
    });
  }

  //chat by type role. maybe used only for support
  getMessagesListByChatType(type: string): Promise<ChatByType> {
    const accessToken = tokenService.getAccessToken();
    return http.get(`${ROOT_URL}bytype/${type}`, null, accessToken);
  }
}

const chatsApi = new ChatsApi();
export default chatsApi;
