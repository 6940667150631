export const ErrorSignIcon = () => (
  <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" width="10" height="10" rx="5" fill="#DC5103" />
    <path d="M6.17152 5.34968H4.82886L4.53516 3.67832V2H6.46523V3.67832L6.17152 5.34968ZM6.17991 7.72308C5.9953 7.90769 5.76872 8 5.50019 8C5.23166 8 5.00229 7.90769 4.81208 7.72308C4.62746 7.53287 4.53516 7.3035 4.53516 7.03497C4.53516 6.76643 4.62746 6.53986 4.81208 6.35525C5.00229 6.16504 5.23166 6.06993 5.50019 6.06993C5.76872 6.06993 5.9953 6.16504 6.17991 6.35525C6.37012 6.53986 6.46523 6.76643 6.46523 7.03497C6.46523 7.3035 6.37012 7.53287 6.17991 7.72308Z"
      fill="#FFFFFF" />
  </svg>
);



