import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Link,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ChatWindow from "../../app/components/chat-window";
import HeaderNew from "../../app/components/redesign/Header/HeaderNew";
import SupportModal from "../../app/components/support-modal";
import { setIsOpenInnModal } from "../../core/store/action-creators";
import { clientStatuses } from "../../core/types/managerApiTypes";
import { rootState } from "../../core/types/rootState";

/* Redesign. Client chat page*/

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: '40px 80px',
      margin: "0 auto",
      maxWidth: '100%',
      boxSizing: "border-box",
    },
    gridWrap: {
      height: '60%',
      "& div::before": {
        display: "none",
      },
      "& div::after": {
        display: "none",
      },
    },
    gridItem: {
      backgroundColor: "#ffffff",
      borderRadius: "10px",
      height: "100%",
    },
    gridItemChat: {
      padding: "0px",
      backgroundColor: "#ffffff",
      borderRadius: "10px",
      height: "100%",
      // '&>div': {
      //     height: '100%',
      // }
    },
    grid: {
      backgroundColor: "#ffffff",
      borderRadius: "10px",
      width: "100%",
      // margin: "0 !important",
      // height: "calc(100vh - 160px)",
      padding: 0,
    },
  })
);

const ClientSupportChat: React.FC = () => {
  const classes = useStyles();
  const initialUser = useSelector((state: rootState) => state.currentUser);
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);

  const handleOpen = () => {
    setOpenModal(true);
  };

  useEffect(() => {
    if (initialUser.status === clientStatuses.NotCompleteRegistration) {
      dispatch(setIsOpenInnModal(true));
    }
  }, [initialUser.status]);

  return (
    <div>
      <HeaderNew title={"Поддержка"} />
      <Container className={classes.container}>
        <Box className={classes.gridWrap}>
          <Grid
            alignContent="space-between"
            container
            spacing={3}
            className={classes.grid}
            style={{ position: "relative" }}
          >
            <Grid
              item
              xs={6}
              className={classes.gridItem}
              style={{
                padding: "30px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Box>
                <Typography variant="h4" component="p">
                  Мы готовы помочь вам любым удобным для вас способом!
                </Typography>
                <Typography
                  variant="subtitle1"
                  component="p"
                  style={{
                    padding: "15px 0px",
                    marginBlockStart: "0px",
                    marginBlockEnd: "0px",
                  }}
                >
                  Закажите обратный звонок, напишите нам на почту, или опишите
                  свою проблему в чате
                </Typography>
                <Typography
                  variant="body2"
                  component="p"
                  style={{ color: "#617E8C", marginTop: "18px" }}
                >
                  Наша почта
                </Typography>
                <Link
                  variant="h4"
                  component="a"
                  href={`mailto:${"support@finlab.ru"}`}
                  style={{ padding: "5px 0px" }}
                >
                  support@finlab.ru
                </Link>
              </Box>
              <Button
                color="primary"
                variant="outlined"
                onClick={handleOpen}
                style={{
                  maxWidth: "205px",
                  position: "fixed",
                  bottom: 60
                }}
              >
                <Typography
                  component="span"
                  variant="body1"
                  style={{
                    color: "#306F9A",
                    textTransform: "none",
                    lineHeight: "2",
                    fontWeight: 500,
                  }}
                >
                  Обратная связь
                </Typography>
              </Button>
            </Grid>
            <Divider
              orientation="vertical"
              flexItem
              style={{
                position: "absolute",
                left: "50%",
                height: "100%",
                display: "block",
              }}
            />
            <Grid
              item
              xs={6}
              className={classes.gridItemChat}
              style={{ padding: "0 0 30px" }}
            >
              <ChatWindow
                leadId=""
                chatType="support"
                chatHelloMessage="Здравствуйте, я ваш менеджер техподдержки. Чем я могу вам помочь?"
              />
            </Grid>
          </Grid>
        </Box>
      </Container>
      {initialUser.status && initialUser.status !== clientStatuses.NotCompleteRegistration && (
        <SupportModal
          isOpen={openModal}
          setOpenModal={setOpenModal}
          text="Если вам необходима консультация, помощь, или у вас возникли вопросы, просто оставьте заявку на обратную связь, и мы вам обязательно ответим!"
        />
      )}
    </div>
  );
};


export default memo(ClientSupportChat);
