import React from "react";
import CheckboxMobile from "../../../../core/consts/images/checkbox_mobile.png";
import { COLORS } from "../../../../core/consts/redesign/commonRedesign";
import { GreenTriangle } from "../../../../core/consts/redesign/icons";
import { useAppSelector } from "../../../../core/store";
import {
  BankType,
  FieldType,
  PropsalData,
} from "../../../new-order/types/bigForm";
import classes from "../../NewOrderRedesign.module.scss";

export type StepType = {
  //PropsalData
  id: number;
  stepId: number;
  name: string;
  isFull: boolean;
  isCommon: boolean;
  banks: BankType[];
  fields: FieldType[];
  innerSteps: StepType[];
  proposals: { id: number[] };
};
type PropsType = {
  setCurrentStep: (step: PropsalData) => void;
  currentStep: PropsalData | null;
  isFinishStep: boolean;
};

const MobileStepper = ({
  setCurrentStep,
  currentStep,
  isFinishStep,
}: PropsType) => {
  const { formData } = useAppSelector((state) => state.newOrder);
  const handleChangeCurrentStep = (step: PropsalData) => {
    setCurrentStep(step);
  };

  return (
    <div className={classes.mobile_stepper}>
      {formData && formData.map((step, index) => (
        <React.Fragment key={index}>
          <div
            className={classes.step_item}
            onClick={() => {
              if (!isFinishStep) {
                handleChangeCurrentStep(step)
              }
            }}>
            <div className={classes.step_icon}>
              <img
                className={classes.step_icon_img}
                alt='order step icon'
                src={step.icon} />
            </div>
            <div
              className={classes.step_indicator}
              style={{
                borderBottomColor: step.id === currentStep?.id ? COLORS.success :
                  step.isFill ? COLORS.success :
                    step.isMissed ? COLORS.alert : COLORS.gray2
              }} />
            {step.id === currentStep?.id &&
              <div className={classes.step_icon_small}>
                <GreenTriangle />
              </div>
            }
          </div>
          {/* внутренние шаги должны иметь иконки банка */}
          {step?.innerSteps && step.innerSteps.length > 0 && (
            step.innerSteps.map((el, i) => (
              <div
                key={i}
                className={classes.step_item}
                onClick={() => {
                  handleChangeCurrentStep(el)
                }}>
                <div className={classes.step_icon}>
                  <img
                    className={classes.step_icon_img}
                    alt='order step icon'
                    src={el.icon} />
                </div>
                <div
                  className={classes.step_indicator}
                  style={{
                    borderBottomColor: el.id === currentStep?.id ? COLORS.success :
                      el.isFill ? COLORS.success :
                        el.isMissed ? COLORS.alert : COLORS.gray2
                  }} />
                {el.id === currentStep?.id &&
                  <div className={classes.step_icon_small}>
                    <GreenTriangle />
                  </div>}
              </div>
            ))
          )}
        </React.Fragment>
      ))}
      {isFinishStep &&
        <div className={classes.step_item}>
          <div className={classes.step_icon}>
            <img src={CheckboxMobile} className={classes.step_icon_img} alt="success icon" />
          </div>
          <div
            className={classes.step_indicator}
            style={{
              borderBottomColor: COLORS.success
            }} />
          <div className={classes.step_icon_small}>
            <GreenTriangle />
          </div>
        </div>
      }
    </div>
  );
};

export default MobileStepper;
