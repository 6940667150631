import React, { memo, useState, useEffect } from "react";
import {
  makeStyles,
  createStyles,
  Theme,
  Modal,
  Typography,
  IconButton,
  Divider,
  Box,
  Paper,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ChatWindow from "../../../app/components/chat-window";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {},
    paper: {
      minHeight: "80%",
      maxHeight: "90%",
      position: "absolute",
      width: "50%",
      maxWidth: "890px",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: theme.palette.background.paper,
      border: "none",
      padding: theme.spacing(2, 4, 0),
      borderRadius: "10px",
      // '& hr+div': {
      //     height: '90%',
      // }
    },
    box: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "baseline",
      width: "100%",
    },
    title: {
      padding: "38px 30px ",
    },
  })
);

type PropsType = {
  close: any;
  open: boolean;
};

const ChatModal: React.FC<PropsType> = (props) => {
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);
  const handleClose = () => {
    setOpenModal(false);
    props.close();
  };

  useEffect(() => {
    setOpenModal(props.open);
  }, [props.open]);

  return (
    <Modal open={openModal} onClose={handleClose} className={classes.modal}>
      <Paper className={classes.paper}>
        <Box className={classes.box}>
          <Typography
            variant="h3"
            component="h3"
            className={classes.title}
            style={{ padding: "0 0 25px 0" }}
          >
            Чат с менеджером
          </Typography>
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider />
        <ChatWindow
          chatType="manager"
          leadId=""
          handleCloseModal={handleClose}
          chatHelloMessage="Здравствуйте, я ваш менеджер. Чем я могу вам помочь?"
        />
      </Paper>
    </Modal>
  );
};

export default memo(ChatModal);
