export const DotsHorizontalIcon = ({ color }: { color?: string }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_5542_30679)">
      <path fill={color ? color : "white"} d="M2 14.0005C3.10457 14.0005 4 13.1051 4 12.0005C4 10.8959 3.10457 10.0005 2 10.0005C0.89543 10.0005 0 10.8959 0 12.0005C0 13.1051 0.89543 14.0005 2 14.0005Z" />
      <path fill={color ? color : "white"} d="M12 14.0005C13.1046 14.0005 14 13.1051 14 12.0005C14 10.8959 13.1046 10.0005 12 10.0005C10.8954 10.0005 10 10.8959 10 12.0005C10 13.1051 10.8954 14.0005 12 14.0005Z" />
      <path fill={color ? color : "white"} d="M22 14.0005C23.1046 14.0005 24 13.1051 24 12.0005C24 10.8959 23.1046 10.0005 22 10.0005C20.8954 10.0005 20 10.8959 20 12.0005C20 13.1051 20.8954 14.0005 22 14.0005Z" />
    </g>
    <defs>
      <clipPath id="clip0_5542_30679">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
