import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Popover from '@mui/material/Popover';
import clsx from 'clsx';
import React from "react";
import { NavLink } from 'react-router-dom';
import { useStylesSideMenu } from "../../useStylesSideMenu";

type Props = {
  title: string
  open: boolean
  url: string
  onClick?: () => void;
  isProductActive: boolean
}

export const MenuItemWithTooltip: React.FC<Props> = (props) => {
  const classes = useStylesSideMenu();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const popoverOpen = Boolean(anchorEl);

  return (
    <ListItem
      button
      disableGutters={true}
      className={props.open ? classes.iconButton : classes.iconButtonClose}    >
      <NavLink
        className={clsx(classes.navlink, {
          [classes.navlink_active]: props.isProductActive,
        })}
        activeClassName={classes.navlink_active}
        to={props.url}>
        <ListItemIcon
          className={classes.menuIcon}
          aria-owns={popoverOpen ? 'mouse-over-popover' : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        >
          {props.children}</ListItemIcon>
        {props.open &&
          <ListItemText
            primary={props.title}
            className={classes.menuText} />
        }
        {!props.open &&
          <Popover
            id="mouse-over-popover"
            sx={{
              pointerEvents: 'none',
            }}
            open={popoverOpen}
            anchorEl={anchorEl}
            onClose={handlePopoverClose}
            disableRestoreFocus
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'left',
            }}
          >
            <div className={classes.popover}>
              {props.title}
            </div>
          </Popover>
        }
      </NavLink>
    </ListItem >
  )
} 