import { FormControlLabel, SwitchProps } from "@mui/material";
import Switch from '@mui/material/Switch';
import { styled } from "@mui/material/styles";
import React from "react";
import { COLORS } from "../core/consts/redesign/commonRedesign";

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({

  boxSizing: 'border-box',
  width: '40px',
  height: '26px',
  padding: 0,
  // margin: '8px',
  display: 'flex',
  alignItems: 'center',
  '&:hover': {
    opacity: 0.9
  },

  '& .MuiSwitch-switchBase': {
    padding: '4px',
    color: COLORS.gray2,
    top: '1px',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: COLORS.white,
      '& + .MuiSwitch-track': {
        backgroundColor: COLORS.brand_blue,
        border: `2px solid ${COLORS.brand_blue}`,
        opacity: 1,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    width: '16px',
    height: '16px',
    boxShadow: 'none',
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    border: `2px solid ${COLORS.gray2}`,
    backgroundColor: COLORS.white,
    opacity: 1,
    height: 'calc(100% - 4px)',
    transition: theme.transitions.create(['background-color', 'border']),
  },
}));

type Props = {
  value?: boolean;
  text: string;
  onChangeValue?: (checked: boolean) => void;
};

const SwitcherCustom: React.FC<Props> = ({
  value,
  text,
  onChangeValue,
}) => {
  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (onChangeValue) {
      onChangeValue(checked);
    }
  };

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginLeft: '10px'
    }}>
      <FormControlLabel
        control={
          <IOSSwitch
            checked={value}
            onChange={handleOnChange}
            color="primary"
          />
        }
        label={
          <span
            style={{
              fontSize: "16px",
              lineHeight: "21px",
              fontFamily: "Gilroy",
              fontWeight: "500",
              overflow: "hidden",
              margin: 0,
              marginLeft: '8px',
              display: "flex",
              alignItems: "center",
            }}
          >
            {text}
          </span>
        }
        labelPlacement="end"
      />
    </div>
  );
};

export default SwitcherCustom;
