import kontragentApi from "../api/konteragentApi";
import { konteragentsListType, konteragentType } from "../api/konteragentApi";



class KonteragentService {

    searchKontragentService(search: string, isUL: boolean, isIP: boolean, isActive: boolean): Promise<konteragentsListType[]> {
        let Q = {
            search: search,
            isUL: isUL,
            isIP: isIP,
            isActive: isActive,
        }
        return kontragentApi.searchKontagentApi(Q)
    }

    getKontragentByIdService(Id: string): Promise<konteragentType> {
        return kontragentApi.getKontagentById(Id)
    }
}

const kontragentService = new KonteragentService()
export default kontragentService